import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import Modal from 'components/Modal';


const ModalBody = styled.div`
  & .form-group:last-of-type {
    margin-bottom: 0px;
  }
`;

export default class Receive extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      lines: [],
      canDelete: false,
    };
    this.onDelete = this.onDelete.bind(this);
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lines !== prevProps.lines || this.state.open !== prevState.open) {
      const {lines, canDelete} = Object.values(this.props.lines)
        .reduce((acc, l) => {
          if (l) {
            acc.lines.push({
              id: l.id,
              qty: l.qty,
              description: l.description,
              partNumber: l.partNumber,
            });
            if (l.canDelete === false) {
              acc.canDelete = false;
            }
          }
          return acc;
        }, {lines: [], canDelete: true});
      if (lines.length >= 1) {
        this.setState({
          ...this.state,
          lines,
          canDelete
        });
      } else {
        this.setState({
          ...this.state,
          lines: [],
          canDelete: false
        });
      }
    }
  }

  onToggleModal(open) {
    this.setState({
      open,
    });
  }

  onDelete() {
    const {jobId, onDelete} = this.props;
    const {lines} = this.state;
    onDelete(jobId, {
      action: 'delete',
      lines: lines.map(l => ({id: l.id}))
    })
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while deleting parts.');
        } else {
          toast.success('Parts successfully deleted.');
          this.setState({
            open: false,
            selectedSuppliers: {}
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while deleting parts.');
      });
  }

  render () {
    console.log('deletecredit:render');
    const {open, lines, canDelete} = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-danger'
          onClick={this.onOpen}
          disabled={canDelete !== true}
        >Delete</button>
        <Modal
          title='Are you sure you wish to delete the following credit lines?'
          open={open}
          onClose={this.onClose}
        >
          <ModalBody className='modal-body'>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th className='border-top-0'>Qty</th>
                  <th className='border-top-0'>Description</th>
                  <th className='border-top-0'>Part #</th>
                </tr>
              </thead>
              <tbody>
                {lines.map((l, i) => (
                  <tr key={i}>
                    <td className='align-middle'>{l.qty}x</td>
                    <td className='align-middle'>{l.description}</td>
                    <td className='align-middle'>{l.partNumber}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalBody>
          <div className='modal-footer bt-0'>
            <button type='button' className='btn btn-secondary' onClick={this.onClose}>Close</button>
            <button
              type='button'
              className='btn btn-danger'
              onClick={this.onDelete}
            >Delete</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

