import moment from 'moment';
import numeral from 'numeral';


let currentState = null;
const cs = window.localStorage.getItem('admin-excess-payments:stormId');
if (cs) {
  currentState = JSON.parse(cs);
}


export default (state = {notFound: null, stormId: currentState}, action) => {
  switch (action.type) {
    case 'ADMIN-EXCESS-PAYMENTS-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        invoices: null
      };
    case 'ADMIN-EXCESS-PAYMENTS-SET-NULL':
      return {
        ...state,
        notFound: null,
        invoices: null
      };
    case 'ADMIN-EXCESS-PAYMENTS-CHANGE-STORM-ID':
      const {stormId} = action;
      window.localStorage.setItem('admin-excess-payments:stormId', JSON.stringify(stormId));
      return {
        ...state,
        stormId,
      };
    case 'ADMIN-EXCESS-PAYMENTS-SET':
      {
        const year = moment().year();
        return {
          ...state,
          notFound: false,
          invoices: action.invoices.map(i => {
            const excessInvoiceDate = moment(i.excessInvoiceDate * 1000);
            return {
              ...i,
              excessInvoiceDateStr: excessInvoiceDate.format(
                excessInvoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
              ),
              excessAmountStr: numeral(i.excessAmount / 100).format('$0,0.00'),
              outstandingAmountStr: numeral((i.excessAmount - i.paidAmount) / 100).format('$0,0.00'),
              isPaid: i.excessAmount === i.paidAmount,
            };
          })
        };
      }
    case 'ADMIN-EXCESS-PAYMENTS-PAY':
      {
        return {
          ...state,
          invoices: state.invoices.filter(i => i.jobId !== action.jobId)
        };
      }
    default:
      return state;
  }
};
