import React from 'react';
import * as Icons from 'react-feather';
import {Map as LeafletMap, TileLayer, Circle} from 'react-leaflet';

import {
  Bar,
  BarChart,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import colors from 'colors';
import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  overflow-y: auto;
  font-size: 0.9rem;
`;
const Section = styled.div`
  border-radius: 0.5rem;
  background-color: ${colors.grey1};
  box-shadow: 0px 8px 15px -4px rgba(180, 180, 180, 0.5);
  :last-of-type {
    margin-bottom: 0px !important;
  }
  .leaflet-container {
    height: 500px;
  }
`;



const DashboardMap = ({section}) => (
  <div className='col-12 mb-3'>
    <Section className='p-2'>
      <LeafletMap center={[-33.878774, 151.156819]} zoom={6}>
        <TileLayer
          url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />
        {section.points.map((p, i) => (
          <Circle key={i} radius={p.radius} center={[p.lat, p.long]} color={section.color} />
        ))}
      </LeafletMap>
    </Section>
  </div>
);


const DashboardFinishDates = ({section}) => (
  <div className='col-12 mb-3'>
    <Section className='p-2'>
      <h5 className='text-primary text-center mb-0'>Finish dates</h5>
      <ResponsiveContainer width='100%' height={480}>
        <BarChart width={640} height={480} data={section.data}>
          <XAxis dataKey='week' />
          <YAxis />
          <Tooltip />
          {section.categories.map((c, k) => (
            <Bar
              key={k}
              dataKey={c.key}
              stackId='a'
              fill={c.color}
              isAnimationActive={false}
            />
          ))}
          <Legend />
        </BarChart>
      </ResponsiveContainer>
    </Section>
  </div>
);


const DashboardProgressChart = ({section}) => (
  <div className='col-12 mb-3'>
    <Section className='p-2'>
      <h5 className='text-primary text-center mb-0'>Progress</h5>
      <ResponsiveContainer width='100%' height={480}>
        <LineChart width={640} height={480} syncId='progress' data={section.data}>
          <XAxis dataKey='label' />
          <YAxis />
          <Tooltip />
          <Line
            type='monotone'
            dataKey='Allocations'
            stroke={section.allocationsColor}
            isAnimationActive={false}
            dot={null}
          />
          <Line
            type='monotone'
            dataKey='Cancellations'
            stroke={section.cancellationsColor}
            isAnimationActive={false}
            dot={null}
          />
          <Line
            type='monotone'
            dataKey='Contacts'
            stroke={section.contactsColor}
            isAnimationActive={false}
            dot={null}
          />
          <Line
            type='monotone'
            dataKey='Assessments'
            stroke={section.assessmentsColor}
            isAnimationActive={false}
            dot={null}
          />
          <Line
            type='monotone'
            dataKey='Booked'
            stroke={section.bookedColor}
            isAnimationActive={false}
            dot={null}
          />
          <Line
            type='monotone'
            dataKey='Remaining'
            stroke={section.remainingColor}
            isAnimationActive={false}
            dot={null}
          />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer width='100%' height={480}>
        <LineChart width={640} height={480} syncId='progress' data={section.data}>
          <XAxis dataKey='label' />
          <YAxis />
          <Tooltip />
          {section.categories.map((c, k) => (
            <Line
              key={k}
              type='monotone'
              dataKey={c.key}
              stroke={c.color}
              isAnimationActive={false}
              dot={null}
              strokeDasharray='5 5'
            />
          ))}
          {section.categories.map((c, k) => (
            <Line
              key={section.categories.length + k}
              type='monotone'
              dataKey={`Finished ${c.key}`}
              stroke={c.color}
              isAnimationActive={false}
              dot={null}
              legendType='none'
            />
          ))}
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </Section>
  </div>
);


const ProductionJobs = ({section}) => (
  <div className='col-12 mb-3'>
    <Section className='p-2'>
      <table className='table table-striped mb-0'>
        <thead>
          <tr>
            <th className='border-top-0'>Job #</th>
            <th className='border-top-0'>Claim #</th>
            <th className='border-top-0'>Rego</th>
            <th className='border-top-0'>Make/model</th>
            <th className='border-top-0'>Start/finish</th>
          </tr>
        </thead>
        <tbody>
          {section.jobs.length === 0 && (
            <tr>
              <td colSpan={4} className='text-center text-secondary'>
                Job does not contain any parts.
              </td>
            </tr>
          )}
          {section.jobs.map((j, i) => (
            <tr key={i}>
              <td><a href={j.url} target='_blank' rel='noopener noreferrer'>{j.jobNumber}</a></td>
              <td>{j.claimNumber}</td>
              <td>{j.rego}</td>
              <td>{j.makeModel}</td>
              <td>{j.startDateStr} {'\u2013'} {j.finishDateStr}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Section>
  </div>
);



const SECTIONS = {
  map: DashboardMap,
  progressChart: DashboardProgressChart,
  finishDates: DashboardFinishDates,
  productionJobs: ProductionJobs,
};

export default class Dashboard extends React.Component {
  componentDidMount() {
    const {stormId, onLoad} = this.props;
    onLoad(stormId);
  }
  componentDidUpdate(prevProps) {
    const {stormId, onLoad} = this.props;
    if (stormId !== prevProps.stormId) {
      onLoad(stormId);
    }
  }
  render() {
    console.log('dashboard:render');
    const {
      notFound,
      stormId,
      storms,
      sections,
      onChangeStormId,
    } = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load dashboard.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h5 className='mb-0'>Dashboard</h5>
          <Select
            align='right'
            background={colors.grey1}
            textField='name'
            options={storms}
            selected={stormId}
            onChange={onChangeStormId}
          />
        </div>
        {stormId !== null && (
          <div className='row mb-n3'>
            {sections.map((s, i) => {
              const S = SECTIONS[s.key];
              if (!S) return null;
              return (
                <S
                  key={i}
                  section={s}
                />
              );
            })}
          </div>
        )}
      </Container>
    );
  }
};
