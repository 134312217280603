module.exports = [
  {
    description: 'ALL TRIM & UPHOLSTERY NESS',
    settings: {
      'TURRET': {
        '^(?!panel)': { /* pricing method */
          'COUPE|HATCH|SEDAN|UTE4DR|VAN|WAGON': { /* body shape */
            '[^]*': { /* paint type */
              rrTime: 4.6,
              rrAutoReplaceable: true,
            }
          },
        }
      },
    }
  },
  {
    description: 'DISCONNECT BATTERY',
    settings: {
      'TURRET': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              rrTime: 0.14,
              rrAutoReplaceable: true,
            }
          }
        }
      },
    }
  },
  {
    description: 'FRONT GRILLE',
    settings: {
      'BONNET|LHF-GUARD|RHF-GUARD': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              rrTime: 0.08,
              rrAutoPaintable: true,
              rrAutoReplaceable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'PLENUM GRILLE & WIPERS',
    settings: {
      'BONNET|LHF-GUARD|RHF-GUARD': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.38,
              rrAutoPaintable: true,
              rrAutoReplaceable: true,
            }
          }
        }
      },
      'TURRET': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              rrTime: 0.35,
              rrAutoReplaceable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'LH HEADLIGHT D/A AND REFOCUS',
    settings: {
      'BONNET|LHF-GUARD|LHF-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              rrTime: 0.22,
              rrAutoPaintable: true,
              rrAutoReplaceable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'RH HEADLIGHT D/A AND REFOCUS',
    settings: {
      'BONNET|RHF-GUARD|RHF-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              rrTime: 0.22,
              rrAutoPaintable: true,
              rrAutoReplaceable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'LH TAIL LIGHTS',
    settings: {
      'BOOT|LHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.12,
              rrAutoReplaceable: true,
              rrAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'RH TAIL LIGHTS',
    settings: {
      'BOOT|RHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.12,
              rrAutoReplaceable: true,
              rrAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'TAIL LIGHTS X 2',
    settings: {
      'BOOT|LHR-DOOR|RHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.24,
            }
          }
        }
      }
    }
  },
  {
    description: 'FRONT SPLASH SHEILDS',
    settings: {
      'FRONT-BUMPER': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.2,
              rrAutoReplaceable: true,
              rrAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'REAR SPLASH SHEILDS X 2',
    settings: {
      'REAR-BUMPER': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.4,
              rrAutoReplaceable: true,
              rrAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'LH SPLASH SHEILDS',
    settings: {
      'BOOT|LHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.2,
              rrAutoReplaceable: true,
              rrAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'RH SPLASH SHEILDS',
    settings: {
      'BOOT|RHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.2,
              rrAutoReplaceable: true,
              rrAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'LH TURRET MOULDS',
    settings: {
      'BOOT|LHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.1,
              rrAutoReplaceable: true,
              rrAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'RH TURRET MOULDS',
    settings: {
      'BOOT|RHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              rrTime: 0.1,
              rrAutoReplaceable: true,
              rrAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'SCAN & CLEAR CODES',
    settings: {
      'TURRET': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              miscPrice: 110,
              miscAutoReplaceable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'AIRBAGS & FITTINGS NECESSARY',
    settings: {
      'LHR-QTR|RHR-QTR|TURRET|LH-CANTRAIL|RH-CANTRAIL': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              rrTime: 0.90,
              rrAutoReplaceable: true,
            }
          }
        }
      },
    }
  },
  {
    description: 'SPECIAL TAPE FRONT SCREEN',
    settings: {
      'BOOT|LHR-DOOR|RHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              miscPrice: 50,
              miscAutoPaintable: true,
            }
          }
        }
      },
      'LHR-QTR|RHR-QTR|LH-CANTRAIL|RH-CANTRAIL': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              miscPrice: 50,
              miscAutoPaintable: true,
            }
          }
        }
      },
      'TURRET': {
        '^(?!panel)': { /* pricing method */
          '[^*]': { /* body shape */
            '[^]*': { /* paint type */
              miscPrice: 50,
              miscAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'SPECIAL TAPE REAR SCREEN',
    settings: {
      'BOOT|LHR-DOOR|RHR-DOOR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '^M[1-3]$': { /* paint type */
              miscPrice: 50,
              miscAutoPaintable: true,
            }
          }
        }
      },
      'LHR-QTR|RHR-QTR|LH-CANTRAIL|RH-CANTRAIL': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              miscPrice: 50,
              miscAutoPaintable: true,
            }
          }
        }
      },
      'TURRET': {
        '^(?!panel)': { /* pricing method */
          '[^*]': { /* body shape */
            '[^]*': { /* paint type */
              miscPrice: 50,
              miscAutoPaintable: true,
            }
          }
        }
      }
    }
  },
  {
    description: 'REAR SCREEN',
    settings: {
      'LHR-QTR|RHR-QTR': {
        '^(?!panel)': { /* pricing method */
          '[^]*': { /* body shape */
            '[^]*': { /* paint type */
              miscPrice: 150,
              miscAutoPaintable: true,
              miscAutoReplaceable: true,
            }
          }
        }
      }
    }
  },
];
