import moment from 'moment';


export default (state = {notFound: null, report: null}, action) => {
  switch (action.type) {
    case 'ADMIN-REPORTS-SURVEY-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        report: null
      };
    case 'ADMIN-REPORTS-SURVEY-SET-NULL':
      return {
        ...state,
        notFound: null,
        report: null,
      };
    case 'ADMIN-REPORTS-SURVEY-SET':
      {
        const {report} = action;
        const year = moment().year();
        return {
          ...state,
          notFound: false,
          report: report.map(r => {
            const ts = moment(r.ts * 1000);
            return {
              ...r,
              tsStr: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
            };
          }),
        };
      }
    default:
      return state;
  }
};
