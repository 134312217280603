import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Payments from './component.jsx';


const load = (API, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/payments`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-PAYMENTS-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'JOB-PAYMENTS-SET',
          jobId,
          ...resp
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-PAYMENTS-SET-NULL'
});

const addPayment = (API, jobId, amount, isExcess, paymentMethod, paymentReference) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/payment`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({amount, isExcess, paymentMethod, paymentReference})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        const {jobNumber, isInvoiced, isPaid, total, payments} = res;
        dispatch({
          type: 'JOB-PAYMENTS-SET',
          jobNumber,
          isInvoiced,
          isPaid,
          total,
          payments,
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = (state, props) => {
  const {jobId} = props;
  let jobValid = null;
  if (state.jobPayments.notFound === true)
    jobValid = false;
  else if (state.jobPayments.notFound === false) {
    jobValid = true;
  }
  if (jobValid !== true) {
    return {
      jobValid,
    };
  }
  const {
    jobPayments: {
      jobNumber, flagged, vpp, vip, importUrl,
      isInvoiced, isPaid, payments, outstandingAmount
    },
    params: {employees: {index: employeesIndex}}
  } = state;
  return {
    jobValid,
    jobId,
    jobNumber,
    flagged,
    vpp,
    vip,
    importUrl,
    isInvoiced,
    isPaid,
    payments,
    outstandingAmount,
    employeesIndex
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: jobId => dispatch(load(props.API, jobId)),
  onUnload: () => dispatch(unload()),
  onAddPayment: (jobId, amount, isExcess, paymentMethod, paymentReference) =>
    dispatch(addPayment(props.API, jobId, amount, isExcess, paymentMethod, paymentReference)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Payments)
);
