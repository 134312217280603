import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';
import env from 'env';
import DollarIn from 'icons/dollar-in.jsx';
import DollarOut from 'icons/dollar-out.jsx';
import Invoice from 'icons/invoice.jsx';
import Receipt from 'icons/receipt.jsx';
import UserDollar from 'icons/user-dollar.jsx';
import Wrench from 'icons/wrench.jsx';

import Add from 'components/internal/Add';
import AssessmentInbox from 'components/internal/AssessmentInbox';


const Container = styled.div`
  width: 64px;
  @media (min-width: 992px) {
    width: 220px;
  }
  background-color: ${env.APP_ENV === 'PROD' ? colors.grey1 : colors.amberExtraLight};
  overflow-y: auto;

  @media print {
    display: none !important;
  }

  ${({open}) => open && `
    @media not print {
      @media (max-width: 992px) {
        display: flex !important;
      }
    }
  `}

  & a {
    color: ${colors.body};
    text-decoration: none;
  }
  & a.active {
    color: ${colors.primary};
  }

  & a div {
    height: 48px;
    width: 48px;
    line-height: 48px;
  }
  & svg {
    stroke: ${colors.body};
  }
  & a.active div {
    background-color: ${colors.primary};
    box-shadow: 0px 7px 10px rgba(157, 157, 157, 0.5);
  }
  & a.active svg {
    stroke: #fff;
  }
`;
const NavButton = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  line-height: 64px;
  @media (max-width: 992px) {
    justify-content: center;
    width: 64px;
  }
`;
const NavIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;
  line-height: 64px;
  :hover svg {
    stroke: ${colors.primary};
  }
  @media (min-width: 992px) {
    margin-left: 1rem;
  }
`;
const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 64px;
  width: 64px;
  line-height: 64px;
  :hover svg {
    stroke: ${colors.primary};
  }
`;


export default ({open, abilities, activePage, activeSubPage, onLogout}) => (
  <Container
    open={open}
    className='flex-shrink-0 d-none d-lg-flex flex-column justify-content-between'
  >
    <div className='d-flex flex-column justify-content-start'>
      {abilities.canViewDashboard === true && (
        <NavButton to='/dashboard' exact={true}>
          <NavIcon
            className='rounded-circle'
            title='dashboard'
          >
            <Icons.Home width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Home</span>
        </NavButton>
      )}
      {abilities.canViewTasks === true && (
        <NavButton to='/tasks' exact={true}>
          <NavIcon
            className='rounded-circle'
            title='task list'
          >
            <Icons.CheckSquare width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Tasks</span>
        </NavButton>
      )}
      {abilities.canViewOutstandingInvoices === true && (
        <NavButton to='/outstanding-invoices' exact={true}>
          <NavIcon
            className='rounded-circle'
            title='outstanding invoices'
          >
            <Invoice width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Outstanding</span>
        </NavButton>
      )}
      {abilities.canViewTechInvoicing === true && (
        <NavButton to='/technician-invoicing' exact={true}>
          <NavIcon
            className='rounded-circle'
            title='done tasks'
          >
            <Receipt width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Tech Invoicing</span>
        </NavButton>
      )}
      {abilities.canViewAssessmentsInbox === true && (
        <Button
          className='d-lg-none'
        >
          <AssessmentInbox />
        </Button>
      )}
      {(abilities.canAddJob === true || abilities.canAdminMiscInvoices === true) && (
        <Button
          className='d-lg-none'
        >
          <Add />
        </Button>
      )}
      {abilities.canViewTracking === true && (
        <NavButton to='/tracking'>
          <NavIcon
            className='rounded-circle'
            title='tracking'
          >
            <Icons.Activity width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Tracking</span>
        </NavButton>
      )}
      {abilities.canViewProduction === true && (
        <NavButton to='/production'>
          <NavIcon
            className='rounded-circle'
            title='production'
          >
            <Icons.Sliders width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Production</span>
        </NavButton>
      )}
      {abilities.canViewConventional === true && (
        <NavButton to='/conventional'>
          <NavIcon
            className='rounded-circle'
            title='conventional'
          >
            <Wrench width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Conventional</span>
        </NavButton>
      )}
      {abilities.canViewBookingSchedule === true && (
        <NavButton to='/booking-schedule/in'>
          <NavIcon
            className='rounded-circle'
            title='booking schedule'
          >
            <Icons.Calendar width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Bookings</span>
        </NavButton>
      )}
      {abilities.canViewOutList === true && (
        <NavButton to='/booking-schedule/out'>
          <NavIcon
            className='rounded-circle'
            title='booking schedule'
          >
            <Icons.CornerUpRight width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Out list</span>
        </NavButton>
      )}
      {abilities.canAdminView === true && (
        <NavButton to='/admin'>
          <NavIcon
            className='rounded-circle'
            title='admin'
          >
            <Icons.Layers width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Admin</span>
        </NavButton>
      )}
      <Button
        className='d-lg-none'
        onClick={onLogout}
      >
        <Icons.LogOut width={24} height={24} />
        <span className='d-none d-lg-block ml-3'>Logout</span>
      </Button>
    </div>
    {abilities.canAdminView === true && activePage === 'admin' && (
      <div className='d-flex flex-column justify-content-end mt-5'>
        {abilities.canAdminCreditorInvoices === true && (
          <NavButton to='/admin/creditor-invoices'>
            <NavIcon
              className='rounded-circle'
              title='creditor invoices'
            >
              <Receipt width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Creditor invoices</span>
          </NavButton>
        )}
        {abilities.canAdminCreditorPayments === true && (
          <NavButton to='/admin/creditor-payments'>
            <NavIcon
              className='rounded-circle'
              title='creditor payments'
            >
              <DollarOut width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Creditor payments</span>
          </NavButton>
        )}
        {abilities.canAdminCredits === true && (
          <NavButton to='/admin/credits'>
            <NavIcon
              className='rounded-circle'
              title='credits'
            >
              <Icons.CornerRightUp width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Credits</span>
          </NavButton>
        )}
        {abilities.canAdminDebtorPayments === true && (
          <NavButton to='/admin/debtor-payments'>
            <NavIcon
              className='rounded-circle'
              title='debtor payments'
            >
              <DollarIn width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Debtor payments</span>
          </NavButton>
        )}
        {abilities.canAdminExcessPayments === true && (
          <NavButton to='/admin/excess-payments'>
            <NavIcon
              className='rounded-circle'
              title='excess payments'
            >
              <Icons.BookOpen width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Excess payments</span>
          </NavButton>
        )}
        {abilities.canAdminMiscInvoices === true && (
          <NavButton to='/admin/misc-invoices'>
            <NavIcon
              className='rounded-circle'
              title='miscellaneous invoices'
            >
              <Icons.CreditCard width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Misc invoices</span>
          </NavButton>
        )}
        {abilities.canAdminTechnicianCommissions === true && (
          <NavButton to='/admin/technician-commissions'>
            <NavIcon
              className='rounded-circle'
              title='technician commissions'
            >
              <UserDollar width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Tech commissions</span>
          </NavButton>
        )}
        {abilities.canAdminReports === true && (
          <NavButton to='/admin/reports'>
            <NavIcon
              className='rounded-circle'
              title='reports'
            >
              <Icons.PieChart width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Reports</span>
          </NavButton>
        )}
        {abilities.canAdminStatus === true && (
          <NavButton to='/admin/status'>
            <NavIcon
              className='rounded-circle'
              title='admin'
            >
              <Icons.Cpu width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Status</span>
          </NavButton>
        )}
        {abilities.canAdminSettings === true && (
          <NavButton to='/admin/settings'>
            <NavIcon
              className='rounded-circle'
              title='settings'
            >
              <Icons.Settings width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Settings</span>
          </NavButton>
        )}
      </div>
    )}
    {abilities.canViewProduction === true && activePage === 'production' && (
      <div className='d-flex flex-column justify-content-end mt-5'>
        <NavButton to='/production/pipeline'>
          <NavIcon
            className='rounded-circle'
            title='pipeline'
          >
            <Icons.Trello width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Pipeline</span>
        </NavButton>
        <NavButton to='/production/jobsheets'>
          <NavIcon
            className='rounded-circle'
            title='jobsheets'
          >
            <Icons.Clipboard width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Jobsheets</span>
        </NavButton>
      </div>
    )}
    {activePage === 'job' && (
      <div className='d-flex flex-column justify-content-end mt-5'>
        {abilities.canJobDetailsView === true && (
          <NavButton to={`/job/${activeSubPage}/details`}>
            <NavIcon
              className='rounded-circle'
              title='job details'
            >
              <Icons.Archive width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Details</span>
          </NavButton>
        )}
        {abilities.canJobPurchasing === true && (
          <NavButton to={`/job/${activeSubPage}/purchasing`}>
            <NavIcon
              className='rounded-circle'
              title='job purchasing'
            >
              <Icons.ShoppingCart width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Purchasing</span>
          </NavButton>
        )}
        {abilities.canJobLocations === true && (
          <NavButton to={`/job/${activeSubPage}/locations`}>
            <NavIcon
              className='rounded-circle'
              title='locations'
            >
              <Icons.MapPin width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Locations</span>
          </NavButton>
        )}
        {abilities.canJobNotes === true && (
          <NavButton to={`/job/${activeSubPage}/notes`}>
            <NavIcon
              className='rounded-circle'
              title='job notes'
            >
              <Icons.FileText width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Notes</span>
          </NavButton>
        )}
        {abilities.canJobSms === true && (
          <NavButton to={`/job/${activeSubPage}/smss`}>
            <NavIcon
              className='rounded-circle'
              title='job sms'
            >
              <Icons.MessageSquare width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>SMS</span>
          </NavButton>
        )}
        {abilities.canJobDocuments === true && (
          <NavButton to={`/job/${activeSubPage}/documents`}>
            <NavIcon
              className='rounded-circle'
              title='job documents'
            >
              <Icons.File width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Documents</span>
          </NavButton>
        )}
        {abilities.canJobPayments === true && (
          <NavButton to={`/job/${activeSubPage}/payments`}>
            <NavIcon
              className='rounded-circle'
              title='payments'
            >
              <Icons.DollarSign width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Payments</span>
          </NavButton>
        )}
        {abilities.canJobQa === true && (
          <NavButton to={`/job/${activeSubPage}/qa`}>
            <NavIcon
              className='rounded-circle'
              title='qa'
            >
              <Icons.Clipboard width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>QA</span>
          </NavButton>
        )}
        {abilities.canJobSurvey === true && (
          <NavButton to={`/job/${activeSubPage}/survey`}>
            <NavIcon
              className='rounded-circle'
              title='job survey'
            >
              <Icons.Star width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Survey</span>
          </NavButton>
        )}
        {abilities.canJobLogs === true && (
          <NavButton to={`/job/${activeSubPage}/logs`}>
            <NavIcon
              className='rounded-circle'
              title='job logs'
            >
              <Icons.Clock width={24} height={24} />
            </NavIcon>
            <span className='d-none d-lg-block ml-3'>Logs</span>
          </NavButton>
        )}
      </div>
    )}
  </Container>
);
