import React from 'react';
import ReactDOM from 'react-dom';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';
import env from 'env';
import {ReactComponent as Logo} from 'logo.svg';


const HeaderContainer = styled.div`
  height: 64px;
  background-color: ${env.APP_ENV === 'PROD' ? colors.grey1 : colors.amberExtraLight};
`;
const Button = styled.div`
  cursor: pointer;
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;

  & a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    line-height: 64px;
  }
  & a div {
    height: 48px;
    width: 48px;
    line-height: 48px;
  }
  & svg {
    stroke: ${colors.body};
  }
  & a.active div {
    background-color: ${colors.primary};
    box-shadow: 0px 7px 10px rgba(157, 157, 157, 0.5);
  }
  & a.active svg {
    stroke: #fff;
  }
  :hover svg {
    stroke: ${colors.primary};
  }
`;
const Avatar = styled.div`
  width: 48px;
  height: 48px;
  line-height: 48px;
  color: #fff;
  background-color: ${colors.green};
`;
const ProfilesExpandButton = styled.div`
  cursor: pointer;
  height: 64px;
  line-height: 64px;
  text-align: center;
  display: inline-block;

  & svg {
    stroke: ${colors.body};
  }
`;
const Profile = styled.div`
  cursor: pointer;
  font-weight: ${opts => opts.selected ? 'bold' : 'normal'};
  :hover {
    background: ${colors.grey1};
  }
`;


export default class Header extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      profilesOpen: false
    };
    this.search = null;
    this.user = null;
    this.dropdown = React.createRef();
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
    this.onClickUser = this.onClickUser.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (this.dropdown.current !== null) {
      const node = ReactDOM.findDOMNode(this.dropdown.current);
      if (!node.contains(e.target) && this.user && !this.user.contains(e.target)) {
        this.setState({
          profilesOpen: false
        });
      }
    }
  }

  onClickMenu(e) {
    e.preventDefault();
    this.props.onToggleMenu();
  };

  onClickUser(e) {
    e.preventDefault();
    if (this.state.profilesOpen !== false) {
      this.setState({
        profilesOpen: false
      });
    } else {
      this.setState({
        profilesOpen: true
      });
    }
  }

  onClickProfile(profile) {
    const {onSwitchProfile} = this.props;
    onSwitchProfile(profile);
  }

  render() {
    const {
      activeProfile,
      profiles,
      onLogout
    } = this.props;
    const {profilesOpen} = this.state;
    const {profile} = profiles[activeProfile];

    return (
      <HeaderContainer
        className='d-flex flex-row justify-content-between align-items-center d-print-none'
      >
        <Button
          className='d-lg-none'
          onClick={this.onClickMenu}
        >
          <Icons.Menu width={24} height={24} />
        </Button>

        <div className='d-none d-lg-inline-block pl-4'>
          <NavLink to='/'>
            <Logo width={144} height={22} />
          </NavLink>
        </div>

        <div className='d-none d-md-flex flex-row'>
          <div className='dropdown' ref={this.dropdown}>
            <div className='d-flex flex-row'>
              <Button
                className='d-flex flex-row justify-content-center align-items-center'
                onClick={this.onClickUser}
              >
                <Avatar className='rounded-circle'>{profile.name}</Avatar>
              </Button>
              <ProfilesExpandButton
                className='pr-3'
                onClick={this.onClickUser}
              >
                <Icons.ChevronDown />
              </ProfilesExpandButton>
            </div>
            {profilesOpen === true && (
              <div
                className='dropdown-menu dropdown-menu-right show p-0 mt-0 mr-4'
                ref={ref => this.user = ref}
              >
                {profiles.length > 1 && profiles.map((p, i) => (
                  <Profile
                    key={i}
                    selected={i === activeProfile}
                    className='text-nowrap p-2'
                    onClick={this.onClickProfile.bind(this, i)}
                  >{p.profile.instanceName}</Profile>
                ))}
                {profiles.length > 1 && <hr className='m-0' />}
                <Profile
                  className='text-nowrap p-2'
                  onClick={onLogout}
                >Logout</Profile>
              </div>
            )}
          </div>
        </div>
      </HeaderContainer>
    );
  }
};
