import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Order from './component.jsx';


const setOpenImage = index => ({
  type: 'SUPPLIER-GALLERY-SET-OPEN-IMAGE',
  index
});

const load = (API, orderId, user) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    const url = new URL(`${env.API_SERVER}/v1/supplier/order/${orderId}`);
    url.searchParams.append('userName', user.name);
    url.searchParams.append('userInitial', user.initial);
    url.searchParams.append('userAvatarBg', user.avatarBg);
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'SUPPLIER-ORDER-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'SUPPLIER-ORDER-SET',
          order: resp.order
        });
        dispatch({
          type: 'SUPPLIER-GALLERY-SET',
          images: resp.images
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const submit = (API, orderId, user, lines) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/supplier/order/${orderId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          userName: user.name,
          userInitial: user.initial,
          userAvatarBg: user.avatarBg,
          lines,
        })
      }
    )
      .then(res => {
        if (res.error === true) return false;
        //const {header, lines} = res;
        //dispatch({
        //  type: 'JOB-DETAILS-HEADER-SET',
        //  header,
        //  generalSettings,
        //  storms
        //});
        //dispatch({
        //  type: 'JOB-DETAILS-QUOTE-SET',
        //  lines
        //});
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'SUPPLIER-ORDER-RESET'
});



const mapStateToProps = (state, props) => {
  const {
    supplierUserDetails: user,
    supplierGallery: gallery,
    supplierOrder: {notFound, order},
  } = state;
  if (user === null) {
    return {
      notFound: null
    }
  };
  return {
    notFound,
    order,
    gallery,
    user
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: (orderId, user) => dispatch(load(props.API, orderId, user)),
  onSubmit: (orderId, user, lines) => dispatch(submit(props.API, orderId, user, lines)),
  onUnload: () => dispatch(unload()),
  onSetOpenImage: index => dispatch(setOpenImage(index)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Order)
);
