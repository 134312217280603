import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Add from './component.jsx';


const jobCreate = (API, history) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/new`, {method: 'POST'})
      .then(res => {
        if (res.error === true) return false;
        const {jobId} = res;
        history.push(`/job/${jobId}`);
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const addTask = () => ({
  type: 'ADD-TASK-OPEN'
});

const addMiscInvoice = () => ({
  type: 'ADD-MISC-INVOICE-OPEN'
});


const mapStateToProps = (state, props) => {
  const {authentication: {activeProfile, profiles}} = state;
  const {profile: {abilities: {canAddJob, canAddMiscInvoice, canAddTask}}} = profiles[activeProfile];
  return {
    canAddJob,
    canAddMiscInvoice,
    canAddTask,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onAddJob: () => dispatch(jobCreate(props.API, props.history)),
  onAddTask: () => dispatch(addTask()),
  onAddMiscInvoice: () => dispatch(addMiscInvoice()),
});

export default withRouter(
  withApi(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Add)
  )
);

