import numeral from 'numeral';

import moment, {_moment} from 'm';


export default (state = {notFound: null, open: false}, action) => {
  switch (action.type) {
    case 'JOB-INVOICE-SET-NOT-FOUND':
      return {
        notFound: true,
        open: state.open,
      };
    case 'JOB-INVOICE-RESET':
      return {
        notFound: null,
        open: false,
      };
    case 'JOB-INVOICE-OPEN':
      return {
        ...state,
        open: true
      };
    case 'JOB-INVOICE-SET':
      const {
        header: {
          jobNumber,
          invoiceNumber,
          invoiceDate,
          name,
          email,
          debtorId,
          make,
          model,
          rego,
          claimNumber,
          excess,
          subtotal,
          gst,
          total,
          startDate,
          finishDate
        },
        lines
      } = action;
      const quote = lines.reduce((acc, l) => {
        if (acc.hasOwnProperty(l.type)) {
          acc[l.type].push({
            ...l,
            total: numeral(l.total / 100).format('$0,0.00'),
          });
        }
        return acc;
      }, {
        pdr: [],
        rr: [],
        repair: [],
        paint: [],
        part: [],
        misc: [],
        sublet: []
      });
      return {
        notFound: false,
        open: state.open,
        jobNumber,
        invoiceNumber,
        invoiceDate: _moment(invoiceDate * 1000).format('DD-MM-YYYY'),
        name,
        email,
        debtorId,
        makeModel: [make, model].filter(f => f && f.length).join(' '),
        rego,
        claimNumber,
        excess: numeral(excess / 100).format('$0,0.00'),
        subtotal: numeral(subtotal / 100).format('$0,0.00'),
        gst: numeral(gst / 100).format('$0,0.00'),
        total: numeral(total / 100).format('$0,0.00'),
        totalPayable: numeral((total - excess) / 100).format('$0,0.00'),
        startDate: startDate ? moment(startDate * 1000).format('DD-MM-YYYY') : 'N/A',
        finishDate: finishDate ? moment(finishDate * 1000).format('DD-MM-YYYY') : 'N/A',
        ...quote
      };
    default:
      return state;
  }
};

