import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import General from './component.jsx';


const change = changes => ({
  type: 'SETTINGS-GENERAL-CHANGE',
  changes
});

const addPublicHoliday = () => ({
  type: 'SETTINGS-GENERAL-ADD-PUBLIC-HOLIDAY',
});

const editPublicHoliday = (index) => ({
  type: 'SETTINGS-GENERAL-EDIT-PUBLIC-HOLIDAY',  
  index
});

const onChangePublicHolidayOption = (changes) => ({
  type: 'SETTINGS-GENERAL-CHANGE-PUBLIC-HOLIDAY',  
  changes
});

const save = (API, data) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/settings/general`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'SETTINGS-GENERAL-SET',
          data: res
        });
        dispatch({
          type: 'PARAMS-UPDATE-GENERAL-SETTINGS',
          data: res
        });
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  return state.settings.general;
};

const mapDispatchToProps = (dispatch, props) => ({
  onChange: changes => dispatch(change(changes)),
  onSave: data => dispatch(save(props.API, data)),
  onAddPublicHoliday: () => dispatch(addPublicHoliday()),
  onEditPublicHoliday: index => dispatch(editPublicHoliday(index)),
  onChangePublicHolidayOption: changes => dispatch(onChangePublicHolidayOption(changes)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(General)
);
