import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


const TASKS = [
  [
    {key: 'canRr', text: 'R&R'},
    {key: 'canRepair', text: 'Repair'},
    {key: 'canPaint', text: 'Paint'},
    {key: 'canDetail', text: 'Detail'},
    {key: 'canQa', text: 'QA'},
  ],
  [
    {key: 'canAllocatePickupTime', text: 'Allocate pickup time'},
    {key: 'canArrival', text: 'Arrivals'},
    {key: 'canOutstandingExcess', text: 'Outstanding excess'},
    {key: 'canAssess', text: 'Assessments'},
    {key: 'canBook', text: 'Not booked'},
    {key: 'canCancelled', text: 'Cancelled'},
  ],
  [
    {key: 'canCredits', text: 'Credits'},
    {key: 'canPartsNotOrdered', text: 'Parts not ordered'},
    {key: 'canPartsNotReceived', text: 'Parts not rec\'ved'},
    {key: 'canPartsNotFitted', text: 'Parts not fitted'},
  ],
  [
    {key: 'canBOPartsDelivery', text: 'B/O delivery'},
    {key: 'canBOPartsBooking', text: 'B/O booking'},
    {key: 'canBOPartsFitment', text: 'B/O fitment'},
  ],
  [
    {key: 'canProduction', text: 'Production'},
    {key: 'canHighCost', text: 'High cost jobs'},
    {key: 'canAuthority', text: 'Quote'},
  ],
  [
    {key: 'canDashboardKPIs', text: 'KPIs'},
    {key: 'canDashboardProgressChart', text: 'Progress chart'},
    {key: 'canDashboardFinishDates', text: 'Finish dates'},
    {key: 'canDashboardTechCommissions', text: 'Tech commissions'},
    {key: 'canDashboardSurvey', text: 'Survey'},
  ],
];

const Button = styled.div`
  cursor: pointer;
  & svg {
    fill: ${props => props.color};
  }
`;
const ExpandButton = styled(Icons.ChevronDown)`
  cursor: pointer;
  stroke: ${colors.grey4};
`;
const CollapseButton = styled(Icons.ChevronUp)`
  cursor: pointer;
  stroke: ${colors.grey4};
`;
const DeleteButton = styled(Icons.X)`
  cursor: pointer;
  stroke: ${colors.red};
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

export default class SettingsLocation extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      selectedEmployee: null,
      toggleState: {}
    };
    this.onToggleIncludeInLocationTrackingReport = this.onToggleIncludeInLocationTrackingReport.bind(
      this,
    );
    this.onSelectEmployee = this.onSelectEmployee.bind(this);
    this.onAddEmployee = this.onAddEmployee.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onSelectEmployee(e) {
    this.setState({
      selectedEmployee: e
    });
  }
  onAddEmployee() {
    this.props.onAddEmployee(this.state.selectedEmployee);
    this.setState({
      selectedEmployee: null
    });
  }
  onToggleEmployee(employeeId) {
    this.setState({
      toggleState: {
        ...this.state.toggleState,
        [employeeId]: !this.state.toggleState[employeeId]
      }
    });
  }
  onChange(field, e) {
    this.props.onChange({
      [field]: e,
      [`${field}Error`]: false
    });
  }
  onToggleIncludeInLocationTrackingReport(e) {
    this.props.onChange({
      includeInLocationTrackingReport: !this.props.includeInLocationTrackingReport
    });
  }
  onChangeDows(field, dow, e) {
    const {
      0: current0,
      1: current1,
      2: current2,
      3: current3,
      4: current4,
      5: current5,
      6: current6,
    } = this.props[field];
    const val = {
      0: current0,
      1: current1,
      2: current2,
      3: current3,
      4: current4,
      5: current5,
      6: current6,
      [dow]: e.target.checked,
    };
    this.props.onChange({
      [field]: val,
    });
  }
  onChangeEmployeeCommission(employeeId, cat, field, e) {
    const valid = field === 'method' || /^(|\d+(\.\d{0,2}){0,1})$/.test(e) === true;
    if (valid === true) {
      this.props.onChangeEmployeeCommission(employeeId, cat, field, e);
    }
  }
  onToggleEmployeeAbility(employeeId, field, e) {
    this.props.onChangeEmployeeAbility(employeeId, {
      [field]: e.target.checked
    });
  }
  onDelete() {
    const {
      onDelete, id
    } = this.props;
    onDelete(id);
  }
  onSave() {
    const {
      onSave,
      id, name, address, pin: _pin,
      partsFrom, partsCc,
      assessmentDows, repairDows, pickupDows, fitmentDows,
      includeInLocationTrackingReport,
      employees: _employees
    } = this.props;
    const pin = (_pin || '').trim() || null;
    const employees = _employees.map(e => ({
      ...e,
      abilities: TASKS.reduce((acc, s) => (
        s.reduce((acc, {key}) => {
          acc[key] = e.abilities[key] === true;
          return acc;
        }, acc)
      ), {})
    }));
    onSave(id, {
      name, address, pin,
      partsFrom, partsCc,
      assessmentDows, repairDows,
      pickupDows, fitmentDows,
      includeInLocationTrackingReport,
      employees
    });
  }
  renderCommissionCategory(employeeId, category, method, percentage, fixedRr, fixedRepair) {
    return (
      <div className='row mb-2'>
        <div className='col-1 text-muted align-self-center'>{category}</div>
        <div className='col-2'>
          <Select
            className='flex-grow-1 mb-0'
            options={['FIXED', 'PERCENTAGE']}
            selected={method}
            onChange={this.onChangeEmployeeCommission.bind(this, employeeId, category, 'method')}
          />
        </div>
        <div className='col-3'>
          <Field
            value={percentage}
            onChange={this.onChangeEmployeeCommission.bind(this, employeeId, category, 'percentage')} />
        </div>
        <div className='col-3'>
          <Field
            value={fixedRr}
            onChange={this.onChangeEmployeeCommission.bind(this, employeeId, category, 'fixedRr')} />
        </div>
        <div className='col-3'>
          <Field
            value={fixedRepair}
            onChange={this.onChangeEmployeeCommission.bind(this, employeeId, category, 'fixedRepair')} />
        </div>
      </div>
    );
  }
  renderEmployee(e, i) {
    const {
      id: employeeId,
      abilities,
      commission
    } = e;
    const {
      managerCommission,
      0: {
        method: cat0Method,
        percentage: cat0Percentage,
        fixedRr: cat0FixedRr,
        fixedRepair: cat0FixedRepair
      },
      1: {
        method: cat1Method,
        percentage: cat1Percentage,
        fixedRr: cat1FixedRr,
        fixedRepair: cat1FixedRepair
      },
      2: {
        method: cat2Method,
        percentage: cat2Percentage,
        fixedRr: cat2FixedRr,
        fixedRepair: cat2FixedRepair
      },
      3: {
        method: cat3Method,
        percentage: cat3Percentage,
        fixedRr: cat3FixedRr,
        fixedRepair: cat3FixedRepair
      },
      4: {
        method: cat4Method,
        percentage: cat4Percentage,
        fixedRr: cat4FixedRr,
        fixedRepair: cat4FixedRepair
      },
      5: {
        method: cat5Method,
        percentage: cat5Percentage,
        fixedRr: cat5FixedRr,
        fixedRepair: cat5FixedRepair
      },
      6: {
        method: cat6Method,
        percentage: cat6Percentage,
        fixedRr: cat6FixedRr,
        fixedRepair: cat6FixedRepair
      },
      7: {
        method: cat7Method,
        percentage: cat7Percentage,
        fixedRr: cat7FixedRr,
        fixedRepair: cat7FixedRepair
      },
    } = commission;
    const {employeeIndex, onDeleteEmployee} = this.props;
    const {toggleState} = this.state;
    const expanded = toggleState[employeeId];
    const ToggleButton = expanded ? CollapseButton : ExpandButton;
    return (
      <Card className='mt-2' key={i}>
        <div className='d-flex flex-row'>
          <ToggleButton
            className='mt-1'
            onClick={this.onToggleEmployee.bind(this, employeeId)}
            width={16}
            height={16}
          />
          <div className='mx-2 flex-grow-1'>
            <div
              className='font-weight-bold cursor-pointer'
              onClick={this.onToggleEmployee.bind(this, employeeId)}
            >{employeeIndex[employeeId].name}</div>
            {expanded === true && (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className='col-4'>
                    <Field
                      title='Manager commission'
                      value={managerCommission}
                      onChange={this.onChangeEmployeeCommission.bind(this, employeeId, null, 'managerCommission')} />
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-1 text-muted align-self-center'></div>
                  <div className='col-2 text-muted'><small>Method</small></div>
                  <div className='col-3 text-muted'><small>Percentage</small></div>
                  <div className='col-3 text-muted'><small>Fixed (R/R)</small></div>
                  <div className='col-3 text-muted'><small>Fixed (repair)</small></div>
                </div>
                {this.renderCommissionCategory(employeeId, 0, cat0Method, cat0Percentage, cat0FixedRr, cat0FixedRepair)}
                {this.renderCommissionCategory(employeeId, 1, cat1Method, cat1Percentage, cat1FixedRr, cat1FixedRepair)}
                {this.renderCommissionCategory(employeeId, 2, cat2Method, cat2Percentage, cat2FixedRr, cat2FixedRepair)}
                {this.renderCommissionCategory(employeeId, 3, cat3Method, cat3Percentage, cat3FixedRr, cat3FixedRepair)}
                {this.renderCommissionCategory(employeeId, 4, cat4Method, cat4Percentage, cat4FixedRr, cat4FixedRepair)}
                {this.renderCommissionCategory(employeeId, 5, cat5Method, cat5Percentage, cat5FixedRr, cat5FixedRepair)}
                {this.renderCommissionCategory(employeeId, 6, cat6Method, cat6Percentage, cat6FixedRr, cat6FixedRepair)}
                {this.renderCommissionCategory(employeeId, 7, cat7Method, cat7Percentage, cat7FixedRr, cat7FixedRepair)}
                <hr className='mt-3 mb-0' />
                {TASKS.map((s, i) => (
                  <React.Fragment key={i}>
                    {i > 0 && <hr className='mt-2 mb-0' />}
                    <div className='row'>
                      {s.map(({key, text}, j) => {
                        const idx = `${key}-${employeeId}`;
                        return (
                          <div className='col-3 mt-2' key={j}>
                            <div className='form-group form-check mb-0'>
                              <input
                                type='checkbox'
                                id={idx}
                                className='form-check-input'
                                checked={abilities[key] === true}
                                onChange={
                                  this.onToggleEmployeeAbility.bind(this, employeeId, key)
                                }
                              />
                              <label
                                htmlFor={idx}
                                className='form-check-label'
                              >{text}</label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </div>
          <DeleteButton
            className='mt-1'
            onClick={() => onDeleteEmployee(employeeId)}
            width={16} height={16} />
        </div>
      </Card>
    );
  }
  render() {
    console.log('settingslocation:render');
    const {
      id,
      name, nameError,
      address, addressError,
      pin, pinError,
      partsFrom, partsFromError,
      partsCc, partsCcError,
      assessmentDows, repairDows,
      pickupDows, fitmentDows,
      includeInLocationTrackingReport,
      employees,
      employeeList,
      onClose
    } = this.props;
    const {selectedEmployee} = this.state;
    return (
      <React.Fragment>
        <div className='modal-body'>
          <Field
            title='Name'
            uppercaseOnly={true}
            background={colors.grey1}
            value={name}
            error={nameError}
            onChange={this.onChange.bind(this, 'name')}
          />
          <Field
            title='Address'
            background={colors.grey1}
            value={address}
            error={addressError}
            onChange={this.onChange.bind(this, 'address')}
          />
          <Field
            title='Pin'
            background={colors.grey1}
            value={pin}
            error={pinError}
            onChange={this.onChange.bind(this, 'pin')}
          />
          <Field
            title='Parts from'
            background={colors.grey1}
            value={partsFrom}
            error={partsFromError}
            onChange={this.onChange.bind(this, 'partsFrom')}
          />
          <Field
            title='Parts cc'
            background={colors.grey1}
            value={partsCc}
            error={partsCcError}
            onChange={this.onChange.bind(this, 'partsCc')}
          />
          <div className='my-3'>
            <button
              className={
                includeInLocationTrackingReport ?
                  'btn btn-secondary w-100' :
                  'btn btn-outline-secondary w-100'
              }
              onClick={this.onToggleIncludeInLocationTrackingReport}
            >{
              includeInLocationTrackingReport ?
                'Include in location tracking report' :
                'Do not include in location tracking report'
            }</button>
          </div>
          <div>
            <small className='text-muted'>Assessment days</small>
            <div className='row'>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((a, j) => (
                <div className='col-3 mt-2' key={j}>
                  <div className='form-group form-check mb-0'>
                    <input
                      type='checkbox'
                      id={`assessment-dows-${j}`}
                      className='form-check-input'
                      checked={assessmentDows[j] === true}
                      onChange={this.onChangeDows.bind(this, 'assessmentDows', j)}
                    />
                    <label
                      htmlFor={`assessment-dows-${j}`}
                      className='form-check-label'
                    >{a}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <small className='text-muted'>Repair days</small>
            <div className='row'>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((a, j) => (
                <div className='col-3 mt-2' key={j}>
                  <div className='form-group form-check mb-0'>
                    <input
                      type='checkbox'
                      id={`repair-dows-${j}`}
                      className='form-check-input'
                      checked={repairDows[j] === true}
                      onChange={this.onChangeDows.bind(this, 'repairDows', j)}
                    />
                    <label
                      htmlFor={`repair-dows-${j}`}
                      className='form-check-label'
                    >{a}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <small className='text-muted'>Pickup days</small>
            <div className='row'>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((a, j) => (
                <div className='col-3 mt-2' key={j}>
                  <div className='form-group form-check mb-0'>
                    <input
                      type='checkbox'
                      id={`pickup-dows-${j}`}
                      className='form-check-input'
                      checked={pickupDows[j] === true}
                      onChange={this.onChangeDows.bind(this, 'pickupDows', j)}
                    />
                    <label
                      htmlFor={`pickup-dows-${j}`}
                      className='form-check-label'
                    >{a}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <small className='text-muted'>Fitment days</small>
            <div className='row'>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((a, j) => (
                <div className='col-3 mt-2' key={j}>
                  <div className='form-group form-check mb-0'>
                    <input
                      type='checkbox'
                      id={`fitment-dows-${j}`}
                      className='form-check-input'
                      checked={fitmentDows[j] === true}
                      onChange={this.onChangeDows.bind(this, 'fitmentDows', j)}
                    />
                    <label
                      htmlFor={`fitment-dows-${j}`}
                      className='form-check-label'
                    >{a}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h6 className='mt-3'>Employees</h6>
          <div className='d-flex flex-row justify-content-between'>
            <Select
              className='flex-grow-1 mr-2'
              options={employeeList}
              textField='name'
              background={colors.grey1}
              selected={selectedEmployee}
              onChange={this.onSelectEmployee}
            />
            <Button
              className='btn btn-primary'
              color={colors.white}
              onClick={this.onAddEmployee}
            ><Icons.Plus width={18} height={18} /></Button>
          </div>
          {employees.map(this.renderEmployee.bind(this))}
          {employees.length === 0 && (
            <div className='mt-2 text-muted'>Location doesn't have any employees yet</div>
          )}
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button type='button' className='btn btn-danger mr-auto' onClick={this.onDelete}>Delete</button>
          }
          <button type='button' className='btn btn-secondary' onClick={onClose}>Close</button>
          <button type='button' className='btn btn-primary' onClick={this.onSave}>Save</button>
        </div>
      </React.Fragment>
    );
  }
};
