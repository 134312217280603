import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const SaveButton = styled.button`
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.div`
  margin-bottom: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;
const Row = styled.div`
  background: ${opts => opts.isPaid === true ? 'white' : colors.redExtraLight};
`;


export default class ExcessPayments extends React.Component {
  constructor(opts) {
    super(opts);
    this.onSearch = this.onSearch.bind(this);
    this.renderInvoice = this.renderInvoice.bind(this);
  }
  componentDidMount() {
    const {stormId, onLoad} = this.props;
    if (stormId !== null) {
      onLoad(stormId);
    }
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onSearch() {
    const {stormId, onLoad} = this.props;
    onLoad(stormId);
  }
  onSave(jobId) {
    this.props.onSave(jobId)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while updating invoice.');
        } else {
          toast.success('Invoice successfully updated.');
        }
      });
  }
  renderInvoice(inv, i) {
    return (
      <Item key={i}>
        <Row className='p-2 rounded' isPaid={inv.isPaid}>
          <div className='row'>
            <div className='col-1 mb-2 mb-lg-0 align-self-center'>
              <Link to={`/job/${inv.jobId}`}>
                <strong className='text-body'>{inv.jobNumber}</strong>
              </Link>
            </div>
            <div className='col-2 mb-2 mb-lg-0 align-self-center'>
              {inv.claimNumber}
            </div>
            <div className='col-1 align-self-center'>
              {inv.excessInvoiceDateStr}
            </div>
            <div className='col-1 align-self-center'>
              {inv.excessInvoiceNumber}
            </div>
            <div className='col-2 align-self-center'>{inv.excessAmountStr}</div>
            <div className='col-2 align-self-center'>{inv.outstandingAmountStr}</div>
            <div className='col-1'>
              {inv.isPaid === true && (
                <SaveButton
                  type='button'
                  className='btn btn-primary'
                  onClick={this.onSave.bind(this, inv.jobId)}
                >
                  <Icons.Check width={18} height={18} />
                </SaveButton>
              )}
            </div>
          </div>
        </Row>
      </Item>
    );
  }
  render() {
    console.log('excesspayments:render');
    const {notFound, stormId, invoices, stormList} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load payments.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h4 className='mb-0'>Excess payments</h4>
          <div className='d-flex flex-row align-items-center'>
            <Select
              className='mr-2'
              placeholder='select a storm'
              background={colors.grey1}
              selected={stormId}
              options={stormList}
              textField='name'
              onChange={this.props.onChangeStormId}
            />
            <SearchButton
              type='button'
              className='btn btn-primary ml-2'
              disabled={!stormId}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3'>
            {invoices.length === 0 && (
              <div className='text-center text-secondary'>No excess invoices found.</div>
            )}
            {invoices.length !== 0 && (
              <React.Fragment>
                <div className='row mb-2 px-2 d-none d-lg-flex'>
                  <div className='col-1 font-weight-bold'>Job</div>
                  <div className='col-2 font-weight-bold'>Debtor</div>
                  <div className='col-1 font-weight-bold'>Inv date</div>
                  <div className='col-1 font-weight-bold'>Inv #</div>
                  <div className='col-2 font-weight-bold'>Excess amount</div>
                  <div className='col-2 font-weight-bold'>Outstanding amount</div>
                  <div className='col-1 font-weight-bold'>
                    <SaveButton
                      type='button'
                      className='btn btn-primary my-0 py-0 invisible'
                    >
                      <Icons.Save width={18} height={18} />
                    </SaveButton>
                  </div>
                </div>
                {invoices.map(this.renderInvoice)}
              </React.Fragment>
            )}
          </Card>
        )}
      </Container>
    );
  };
};

