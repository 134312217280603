import moment from 'm';

import TruncateString from 'utils/truncate-string.js';


let currentState = null;
const cs = window.localStorage.getItem('booking-schedule:stormId');
if (cs) {
  currentState = JSON.parse(cs);
}

export default (state = {
  notFound: null,
  stormId: currentState,
  start: moment().day(0).hour(0).minute(0).second(0).millisecond(0).unix(),
  finish: moment().day(0).add(13, 'days').hour(0).minute(0).second(0).millisecond(0).unix(),
  bookings: []
}, action) => {
  switch (action.type) {
    case 'BOOKING-SCHEDULE-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        bookings: []
      };
    case 'BOOKING-SCHEDULE-RESET':
      return {
        notFound: null,
        stormId: currentState,
        start: moment().day(0).hour(0).minute(0).second(0).millisecond(0).unix(),
        finish: moment().day(0).add(13, 'days').hour(0).minute(0).second(0).millisecond(0).unix(),
        bookings: []
      };
    case 'BOOKING-SCHEDULE-CHANGE-STORM-ID':
      const {stormId} = action;
      window.localStorage.setItem('booking-schedule:stormId', JSON.stringify(stormId));
      currentState = stormId;
      return {
        ...state,
        stormId,
      };
    case 'BOOKING-SCHEDULE-SET':
    {
      const FLAGS = {
        null: 'gray',
        pdr: 'greenExtraLight',
        paint: 'blueExtraLight',
        turret: 'orangeExtraLight'
      };
      const {
        start,
        finish,
        bookings: _bookings,
        numPdr,
        numPaint,
        numTurret,
      } = action;
      const bookingsIndex = _bookings.reduce((acc, b) => {
        b.jobs.forEach(j => {
          j.flag = FLAGS[j.bookingType];
          j.startDateStr = moment(j.startDate * 1000).format('DD-MM');
          j.finishDateStr = moment(j.finishDate * 1000).format('DD-MM');
          j.makeModel = TruncateString([j.make, j.model].join(' '));
        });
        acc[b.bookingDate] = b;
        return acc;
      }, {});
      const bookings = Array.apply(null, new Array(Math.floor((finish - start) / 86400) + 1))
        .map((_, i) => {
          const date = moment(start * 1000).add(i, 'days');
          return {
            bookingDateStr: date.format('ddd Do MMM'),
            ...(bookingsIndex[date.unix()] || {numPdr: 0, numPaint: 0, numTurret: 0, overbooked: false, jobs: []})
          };
        });
      return {
        ...state,
        notFound: false,
        start,
        finish,
        bookings,
        numPdr,
        numPaint,
        numTurret,
      };
    }
    case 'BOOKING-SCHEDULE-CHANGE-DATE-RANGE':
    {
      const {change} = action;
      return {
        ...state,
        ...change
      };
    }
    default:
      return state;
  }
};
