import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';
import Tow from 'icons/tow.jsx';
import Van from 'icons/van.jsx';


const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;

export default class Status extends React.Component {
  constructor(opts) {
    super(opts);
    this.onToggleIsIn = this.onToggle.bind(this, 'isIn');
    this.onToggleIsOut = this.onToggle.bind(this, 'isOut');
    this.onToggleOutOfScope = this.onToggle.bind(this, 'outOfScope');
    this.onToggleAutoOrmEnabled = this.onToggle.bind(this, 'autoOrmEnabled');
    this.onToggleTotalLoss = this.onToggle.bind(this, 'totalLoss');
    this.onToggleCashSettle = this.onToggle.bind(this, 'cashSettle');
    this.onToggleAuthorised = this.onToggle.bind(this, 'authorised');
    this.onToggleConvVan = this.onToggle.bind(this, 'convVan');
    this.onToggleConvTow = this.onToggle.bind(this, 'convTow');
    this.onToggleConvIsOut = this.onToggle.bind(this, 'convIsOut');
    this.onToggleConvIsIn = this.onToggle.bind(this, 'convIsIn');
  }
  onToggle(field) {
    this.props.onChange({
      [field]: !this.props[field]
    });
  }
  render() {
    console.log('status:render');
    const {
      visible,
      hasOrm,
      hasPainter,
      isIn,
      isOut,
      outOfScope,
      canOutOfScope,
      canOrm,
      autoOrmEnabled,
      totalLoss,
      cashSettle,
      canStatus,
      authorised,
      canConv,
      convVan,
      convTow,
      convIsOut,
      convIsIn
    } = this.props;
    if (visible !== true) return null;
    return (
      <Card className='p-3 mt-3'>
        <div className='row'>
          <div className='col-sm-6 col-lg-3'>
            <small className='text-muted'>Status</small>
            <div className='row no-gutters'>
              <div className='col mr-1'>
                <button
                  type='button'
                  className={isIn ?
                    'btn btn-secondary w-100' :
                    'btn btn-outline-secondary w-100'
                  }
                  onClick={this.onToggleIsIn}
                >In</button>
              </div>
              <div className='col mx-1'>
                <button
                  type='button'
                  className={isOut ?
                    'btn btn-secondary w-100' :
                    'btn btn-outline-secondary w-100'
                  }
                  onClick={this.onToggleIsOut}
                >Out</button>
              </div>
            </div>
          </div>
          {(canOutOfScope === true || canOrm === true) && (
            <div className='col-sm-6 col-lg-3'>
              <div className='row no-gutters mx-n1'>
                {canOutOfScope === true && (
                  <div className='col mx-1'>
                    <small className='text-muted'>Out of scope</small>
                    <button
                      type='button'
                      className={outOfScope ?
                        'btn btn-secondary w-100' :
                        'btn btn-outline-secondary w-100'
                      }
                      disabled={canStatus !== true}
                      onClick={this.onToggleOutOfScope}
                    >OoS</button>
                  </div>
                )}
                {canOrm === true && (
                  <div className='col mx-1'>
                    <small className='text-muted'>ORM</small>
                    <button
                      type='button'
                      className={autoOrmEnabled ?
                        'btn btn-secondary w-100' :
                        'btn btn-outline-secondary w-100'
                      }
                      onClick={this.onToggleAutoOrmEnabled}
                    >A-ORM</button>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className='col-sm-6 col-lg-3'>
            <small className='text-muted'>Outcome</small>
            <div className='row no-gutters'>
              <div className='col mr-1'>
                <button
                  type='button'
                  className={totalLoss ?
                    'btn btn-secondary w-100' :
                    'btn btn-outline-secondary w-100'
                  }
                  disabled={canStatus !== true}
                  onClick={this.onToggleTotalLoss}
                >T/L</button>
              </div>
              <div className='col ml-1'>
                <button
                  type='button'
                  className={cashSettle ?
                    'btn btn-secondary w-100' :
                    'btn btn-outline-secondary w-100'
                  }
                  disabled={canStatus !== true}
                  onClick={this.onToggleCashSettle}
                >C/S</button>
              </div>
              <div className='col ml-1'>
                <button
                  type='button'
                  disabled={hasOrm === true || canOrm === true}
                  className={authorised ?
                    'btn btn-secondary w-100' :
                    'btn btn-outline-secondary w-100'
                  }
                  onClick={this.onToggleAuthorised}
                >Auth'ed</button>
              </div>
            </div>
          </div>
          {canConv === true && (
            <div className='col-sm-6 col-lg-3'>
              <small className='text-muted'>Conventional</small>
              <div className='row no-gutters'>
                <div className='col mr-1'>
                  <div className='btn-group w-100'>
                    <button
                      type='button'
                      className={convVan ?
                        'btn btn-secondary w-100' :
                        'btn btn-outline-secondary w-100'
                      }
                      disabled={true}
                      onClick={this.onToggleConvVan}
                    ><Van width={18} height={18} /></button>
                    <button
                      type='button'
                      className={convTow ?
                        'btn btn-secondary w-100' :
                        'btn btn-outline-secondary w-100'
                      }
                      disabled={true}
                      onClick={this.onToggleConvTow}
                    ><Tow width={18} height={18} /></button>
                  </div>
                </div>
                <div className='col ml-1'>
                  <div className='btn-group w-100'>
                    <button
                      type='button'
                      className={convIsOut ?
                        'btn btn-secondary w-100' :
                        'btn btn-outline-secondary w-100'
                      }
                      disabled={true /*hasPainter !== true*/}
                      onClick={this.onToggleConvIsOut}
                    >
                      <Icons.LogOut width={18} height={18} />
                    </button>
                    <button
                      type='button'
                      className={convIsIn ?
                        'btn btn-secondary w-100' :
                        'btn btn-outline-secondary w-100'
                      }
                      disabled={hasPainter !== true || convIsOut !== true}
                      onClick={this.onToggleConvIsIn}
                    >
                      <Icons.LogIn width={18} height={18} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    );
  }
};
