import React from 'react';
import * as Icons from 'react-feather';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';

import JobTitle from '../JobTitle';
import ImageList from '../ImageList';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Button = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.div`
  margin-bottom: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;
const Note = styled.div`
  white-space: pre-wrap;
`;

const NOTE_TEMPLATES = [
  {id: 'customer-contacted', text: 'Customer contacted'},
  {id: 'customer-contacted-left-message', text: 'Customer contacted - left message'},
  {id: 'customer-contacted-no-answer', text: 'Customer contacted - no answer'},
];

export default class Notes extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      template: null,
      note: '',
      repxNote: '',
    };
    this.onChangeTemplate = this.onChange.bind(this, 'template');
    this.onChangeNote = this.onChange.bind(this, 'note');
    this.onChangeRepxNote = this.onChange.bind(this, 'repxNote');
    this.onAddNote = this.onAddNote.bind(this);
    this.onAddRepxNote = this.onAddRepxNote.bind(this);
  }
  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {jobId, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
  }
  onChange(field, e) {
    if (field === 'template') {
      this.setState({
        template: e,
        note: e === null ? this.state.note : NOTE_TEMPLATES.find(n => n.id === e).text,
      });
    } else {
      this.setState({
        template: null,
        [field]: e,
      });
    }
  }
  onAddNote(e) {
    const {template: type, note} = this.state;
    const {jobId, onAddNote} = this.props;
    onAddNote(jobId, type, note)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while adding notes.');
        } else {
          this.setState({
            template: null,
            note: '',
          });
          toast.success('Notes successfully added.');
        }
      });
  }
  onAddRepxNote(e) {
    const {repxNote} = this.state;
    const {jobId, onAddRepxNote} = this.props;
    onAddRepxNote(jobId, repxNote)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while adding repx notes.');
        } else {
          this.setState({
            repxNote: ''
          });
          toast.success('Repx notes successfully added.');
        }
      });
  }
  render() {
    console.log('notes:render');
    const {jobValid} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }

    const {
      jobId,
      jobNumber,
      flagged,
      vpp,
      vip,
      importUrl,
      notes,
      repxNotes,
      employeesIndex
    } = this.props;
    const {template, note, repxNote} = this.state;
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        <ImageList jobId={jobId} />
        <div className='row no-gutters'>
          <div className='col-sm-12 col-md-6 pr-md-2 mt-3'>
            <Card className='p-3'>
              <div className='font-weight-bold mb-2'>Notes</div>
              <div className='d-flex flex-row justify-content-between mb-3'>
                <Select
                  className='mb-0 mr-2'
                  placeholder='select a template'
                  options={NOTE_TEMPLATES}
                  selected={template}
                  onChange={this.onChangeTemplate}
                />
                <Field
                  className='flex-grow-1 mb-0 mr-2'
                  value={note}
                  onChange={this.onChangeNote}
                  readOnly={template != null}
                />
                <Button
                  className='btn btn-primary'
                  type='button'
                  onClick={this.onAddNote}
                  disabled={note === ''}
                ><Icons.Plus width={18} height={18} /></Button>
              </div>
              {notes.length === 0 && (
                <div className='text-center text-secondary'>No notes yet.</div>
              )}
              {notes.length !== 0 && notes.map((n, i) => (
                <Item key={i}>
                  <small className='text-muted'>{employeesIndex[n.employeeId].name} {'\u2013'} {n.ts}</small>
                  <Note>{n.note}</Note>
                </Item>
              ))}
            </Card>
          </div>
          <div className='col-sm-12 col-md-6 pl-md-2 mt-3'>
            <Card className='p-3'>
              <div className='font-weight-bold mb-2'>RepX notes</div>
              <div className='d-flex flex-row justify-content-between mb-3'>
                <Field
                  className='flex-grow-1 mb-0 mr-2'
                  value={repxNote}
                  onChange={this.onChangeRepxNote}
                />
                <Button
                  className='btn btn-primary'
                  type='button'
                  onClick={this.onAddRepxNote}
                  disabled={repxNote === ''}
                ><Icons.Plus width={18} height={18} /></Button>
              </div>
              {repxNotes.length === 0 && (
                <div className='text-center text-secondary'>No repx notes yet.</div>
              )}
              {repxNotes.length !== 0 && repxNotes.map((n, i) => (
                <Item key={i}>
                  <small className='text-muted'>{n.author} {'\u2013'} {n.ts}</small>
                  <Note>{n.text}</Note>
                </Item>
              ))}
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  };
};

