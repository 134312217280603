import moment from 'm';

import TruncateString from 'utils/truncate-string.js';


let sectionStates = {};
const cv = window.localStorage.getItem('tracking:sectionStates');
if (cv) {
  sectionStates = JSON.parse(cv);
}


const SECTIONS = [
  {title: 'Unallocated', key: 'jobsUnallocated'},
  {title: 'Cancelled', key: 'jobsCancelled', showPartsFlags: true},
  {title: 'New', key: 'jobsNew', showContacted: true},
  {title: 'Waiting for assessment', key: 'jobsToBeAssessed', showAssessmentTime: true, showZeroValueFlag: true},
  {title: 'Assessing today', key: 'jobsAssessing', showAssessmentTime: true, showZeroValueFlag: true},
  {title: 'Assessment no show', key: 'jobsAssessNoShow', showAssessmentTime: true},
  {title: 'Flagged', key: 'jobsFlagged'},
  {title: 'To book for repairs', key: 'jobsToBook'},
  {title: 'Due in', key: 'jobsDueIn'},
  {title: 'Arriving today and tomorrow', key: 'jobsArriving'},
  {title: 'Repair no show', key: 'jobsNoShow'},
  {title: 'In production', key: 'jobsInProduction', showPartsFlags: true},
  {title: 'To arrange ready time', key: 'jobsToArrangeDetail'},
  {title: 'To arrange pickup time', key: 'jobsToArrangePickup'},
  {title: 'Due out', key: 'jobsLeaving'},
  {title: 'Waiting for backorder part fitment', key: 'jobsWaitingForFitment', showFitmentTime: true},
  {title: 'To be sent for authority', key: 'jobsToQuote', showNeedsPartsFlag: true},
  {title: 'Waiting for authority', key: 'jobsWaitingForAuthority'},
  {title: 'Authority declined', key: 'jobsAuthorityDeclined'},
  {title: 'To be invoiced', key: 'jobsToInvoice'},
  {title: 'Waiting for payment authority', key: 'jobsWaitingForPaymentAuthority'},
  {title: 'Payment declined', key: 'jobsPaymentDeclined'},
  {title: 'Waiting for payment', key: 'jobsWaitingForPayment'},
];

export default (state = {
  notFound: null,
  sections: [],
  sectionStates
}, action) => {
  switch (action.type) {
    case 'TRACKING-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        sections: []
      };
    case 'TRACKING-SET':
    {
      const sections = SECTIONS.map(s => {
        const jobs = action[s.key].map(j => {
          let alertColor = null;
          if (j.flagged === true) {
            alertColor = 'red';
          } else if (j.vpp === true) {
            alertColor = 'orange';
          } else if (j.vip === true) {
            alertColor = 'yellow';
          }
          let flag = 'white';
          if (s.showPartsFlags === true && j.hasParts === true) {
            if (j.hasReceivedAllParts === true) {
              flag = 'greenExtraLight';
            } else if (j.hasOrderedAllParts === true) {
              flag = 'purpleExtraLight';
            } else {
              flag = 'orangeExtraLight';
            }
          } else if (s.showContacted === true) {
            if (j.numTimesContacted > 0) {
              flag = 'blueExtraLight';
            } else if (j.newNotificationSmsSent === true) {
              flag = 'tealExtraLight';
            }
          }
          return {
            ...j,
            alertColor,
            flag,
            rego: j.rego !== '' ? j.rego : 'N/A',
            claimNumber: j.claimNumber !== '' ? j.claimNumber : 'N/A',
            makeModel: TruncateString([j.make || '', j.model || ''].join(' ')),
            startDateStr: j.startDate !== null ? moment(j.startDate * 1000).format('DD-MM') : 'N/A',
            finishDateStr: j.finishDate !== null ? moment(j.finishDate * 1000).format('DD-MM') : 'N/A',
            showContacted: s.showContacted === true,
            showAssessmentTime: s.showAssessmentTime === true,
            assessmentTime: s.showAssessmentTime === true && moment(j.assessmentDate * 1000).format('DD-MM HH:mm'),
            showFitmentTime: s.showFitmentTime === true,
            showZeroValueFlag: s.showZeroValueFlag === true && j.zeroInsuredValue === true,
            showNeedsPartsFlag: s.showNeedsPartsFlag === true && j.needsParts === true,
            fitmentTime: s.showFitmentTime === true && (
              j.fitmentDate !== null ? moment(j.fitmentDate * 1000).format('DD-MM HH:mm') : 'N/A'
            ),
            subtotal: j.subtotal !== null ? (j.subtotal / 100) : 'N/A',
            total: j.total !== null ? (j.total / 100) : 'N/A',
            totalLessExcess: j.total > 0 ? (j.total - j.excess) / 100 : 0,
            mailbox: j.mailbox !== null ? j.mailbox : 'N/A',
          };
        });
        return {
          title: s.title,
          key: s.key,
          sheetTitle: s.key.replace('jobs', ''),
          jobs,
          numJobs: jobs.length
        };
      });
      return {
        ...state,
        notFound: false,
        sections,
      };
    }
    case 'TRACKING-SECTION-TOGGLE':
    {
      const {section} = action;
      const newSectionStates = {
        ...state.sectionStates,
        [section]: !state.sectionStates[section]
      };
      window.localStorage.setItem('tracking:sectionStates', JSON.stringify(newSectionStates));
      return {
        ...state,
        sectionStates: newSectionStates
      };
    }
    default:
      return state;
  }
};
