import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;


export default class Status extends React.Component {
  componentDidMount() {
    const {onLoad} = this.props;
    onLoad();
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  render() {
    console.log('status:render');
    const {notFound, mailboxes} = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load status.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 p-3'>
        <h4 className='mb-3'>Status</h4>
        <Card className='p-3'>
          {mailboxes.length === 0 && (
            <div className='text-center text-secondary'>No status found.</div>
          )}
          {mailboxes.length !== 0 && (
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th className='border-top-0'>ID</th>
                  <th className='border-top-0'>Name</th>
                  <th className='border-top-0'>Last sync</th>
                </tr>
              </thead>
              <tbody>
                {mailboxes.map((m, i) => (
                  <tr key={i} className={m.alive === true ? 'alert-success' : 'alert-danger'}>
                    <td>{m.id}</td>
                    <td>{m.name}</td>
                    <td>{m.ts}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Card>
      </Container>
    );
  };
};

