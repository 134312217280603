import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Dashboard from './component.jsx';


const load = (API, stormId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    const url = stormId ?
      `${env.API_SERVER}/v1/debtor/dashboard/${stormId}` :
      `${env.API_SERVER}/v1/debtor/dashboard`;
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'DEBTOR-DASHBOARD-SET-NOT-FOUND'
          });
          return null;
        }
        const {storms, sections} = resp;
        dispatch({
          type: 'DEBTOR-DASHBOARD-SET',
          storms,
          sections
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const changeStormId = stormId => ({
  type: 'DEBTOR-DASHBOARD-STORM-ID-SET',
  stormId,
});


const mapStateToProps = (state, props) => {
  return state.debtorDashboard;
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: stormId => dispatch(load(API, stormId)),
  onChangeStormId: stormId => dispatch(changeStormId(stormId)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
