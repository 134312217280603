import React from 'react';
import * as Icons from 'react-feather';
import {Map as LeafletMap, TileLayer, Tooltip as LeafletTooltip, CircleMarker, Popup} from 'react-leaflet';
import {
  Bar,
  BarChart,
  Legend,
  Line,
  LineChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import colors from 'colors';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  overflow-y: auto;
  font-size: 0.9rem;
`;
const Section = styled.div`
  border-radius: 0.5rem;
  background-color: ${colors.grey1};
  box-shadow: 0px 8px 15px -4px rgba(180, 180, 180, 0.5);
  :last-of-type {
    margin-bottom: 0px !important;
  }
  .leaflet-container {
    height: 500px;
  }
  .leaflet-tooltip {
    height: 25px;
    width: 25px;
    text-align: center;
    border-radius: 50%
  }
`;
const SectionTitle = styled.h5`
  line-height: 1.5;
`;


const DashboardMap = ({section}) => (
  <div className='col-12 mb-3'>
    <Section className='p-2'>
      <LeafletMap center={[-33.878774, 151.156819]} zoom={6}>
        <TileLayer
          url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />
        {section.data.map((storm, i) =>
          storm.points.map((p, j) => (
            <CircleMarker key={`${i}-${j}`} radius={5} center={[p.lat, p.long]} color={storm.color}>
              {p.count > 1 && <LeafletTooltip permanent>{p.count}</LeafletTooltip>}
              <Popup maxHeight={125}>
                {p.jobInfo.map(info => (
                  <div><a href={`https://app.autorms.com.au/job/${info.jobId}/details`} target='_blank' rel='noopener noreferrer'>{info.jobNumber}</a></div>
                ))}
              </Popup>
            </CircleMarker>
          ))
        )}
      </LeafletMap>
    </Section>
  </div>
);

const DashboardKPIs = ({locationIndex, section}) => (
  <div className='col-12 col-md-6 mb-3'>
    <Section className='p-2'>
      <SectionTitle className='text-primary text-center'>KPIs</SectionTitle>
      <ResponsiveContainer width='100%' height={480}>
        <RadarChart width={640} height={480} data={section.data}>
          <PolarGrid />
          <PolarAngleAxis dataKey='title' />
          {section.locations.map((l, j) => (
            <Radar
              key={j}
              name={locationIndex[l.id].name}
              dataKey={l.id}
              stroke={l.color}
              fill={l.color}
              fillOpacity={0.6}
              isAnimationActive={false}
            />
          ))}
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </Section>
  </div>
);

class DashboardSurvey extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      location: opts.section.locations[0],
      data: opts.section.list[0],
    };
    this.onChangeLocation = this.onChangeLocation.bind(this);
  }
  onChangeLocation(e) {
    this.setState({
      location: e.target.value,
      data: this.props.section.list[e.target.value],
    });
  }
  render() {
    const {locationIndex, section} = this.props;
    const {location, data} = this.state;
    return (
      <div className='col-12 mb-3'>
        <Section className='p-2'>
          <div className='row'>
            <div className='col-4 offset-4 my-auto'>
              <SectionTitle className='text-primary text-center mb-0'>Survey</SectionTitle>
            </div>
            <div className='col-3 offset-1'>
              <select
                className='form-control'
                value={location}
                onChange={this.onChangeLocation}
              >
                {section.locations.map((l, i) => (
                  <option key={i} value={i}>{locationIndex[l].name}</option>
                ))}
              </select>
            </div>
          </div>
          <ResponsiveContainer width='100%' height={480}>
            <LineChart width={640} height={480} syncId='progress' data={data.data}>
              <XAxis dataKey='week' />
              <YAxis />
              <Tooltip />
              {data.categories.map((c, k) => (
                <Line
                  key={k}
                  type='monotone'
                  dataKey={c.key}
                  stroke={c.color}
                  isAnimationActive={false}
                  dot={null}
                />
              ))}
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </Section>
      </div>
    );
  }
};

class DashboardProgressChart extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      location: opts.section.locations[0],
      data: opts.section.list[0],
    };
    this.onChangeLocation = this.onChangeLocation.bind(this);
  }
  onChangeLocation(e) {
    this.setState({
      location: e.target.value,
      data: this.props.section.list[e.target.value],
    });
  }
  render() {
    const {locationIndex, section} = this.props;
    const {location, data} = this.state;
    return (
      <div className='col-12 mb-3'>
        <Section className='p-2'>
          <div className='row'>
            <div className='col-4 offset-4 my-auto'>
              <SectionTitle className='text-primary text-center mb-0'>Progress</SectionTitle>
            </div>
            <div className='col-3 offset-1'>
              <select
                className='form-control'
                value={location}
                onChange={this.onChangeLocation}
              >
                {section.locations.map((l, i) => (
                  <option key={i} value={i}>{locationIndex[l].name}</option>
                ))}
              </select>
            </div>
          </div>
          <ResponsiveContainer width='100%' height={480}>
            <LineChart width={640} height={480} syncId='progress' data={data.data}>
              <XAxis dataKey='label' />
              <YAxis />
              <Tooltip />
              <Line
                type='monotone'
                dataKey='Allocations'
                stroke={data.allocationsColor}
                isAnimationActive={false}
                dot={null}
              />
              <Line
                type='monotone'
                dataKey='Cancellations'
                stroke={data.cancellationsColor}
                isAnimationActive={false}
                dot={null}
              />
              <Line
                type='monotone'
                dataKey='Contacts'
                stroke={data.contactsColor}
                isAnimationActive={false}
                dot={null}
              />
              <Line
                type='monotone'
                dataKey='Assessments'
                stroke={data.assessmentsColor}
                isAnimationActive={false}
                dot={null}
              />
              <Line
                type='monotone'
                dataKey='Booked'
                stroke={data.bookedColor}
                isAnimationActive={false}
                dot={null}
              />
              <Line
                type='monotone'
                dataKey='Remaining'
                stroke={data.remainingColor}
                isAnimationActive={false}
                dot={null}
              />
              <Legend />
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={480}>
            <LineChart width={640} height={480} syncId='progress' data={data.data}>
              <XAxis dataKey='label' />
              <YAxis />
              <Tooltip />
              {data.categories.map((c, k) => (
                <Line
                  key={k}
                  type='monotone'
                  dataKey={c.key}
                  stroke={c.color}
                  isAnimationActive={false}
                  dot={null}
                  strokeDasharray='5 5'
                />
              ))}
              {data.categories.map((c, k) => (
                <Line
                  key={data.categories.length + k}
                  type='monotone'
                  dataKey={`Finished ${c.key}`}
                  stroke={c.color}
                  isAnimationActive={false}
                  dot={null}
                  legendType='none'
                />
              ))}
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </Section>
      </div>
    );
  }
};

class DashboardFinishDates extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      location: opts.section.locations[0],
      data: opts.section.list[0],
    };
    this.onChangeLocation = this.onChangeLocation.bind(this);
  }
  onChangeLocation(e) {
    this.setState({
      location: e.target.value,
      data: this.props.section.list[e.target.value],
    });
  }
  render() {
    const {locationIndex, section} = this.props;
    const {location, data} = this.state;
    return (
      <div className='col-12 col-md-6 mb-3'>
        <Section className='p-2'>
          <div className='row'>
            <div className='col-4 offset-4 my-auto'>
              <SectionTitle className='text-primary text-center mb-0'>Finish dates</SectionTitle>
            </div>
            <div className='col-3 offset-1'>
              <select
                className='form-control'
                value={location}
                onChange={this.onChangeLocation}
              >
                {section.locations.map((l, i) => (
                  <option key={i} value={i}>{locationIndex[l].name}</option>
                ))}
              </select>
            </div>
          </div>
          <ResponsiveContainer width='100%' height={480}>
            <BarChart width={640} height={480} data={data.data}>
              <XAxis dataKey='week' />
              <YAxis />
              <Tooltip />
              {data.categories.map((c, k) => (
                <Bar
                  key={k}
                  dataKey={c.key}
                  stackId='a'
                  fill={c.color}
                  isAnimationActive={false}
                />
              ))}
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        </Section>
      </div>
    );
  }
};

class DashboardTechCommissions extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      location: opts.section.locations[0],
      data: opts.section.list[0],
    };
    this.onChangeLocation = this.onChangeLocation.bind(this);
  }
  onChangeLocation(e) {
    this.setState({
      location: e.target.value,
      data: this.props.section.list[e.target.value],
    });
  }
  render() {
    const {employeeIndex, locationIndex, section} = this.props;
    const {location, data} = this.state;
    return (
      <div className='col-12 col-md-6 mb-3'>
        <Section className='p-2'>
          <div className='row'>
            <div className='col-4 offset-4 my-auto'>
              <SectionTitle className='text-primary text-center mb-0'>Tech commissions</SectionTitle>
            </div>
            <div className='col-3 offset-1'>
              <select
                className='form-control'
                value={location}
                onChange={this.onChangeLocation}
              >
                {section.locations.map((l, i) => (
                  <option key={i} value={i}>{locationIndex[l].name}</option>
                ))}
              </select>
            </div>
          </div>
          <ResponsiveContainer width='100%' height={480}>
            <LineChart width={640} height={480} data={data.data}>
              <XAxis dataKey='week' />
              <YAxis />
              {data.employees.map((e, k) => (
                <Line
                  key={k}
                  type='monotone'
                  dataKey={e.key}
                  stroke={e.color}
                  isAnimationActive={false}
                />
              ))}
              <Legend
                payload={data.employees.map((e, k) => ({
                  value: employeeIndex[e.key].name,
                  type: 'line',
                  id: `ID${k}`,
                  color: e.color,
                }))}
              />
            </LineChart>
          </ResponsiveContainer>
        </Section>
      </div>
    );
  }
};


const SECTIONS = {
  map: DashboardMap,
  kpis: DashboardKPIs,
  progressChart: DashboardProgressChart,
  finishDates: DashboardFinishDates,
  techCommissions: DashboardTechCommissions,
  survey: DashboardSurvey,
};

export default class Dashboard extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
    };
  }
  componentDidMount() {
    const {onLoad} = this.props;
    onLoad();
  }
  render() {
    console.log('dashboard:render');
    const {notFound, sections, employeeIndex, locationIndex} = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load dashboard.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 p-3'>
        <SectionTitle className='mb-3'>Dashboard</SectionTitle>
        <div className='row mb-n3'>
          {sections.map((s, i) => {
            const S = SECTIONS[s.key];
            return (
              <S
                key={i}
                employeeIndex={employeeIndex}
                locationIndex={locationIndex}
                section={s}
              />
            );
          })}
        </div>
      </Container>
    );
  }
};
