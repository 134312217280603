import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import JobTitle from '../JobTitle';
import ImageList from '../ImageList';
import QaForm from './QaForm.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Item = styled.div`
  margin-bottom: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;
const Note = styled.div`
  white-space: pre-wrap;
`;
const Qa = styled.div`
  white-space: pre-wrap;
`

const QA_ENTRIES = [
  {
    type: 'mid-repair',
    condition: _ => true,
    entries: [
      {title: 'No missing dents found', negativeTitle: 'Missing dents found', key: 'missedDents'},
      {title: 'PDR Repairs to standard', negativeTitle: 'Poor PDR repairs', key: 'poorPDRRepairs'},
      {title: 'Access holes not damaged', negativeTitle: 'Damaged access holes', key: 'accessHoles'},
      {title: 'Turret braces not bent', negativeTitle: 'Bent turret braces', key: 'turretBracesBent'},
      {title: 'No tooling marks found under bonnet', negativeTitle: 'Tooling marks under bonnet', key: 'toolingMarksUnderBonnet'},
    ]
  },
  {
    type: 'final',
    condition: _ => true,
    entries: [
      {title: 'All parts have been fitted', negativeTitle: 'Not all parts fitted', key: 'hasFitted'},
      {title: 'Seatbelts adjusted and retract', negativeTitle: 'Seatbelts not adjusted', key: 'seatbeltsRetractAndAdjust'},
      {title: 'Aerial fitted and radio is working', negativeTitle: 'Aerial faulting', key: 'radioWorking'},
      {title: 'Bolts are brush touched', negativeTitle: 'Bolts not brush touched', key: 'brushTouchBolts'},
      {title: 'Trims fitted correctly', negativeTitle: 'Trims not fitted correctly', key: 'trimsFitted'},
      {title: 'No dirty marks in headliner', negativeTitle: 'Dirty marks on headliner', key: 'dirtyMarks'},
      {title: 'Sun visors working', negativeTitle: 'Sun visors not working', key: 'sunVisorsWorking'},
      {title: 'Panel gaps acceptable', negativeTitle: 'Panel gaps', key: 'panelGapsAcceptable'},
      {title: 'No unquoted cracked paint', negativeTitle: 'Unquoted cracked paint found', key: 'crackedPaintNotOnQuote'},
      {title: 'ADAS Completed/Images uploaded (if vehicle needs ADAS)', negativeTitle: 'ADAS not done', key: 'ADASCompleted'},
      {title: 'Vehicle scan completed', negativeTitle: 'Vehicle scan not performed', key: 'vehicleScan'},
      {title: 'Detail acceptable interior, exterior, tyre shine, glass', negativeTitle: 'Poor detailing', key: 'detailAcceptableInterior'},
    ]
  },
  {
    type: 'final',
    condition: bookingType =>  ['paint', 'turret'].indexOf(bookingType) !== -1,
    entries: [
      {title: 'Paint/colour acceptable', negativeTitle: 'Poor paint', key: 'colourAcceptable'},
      {title: 'No imperfections in clear', negativeTitle: 'Imperfections in clear', key: 'imperfectionsInClear'},
      {title: 'Compound', negativeTitle: 'Compound', key: 'compound'},
      {title: 'No aperture edges dry spray or hard edges', negativeTitle: 'Aperture or hard edges', key: 'apertureEdges'},
      {title: 'No ripply panels', negativeTitle: 'Ripply panels', key: 'ripplyPanels'},
      {title: 'No silicone spots', negativeTitle: 'Silicone spots', key: 'siliconeSpots'},
    ]
  },
];

const QA_TITLES = QA_ENTRIES.reduce((acc, e) => (
  e.entries.reduce((acc, r) => {
    acc[r.key] = r.negativeTitle;
    return acc;
  }, acc)
), {});


export default class Qas extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
    };
  }
  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {jobId, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
  }

  render() {
    console.log('qa:render');
    const {jobValid} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }
    const {
      jobId,
      jobNumber,
      bookingType,
      flagged,
      vpp,
      vip,
      importUrl,
      qas,
      employeesIndex,
      onQaAction
    } = this.props;
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        <ImageList jobId={jobId} />
        <Card className='p-3 mt-3'>
          <div className='d-flex flex-row justify-content-between mb-3'>
            <div className='font-weight-bold mb-2'>QAs</div>
            <div className='d-flex flex-row'>
              <QaForm
                type='mid-repair'
                jobId={jobId}
                entries={QA_ENTRIES}
                bookingType={bookingType}
                onSave={onQaAction}
              />
              <QaForm
                type='final'
                jobId={jobId}
                entries={QA_ENTRIES}
                bookingType={bookingType}
                onSave={onQaAction}
              />
            </div>
          </div>
          {qas.length === 0 && (
            <div className='text-center text-secondary'>No qa entries yet.</div>
          )}
          {qas.length !== 0 && qas.map((q, i) => (
            <Item key={i}>
              <small className='text-muted'>{employeesIndex[q.employeeId].name} {'\u2013'} {q.ts}</small>
              <Qa>
                {q.entries.length === 0 && q.type === 'mid-repair' && <span>Mid repair QA passed</span>}
                {q.entries.length === 0 && q.type === 'final' && <span>Final QA passed</span>}
                {q.entries.length !== 0 && q.type === 'mid-repair' && <div>Mid repair QA:</div>}
                {q.entries.length !== 0 && q.type === 'final' && <div>Final QA:</div>}
                {q.entries.length !== 0 && (
                  <ul className='mb-1'>
                    {q.entries.map((e, i) => (
                      <li key={i}>{QA_TITLES[e] || 'Unknown'}</li>
                    ))}
                  </ul>
                )}
              </Qa>
              {q.note.length > 0 && <span>Note:</span>}
              <Note>{q.note}</Note>
            </Item>
          ))}

        </Card>
      </React.Fragment>
    );
  };
};

