import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import Modal from 'components/Modal';
import General from './General';
import List from './List.jsx';
import Creditor from './Creditor.jsx';
import Debtor from './Debtor.jsx';
import Employee from './Employee.jsx';
import LightTunnel from './LightTunnel.jsx';
import MiscInvoiceCategory from './MiscInvoiceCategory.jsx';
import Location from './Location.jsx';
import Mailbox from './Mailbox.jsx';
import SMSTemplate from './SMSTemplate.jsx';
import Storm from './Storm.jsx';
import Company from './Company.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;


export default class Settings extends React.Component {
  constructor(opts) {
    super(opts);

    this.onEditCreditor = this.onEdit.bind(this, 'creditor');
    this.onCloseCreditor = this.onCloseModal.bind(this, 'creditor');
    this.onChangeCreditor = this.onChange.bind(this, 'creditor');
    this.onSaveCreditor = this.onSave.bind(this, 'creditor');
    this.onDeleteCreditor = this.onDelete.bind(this, 'creditor');

    this.onEditDebtor = this.onEdit.bind(this, 'debtor');
    this.onCloseDebtor = this.onCloseModal.bind(this, 'debtor');
    this.onChangeDebtor = this.onChange.bind(this, 'debtor');
    this.onSaveDebtor = this.onSave.bind(this, 'debtor');
    this.onDeleteDebtor = this.onDelete.bind(this, 'debtor');

    this.onEditEmployee = this.onEdit.bind(this, 'employee');
    this.onCloseEmployee = this.onCloseModal.bind(this, 'employee');
    this.onChangeEmployee = this.onChange.bind(this, 'employee');
    this.onSaveEmployee = this.onSave.bind(this, 'employee');
    this.onDeleteEmployee = this.onDelete.bind(this, 'employee');

    this.onEditMailbox = this.onEdit.bind(this, 'mailbox');
    this.onCloseMailbox = this.onCloseModal.bind(this, 'mailbox');
    this.onChangeMailbox = this.onChange.bind(this, 'mailbox');
    this.onSaveMailbox = this.onSave.bind(this, 'mailbox');
    this.onDeleteMailbox = this.onDelete.bind(this, 'mailbox');

    this.onEditSmsTemplate = this.onEdit.bind(this, 'smsTemplate');
    this.onCloseSmsTemplate = this.onCloseModal.bind(this, 'smsTemplate');
    this.onChangeSmsTemplate = this.onChange.bind(this, 'smsTemplate');
    this.onSaveSmsTemplate = this.onSave.bind(this, 'smsTemplate');
    this.onDeleteSmsTemplate = this.onDelete.bind(this, 'smsTemplate');

    this.onEditStorm = this.onEdit.bind(this, 'storm');
    this.onCloseStorm = this.onCloseModal.bind(this, 'storm');
    this.onChangeStorm = this.onChange.bind(this, 'storm');
    this.onSaveStorm = this.onSave.bind(this, 'storm');
    this.onDeleteStorm = this.onDelete.bind(this, 'storm');

    this.onEditCompany = this.onEdit.bind(this, 'company');
    this.onCloseCompany = this.onCloseModal.bind(this, 'company');
    this.onChangeCompany = this.onChange.bind(this, 'company');
    this.onSaveCompany = this.onSave.bind(this, 'company');
    this.onDeleteCompany = this.onDelete.bind(this, 'company');

    this.onEditLightTunnel = this.onEdit.bind(this, 'lightTunnel');
    this.onCloseLightTunnel = this.onCloseModal.bind(this, 'lightTunnel');
    this.onChangeLightTunnel = this.onChange.bind(this, 'lightTunnel');
    this.onSaveLightTunnel = this.onSave.bind(this, 'lightTunnel');
    this.onDeleteLightTunnel = this.onDelete.bind(this, 'lightTunnel');

    this.onEditMiscInvoiceCategory = this.onEdit.bind(this, 'miscInvoiceCategory');
    this.onCloseMiscInvoiceCategory = this.onCloseModal.bind(this, 'miscInvoiceCategory');
    this.onChangeMiscInvoiceCategory = this.onChange.bind(this, 'miscInvoiceCategory');
    this.onSaveMiscInvoiceCategory = this.onSave.bind(this, 'miscInvoiceCategory');
    this.onDeleteMiscInvoiceCategory = this.onDelete.bind(this, 'miscInvoiceCategory');

    this.onEditLocation = this.onEdit.bind(this, 'location');
    this.onCloseLocation = this.onCloseModal.bind(this, 'location');
    this.onChangeLocation = this.onChange.bind(this, 'location');
    this.onSaveLocation = this.onSave.bind(this, 'location');
    this.onDeleteLocation = this.onDelete.bind(this, 'location');
  }

  componentDidMount() {
    const {onLoad} = this.props;
    onLoad();
  }

  onEdit(section, id) {
    const {onEdit} = this.props;
    onEdit(section, id);
  }

  onChange(section, changes) {
    const {onChange} = this.props;
    onChange(section, changes);
  }

  onSave(section, id, data) {
    const {onSave} = this.props;
    onSave(section, id, data)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while updating settings.');
        } else {
          this.onCloseModal(section);
          toast.success('Settings successfully updated.');
        }
      })
      .catch(e => {
        toast.error('Error encountered while updating settings.');
      });
  }

  onDelete(section, id) {
    if (window.confirm(`Are you sure you wish to delete this ${section}?`) === true) {
      const {onDelete} = this.props;
      onDelete(section, id)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered while updating settings.');
          } else {
            this.onCloseModal(section);
            toast.success('Settings successfully updated.');
          }
        })
        .catch(e => {
          toast.error('Error encountered while updating settings.');
        });
    }
  }

  onCloseModal(section) {
    const {onEdit} = this.props;
    onEdit(section, null);
  }

  renderCreditor() {
    const {creditor, miscInvoiceCategories, supplierIndex, supplierList} = this.props;
    if (creditor === null) return null;
    return (
      <Modal
        title={creditor.id === 'new' ? 'New creditor' : 'Edit creditor'}
        open={true}
        wide={true}
        onClose={this.onCloseCreditor}
      >
        <Creditor
          onClose={this.onCloseCreditor}
          onChange={this.onChangeCreditor}
          onSave={this.onSaveCreditor}
          onDelete={this.onDeleteCreditor}
          supplierIndex={supplierIndex}
          supplierList={supplierList}
          miscInvoiceCategoryList={miscInvoiceCategories}
          {...creditor}
        />
      </Modal>
    );
  }

  renderDebtor() {
    const {debtor, stormIndex, stormList} = this.props;
    if (debtor === null) return null;
    return (
      <Modal
        title={debtor.id === 'new' ? 'New debtor' : 'Edit debtor'}
        open={true}
        onClose={this.onCloseDebtor}
      >
        <Debtor
          onClose={this.onCloseDebtor}
          onChange={this.onChangeDebtor}
          onChangeAbility={this.props.onChangeDebtorAbility}
          onAddStorm={this.props.onChangeDebtorAddStorm}
          onDeleteStorm={this.props.onChangeDebtorDeleteStorm}
          onSave={this.onSaveDebtor}
          onDelete={this.onDeleteDebtor}
          stormIndex={stormIndex}
          stormList={stormList}
          {...debtor}
        />
      </Modal>
    );
  }

  renderEmployee() {
    const {employee} = this.props;
    if (employee === null) return null;
    return (
      <Modal
        title={employee.id === 'new' ? 'New employee' : 'Edit employee'}
        open={true}
        onClose={this.onCloseEmployee}
        wide={true}
      >
        <Employee
          onClose={this.onCloseEmployee}
          onChange={this.onChangeEmployee}
          onChangeAbility={this.props.onChangeEmployeeAbility}
          onSave={this.onSaveEmployee}
          onDelete={this.onDeleteEmployee}
          {...employee}
        />
      </Modal>
    );
  }

  renderLightTunnel() {
    const {lightTunnel} = this.props;
    if (lightTunnel === null) return null;
    return (
      <Modal
        title={lightTunnel.id === 'new' ? 'New light tunnel' : 'Edit light tunnel'}
        open={true}
        wide={true}
        onClose={this.onCloseLightTunnel}
      >
        <LightTunnel
          onClose={this.onCloseLightTunnel}
          onChange={this.onChangeLightTunnel}
          onSave={this.onSaveLightTunnel}
          onDelete={this.onDeleteLightTunnel}
          {...lightTunnel}
        />
      </Modal>
    );
  }

  renderMiscInvoiceCategory() {
    const {miscInvoiceCategory} = this.props;
    if (miscInvoiceCategory === null) return null;
    return (
      <Modal
        title={miscInvoiceCategory.id === 'new' ? 'New misc invoice category' : 'Edit misc invoice category'}
        open={true}
        wide={true}
        onClose={this.onCloseMiscInvoiceCategory}
      >
        <MiscInvoiceCategory
          onClose={this.onCloseMiscInvoiceCategory}
          onChange={this.onChangeMiscInvoiceCategory}
          onSave={this.onSaveMiscInvoiceCategory}
          onDelete={this.onDeleteMiscInvoiceCategory}
          {...miscInvoiceCategory}
        />
      </Modal>
    );
  }

  renderLocation() {
    const {location, employeeIndex, employeeList} = this.props;
    if (location === null) return null;
    return (
      <Modal
        title={location.id === 'new' ? 'New location' : 'Edit location'}
        open={true}
        wide={true}
        onClose={this.onCloseLocation}
      >
        <Location
          onClose={this.onCloseLocation}
          onChange={this.onChangeLocation}
          onAddEmployee={this.props.onChangeLocationAddEmployee}
          onDeleteEmployee={this.props.onChangeLocationDeleteEmployee}
          onChangeEmployeeCommission={this.props.onChangeLocationChangeEmployeeCommission}
          onChangeEmployeeAbility={this.props.onChangeLocationChangeEmployeeAbility}
          onSave={this.onSaveLocation}
          onDelete={this.onDeleteLocation}
          employeeIndex={employeeIndex}
          employeeList={employeeList}
          {...location}
        />
      </Modal>
    );
  }

  renderMailbox() {
    const {debtors, stormList, companyList, mailbox} = this.props;
    if (mailbox === null) return null;
    return (
      <Modal
        title={mailbox.id === 'new' ? 'New mailbox' : 'Edit mailbox'}
        open={true}
        onClose={this.onCloseMailbox}
      >
        <Mailbox
          onClose={this.onCloseMailbox}
          onChange={this.onChangeMailbox}
          onSave={this.onSaveMailbox}
          onDelete={this.onDeleteMailbox}
          debtors={debtors}
          storms={stormList}
          companies={companyList}
          {...mailbox}
        />
      </Modal>
    );
  }

  renderSmsTemplate() {
    const {smsTemplate} = this.props;
    if (smsTemplate === null) return null;
    return (
      <Modal
        title={smsTemplate.id === 'new' ? 'New SMS template' : 'Edit SMS template'}
        open={true}
        onClose={this.onCloseSmsTemplate}
      >
        <SMSTemplate
          onClose={this.onCloseSmsTemplate}
          onChange={this.onChangeSmsTemplate}
          onSave={this.onSaveSmsTemplate}
          onDelete={this.onDeleteSmsTemplate}
          {...smsTemplate}
        />
      </Modal>
    );
  }

  renderStorm() {
    const {locations, storm, creditorIndex, creditorList} = this.props;
    if (storm === null) return null;
    return (
      <Modal
        title={storm.id === 'new' ? 'New storm' : 'Edit storm'}
        open={true}
        onClose={this.onCloseStorm}
      >
        <Storm
          onClose={this.onCloseStorm}
          onChange={this.onChangeStorm}
          onAddCreditor={this.props.onChangeStormAddCreditor}
          onDeleteCreditor={this.props.onChangeStormDeleteCreditor}
          onToggleCreditorMake={this.props.onToggleStormCreditorMake}
          onSave={this.onSaveStorm}
          onDelete={this.onDeleteStorm}
          locations={locations}
          creditorIndex={creditorIndex}
          creditorList={creditorList}
          {...storm}
        />
      </Modal>
    );
  }

  renderCompany() {
    const {company} = this.props;
    if (company === null) return null;
    return (
      <Modal
        title={company.id === 'new' ? 'New company' : 'Edit company'}
        open={true}
        onClose={this.onCloseCompany}
      >
        <Company
          onClose={this.onCloseCompany}
          onChange={this.onChangeCompany}    
          onSave={this.onSaveCompany}
          onDelete={this.onDeleteCompany}
          {...company}
        />
      </Modal>
    );
  }

  render() {
    console.log('settings:render');
    const {settingsValid} = this.props;
    if (settingsValid === null) return null;
    if (settingsValid === false) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load settings.</div>
        </ErrorContainer>
      );
    }

    return (
      <div className='flex-grow-1 p-3'>
        <h5 className='mb-3 p-0'>Settings</h5>
        <Card>
          <General />
        </Card>
        <Card>
          <List
            title='Creditors'
            name='creditors'
            onEdit={this.onEditCreditor}
          />
        </Card>
        <Card>
          <List
            title='Debtors'
            name='debtors'
            onEdit={this.onEditDebtor}
          />
        </Card>
        <Card>
          <List
            title='Employees'
            name='employees'
            onEdit={this.onEditEmployee}
          />
        </Card>
        <Card>
          <List
            title='Light tunnels'
            name='lightTunnels'
            onEdit={this.onEditLightTunnel}
          />
        </Card>
        <Card>
          <List
            title='Locations'
            name='locations'
            onEdit={this.onEditLocation}
          />
        </Card>
        <Card>
          <List
            title='Mailboxes'
            name='mailboxes'
            onEdit={this.onEditMailbox}
          />
        </Card>
        <Card>
          <List
            title='Misc invoice categories'
            name='miscInvoiceCategories'
            onEdit={this.onEditMiscInvoiceCategory}
          />
        </Card>
        <Card>
          <List
            title='SMS templates'
            name='smsTemplates'
            onEdit={this.onEditSmsTemplate}
          />
        </Card>
        <Card>
          <List
            title='Storms'
            name='storms'
            onEdit={this.onEditStorm}
          />
        </Card>
        <Card>
          <List
            title='Companies'
            name='companies'
            onEdit={this.onEditCompany}
          />
        </Card>
        {this.renderCreditor()}
        {this.renderDebtor()}
        {this.renderEmployee()}
        {this.renderLightTunnel()}
        {this.renderMiscInvoiceCategory()}
        {this.renderLocation()}
        {this.renderMailbox()}
        {this.renderSmsTemplate()}
        {this.renderStorm()}
        {this.renderCompany()}
      </div>
    );
  }
};

