import React from 'react';

import colors from 'colors';

import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


const AUTO_TYPES = [
  {id: 'new-notification', text: 'New notification'},
  {id: 'booking-upcoming', text: 'Booking upcoming'},
  {id: 'booking-start', text: 'Booking start'},
  {id: 'booking-finish', text: 'Booking finish'},
  {id: 'checkin', text: 'Car checkin'},
  {id: 'repair-in-progress', text: 'Repair in progress'},
];

export default class SettingsSMSTemplate extends React.Component {
  constructor(opts) {
    super(opts);
    this.onChangeAutoType = this.onChange.bind(this, 'autoType');
    this.onChangeName = this.onChange.bind(this, 'name');
    this.onChangeTemplate = this.onChange.bind(this, 'template');
    this.onToggleEnabled = this.onToggleEnabled.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onChange(field, e) {
    this.props.onChange({
      [field]: e,
      [`${field}Error`]: false
    });
  }
  onToggleEnabled(e) {
    this.props.onChange({
      enabled: !this.props.enabled
    });
  }
  onDelete() {
    const {onDelete, id} = this.props;
    onDelete(id);
  }
  onSave() {
    const {onSave, id, autoType, name, template, enabled} = this.props;
    onSave(id, {autoType, name, template, enabled});
  }
  render() {
    console.log('settingssmstemplate:render');
    const {onClose} = this.props;
    const {
      id,
      autoType,
      name, nameError,
      template, templateError,
      enabled,
    } = this.props;
    return (
      <React.Fragment>
        <div className='modal-body'>
          <Select
            title='Auto type'
            placeholder='select an auto type'
            background={colors.grey1}
            options={AUTO_TYPES}
            selected={autoType}
            onChange={this.onChangeAutoType} />
          <Field
            title='Name'
            background={colors.grey1}
            uppercaseOnly={true}
            value={name}
            error={nameError}
            onChange={this.onChangeName} />
          <Field
            title='Template'
            background={colors.grey1}
            value={template}
            error={templateError}
            onChange={this.onChangeTemplate} />
          <div className='mt-3'>
            <button
              className={enabled ? 'btn btn-secondary w-100' : 'btn btn-outline-secondary w-100'}
              onClick={this.onToggleEnabled}
            >{enabled ? 'Enabled' : 'Disabled'}</button>
          </div>
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button type='button' className='btn btn-danger mr-auto' onClick={this.onDelete}>Delete</button>
          }
          <button type='button' className='btn btn-secondary' onClick={onClose}>Close</button>
          <button type='button' className='btn btn-primary' onClick={this.onSave}>Save</button>
        </div>
      </React.Fragment>
    );
  }
};
