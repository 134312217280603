import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import Modal from 'components/Modal';
import SearchBar from 'components/internal/SearchBar';


const Card = styled.div`
  color: ${colors.body};
  background: ${colors.grey1};
`;
const JobField = styled.div`
  font-size: 0.9rem;
  @media (max-width: 768px) {
    width: ${opts => opts.width}px;
  }
  @media (max-width: 400px) {
    width: ${opts => opts.width / 2}px;
  }
`;

export default class Import extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      job: null,
      includeParts: true,
      includePartNumbers: false,
      includeComments: false
    };
    this.onSelectJob = this.onSelectJob.bind(this);
    this.onToggleParts = this.onToggle.bind(this, 'includeParts');
    this.onTogglePartNumbers = this.onToggle.bind(this, 'includePartNumbers');
    this.onToggleComments = this.onToggle.bind(this, 'includeComments');
    this.onImport = this.onImport.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.jobId !== prevProps.jobId || this.props.open !== prevProps.open) {
      this.setState({
        job: null,
        includeParts: true,
        includePartNumbers: false,
        includeComments: false
      });
    }
  }
  onSelectJob(job) {
    this.setState({
      job
    });
  }
  onToggle(field, e) {
    const newState = {
      ...this.state,
      [field]: e.target.checked
    };
    if (newState.includeParts !== true) {
      newState.includePartNumbers = false;
    }
    this.setState(newState);
  }
  onImport() {
    const {jobId, onImport, onClose} = this.props;
    const {job, includeParts, includePartNumbers, includeComments} = this.state;
    onImport(jobId, job.id, includeParts, includePartNumbers, includeComments)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while importing job.');
        } else {
          toast.success('Job successfully imported.');
          onClose();
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while importing job.');
      });
  }
  renderJob() {
    const {job} = this.state;
    if (job === null) {
      return (
        <Card className='rounded p-2 mt-3'>
          Please select a job to import from
        </Card>
      );
    }
    return (
      <Card className='rounded p-2 mt-3'>
        <NavLink to={`/job/${job.id}`}>
          <JobField className='font-weight-bold text-body'>{job.jobNumber}</JobField>
        </NavLink>
        <div className='d-flex flex-row justify-content-between'>
          <JobField className='text-truncate' width={100}>{job.name}</JobField>
          <JobField className='text-right text-truncate text-uppercase' width={100}>{job.makeModel}</JobField>
        </div>
      </Card>
    );
  }
  render() {
    const {open, onClose} = this.props;
    const {job, includeParts, includePartNumbers, includeComments} = this.state;
    return (
      <Modal title='Import quote from...' open={open} onClose={onClose}>
        <div className='modal-body'>
          <SearchBar background={colors.grey1} showPlaceholder={true} onSelect={this.onSelectJob} />
          {this.renderJob()}
          <hr />
          <div className='form-group form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              id='import-include-parts'
              onChange={this.onToggleParts}
              checked={includeParts}
            />
            <label className='form-check-label' htmlFor='import-include-parts'>
              Include parts
            </label>
          </div>
          <div className='form-group form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              id='import-include-partnumbers'
              onChange={this.onTogglePartNumbers}
              disabled={includeParts !== true}
              checked={includeParts === true && includePartNumbers === true}
            />
            <label className='form-check-label' htmlFor='import-include-partnumbers'>
              Include part numbers
            </label>
          </div>
          <div className='form-group form-check mb-0'>
            <input
              type='checkbox'
              className='form-check-input'
              id='import-include-comments'
              onChange={this.onToggleComments}
              checked={includeComments}
            />
            <label className='form-check-label' htmlFor='import-include-comments'>
              Include comments
            </label>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={onClose}
          >Close</button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onImport}
            disabled={job === null}
          >Import</button>
        </div>
      </Modal>
    );
  }
};
