import TruncateString from 'utils/truncate-string.js';


export default (state = {
  notFound: null,
  list: [],
}, action) => {
  switch (action.type) {
    case 'SUPPLIER-LIST-RESET':
      return {
        ...state,
        notFound: null,
        list: []
      };
    case 'SUPPLIER-LIST-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        list: []
      };
    case 'SUPPLIER-LIST-SET':
    {
      const {list} = action;
      return {
        ...state,
        notFound: false,
        list: list.map(q => ({
          ...q,
          idStr: [q.jobNumber, q.id, q.checksum].join(':'),
          makeModel: TruncateString([q.make, q.model].join(' ')),
          userName: q.openUser ? q.openUser.name : 'N/A',
        })),
      };
    }
    case 'SUPPLIER-QUOTE-SET':
      const {quote} = action;
      return {
        ...state,
        list: state.list.map(q => {
          if (q.id !== quote.id) return q;
          return {
            ...q,
            openUser: quote.openUser
          };
        })
      };
    case 'SUPPLIER-ORDER-SET':
      const {order} = action;
      return {
        ...state,
        list: state.list.map(o => {
          if (o.id !== order.id) return o;
          return {
            ...o,
            openUser: order.openUser
          };
        })
      };
    default:
      return state;
  }
};
