import {connect} from 'react-redux';

import Header from './component.jsx';


const switchProfile = profile => {
  return dispatch => {
    dispatch({
      type: 'LOCATION-ID-RESET',
    });
    dispatch({
      type: 'PARAMS-RESET',
    });
    dispatch({
      type: 'AUTHENTICATION-SET-ACTIVE-PROFILE',
      profile
    });
  };
};

const searchSelect = (history, id) => {
  history.push(`/job/${id}`);
}

const logout = () => ({
  type: 'AUTHENTICATION-RESET'
});

const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    params: {employees: {index: employeeIndex}}
  } = state;
  const {profile: {abilities: {canSearch}}} = profiles[activeProfile];
  return {
    activeProfile,
    profiles,
    employeeIndex,
    canSearch,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onToggleMenu: () => dispatch({
    type: 'MENU-TOGGLE'
  }),
  onSearchSelect: job => searchSelect(props.history, job.id),
  onSwitchProfile: profile => dispatch(switchProfile(profile)),
  onLogout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

