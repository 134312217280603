import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import Modal from 'components/Modal';


const LightTunnelRow = styled.div`
  :last-of-type {
    margin-bottom: 0 !important;
  }
`;

export default class LightTunnel extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      lightTunnelId: null
    };
    //this.onSelectLightTunnel = this.onSelectLightTunnel.bind(this);
    this.onCapture = this.onCapture.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.jobId !== prevProps.jobId || this.props.open !== prevProps.open) {
      this.setState({
        lightTunnelId: null,
      });
    }
  }
  onToggle(lightTunnelId, e) {
    this.setState({
      lightTunnelId,
    });
  }
  onCapture() {
    const {jobId, onCapture, onClose} = this.props;
    const {lightTunnelId} = this.state;
    onCapture(lightTunnelId, jobId)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while capturing from light tunnel.');
        } else {
          toast.success('Job successfully triggered light tunnel.');
          onClose();
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while capturing from light tunnel.');
      });
  }
  render() {
    const {open, lightTunnels, onClose} = this.props;
    const {lightTunnelId} = this.state;
    return (
      <Modal title='Light tunnel capture...' open={open} onClose={onClose}>
        <div className='modal-body'>
          {lightTunnels.map((l, i) => (
            <LightTunnelRow key={i} className='form-group form-check mb-2'>
              <input
                type='radio'
                className='form-check-input'
                id={`light-tunnel-${i}`}
                onChange={this.onToggle.bind(this, l.id)}
                checked={lightTunnelId === l.id}
              />
              <label className='form-check-label' htmlFor={`light-tunnel-${i}`}>
                {l.name}
              </label>
            </LightTunnelRow>
          ))}
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={onClose}
          >Close</button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onCapture}
            disabled={lightTunnelId === null}
          >Capture</button>
        </div>
      </Modal>
    );
  }
};
