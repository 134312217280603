import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Autocomplete from 'components/Autocomplete.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  margin: 1rem;
`;
const Check = styled(Icons.Check)`
  stroke: white !important;
`;
const X = styled(Icons.X)`
  stroke: white !important;
`;
const Item = styled.tr`
  background: ${opts => opts.alert ? colors.redExtraLight : colors.white};
  color: ${colors.body};
  ${opts => opts.header && `
    cursor: pointer;
    font-weight: bold;
    color: ${colors.primary};
    & svg {
      stroke: ${colors.primary};
    }
  `}
  > td {
    border: 0 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;

const Task = styled.tr`
  background: ${opts => opts.alert ? colors.redExtraLight : colors.white};
  & td {
    border: 0 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;


export default class TechnicianCommissions extends React.Component {
  constructor(opts) {
    super(opts);
    const {employeeId, employee} = opts.employees.reduce((acc, c) => {
      if (acc.employeeId === c.id) {
        acc.employee = c.name;
      }
      return acc;
    }, {employeeId: opts.employeeId, employee: ''});
    this.state = {
      toggles: {},
      employeeId,
      employee,
      open: false
    };
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.onFilterEmployee = this.onFilterEmployee.bind(this);
    this.renderInvoice = this.renderInvoice.bind(this);
    this.onToggleOutstanding = this.onToggleOutstanding.bind(this);
  }
  componentDidMount() {
    const {employeeId, onLoad} = this.props;
    if (employeeId !== null) {
      onLoad(employeeId);
    }
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {employeeId, invoices, onLoad} = this.props;
    if (employeeId !== null && employeeId !== prevProps.employeeId) {
      onLoad(employeeId);
    }
    if (invoices !== prevProps.invoices) {
      this.setState({
        toggles: invoices.reduce((acc, i, j) => {
          const open = i.payDate === null;
          acc[j] = {
            open,
            Icon: open ? Icons.ChevronDown : Icons.ChevronUp,
          };
          return acc;
        }, {})
      });
    }
  }
  onChangeEmployee(e) {
    if (typeof e === 'string') {
      this.setState({
        employee: e,
        employeeId: null,
      });
      if (e === '') {
        this.props.onChangeEmployeeId(null);
      }
    } else {
      this.setState({
        employee: e.text,
        employeeId: e.id,
      });
      this.props.onChangeEmployeeId(e.id);
    }
  }
  onFilterEmployee(v) {
    const {employees} = this.props;
    if (v === null) return employees.map(c => ({id: c.id, text: c.name}));
    const regex = new RegExp(v, 'i');
    const res = employees
      .reduce((acc, c) => {
        if (regex.test(c.name) === true) {
          acc.push({
            id: c.id,
            text: c.name,
          });
        }
        return acc;
      }, []);
    return res;
  }
  onToggle(index) {
    const toggle = this.state.toggles[index];
    this.setState({
      toggles: {
        ...this.state.toggles,
        [index]: {
          open: !toggle.open,
          Icon: toggle.open ? Icons.ChevronUp : Icons.ChevronDown,
        }
      }
    });
  }
  onToggleOutstanding() {
    this.setState({
      open: !this.state.open
    });
  }
  onProcess(invoiceId, action, e) {
    e.stopPropagation();
    if (window.confirm(`Are you sure you wish to ${action} this invoice?`) === true) {
      const {employeeId, onProcess} = this.props;
      onProcess(employeeId, invoiceId, action)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered while processing invoice.');
          } else {
            toast.success('Invoice successfully processed.');
          }
        });
    }
  }
  renderInvoice(i, j) {
    const {chargesGst} = this.props;
    const {toggles} = this.state;
    const toggle = toggles[j] || {open: false, Icon: Icons.ChevronUp};
    const ToggleIcon = toggle.Icon;
    return (
      <React.Fragment key={j}>
        <tr>
          <td className='p-1 border-0' />
        </tr>
        <Item
          header={true}
          alert={i.payDate === null}
          onClick={this.onToggle.bind(this, j)}
        >
          <td className='align-middle'>{i.id}</td>
          <td className='align-middle text-center'>{i.createDateStr}</td>
          {chargesGst && <td className='align-middle text-center'>{i.subtotalStr}</td>}
          {chargesGst && <td className='align-middle text-center'>{i.gstStr}</td>}
          <td className='align-middle text-center'>{i.totalStr}</td>
          {i.payDate === null && (
            <td className='text-center'>
              <button
                className='btn btn-success mr-1 px-3'
                onClick={this.onProcess.bind(this, i.id, 'pay')}
              ><Check width={18} height={18} /></button>
              <button
                className='btn btn-danger ml-1 px-3'
                onClick={this.onProcess.bind(this, i.id, 'reject')}
              ><X width={18} height={18} /></button>
            </td>
          )}
          {i.payDate !== null && (
            <td className='align-middle text-center'>{i.payDateStr}</td>
          )}
          <td>
            <ToggleIcon />
          </td>
        </Item>
        {toggle.open === true && (
          <Item alert={i.payDate === null}>
            <td />
            <td colSpan={3 + (chargesGst ? 2 : 0)}>
              <table className='table mb-0'>
                <thead>
                  <tr>
                    <th className='border-top-0 py-0'>Job #</th>
                    <th className='border-top-0 py-0'>Rego</th>
                    <th className='border-top-0 py-0'>Make/model</th>
                    <th className='border-top-0 py-0'>Task</th>
                    <th className='border-top-0 py-0 text-right'>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {i.tasks.map((t, x) => (
                    <tr key={x}>
                      <td className='py-0'>
                        <Link to={`/job/${t.jobId}`}>
                          <span className='text-body'>{t.jobNumber}</span>
                        </Link>
                      </td>
                      <td className='py-0'>{t.rego}</td>
                      <td className='py-0 text-uppercase'>{t.makeModel}</td>
                      <td className='py-0 text-uppercase'>{t.task}</td>
                      {t.amount >= 0 && (
                        <td className='py-0 text-right'>{t.amountStr}</td>
                      )}
                      {t.amount < 0 && (
                        <td className='py-0 text-right text-danger'>{t.amountStr}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
            <td />
          </Item>
        )}
      </React.Fragment>
    );
  }
  render() {
    console.log('techniciancommissions:render');
    const {notFound} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load commissions.</div>
        </ErrorContainer>
      );
    }
    const {
      chargesGst,
      invoices,
      outstandingTasks,
      subtotalTasks,
      totalTasks,
      gstTasks,
    } = this.props;
    const {employee, open} = this.state;
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h4 className='mb-0'>Technician commissions</h4>
          <Autocomplete
            className='m-0'
            background={colors.grey1}
            uppercaseOnly={true}
            showCaret={true}
            value={employee}
            onChange={this.onChangeEmployee}
            onFilter={this.onFilterEmployee}
          />
        </div>
        {notFound === false && open === false && (
          <Card className='p-3 mt-3'>
            <div className='font-weight-bold' onClick={this.onToggleOutstanding}>
              Outstanding tasks ({outstandingTasks.length})
              with total amount: {totalTasks}
              <Icons.ChevronUp color={colors.primary} />
            </div>
          </Card>
        )}
        {notFound === false && open && (
          <Card className='p-3 mt-3'>
            <div className='font-weight-bold' onClick={this.onToggleOutstanding}>
              Outstanding tasks ({outstandingTasks.length})
              with total amount: {totalTasks}
              <Icons.ChevronDown color={colors.primary} />
            </div>
            {outstandingTasks.length === 0 && (
              <div className='text-center text-secondary'>No tasks remaining.</div>
            )}
            {outstandingTasks.length !== 0 && (
              <table className='table mb-0 mt-3'>
                <thead>
                  <tr>
                    <th className='border-0 py-0'>Job #</th>
                    <th className='border-0 py-0'>Rego</th>
                    <th className='border-0 py-0'>Make/model</th>
                    <th className='border-0 py-0'>Task</th>
                    <th className='border-0 py-0'>Done date</th>
                    <th className='border-0 py-0'>Amount</th>
                    <th className='border-0 py-0' width={50} />
                  </tr>
                </thead>
                <tbody>
                  {outstandingTasks.map((t, i) => (
                    <React.Fragment key={i}>
                      <tr>
                        <td className='p-1 border-0' />
                      </tr>
                      <Task className={t.disabled && 'text-muted'}>
                        <td>
                          <Link to={`/job/${t.jobId}`}>
                            <strong className={t.disabled ? 'text-muted' : 'text-body'}>{t.jobNumber}</strong>
                          </Link>
                        </td>
                        <td>{t.rego}</td>
                        <td className='text-uppercase'>{t.makeModel}</td>
                        <td className='text-uppercase'>{t.task}</td>
                        <td>{t.doneDateStr}</td>
                        <td>
                          {t.amountStr}
                          {t.adjustment > 0 && (
                            <span className='text-success ml-2'>inc {t.adjustmentStr} adjustment</span>
                          )}
                          {t.adjustment < 0 && (
                            <span className='text-danger ml-2'>inc {t.adjustmentStr} adjustment</span>
                          )}
                        </td>
                      </Task>
                    </React.Fragment>
                  ))}
                  {chargesGst && (
                    <tr>
                      <td
                        className='border-0 font-weight-bold pb-0 align-middle'
                        colSpan={5}
                        align='right'
                      >Subotal</td>
                      <td className='border-0 font-weight-bold pb-0 align-middle'>
                        {subtotalTasks}
                      </td>
                    </tr>
                  )}
                  {chargesGst && (
                    <tr>
                      <td
                        className='border-0 font-weight-bold pb-0 align-middle'
                        colSpan={5}
                        align='right'
                      >GST</td>
                      <td className='border-0 font-weight-bold pb-0 align-middle'>
                        {gstTasks}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td
                      className='border-0 font-weight-bold pb-0 align-middle'
                      colSpan={5}
                      align='right'
                    >Total</td>
                    <td className='border-0 font-weight-bold pb-0 align-middle'>
                      {totalTasks}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Card>
        )}
        {notFound === false && (
          <Card className='p-3'>
            {invoices.length === 0 && (
              <div className='text-center text-secondary'>No invoices found.</div>
            )}
            {invoices.length !== 0 && (
              <table className='table mb-0'>
                <thead>
                  <tr>
                    <th className='border-0 py-0'>#</th>
                    <th className='border-0 py-0 text-center'>Date</th>
                    {chargesGst && <th className='border-0 py-0 text-center'>Subtotal</th>}
                    {chargesGst && <th className='border-0 py-0 text-center'>GST</th>}
                    <th className='border-0 py-0 text-center'>Total</th>
                    <th className='border-0 py-0 text-center'>Pay date</th>
                    <th className='border-0 py-0' width={64} />
                  </tr>
                </thead>
                <tbody>
                  {invoices.map(this.renderInvoice)}
                </tbody>
              </table>
            )}
          </Card>
        )}
      </Container>
    );
  };
};

