import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import Modal from 'components/Modal';
import Select from 'components/Select.jsx';


const REASONS = [
  'replaced - damaged',
  'replaced - incorrect part',
  'replaced - insurance cancelled',
  'replaced - wrongly ordered',
  'returned - damaged',
  'returned - incorrect part',
  'returned - insurance cancelled',
  'returned - wrongly ordered',
];
const ModalBody = styled.div`
  & .form-group:last-of-type {
    margin-bottom: 0px;
  }
`;

export default class Credit extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      orderId: null,
      lines: [],
      canCredit: false,
      canSave: false
    };
    this.onSave = this.onSave.bind(this);
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lines !== prevProps.lines || this.state.open !== prevState.open) {
      const {lines, orders, canCredit} = Object.values(this.props.lines)
        .reduce((acc, l) => {
          if (l) {
            if (acc.orderIndex[l.orderId] !== true) {
              acc.orderIndex[l.orderId] = true;
              acc.orders.push(l.orderId);
            }
            acc.lines.push({
              id: l.invoiceLineId,
              qty: l.qtySupplied,
              description: l.reqDescription,
              partNumber: l.reqPartNumber,
              reason: null,
            });
            if (l.canCredit === false) {
              acc.canCredit = false;
            }
          }
          return acc;
        }, {lines: [], orders: [], orderIndex: {}, canCredit: true});
      if (lines.length >= 1 && orders.length === 1) {
        this.setState({
          ...this.state,
          orderId: orders[0],
          lines,
          canCredit
        });
      } else {
        this.setState({
          ...this.state,
          orderId: null,
          lines: [],
          canCredit: false
        });
      }
    }
  }

  onToggleModal(open) {
    if (open === true) {
      this.setState({
        open,
        canSave: false
      });
    } else {
      this.setState({
        open,
      });
    }
  }

  onSelectReason(idx, v) {
    let canSave = null;
    const lines = this.state.lines.map((l, i) => {
      let newL = l;
      if (i === idx) {
        newL = {
          ...l,
          reason: v
        };
      }
      if (newL.reason === null) {
        canSave = false;
      } else if (canSave === null) {
        canSave = true;
      }
      return newL;
    });
    this.setState({
      lines,
      canSave
    });
  }

  //should an order line be only creditable once?
  //should not allow crediting an order line that has
  //already been credited once?
  onSave() {
    const {jobId, onSave} = this.props;
    const {orderId, lines} = this.state;
    onSave(
      jobId,
      orderId,
      {
        action: 'credit',
        lines: lines.map(({id, reason}) => ({id, reason}))
      }
    )
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while crediting parts.');
        } else {
          toast.success('Parts successfully credited.');
          this.setState({
            open: false,
            selectedSuppliers: {}
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while crediting parts.');
      });
  }

  render () {
    console.log('credit:render');
    const {open, lines, canCredit, canSave} = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-warning ml-2'
          onClick={this.onOpen}
          disabled={canCredit !== true}
        >Credit</button>
        <Modal
          title='Credit parts'
          open={open}
          onClose={this.onClose}
        >
          <ModalBody className='modal-body'>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th className='border-top-0' width='60%'>Description</th>
                  <th className='border-top-0 text-center'>Reason</th>
                </tr>
              </thead>
              <tbody>
                {lines.map((l, i) => (
                  <tr key={i}>
                    <td className='align-middle'>{l.qty}x {l.description} {l.partNumber}</td>
                    <td className='m-0'>
                      <Select
                        placeholder='select a reason'
                        background={colors.grey1}
                        selected={l.reason}
                        options={REASONS}
                        onChange={this.onSelectReason.bind(this, i)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalBody>
          <div className='modal-footer bt-0'>
            <button type='button' className='btn btn-secondary' onClick={this.onClose}>Close</button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={this.onSave}
              disabled={canSave !== true}
            >Save</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

