import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';


const LoadingContainer = styled.div`
  background: rgba(0,0,0,0.7);
  color: #fff;
  z-index: 2000;

  svg {
    stroke: #fff;
    animation: spin 2s linear infinite;
  }
`;


export default ({loading}) => (
  loading ?
    (
      <LoadingContainer
        className='fixed-top w-100 h-100 d-flex flex-column justify-content-center align-items-center'
      >
        <div className='mb-2'>
          <Icons.Loader width={48} height={48} />
        </div>
        <div>Loading...</div>
      </LoadingContainer>
    ) :
    null
);
