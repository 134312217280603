import colors from 'colors';


const COLORS = [
  colors.red,
  colors.green,
  colors.blue,
  colors.purple,
  colors.yellowDark,
  colors.indigo,
  colors.pink,
  colors.teal,
  colors.lime,
  colors.amber,
  colors.orange,
  colors.brown,
];

export default (state = {
  notFound: null,
  sections: [],
}, action) => {
  switch (action.type) {
    case 'INTERNAL-DASHBOARD-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'INTERNAL-DASHBOARD-SET':
    {
      const KPI_TITLES = {
        overdueStartRepairs: 'Start',
        overdueFinishRepairs: 'Finish',
        awaitingBackorderParts: 'B/O',
        toBeSentForAuthority: 'Quote',
        assessNoShows: 'Assess',
        repairNoShows: 'Repair',
        outstandingExcess: 'Excess',
      };
      return {
        ...state,
        notFound: false,
        sections: action.sections.map(s => {
          if (s.key === 'map') {
            return {
              key: s.key,
              data: s.list.map((storm, i) => ({
                color: COLORS[i % COLORS.length],
                points: storm.reduce((acc, {long, lat, jobInfo, count}) => {
                  if (long === null || lat === null) {
                    console.warn('invalid map coordinates received');
                  } else {
                    acc.push({
                      long,
                      lat,
                      jobInfo,
                      count,
                    });
                  }
                  return acc;
                }, []),
              })),
            };
          } else if (s.key === 'kpis') {
            const {locations, data} = s.list.reduce(
              (acc, l, i) => {
                acc.locations.push({
                  id: l.locationId,
                  color: COLORS[i % COLORS.length],
                });
                Object.keys(l.data).reduce((acc, k) => {
                  if (!acc.index.hasOwnProperty(k)) {
                    acc.index[k] = acc.data.length;
                    acc.data.push({
                      title: KPI_TITLES[k],
                    });
                  }
                  acc.data[acc.index[k]][l.locationId] = l.data[k];
                  return acc;
                }, acc);
                return acc;
              },
              {locations: [], index: {}, data: []}
            );
            return {
              key: s.key,
              locations,
              data,
            };
          } else if (s.key === 'progressChart') {
            return {
              ...s,
              ...s.list.reduce((acc, l) => {
                acc.locations.push(l.locationId);
                acc.list.push({
                  ...l,
                  allocationsColor: COLORS[0],
                  cancellationsColor: COLORS[1],
                  contactsColor: COLORS[2],
                  assessmentsColor: COLORS[3],
                  bookedColor: COLORS[4],
                  remainingColor: COLORS[5],
                  categories: l.categories.map((c, i) => ({
                    key: c,
                    color: COLORS[i % COLORS.length],
                  })),
                });
                return acc;
              }, {locations: [], list: []}),
            };
          } else if (s.key === 'finishDates') {
            return {
              ...s,
              ...s.list.reduce((acc, l) => {
                acc.locations.push(l.locationId);
                acc.list.push({
                  ...l,
                  categories: l.categories.map((c, i) => ({
                    key: c,
                    color: COLORS[i % COLORS.length],
                  })),
                });
                return acc;
              }, {locations: [], list: []}),
            };
          } else if (s.key === 'survey') {
            return {
              ...s,
              ...s.list.reduce((acc, l) => {
                acc.locations.push(l.locationId);
                acc.list.push({
                  ...l,
                  categories: l.categories.map((c, i) => ({
                    key: c,
                    color: COLORS[i % COLORS.length],
                  })),
                });
                return acc;
              }, {locations: [], list: []}),
            };
          } else if (s.key === 'techCommissions') {
            return {
              ...s,
              ...s.list.reduce((acc, l) => {
                acc.locations.push(l.locationId);
                acc.list.push({
                  ...l,
                  employees: l.employees.map((c, i) => ({
                    key: c,
                    color: COLORS[i % COLORS.length],
                  })),
                  data: l.data.map(d => Object.keys(d).reduce((acc, k) => {
                    if (k === 'week') {
                      acc[k] = d[k];
                    } else {
                      acc[k] = d[k] / 100;
                    }
                    return acc;
                  }, {})),
                });
                return acc;
              }, {locations: [], list: []}),
            };
          } else {
            return s;
          }
        }),
      };
    }
    default:
      return state;
  }
};
