import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Notes from './component.jsx';


const load = (API, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/notes`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-NOTES-SET-NOT-FOUND'
          });
          dispatch({
            type: 'JOB-GALLERY-SET',
            images: []
          });
          return null;
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        dispatch({
          type: 'JOB-NOTES-SET-NOTES',
          notes: resp.notes
        });
        dispatch({
          type: 'JOB-NOTES-SET-FOUND',
          jobId,
          jobNumber: resp.jobNumber,
          flagged: resp.flagged,
          vpp: resp.vpp,
          vip: resp.vip,
          importedFromArms: resp.importedFromArms,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });

    API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/repx-notes`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error !== true) {
          dispatch({
            type: 'JOB-NOTES-SET-REPXNOTES',
            notes: resp.notes
          });
        }
      });
  };
};

const unload = () => ({
  type: 'JOB-NOTES-SET-NULL'
});

const addNote = (API, jobId, type, note) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/note`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({type, note})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'JOB-NOTES-SET-NOTES',
          notes: res.notes
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const addRepxNote = (API, jobId, note) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/repx-note`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({note})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'JOB-NOTES-SET-REPXNOTES',
          notes: res.notes
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {jobId} = props;
  let jobValid = null;
  if (state.jobNotes.notFound === true)
    jobValid = false;
  else if (state.jobNotes.notFound === false) {
    jobValid = true;
  }
  if (jobValid !== true) {
    return {
      jobValid,
    };
  }
  const {
    jobNotes: {jobNumber, flagged, vpp, vip, importUrl, notes, repxNotes},
    params: {employees: {index: employeesIndex}}
  } = state;
  return {
    jobValid,
    jobId,
    jobNumber,
    flagged,
    vpp,
    vip,
    importUrl,
    notes,
    repxNotes,
    employeesIndex
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: jobId => dispatch(load(props.API, jobId)),
  onUnload: () => dispatch(unload()),
  onAddNote: (jobId, type, note) => dispatch(addNote(props.API, jobId, type, note)),
  onAddRepxNote: (jobId, note) => dispatch(addRepxNote(props.API, jobId, note)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notes)
);
