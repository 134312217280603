import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';


const JobCard = styled.div`
  border-radius: 0.5rem;
  background: ${opts => opts.color};
  border: 1px solid ${opts => opts.color};
`;
//const JobCardFlag = styled.div`
//  border-radius: 0.5rem 0rem 0rem 0.5rem;
//  background: ${opts => opts.color};
//  width: 4px;
//  min-height: 100%;
//`;
const JobImg = styled.img`
  align-self: center;
  border-radius: 0.25rem;
  margin-left: 0px !important;
  max-height: 48px;
  width: auto;
`;
const StageContainer = styled.div`
  cursor: pointer;
  color: ${colors.grey4};
`;
const StageThumbnail = styled.div`
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  background: ${opts => opts.bg};
  color: #fff;

  svg {
    stroke: #fff;
  }
`;


class Stage extends React.Component {
  constructor(opts) {
    super(opts);
    this.ref = null;
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    if (this.ref !== null) {
      const {jobId, locationId, stage, employeeId, onToggle} = this.props;
      onToggle(jobId, locationId, stage, employeeId, this.ref);
    }
  }
  render() {
    const {employeeId, employees} = this.props;
    return (
      <StageContainer
        ref={ref => this.ref = ref}
        className='d-flex flex-column align-items-center'
      >
        <StageThumbnail
          className='rounded-circle'
          onClick={this.onClick}
          bg={
            employeeId === null ?
              colors.grey4 :
              employees[employeeId].avatarBg
          }
        >
          {employeeId === null ?
            <Icons.User width={16} height={16} /> :
            <span title={employees[employeeId].name}>{employees[employeeId].initials}</span>
          }
        </StageThumbnail>
      </StageContainer>
    );
  }
};


export default stage => ({task, employees, onToggleEmployees}) => (
  <div className='col-sm-6 col-md-4 col-lg-3 mt-2'>
    <JobCard
      className='d-flex flex-row mx-1 h-100'
      color={colors[task.alertColor || task.flag]}
    >
      <div className='d-flex flex-column justify-content-between flex-grow-1 p-2'>
        <div className='d-flex flex-row justify-content-between'>
          <div>
            <NavLink to={`/job/${task.jobId}`} className='text-body font-weight-bold'>
              {task.jobNumber}
            </NavLink>
          </div>
          <div className='text-center'>{task.rego}</div>
          <div className='text-right'>{task.startDateStr} | {task.finishDateStr}</div>
        </div>
        <div className='d-flex flex-row justify-content-between'>
          <div className='text-truncate text-uppercase'>{task.makeModel}</div>
          <Stage
            jobId={task.jobId}
            locationId={task.locationId}
            stage={stage}
            employeeId={task.employeeId}
            employees={employees}
            onToggle={onToggleEmployees}
          />
        </div>
      </div>
      {task.image64 && <JobImg src={task.image64} className='m-2' />}
    </JobCard>
  </div>
);
