import React from 'react';

import colors from 'colors';

import Field from 'components/Field.jsx';


export default class SettingsCompany extends React.Component {
  constructor(opts) {
    super(opts);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onChange(field, e) {
    this.props.onChange({
      [field]: e,
      [`${field}Error`]: false
    });
  }
  onDelete() {
    const {
      onDelete, id
    } = this.props;
    onDelete(id);
  }
  onSave() {
    const {
      onSave,
      id, name, jurisdiction, abn, licenseNumber, address, phone,
      logo, bsb, accountNumber,
    } = this.props;
    onSave(id, {name, jurisdiction, abn, licenseNumber, address, phone,
      logo, bsb, accountNumber});
  }
  render() {
    console.log('settingscompany:render');
    const {onClose} = this.props;
    const {
      id,
      name, nameError,
      jurisdiction, jurisdictionError,
      abn, abnError,
      licenseNumber, licenseNumberError,
      address, addressError,
      phone, phoneError,
      logo, logoError,
      bsb, bsbError,
      accountNumber, accountNumberError,      
    } = this.props;
    return (
      <React.Fragment>
        <div className='modal-body'>
          <Field
            title='Name'
            background={colors.grey1}
            uppercaseOnly={true}
            value={name}
            error={nameError}
            onChange={this.onChange.bind(this, 'name')} />
          <Field
            title='Jurisdiction'
            background={colors.grey1}
            value={jurisdiction}
            error={jurisdictionError}
            onChange={this.onChange.bind(this, 'jurisdiction')} />
          <Field
            title='ABN'
            background={colors.grey1}
            value={abn}
            error={abnError}
            onChange={this.onChange.bind(this, 'abn')} />
          <Field
            title='License #'
            background={colors.grey1}
            uppercaseOnly={true}
            value={licenseNumber}
            error={licenseNumberError}
            onChange={this.onChange.bind(this, 'licenseNumber')} />
          <Field
            title='Address'
            background={colors.grey1}
            value={address}
            error={addressError}
            onChange={this.onChange.bind(this, 'address')} />
          <Field
            title='Phone'
            background={colors.grey1}
            value={phone}
            error={phoneError}
            onChange={this.onChange.bind(this, 'phone')} />
          <Field
            title='Logo'
            background={colors.grey1}
            value={logo}
            error={logoError}
            onChange={this.onChange.bind(this, 'logo')} />
          <Field
            title='BSB'
            background={colors.grey1}
            value={bsb}
            error={bsbError}
            onChange={this.onChange.bind(this, 'bsb')} />
          <Field
            title='Account #'
            background={colors.grey1}
            value={accountNumber}
            error={accountNumberError}
            onChange={this.onChange.bind(this, 'accountNumber')} />
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button
              type='button'
              className='btn btn-danger mr-auto'
              onClick={this.onDelete}
            >Delete</button>
          }
          <button
            type='button'
            className='btn btn-secondary'
            onClick={onClose}
          >Close</button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onSave}
          >Save</button>
        </div>
      </React.Fragment>
    );
  }
};
