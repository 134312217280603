import {connect} from 'react-redux';

import UserDetails from './component.jsx';


const save = details => ({
  type: 'SUPPLIER-USER-DETAILS-SET',
  ...details
});

const mapStateToProps = (state, props) => {
  const {supplierUserDetails} = state;
  return {
    open: supplierUserDetails === null,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  //onClose: () => dispatch(close()),
  onSave: details => dispatch(save(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetails);

