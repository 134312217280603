import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Production from './component.jsx';


const setFilteredEmployee = employee => ({
  type: 'PRODUCTION-SET-FILTERED-EMPLOYEE',
  employee
});

const load = (API, locationId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/production/${locationId}`, {method: 'GET'})
      .then(res => {
        if (res && res.error === true) {
          dispatch({
            type: 'PRODUCTION-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'PRODUCTION-SET',
          ...res
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    locationId,
    params: {
      employees: {index: employeeIndex},
      locations,
    },
    production: {
      notFound,
      filteredEmployee,
      employeeLoads
    }
  } = state;
  let employeeList = [];
  if (locationId !== null) {
    employeeList = locations.index[locationId].productionEmployees;
  }
  return {
    notFound,
    locationId,
    filteredEmployee,
    employeeIndex,
    employeeList,
    employeeLoads,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: locationId => dispatch(load(API, locationId)),
  onFilterEmployee: employee => dispatch(setFilteredEmployee(employee)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Production)
);
