import React from 'react';

export default {
  width: 606,
  height: 1027,
  template: (
    <g transform='translate(1.000, 0.000)'>
      <path d='M427.5,127.8 L431.8,124.6 L442.3,116.8 C442.9,116.2 444.6,114.9 445.2,113.9 C446.1,112.6 446.8,111.4 447.3,109.9 C448.5,105.7 449.7,100.4 450.9,94.6 L452.4,86 C453.2,82.3 454.4,72.9 454.5,69.2 C455.3,44 453,33.7 452.2,14.6 C452.2,14.4 452.2,14.2 452.2,13.9 L452.2,7.5 L452.9,7.3 C453.6,7 454,6.3 453.8,5.7 C453.4,4.1 452.9,3.3 451.7,2.8 L450.5,1.4 C449.7,0.5 448.3,0.3 447.3,0.9 L440.9,4.6 C440,5.1 438.9,5.4 437.7,5.5 L412.4,6.4 L399,6.7 C394.8,6.5 386.8,3.9 380.7,3.4 L370.4,3.3 C370.4,3.3 355.3,3.3 351,3.3 L315.7,3.3 C310.3,3.3 303.5,3.3 297.7,3.3 C291.9,3.3 285.1,3.3 279.7,3.3 L244.4,3.3 C240.1,3.3 225,3.3 225,3.3 L214.7,3.4 C208.6,3.9 200.6,6.5 196.4,6.7 L183.1,6.4 L157.8,5.5 C156.7,5.5 155.6,5.2 154.6,4.6 L148.2,0.9 C147.2,0.3 145.8,0.5 145,1.4 L143.8,2.8 C142.6,3.3 142.1,4.1 141.7,5.7 C141.5,6.4 141.9,7.1 142.6,7.3 L143.3,7.5 L143.3,13.9 C143.3,14.1 143.3,14.3 143.3,14.6 C142.4,33.7 140.2,44 141,69.2 C141.1,73 142.3,82.3 143.1,86 L144.6,94.6 C145.8,100.4 147,105.8 148.2,109.9 C148.6,111.3 149.4,112.5 150.3,113.9 C150.9,114.9 152.6,116.2 153.2,116.8 L163.7,124.6 L168,127.8 C168,127.8 304,170.5 427.5,127.8 Z' stroke='#1D1D1B' fill='#FFFFFF' fillRule='nonzero' />
      <path d='M180.6,649.9 C163.7,724.4 159.4,794.9 162.4,834 L163.3,845.6 L169.6,894.9 L231.9,918.7 L369.8,916.8 L435.8,894.8 L441.4,845.5 L442.2,833.9 C444.8,794.8 437.9,725.3 421.7,649.8 C278.8,731 180.6,654.4 180.6,654.4' stroke='#1D1D1B' fill='#FFFFFF' fillRule='nonzero' />
      <path d='M432.1,705 C429.7,691.2 424.8,664.2 421.7,649.9 C421.7,649.9 423,678.5 392.5,688.8 C362,699.1 344,701.1 296,701 C252.3,700.5 218.3,694.9 198.5,683.2 C188.7,677 180.4,663.6 180.6,649.9 C177.3,664.7 173.2,690.8 170.8,705 C196.7,722.3 321.1,770.3 432.1,705 Z' stroke='#1D1D1B' fill='#FFFFFF' fillRule='nonzero' />
      <path d='M395.8,557.1 C386,511.4 391.7,395.9 397.3,305.4 L401.7,302.8 C396.8,393.5 390.4,509.6 400,556 C400,556.1 397.1,556.8 395.8,557.1 Z' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M206.5,557.1 C216.3,512.8 210.6,398.5 204.9,305.4 C204.9,305.4 200.5,302.9 200.5,302.9 C206.2,395.1 212,511.1 202.4,556.1 C202.3,556.1 205.1,556.8 206.5,557.1 Z' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M301.1,305.6 C301.1,305.6 225.1,305.8 206.6,305.6 C206,305.6 205.4,305.5 204.9,305.4 L202.5,304 L200.5,302.8 C199.4,287.7 198.9,263.1 198.9,247.2 C198.9,225.5 199.9,205 201,193.5 C201.2,191.6 202.4,190 204,189.3 C221.6,182.1 238,177.7 256.6,175.3 C266.3,174 276.7,173.3 288.4,173 L291.6,172.9 C293.8,172.9 296.3,172.9 298.7,172.9 L301,172.9 C304.2,172.9 307.4,172.9 310.4,173 L313.6,173.1 C325.3,173.4 335.7,174.1 345.3,175.4 C363.9,177.9 380.3,182.2 397.9,189.4 C399.6,190.1 400.8,191.7 400.9,193.6 C402,205.1 403,225.6 403,247.3 C403,263.2 402.4,287.8 401.4,302.9 L399.4,304.1 L397,305.5 C396.4,305.6 395.9,305.7 395.3,305.7 C377.1,305.8 315.5,305.7 301.1,305.6 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#808080' fillRule='nonzero' />
      <path d='M180.6,649.9 C180.6,646.8 181,643.6 181.7,640.6 L200.3,564 L202.2,556.1 L205.4,557 C238.1,563.3 265.4,562.1 301,562.1 C336.6,562.1 366,563.4 396.6,557 L399.8,556.1 L401.7,564 L420.3,640.6 C421,643.7 421.4,646.8 421.4,649.9 C421.4,665.3 412.1,679.7 397.8,686.4 C376.9,696.2 345.2,701 300.9,701 C256.6,701 224.8,696.2 204,686.4 C189.9,679.7 180.6,665.4 180.6,649.9 C180.6,649.9 180.6,649.9 180.6,649.9 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#808080' fillRule='nonzero' />
      <path d='M195.9,29.3 C203.5,38.4 204.7,42 211.4,42 L295,42 L297.8,42 L381.4,42 C388.1,42 389.3,38.3 396.9,29.3 C398.6,27.4 397.7,23.5 395.1,23.2 L384.6,21 C381,20.1 377.1,20 371,20 L297.7,20 L295,20 L221.7,20 C215.6,19.9 211.7,20 208.2,20.9 L197.7,23.1 C195.1,23.4 194.2,27.4 195.9,29.3 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
      <path d='M301.2,300.5 C301.2,300.5 231.1,300.7 214,300.5 C213.5,300.5 212.9,300.4 212.4,300.3 L210.2,299 C209.2,285.1 208.6,261.2 208.6,245.9 C208.6,225.9 209.5,207.6 210.4,197 C210.6,195.2 211.7,193.6 213.2,193 C228.9,186.6 243.6,182.6 260.3,180.4 C269.2,179.2 272.5,179 277.8,178.7 L283,182.2 C285.1,182.2 300.6,181.9 300.6,181.9 C303.5,181.9 315.4,182.1 318.2,182.2 L323.5,178.6 C328,178.8 332.9,179.1 341.8,180.3 C358.5,182.5 373.2,186.4 388.9,192.9 C390.5,193.5 391.6,195.1 391.7,196.9 C392.6,207.5 393.5,225.8 393.5,245.8 C393.5,261.1 393,285 391.9,298.9 L389.7,300.2 C389.2,300.3 388.7,300.4 388.1,300.4 C371.3,300.7 314.6,300.6 301.2,300.5 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#CFCFCF' fillRule='nonzero' />
      <path d='M301.1,694.7 C257.1,694.7 223.5,689.9 200.7,677.9 C190.4,672.5 182.6,654.8 184.6,645.8 L202.6,567.5 C202.9,566.2 203.7,565 204.9,564.3 C206.1,563.6 207.5,563.5 208.8,563.8 C241,569.8 269.9,568.3 301,568.3 C332.2,568.3 360.1,569.7 393.2,563.8 C394.6,563.6 396,563.6 397.1,564.3 C398.3,565 399.1,566.2 399.4,567.5 L417.4,645.8 C419.4,654.8 409,673 401.1,677.9 C378.6,692.1 347.5,694.8 301.1,694.7 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#CFCFCF' fillRule='nonzero' />
      <path d='M160.7,39.6 C155.6,39.6 154.7,39.6 153.5,40.8 L151.3,43.6 C150,45.4 150,46.8 150.2,52.8 L150.5,56.8 C150.8,60 153.3,60.8 157.5,61 L176.8,61.5 C189,61.5 193.3,60.3 189.9,55.4 L182.4,44.5 C180.6,42.3 179,40.6 173.1,40.2 C168.9,39.7 165,39.5 160.7,39.6 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
      <path d='M432,39.6 C437.1,39.6 438,39.6 439.2,40.8 L441.4,43.6 C442.7,45.4 442.7,46.8 442.5,52.8 L442.2,56.8 C441.9,60 439.4,60.8 435.2,61 L415.9,61.5 C403.7,61.5 399.4,60.3 402.8,55.4 L410.3,44.5 C412.1,42.3 413.7,40.6 419.6,40.2 C423.9,39.7 427.7,39.5 432,39.6 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
      <path d='M328.2,174 C323.4,179.6 319.7,182.3 318.5,182.3 L301.3,182 L300.6,182 L283.4,182.3 C282.2,182.3 278.5,179.7 273.7,174 C282,173.3 294.8,172.9 301.3,172.9 C307.5,172.9 319.5,173.2 328.2,174 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#808080' fillRule='nonzero' />
      <path d='M326.1,174.9 C323.7,177.6 319.3,181.1 318.4,181.1 L301.2,180.8 L300.5,180.8 L283.3,181.1 C282.5,181.1 278.3,177.7 275.9,175 C280.9,174.4 295.4,174 300.9,174 C306.4,174 320.3,174.3 326.1,174.9 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero' />
      <path d='M171.3,119.7 C168.8,118.2 168.7,116.2 168.8,114.3 L170.6,107.9 C171.6,105.3 175,103.6 176.9,102.6 C185.4,98.5 194.4,98 202,96.5 L186,127 C181.8,125.1 171.3,119.7 171.3,119.7 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#E11119' fillRule='nonzero' />
      <path d='M160.8,57.8 L160.1,42 L156.2,42 C154.8,42 152.6,43.5 152.6,45.1 C152.8,50.4 152.9,57.8 156.1,57.8 L160.8,57.8 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero' />
      <path d='M422.2,119.7 C424.7,118.2 424.8,116.2 424.7,114.3 L422.9,107.9 C421.9,105.3 418.5,103.6 416.6,102.6 C408.1,98.5 399.1,98 391.5,96.5 L407.5,126.9 C411.7,125.1 422.2,119.7 422.2,119.7 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#E11119' fillRule='nonzero' />
      <path d='M431.9,57.8 L432.6,42 L436.5,42 C437.9,42 440.1,43.5 440.1,45.1 C439.9,50.4 439.8,57.8 436.6,57.8 L431.9,57.8 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero' />
      <path d='M296.4,113.4 C286.7,113.4 255,113.4 255,113.4 C237.1,113.4 238.6,112.3 225.9,100.8 L219.1,94.9 L200.5,99.1' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M297.7,78.3 C293.6,78.3 262.6,77.7 239.4,78.9 C233.4,79.2 231.1,80.5 225.9,85.6 C223.4,88 220.3,92 219.2,94.8' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M198.2,89.1 C203.7,82.1 211.1,69 211,64.8 C211,60.3 194.6,36.1 188.9,33.7 C182.9,31.5 156.6,30.4 151.7,32.6 C149.2,33.9 149.6,40.3 150,46.4' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M296.3,113.4 C306,113.4 337.7,113.4 337.7,113.4 C355.6,113.4 354.1,112.3 366.8,100.8 L373.6,94.9 L392.2,99.1' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M295,78.3 C299.1,78.3 330.1,77.7 353.3,78.9 C359.3,79.2 361.6,80.5 366.8,85.6 C369.3,88 372.4,92 373.5,94.8' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M394.5,89.1 C389,82.1 381.6,69 381.7,64.8 C381.7,60.3 398.1,36.1 403.8,33.7 C409.8,31.5 436.1,30.4 441,32.6 C443.5,33.9 443.1,40.3 442.7,46.4' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M211.1,64.8 L382,64.8' stroke='#000000' strokeWidth='0.5' />
      <path d='M232,142.5 C218.9,156.6 213.4,166.7 202.3,190.6' stroke='#1D1D1B' strokeWidth='0.5' />
      <path d='M370.1,142.6 C383.2,156.7 387.9,165.9 398.9,189.7' stroke='#1D1D1B' strokeWidth='0.5' />
      <rect stroke='#1D1D1B' strokeWidth='0.5' fill='#737373' x='254.5' y='85.8' width='83.9' height='20.7' />
      <path d='M258.3,105.8 L334.7,105.8 C336.2,105.8 337.4,104.8 337.4,103.5 L337.4,88.8 C337.4,87.6 336.2,86.5 334.7,86.5 L258.3,86.5 C256.8,86.5 255.6,87.5 255.6,88.8 L255.6,103.5 C255.6,104.8 256.8,105.8 258.3,105.8 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#FFFFFF' />
      <rect stroke='#1D1D1B' opacity='0.7' fillRule='nonzero' x='213.8' y='309.3' width='31' height='246.2' />
      <rect stroke='#1D1D1B' opacity='0.7' fillRule='nonzero' x='356.5' y='309.3' width='31' height='246.2' />
      <g transform='translate(0.000000, 121.000000)' stroke='#1D1D1B'>
        <path d='M67.1,0.6 C78.2,0.4 85.4,4.6 92.1,9.7 C93.4,10.7 95,11.2 96.6,11.3 C109.6,11.7 118,12.5 130.8,15.4 C132.3,15.7 134,15.6 135.4,15 C141.4,12.6 145.1,13.2 145.8,16.5 C145.8,16.6 153.2,62.2 153.3,62.2 L166,109.6 C175.6,139.4 194.3,193.8 197.6,221.7 C207.1,299.4 205.4,371.4 200.7,407.5 C199.9,413.7 195.7,429.4 195.7,429.4 C183.6,476.8 137.3,556.6 135.7,557.4 C134.2,558.8 134.6,559.6 134.4,561.2 C134.2,563 134.1,563.7 133.9,565.6 C131.1,593.1 125.7,631.9 125.7,631.9 C120.4,671 103.5,723.9 84.3,748.7 C83.8,749.5 83,750.2 82.2,750.9 C79.8,752.9 76.8,754 73.7,754.1 C70.2,754.2 64,754.4 61.4,754.3 C61,754.3 60.6,754.5 60.4,754.8 C60,755.5 59.2,755.9 58.3,756 L30,756.3 L13.1,756.3 C11.8,756.3 10.6,756.2 9.4,755.9 L7.6,755.5 C6.4,755.2 5.5,754.2 5.4,753 C5.4,753 5.4,753 5.4,753 C3.3,724.7 1.9,711.8 1.8,684.5 L4.9,684.5 C113,692.4 117,543.7 1.6,549.6 L0,549.6 L1.7,225.7 L3.9,225.8 C7.8,226 16.3,226.1 22.6,226 C107,223.9 109.3,105.3 27.5,95.6 C27.5,95.6 27.5,95.6 27.5,95.6 C24.3,95.2 20.9,94.9 17.4,94.7 C16.1,94.6 14.8,94.6 13.5,94.6 L3.3,94.6 L5.4,84.8 L7.9,72.6 C8.7,69.5 9.5,67.9 11.1,66.7 L12.7,65.9 C13.3,65.6 13.7,65 13.8,64.3 C14.7,55.9 20.9,19 26.3,6.8 C29.5,-1.7 44.3,0.8 67.1,0.6 Z' fill='#FFFFFF' fillRule='nonzero' />
        <path d='M156,155.7 C155.6,155.1 154.8,155 154.2,155.1 L153.4,134.4 C152.2,134.3 150.3,149.2 149.1,156.5 C142.9,194.2 138.2,240.2 134.9,327.5 C131.7,403.3 127.8,475.9 122.9,534.6 C126,531.1 139.9,511.4 139.9,511.4 L137.3,506.1 C160.8,476.9 183.7,442.6 188.7,395.4 C189.2,390.6 191.6,363.6 191.6,342.4 C191.6,339.7 191.6,329.6 191.6,326.9 C191.6,296.5 186.7,259.3 184.5,246.4 C178.1,208.4 165.1,168.2 156,155.7 Z' strokeWidth='0.5' fill='#808080' fillRule='nonzero' />
        <path d='M36.5,34.9 C36.5,39.7 36,42.8 37.9,45.7 L40.8,49.8 C42.4,51.6 43.6,51.4 45.7,50.1 L56.1,43.7 C57.5,42.8 58.4,41.2 58.5,37.3 L59.2,15.8 C59.5,8.3 58.8,4.1 53.9,4.1 L46.8,4.3 C40.1,4.7 38.9,13.4 38,19.3 C37,25.3 36.5,28.7 36.5,34.9 Z' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M36.6,755.3 C38.8,754.9 40.7,753.4 41.5,751.3 L49.8,729.5 C51,726.3 50.4,720.5 46.1,717.6 L33.1,709.8 C30.9,708.5 28.1,709.1 26.4,711 L16.3,723.4 C14.9,725.1 14.2,727.2 14.3,729.3 L14.5,738.6 C14.6,741.7 16.6,744.5 19.5,745.5 L28.5,748.6 C30.5,749.3 31.2,749.2 31.2,750.4 L31.3,754.5 C31.3,754.9 31.5,755.3 31.8,755.6 C32.1,755.9 32.5,756 33,755.9 L36.6,755.3 Z' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M55.7,38 C55.7,37.7 55.5,37.5 55.2,37.5 L40.5,37.5 L39.7,38.2 L39.7,41.5 C39.7,41.9 40.9,43.1 41.4,43.1 C44.7,43.1 48.7,42.5 53.3,40.9 C54.6,40.5 55.5,40.2 55.7,38.5 C55.7,38.4 55.7,38.2 55.7,38 Z' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero' />
        <path d='M30.1,725.2 C25.9,725.2 22.5,726.8 22.5,727.2 C22.5,728.3 25.9,729.2 30.1,729.2 C34.3,729.2 37.7,728.3 37.7,727.2 C37.7,726.8 33.4,725.2 30.1,725.2 Z' strokeWidth='0.5' fill='#EDEDED' fillRule='nonzero' />
        <path d='M128.8,34.9 C127.8,37.5 125.9,38.2 123.9,38.5 L117,38.6 C114.1,38.4 111.5,35.7 110.1,34.2 C103.9,27.6 101.4,19.5 98,13.1 L132.8,19.6 C131.9,24 128.8,34.9 128.8,34.9 Z' strokeWidth='0.5' fill='#E11119' fillRule='nonzero' />
        <path d='M153.4,134.3 L154.2,155 C155.4,155.1 155.5,155.2 156,155.6 C163.4,165.5 171,189.4 175.4,205.3 C177.9,214.3 182.9,234.9 186.6,260.7 C187.3,265.5 191.5,308 191.5,315.6 C191.5,338.5 191.5,352.4 189.9,380.3 C188.6,411.5 181.7,451.4 137.3,505.9 L139.9,511.2 C163.1,481.3 191,436.3 194,382.8 C194.3,376.7 195.7,355.8 195.7,332.2 C195.8,292.6 192.4,273.9 191.6,267.4 C185.5,220.4 160.9,133 153.4,134.3 Z' strokeWidth='0.5' fill='#BCBCBB' fillRule='nonzero' />
        <path d='M62.5,750.3 L71.2,728.1 L71.3,728.7 C71.3,728.4 71.4,728.1 71.4,727.7 C71.5,727.3 71.5,726.8 71.6,726.1 L85.2,697.5 L100.1,671.8 C100.2,671.6 100.5,671.4 100.7,671.4 L100.8,671.4 C100.9,670.7 101.1,670 101.2,669.4 L101.4,669 L102.1,669 L102.3,669.2 C102.7,669.7 103.2,672.4 102.4,676.2 L88,743.6 L87.9,743.8 C82.5,753.3 77,754.8 66,754.8 C66,754.8 66,754.8 66,754.8 C64.3,754.8 62.5,754.8 60.5,754.7 L59.4,754.7 L62.5,750.3 Z' fill='#EDEDED' fillRule='nonzero' strokeLinejoin='round' />
        <path d='M115.4,132.3 L115.4,163.1 C115.4,165.3 117.2,167.1 119.4,167.1 L136.1,167.1 C138.3,167.1 140.1,165.3 140.1,163.1 L140.1,132.3 C140.1,130.1 138.3,128.3 136.1,128.3 L119.4,128.3 C117.2,128.3 115.4,130.1 115.4,132.3 Z' strokeWidth='0.5' />
        <path d='M112.7,402.9 C113,403.1 113.4,403.1 113.7,402.9 C114,402.7 114.2,402.4 114.2,402.1 L117.5,354.1 C117.5,353.8 117.4,353.4 117.1,353.2 C116.8,353 116.5,352.9 116.1,353 L114.2,353.7 C111.2,354.8 109.1,357.5 108.9,360.6 L108.4,367.7 C108.3,368.6 108.4,369.5 108.7,370.3 L109.2,371.9 L109.2,388.7 L107.9,390.5 C107.3,391.3 107,392.3 107,393.2 L107,395 C107,397.9 108.6,400.6 111.2,402 L112.7,402.9 Z' strokeWidth='0.5' />
        <path d='M125.2,240 C125.5,240.2 125.9,240.2 126.2,240 C126.5,239.8 126.7,239.5 126.7,239.2 L130,191.2 C130,190.9 129.9,190.5 129.6,190.3 C129.3,190.1 129,190 128.6,190.1 L126.7,190.8 C123.7,191.9 121.6,194.6 121.4,197.7 L120.9,204.8 C120.8,205.7 120.9,206.6 121.2,207.4 L121.7,209 L121.7,225.8 L120.4,227.6 C119.8,228.4 119.5,229.4 119.5,230.3 L119.5,232.1 C119.5,235 121.1,237.7 123.7,239.1 L125.2,240 Z' strokeWidth='0.5' />
        <path d='M123,534.6 L111.2,538.6 C103.6,541.2 95.7,542.5 87.6,542.5 C62.3,542.4 15.8,538.3 15.8,529.1 L23.1,266.2 C23.3,260.2 25.4,254.5 29.3,249.9 C32.5,246.1 36.8,242.3 42.6,237.8 C73.9,214.9 97.1,194.8 121.9,181.9 C127,179.2 132.4,179.1 134.9,181.2 L144.6,189.5 L177.3,211.5 L181.4,214.1' strokeWidth='0.5' />
        <path d='M20.8,347 L27.9,347.4 C56.7,352.4 111.2,351.7 126.8,346.9 L134.3,344.9 L191.7,333 L195.9,332.9' strokeWidth='0.5' />
        <ellipse strokeWidth='0.5' cx='30.1' cy='731.1' rx='10.6' ry='5.9' />
      </g>
      <g transform='translate(399.000000, 121.000000)' stroke='#1D1D1B'>
        <path d='M138,0.6 C126.9,0.4 119.7,4.6 113,9.7 C111.7,10.7 110.1,11.2 108.5,11.3 C95.5,11.7 87.1,12.5 74.3,15.4 C72.8,15.7 71.1,15.6 69.7,15 C63.7,12.6 60,13.2 59.3,16.5 C59.3,16.6 51.9,62.2 51.8,62.2 L39,109.6 C29.4,139.4 10.7,193.8 7.4,221.7 C-2.1,299.4 -0.4,371.4 4.3,407.5 C5.1,413.7 9.3,429.4 9.3,429.4 C21.4,476.8 67.7,556.6 69.3,557.4 C70.8,558.8 70.4,559.6 70.6,561.2 C70.8,563 70.9,563.7 71.1,565.6 C73.9,593.1 79.3,631.9 79.3,631.9 C84.6,671 101.5,723.9 120.7,748.7 C121.2,749.5 122,750.2 122.8,750.9 C125.2,752.9 128.2,754 131.3,754.1 C134.8,754.2 141,754.4 143.6,754.3 C144,754.3 144.4,754.5 144.6,754.8 C145,755.5 145.8,755.9 146.7,756 L175,756.3 L191.9,756.3 C193.2,756.3 194.4,756.2 195.6,755.9 L197.4,755.5 C198.6,755.2 199.5,754.2 199.6,753 C199.6,753 199.6,753 199.6,753 C201.7,724.7 203.1,711.8 203.2,684.5 L200.1,684.5 C92,692.4 88,543.7 203.4,549.6 L205,549.6 L203.3,225.7 L201.1,225.8 C197.2,226 188.7,226.1 182.4,226 C98,223.9 95.7,105.3 177.5,95.6 C177.5,95.6 177.5,95.6 177.5,95.6 C180.7,95.2 184.1,94.9 187.6,94.7 C188.9,94.6 190.2,94.6 191.5,94.6 L201.7,94.6 L199.6,84.8 L197.1,72.6 C196.3,69.5 195.5,67.9 193.9,66.7 L192.3,65.9 C191.7,65.6 191.3,65 191.2,64.3 C190.3,55.9 184.1,19 178.7,6.8 C175.6,-1.7 160.7,0.8 138,0.6 Z' fill='#FFFFFF' fillRule='nonzero' />
        <path d='M49,155.7 C49.4,155.1 50.2,155 50.8,155.1 L51.6,134.4 C52.8,134.3 54.7,149.2 55.9,156.5 C62.1,194.2 66.8,240.2 70.1,327.5 C73.3,403.3 77.2,475.9 82.1,534.6 C79,531.1 65.1,511.4 65.1,511.4 L67.7,506.1 C44.2,476.9 21.3,442.6 16.3,395.4 C15.8,390.6 13.4,363.6 13.4,342.4 C13.4,339.7 13.4,329.6 13.4,326.9 C13.4,296.5 18.3,259.3 20.5,246.4 C26.9,208.4 39.9,168.2 49,155.7 Z' strokeWidth='0.5' fill='#808080' fillRule='nonzero' />
        <path d='M168.5,34.9 C168.5,39.7 169,42.8 167.1,45.7 L164.2,49.8 C162.6,51.6 161.4,51.4 159.3,50.1 L148.9,43.7 C147.5,42.8 146.6,41.2 146.5,37.3 L145.8,15.8 C145.5,8.3 146.2,4.1 151.1,4.1 L158.2,4.3 C164.9,4.7 166.1,13.4 167,19.3 C168,25.3 168.5,28.7 168.5,34.9 Z' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M168.4,755.3 C166.2,754.9 164.3,753.4 163.5,751.3 L155.2,729.5 C154,726.3 154.6,720.5 158.9,717.6 L171.9,709.8 C174.1,708.5 176.9,709.1 178.6,711 L188.7,723.4 C190.1,725.1 190.8,727.2 190.7,729.3 L190.5,738.6 C190.4,741.7 188.4,744.5 185.5,745.5 L176.5,748.6 C174.5,749.3 173.8,749.2 173.8,750.4 L173.7,754.5 C173.7,754.9 173.5,755.3 173.2,755.6 C172.9,755.9 172.5,756 172,755.9 L168.4,755.3 Z' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M149.3,38 C149.3,37.7 149.5,37.5 149.8,37.5 L164.5,37.5 L165.3,38.2 L165.3,41.5 C165.3,41.9 164.1,43.1 163.6,43.1 C160.3,43.1 156.3,42.5 151.7,40.9 C150.4,40.5 149.5,40.2 149.3,38.5 C149.3,38.4 149.3,38.2 149.3,38 Z' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero' />
        <path d='M174.9,725.2 C179.1,725.2 182.5,726.8 182.5,727.2 C182.5,728.3 179.1,729.2 174.9,729.2 C170.7,729.2 167.3,728.3 167.3,727.2 C167.3,726.8 171.7,725.2 174.9,725.2 Z' strokeWidth='0.5' fill='#EDEDED' fillRule='nonzero' />
        <path d='M76.2,34.9 C77.2,37.5 79.1,38.2 81.1,38.5 L88,38.6 C90.9,38.4 93.5,35.7 94.9,34.2 C101.1,27.6 103.6,19.5 107,13.1 L72.2,19.6 C73.1,24 76.2,34.9 76.2,34.9 Z' strokeWidth='0.5' fill='#E11119' fillRule='nonzero' />
        <path d='M51.7,134.3 L50.9,155 C49.7,155.1 49.6,155.2 49.1,155.6 C41.7,165.5 34.1,189.4 29.7,205.3 C27.2,214.3 22.2,234.9 18.5,260.7 C17.8,265.5 13.6,308 13.6,315.6 C13.6,338.5 13.6,352.4 15.2,380.3 C16.5,411.5 23.4,451.4 67.8,505.9 L65.2,511.2 C42,481.3 14.1,436.3 11.1,382.8 C10.8,376.7 9.4,355.8 9.4,332.2 C9.3,292.6 12.7,273.9 13.5,267.4 C19.5,220.4 44.2,133 51.7,134.3 Z' strokeWidth='0.5' fill='#BCBCBB' fillRule='nonzero' />
        <path d='M142.5,750.3 L133.8,728.1 L133.7,728.7 C133.7,728.4 133.6,728.1 133.6,727.7 C133.5,727.3 133.5,726.8 133.4,726.1 L119.8,697.5 L104.9,671.8 C104.8,671.6 104.5,671.4 104.3,671.4 L104.2,671.4 C104.1,670.7 103.9,670 103.8,669.4 L103.6,669 L102.9,669 L102.7,669.2 C102.3,669.7 101.8,672.4 102.6,676.2 L117,743.5 L117.1,743.7 C122.5,753.2 128,754.7 139,754.7 C139,754.7 139,754.7 139,754.7 C140.7,754.7 142.5,754.7 144.5,754.6 L145.6,754.6 L142.5,750.3 Z' fill='#EDEDED' fillRule='nonzero' strokeLinejoin='round' />
        <path d='M89.6,132.3 L89.6,163.1 C89.6,165.3 87.8,167.1 85.6,167.1 L68.9,167.1 C66.7,167.1 64.9,165.3 64.9,163.1 L64.9,132.3 C64.9,130.1 66.7,128.3 68.9,128.3 L85.6,128.3 C87.8,128.3 89.6,130.1 89.6,132.3 Z' strokeWidth='0.5' />
        <path d='M92.3,402.9 C92,403.1 91.6,403.1 91.3,402.9 C91,402.7 90.8,402.4 90.8,402.1 L87.5,354.1 C87.5,353.8 87.6,353.4 87.9,353.2 C88.2,353 88.5,352.9 88.9,353 L90.8,353.7 C93.8,354.8 95.9,357.5 96.1,360.6 L96.6,367.7 C96.7,368.6 96.6,369.5 96.3,370.3 L95.8,371.9 L95.8,388.7 L97.1,390.5 C97.7,391.3 98,392.3 98,393.2 L98,395 C98,397.9 96.4,400.6 93.8,402 L92.3,402.9 Z' strokeWidth='0.5' />
        <path d='M79.8,240 C79.5,240.2 79.1,240.2 78.8,240 C78.5,239.8 78.3,239.5 78.3,239.2 L75,191.2 C75,190.9 75.1,190.5 75.4,190.3 C75.7,190.1 76,190 76.4,190.1 L78.3,190.8 C81.3,191.9 83.4,194.6 83.6,197.7 L84.1,204.8 C84.2,205.7 84.1,206.6 83.8,207.4 L83.3,209 L83.3,225.8 L84.6,227.6 C85.2,228.4 85.5,229.4 85.5,230.3 L85.5,232.1 C85.5,235 83.9,237.7 81.3,239.1 L79.8,240 Z' strokeWidth='0.5' />
        <path d='M82.1,534.6 L93.9,538.6 C101.5,541.2 109.4,542.5 117.5,542.5 C142.8,542.4 189.3,538.3 189.3,529.1 L182,266.2 C181.8,260.2 179.7,254.5 175.8,249.9 C172.6,246.1 168.3,242.3 162.5,237.8 C131.2,214.9 108,194.8 83.2,181.9 C78.1,179.2 72.7,179.1 70.2,181.2 L60.5,189.5 L27.8,211.5 L23.7,214.1' strokeWidth='0.5' />
        <path d='M184.3,347 L177.2,347.4 C148.4,352.4 93.9,351.7 78.3,346.9 L70.8,344.9 L13.4,333 L9.2,332.9' strokeWidth='0.5' />
        <ellipse strokeWidth='0.5' cx='174.9' cy='731.1' rx='10.6' ry='5.9' />
      </g>
      <g transform='translate(124.000000, 877.000000)'>
        <path d='M15.1,17.5 C10.4,16.7 4.4,0.2 4.4,0.2 C2.1,8.2 6.83064716e-14,30.7 0.2,35.8 L0.8,96.3 L0.9,117.3 C1.1,121.7 -0.7,141.3 3,144.8 L5.2,146 C7.9,147.2 14.8,147.1 17.4,147.2 L46.5,146.8 L90.7,146 C91.2,146 91.7,145.8 92.1,145.4 L93.4,144 C93.4,144 94.6,144 95.5,144 C99,144 116.7,144 128.7,144.1 L176.6,144.2 L177.5,144.2 L225,144.3 C237,144.3 254,144.3 257.5,144.3 C258.4,144.3 260.3,144.3 260.3,144.3 L261.6,145.7 C262,146.1 262.4,146.3 263,146.4 L307.2,147.4 L336.3,147.9 C339,147.9 345.9,147.9 348.5,146.8 L350.7,145.6 C354.4,142.1 352.7,122.5 352.9,118.1 L353,97.1 L353.7,36.6 C353.9,31.5 351.9,9 349.6,1 C349.6,1 345,14.7 337.5,17.8 C316.4,26.6 257.9,38.4 248.2,40.4 L108,41.8 C98.3,39.7 39.8,22 16.6,17.2' stroke='#1D1D1B' />
        <path d='M339.7,17.7 L336.9,31.9 C336.2,35.3 334.3,38.2 331.7,39.9 C322.6,45.7 311.3,50.1 299.1,52.5 L297.7,51.2 C296.9,51.4 296,51.5 295,51.6 C287.9,52.5 277.9,52.5 269.8,52.4 L264.4,42.8 C271.5,38.8 283.8,33.7 298.1,28.9 C311,24.5 325.5,20.3 339,17.2 C339.2,17.2 339.3,17.2 339.4,17.3 C339.7,17.5 339.7,17.6 339.7,17.7 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M14.3,17.2 L17,31.4 C17.6,34.8 19.5,37.7 22.2,39.4 C31.2,45.3 42.6,49.6 54.7,52.1 L56.1,50.8 C56.9,51 57.8,51.1 58.8,51.2 C65.9,52.1 75.9,52.1 84,52.1 L89.4,42.5 C82.3,38.5 70,33.3 55.8,28.4 C42.9,23.9 28.4,19.7 14.9,16.6 C14.7,16.6 14.6,16.6 14.5,16.7 C14.3,16.9 14.2,17 14.3,17.2 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M350,137.1 C347.7,138.6 343.1,139.1 336.7,139 L262.8,138.9' stroke='#000000' strokeWidth='0.5' />
        <path d='M3.7,136.5 C6,138 10.6,138.5 16.9,138.5 L90.8,138.6' stroke='#000000' strokeWidth='0.5' />
        <path d='M246,103.4 C251.9,99.6 260.6,94.4 269.1,92.4 C295.1,86.8 327.9,79.3 334.1,80.9 C341.2,83.3 343.1,104.2 343.1,109.9 C343.1,113.2 334.7,122.9 326.5,125.4 C313.2,128.8 278.1,130.2 273.5,128.3 C269.9,127.2 262.1,114.6 256.5,105.2 C256.3,104.9 256.1,104.8 255.8,104.8 C251.7,105.3 244.7,104.2 246,103.4 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M107.8,103.2 C101.9,99.4 93.2,94.2 84.7,92.1 C58.7,86.4 26,78.8 19.7,80.4 C12.6,82.8 10.6,103.6 10.6,109.4 C10.6,112.7 18.9,122.4 27.1,125 C40.3,128.5 75.5,130 80.1,128.1 C83.7,127 91.5,114.4 97.2,105 C97.4,104.7 97.6,104.6 97.9,104.6 C102,105 109,104 107.8,103.2 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M260.5,141.1 C258.9,138.9 248.3,119.5 241,105.5 C237.7,107.5 236.1,108 231.4,108 L177,107.9 L176.6,107.9 L122.2,107.8 C117.6,107.8 115.9,107.3 112.6,105.3 C105.3,119.2 94.6,138.6 93,140.8 C91.5,143.9 95.9,143.9 99.3,143.9 L176.4,144 L176.8,144 L253.9,144.1 C257.5,144.2 262,144.2 260.5,141.1 Z' stroke='#000000' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero' />
        <path d='M265.6,43.1 L269.3,49.7 L270.4,51.7 L273.7,51.7 C282.2,51.7 291.7,51.6 297.6,50.4 L298,50.3 L298.5,50.8 L299.4,51.6 C311.6,49.1 322.9,44.7 331.6,38.9 C333.8,37.4 335.4,35 336,32.1 L338.8,17.9 C312.5,24 280.1,35.1 265.6,43.1 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#EDEDED' fillRule='nonzero' />
        <ellipse stroke='#1D1D1B' strokeWidth='0.500000782' fill='#EDEDED' fillRule='nonzero' transform='translate(323.049325, 108.352111) rotate(-89.898686) translate(-323.049325, -108.352111) ' cx='323.049325' cy='108.352111' rx='11.0000172' ry='9.10001423' />
        <path d='M88.3,42.8 L84.6,49.4 L83.5,51.4 L80.2,51.4 C71.7,51.4 62.2,51.2 56.3,50.1 L55.9,50 L55.4,50.5 L54.5,51.3 C42.3,48.8 31.1,44.3 22.3,38.5 C20.1,37 18.5,34.6 17.9,31.7 L15.2,17.5 C41.4,23.5 73.9,34.8 88.3,42.8 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#EDEDED' fillRule='nonzero' />
        <ellipse stroke='#1D1D1B' strokeWidth='0.500000782' fill='#EDEDED' fillRule='nonzero' transform='translate(30.691784, 107.861227) rotate(-89.898686) translate(-30.691784, -107.861227) ' cx='30.691784' cy='107.861227' rx='11.0000172' ry='9.10001423' />
        <path d='M336.7,33.9 L341.9,35 C344,35.5 345.1,36.6 346.7,39.4 L353.4,52.6' stroke='#1D1D1B' strokeWidth='0.5' />
        <path d='M17.2,33.3 L12.1,34.4 C10,34.9 8.9,36 7.4,38.7 L0.7,51.8' stroke='#1D1D1B' strokeWidth='0.5' />
        <rect stroke='#1D1D1B' strokeWidth='0.500000503' fill='#737373' transform='translate(176.652069, 100.637195) rotate(-89.918706) translate(-176.652069, -100.637195) ' x='165.052057' y='50.6371448' width='23.2000234' height='100.000101' />
        <path d='M222.2,89.9 L131,89.7 C129.2,89.7 127.8,90.8 127.8,92.2 L127.8,108.6 C127.8,110 129.2,111.1 131,111.1 L222.2,111.3 C224,111.3 225.4,110.2 225.4,108.8 L225.4,92.4 C225.4,91.1 224,89.9 222.2,89.9 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#FFFFFF' />
      </g>
    </g>
  ),
  panels: [
    {
      name: 'FRONT-BUMPER',
      transform: 'translate(123, 876)',
      paths: [
        'M354.4,98.7 L355.1,38.2 C355.3,33.3 353.4,10.7 351,2.4 L350.6,0.9 L350.1,2.4 C350.1,2.5 345.4,15.9 338.3,18.9 C337.8,19.1 337.3,19.3 336.8,19.5 C324.9,22.3 311.7,26.1 299.2,30.4 C299.1,30.4 299,30.5 298.9,30.5 C280.3,35 261.3,39 252.3,40.9 C250.9,41.2 249.8,41.4 249.1,41.6 L109,42.9 C105.2,42.1 94.6,39.1 81.2,35.2 C59.3,29 32,21.2 17.7,18.2 L17.6,18.5 C17,18.4 16.5,18.2 15.9,18.1 C15.7,18 15.4,18.1 15.2,18.3 C15.2,18.3 15.2,18.3 15.2,18.3 C11.7,16.1 7.4,5.8 5.9,1.7 L5.4,0.3 L5,1.8 C2.6,10.1 0.6,32.7 0.8,37.6 L1.4,98.1 L1.5,119.1 C1.5,120.1 1.5,121.8 1.4,124 C1.1,134.2 1,144.3 3.8,147 L6.1,148.3 C8.6,149.4 14.4,149.4 17.5,149.5 L18.5,149.5 L47.6,149.1 L91.8,148.3 C92.4,148.3 93.1,148 93.5,147.5 L94.6,146.2 L96.5,146.2 C100,146.2 117.7,146.2 129.7,146.3 L225.9,146.5 C237,146.5 252.4,146.5 257.4,146.5 L261,146.5 L262.1,147.8 C262.5,148.3 263.2,148.6 263.8,148.6 L308,149.6 L337.2,150.1 L338.2,150.1 C341.3,150.1 347.2,150 349.6,148.9 L351.8,147.7 L351.9,147.6 C354.7,144.9 354.7,134.8 354.3,124.6 C354.2,122.4 354.2,120.7 354.2,119.7 L354.4,98.7 Z'
      ]
    },
    {
      name: 'BONNET',
      transform: 'translate(162, 703)',
      paths: [
        'M8.6,0.797321952 C-1.23536767,75.449097 -0.41386214,93.5425158 1.4,131 L2.3,142.6 L8.6,191.9 L70.9,215.7 L208.8,213.8 L274.8,191.8 L280.4,142.5 L281.2,130.9 C282.392872,91.9704178 271.260517,0.797321952 271.260517,0.797321952 C271.260517,0.797321952 261.959859,7.54920428 235.37993,18.461042 C208.8,29.3728798 183.329843,35.4995193 135.344199,34.6002226 C91.6657092,33.781646 69.3602893,25.0471158 48.8056876,19.3356432 C30.417241,13.0674234 18.3104995,7.4789583 8.6,0.797321952 Z'
      ]
    },
    {
      name: 'PLENUM',
      transform: 'translate(170, 649)',
      paths: [
        'M262.1,56 C259.7,42.2 254.8,15.2 251.7,0.9 C251.7,0.9 253,29.5 222.5,39.8 C192,50.1 174,52.1 126,52 C82.3,51.5 48.3,45.9 28.5,34.2 C18.7,28 10.4,14.6 10.6,0.9 C7.3,15.7 3.2,41.8 0.8,56 C26.7,73.3 151.1,121.3 262.1,56 Z'
      ]
    },
    {
      name: 'LHF-GUARD',
      transform: 'translate(469, 651)',
      paths: [
        'M61.7,13.5 C118.4,10.6 121,0 121,0 L134.9,0 L135,20 L133.4,20 C18,14 22,163 130.1,155 L133.2,155 C133.2,182 132,195.3 130,223.6 C130,223.6 130,223.6 130,223.6 C130,224.8 128.9,225.8 127.7,226.1 L125.8,226.5 C124.6,226.8 123.2,226.9 122,226.9 L105,226.9 L76.7,226.6 C75.9,226.6 75.1,226.1 74.6,225.4 C74.4,225 74,224.8 73.6,224.9 C70.9,225 64.7,224.9 61.3,224.7 C58.2,224.6 55.2,223.5 52.8,221.5 C52,220.8 51.3,220.1 50.7,219.3 C31.4,194.4 14.6,141.6 9.3,102.5 C9.3,102.5 3.9,63.7 1.1,36.2 C0.9,34.3 0.7,33.6 0.6,31.8 C0.6,31.8 4.4,24.3 12.4,5.6 C23,10.3 32.4,13 61.7,13.5 Z'
      ]
    },
    {
      name: 'RHF-GUARD',
      transform: 'translate(0, 651)',
      paths: [
        'M73.3,13.5 C16.7,10.6 14,0 14,0 L0.1,0 L0,20 L1.6,20 C117,14 113,163 4.9,155 L1.8,155 C1.8,182 3,195.3 5,223.6 C5,223.6 5,223.6 5,223.6 C5,224.8 6.2,225.8 7.4,226.1 L9.3,226.5 C10.5,226.8 11.9,226.9 13.1,226.9 L30,226.9 L58.3,226.6 C59.1,226.6 59.9,226.1 60.4,225.4 C60.6,225 61,224.8 61.4,224.9 C64.1,225 70.3,224.9 73.7,224.7 C76.8,224.6 79.8,223.5 82.2,221.5 C83,220.8 83.7,220.1 84.3,219.3 C103.6,194.4 120.4,141.6 125.7,102.5 C125.7,102.5 131.1,63.7 133.9,36.2 C134.1,34.3 134.3,33.6 134.4,31.8 C134.4,31.8 130.6,24.3 122.6,5.6 C112,10.3 102.7,13 73.3,13.5 Z'
      ]
    },
    {
      name: 'LHF-DOOR',
      transform: 'translate(398, 453)',
      paths: [
        'M205.4,13 C183.1,15.6 123.2,21.7 93.7,17.3 C75.5,13.4 63.6,10.6 57.3,9.8 C50,8.5 15,0.7 11,0.8 C6.5,1 1.5,0.4 1.5,0.4 L1,0.4 C0.9,30.6 2.4,56.7 4.8,75.2 C5.6,81.4 9.8,97 9.8,97.1 C21.6,143 66.9,222.1 69.9,225.3 C70.9,226.2 70.9,226.8 71,227.8 C71,228.1 71,228.5 71.1,228.8 C71.2,230.1 71.6,229.8 71.6,229.8 C71.6,229.8 75.4,222.5 83.4,203.8 C94.1,208.5 103.4,211.4 132.7,211.9 C189.4,209.1 192,198.9 192,198.9 L206,198.9 L205.4,13 Z'
      ]
    },
    {
      name: 'RHF-DOOR',
      transform: 'translate(0, 453)',
      paths: [
        'M0.6,13 C22.9,15.6 82.8,21.7 112.3,17.3 C130.5,13.4 142.4,10.6 148.7,9.8 C156,8.5 191,0.7 195,0.8 C199.5,1 204.5,0.4 204.5,0.4 L205,0.4 C205.1,30.6 203.6,56.7 201.2,75.2 C200.4,81.4 196.2,97 196.2,97.1 C184.4,143 139.1,222.1 136.1,225.3 C135.1,226.2 135.1,226.8 135,227.8 C135,228.1 135,228.5 134.9,228.8 C134.8,230.1 134.4,229.8 134.4,229.8 C134.4,229.8 130.6,222.5 122.6,203.8 C111.9,208.5 102.6,211.4 73.3,211.9 C16.6,209.1 14,198.9 14,198.9 L-1.13686838e-13,198.9 L0.6,13 Z'
      ]
    },
    {
      name: 'LHR-DOOR',
      transform: 'translate(399, 297)',
      paths: [
        'M6.6,45.3 C8.3,33.6 12.1,17.2 17.1,0 L18.1,0 L119.1,0 L119.6,0 C125.2,25.3 145.4,48.2 182.3,49.1 C184.2,49.1 186.3,49.2 188.6,49.2 C193.4,49.2 198.3,49.1 201,48.9 L203.7,48.8 L204.3,169.1 C181.8,171.7 122.3,177.7 92.8,173.4 C74.6,169.5 62.7,166.7 56.4,165.9 C49.1,164.6 14.1,156.8 10.1,156.9 C5.6,157.1 0.6,156.5 0.6,156.5 L0.1,156.5 C-4.3298698e-15,156.4 1.5,80.9 6.6,45.3 Z'
      ]
    },
    {
      name: 'RHR-DOOR',
      transform: 'translate(0, 297)',
      paths: [
        'M198.4,45.3 C196.7,33.6 192.9,17.2 187.9,0 L186.9,0 L86,0 L85.5,0 C79.9,25.3 59.7,48.2 22.8,49.1 C20.9,49.1 18.8,49.2 16.5,49.2 C11.7,49.2 6.8,49.1 4.1,48.9 L1.4,48.8 L0.8,169.1 C23.3,171.7 82.8,177.7 112.3,173.4 C130.5,169.5 142.4,166.7 148.7,165.9 C156,164.6 191,156.8 195,156.9 C199.5,157.1 204.5,156.5 204.5,156.5 L205,156.5 C205,156.4 203.5,80.9 198.4,45.3 Z'
      ]
    },
    {
      name: 'LHR-QTR',
      transform: 'translate(416, 120)',
      paths: [
        'M17,123.9 C18.6,118.9 20.2,114.3 21.5,110.1 L34.2,62.7 C34.3,62.2 35.8,53 37.4,43.2 C41,21.2 41.6,17.7 41.7,17 C42.1,15.5 43.2,13.7 46.5,13.7 C48.2,13.7 50.4,14.2 52.8,15.2 C54.2,15.7 55.7,15.9 57.1,15.5 C70.5,12.5 79.1,11.8 91.4,11.4 C92.9,11.4 94.4,10.8 95.6,9.9 C102.9,4.4 110,0.7 120.3,0.7 C120.5,0.7 120.7,0.7 121,0.7 C125.8,0.7 130.1,0.7 134.3,0.6 C137.1,0.6 139.7,0.5 142.1,0.5 C153.6,0.5 160.2,1.5 162.3,7.1 C167.7,19.4 174,56.6 174.8,64.8 C174.9,65.3 175.2,65.8 175.6,66 L177.3,66.9 C178.9,68.1 179.8,69.8 180.6,73.1 L183.1,85.5 L185.3,96 L174.5,96 C173.1,96 171.8,95.9 170.6,95.9 C167.2,96 163.8,96.2 160.6,96.7 C117.8,101.8 99.8,135.9 101.2,165.5 L102.5,176.9 L102,177 L1,177 L0.3,177 C5.6,159 11.8,140 17,123.9 Z'
      ]
    },
    {
      name: 'RHR-QTR',
      transform: 'translate(2, 120)',
      paths: [
        'M169,123.9 C167.4,118.9 165.8,114.3 164.5,110.1 L151.8,62.7 C151.7,62.2 150.2,53 148.6,43.2 C145,21.2 144.4,17.7 144.3,17 C143.9,15.5 142.8,13.7 139.5,13.7 C137.8,13.7 135.6,14.2 133.2,15.2 C131.8,15.7 130.3,15.9 128.9,15.5 C115.5,12.5 106.9,11.8 94.6,11.4 C93.1,11.4 91.6,10.8 90.4,9.9 C83.1,4.4 76,0.7 65.7,0.7 C65.5,0.7 65.3,0.7 65,0.7 C60.2,0.7 55.9,0.7 51.7,0.6 C48.9,0.6 46.3,0.5 43.9,0.5 C32.4,0.5 25.8,1.5 23.7,7.1 C18.3,19.4 12,56.6 11.2,64.8 C11.1,65.3 10.8,65.8 10.4,66 L8.7,66.9 C7.1,68.1 6.2,69.8 5.4,73.1 L2.9,85.5 L0.7,96 L11.5,96 C12.9,96 14.2,95.9 15.4,95.9 C18.8,96 22.2,96.2 25.4,96.7 C68.2,101.8 86.2,135.9 84.8,165.5 L83.5,176.9 L84,177 L185,177 L185.7,177 C180.5,159 174.3,140 169,123.9 Z'
      ]
    },
    {
      name: 'TURRET',
      transform: 'translate(200, 302)',
      paths: [{
        fillRule: 'evenodd',
        d: 'M0.5,0.8 L4.8,3.4 L196.6,3.7 L201.7,0.8 C202.5,0.8 189.7,161.6 200.1,254 C182.7,257.3 170.4,260.9 101.2,260 C33.2,261.6 23.7,257.5 2.3,254 C7,202.4 10.3,194.6 0.5,0.8 Z M13.5,7.3 h31 v246.2 h-31 Z M156.5,7.3 h31 v246.2 h-31 Z'
      }]
    },
    {
      name: 'LH-CANTRAIL',
      transform: 'translate(356, 302)',
      paths: [
        'M0.5,7.3 h31 v246.2 h-31 Z'
      ]
    },
    {
      name: 'RH-CANTRAIL',
      transform: 'translate(213, 302)',
      paths: [
        'M0.5,7.3 h31 v246.2 h-31 Z'
      ]
    },
    {
      name: 'BOOT',
      transform: 'translate(140, 65)',
      paths: [
        'M0.4,0 C0.4,1.4 0.5,2.8 0.5,4.2 C0.6,8.1 1.8,17.4 2.6,21.1 L4.1,29.7 C5.4,36.1 6.6,41.2 7.7,45.1 C8.1,46.6 8.9,47.8 9.8,49.2 C10.3,50 11.4,51 12.2,51.7 C12.4,51.9 12.6,52.1 12.8,52.3 L23.3,60.1 L27.6,63.3 L27.8,63.2 C28.4,63.4 89.2,82.1 165.3,82.1 C165.3,82.1 165.3,82.1 165.3,82.1 C210,82.1 251.1,75.8 287.6,63.2 L302.6,52.2 C302.7,52.1 303,51.9 303.2,51.7 C304,51 305.1,50.1 305.6,49.2 C306.5,47.9 307.3,46.6 307.7,45.1 C308.8,41.3 310,36.1 311.3,29.7 L312.8,21.2 C313.6,17.5 314.8,8.1 314.9,4.3 C314.9,2.8 315,1.5 315,0.1 L0.4,0.1 L0.4,0 Z'
      ]
    },
    {
      name: 'REAR-BUMPER',
      transform: 'translate(140, 0)',
      paths: [
        'M315.1,65 C315.4,51 314.6,41.4 313.8,31.7 C313.4,26.5 312.9,21 312.6,14.6 L312.6,7.9 L313,7.8 C313.9,7.4 314.5,6.5 314.2,5.6 C313.8,4.1 313.3,3 311.9,2.4 L310.8,1.1 C309.9,0.1 308.2,-0.2 307,0.5 L300.6,4.2 C299.7,4.7 298.7,5 297.7,5 L272.4,5.9 L259,6.2 C257.1,6.1 254.2,5.5 251.1,4.8 C247.7,4 243.9,3.3 240.6,3.1 L230.3,3.1 L175.6,3.1 C173.6,3.1 171.5,3 169.3,3 L157.6,2.9 L146,2.9 C143.8,2.9 141.7,3 139.7,3 L85,3 L74.7,3 C71.5,3.3 67.7,4 64.3,4.8 C61.2,5.5 58.4,6.1 56.5,6.2 L43.2,5.8 L17.9,4.9 C16.9,4.9 15.9,4.6 15,4.1 L8.6,0.4 C7.4,-0.3 5.7,2.49800181e-15 4.8,1 L3.7,2.3 C2.3,2.9 1.8,4 1.4,5.5 C1.2,6.4 1.7,7.4 2.7,7.7 L3.1,7.8 L3.1,13.8 L3,14.5 C2.7,20.9 2.2,26.4 1.8,31.6 C1,41.3 0.2,50.9 0.5,64.9 L315.1,64.9 L315.1,65 Z'
      ]
    },
  ]
};

