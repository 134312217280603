import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import ExcessInvoice from './component.jsx';


const load = (API, jobId, emailId = null, to = null) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/excess-invoice`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          emailId,
          to,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error === true) {
          if (resp.hasOwnProperty('errors')) {
            dispatch({
              type: 'JOB-DETAILS-HEADER-SET-ERRORS',
              errors: resp.errors.reduce((acc, {path}) => {
                acc[`${path}Error`] = true;
                return acc;
              }, {})
            });
          }
          if (emailId === null || to === null) {
            dispatch({
              type: 'JOB-EXCESS-INVOICE-RESET'
            });
          }
          return false;
        }
        dispatch({
          type: 'JOB-EXCESS-INVOICE-SET',
          ...resp,
        });
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-EXCESS-INVOICE-RESET'
});


const mapStateToProps = (state, props) => {
  const {jobId} = props;
  const {
    jobDetails: {
      company
    },
    jobExcessInvoice
  } = state;
  return {
    jobId,
    company,
    ...jobExcessInvoice,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: (jobId, emailId, to) => dispatch(load(API, jobId, emailId, to)),
  onUnload: () => dispatch(unload()),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExcessInvoice)
);

