import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import uuidv4 from 'uuid/v4';

import colors from 'colors';

import Modal from 'components/Modal';


const Body = styled.div`
  font-size: 16px;
  line-height: 2.5rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  padding: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;
const Input = styled.input`
  height: inherit !important;
`;
const Mail = styled(Icons.Mail)`
  stroke: ${colors.primary};
`;
const Send = styled(Icons.Send)`
  stroke: #fff;
`;
const Printer = styled(Icons.Printer)`
  stroke: ${colors.primary};
`;

export default class Infosheet extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      id: uuidv4(),
      formOpen: false,
      to: '',
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onEmail = this.onEmail.bind(this);
    this.onPrint = this.onPrint.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        id: uuidv4(),
        formOpen: false,
      });
      const {jobId, open, onLoad} = this.props;
      if (open === true) {
        onLoad(jobId)
          .then(res => {
            if (res !== true) {
              toast.error('Error encountered opening info sheet.');
            }
            //this.onPrint();
          });
      }
    }
  }
  onChangeEmail(e) {
    this.setState({
      to: e.target.value,
    });
  }
  onEmail() {
    const {id, formOpen, to} = this.state;
    const {jobId, customerEmail, onEmail} = this.props;
    if (formOpen !== true) {
      this.setState({
        formOpen: true,
        to: customerEmail || '',
      });
    } else {
      const emails = to.split(/[,;\s]/g).filter(e => e.length);
      onEmail(jobId, id, emails)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered sending email.');
          } else {
            toast.success('Email successfully sent.');
          }
        })
        .catch(e => {
          toast.error('Error encountered sending email.');
        });
    }
  }
  onPrint() {
    //setTimeout(() => {
      window.print();
    //}, 1);
  }
  render() {
    if (this.props.open !== true || this.props.notFound !== false) return null;
    const {
      companyName,
      abn,
      licenseNumber,
      phone,
      locations,
      jobNumber,
      rego,
      claimNumber,
      excess,
      startDate,
      finishDate,
      defaultLocation,
      onUnload
    } = this.props;
    const {formOpen, to} = this.state;
    return (
      <Modal
        open={true}
        fullScreen={true}
      >
        <Body className='modal-body'>
          <div className='d-none d-print-block mb-5'>
            <div className='d-flex flex-column align-items-end text-uppercase'>
              <h3>{companyName}</h3>
              <div>{locations[defaultLocation].address}</div>
              <div>P: {phone}</div>
              <div>ABN: {abn}</div>
              <div>LIC #: {licenseNumber}</div>
            </div>
          </div>
          <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
            <h5 className='mb-0 p-0'>Information sheet</h5>
            <div className='d-flex flex-row d-print-none'>
              {formOpen === true && (
                <div className='input-group'>
                  <Input
                    type='email'
                    className='form-control'
                    value={to}
                    onChange={this.onChangeEmail}
                  />
                  <div className='input-group-append'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={this.onEmail}
                    ><Send width={18} height={18} /></button>
                  </div>
                </div>
              )}
              {formOpen !== true && (
                <button type='button' className='btn' onClick={this.onEmail}><Mail /></button>
              )}
              <button type='button' className='btn ml-2' onClick={this.onPrint}><Printer /></button>
              <button type='button' className='close ml-2' onClick={onUnload}><Icons.X /></button>
            </div>
          </div>
          <Card>
            <div className='row'>
              <div className='col-2 text-right font-weight-bold'>Job #:</div>
              <div className='col-2'>{jobNumber}</div>
              <div className='col-2 text-right font-weight-bold'>Claim #:</div>
              <div className='col-2'>{claimNumber}</div>
              <div className='col-2 text-right font-weight-bold'>Start/Finish:</div>
              <div className='col-2'>{startDate} {'\u2013'} {finishDate}</div>
            </div>
            <div className='row'>
              <div className='col-2 text-right font-weight-bold'>Rego:</div>
              <div className='col-2'>{rego}</div>
              <div className='col-2 text-right font-weight-bold'>Excess:</div>
              <div className='col-2'>{excess}</div>
              <div className='col-2 text-right font-weight-bold'>Drop off time:</div>
              <div className='col-2'>07:30 {'\u2013'} 10:00</div>
            </div>
          </Card>
          <div className='font-weight-bold mt-3'>What to expect</div>
          <ul className='mb-0'>
            <li>All hail dents to be removed and all hail damaged moulds to be replaced.</li>
            <li>Lifetime warranty on your repairs.</li>
            <li>The fastest possible turn around and an eco friendly solution to hail repair.</li>
            <li>Your paintwork to look like it did before the hail, we do not paint the panels unless absolutely needed which means general wear and tear will remain visible.</li>
          </ul>
          <div className='font-weight-bold mt-3'>What NOT to expect</div>
          <ul className='mb-0'>
            <li>Any damage to your vehicle that is not hail related will not be repaired. Your insurance claim will only cover hail related damage. Old damage, trolley dents, scratches, chips and scuffs are not covered unless we have otherwise been instructed and authorised by your insurance company.</li>
            <li>A replacement car. Please ask insurer if you have this option on your policy and consult with them to arrange a replacement car for you.</li>
            <li>Drop off and pick up. Please arrange your own transport to and from our site.</li>
            <li>We do not buff and polish your car in an attempt to keep your duco as original as possible and in most cases will not wash the vehicle in an attempt to be as eco friendly as we can.</li>
          </ul>
          <div className='font-weight-bold mt-3'>Excess payments</div>
          <p>Upon pick up of your completed vehicle, the excess amount that is applicable to your claim must be paid for in full before we will release your vehicle. Only CASH and EFTPOS payments will be accepted on our premises. We apologise for any inconvenience and please keep in mind we are a temporary site.</p>
          <p>If you are unable to pay on pick up, you can pay the excess direct to your insurance company either over the phone or in person at your nearest branch. They will supply you with a receipt number. Please call us ASAP with that receipt number <span className='font-weight-bold'>PRIOR</span> to pick up of your vehicle.</p>
          <div className='font-weight-bold mt-3'>Valuables</div>
          <p>We will not be held responsible for any items left in your car during it’s time on our premises. If you are concerned about your valuables, please remove them before leaving your vehicle.</p>
          <p>Finally, we may have to remove baby seats, however we are not authorised to refit them. You must do this yourself if needed.</p>
          <p>If you have any concerns about any of the above, or any other questions, please feel free to contact us direct on <span className='font-weight-bold'>{phone}</span> and we will be happy to help you out.</p>
          <p className='mb-0'>Thank you,<br />Management and staff</p>
        </Body>
      </Modal>
    );
  }
};
