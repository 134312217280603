import React from 'react';
import {toast} from 'react-toastify';

import colors from 'colors';

import Modal from 'components/Modal';
import Select from 'components/Select.jsx';


export default class Order extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      disabled: true,
      creditor: null,
      location: null,
      otp: false,
    };
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
    this.onSelectCreditor = this.onSelect.bind(this, 'creditor');
    this.onSelectLocation = this.onSelect.bind(this, 'location');
    this.onToggleOtp = this.onToggleOtp.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lines !== prevProps.lines) {
      const disabled = Object.values(this.props.lines).reduce((acc, l) => {
        if (l !== undefined) return false;
        return acc;
      }, true);
      this.setState({
        disabled
      });
    }
  }

  onToggleModal(open) {
    if (open === true) {
      const location = this.props.locations.length === 1 ?
        this.props.locations[0].id :
        null;
      this.setState({
        open,
        creditor: null,
        location,
        otp: false,
      });
    } else {
      this.setState({
        open
      });
    }
  }

  onSelect(field, id) {
    this.setState({
      [field]: id
    });
  }
  onToggleOtp(e) {
    this.setState({
      otp: e.target.checked
    });
  }

  onSend() {
    const {jobId, lines, onSend} = this.props;
    const {creditor, location, otp} = this.state;
    onSend(
      jobId,
      creditor,
      location,
      otp,
      Object.values(lines)
        .filter(v => v)
        .map(({id}) => id)
    )
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while creating order.');
        } else {
          toast.success('Order successfully created.');
          this.setState({
            open: false,
            creditor: null
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while creating order.');
      });
  }

  render () {
    console.log('order:render');
    const {creditors, locations} = this.props;
    const {open, disabled, creditor, location, otp} = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-primary ml-2'
          onClick={this.onOpen}
          disabled={disabled}
        >Order</button>
        <Modal
          title='Send order'
          open={open}
          onClose={this.onClose}
        >
          <div className='modal-body'>
            <Select
              title='Creditor'
              background={colors.grey1}
              selected={creditor}
              options={creditors}
              textField='name'
              onChange={this.onSelectCreditor}
            />
            <Select
              className='mt-2'
              title='Location'
              textField='name'
              background={colors.grey1}
              selected={location || (locations.length === 1 ? locations[0].id : null)}
              options={locations}
              onChange={this.onSelectLocation}
            />
            <div className='form-group form-check mt-2 mb-0'>
              <input
                type='checkbox'
                id='order-otp'
                className='form-check-input'
                checked={otp === true}
                onChange={this.onToggleOtp}
              />
              <label
                htmlFor='order-otp'
                className='form-check-label'
              >Over the phone order</label>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={this.onClose}
            >Close</button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={this.onSend.bind(this)}
            >Send</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

