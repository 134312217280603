import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Body = styled.div`
  font-size: 16px;
  color: ${colors.body};
  padding: 1rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const StageContainer = styled.div`
  height: 100px;
  font-size: 14px;
  font-weight: bold;
`;
const Bar = styled.div`
  position: relative;
  left: 25px;
  width: 2px;
  height: 100%;
  background: ${opts => opts.done ? colors.green : colors.grey4};
`;
  //position: relative;
  //margin-left: auto;
  //margin-right: auto;
const Dot = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background: ${opts => opts.done ? colors.green : colors.grey4};
  text-align: center;
  line-height: 48px;
  z-index: 1000;
  & svg {
    stroke: white;
    @media print {
      stroke: ${colors.green};
    }
  }
`;
const Title = styled.div`
  width: 100px;
  color: ${opts => opts.done ? colors.green : colors.body};
  text-align: right;
  margin-right: 2rem;
`;
const Ts = styled.div`
  width: 100px;
  color: ${opts => opts.done ? colors.green : colors.body};
  margin-left: 2rem;
`;


const Stage = ({title, done, date}) => (
  <StageContainer className='d-flex flex-row justify-content-center align-items-center'>
    <Title done={done}>{title}</Title>
    <Bar done={done} />
    <Dot done={done}>
      {done && <Icons.Check />}
    </Dot>
    <Ts done={done}>{date}</Ts>
  </StageContainer>
);



export default class AppClaim extends React.Component {
  componentDidMount() {
    const {match:{params:{token}}} = this.props;
    const {onLoad} = this.props;
    onLoad(token);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      const {token, onLoad} = this.props;
      onLoad(token);
    }
  }
  render() {
    const {notFound} = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Claim not found.</div>
        </ErrorContainer>
      );
    }
    const {
      companyName,
      abn,
      licenseNumber,
      phone,
      jobNumber,
      rego,
      claimNumber,
      vin,
      excess,
      startDateStr,
      finishDateStr,
      address,
      assessmentBooked,
      assessmentDateStr,
      assessed,
      repairBooked,
      isIn,
      inDateStr,
      repaired,
      qaed,
      qaDateStr,
      isOut,
      outDateStr,
      excessPaid,
      excessInvoiceDateStr,
      quoted,
      quoteDateStr,
      authorised,
      authoriseDateStr,
      invoiced,
      invoiceDateStr,
      paid,
      payDateStr,
    } = this.props;
    return (
      <Body>
        <div className='mb-5'>
          <div className='d-flex flex-column align-items-end text-uppercase'>
            <h3>{companyName}</h3>
            <div>{address}</div>
            <div>P: {phone}</div>
            <div>ABN: {abn}</div>
            <div>LIC #: {licenseNumber}</div>
          </div>
        </div>
        <Card className='mb-3 p-3'>
          <div className='row no-gutters'>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Job #:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{jobNumber}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Claim #:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{claimNumber}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>VIN:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{vin}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Rego:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{rego}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Excess:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{excess}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Start/Finish:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{startDateStr} {'\u2013'} {finishDateStr}</div>
          </div>
        </Card>
        <Card className='d-flex flex-column justify-content-center align-items-center p-3'>
            <Stage
              done={assessmentBooked}
              title='Assess booked'
              date={assessmentDateStr}
            />
            <Stage
              done={assessed}
              title='Assessed'
            />
            <Stage
              done={repairBooked}
              title='Repair booked'
              date={repairBooked ? startDateStr : ''}
            />
            <Stage
              done={isIn}
              title='Arrived'
              date={inDateStr}
            />
            <Stage
              done={repaired}
              title='Repaired'
            />
            <Stage
              done={qaed}
              title='Inspected'
              date={qaDateStr}
            />
            <Stage
              done={excessPaid}
              title='Excess paid'
              date={excessInvoiceDateStr}
            />
            <Stage
              done={isOut}
              title='Collected'
              date={outDateStr}
            />
            <Stage
              done={quoted}
              title='Quoted'
              date={quoteDateStr}
            />
            <Stage
              done={authorised}
              title='Authorised'
              date={authoriseDateStr}
            />
            <Stage
              done={invoiced}
              title='Invoiced'
              date={invoiceDateStr}
            />
            <Stage
              done={paid}
              title='Paid'
              date={payDateStr}
            />
        </Card>
      </Body>
    );
  }
};


