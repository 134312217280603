import React from 'react';
import {toast} from 'react-toastify';

import Modal from 'components/Modal';


export default class DeleteSublet extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      jobLineId: null,
      canDelete: false,
    };
    this.onDelete = this.onDelete.bind(this);
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lines !== prevProps.lines || this.state.open !== prevState.open) {
      const _lines = Object.values(this.props.lines).filter(l => l !== undefined);
      if (_lines.length === 1) {
        const [{
          id: jobLineId,
          canDelete,
        }] = _lines;
        this.setState({
          jobLineId,
          canDelete,
        });
      } else {
        this.setState({
          jobLineId: null,
          canDelete: false,
        });
      }
    }
  }

  onToggleModal(open) {
    this.setState({
      open,
    });
  }

  onDelete() {
    const {jobId, onDelete} = this.props;
    const {jobLineId} = this.state;
    onDelete(jobId, {
      action: 'delete',
      jobLineId,
    })
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while deleting sublet invoice.');
        } else {
          toast.success('Sublet invoice successfully deleted.');
          this.setState({
            open: false,
            selectedSuppliers: {}
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while deleting sublet invoice.');
      });
  }

  render () {
    console.log('deletesublet:render');
    const {open, canDelete} = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-danger'
          onClick={this.onOpen}
          disabled={canDelete !== true}
        >Delete</button>
        <Modal
          title='Are you sure you wish to delete this sublet invoice?'
          open={open}
          onClose={this.onClose}
        >
          <div className='modal-footer mt-3 bt-0'>
            <button type='button' className='btn btn-secondary' onClick={this.onClose}>Close</button>
            <button
              type='button'
              className='btn btn-danger'
              onClick={this.onDelete}
            >Delete</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

