import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Settings from './component.jsx';


const SECTIONS = {
  mailbox: 'mailbox',
  storm: 'storm',
  lightTunnel: 'light-tunnel',
  miscInvoiceCategory: 'misc-invoice-category',
  location: 'location',
  employee: 'employee',
  debtor: 'debtor',
  creditor: 'creditor',
  smsTemplate: 'sms-template',
  company: 'company',
};

const load = (API) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/settings`, {method: 'GET'})
      .then(settings => {
        if (settings && settings.error === true) {
          dispatch({
            type: 'SETTINGS-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'SETTINGS-SET',
          settings
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const edit = (section, id) => ({
  type: 'SETTINGS-SECTION-EDIT',
  section,
  id
});

const change = (section, changes) => ({
  type: 'SETTINGS-SECTION-CHANGE',
  section,
  changes
});

const employeeChangeAbility = change => ({
  type: 'SETTINGS-EMPLOYEE-CHANGE-ABILITY',
  change
});

const debtorChangeAbility = change => ({
  type: 'SETTINGS-DEBTOR-CHANGE-ABILITY',
  change
});

const debtorAddStorm = stormId => ({
  type: 'SETTINGS-DEBTOR-ADD-STORM',
  stormId
});

const debtorDeleteStorm = stormId => ({
  type: 'SETTINGS-DEBTOR-DELETE-STORM',
  stormId
});


const locationAddEmployee = employeeId => ({
  type: 'SETTINGS-LOCATION-ADD-EMPLOYEE',
  employeeId
});

const locationDeleteEmployee = employeeId => ({
  type: 'SETTINGS-LOCATION-DELETE-EMPLOYEE',
  employeeId
});

const locationChangeEmployeeCommission = (employeeId, category, field, value) => ({
  type: 'SETTINGS-LOCATION-CHANGE-EMPLOYEE-COMMISSION',
  employeeId,
  category,
  field,
  value
});

const locationChangeEmployeeAbility = (employeeId, change) => ({
  type: 'SETTINGS-LOCATION-CHANGE-EMPLOYEE-ABILITY',
  employeeId,
  change
});

const stormAddCreditor = creditorId => ({
  type: 'SETTINGS-STORM-ADD-CREDITOR',
  creditorId
});

const stormDeleteCreditor = creditorId => ({
  type: 'SETTINGS-STORM-DELETE-CREDITOR',
  creditorId
});

const stormToggleCreditorMake = (creditorId, make) => ({
  type: 'SETTINGS-STORM-TOGGLE-CREDITOR-MAKE',
  creditorId,
  make
});

const save = (API, section, id, data) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/settings/${SECTIONS[section]}/${id}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          ...data
        })
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'SETTINGS-SECTION-SET',
          section,
          data: res
        });
        if (section === 'employee') {
          dispatch({
            type: 'PARAMS-UPDATE-EMPLOYEE',
            employee: res
          });
        }
        if (section === 'location') {
          dispatch({
            type: 'PARAMS-UPDATE-LOCATION',
            location: res
          });
        }
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const remove = (API, section, id) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/settings/${SECTIONS[section]}/${id}`, {method: 'DELETE'})
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'SETTINGS-SECTION-DEL',
          section,
          id
        });
        if (section === 'employee') {
          dispatch({
            type: 'PARAMS-DELETE-EMPLOYEE',
            id
          });
        }
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  let settingsValid = null;
  if (state.settings.notFound === true)
    settingsValid = false;
  else if (state.settings.notFound === false) {
    settingsValid = true;
  }
  if (settingsValid !== true) {
    return {settingsValid};
  }
  const {
    settings: {
      debtors,
      storms: stormList,
      stormIndex,
      lightTunnels,
      miscInvoiceCategories,
      locations,
      smsTemplates,
      creditorIndex,
      employeeIndex,
      employees: employeeList,
      creditors: creditorList,
      creditor,
      supplierIndex,
      suppliers: supplierList,
      debtor,
      employee,
      lightTunnel,
      miscInvoiceCategory,
      mailbox,
      storm,
      location,
      smsTemplate,
      companies: companyList,
      company
    }
  } = state;
  return {
    settingsValid,
    creditor,
    debtor,
    employee,
    mailbox,
    storm,
    lightTunnel,
    miscInvoiceCategories,
    location,
    debtors,
    stormIndex,
    stormList,
    locations,
    lightTunnels,
    miscInvoiceCategory,
    employeeIndex,
    employeeList,
    creditorIndex,
    creditorList,
    supplierIndex,
    supplierList,
    smsTemplates,
    smsTemplate,
    companyList,
    company,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: () => dispatch(load(props.API)),
  onEdit: (section, id) => dispatch(edit(section, id)),
  onChange: (section, changes) => dispatch(change(section, changes)),
  onChangeEmployeeAbility: change => dispatch(employeeChangeAbility(change)),
  onChangeDebtorAbility: change => dispatch(debtorChangeAbility(change)),
  onChangeDebtorAddStorm: stormId => dispatch(debtorAddStorm(stormId)),
  onChangeDebtorDeleteStorm: stormId => dispatch(debtorDeleteStorm(stormId)),
  onChangeLocationAddEmployee: employeeId => dispatch(locationAddEmployee(employeeId)),
  onChangeLocationDeleteEmployee: employeeId => dispatch(locationDeleteEmployee(employeeId)),
  onChangeLocationChangeEmployeeCommission: (employeeId, category, field, value) =>
  dispatch(locationChangeEmployeeCommission(employeeId, category, field, value)),
  onChangeLocationChangeEmployeeAbility: (employeeId, change) =>
  dispatch(locationChangeEmployeeAbility(employeeId, change)),
  onChangeStormAddCreditor: creditorId => dispatch(stormAddCreditor(creditorId)),
  onChangeStormDeleteCreditor: creditorId => dispatch(stormDeleteCreditor(creditorId)),
  onToggleStormCreditorMake: (creditorId, make) => dispatch(stormToggleCreditorMake(creditorId, make)),
  onSave: (section, id, data) => dispatch(save(props.API, section, id, data)),
  onDelete: (section, id) => dispatch(remove(props.API, section, id)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Settings)
);
