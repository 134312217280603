import moment from 'moment';
import numeral from 'numeral';


let currentState = null;
const cs = window.localStorage.getItem('admin-payments:debtorId');
if (cs) {
  currentState = JSON.parse(cs);
}


export default (state = {
  notFound: null,
  debtorId: currentState,
  endDate: moment().day(1).add(-1, 'day').unix(),
}, action) => {
  switch (action.type) {
    case 'ADMIN-PAYMENTS-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
      };
    case 'ADMIN-PAYMENTS-SET-NULL':
      return {
        ...state,
        notFound: null,
      };
    case 'ADMIN-PAYMENTS-CHANGE-DEBTOR-ID':
      const {debtorId} = action;
      window.localStorage.setItem('admin-payments:debtorId', JSON.stringify(debtorId));
      return {
        ...state,
        debtorId
      };
    case 'ADMIN-PAYMENTS-CHANGE-END-DATE':
      const {endDate} = action;
      return {
        ...state,
        endDate,
      };
    case 'ADMIN-PAYMENTS-SET':
      {
        const year = moment().year();
        const t30 = moment().add(-30, 'days').unix();
        const t60 = moment().add(-60, 'days').unix();
        const t90 = moment().add(-90, 'days').unix();
        const {
          invoices0,
          invoices30,
          invoices60,
          invoices90,
          total0,
          total30,
          total60,
          total90,
          totalOutstanding
        } = action.invoices.reduce((acc, i) => {
          const invoiceDate = moment(i.invoiceDate * 1000);
          acc.totalOutstanding += i.outstandingAmount;
          let invoices;
          if (i.invoiceDate < t90) {
            invoices = acc.invoices90;
            acc.total90 += i.outstandingAmount;
          } else if (i.invoiceDate < t60) {
            invoices = acc.invoices60;
            acc.total60 += i.outstandingAmount;
          } else if (i.invoiceDate < t30) {
            invoices = acc.invoices30;
            acc.total30 += i.outstandingAmount;
          } else {
            invoices = acc.invoices0;
            acc.total0 += i.outstandingAmount;
          }
          invoices.push({
            ...i,
            invoiceDateStr: invoiceDate.format(
              invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
            ),
            outstandingAmountStr: numeral(i.outstandingAmount / 100).format('$0,0.00'),
            paymentDate: null,
            paymentMethod: null,
            paymentReference: '',
          });
          return acc;
        }, {
          invoices0: [],
          invoices30: [],
          invoices60: [],
          invoices90: [],
          total0: 0,
          total30: 0,
          total60: 0,
          total90: 0,
          totalOutstanding: 0
        });
        return {
          ...state,
          notFound: false,
          numInvoices: action.invoices.length,
          invoices0,
          invoices30,
          invoices60,
          invoices90,
          total0Str: numeral(total0 / 100).format('$0,0.00'),
          total30Str: numeral(total30 / 100).format('$0,0.00'),
          total60Str: numeral(total60 / 100).format('$0,0.00'),
          total90Str: numeral(total90 / 100).format('$0,0.00'),
          totalOutstandingStr: numeral(totalOutstanding / 100).format('$0,0.00'),
          gstOutstandingStr: numeral(totalOutstanding / 100 / 1.1 * 0.1).format('$0,0.00'),
        };
      }
    case 'ADMIN-PAYMENTS-UPDATE':
      {
        const {jobId, changes} = action;
        return {
          ...state,
          invoices0: state.invoices0.map(m => {
            if (m.jobId !== jobId) return m;
            return {
              ...m,
              ...changes
            };
          }),
          invoices30: state.invoices30.map(m => {
            if (m.jobId !== jobId) return m;
            return {
              ...m,
              ...changes
            };
          }),
          invoices60: state.invoices60.map(m => {
            if (m.jobId !== jobId) return m;
            return {
              ...m,
              ...changes
            };
          }),
          invoices90: state.invoices90.map(m => {
            if (m.jobId !== jobId) return m;
            return {
              ...m,
              ...changes
            };
          }),
        };
      }
    default:
      return state;
  }
};
