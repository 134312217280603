import moment from 'moment';
import numeral from 'numeral';

import TruncateString from 'utils/truncate-string.js';


export default (state = {
  notFound: null,
}, action) => {
  switch (action.type) {
    case 'TECHNICIAN-INVOICING-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'TECHNICIAN-INVOICING-SET':
    {
      const {
        chargesGst,
        gstRate,
        invoices: _invoices,
        outstandingTasks: _outstandingTasks
      } = action;
      /* eslint-disable no-useless-rename */
      const {total: totalInvoices, invoices: invoices} = _invoices
        .reduce((acc, i) => {
          acc.total += i.amount;
          acc.invoices.push({
            ...i,
            createDateStr: moment(i.createDate * 1000).format('DD-MM-YYYY'),
            payDateStr: i.payDate ? moment(i.payDate * 1000).format('DD-MM-YYYY') : '–',
            amountStr: numeral(i.amount / 100).format('$0,0.00'),
          });
          return acc;
        }, {total: 0, invoices: []});
      const {total: totalTasks, tasks: outstandingTasks} = _outstandingTasks.reduce((acc, t) => {
        t.selected = t.canInvoice && t.amount !== null;
        if (t.selected === true) {
          acc.total += t.amount;
        }
        acc.tasks.push({
          ...t,
          disabled: !t.canInvoice,
          doneDateStr: moment(t.doneDate * 1000).format('DD-MM'),
          amountStr: t.amount !== null ? numeral(t.amount / 100).format('$0,0.00') : '-',
          adjustmentStr: t.adjustment ? numeral(t.adjustment / 100).format('($0,0.00)') : null,
          makeModel: TruncateString([t.make || '', t.model || ''].join(' ')),
        });
        return acc;
      }, {total: 0, tasks: []});
      return {
        notFound: false,
        chargesGst,
        gstRate,
        invoices,
        subtotalInvoices: numeral(totalInvoices / 100).format('$0,0.00'),
        gstInvoices: numeral(totalInvoices / 100 * (chargesGst ? (gstRate / 100) : 0)).format('$0,0.00'),
        totalInvoices: numeral(totalInvoices / 100 * (chargesGst ? (100 + gstRate) / 100 : 1)).format('$0,0.00'),
        outstandingTasks,
        subtotalTasks: numeral(totalTasks / 100).format('$0,0.00'),
        gstTasks: numeral(totalTasks / 100 * (chargesGst ? (gstRate / 100) : 0)).format('$0,0.00'),
        totalTasks: numeral(totalTasks / 100 * (chargesGst ? (100 + gstRate) / 100 : 1)).format('$0,0.00'),
      };
    }
    case 'TECHNICIAN-INVOICING-TOGGLE':
    {
      const {index, selected} = action;
      const {total: totalTasks, tasks: outstandingTasks} = state.outstandingTasks.reduce((acc, t, i) => {
        let newT = t;
        if (i === index) {
          newT = {
            ...t,
            selected
          }
        }
        if (newT.selected === true) {
          acc.total += t.amount;
        }
        acc.tasks.push(newT);
        return acc;
      }, {total: 0, tasks: []});
      return {
        ...state,
        outstandingTasks,
        subtotalTasks: numeral(totalTasks / 100).format('$0,0.00'),
        gstTasks: numeral(totalTasks / 100 * (state.chargesGst ? (state.gstRate / 100) : 0)).format('$0,0.00'),
        totalTasks: numeral(totalTasks / 100 * (state.chargesGst ? (100 + state.gstRate) / 100 : 1)).format('$0,0.00')
      };
    }
    default:
      return state;
  }
};
