import moment from 'moment';


export default (
  state = {
    notFound: null,
    jobId: null,
    jobNumber: null,
    documents: [],
  },
  action
) => {
  switch (action.type) {
    case 'JOB-DOCUMENTS-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-DOCUMENTS-SET-FOUND':
      const{jobId, jobNumber, flagged, vpp, vip, importedFromArms} = action;
      return {
        ...state,
        notFound: false,
        jobId,
        jobNumber,
        flagged,
        vpp,
        vip,
        importUrl: importedFromArms ?
          `https://www.arms1.com.au/search?search-quote-number=${jobNumber}` :
          null,
      };
    case 'JOB-DOCUMENTS-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
        documents: [],
      };
    case 'JOB-DOCUMENTS-SET-DOCUMENTS':
      {
        const year = moment().year();        
        return {
          ...state,
          documents: action.documents.map(doc => {
            const ts = moment(doc.createDate * 1000);
            const path = doc.document.split('/')
            return {
              ...doc,
              filename: path[path.length - 1],
              ts: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
            };
          })
        };
      }
    default:
      return state;
  }
};
