import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import PartsFinder from './component.jsx';


const loadVehicle = (API, modelId) => {
  return dispatch => {
    if (!modelId) return;
    dispatch({type: 'LOADING-INC'});
    const url = new URL(`${env.API_SERVER}/v1/internal/vehicle-info`);
    url.searchParams.append('modelId', modelId);
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          return null;
        }
        dispatch({
          type: 'JOB-DETAILS-SET-VEHICLE-INFO',
          parts: resp.parts
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const togglePart = ({partId, description, partNumber, price}) => ({
  type: 'JOB-DETAILS-QUOTE-ITEM-TOGGLE',
  rowType: 'part',
  id: partId,
  qty: 1,
  description,
  ntarItemCode: null,
  partNumber,
  price
});

const mapStateToProps = (state, props) => {
  let partSections = null;
  const {
    params: {
      partsVehicles: vehicles,
      partsNgramIndex: vehiclesNgramIndex
    },
    jobDetails: {
      selectedLineItems,
      vehicleInfo,
    }
  } = state;
  if (vehicleInfo) {
    partSections = vehicleInfo.sections;
  }
  //console.log(selectedLineItems);
  return {
    vehicles,
    vehiclesNgramIndex,
    selectedLineItems,
    partSections
  };
  //const {
  //  //header: {partsModelId},
  //  vehicleInfo: {partSections},
  //  selectedLineItems
  //} = state.jobDetails;
  //return {
  //  partsModelId,
  //  partSections,
  //  selectedLineItems
  //};
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoadVehicle: modelId => dispatch(loadVehicle(props.API, modelId)),
  onTogglePart: part => dispatch(togglePart(part))
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartsFinder)
);
