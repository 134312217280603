import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import CalendarInput from 'components/CalendarInput.jsx';
import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


const PAYMENT_METHODS = [
  'cash',
  'cheque',
  'direct deposit',
  'eftpos',
  'waived',
];
const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const SaveButton = styled.button`
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.div`
  margin-bottom: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  @media only screen and (max-width: 600px) {
    display: none !important
  }
`;

const Total = styled.div`
  @media only screen and (max-width: 600px) {
    display: none !important
  }
`;

const InvoiceSectionTitle = styled.h5`
  @media print {
    border-bottom: 1px solid ${colors.black};
  }
`;


export default class DebtorPayments extends React.Component {
  constructor(opts) {
    super(opts);
    this.onSearch = this.onSearch.bind(this);
    this.renderInvoice = this.renderInvoice.bind(this);
  }
  componentDidMount() {
    const {debtorId, endDate, onLoad} = this.props;
    if (debtorId !== null && endDate !== null) {
      onLoad(debtorId, endDate);
    }
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onSearch() {
    const {debtorId, endDate, onLoad} = this.props;
    if (debtorId !== null && endDate !== null) {
      onLoad(debtorId, endDate);
    }
  }
  onChange(jobId, field, v) {
    this.props.onChange(
      jobId,
      {
        [field]: v
      }
    );
  }
  onSave({jobId, paymentDate, paymentMethod, paymentReference}) {
    this.props.onSave(jobId, paymentDate, paymentMethod, paymentReference)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while updating invoice.');
        } else {
          toast.success('Invoice successfully updated.');
        }
      });
  }
  renderInvoice(inv, i) {
    const {debtorsIndex} = this.props;
    return (
      <Item key={i}>
        <div className='bg-white p-2 rounded'>
          <div className='row'>
            <div className='col-1 mb-2 mb-lg-0 align-self-center'>
              <Link to={`/job/${inv.jobId}`}>
                <strong className='text-body'>{inv.jobNumber}</strong>
              </Link>
            </div>
            <div className='col-5 col-lg-3 mb-2 mb-lg-0 align-self-center'>
              {debtorsIndex[inv.debtorId].name}
              <br />
              {inv.claimNumber}
            </div>
            <div className='col-2 col-lg-1 align-self-center'>
              {inv.invoiceDateStr}
            </div>
            <div className='col-2 col-lg-1 align-self-center'>
              {inv.invoiceNumber}
            </div>
            <div className='col-2 col-lg-1 align-self-center'>{inv.outstandingAmountStr}</div>
            <div className='col-lg-5 d-print-none'>
              <div className='row no-gutters'>
                <div className='col mr-2'>
                  <CalendarInput
                    showClear={true}
                    background={colors.grey2}
                    selected={inv.paymentDate}
                    onChange={this.onChange.bind(this, inv.jobId, 'paymentDate')}
                  />
                </div>
                <div className='col mr-2'>
                  <Select
                    background={colors.grey2}
                    selected={inv.paymentMethod}
                    options={PAYMENT_METHODS}
                    onChange={this.onChange.bind(this, inv.jobId, 'paymentMethod')}
                  />
                </div>
                <div className='col mr-2'>
                  <Field
                    value={inv.paymentReference}
                    background={colors.grey2}
                    uppercaseOnly={true}
                    onChange={this.onChange.bind(this, inv.jobId, 'paymentReference')}
                  />
                </div>
                <div className='col-auto'>
                  <SaveButton
                    type='button'
                    className='btn btn-primary'
                    saveId={i}
                    onClick={this.onSave.bind(this, inv)}
                    disabled={(inv.outstandingAmount === 0 || inv.paymentDate === null || inv.paymentMethod === null)}
                  >
                    <Icons.Save width={18} height={18} />
                  </SaveButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Item>
    );
  }
  render() {
    console.log('debtorpayments:render');
    const {
      companyName,
      jurisdiction,
      abn,
      licenseNumber,
      bsb,
      accountNumber,
      address,
      phone,
      logo,
      notFound,
      debtorId,
      endDate,
      numInvoices,
      invoices0,
      invoices30,
      invoices60,
      invoices90,
      total0Str,
      total30Str,
      total60Str,
      total90Str,
      gstOutstandingStr,
      totalOutstandingStr,
      debtorsList,
    } = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load payments.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-none d-print-flex mb-5'>
          <div className='col-12 col-sm-6 text-center text-sm-left'>
            {logo && <img src={logo} alt='logo' width="250" />}
          </div>
          <div className='col-12 col-sm-6 text-center text-sm-right text-uppercase'>
            <h3>{companyName}</h3>
            <div>{address}</div>
            <div>P: {phone}</div>
            <div>{jurisdiction === 'AU' && 'ABN:'}{jurisdiction === 'NZ' && 'GST #:'} {abn}</div>
            <div>LIC #: {licenseNumber}</div>
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h4 className='mb-0'>Debtor payments</h4>
          <div className='d-flex flex-row align-items-center'>
            <Select
              placeholder='select a debtor'
              background={colors.grey1}
              selected={debtorId}
              options={debtorsList}
              textField='name'
              onChange={this.props.onChangeDebtorId}
            />
            <div className='mx-2'>
              <CalendarInput
                width='85px'
                align='right'
                background={colors.grey1}
                selected={endDate}
                onChange={this.props.onChangeEndDate}
              />
            </div>
            <SearchButton
              type='button'
              className='btn btn-primary d-print-none'
              disabled={debtorId === null || endDate === null}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3'>
            {numInvoices === 0 && (
              <div className='text-center text-secondary'>No invoices found.</div>
            )}
            {numInvoices !== 0 && (
              <React.Fragment>
                <Title className='row mb-2 px-2 d-lg-flex'>
                  <div className='col-1 col-lg-1 font-weight-bold'>Job</div>
                  <div className='col-5 col-lg-3 font-weight-bold'>Debtor</div>
                  <div className='col-2 col-lg-1 font-weight-bold'>Inv date</div>
                  <div className='col-2 col-lg-1 font-weight-bold'>Inv #</div>
                  <div className='col-2 col-lg-1 font-weight-bold'>Amount</div>
                  <div className='col-lg-5 font-weight-bold d-print-none'>
                    <div className='row no-gutters'>
                      <div className='col mr-2'>Pay date</div>
                      <div className='col mr-2'>Pay method</div>
                      <div className='col'>Ref</div>
                      <div className='col-auto'>
                        <SaveButton
                          type='button'
                          className='btn btn-primary my-0 py-0 invisible'
                        >
                          <Icons.Save width={18} height={18} />
                        </SaveButton>
                      </div>
                    </div>
                  </div>
                </Title>
                {invoices0.length !== 0 && (
                  <React.Fragment>
                    <InvoiceSectionTitle className='px-2 mt-4'>Current period</InvoiceSectionTitle>
                    {invoices0.map(this.renderInvoice)}
                  </React.Fragment>
                )}
                {invoices30.length !== 0 && (
                  <React.Fragment>
                    <InvoiceSectionTitle className='px-2 mt-4'>30 days</InvoiceSectionTitle>
                    {invoices30.map(this.renderInvoice)}
                  </React.Fragment>
                )}
                {invoices60.length !== 0 && (
                  <React.Fragment>
                    <InvoiceSectionTitle className='px-2 mt-4'>60 days</InvoiceSectionTitle>
                    {invoices60.map(this.renderInvoice)}
                  </React.Fragment>
                )}
                {invoices90.length !== 0 && (
                  <React.Fragment>
                    <InvoiceSectionTitle className='px-2 mt-4'>90 days</InvoiceSectionTitle>
                    {invoices90.map(this.renderInvoice)}
                  </React.Fragment>
                )}
                <Total className='row mb-2 px-2 d-lg-flex'>
                  <div className='col-10 col-lg-6 font-weight-bold text-right'>GST</div>
                  <div className='col-2 col-lg-1 font-weight-bold'>{gstOutstandingStr}</div>
                </Total>
                <Total className='row px-2 d-lg-flex'>
                  <div className='col-10 col-lg-6 font-weight-bold text-right'>Total</div>
                  <div className='col-2 col-lg-1 font-weight-bold'>{totalOutstandingStr}</div>
                </Total>
              </React.Fragment>
            )}
          </Card>
        )}
        {notFound === false && numInvoices !== 0 && (
          <Card className='p-3 mt-3'>
            <div className='row px-2'>
              <div className='col-3 text-center'>
                <div className='font-weight-bold'>Current</div>
                {total0Str}
              </div>
              <div className='col-3 text-center'>
                <div className='font-weight-bold'>30 days</div>
                {total30Str}
              </div>
              <div className='col-3 text-center'>
                <div className='font-weight-bold'>60 days</div>
                {total60Str}
              </div>
              <div className='col-3 text-center'>
                <div className='font-weight-bold'>90 days</div>
                {total90Str}
              </div>
            </div>
            <div className='row no-gutters mt-4'>
              <div className='col-6 text-right pr-2'>Account name:</div>
              <div className='col-6'>{companyName}</div>
            </div>
            <div className='row no-gutters'>
              <div className='col-6 text-right pr-2'>BSB:</div>
              <div className='col-6'>{bsb}</div>
            </div>
            <div className='row no-gutters'>
              <div className='col-6 text-right pr-2'>Account #:</div>
              <div className='col-6'>{accountNumber}</div>
            </div>
          </Card>
        )}
      </Container>
    );
  };
};

