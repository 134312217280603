import moment from 'm';


export default (state = {
  notFound: null,
  mailboxes: null,
}, action) => {
  switch (action.type) {
    case 'ADMIN-STATUS-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'ADMIN-STATUS-SET-NULL':
      return {
        notFound: null,
        mailboxes: null,
      };
    case 'ADMIN-STATUS-SET':
      {
        const now = moment().unix();
        return {
          notFound: false,
          mailboxes: action.mailboxes.map(m => ({
            ...m,
            ts: moment(m.lastTs * 1000).fromNow(),
            alive: Math.abs(now - m.lastTs) < 300,
          }))
        };
      }
    default:
      return state;
  }
};
