module.exports = {
  'COUPE': 2.4,
  'HATCH': 2.4,
  'SEDAN': 2.5,
  'UTE2DR': 2.3,
  'UTE4DR': 2.5,
  'VAN': 3.5,
  'WAGON/5DR': 2.8
};

