import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Import from './component.jsx';


const close = () => ({
  type: 'JOB-IMPORT-CLOSE'
});

const importQuote = (API, jobId, srcJobId, includeParts, includePartNumbers, includeComments) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    const url = new URL(`${env.API_SERVER}/v1/internal/job/${jobId}/import`);
    url.searchParams.append('srcJobId', srcJobId);
    url.searchParams.append('includeParts', includeParts);
    url.searchParams.append('includePartNumbers', includePartNumbers);
    url.searchParams.append('includeComments', includeComments);
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        dispatch({
          type: 'JOB-DETAILS-QUOTE-SET',
          lines: resp.lines
        });
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = (state, props) => {
  const {jobImport: {open}} = state;
  const {jobId} = props;
  return {jobId, open};
};

const mapDispatchToProps = (dispatch, props) => ({
  onClose: () => dispatch(close()),
  onImport: (jobId, srcJobId, includeParts, includePartNumbers, includeComments) => dispatch(
    importQuote(
      props.API,
      jobId,
      srcJobId,
      includeParts,
      includePartNumbers,
      includeComments
    )
  ),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Import)
);

