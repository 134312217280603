import colors from 'colors';


const AVATAR_BGS = [
  {
    light: colors.redLight,
    dark: colors.red,
  },
  {
    light: colors.greenLight,
    dark: colors.green,
  },
  {
    light: colors.blueLight,
    dark: colors.blue,
  },
  {
    light: colors.purpleLight,
    dark: colors.purple,
  },
  {
    light: colors.yellowLight,
    dark: colors.yellow,
  },
  {
    light: colors.indigoLight,
    dark: colors.indigo,
  },
  {
    light: colors.pinkLight,
    dark: colors.pink,
  },
  {
    light: colors.tealLight,
    dark: colors.teal,
  },
  {
    light: colors.limeLight,
    dark: colors.lime,
  },
  {
    light: colors.amberLight,
    dark: colors.amber,
  },
  {
    light: colors.orangeLight,
    dark: colors.orange,
  },
  {
    light: colors.brownLight,
    dark: colors.brown,
  }
];


export let get = id => (AVATAR_BGS[parseInt((id).toString().substr(-6, 4), 16) % AVATAR_BGS.length]);
