import React from 'react';
import {toast} from 'react-toastify';


export default class Collect extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      canCollect: false,
    };
    this.onSave = this.onSave.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lines !== prevProps.lines) {
      const {lines, canCollect} = Object.values(this.props.lines).reduce((acc, l) => {
        if (l) {
          acc.lines.push({id: l.id});
          if (l.canCollect === false) {
            acc.canCollect = false;
          }
        }
        return acc;
      }, {lines: [], canCollect: true});
      if (lines.length) {
        this.setState({
          ...this.state,
          lines,
          canCollect
        });
      } else {
        this.setState({
          ...this.state,
          lines,
          canCollect: false
        });
      }
    }
  }

  onSave() {
    const {jobId, onSave} = this.props;
    const {lines} = this.state;
    onSave(jobId, {action: 'collect', lines})
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while marking credits collected.');
        } else {
          toast.success('Credits successfully collected.');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while updating credits.');
      });
  }

  render () {
    console.log('collect:render');
    const {canCollect} = this.state;
    return (
      <button
        type='button'
        className='btn btn-warning ml-2'
        onClick={this.onSave}
        disabled={canCollect !== true}
      >Collect</button>
    );
  }
};

