import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Autocomplete from 'components/Autocomplete.jsx';
import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.tr`
  background: ${opts => opts.alert ? colors.redExtraLight : colors.white};
  & td {
    border: 0 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;


export default class CreditorInvoices extends React.Component {
  constructor(opts) {
    super(opts);
    const {creditorId, creditor} = opts.creditors.reduce((acc, c) => {
      if (acc.creditorId === c.id) {
        acc.creditor = c.name;
      }
      return acc;
    }, {creditorId: opts.creditorId, creditor: ''});
    this.state = {
      creditorId,
      creditor,
    };
    this.onChangeCreditor = this.onChangeCreditor.bind(this);
    this.onFilterCreditor = this.onFilterCreditor.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onProcessInvoice = this.onProcessInvoice.bind(this);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onChangeCreditor(e) {
    if (typeof e === 'string') {
      this.setState({
        creditor: e,
        creditorId: null,
      });
      if (e === '') {
        this.props.onChangeCreditorId(null);
      }
    } else if (e != null && e.id){
      this.setState({
        creditor: e.text,
        creditorId: e.id,
      });
      this.props.onChangeCreditorId(e.id);
    }
  }
  onFilterCreditor(v) {
    const {creditors} = this.props;
    if (v === null) return creditors.map(c => ({id: c.id, text: c.name}));
    const regex = new RegExp(v, 'i');
    const res = creditors
      .reduce((acc, c) => {
        if (regex.test(c.name) === true) {
          acc.push({
            id: c.id,
            text: c.name,
          });
        }
        return acc;
      }, []);
    return res;
  }
  onSearch() {
    const {creditorId, invoiceNumber, onSearch} = this.props;
    onSearch(creditorId, invoiceNumber);
  }
  onProcessInvoice() {
    const {creditorId, data: {invoiceId}, onProcessInvoice} = this.props;
    onProcessInvoice(creditorId, invoiceId)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while processing invoice.');
        } else {
          toast.success('Invoice successfully processed.');
        }
      });
  }
  onProcessCredit(c) {
    const {creditorId, data: {invoiceId}, onProcessCredit} = this.props;
    onProcessCredit(creditorId, invoiceId, c.creditId)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while processing credit.');
        } else {
          toast.success('Credit successfully processed.');
        }
      });
  }
  render() {
    console.log('creditorinvoices:render');
    const {notFound} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load invoice.</div>
        </ErrorContainer>
      );
    }
    const {invoiceNumber, data, creditors} = this.props;
    const {creditorId, creditor} = this.state;
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h4 className='mb-0'>Creditor invoices</h4>
          <div className='d-flex flex-row align-items-center'>
            <Autocomplete
              className='m-0'
              background={colors.grey1}
              uppercaseOnly={true}
              showCaret={true}
              value={creditor}
              onChange={this.onChangeCreditor}
              onFilter={this.onFilterCreditor}
            />
            {false && <Select
              placeholder='select a creditor'
              background={colors.grey1}
              selected={creditorId}
              options={creditors}
              textField='name'
              onChange={this.onChangeCreditor}
            />}
            <Field
              className='ml-2'
              value={invoiceNumber}
              background={colors.grey1}
              uppercaseOnly={true}
              onChange={this.props.onChangeInvoiceNumber}
            />
            <SearchButton
              type='button'
              className='btn btn-primary ml-2'
              disabled={creditorId === null || invoiceNumber === null || invoiceNumber === ''}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3'>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th className='border-0 py-0'>Job #</th>
                  <th className='border-0 py-0'>Invoice #</th>
                  <th className='border-0 py-0'>Invoice date</th>
                  <th className='border-0 py-0'>Amount</th>
                  <th className='border-0 py-0'>Description</th>
                  <th className='border-0 py-0'>Credit date</th>
                  <th className='border-0 py-0'>Reason</th>
                  <th className='border-0 py-0'>Collect date</th>
                  <th className='border-0 py-0'>Process date</th>
                  <th className='border-0 py-0'>Pay date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='p-1 border-0' />
                </tr>
                <Item>
                  <td className='align-middle'>
                    <Link to={`/job/${data.jobId}/purchasing`}>
                      <strong className='text-body'>{data.jobNumber}</strong>
                    </Link>
                  </td>
                  <td className='align-middle'>{data.invoiceNumber}</td>
                  <td className='align-middle'>{data.invoiceDateStr}</td>
                  <td className='align-middle'>{data.totalStr}</td>
                  <td colSpan={4} />
                  <td className='align-middle'>
                    {data.processDate === null && (
                      <button
                        type='button'
                        className='btn btn-warning'
                        disabled={data.invoiceId === null}
                        onClick={this.onProcessInvoice}
                      >Process</button>
                    )}
                    {data.processDate !== null && data.processDateStr}
                  </td>
                  <td className='align-middle'>
                    {data.payDateStr}
                  </td>
                </Item>
                {data.credits.map((c, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td className='p-1 border-0' />
                    </tr>
                    <Item alert={true}>
                      <td className='align-middle' colSpan={3} />
                      <td className='align-middle'>{c.totalStr}</td>
                      <td className='align-middle'>{c.qty}x {c.description} {c.partNumber}</td>
                      <td className='align-middle'>{c.creditDateStr}</td>
                      <td className='align-middle'>{c.reason}</td>
                      <td className='align-middle'>{c.collectDateStr}</td>
                      <td className='align-middle'>
                        {c.processDate === null && (
                          <button
                            type='button'
                            className='btn btn-warning'
                            disabled={c.collectDate === null}
                            onClick={this.onProcessCredit.bind(this, c)}
                          >Process</button>
                        )}
                        {c.processDate !== null && c.processDateStr}
                      </td>
                      <td className='align-middle'>
                        {c.payDateStr}
                      </td>
                    </Item>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </Card>
        )}
      </Container>
    );
  };
};

