export default [
  'ABARTH', 'ALFA ROMEO', 'ALPINA', 'ALPINE', 'ARMSTRONG SIDDELEY',
  'ASIA MOTORS', 'ASTON MARTIN', 'AUDI', 'AUSTIN', 'AUSTIN HEALEY',
  'AUSTRALIAN CLASSIC CAR', 'BEDFORD', 'BENTLEY', 'BERTONE', 'BLADE',
  'BMC', 'BMW', 'BOLWELL', 'BUFORI', 'BUGATTI', 'BUICK', 'CADILLAC',
  'CATERHAM', 'CHERY', 'CHEVROLET', 'CHRYSLER', 'CITROEN', 'COMMER',
  'CSV', 'DAEWOO', 'DAIHATSU', 'DAIMLER', 'DATSUN', 'DE TOMASO', 'DODGE',
  'ELFIN', 'EUNOS', 'FERRARI', 'FIAT', 'FORD', 'FORD PERFORMANCE VEHICLES',
  'FOTON', 'FSM', 'GEELY', 'GENESIS', 'GIOCATTOLO', 'GREAT WALL', 'HAVAL',
  'HDT', 'HILLMAN', 'HINO', 'HOLDEN', 'HOLDEN SPECIAL VEHICLES', 'HONDA',
  'HUMBER', 'HUMMER', 'HYUNDAI', 'INFINITI', 'INTERNATIONAL', 'ISO', 'ISUZU',
  'JAGUAR', 'JEEP', 'JENSEN', 'JMC', 'KIA', 'KTM', 'LADA', 'LAMBORGHINI',
  'LANCIA', 'LAND ROVER', 'LDV', 'LEXUS', 'LEYLAND', 'LIGHTBURN', 'LINCOLN',
  'LOTUS', 'MAHINDRA', 'MASERATI', 'MAYBACH', 'MAZDA', 'MCLAREN', 'MERCEDES-BENZ',
  'MG', 'MINI', 'MITSUBISHI', 'MORGAN', 'MORRIS', 'NISSAN', 'NOBLE', 'NSU',
  'OPEL', 'PAGANI', 'PEUGEOT', 'PONTIAC', 'PORSCHE', 'PROTON', 'RAM',
  'RAMBLER', 'RENAULT', 'ROBNELL', 'ROLLS-ROYCE', 'ROVER', 'SAAB', 'SEAT',
  'SIMCA', 'SKODA', 'SMART', 'SSANGYONG', 'STUDEBAKER', 'SUBARU', 'SUZUKI',
  'TATA', 'TD 2000', 'TESLA', 'TOYOTA', 'TRD', 'TRIUMPH', 'TVR', 'VANDEN PLAS',
  'VAUXHALL', 'VOLKSWAGEN', 'VOLVO', 'WOLSELEY', 'ZX AUTO'
];

