import React from 'react';

const STATES = ['ACT', 'NSW', 'NT', 'QLD', 'VIC', 'TAS', 'WA'];

export default class SettingsPublicHoliday extends React.Component {

  onChangeStateOption(e) {        
    if (e.target.value === 'All') {
        this.props.onChange({
          states: {"ALL": true}
        });
    } else {
        this.props.onChange({
          states: {"ALL": false}
        });
     }
  }
  onToggleState(field, e) {
    const { states } = this.props;    
    this.props.onChange({
      states: {
        ...states,
        [field]: e.target.checked
      }
    });
  }
 
  onDelete(index) {
    const {onDelete} = this.props;
    onDelete(index);
  }
  onSave(index, states) {
    const { onSave } = this.props;    
    onSave(index, states)
  }
  
  render() {
    console.log('settingspublicholoday:render');    
    const {onClose} = this.props;       
    const { dayStr, states, index } = this.props;
    
    return (
      <React.Fragment>
        <div className='modal-body'>                    
          <h6 className='mt-3'>Select states for {dayStr}</h6>          
          <div>
            <h6 className='text-muted'>States</h6>
            <div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='current-state'
                  id='current-state-1'
                  value='All'
                  checked={states["ALL"] === true}
                  onChange={this.onChangeStateOption.bind(this)}
                />
                <label className='form-check-label' htmlFor='current-state-1'>All</label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='current-state'
                  id='current-state-2'
                  value='Custom'
                  checked={states["ALL"] === false}
                  onChange={this.onChangeStateOption.bind(this)}
                />
                <label className='form-check-label' htmlFor='current-state2'>Custom</label>
              </div>              
            </div>
          </div>         
          {states["ALL"] !== true && (
            <React.Fragment>
              <div className='row'>
              {STATES.map((s, i) => (
                <div className='col-4 mt-2' key={i+1}>
                  <div className='form-group form-check mb-0'>
                    <input
                      type='checkbox'
                      id={`s-${i+1}`}
                      className='form-check-input'
                      checked={states[s] === true}
                      onChange={this.onToggleState.bind(this, s)}
                    />
                    <label
                      htmlFor={`s-${i+1}`}
                      className='form-check-label'
                    >{s}</label>
                  </div>
                </div>
            ))}
            </div>
            </React.Fragment>
          )}          
        </div>
        <div className='modal-footer'>          
          <button type='button' className='btn btn-danger mr-auto' onClick={this.onDelete.bind(this, index)}>Delete</button>          
          <button type='button' className='btn btn-secondary' onClick={onClose}>Close</button>
          <button type='button' className='btn btn-primary' 
            onClick={this.onSave.bind(this, index, states)}
            disabled={Object.entries(states).filter(s => s[1] === true).length === 0}
          >Save</button>
        </div>
      </React.Fragment>
    );
  }
};
