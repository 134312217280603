import numeral from 'numeral';

import moment, {_moment} from 'm';
import * as PanelData from 'utils/panel-data';
import MAX_DENT_COUNTS from 'utils/max-dent-counts';


const WORKS = {
  paint: 'AutoPaintable',
  replace: 'AutoReplaceable'
};
const AUTO_INDEX = Object.keys(WORKS).reduce((acc, w) => {
  acc[w] = [];
  ['rr', 'paint', 'part', 'misc'].reduce((acc, t) => {
    const properT = t.charAt(0).toUpperCase() + t.substr(1);
    acc.push({
      type: t,
      id: `${t}Id`,
      can: `can${properT}`,
      idx: `${t}${WORKS[w]}`,
    });
    return acc;
  }, acc[w]);
  return acc;
}, {});

const getJobStatus = j => {
  const today = _moment()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .unix();
  const tomorrow = _moment()
    .add(1, 'day')
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .unix();
  if (j.archiveDate !== null) return 'Archived';
  else if (j.cancelDate !== null) return 'Cancelled';
  else if (j.debtorId === null || j.primaryLocation === null) return 'Unallocated';
  else if (
    j.csTlDate !== null ||
    j.totalLoss === true ||
    j.cashSettle === true
  ) return 'Cancelled';
  else if (j.assessmentDate === null) return 'New';
  else if (j.total === 0) {
    if (j.assessmentDate < today) return 'Assessment no show';
    else if (j.assessmentDate < tomorrow) return 'Assessing today';
    else return 'Waiting for assessment';
  } else if (j.startDate === null) {
    if (j.flagged === true) return 'Flagged';
    else return 'To book for repairs';
  } else if (j.inDate === null) {
    if (j.startDate < today) return 'Repair no show';
    else if (j.startDate <= tomorrow) return 'Arriving today/tomorrow';
    else return 'Due in';
  } else if (j.outDate === null) {
    if (
      j.fitupDate === null ||
      (j.painter !== null && j.paintDate === null) ||
      (j.repairer !== null && j.repairDate === null) ||
      (j.striper !== null && j.stripDate === null)
    ) return 'In production';
    else if (j.readyDate === null) return 'To arrange ready time';
    else if (j.pickupDate === null) return 'To arrange pickup time';
    else return 'Due out';
  } else if (j.quoteDate === null) return 'To be sent for authority';
  else if (j.authorityDeclineDate === null && j.authoriseDate === null) return 'Waiting for authority';
  else if (j.authorityDeclineDate > j.authoriseDate) return 'Authority declined';
  else if (j.invoiceDate === null) return 'To be invoiced';
  else if (j.payDate === null) {
    if (j.paymentDeclineDate === null && j.paymentAuthoriseDate === null) return 'Waiting for payment';
    else if (j.paymentDeclineDate > j.paymentAuthoriseDate) return 'Payment declined';
    else return 'Payment authorised';
  }
  else return 'Paid';
};

const pricePdrRow = (row, hasPaint, dentMatrixFactor) => {
  const {description: panel, qty, size, p2p} = row;
  if (panel && qty && size) {
    const panelSizeFactor = PanelData.PANEL_SIZE_FACTORS[panel];
    const panelMaterialFactor = PanelData.PANEL_MATERIAL_FACTOR;
    const dentSizeFactor = PanelData.DENT_SIZE_FACTORS[size];
    const p2pFactor = PanelData.P2P_FACTORS[p2p === true];

    let cost = 0;
    let counter = 0;
    for (let i = 0; i < PanelData.NUM_DENTS_FACTORS.length; i++) {
      let growth = PanelData.NUM_DENTS_FACTORS[i].rate;
      let max = PanelData.NUM_DENTS_FACTORS[i].max;
      while (counter < qty && counter < max) {
        if (cost === 0) {
          cost = 80;
        } else {
          cost *= growth;
        }
        counter++;
      }
    }
    if (
      MAX_DENT_COUNTS.hasOwnProperty(panel) &&
      MAX_DENT_COUNTS[panel].hasOwnProperty(size)
    ) {
      row.highCount = qty > MAX_DENT_COUNTS[panel][size];
    } else {
      console.warn('MAX_DENT_COUNT NOT DEFINED FOR', panel);
      row.highCount = false;
    }
    row.total = cost * panelSizeFactor * panelMaterialFactor * dentSizeFactor * p2pFactor * dentMatrixFactor / 100;
    if (hasPaint === true) {
      row.total *= 0.75;
    }
    row.total = Math.round(row.total);
    row.totalStr = row.total.toFixed(2);
  } else {
    row.highCount = false;
    row.total = 0;
    row.totalStr = '';
  }
};

const generateNewItem = (
  bodyPaint,
  rowType,
  rate = undefined,
  lineItemId = undefined,
  item = {},
  id = null
) => {
  let res = {
    id,
    lineItemId,
    deleted: false,
    comments: '',
  };
  switch (rowType) {
    case 'category':
      res.description = 'CATEGORY';
      res.comments = undefined;
      break;
    case 'pdr':
      res.description = item.panel;
      res.desc = res.description.replace(/-/g, ' ');
      res.qty = item.qty;
      res.size = item.size;
      res.total = null;
      res.p2p = false;
      res.categoryExtra = item.categoryExtra || false;
      res.onReport = false;
      res.ormStatus = 'ADDED';
      res.ormComments = '';
      res.highCount = false;
      break;
    case 'rr':
      res.description = item.description || '';
      res.hours = item.rrTime ? Math.round(item.rrTime * 100) / 100 : item.rrTime;
      res.rate = rate;
      res.total = (res.hours && res.rate) ? (res.hours * res.rate) : null;
      res.categoryExtra = item.categoryExtra || false;
      res.onReport = false;
      res.ormStatus = 'ADDED';
      res.ormComments = '';
      break;
    case 'repair':
      res.description = item.description || '';
      res.hours = item.repairTime ? Math.round(item.repairTime * 100) / 100 : item.hours;
      res.rate = rate;
      res.total = (res.hours && res.rate) ? (res.hours * res.rate) : null;
      res.categoryExtra = item.categoryExtra || false;
      res.onReport = false;
      res.ormStatus = 'ADDED';
      res.ormComments = '';
      break;
    case 'paint':
      res.description = item.description || '';
      res.hours = item.paintTime ? Math.round(item.paintTime * 100) / 100 : item.paintTime;
      res.rate = rate;
      res.total = (res.hours && res.rate) ? (res.hours * res.rate) : null;
      res.blend = item.isBlend === true;
      res.paintGroup = bodyPaint;
      res.categoryExtra = item.categoryExtra || false;
      res.onReport = false;
      res.ormStatus = 'ADDED';
      res.ormComments = '';
      break;
    case 'part':
      res.description = item.description || '';
      res.partNumber = item.partNumber || '';
      res.qty = 1;
      res.price = Number(item.partPrice || '');
      res.total = res.price !== null ? res.price : null;
      res.source = 'NEW';
      res.replacePanel = item.replacePanel || null;
      res.categoryExtra = item.categoryExtra || false;
      res.partOutcome = null;
      res.partEta = null;
      res.partEtaStr = '';
      res.onReport = false;
      res.ormStatus = 'ADDED';
      res.ormComments = '';
      break;
    case 'misc':
      res.description = item.description || '';
      res.price = Math.round(Number(item.miscPrice || '') * 100) / 100;
      res.total = res.price !== null ? res.price : null;
      res.categoryExtra = item.categoryExtra || false;
      res.onReport = false;
      res.ormStatus = 'ADDED';
      res.ormComments = '';
      break;
    case 'sublet':
      res.description = item.description || '';
      res.price = Math.round(Number(item.subletPrice || '') * 100) / 100;
      res.total = res.price !== null ? res.price : null;
      res.categoryExtra = item.categoryExtra || false;
      res.onReport = false;
      res.ormStatus = 'ADDED';
      res.ormComments = '';
      break;
    case 'add-pdr':
      res.description = item.description || '';
      res.total = null;
      res.onReport = false;
      res.ormStatus = 'ADDED';
      res.ormComments = '';
      break;
    default:
      break;
  }
  res.totalStr = res.total !== undefined && res.total !== null ? res.total.toFixed(2) : '';
  return res;
};

const massageQuote = (lines) => {
  console.warn('NEED TO STORE STORM SETTINGS AGAINST JOB SO IT DOESNT CHANGE CALCULATIONS AFTER INVOICING... THINGS LIKE CATEGORY SETTINGS ETC');
  const {pdr, quote, selectedLineItems} = lines.reduce((acc, l, idx) => {
    const descId = l.description.replace(/[^a-z0-9\s]/gi, '');
    if (l.type === 'pdr') {
      const panel = l.description;
      const description = l.description.replace(/-/g, ' ');
      if (l.deleted !== true) {
        acc.pdr[panel] = {
          i: acc.quote.pdr.length,
          qty: l.qty,
          size: l.size,
        };
      }
      const ormTotal = (l.ormHours && l.ormRate) ? (l.ormHours * l.ormRate) : null;
      const ormTotalStr = ormTotal !== undefined && ormTotal !== null
        ? ormTotal.toFixed(2)
        : '';
      const pdrRow = {
        ...l,
        desc: l.p2p ? `P2P ${description}` : description,
        lineItemId: `pdr-${descId}`,
        ormTotal,
        ormTotalStr,
      };
      acc.quote.pdr.push(pdrRow);
    } else if (l.type === 'category') {
      acc.quote.category.push(l);
    } else {
      acc.selectedLineItems[`${l.type}-${descId}`] = l.deleted !== true;
      const newRow = {
        ...l,
        lineItemId: `${l.type}-${descId}`
      };
      if (['rr', 'repair', 'paint'].indexOf(l.type) !== -1) {
        newRow.total = (l.hours && l.rate) ? (l.hours * l.rate) : null;
        newRow.ormTotal = (l.ormHours && l.ormRate) ? (l.ormHours * l.ormRate) : null;
      } else if (['misc', 'sublet', 'add-pdr'].indexOf(l.type) !== -1) {
        newRow.total = l.price !== undefined ? Number(l.price) : null;
        newRow.ormTotal = l.ormPrice !== undefined ? Number(l.ormPrice) : null;
      } else if (l.type === 'part') {
        newRow.partEtaStr = l.partEta ? moment(l.partEta * 1000).format('DD-MM') : '';
        newRow.total = (l.qty !== undefined && l.price !== undefined)
          ? (l.qty * l.price)
          : null;
        newRow.ormTotal = (l.ormQty !== undefined && l.ormPrice !== undefined)
          ? (l.ormQty * l.ormPrice)
          : null;
      } else {
        newRow.total = (l.qty !== undefined && l.price !== undefined) ? (l.qty * l.price) : null;
        newRow.ormTotal = (l.ormQty !== undefined && l.ormPrice !== undefined) ? (l.ormQty * l.ormPrice) : null;
      }
      newRow.totalStr = newRow.total !== undefined && newRow.total !== null
        ? newRow.total.toFixed(2)
        : '';
      newRow.ormTotalStr = newRow.ormTotal !== undefined && newRow.ormTotal !== null
        ? newRow.ormTotal.toFixed(2)
        : '';
      acc.quote[l.type].push(newRow);
    }
    return acc;
  }, {
    pdr: {},
    quote: {
      category: [],
      pdr: [],
      rr: [],
      repair: [],
      paint: [],
      part: [],
      misc: [],
      sublet: [],
      'add-pdr': []
    },
    selectedLineItems: {}
  });
  Object.keys(quote).forEach(t => {
    if (t !== 'category' && t !== 'pdr') {
      quote[t].push({
        id: null
      });
    }
  });
  return {pdr, quote, selectedLineItems};
};


let _lastDentMatrixFactor = null;
let _lastPdr = null;
let _lastPaint = null;
let _lastBodyShape = null;
let _lastBodyPaint = null;
let _lastPaintTotal = null;
const reset = () => {
  _lastDentMatrixFactor = null;
  _lastPdr = null;
  _lastPaint = null;
  _lastBodyShape = null;
  _lastBodyPaint = null;
  _lastPaintTotal = null;
};
const animateQuote = (quote, selectedLineItems, header, lineItems) => {
  if (!quote || !lineItems) {
    return {
      header,
      quote,
      selectedLineItems,
      pdrPanels: {},
      paintPanels: {},
      replacePanels: {},
      bookingType: null,
      calculatedRepairDays: null,
      category: 0,
      potentialTotalLoss: false,
      lowCategoryAdjustment: false,
      totals: {
        pdr: 0,
        rr: 0,
        repair: 0,
        paint: 0,
        part: 0,
        misc: 0,
        sublet: 0,
        addPdr: 0,
        quoted: 0,
        catAdjustment: 0,
        catExtras: 0,
        subtotal: 0,
        gst: 0,
        total: 0,
      }
    };
  }

  let dentMatrixFactor = 100;
  if (header.jobSettings !== null) {
    dentMatrixFactor = header.jobSettings.dentMatrixFactor;
  }

  const {
    category: categoryList,
    pdr: pdrList,
    repair: repairList,
    part: partList,
    paint: paintList,
    sublet: subletList,
    'add-pdr': addPdrList,
  } = quote;
  let newQuote = quote;
  let newSelectedLineItems = selectedLineItems;
  const {
    paintPanels: _paintPanels,
    blendPanels,
    paintCount: _numPaintPanels,
    blendCount: numBlendPanels,
    total: paintTotal,
    hours: paintHours,
    catExtraTotal: paintCatExtraTotal,
    damagedPanels: __damagedPanels,
  } = paintList
    .reduce((acc, {categoryExtra, onReport, lineItemId, hours, total, ormStatus, deleted, blend}) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE') {
        const panel = lineItems.paint.sections[lineItemId];
        if (panel && acc.paintPanels[panel] !== true) {
          acc.damagedPanels[panel] = true;
          acc.paintPanels[panel] = true;
          if (blend === true) {
            acc.blendPanels[panel] = true;
            acc.blendCount++;
          }
          acc.paintCount++;
        }
        if (categoryExtra === true) {
          acc.catExtraTotal += Math.round((total || 0) * 100);
        } else {
          acc.total += Math.round((total || 0) * 100);
          acc.hours += parseFloat(hours || 0);
        }
      }
      return acc;
    }, {
      paintPanels: {},
      blendPanels: {},
      paintCount: 0,
      blendCount: 0,
      total: 0,
      hours: 0,
      catExtraTotal: 0,
      damagedPanels: {},
    });
  const {
    panels: replacePanels,
    count: numReplacePanels,
    total: partTotal,
    catExtraTotal: partCatExtraTotal,
    damagedPanels: _damagedPanels,
  } = partList
    .reduce((acc, {categoryExtra, onReport, replacePanel, total, ormStatus, deleted}) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE') {
        if (replacePanel && acc.panels[replacePanel] !== true) {
          acc.damagedPanels[replacePanel] = true;
          acc.panels[replacePanel] = true;
          acc.count++;
        }
        if (categoryExtra === true) {
          acc.catExtraTotal += Math.round((total || 0) * 100);
        } else {
          acc.total += Math.round((total || 0) * 100);
        }
      }
      return acc;
    }, {
      panels: {},
      count: 0,
      total: 0,
      catExtraTotal: 0,
      damagedPanels: __damagedPanels,
    });
  const {
    panels: pdrPanels,
    numPdrPanels,
    numDents,
    paintPanels,
    numPaintPanels,
    damagedPanels,
  } = pdrList
    .reduce((acc, {categoryExtra, onReport, description, size, qty, p2p, total, ormStatus, deleted}) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE' && size && qty) {
        if (acc.panels[description] !== true) {
          acc.numPdrPanels++;
          acc.panels[description] = true;
          acc.damagedPanels[description] = true;
        }
        if (p2p === true && acc.paintPanels[description] !== true) {
          acc.paintPanels[description] = true;
          acc.numPaintPanels++;
        }
        if (!acc.numDents.hasOwnProperty(size)) {
          acc.numDents[size] = 0;
        }
        acc.numDents[size] += qty;
      }
      return acc;
    }, {
      panels: {},
      numPdrPanels: 0,
      numDents: {1: 0, 2: 0, 3: 0, 4: 0},
      paintPanels: _paintPanels,
      numPaintPanels: _numPaintPanels,
      damagedPanels: _damagedPanels,
    });
  const {
    total: repairTotal,
    catExtraTotal: repairCatExtraTotal,
    hours: repairHours,
  } = repairList
    .reduce((acc, {hours, categoryExtra, onReport, total, ormStatus, deleted}, j) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE') {
        if (categoryExtra === true) {
          acc.catExtraTotal += Math.round((total || 0) * 100);
        } else {
          acc.hours += parseFloat(hours || 0);
          acc.total += Math.round((total || 0) * 100);
        }
      }
      return acc;
    }, {
      total: 0,
      catExtraTotal: 0,
      hours: 0,
    });

  if (_lastPdr !== pdrList || _lastPaint !== paintList || _lastDentMatrixFactor !== dentMatrixFactor) {
    newQuote = {
      ...newQuote,
      pdr: pdrList.map(l => {
        if (replacePanels[l.description] === true) {
          l.qty = 0;
          l.size = 0;
          l.deleted = true;
        }
        pricePdrRow(l, paintPanels[l.description] === true, dentMatrixFactor);
        return {
          ...l
        };
      })
    };

    _lastDentMatrixFactor = dentMatrixFactor;
    _lastPdr = newQuote.pdr;
    _lastPaint = newQuote.paint;
  }

  const {
    total: pdrTotal,
    catExtraTotal: pdrCatExtraTotal
  } = newQuote.pdr
    .reduce((acc, {categoryExtra, onReport, size, qty, total, ormStatus, deleted}) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE' && size && qty) {
        if (categoryExtra === true) {
          acc.catExtraTotal += Math.round((total || 0) * 100);
        } else {
          acc.total += Math.round((total || 0) * 100);
        }
      }
      return acc;
    }, {
      total: 0,
      catExtraTotal: 0,
    });

  if (header.invoiceDate === null) {
    if (pdrTotal || pdrCatExtraTotal) {
      if (newSelectedLineItems['rr-RR NECESSARY FOR PDR'] === undefined) {
        newSelectedLineItems = {
          ...newSelectedLineItems,
          'rr-RR NECESSARY FOR PDR': true
        };
        const newItem = generateNewItem(undefined, 'rr', 100, 'rr-RR NECESSARY FOR PDR', {
          description: 'R&R NECESSARY FOR PDR',
          rrTime: PanelData.RR_FACTORS[header.bodyShape],
        });
        const newRr = newQuote.rr.slice();
        newRr.splice(newQuote.rr.length - 1, 0, newItem);
        newQuote = {
          ...newQuote,
          rr: newRr
        };
        _lastBodyShape = header.bodyShape;
      } else if (
        newSelectedLineItems['rr-RR NECESSARY FOR PDR'] === true &&
        _lastBodyShape !== header.bodyShape
      ) {
        _lastBodyShape = header.bodyShape;
        newQuote = {
          ...newQuote,
          rr: newQuote.rr.map(l => {
            if (l.lineItemId !== 'rr-RR NECESSARY FOR PDR') return l;
            const hours = PanelData.RR_FACTORS[header.bodyShape];
            const total = (hours && l.rate) ? (hours * l.rate) : null;
            return {
              ...l,
              hours,
              total,
              totalStr: total ? (total).toFixed(2) : '',
            };
          })
        };
      }

      // the extra space after COAT has to be there...
      // the regex replaces & with \s
      if (newSelectedLineItems['misc-PROOF COAT  WAX'] === undefined) {
        newSelectedLineItems = {
          ...newSelectedLineItems,
          'misc-PROOF COAT  WAX': true
        };
        const newItem = generateNewItem(undefined, 'misc', undefined, 'misc-PROOF COAT  WAX', {
          description: 'PROOF, COAT & WAX',
          miscPrice: numPaintPanels > 0 ? 6.60 * numPdrPanels : 25,
        });
        const newMisc = newQuote.misc.slice();
        newMisc.splice(newQuote.misc.length - 1, 0, newItem);
        newQuote = {
          ...newQuote,
          misc: newMisc
        };
      } else {
        newQuote = {
          ...newQuote,
          misc: newQuote.misc.map(l => {
            if (l.lineItemId !== 'misc-PROOF COAT  WAX') return l;
            const price = Math.round((numPaintPanels > 0 ? 6.60 * numPdrPanels : 25) * 100) / 100;
            return {
              ...l,
              price,
              total: price,
              totalStr: price.toFixed(2),
            };
          })
        };
      }

      // handle infection allowance
      if (header.jobSettings && header.jobSettings.infectionAllowanceEnabled === true) {
        if (newSelectedLineItems['misc-INFECTION ALLOWANCE'] === undefined) {
          newSelectedLineItems = {
            ...newSelectedLineItems,
            'misc-INFECTION ALLOWANCE': true
          };
          const newItem = generateNewItem(undefined, 'misc', undefined, 'misc-INFECTION ALLOWANCE', {
            description: 'INFECTION ALLOWANCE',
            miscPrice: 25,
          });
          const newMisc = newQuote.misc.slice();
          newMisc.splice(newQuote.misc.length - 1, 0, newItem);
          newQuote = {
            ...newQuote,
            misc: newMisc
          };
        } else {
          newQuote = {
            ...newQuote,
            misc: newQuote.misc.map(l => {
              if (l.lineItemId !== 'misc-INFECTION ALLOWANCE') return l;
              const price = 25;
              return {
                ...l,
                price,
                total: price,
                totalStr: price.toFixed(2),
              };
            })
          };
        }
      }
    }

    if (
      replacePanels['TURRET'] === true ||
      replacePanels['LH-CANTRAIL'] === true ||
      replacePanels['RH-CANTRAIL'] === true ||
      replacePanels['LHR-QTR'] === true ||
      replacePanels['RHR-QTR'] === true
    ) {
      if (newSelectedLineItems['misc-WELDED PANEL ALLOWANCE'] === undefined) {
        newSelectedLineItems = {
          ...newSelectedLineItems,
          'misc-WELDED PANEL ALLOWANCE': true
        };
        const newItem = generateNewItem(undefined, 'misc', undefined, 'misc-WELDED PANEL ALLOWANCE', {
          description: 'WELDED PANEL ALLOWANCE',
          miscPrice: 9.70
        });
        const newMisc = newQuote.misc.slice();
        newMisc.splice(newQuote.misc.length - 1, 0, newItem);
        newQuote = {
          ...newQuote,
          misc: newMisc
        };
      }
    }

    if (header.jobSettings && header.jobSettings.pricingMethod !== 'panel') {
      if (newSelectedLineItems['misc-PANEL CONSUMABLES'] === undefined && repairHours) {
        newSelectedLineItems = {
          ...newSelectedLineItems,
          'misc-PANEL CONSUMABLES': true
        };
        const newItem = generateNewItem(undefined, 'misc', undefined, 'misc-PANEL CONSUMABLES', {
          description: 'PANEL CONSUMABLES',
          miscPrice: Number(4.70 * repairHours).toFixed(2),
        });
        const newMisc = newQuote.misc.slice();
        newMisc.splice(newQuote.misc.length - 1, 0, newItem);
        newQuote = {
          ...newQuote,
          misc: newMisc
        };
      } else if (newSelectedLineItems['misc-PANEL CONSUMABLES'] === true) {
        newQuote = {
          ...newQuote,
          misc: newQuote.misc.map(l => {
            if (l.lineItemId !== 'misc-PANEL CONSUMABLES') return l;
            const price = Math.round(4.70 * repairHours * 100) / 100;
            return {
              ...l,
              price,
              total: price,
              totalStr: price.toFixed(2),
            };
          })
        };
      }

      if (Number(_lastPaintTotal) !== Number(paintTotal) || _lastBodyPaint !== header.bodyPaint) {
        if (newSelectedLineItems['misc-PAINT MATERIALS'] === undefined && paintTotal) {
          newSelectedLineItems = {
            ...newSelectedLineItems,
            'misc-PAINT MATERIALS': true
          };
          const newItem = generateNewItem(undefined, 'misc', undefined, 'misc-PAINT MATERIALS', {
            description: 'PAINT MATERIALS',
            miscPrice: Number(PanelData.PAINT_MATERIAL_FACTORS[header.bodyPaint] * paintHours).toFixed(2),
          });
          const newMisc = newQuote.misc.slice();
          newMisc.splice(newQuote.misc.length - 1, 0, newItem);
          newQuote = {
            ...newQuote,
            misc: newMisc
          };
        } else if (newSelectedLineItems['misc-PAINT MATERIALS'] === true) {
          newQuote = {
            ...newQuote,
            misc: newQuote.misc.map(l => {
              if (l.lineItemId !== 'misc-PAINT MATERIALS') return l;
              const price = Math.round(
                PanelData.PAINT_MATERIAL_FACTORS[header.bodyPaint] * paintHours * 100) / 100;
              return {
                ...l,
                price,
                total: price,
                totalStr: price.toFixed(2),
              };
            })
          };
        }
        if (newSelectedLineItems['misc-PAINT CONSUMABLES'] === undefined && paintTotal) {
          newSelectedLineItems = {
            ...newSelectedLineItems,
            'misc-PAINT CONSUMABLES': true
          };
          const newItem = generateNewItem(undefined, 'misc', undefined, 'misc-PAINT CONSUMABLES', {
            description: 'PAINT CONSUMABLES',
            miscPrice: Number(8.30 * paintHours).toFixed(2),
          });
          const newMisc = newQuote.misc.slice();
          newMisc.splice(newQuote.misc.length - 1, 0, newItem);
          newQuote = {
            ...newQuote,
            misc: newMisc
          };
        } else if (newSelectedLineItems['misc-PAINT CONSUMABLES'] === true) {
          newQuote = {
            ...newQuote,
            misc: newQuote.misc.map(l => {
              if (l.lineItemId !== 'misc-PAINT CONSUMABLES') return l;
              const price = Math.round(8.30 * paintHours * 100) / 100;
              return {
                ...l,
                price,
                total: price,
                totalStr: price.toFixed(2),
              };
            })
          };
        }
        _lastBodyPaint = header.bodyPaint;
        _lastPaintTotal = paintTotal;
      }
    }
  }

  const {total: rrTotal, catExtraTotal: rrCatExtraTotal} = newQuote.rr
    .reduce((acc, {categoryExtra, onReport, total, ormStatus, deleted}) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE') {
        if (categoryExtra === true) {
          acc.catExtraTotal += Math.round((total || 0) * 100);
        } else {
          acc.total += Math.round((total || 0) * 100);
        }
      }
      return acc;
    }, {total: 0, catExtraTotal: 0});
  const {total: miscTotal, catExtraTotal: miscCatExtraTotal} = newQuote.misc
    .reduce((acc, {categoryExtra, onReport, total, ormStatus, deleted}) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE') {
        if (categoryExtra === true) {
          acc.catExtraTotal += Math.round((total || 0) * 100);
        } else {
          acc.total += Math.round((total || 0) * 100);
        }
      }
      return acc;
    }, {total: 0, catExtraTotal: 0});
  const {total: subletTotal, catExtraTotal: subletCatExtraTotal} = subletList
    .reduce((acc, {categoryExtra, onReport, total, ormStatus, deleted}) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE') {
        if (categoryExtra === true) {
          acc.catExtraTotal += Math.round((total || 0) * 100);
        } else {
          acc.total += Math.round((total || 0) * 100);
        }
      }
      return acc;
    }, {total: 0, catExtraTotal: 0});
  const {total: addPdrTotal} = addPdrList
    .reduce((acc, {onReport, total, ormStatus, deleted}) => {
      if (deleted !== true && onReport !== true && ormStatus !== 'ACKNOWLEDGEDDELETE') {
        acc.total += Math.round((total || 0) * 100);
      }
      return acc;
    }, {total: 0});

  let calculatedRepairDays = null;
  if (header.jobSettings !== null) {
    const {
      repair: numRepairDays,
      pdr: numPdrDays,
      paint: numPaintDays,
      respray: numResprayDays,
      turret: numTurretDays
    } = header.jobSettings.bookingDays;
    if (replacePanels['TURRET'] === true || paintPanels['TURRET'] === true) {
      calculatedRepairDays = numTurretDays;
    } else if (numPaintPanels > 3) {
      calculatedRepairDays = numResprayDays;
    } else if (numPaintPanels > 0) {
      calculatedRepairDays = numPaintDays;
    } else if (numDents[1] + numDents[2] + numDents[3] + numDents[4] > 0) {
      calculatedRepairDays = numPdrDays;
    } else if (['panel', 'line-by-line'].indexOf(header.jobSettings.pricingMethod) !== -1) {
      calculatedRepairDays = numRepairDays;
    }
  }

  let bookingType = null;
  if (replacePanels['TURRET'] === true) {
    bookingType = 'turret';
  } else if (numPaintPanels > 0) {
    bookingType = 'paint';
  } else if (numDents[1] + numDents[2] + numDents[3] + numDents[4] > 0) {
    bookingType = 'pdr';
  } else if (
    header.jobSettings !== null &&
    (['panel', 'line-by-line'].indexOf(header.jobSettings.pricingMethod) !== -1)
  ) {
    bookingType = 'repair';
  }

  if (header.invoiceDate !== null) {
    let totalLossPercentage = 70;
    let minCategoryAdjustment = 0;
    if (header.jobSettings !== null) {
      (
        {
          totalLossPercentage,
          minCategoryAdjustment,
        } = header.jobSettings
      );
    }
    const potentialTotalLoss = header.total >= header.insuredValue * totalLossPercentage / 100;
    const lowCategoryAdjustment = (
      header.category !== 0 &&
      minCategoryAdjustment > 0 &&
      header.categoryAdjustment < minCategoryAdjustment * 100
    );
    return {
      header,
      quote: newQuote,
      selectedLineItems: newSelectedLineItems,
      pdrPanels,
      paintPanels,
      replacePanels,
      bookingType,
      calculatedRepairDays,
      category: header.category,
      potentialTotalLoss,
      lowCategoryAdjustment,
      totals: {
        pdr: header.pdrTotal,
        rr: header.rrTotal,
        repair: header.repairTotal,
        paint: header.paintTotal,
        part: header.partTotal,
        misc: header.miscTotal,
        sublet: header.subletTotal,
        quoted: header.quotedTotal,
        addPdr: header.addPdrTotal,
        catAdjustment: header.categoryAdjustment,
        catExtras: header.categoryExtraTotal,
        subtotal: header.subtotal,
        gst: header.gst,
        total: header.total,
      }
    };
  }

  const quoted =
    (Math.round(pdrTotal / 100) * 100) +
    (Math.round(rrTotal / 100) * 100) +
    (Math.round(repairTotal / 100) * 100) +
    (Math.round(paintTotal / 100) * 100) +
    partTotal +
    miscTotal +
    subletTotal;
  const catExtras =
    (Math.round(pdrCatExtraTotal / 100) * 100) +
    (Math.round(rrCatExtraTotal / 100) * 100) +
    (Math.round(repairCatExtraTotal / 100) * 100) +
    (Math.round(paintCatExtraTotal / 100) * 100) +
    partCatExtraTotal +
    miscCatExtraTotal +
    subletCatExtraTotal;

  let newHeader = header;
  if (catExtras !== 0) {
    newHeader = {
      ...header,
      autoOrmEnabled: false
    };
  }

  let pricingMethod = 'category';
  let category = 0;
  let categoryTotal = null;
  let categoryAllowance = 0;
  let totalLossPercentage = 70;
  let minCategoryAdjustment = 0;
  if (newHeader.jobSettings !== null) {
    const {
      pricingMethod: _pricingMethod,
      categoryMethod,
      categorySettings,
      totalLossPercentage: _totalLossPercentage,
      minCategoryAdjustment: _minCategoryAdjustment,
    } = newHeader.jobSettings;
    pricingMethod = _pricingMethod;
    totalLossPercentage = _totalLossPercentage || totalLossPercentage;
    minCategoryAdjustment = _minCategoryAdjustment || minCategoryAdjustment;
    if (pricingMethod === 'category' && newHeader.outOfScope !== true) {
      if (categoryMethod === 1) {
        const isCat7 = ['TURRET', 'LH-CANTRAIL', 'RH-CANTRAIL', 'LHR-QTR', 'RHR-QTR']
          .reduce((acc, p) => {
            if (replacePanels[p] === true) return true;
            return acc;
          }, false);
        if (isCat7) {
          category = 7;
        } else if (numReplacePanels >= 2 || numPaintPanels >= 4) {
          category = 6;
        } else if (
          numDents[1] + numDents[2] + numDents[3] + numDents[4] > 1200 ||
          numDents[2] + numDents[3] + numDents[4] > 1000 ||
          numDents[3] + numDents[4] > 750 ||
          numDents[4] > 200
        ) {
          category = 5;
        } else if (numReplacePanels > 0 || numPaintPanels > 0) {
          category = 4;
        } else if (
          numDents[1] + numDents[2] + numDents[3] + numDents[4] > 850 ||
          numDents[2] + numDents[3] + numDents[4] > 700 ||
          numDents[3] + numDents[4] > 500 ||
          numDents[4] > 100
        ) {
          category = 3;
        } else if (
          numDents[1] + numDents[2] + numDents[3] + numDents[4] > 350 ||
          numDents[2] + numDents[3] + numDents[4] > 200 ||
          numDents[3] + numDents[4] > 120 ||
          numDents[4] > 50
        ) {
          category = 2;
        } else if (numDents[1] + numDents[2] + numDents[3] + numDents[4] > 0) {
          category = 1;
        }
      } else if (categoryMethod === 2) {
        if (replacePanels['TURRET'] === true) {
          if (quoted <= categorySettings.max * 100) {
            category = 3;
          }
        } else if (numPaintPanels > 0) {
          category = 2;
        } else if (quoted >= categorySettings.min * 100) {
          category = 1;
        }
      } else if (categoryMethod === 3) {
        let numPaintPanelsSansBonnet = numPaintPanels;
        if (paintPanels['BONNET'] === true) {
          numPaintPanelsSansBonnet--;
        }
        let numReplacePanelsSansPlenum = numReplacePanels;
        if (replacePanels['PLENUM'] === true) {
          numReplacePanelsSansPlenum--;
        }
        if (
          paintPanels['BONNET'] === true &&
          blendPanels['LHF-GUARD'] === true
        ) {
          numPaintPanelsSansBonnet--;
        }
        if (
          paintPanels['BONNET'] === true &&
          blendPanels['RHF-GUARD'] === true
        ) {
          numPaintPanelsSansBonnet--;
        }
        if (numPaintPanels === 0 && numReplacePanelsSansPlenum === 0) {
          if (quoted >= categorySettings.min * 100) {
            category = 1;
          }
        } else if (
          numPaintPanels > 0 &&
          paintPanels['TURRET'] !== true &&
          (
            numPaintPanelsSansBonnet === 0 ||
            numPaintPanels - numBlendPanels <= 1
          )
        ) {
          category = 2;
        } else if (quoted <= categorySettings.max * 100) {
          category = 3;
        }
      } else if (categoryMethod === 4) {
        const numDamagedPanels = Object.keys(damagedPanels).length;
        if (
          quoted >= categorySettings.min * 100 &&
          quoted <= categorySettings.max * 100
        ) {
          if (numDamagedPanels > 7 && numPaintPanels > 0) {
            category = 4;
          } else if (numDamagedPanels > 7) {
            category = 3;
          } else if (numPaintPanels > 0) {
            category = 2;
          } else {
            category = 1;
          }

          if (replacePanels['TURRET'] === true) {
            categoryAllowance += header.jobSettings.categorySettings.turretAllowance;
          }
        }
      }

      if (category !== 0) {
        categoryTotal = (categorySettings[category] + categoryAllowance) * 100;
      }
    }
  }

  let catAdjustment = 0;
  if (categoryTotal !== null) {
    catAdjustment = categoryTotal - quoted;
  }

  let gstRate = 0;
  if (newHeader.jobSettings) {
    ({gstRate} = newHeader.jobSettings);
  }

  const subtotal = quoted + catAdjustment + catExtras;
  const gst = Math.round(subtotal * (gstRate / 100));
  const total = subtotal + gst;

  const potentialTotalLoss = total >= newHeader.insuredValue * totalLossPercentage / 100;
  const lowCategoryAdjustment = (
    category !== 0 &&
    minCategoryAdjustment > 0 &&
    catAdjustment < minCategoryAdjustment * 100
  );

  if (categoryList.length === 0 && subtotal !== 0) {
    const newItem = generateNewItem(undefined, 'category');
    newQuote = {
      ...newQuote,
      category: [newItem]
    };
  }

  return {
    header: newHeader,
    quote: newQuote,
    selectedLineItems: newSelectedLineItems,
    pdrPanels,
    paintPanels,
    replacePanels,
    bookingType,
    calculatedRepairDays,
    category,
    potentialTotalLoss,
    lowCategoryAdjustment,
    totals: {
      pdr: pdrTotal,
      rr: rrTotal,
      repair: repairTotal,
      paint: paintTotal,
      part: partTotal,
      misc: miscTotal,
      sublet: subletTotal,
      quoted: quoted,
      addPdr: addPdrTotal,
      catAdjustment,
      catExtras,
      subtotal,
      gst,
      total
    }
  };
};

const PREFILL_FIELDS = ['body', 'bodyShape', 'debtorId', 'stormId', 'companyId']
const prefillJobHeader = (header, lastJobHeader) => {
  if(header.companyId === null && header.debtorId === null && header.stormId === null) {
    PREFILL_FIELDS.forEach(f => {
      if(header[f] === null || header[f] === '') {
        header[f] = lastJobHeader[f]
      }
    })
  }
}

export default (state = {
  notFound: null,
  blockedDays: null,
  company: null,
  customerPortalUrl: null,
  claimPortalUrl: null,
}, action) => {
  let lastJobHeader = window.localStorage.getItem('lastJobHeader');
  if (lastJobHeader) {
    lastJobHeader = JSON.parse(lastJobHeader);
  }
  switch (action.type) {
    case 'JOB-DETAILS-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
        blockedDays: null,
        company: null,
        customerPortalUrl: null,
        claimPortalUrl: null,
      };
    case 'JOB-DETAILS-SET-FOUND':
      return {
        ...state,
        notFound: false,
        jobId: action.jobId,
        jobNumber: action.jobNumber,
        blockedDays: null,
      };
    case 'JOB-DETAILS-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
        blockedDays: null,
        company: null,
        customerPortalUrl: null,
        claimPortalUrl: null,
      };
    case 'JOB-DETAILS-PORTAL-SET':
      const {customerPortalUrl = null, claimPortalUrl = null} = action;
      return {
        ...state,
        customerPortalUrl: customerPortalUrl,
        claimPortalUrl: claimPortalUrl,
      };
    case 'JOB-DETAILS-COMPANY-SET':
      const {company = null}= action;
      return {
        ...state,
        company,
      };
    case 'JOB-DETAILS-HEADER-SET':
      const {header, generalSettings, debtors, storms} = action;
      if(lastJobHeader) {
        prefillJobHeader(header, lastJobHeader)
      }
      if (
        header.debtorId !== null &&
        debtors[header.debtorId] &&
        header.stormId !== null &&
        storms[header.stormId]
      ) {
        // jobDetails.header.jobSettings should not change once job has closed/invoiced
        if (
          header.cancelDate === null &&
          header.archiveDate === null &&
          header.quoteDate === null &&
          header.invoiceDate === null &&
          header.payDate === null
        ) {
          const {
            name,
            dentMatrixFactor,
            totalLossPercentage,
            minCategoryAdjustment,
            pricingMethod,
            categoryMethod,
            categorySettings,
            infectionAllowanceEnabled,
            gstRate,
          } = debtors[header.debtorId];
          header.jobSettings = {
            debtorName: name,
            pricingMethod,
            dentMatrixFactor,
            categoryMethod,
            categorySettings,
            totalLossPercentage,
            minCategoryAdjustment,
            infectionAllowanceEnabled,
            bookingDays: generalSettings.bookingDays,
            gstRate: gstRate,
          };
        } else {
          const {
            name: debtorNameNew,
            dentMatrixFactor: dentMatrixFactorNew,
            pricingMethod: pricingMethodNew,
            categoryMethod: categoryMethodNew,
            categorySettings: categorySettingsNew,
            totalLossPercentage: totalLossPercentageNew,
            minCategoryAdjustment: minCategoryAdjustmentNew,
            infectionAllowanceEnabled: infectionAllowanceEnabledNew,
            gstRate: gstRateNew,
          } = debtors[header.debtorId];
          const {
            debtorName = debtorNameNew,
            pricingMethod = pricingMethodNew,
            dentMatrixFactor = dentMatrixFactorNew,
            categoryMethod = categoryMethodNew,
            categorySettings = categorySettingsNew,
            totalLossPercentage = totalLossPercentageNew,
            minCategoryAdjustment = minCategoryAdjustmentNew,
            infectionAllowanceEnabled = infectionAllowanceEnabledNew,
            bookingDays: _bookingDays = generalSettings.bookingDays,
            gstRate = gstRateNew,
          } = header.jobSettings || {};
          const bookingDays = {
            ...generalSettings.bookingDays,
            ..._bookingDays,
          };
          header.jobSettings = {
            debtorName,
            pricingMethod,
            dentMatrixFactor,
            categoryMethod,
            categorySettings,
            totalLossPercentage,
            minCategoryAdjustment,
            infectionAllowanceEnabled,
            bookingDays,
            gstRate,
          };
        }
      } else {
        header.jobSettings = null;
      }
      let assessmentDateDate = null;
      let assessmentDateTime = null;
      if (header.assessmentDate !== null) {
        const tmp = moment(header.assessmentDate * 1000);
        assessmentDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
        assessmentDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 15;
      }
      let readyDateDate = null;
      let readyDateTime = null;
      if (header.readyDate !== null) {
        const tmp = moment(header.readyDate * 1000);
        readyDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
        readyDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 30;
      }
      let pickupDateDate = null;
      let pickupDateTime = null;
      if (header.pickupDate !== null) {
        const tmp = moment(header.pickupDate * 1000);
        pickupDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
        pickupDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 30;
      }
      let fitmentDateDate = null;
      let fitmentDateTime = null;
      if (header.fitmentDate !== null) {
        const tmp = moment(header.fitmentDate * 1000);
        fitmentDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
        fitmentDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 30;
      }
      const newHeader = {
        ...header,
        importUrl: header.importedFromArms ?
          `https://www.arms1.com.au/search?search-quote-number=${header.jobNumber}` :
          null,
        excessStr: numeral(header.excess / 100).format('0[.]00'),
        bodyPaint: header.bodyPaint || 'S1',
        insuredValueStr: numeral(header.insuredValue / 100).format('0[.]00'),
        assessmentDateDate,
        assessmentDateTime,
        currentStartDate: header.startDate,
        currentFinishDate: header.finishDate,
        readyDateDate,
        readyDateTime,
        pickupDateDate,
        pickupDateTime,
        fitmentDateDate,
        fitmentDateTime,
        isIn: header.inDate !== null,
        isOut: header.outDate !== null,
        isInvoiced: header.invoiceDate !== null,
        isPaid: header.paymentAuthoriseDate !== null || header.payDate !== null,
        isArchived: header.archiveDate !== null,
        authorised: header.authoriseDate !== null,
        hasOrm: header.ormId !== null,
        hasPrimaryLocation: header.primaryLocationId !== null,
        hasPainter: header.painter !== null,
      };
      const jobSettings = (newHeader.jobSettings || {});
      return {
        ...state,
        modified: false,
        header: newHeader,
        lineItems: PanelData.generateLineItems(
          undefined,
          jobSettings.debtorName,
          jobSettings.pricingMethod,
          newHeader.bodyShape,
          newHeader.bodyPaint,
        )
      };
    case 'JOB-DETAILS-HEADER-SET-ERRORS':
      if (state.notFound !== false) return state;
      return {
        ...state,
        header: {
          ...state.header,
          ...action.errors
        }
      };
    case 'JOB-DETAILS-HEADER-REFRESH-DATESTAMPS':
      const datestamps = [
        {name: 'Create', key: 'createDate'},
        {name: 'Cancel', key: 'cancelDate', alert: true},
        {name: 'In', key: 'inDate'},
        {name: 'Signed', key: 'agreementDate'},
        {name: 'Out', key: 'outDate'},
        {name: 'Quote', key: 'quoteDate'},
        {name: 'Decline', key: 'declineDate'},
        {name: 'C/S or T/L', key: 'csTlDate'},
        {name: 'Authorise', key: 'authoriseDate'},
        {name: 'Authority decline', key: 'authorityDeclineDate'},
        {name: 'Invoice', key: 'invoiceDate'},
        {name: 'Payment decline', key: 'paymentDeclineDate', alert: true},
        {name: 'Payment Authorise', key: 'paymentAuthoriseDate'},
        {name: 'Excess invoice', key: 'excessInvoiceDate'},
        {name: 'Pay', key: 'payDate'},
        {name: 'Decline repairs', key: 'declineDate'},
        {name: 'Archive', key: 'archiveDate', alert: true},
      ].reduce((acc, {name, key, alert}) => {
        if (state.header[key] !== null) {
          acc.push({
            name,
            value: _moment(state.header[key] * 1000).format('DD-MM-YYYY HH:mm'),
            alert,
          });
        }
        return acc;
      }, []);
      return {
        ...state,
        status: getJobStatus(state.header),
        datestamps,
      };
    case 'JOB-DETAILS-BLOCKED-DAYS-SET':
      return {
        ...state,
        blockedDays: action.blockedDays
      };
    case 'JOB-DETAILS-QUOTE-SET':
      {
        reset();
        const {pdr, quote, selectedLineItems} = massageQuote(action.lines);
        const animatedFields = animateQuote(
          quote,
          selectedLineItems,
          state.header,
          state.lineItems,
        );
        return {
          ...state,
          modified: false,
          pdr,
          ...animatedFields
        };
      }
    case 'JOB-DETAILS-HEADER-UPDATE':
      {
        let newHeader = {
          ...state.header,
          ...action.changes
        };
        window.localStorage.setItem('lastJobHeader', JSON.stringify(newHeader));
        if (newHeader.cashSettle === true) {
          newHeader.outOfScope = true;
        }
        if (state.header.invoiceDate !== null) {
          if (state.header.fitmentDate !== newHeader.fitmentDate) {
            newHeader.fitmentDateDate = null;
            newHeader.fitmentDateTime = null;
            if (newHeader.fitmentDate !== null) {
              const tmp = moment(newHeader.fitmentDate * 1000);
              newHeader.fitmentDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
              newHeader.fitmentDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 30;
            }
          }
          return {
            ...state,
            modified: true,
            header: {
              ...state.header,
              name: newHeader.name,
              nameError: newHeader.nameError,
              address: newHeader.address,
              addressError: newHeader.addressError,
              state: newHeader.state,
              stateError: newHeader.stateError,
              postcode: newHeader.postcode,
              postcodeError: newHeader.postcodeError,
              comments: newHeader.comments,
              commentsError: newHeader.commentsError,
              mobile: newHeader.mobile,
              mobileError: newHeader.mobileError,
              email: newHeader.email,
              emailError: newHeader.emailError,
              fitmentDate: newHeader.fitmentDate,
              fitmentDateDate: newHeader.fitmentDateDate,
              fitmentDateTime: newHeader.fitmentDateTime
            },
          };
        } else if (state.header.excessInvoiceDate !== null) {
          newHeader = {
            ...newHeader,
            excess: state.header.excess,
            excessStr: state.header.excessStr,
            excessError: state.header.excessStr,
          };
        }

        if (state.header.debtorId !== newHeader.debtorId || state.header.stormId !== newHeader.stormId) {
          if (
            state.header.cancelDate !== null ||
            state.header.archiveDate !== null ||
            state.header.quoteDate !== null ||
            state.header.invoiceDate !== null ||
            state.header.payDate !== null
          ) {
            return state;
          }
          if (action.debtors[newHeader.debtorId] && action.storms[newHeader.stormId]) {
            const {
              name: debtorName,
              dentMatrixFactor,
              totalLossPercentage,
              minCategoryAdjustment,
              infectionAllowanceEnabled,
              pricingMethod,
              categoryMethod,
              categorySettings,
              gstRate,
            } = action.debtors[newHeader.debtorId];
            newHeader.jobSettings = {
              debtorName,
              pricingMethod,
              dentMatrixFactor,
              categoryMethod,
              categorySettings,
              totalLossPercentage,
              minCategoryAdjustment,
              infectionAllowanceEnabled,
              bookingDays: action.generalSettings.bookingDays,
              gstRate: gstRate,
            };
          } else {
            newHeader.jobSettings = null;
          }
          newHeader.authorised = newHeader.authoriseDate !== null;
          const jobSettings = (newHeader.jobSettings || {});
          const lineItems = PanelData.generateLineItems(
            state.lineItems,
            jobSettings.debtorName,
            jobSettings.pricingMethod,
            newHeader.bodyShape,
            newHeader.bodyPaint,
          );
          const animatedFields = animateQuote(
            state.quote,
            state.selectedLineItems,
            newHeader,
            lineItems,
          );
          return {
            ...state,
            modified: true,
            //header: newHeader,
            lineItems,
            ...animatedFields,
          }
        } else if (
          state.header.outOfScope !== newHeader.outOfScope ||
          state.header.insuredValue !== newHeader.insuredValue
        ) {
          const animatedFields = animateQuote(
            state.quote,
            state.selectedLineItems,
            newHeader,
            state.lineItems,
          );
          return {
            ...state,
            modified: true,
            //header: newHeader,
            ...animatedFields
          }
        } else if (
          state.header.debtorId !== newHeader.debtorId ||
          state.header.bodyShape !== newHeader.bodyShape ||
          state.header.bodyPaint !== newHeader.bodyPaint
        ) {
          const jobSettings = (newHeader.jobSettings || {});
          const lineItems = PanelData.generateLineItems(
            state.lineItems,
            jobSettings.debtorName,
            jobSettings.pricingMethod,
            newHeader.bodyShape,
            newHeader.bodyPaint,
          );
          const animatedFields = animateQuote(
            state.quote,
            state.selectedLineItems,
            newHeader,
            lineItems,
          );
          return {
            ...state,
            modified: true,
            //header: newHeader,
            lineItems,
            ...animatedFields
          };
        } else {
          if (state.header.inDate !== newHeader.inDate) {
            newHeader.isIn = newHeader.inDate !== null;
          }
          if (state.header.outDate !== newHeader.outDate) {
            newHeader.isOut = newHeader.outDate !== null;
          }
          if (state.header.isIn !== newHeader.isIn && newHeader.isIn === false) {
            newHeader.isOut = false;
          }
          if (state.header.convIsOut !== newHeader.convIsOut && newHeader.convIsOut === false) {
            newHeader.convIsIn = false;
          }
          if (state.header.assessmentDate !== newHeader.assessmentDate) {
            newHeader.assessmentDateDate = null;
            newHeader.assessmentDateTime = null;
            if (newHeader.assessmentDate !== null) {
              const tmp = moment(newHeader.assessmentDate * 1000);
              newHeader.assessmentDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
              newHeader.assessmentDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 15;
            }
          }
          if (state.header.readyDate !== newHeader.readyDate) {
            newHeader.readyDateDate = null;
            newHeader.readyDateTime = null;
            if (newHeader.readyDate !== null) {
              const tmp = moment(newHeader.readyDate * 1000);
              newHeader.readyDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
              newHeader.readyDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 30;
            }
          }
          if (state.header.pickupDate !== newHeader.pickupDate) {
            newHeader.pickupDateDate = null;
            newHeader.pickupDateTime = null;
            if (newHeader.pickupDate !== null) {
              const tmp = moment(newHeader.pickupDate * 1000);
              newHeader.pickupDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
              newHeader.pickupDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 30;
            }
          }
          if (state.header.fitmentDate !== newHeader.fitmentDate) {
            newHeader.fitmentDateDate = null;
            newHeader.fitmentDateTime = null;
            if (newHeader.fitmentDate !== null) {
              const tmp = moment(newHeader.fitmentDate * 1000);
              newHeader.fitmentDateDate = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
              newHeader.fitmentDateTime = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 30;
            }
          }
          return {
            ...state,
            modified: true,
            header: newHeader,
          };
        }
      }
    case 'JOB-DETAILS-HEADER-SET-INVOICE-DATE':
      {
      const newHeader = {
        ...state.header,
        invoiceDate: action.invoiceDate,
        invoiceNumber: action.invoiceNumber,
        isInvoiced: action.invoiceDate !== null,
        isPaid: action.paymentAuthoriseDate !== null || action.payDate !== null,
      };
        return {
          ...state,
          header: newHeader,
        };
      }
    case 'JOB-DETAILS-HEADER-SET-ARCHIVE-DATE':
      {
      const newHeader = {
        ...state.header,
        archiveDate: action.archiveDate,
        isArchived: action.archiveDate !== null,
      };
        return {
          ...state,
          header: newHeader,
        };
      }
    case 'JOB-DETAILS-QUOTE-ADD-ROW':
      {
        if (state.header.invoiceDate !== null) return state;

        const {rowType, rate, position} = action;
        const array = state.quote[rowType].slice();
        array.splice(
          position,
          0,
          generateNewItem(state.header.bodyPaint, rowType, rate)
        );
        return {
          ...state,
          modified: true,
          quote: {
            ...state.quote,
            [rowType]: array
          }
        };
      }
    case 'JOB-DETAILS-QUOTE-REMOVE-ROW':
      {
        if (state.header.invoiceDate !== null) return state;

        const {rowType, i} = action;
        let panel = null;

        let newSelectedLineItems = null;
        const array = state.quote[rowType]
          .map((r, idx) => {
            if (idx === i) {
              panel = r.description;
              if (r.lineItemId) {
                newSelectedLineItems = newSelectedLineItems || {...state.selectedLineItems};
                newSelectedLineItems[r.lineItemId] = false;
              }
              return {
                ...r,
                deleted: true,
                ormStatus: 'DELETED',
              };
            }
            return r;
          });

        const newQuote = {
          ...state.quote,
          [rowType]: array
        };
        const animatedFields = animateQuote(
          newQuote,
          newSelectedLineItems || state.selectedLineItems,
          state.header,
          state.lineItems,
        );

        if (rowType === 'pdr') {
          let pdr = {
            ...state.pdr
          };
          if (panel !== null) {
            pdr[panel].qty = '';
            pdr[panel].size = '';
          }
          return {
            ...state,
            modified: true,
            quote: newQuote,
            pdr,
            ...animatedFields,
          };
        } else {
          //let selectedLineItems = {
          //  ...state.selectedLineItems,
          //};
          //if (lineItemId !== null) {
          //  selectedLineItems[lineItemId] = false;
          //}
          return {
            ...state,
            modified: true,
            //quote: newQuote,
            //selectedLineItems,
            ...animatedFields,
          };
        }
      }
    case 'JOB-DETAILS-QUOTE-PART-OUTCOME':
      {
        const {index, partOutcome} = action;
        const array = state.quote.part.map((row, idx) => {
          if (idx !== index) return row;
          return {
            ...row,
            partOutcome,
          };
        });
        const newQuote = {
          ...state.quote,
          part: array
        };
        return {
          ...state,
          quote: newQuote,
        };
      }
    case 'JOB-DETAILS-QUOTE-UPDATE':
      {
        const {rowType, i, changes} = action;
        let selectedLineItems = null;
        const array = state.quote[rowType].map((row, idx) => {
          if (idx !== i) return row;
          const ret = {...row};
          Object.keys(changes).forEach(field => {
            if (['hours', 'rate', 'qty', 'price'].indexOf(field) !== -1) {
              if (state.header.invoiceDate !== null) return;
              ret.ormStatus = ret.ormStatus !== 'ADDED' ? 'CHANGED' : ret.ormStatus;
              if (/^(|\d+(\.\d{0,2}){0,1})$/.test(changes[field]) === true) {
                ret[field] = changes[field];
              } else {
                if (
                  field === 'price' &&
                  (rowType === 'misc' || rowType === 'add-pdr') &&
                  /^(|-?\d*(\.\d{0,2}){0,1})$/.test(changes[field]) === true
                ) {
                  ret[field] = changes[field];
                }
              }
              if (['rr', 'repair', 'paint'].indexOf(rowType) !== -1) {
                ret.total = (!isNaN(ret.hours) && !isNaN(ret.rate)) ? (ret.hours * ret.rate) : null;
              } else if (['misc', 'sublet', 'add-pdr'].indexOf(rowType) !== -1) {
                ret.total = ret.price !== undefined ? Number(ret.price) : null;
              } else {
                ret.total = (!isNaN(ret.qty) && !isNaN(ret.price)) ? (ret.qty * ret.price) : null;
              }
              ret.totalStr = ret.total !== undefined && ret.total !== null ? ret.total.toFixed(2) : '';
            } else if (field === '_id') {
              if (state.header.invoiceDate !== null) return;
              ret.lineItemId = changes[field];
              if (selectedLineItems === null) {
                selectedLineItems = {...state.selectedLineItems};
              }
              selectedLineItems[changes[field]] = true;
              if (rowType === 'part') {
                ret.replacePanel = state.lineItems.part.sections[ret.lineItemId] || null;
              }
            } else {
              if (
                state.header.invoiceDate !== null &&
                (
                  field === 'categoryExtra' ||
                  field === 'onReport' ||
                  (
                    rowType === 'pdr' &&
                    field === 'p2p'
                  ) ||
                  (
                    rowType === 'paint' &&
                    ['blend', 'paintGroup'].indexOf(field) !== -1
                  ) ||
                  (
                    rowType === 'part' &&
                    field === 'replacePanel'
                  )
                )
              ) return;
              ret[field] = changes[field];
              if (rowType === 'pdr' && field === 'p2p') {
                ret.desc = ret.p2p ? `P2P ${ret.description}` : ret.description;
                ret.desc = ret.desc.replace(/-/g, ' ');
              } else if (rowType === 'part' && field === 'partEta') {
                ret.partEtaStr = ret.partEta ? moment(ret.partEta * 1000).format('DD-MM') : '';
              } else if (field !== 'ormStatus') {
                ret.ormStatus = (state.header.invoiceDate === null && ret.ormStatus !== 'ADDED')
                  ? 'CHANGED'
                  : ret.ormStatus;
              }
            }
          });
          return ret;
        });
        const newQuote = {
          ...state.quote,
          [rowType]: array
        };
        const animatedFields = animateQuote(
          newQuote,
          selectedLineItems || state.selectedLineItems,
          state.header,
          state.lineItems,
        );
        return {
          ...state,
          modified: true,
          ...animatedFields,
        };
      }
    case 'JOB-DETAILS-QUOTE-UPDATE-PDR':
      {
        if (state.header.invoiceDate !== null) return state;

        const {id, panel, qty: _qty, size: _size} = action;
        const qty = _qty && !isNaN(_qty) ? Number(_qty) : undefined;
        const size = _size && !isNaN(_size) ? Number(_size) : undefined;

        let {
          pdr: newPdr,
          quotePdr: newQuotePdr,
          found: pdrRowFound
        } = state.quote.pdr.reduce((acc, row) => {
          if (row.lineItemId !== id) {
            acc.quotePdr.push(row);
            return acc;
          }
          const ret = {...row};
          if (ret.deleted === true) {
            ret.qty = qty;
            ret.size = size;
            ret.deleted = false;
          } else if (_qty !== undefined) {
            ret.qty = qty;
          } else if (_size !== undefined) {
            ret.size = size;
          }
          acc.pdr[panel] = {
            ...acc.pdr[panel],
            qty: ret.qty,
            size: ret.size
          };
          acc.found = true;
          acc.quotePdr.push(ret);
          return acc;
        }, {pdr: {...state.pdr}, quotePdr: [], found: false});

        if (pdrRowFound !== true) {
          newPdr[panel] = {
            i: state.quote.pdr.length,
            qty,
            size
          };
          const newItem = generateNewItem(state.header.bodyPaint, 'pdr', undefined, id, {
            panel,
            qty,
            size
          });
          newQuotePdr = state.quote.pdr.slice();
          newQuotePdr.splice(newQuotePdr.length, 0, newItem);
        }

        const newQuote = {
          ...state.quote,
          pdr: newQuotePdr
        };

        const animatedFields = animateQuote(
          newQuote,
          state.selectedLineItems,
          state.header,
          state.lineItems,
        );
        return {
          ...state,
          modified: true,
          pdr: newPdr,
          ...animatedFields,
        }
      }
    case 'JOB-DETAILS-QUOTE-PANEL-AUTO-WORK':
      {
        if (state.header.invoiceDate !== null) return state;

        const {panel, work, rates} = action;
        const {quote, lineItems} = state;

        const selectedLineItems = {...state.selectedLineItems};

        const newQuoteParts = {};
        lineItems.sections[panel].forEach(l => {
          AUTO_INDEX[work].forEach(i => {
            if (l[i.idx] === true && l[i.can] === true) {

              if (!newQuoteParts.hasOwnProperty(i.type)) {
                newQuoteParts[i.type] = quote[i.type].slice();
              }

              const rowType = i.type;
              const id = l[i.id];

              const supersetExists = l.supersetIds.reduce((acc, superItem) => {
                if (superItem[i.id] && selectedLineItems[superItem[i.id]] === true) return true;
                return acc;
              }, false);
              if (supersetExists === false) {
                selectedLineItems[id] = true;
                let found = false;
                newQuoteParts[rowType] = newQuoteParts[rowType].map(i => {
                  if (i.lineItemId !== id) return i;
                  found = true;
                  return generateNewItem(state.header.bodyPaint, rowType, rates[rowType], id, l, i.id);
                });
                if (found !== true) {
                  const newItem = generateNewItem(state.header.bodyPaint, rowType, rates[rowType], id, l);
                  newQuoteParts[rowType].splice(newQuoteParts[rowType].length - 1, 0, newItem);
                }

                l.subsetIds.forEach(subItem => {
                  if (subItem[i.id]) {
                    selectedLineItems[subItem[i.id]] = false;
                    newQuoteParts[rowType] = newQuoteParts[rowType]
                      .map(row => {
                        if (row.lineItemId !== subItem[i.id]) return row;
                        return {
                          ...row,
                          deleted: true,
                        };
                      });
                  }
                });
              }
            }
          });
        });

        let newPdr = state.pdr;
        if (work === 'replace') {
          const array = state.quote.pdr
            .map(r => {
              if (r.description === panel) {
                return {
                  ...r,
                  deleted: true,
                  qty: null,
                  size: null,
                  total: null,
                  p2p: false,
                  categoryExtra: false,
                };
              }
              return r;
            });
          newQuoteParts.pdr = array;

          if (panel !== null) {
            newPdr = {
              ...state.pdr,
              [panel]: {
                ...state.pdr[panel],
                qty: '',
                size: ''
              }
            };
          }
        }

        const newQuote = {
          ...state.quote,
          ...newQuoteParts
        };
        const animatedFields = animateQuote(
          newQuote,
          selectedLineItems,
          state.header,
          state.lineItems,
        );
        return {
          ...state,
          modified: true,
          pdr: newPdr,
          ...animatedFields,
        };
      }
    case 'JOB-DETAILS-QUOTE-ITEM-TOGGLE':
      {
        if (state.header.invoiceDate !== null) return state;

        const {
          rowType,
          id,
          description,
          partNumber,
          hours,
          rate,
          qty,
          price,
          blend,
          replacePanel
        } = action;

        let array;
        const selectedLineItems = {
          ...state.selectedLineItems,
          [id]: true
        };

        if (!state.selectedLineItems.hasOwnProperty(id)) {
          array = state.quote[rowType].slice();
          const newItem = generateNewItem(state.header.bodyPaint, rowType, rate, id, {
            description,
            partNumber,
            rrTime: hours,
            repairTime: hours,
            paintTime: hours,
            qty,
            partPrice: price,
            miscPrice: price,
            subletPrice: price,
            isBlend: blend,
            replacePanel
          });
          array.splice(array.length - 1, 0, newItem);
        } else if (state.selectedLineItems[id] === false) {
          array = state.quote[rowType]
            .map(row => {
              if (row.lineItemId !== id) return row;
              return generateNewItem(state.header.bodyPaint, rowType, rate, id, {
                description,
                partNumber,
                rrTime: hours,
                repairTime: hours,
                paintTime: hours,
                qty,
                partPrice: price,
                miscPrice: price,
                subletPrice: price,
                isBlend: blend,
                replacePanel
              }, row.id);
            });
        } else {
          selectedLineItems[id] = false;
          array = state.quote[rowType]
            .map(row => {
              if (row.lineItemId !== id) return row;
              return {
                ...row,
                deleted: true,
              };
            });
        }

        const newQuote = {
          ...state.quote,
          [rowType]: array
        };
        const animatedFields = animateQuote(
          newQuote,
          selectedLineItems,
          state.header,
          state.lineItems,
        );
        return {
          ...state,
          modified: true,
          ...animatedFields,
        };
      }
    case 'JOB-DETAILS-SET-VEHICLE-INFO':
      {
        const {parts: _parts} = action;
        const {parts, sections} = _parts
          .reduce((acc, {partNumber, description, price, section}) => {
            const part = {partNumber, description, price};
            if (!acc.sectionsIndex.hasOwnProperty(section)) {
              acc.sectionsIndex[section] = acc.sections.length;
              acc.sections.push({
                name: section,
                parts: []
              });
            }
            acc.parts.push(part);
            acc.sections[acc.sectionsIndex[section]].parts.push({
              ...part,
              partId: `part-${part.description}`,
              priceStr: numeral(part.price).format('$0,0.00')
            });
            return acc;
          }, {
            parts: [],
            sectionsIndex: {},
            sections: []
          });
        return {
          ...state,
          vehicleInfo: {
            parts,
            sections
          }
        };
      }
    default:
      return state;
  }
};
