
export let ABILITY_ROWS = [
  [
    {
      key: 'canAddJob',
      text: 'Add job',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    }, //
    {
      key: 'canAddMiscInvoice',
      text: 'Add misc invoice',
      templates: ['god', ]
    }, //
    {
      key: 'canAddTask',
      text: 'Add task',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    }, //
    {
      key: 'canViewAssessmentsInbox',
      text: 'View assessments inbox',
      templates: ['reception', ]
    }, //
    {
      key: 'canSearch',
      text: 'Search',
      templates: ['god', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    }, //
    {
      key: 'canViewCustomerPortal',
      text: 'Customer portal',
      templates: ['god', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    }, //
    {
      key: 'canViewClaimPortal',
      text: 'Claim portal',
      templates: ['god', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    }, //
    {
      key: 'canViewOrmPortal',
      text: 'ORM portal',
      templates: ['god', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    }, //
  ],
  [
    {
      key: 'canJobDetailsBookingOverride',
      text: 'Job details - booking override',
      templates: ['god', ]
    },
    {
      key: 'canJobDetailsView',
      text: 'Job details - view',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewCustomer',
      text: 'Job details - view customer',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewCar',
      text: 'Job details - view car',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewDates',
      text: 'Job details - view dates',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewDatestamps',
      text: 'Job details - view datestamps',
      templates: ['god', 'techRepairerAndEstimator', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewStatuses',
      text: 'Job details - view statuses',
      templates: ['god', 'techRepairerAndEstimator', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewCategory',
      text: 'Job details - view category',
      templates: ['techRepairerOnly', 'techRepairerAndEstimator' ]
    },
    {
      key: 'canJobDetailsViewSummary',
      text: 'Job details - view summary',
      templates: ['god', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewQuote',
      text: 'Job details - view quote',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewQuotePrices',
      text: 'Job details - view quote prices',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewQuoteAdjPdr',
      text: 'Job details - view adj pdr',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsViewQuoteFitParts',
      text: 'Job details - view quote fit parts',
      templates: ['rr', 'qa', ]
    },
    {
      key: 'canJobDetailsSave',
      text: 'Job details - save',
      templates: ['god', 'techRepairerAndEstimator', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDetailsSavePostStart',
      text: 'Job details - save post start',
      templates: ['god', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
  ],
  [
    {
      key: 'canJobAddImages',
      text: 'Job add images',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobArchive',
      text: 'Job archive',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobExcessInvoice',
      text: 'Job excess invoice',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobInvoice',
      text: 'Job invoice',
      templates: ['god', 'reception', ]
    },
    {
      key: 'canJobCancelInvoice',
      text: 'Job cancel invoice',
      templates: ['god', ]
    },
    {
      key: 'canJobLocations',
      text: 'Job locations',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobLogs',
      text: 'Job logs',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', ]
    },
    {
      key: 'canJobNotes',
      text: 'Job notes',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobDocuments',
      text: 'Job documents',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobSurvey',
      text: 'Job Survey',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobOrm',
      text: 'Job ORM',
      templates: ['god', 'techRepairerAndEstimator', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobReallocate',
      text: 'Job Reallocate',
      templates: ['god']
    },
    {
      key: 'canJobPayments',
      text: 'Job payments',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobQa',
      text: 'Job QA',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobPurchasing',
      text: 'Job purchasing',
      templates: ['god', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canJobSms',
      text: 'Job sms',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
  ],
  [
    {
      key: 'canAdminView',
      text: 'Admin - view',
      templates: ['god', ]
    },
    {
      key: 'canAdminCreditorInvoices',
      text: 'Admin - creditor invoices',
      templates: ['god', ]
    },
    {
      key: 'canAdminCreditorPayments',
      text: 'Admin - creditor payments',
      templates: ['god', ]
    },
    {
      key: 'canAdminCredits',
      text: 'Admin - credits',
      templates: ['god', ]
    },
    {
      key: 'canAdminDebtorPayments',
      text: 'Admin - debtor payments',
      templates: ['god', ]
    },
    {
      key: 'canAdminExcessPayments',
      text: 'Admin - excess payments',
      templates: ['god', ]
    },
    {
      key: 'canAdminMiscInvoices',
      text: 'Admin - misc invoices',
      templates: ['god', ]
    },
    {
      key: 'canAdminReports',
      text: 'Admin - reports',
      templates: ['god', ]
    },
    {
      key: 'canAdminStatus',
      text: 'Admin - view status',
      templates: ['god', ]
    },
    {
      key: 'canAdminSettings',
      text: 'Admin - settings',
      templates: ['god', ]
    },
    {
      key: 'canAdminTechnicianCommissions',
      text: 'Admin - tech commissions',
      templates: ['god', ]
    },
  ],
  [
    {
      key: 'canViewBookingSchedule',
      text: 'View booking schedule',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canViewConventional',
      text: 'View conventional',
      templates: ['god', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canViewDashboard',
      text: 'View dashboard',
      templates: ['god', 'siteManager', ]
    },
    {
      key: 'canViewOutList',
      text: 'View out list',
      templates: ['god', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canViewProduction',
      text: 'View production',
      templates: ['god', 'qa', 'partsManager', 'siteManager', ]
    },
    {
      key: 'canViewTasks',
      text: 'View tasks',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', 'qa', 'reception', 'partsManager', 'conventionalManager', 'siteManager', ]
    },
    {
      key: 'canViewTechInvoicing',
      text: 'View tech invoicing',
      templates: ['god', 'techRepairerOnly', 'techRepairerAndEstimator', 'rr', ]
    },
    {
      key: 'canViewTracking',
      text: 'View tracking',
      templates: ['god', 'siteManager', ]
    },
    {
      key: 'canViewOutstandingInvoices',
      text: 'View outstanding invoices',
      templates: ['god', ]
    },
  ],
  [
    {
      key: 'canViewDashboardMap',
      text: 'View repair map',
      templates: ['god', ]
    },
  ],
];

export let TEMPLATES = [
  {id: 'conventionalManager', text: 'Conventional manager'},
  {id: 'god', text: 'God'},
  {id: 'partsManager', text: 'Parts manager'},
  {id: 'qa', text: 'QA'},
  {id: 'reception', text: 'Reception'},
  {id: 'techRepairerOnly', text: 'PDR tech - Repair'},
  {id: 'techRepairerAndEstimator', text: 'PDR tech - Repair & quote'},
  {id: 'rr', text: 'R&R'},
  {id: 'siteManager', text: 'Site manager'},
];

//export let TEMPLATES = Object
//  .keys(
//    ABILITY_ROWS.reduce((acc, r) => (
//      r.reduce((acc, a) => (
//        (a.templates || []).reduce((acc, t) => {
//          acc[t] = true;
//          return acc;
//        }, acc)
//      ), acc)
//    ), {})
//  )
//  .map(k => TEMPLATE_NAMES[k])
//  .sort();

//console.log(TEMPLATES);
//TEMPLATES.sort();
