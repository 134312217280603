import React from 'react';
import PropTypes from 'prop-types';

const DollarIn = props => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...otherProps}
    >
      <line x1='16' y1='4' x2='16' y2='20' />
      <path d='
        M 21 6
        h -7.5
        a 2.5 2.5 0 0 0 0 6
        h 5
        a 2.5 2.5 0 0 1 0 6
        h -7.5
      ' />
      <polyline points='5 9 7 12 5 15' />
      <line x1='1' y1='12' x2='7' y2='12' />
    </svg>
  );
};


DollarIn.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DollarIn.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default DollarIn;
