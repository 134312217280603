import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import Header from 'components/internal/Header';
import Sidebar from 'components/internal/Sidebar';

import AddMiscInvoice from 'components/internal/AddMiscInvoice';
import AddTask from 'components/internal/AddTask';
import Admin from 'components/internal/Admin';
import BookingSchedule from 'components/internal/BookingSchedule';
import Conventional from 'components/internal/Conventional';
import Dashboard from 'components/internal/Dashboard';
import Job from 'components/internal/Job';
import OutstandingInvoices from 'components/internal/OutstandingInvoices';
import Production from 'components/internal/Production';
import TechnicianInvoicing from 'components/internal/TechnicianInvoicing';
import Tasks from 'components/internal/Tasks';
import Tracking from 'components/internal/Tracking';

import colors from 'colors';


const Body = styled.div`
  height: calc(100vh - 64px);
  color: ${colors.body};

  @media print {
    height: inherit;
    ${opts => opts.modalOpen && `
      display: none !important;
    `}
  }
`;


export default class AppInternal extends React.Component {
  constructor(opts) {
    super(opts);
    this.timer = null;
  }
  componentDidMount() {
    const {visible, onLoadParams, version, paramsLoaded, abilities} = this.props;
    if (visible === true) {
      onLoadParams(version);
      if (
        this.timer === null &&
        paramsLoaded === true &&
        abilities.canViewAssessmentsInbox === true
      ) {
        this.refreshAssessmentInbox();
      }
    }
  }
  componentWillUnmount() {
    if (this.timer !== null && this.timer !== 'running') {
      clearTimeout(this.timer);
    }
  }
  componentDidUpdate(prevProps) {
    const {activeProfile, paramsLoaded, visible, abilities} = this.props;
    const {activeProfile: prevActiveProfile} = prevProps;
    if (prevActiveProfile !== activeProfile) {
      window.location.reload();
    }
    if (
      this.timer === null &&
      visible === true &&
      paramsLoaded === true &&
      abilities.canViewAssessmentsInbox === true
    ) {
      this.refreshAssessmentInbox();
    }
  }
  async refreshAssessmentInbox() {
    this.timer = 'running';
    try {
      await this.props.onLoadAssessmentInbox();
    } catch (e) {
    } finally {
      this.timer = setTimeout(this.refreshAssessmentInbox.bind(this), 5 * 60 * 1000);
    }

  }
  render() {
    const {modalOpen, visible, paramsLoaded, userId, employeeIndex, abilities} = this.props;
    if (visible !== true || paramsLoaded !== true) return null;
    const profile = employeeIndex[userId];
    if (!profile) return null;
    return (
      <React.Fragment>
        <AddMiscInvoice />
        <AddTask />
        <Route path='/:active?' component={Header} />
        <Body className='d-flex flex-row' modalOpen={modalOpen}>
          <Route path='/:activePage?/:activeSubPage?' component={Sidebar} />
          {abilities.canViewDashboard === true && (
            <Switch>
              <Redirect from='/' to='/dashboard' exact={true} />
              <Route path='/dashboard' exact={true} component={Dashboard} />
            </Switch>
          )}
          {abilities.canViewTasks === true && (
            <Switch>
              {abilities.canViewDashboard !== true && <Redirect from='/' to='/tasks' exact={true} />}
              <Route path='/tasks' exact={true} component={Tasks} />
            </Switch>
          )}
          {abilities.canViewOutstandingInvoices === true && (
            <Route path='/outstanding-invoices' exact={true} component={OutstandingInvoices} />
          )}
          {abilities.canViewTechInvoicing === true && (
            <Route path='/technician-invoicing' exact={true} component={TechnicianInvoicing} />
          )}
          <Switch>
            <Redirect from='/job/:jobId' to='/job/:jobId/details' exact={true} />
            <Route path='/job/:jobId' component={Job} />
          </Switch>
          <Route path='/tracking' exact={true} component={Tracking} />
          <Switch>
            <Redirect from='/production' to='/production/pipeline' exact={true} />
            <Route path='/production' component={Production} />
          </Switch>
          <Route path='/booking-schedule/:dir' exact={true} component={BookingSchedule} />
          <Route path='/conventional' exact={true} component={Conventional} />
          <Route path='/admin' component={Admin} />
        </Body>
      </React.Fragment>
    );
  }
};


