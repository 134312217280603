import numeral from 'numeral';

let currentState = null;
const cs = window.localStorage.getItem('admin-reports-storm-activity:stormId');
if (cs) {
  currentState = JSON.parse(cs);
}

const REPORT = [
  {num: 'numAllocated', title: '# Allocated'},
  {num: 'numCancelled', title: '# Cancelled'},
  {num: 'numTotalLoss', title: '# Total loss'},
  {num: 'numCashSettle', title: '# Cash settled'},
  {num: 'numContacted', title: '# Contacted'},
  {num: 'numAssessmentBooked', title: '# Assessments booked'},
  {num: 'numAssessNoShow', title: '# Assess no shows'},
  {num: 'numAssessed', title: '# Assessed'},
  {num: 'numRepairNoShow', title: '# Repair no shows'},
  {type: 'break'},
  {num: 'numBooked', title: '# Booked', pctOf: 'numAllocated'},
  {type: 'hr'},
  {num: 'numBooked1', title: '# Booked (light pdr)'},
  {num: 'numBooked2', title: '# Booked (light combo)'},
  {num: 'numBooked3', title: '# Booked (heavy pdr)'},
  {num: 'numBooked4', title: '# Booked (heavy combo)'},
  {num: 'numBooked5', title: '# Booked (light combo + turret)'},
  {num: 'numBooked6', title: '# Booked (heavy combo + turret)'},
  {num: 'numBooked0', title: '# Booked (oos)'},
  {type: 'break'},
  {num: 'numRepairStarted', title: '# Repair started', pctOf: 'numBooked'},
  {type: 'hr'},
  {num: 'numRepairStarted1', title: '# Repair started (light pdr)'},
  {num: 'numRepairStarted2', title: '# Repair started (light combo)'},
  {num: 'numRepairStarted3', title: '# Repair started (heavy pdr)'},
  {num: 'numRepairStarted4', title: '# Repair started (heavy combo)'},
  {num: 'numRepairStarted5', title: '# Repair started (light combo + turret)'},
  {num: 'numRepairStarted6', title: '# Repair started (heavy combo + turret)'},
  {num: 'numRepairStarted0', title: '# Repair started (oos)'},
  {type: 'break'},
  {num: 'numRepairFinished', title: '# Repair finished', pctOf: 'numBooked'},
  {type: 'hr'},
  {num: 'numRepairFinished1', title: '# Repair finished (light pdr)'},
  {num: 'numRepairFinished2', title: '# Repair finished (light combo)'},
  {num: 'numRepairFinished3', title: '# Repair finished (heavy pdr)'},
  {num: 'numRepairFinished4', title: '# Repair finished (heavy combo)'},
  {num: 'numRepairFinished5', title: '# Repair finished (light combo + turret)'},
  {num: 'numRepairFinished6', title: '# Repair finished (heavy combo + turret)'},
  {num: 'numRepairFinished0', title: '# Repair finished (oos)'},
  {type: 'break'},
  {
    num: 'numWaitingAuthority',
    amount: 'amountWaitingForAuthority',
    title: '# Waiting for authority',
  },
  {type: 'hr'},
  {num: 'numWaitingAuthority1', title: '# Waiting for authority (light pdr)'},
  {num: 'numWaitingAuthority2', title: '# Waiting for authority (light combo)'},
  {num: 'numWaitingAuthority3', title: '# Waiting for authority (heavy pdr)'},
  {num: 'numWaitingAuthority4', title: '# Waiting for authority (heavy combo)'},
  {num: 'numWaitingAuthority5', title: '# Waiting for authority (light combo + turret)'},
  {num: 'numWaitingAuthority6', title: '# Waiting for authority (heavy combo + turret)'},
  {num: 'numWaitingAuthority0', title: '# Waiting for authority (oos)'},
  {type: 'break'},
  {num: 'numInvoiced', title: '# Invoiced', pctOf: 'numBooked'},
  {type: 'hr'},
  {num: 'numInvoiced1', title: '# Invoiced (light pdr)'},
  {num: 'numInvoiced2', title: '# Invoiced (light combo)'},
  {num: 'numInvoiced3', title: '# Invoiced (heavy pdr)'},
  {num: 'numInvoiced4', title: '# Invoiced (heavy combo)'},
  {num: 'numInvoiced5', title: '# Invoiced (light combo + turret)'},
  {num: 'numInvoiced6', title: '# Invoiced (heavy combo + turret)'},
  {num: 'numInvoiced0', title: '# Invoiced (oos)'},
];

export default (state = {notFound: null, stormId: currentState, report: null}, action) => {
  switch (action.type) {
    case 'ADMIN-REPORTS-STORM-ACTIVITY-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        report: null
      };
    case 'ADMIN-REPORTS-STORM-ACTIVITY-SET-NULL':
      return {
        ...state,
        notFound: null,
        report: null,
      };
    case 'ADMIN-REPORTS-STORM-ACTIVITY-CHANGE-STORM-ID':
      const {stormId} = action;
      window.localStorage.setItem('admin-reports-storm-activity:stormId', JSON.stringify(stormId));
      return {
        ...state,
        notFound: null,
        stormId,
        report: null,
      };
    case 'ADMIN-REPORTS-STORM-ACTIVITY-SET':
      {
        return {
          ...state,
          notFound: false,
          report: REPORT.map(r => ({
            ...r,
            pct: r.pctOf ? action[r.num] / action[r.pctOf] * 100: undefined,
            amount: r.amount ? numeral(action[r.amount / 100]).format('$0,0') : undefined,
            count: r.num ? numeral(action[r.num]).format('0,0') : undefined,
          }))
        };
      }
    default:
      return state;
  }
};
