import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import Wrench from 'icons/wrench.jsx';
import colors from 'colors';

import STAGE_MAP from 'utils/stage-map.js';


const Card = styled.div`
  border-radius: 0.5rem;
  background: ${opts => opts.color};
  :last-of-type {
    margin-bottom: 0px !important;
  }
`;
//const CardHandle = styled.div`
//  border-radius: 0.5rem 0rem 0rem 0.5rem;
//  background: ${opts => opts.color};
//  width: 4px;
//  min-height: 100%;
//`;
const StageContainer = styled.div`
  cursor: pointer;
  color: ${colors.grey4};
`;
const StageThumbnail = styled.div`
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  background: ${opts => opts.bg};
  color: #fff;

  svg {
    stroke: #fff;
  }
`;
const JobImg = styled.img`
  border-radius: 0.5rem;
  max-height: 48px;
  width: auto;
`;

class Stage extends React.Component {
  constructor(opts) {
    super(opts);
    this.ref = null;
    this.onClick = this.onClick.bind(this);
    this.onDone = this.onDone.bind(this);
  }
  onClick() {
    if (this.ref !== null && this.props.doneDate === null) {
      const {jobId, stage, employee, onToggle} = this.props;
      onToggle(jobId, stage, employee, this.ref);
    }
  }
  onDone(e) {
    const {jobId, stage, employee, onDone} = this.props;
    if (employee !== null) {
      onDone(jobId, stage, e.target.checked);
    }
  }
  render() {
    const {arrived, stage, employee, employees, doneDate} = this.props;
    return (
      <StageContainer
        ref={ref => this.ref = ref}
        className='d-flex flex-column justify-content-between align-items-center p-1'
      >
        <StageThumbnail
          className='rounded-circle'
          onClick={this.onClick}
          bg={
            employee === null ?
              colors.grey4 :
              (doneDate === null ? employees[employee].avatarBg : employees[employee].avatarBgLight)
          }
        >
          {employee === null ?
            <Icons.User width={16} height={16} /> :
            <span title={employees[employee].name}>{employees[employee].initials}</span>
          }
        </StageThumbnail>
        <div
          className='py-1'
          onClick={this.onClick}
        >{stage}</div>
        <input
          type='checkbox'
          disabled={arrived !== true || employee === null}
          checked={employee !== null && doneDate !== null}
          onChange={this.onDone}
        />
      </StageContainer>
    );
  }
};


export default class Job extends React.Component {
  render() {
    const {job, filteredEmployee, employeeIndex, onStageToggle, onStageDone} = this.props;
    if (filteredEmployee !== null) {
      let toFilter = true;
      for (let i in STAGE_MAP) {
        const s = STAGE_MAP[i];
        if (
          (
            filteredEmployee === 'repairers' &&
            ['strip', 'repair', 'fitup'].indexOf(i) !== -1 &&
            job[s.employee] !== null &&
            job[s.date] === null
          ) ||
          (
            filteredEmployee === 'painters' &&
            i === 'paint' &&
            job[s.employee] !== null &&
            job[s.date] === null
          ) ||
          (
            job[s.employee] === filteredEmployee &&
            job[s.date] === null
          )
        ) {
          toFilter = false;
          break;
        }
      }
      if (toFilter === true) return null;
    }
    const arrived = job.inDate !== null;
    return (
      <Card
        className='d-flex flex-column justify-content-between p-2 my-2'
        color={colors[job.alertColor || job.flag]}
      >
        <div className='d-flex flex-row mb-2'>
          <div className='d-flex flex-column justify-content-between flex-grow-1'>
            <div className='d-flex flex-row justify-content-between'>
              <div>
                <Link
                  to={`/job/${job.id}`}
                  className='text-body font-weight-bold'
                >{job.jobNumber}</Link>
              </div>
              <div className='text-center'>{job.rego}</div>
              <div className='text-right'>{job.startDateStr} | {job.finishDateStr}</div>
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className='flex-grow-1 text-truncate text-uppercase'>{job.makeModel}</div>
              {job.isTurret === true && (
                <Wrench width={14} height={14} className='ml-1' />
              )}
              {job.isPaint === true && (
                <Icons.Droplet width={14} height={14} className='ml-1' />
              )}
              {job.hasSublet === true && (
                <Icons.LogOut width={14} height={14} className='ml-1' />
              )}
              <div className='ml-1'>{job.category}</div>
            </div>
          </div>
          {job.image64 && (
            <JobImg src={job.image64} className='ml-2' />
          )}
        </div>
        <div className='d-flex flex-row justify-content-between'>
          {
            Object.keys(STAGE_MAP).map((s, i) => {
              const stage = STAGE_MAP[s];
              return (
                <Stage
                  key={i}
                  jobId={job.id}
                  stage={s}
                  employee={job[stage.employee]}
                  employees={employeeIndex}
                  arrived={arrived}
                  doneDate={job[stage.date]}
                  onToggle={onStageToggle}
                  onDone={onStageDone}
                />
              )
            })
          }
        </div>
      </Card>
    );
  }
};

