module.exports = {
  lineItems: [
    {
      description: 'TOW IN (WITHIN 15KM)',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 80,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TOW IN (ADDITIONAL PER KM CHARGE)',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 0,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'PRE SCAN',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 0,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'WINDSCREEN MOUNTED CAMERA CALIBRATION',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 280,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'FRONT BAR MOUNTED RADAR CALIBRATION',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 280,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'SIDE MIRROR BLIND SPOT CAMERA CALIBRATION',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 280,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'DYNAMIC ROAD TEST',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 80,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'CALIBRATION REPORT',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 0,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'CALIBRATION CERTIFICATE',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 0,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'POST SCAN IDC5',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 0,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TOW OUT (WITHIN 15 KM)',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 80,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TOW OUT (ADDITIONAL PER KM CHARGE)',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 5,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BLIND SPOT RADAR CALIBRATION – RHS ONLY',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 280,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BLIND SPOT RADAR CALIBRATION – LHS ONLY',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 280,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BLIND SPOT RADAR CALIBRATION – BOTH SIDES',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 400,
              }
            }
          },
          },
        },
      }
    },
    {
      description: '360° BIRDS EYE CAMERA SYSTEM CALIBRATION – SINGLE CAMERA',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 280,
              }
            }
          },
          },
        },
      }
    },
    {
      description: '360° BIRDS EYE CAMERA SYSTEM CALIBRATION – ALL 4 CAMERAS',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 400,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'CODING NEW RADAR MODULE (INCL OEM ACCESS FEES/TOKENS)',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 250,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'FRONT BAR MOUNTED RADAR AND WINDSCREEN MOUNTED CAMERA CALIBRATION',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 400,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'PARKING SONAR SENSOR INITIALISATION',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 120,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'PARKING SONAR SENSOR CALIBRATION',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 180,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'HEADLIGHT INITIALISATION',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 120,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'AIR CONDITIONING REGAS (R134A)',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 120,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'AIR CONDITIONING REGAS (R1234YF)',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 700,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'CHILD SEAT INSTALLATION',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 68.18,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'GATED BUCKLE',
      settings: {
        'ADAS': {
          '[^]*': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 10,
              }
            }
          },
          },
        },
      }
    },
  ]
};

