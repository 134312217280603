import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Smss from './component.jsx';


const load = (API, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/smss`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-SMSS-SET-NOT-FOUND'
          });
          dispatch({
            type: 'JOB-GALLERY-SET',
            images: []
          });
          return null;
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        dispatch({
          type: 'JOB-SMSS-SET',
          smss: resp.smss
        });
        dispatch({
          type: 'JOB-SMSS-SET-FOUND',
          jobId,
          jobNumber: resp.jobNumber,
          flagged: resp.flagged,
          vpp: resp.vpp,
          vip: resp.vip,
          importedFromArms: resp.importedFromArms,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-SMSS-SET-NULL'
});

const sendSMS = (API, jobId, smsUuid, sms) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/sms`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({smsUuid, sms})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'JOB-SMSS-SET',
          smss: res.smss
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {jobId} = props;
  let jobValid = null;
  if (state.jobSmss.notFound === true)
    jobValid = false;
  else if (state.jobSmss.notFound === false) {
    jobValid = true;
  }
  if (jobValid !== true) {
    return {
      jobValid,
    };
  }
  const {
    jobSmss: {jobNumber, flagged, vpp, vip, importUrl, smss},
    params: {
      employees: {index: employeesIndex},
      smsTemplates: {index: smsTemplatesIndex, list: smsTemplatesList}
    }
  } = state;
  return {
    jobValid,
    jobId,
    jobNumber,
    flagged,
    vpp,
    vip,
    importUrl,
    smss,
    employeesIndex,
    smsTemplatesIndex,
    smsTemplatesList,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: jobId => dispatch(load(props.API, jobId)),
  onUnload: () => dispatch(unload()),
  onSendSMS: (jobId, smsUuid, sms) => dispatch(sendSMS(props.API, jobId, smsUuid, sms))
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Smss)
);
