import React from 'react';
import PropTypes from 'prop-types';

const Receipt = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...otherProps}
    >
      <path d='
        M 14 2
        h -8
        a 2 2 0 0 0 -2 2
        v 17
        a 1 1 0 0 0 1 1
        l 2 -2
        a 1 1 0 0 1 1 0
        l 1 1
        a 1 1 0 0 0 1 1
        l 2 -2
        a 1 1 0 0 1 1 0
        l 1 1
        a 1 1 0 0 0 1 1
        l 2 -2
        a 1 1 0 0 1 1 0
        l 1 1
        a 1 1 0 0 0 1 1
        v -14
        z
      ' />
      <polyline points='14 2 14 8 20 8' />
      <line x1='10' y1='8' x2='8' y2='8' />
      <line x1='16' y1='12' x2='8' y2='12' />
      <line x1='16' y1='16' x2='8' y2='16' />
    </svg>
  );
};

Receipt.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Receipt.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Receipt;
