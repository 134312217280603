import React from 'react';
import PropTypes from 'prop-types';

import colors from 'colors';

const Invoice = props => {
  const {size, ...otherProps } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={colors.grey3}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...otherProps}
    >
      <path d='M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z'/><path d='M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8'/>
      <path d='M12 17.5v-11'/>
    </svg>
  );
};

Invoice.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Invoice.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Invoice;
