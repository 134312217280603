import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {withApi} from 'components/API.jsx';

import env from 'env';
import AppSurvey from './component.jsx';


const load = (API, token) => {
  return async dispatch => {
    dispatch({type: 'LOADING-INC'});
    try {
      const resp = await API.request(
        `${env.API_SERVER}/v1/survey`,
        {
          method: 'GET'
        },
        {
          'content-type': 'application/json',
          'API-TOKEN': token,
        }
      );
      if (resp && resp.error === true) {
        dispatch({
          type: 'SURVEY-SET-NOT-FOUND'
        });
        return false;
      }
      dispatch({
        type: 'SURVEY-SET',
        data: resp
      });
      return null;
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({type: 'LOADING-DEC'});
    }
  };
};

const submit = (API, token, payload) => {
  return async dispatch => {
    dispatch({type: 'LOADING-INC'});
    try {
      const resp = await API.request(
        `${env.API_SERVER}/v1/survey`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        {
          'content-type': 'application/json',
          'API-TOKEN': token,
        }
      );
      if (resp && resp.error === true) {
        dispatch({
          type: 'SURVEY-SUBMIT-FAILED'
        });
        return false;
      }
      dispatch({
        type: 'SURVEY-SAVED',
        data: resp
      });
      return true;
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({type: 'LOADING-DEC'});
    }
  };
};

const unload = () => ({
  type: 'SURVEY-RESET',
});

const mapStateToProps = (state, props) => {
  return state.survey;
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onSubmit: (token, payload) => dispatch(submit(API, token, payload)),
  onUnload: () => dispatch(unload()),
  onLoad: token => dispatch(load(API, token)),
});

export default withRouter(withApi(connect(mapStateToProps, mapDispatchToProps)(AppSurvey)));
