import React from 'react';
import {toast} from 'react-toastify';


export default class Process extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      canProcess: false,
    };
    this.onSave = this.onSave.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.lines !== prevProps.lines) {
      const {lines, canProcess} = Object.values(this.props.lines).reduce((acc, l) => {
        if (l) {
          acc.lines.push({id: l.id});
          if (l.canProcess === false) {
            acc.canProcess = false;
          }
        }
        return acc;
      }, {lines: [], canProcess: true});
      if (lines.length) {
        this.setState({
          ...this.state,
          lines,
          canProcess
        });
      } else {
        this.setState({
          ...this.state,
          lines,
          canProcess: false
        });
      }
    }
  }

  onSave() {
    const {jobId, onSave} = this.props;
    const {lines} = this.state;
    onSave(jobId, {action: 'process', lines})
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while marking credits processed.');
        } else {
          toast.success('Credits successfully processed.');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered updating credits');
      });
  }

  render () {
    console.log('process:render');
    const {canProcess} = this.state;
    return (
      <button
        type='button'
        className='btn btn-warning ml-2'
        onClick={this.onSave}
        disabled={canProcess !== true}
      >Process</button>
    );
  }
};

