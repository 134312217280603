import {connect} from 'react-redux';

import Modal from './component.jsx';


const toggle = open => ({
  type: 'MODAL-SET',
  open,
});


const mapDispatchToProps = dispatch => ({
  onToggle: open => dispatch(toggle(open))
});

export default connect(null, mapDispatchToProps)(Modal);
