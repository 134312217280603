import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';

import CalendarInput from 'components/CalendarInput.jsx';
import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  overflow-x: auto;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Table = styled.table`
  margin-bottom: 0;
  width: 2000px;
`;
const Th = styled.th`
  padding: 0 !important;
`;
const Td = styled.td`
  padding: 0 !important;
`;

export default class ReportsInvoice extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      locationId: null,
      start: null,
      finish: null,
      canSearch: false,
    };
    this.onChangeLocation = this.onChange.bind(this, 'locationId');
    this.onChangeStart = this.onChange.bind(this, 'start');
    this.onChangeFinish = this.onChange.bind(this, 'finish');
    this.onSearch = this.onSearch.bind(this);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onChange(field, e) {
    const newState = {
      ...this.state,
      [field]: e,
    };
    this.setState({
      ...newState,
      canSearch: (
        newState.locationId !== null &&
        newState.start !== null &&
        newState.finish !== null
      ),
    });
  }
  onSearch() {
    const {onLoad} = this.props;
    const {locationId, start, finish} = this.state;
    onLoad(locationId, start, finish);
  }
  render() {
    console.log('invoice:render');
    const {
      notFound,
      locations,
      report,
    } = this.props;
    const {
      locationId,
      start,
      finish,
      canSearch,
    } = this.state;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load report.</div>
        </ErrorContainer>
      );
    }
    return (
      <React.Fragment>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label py-0'>Location</label>
          <div className='col-sm-4'>
            <Select
              background={colors.grey1}
              placeholder='select a location'
              selected={locationId}
              options={locations}
              textField='name'
              onChange={this.onChangeLocation}
            />
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Date range</label>
          <div className='col-sm-4'>
            <div className='d-flex flex-row align-items-center'>
              <div className='mr-2'>
                <CalendarInput
                  width='85px'
                  background={colors.grey1}
                  selected={start}
                  onChange={this.onChangeStart}
                />
              </div>
              <Icons.ArrowRight color={colors.grey4} width={18} height={18} />
              <div className='ml-2'>
                <CalendarInput
                  align='right'
                  width='85px'
                  background={colors.grey1}
                  selected={finish}
                  onChange={this.onChangeFinish}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4 offset-sm-2'>
            <SearchButton
              type='button'
              className='btn btn-primary'
              disabled={canSearch !== true}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3 mt-4'>
            {report === null && (
              <div className='text-center text-secondary'>Report not found.</div>
            )}
            {report !== null && (
              <Table className='table'>
                <thead>
                  <tr>
                    {report.columns.map((c, i) => (
                      <Th key={i} className='border-0'>{c}</Th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {report.rows.map((r, i) => (
                    <tr key={i}>
                      {r.map((v, j) => (
                        <Td key={j}>
                          {v.id && <Link to={`/job/${v.id}`}><strong className='text-body'>{v.v}</strong></Link>}
                          {!v.id && v.v}
                        </Td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card>
        )}
      </React.Fragment>
    );
  };
};

