import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Dates from './component.jsx';


const load = (API, stormId, bookingType) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    const url = new URL(`${env.API_SERVER}/v1/internal/booking-schedule/${stormId}/blocked-days`);
    url.searchParams.append('bookingType', bookingType);
    return API.requestForm(url, {method: 'GET'})
      .then(res => {
        if (res !== null && res.error !== true) {
          dispatch({
            type: 'JOB-DETAILS-BLOCKED-DAYS-SET',
            blockedDays: res.blockedDays
          });
        } else {
          dispatch({
            type: 'JOB-DETAILS-BLOCKED-DAYS-SET',
            blockedDays: null
          });
        }
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    params: {
      generalSettings: {
        publicHolidayIndex: publicHolidays,
      },
      locations: {
        index: locations
      },
    },
    jobDetails: {
      header: {
        stormId, flagged, state: currentState,
        startDate, startDateError,
        finishDate, finishDateError,
        assessmentDateDate, assessmentDateTime,
        readyDateDate, readyDateTime,
        pickupDateDate, pickupDateTime,
        fitmentDateDate, fitmentDateTime,
        primaryLocationId,
      },
      bookingType,
      calculatedRepairDays,
      blockedDays,      
    }
  } = state;    
  const {
    profile: {abilities: {canJobDetailsViewDates, canJobDetailsBookingOverride}}
  } = profiles[activeProfile];    
  return {
    canJobDetailsViewDates,
    canJobDetailsBookingOverride,
    publicHolidays,
    currentState,
    stormId,
    primaryLocationId,
    bookingType,
    flagged,
    startDate,
    startDateError,
    finishDate,
    finishDateError,
    assessmentDateDate,
    assessmentDateTime,
    readyDateDate,
    readyDateTime,
    pickupDateDate,
    pickupDateTime,
    fitmentDateDate,
    fitmentDateTime,
    calculatedRepairDays,
    blockedDays,    
    locations,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: (stormId, bookingType) => dispatch(load(props.API, stormId, bookingType)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dates)
);
