import moment from 'moment';


export default (
  state = {
    notFound: null,
    jobId: null,
    jobNumber: null,
    qas: []
  },
  action
) => {
  switch (action.type) {
    case 'JOB-QAS-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-QAS-SET-FOUND':
      const{jobId, jobNumber, flagged, vpp, vip, importedFromArms, bookingType} = action;
      return {
        ...state,
        notFound: false,
        jobId,
        jobNumber,
        flagged,
        vpp,
        vip,
        bookingType,
        importUrl: importedFromArms ?
          `https://www.arms1.com.au/search?search-quote-number=${jobNumber}` :
          null,
      };
    case 'JOB-QAS-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
        qas: []
      };
    case 'JOB-QAS-SET-QAS':
    {
      const year = moment().year();
      return {
        ...state,
        qas: action.qas.map(q => {
          const ts = moment(q.createDate * 1000);
          const entries = JSON.parse(q.entries);
          return {
            ...q,
            ts: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
            entries: Object.keys(entries  || {}).filter(e =>  entries[e] !== true),
          };
        })
      };
    }
    default:
      return state;
  }
};
