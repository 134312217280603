import React from 'react';
import * as Icons from 'react-feather';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Autocomplete from 'components/Autocomplete.jsx';
import CalendarInput from 'components/CalendarInput.jsx';
import Field from 'components/Field.jsx';
import Modal from 'components/Modal';
import SearchBar from 'components/internal/SearchBar';


const Textarea = styled.textarea`
  border-color: ${opts => opts.error ? colors.red : '#fff'} !important;
  background: ${colors.grey1};
`;
const PriorityButton = styled.button`
  width: 33%;
`;

export default class AddTask extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      canAdd: false,
      jobId: opts.jobId || null,
      jobNumber: opts.jobNumber || '',
      toId: null,
      to: '',
      title: '',
      notes: '',
      dueDate: null,
      priority: 3,
      responseRequired: true,
    };
    this.onFilterTo = this.onFilterTo.bind(this);
    this.onChangeJob = this.onChange.bind(this, 'job');
    this.onChangeTo = this.onChange.bind(this, 'to');
    this.onChangeTitle = this.onChange.bind(this, 'title');
    this.onChangeNotes = this.onChange.bind(this, 'notes');
    this.onChangeDueDate = this.onChange.bind(this, 'dueDate');
    this.onChangeResponseRequired = this.onChange.bind(this, 'responseRequired');
    this.onAdd = this.onAdd.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        canAdd: false,
        jobId: this.props.jobId || null,
        jobNumber: this.props.jobNumber || '',
        toId: null,
        to: '',
        title: '',
        notes: '',
        dueDate: null,
        priority: 3,
        responseRequired: true,
      });
    }
  }
  onChange(field, e) {
    let newState = null;
    if (field === 'job') {
      if (e) {
        newState = {
          ...this.state,
          jobId: e.id,
          jobNumber: e.jobNumber,
        };
      } else {
        newState = {
          ...this.state,
          jobId: null,
          jobNumber: '',
        };
      }
    } else if (field === 'to') {
      if (typeof e === 'string') {
        newState = {
          ...this.state,
          toId: null,
          to: e
        };
      } else {
        newState = {
          ...this.state,
          toId: e.id,
          to: e.text,
        };
      }
    } else if (field === 'responseRequired') {
      newState = {
        ...this.state,
        responseRequired: e.target.checked,
      };
    } else if (field === 'notes') {
      newState = {
        ...this.state,
        notes: e.target.value,
      };
    } else {
      newState = {
        ...this.state,
        [field]: e,
      };
    }
    if (newState !== null) {
      newState.canAdd = (
        newState.jobId !== null &&
        newState.toId !== null &&
        newState.title.trim() !== ''
      );
      this.setState(newState);
    }
  }
  onFilterTo(v) {
    const {employees} = this.props;
    const regex = new RegExp(v, 'i');
    const res = employees
      .reduce((acc, e) => {
        if (regex.test(e.name) === true) {
          acc.push({
            id: e.id,
            text: e.name,
          });
        }
        return acc;
      }, []);
    return res;
  }
  onAdd() {
    const {onAdd, onClose} = this.props;
    const {
      jobId,
      toId,
      title,
      notes,
      dueDate,
      priority,
      responseRequired,
    } = this.state;
    onAdd({
      jobId,
      to: toId,
      title,
      notes,
      dueDate,
      priority,
      responseRequired,
    })
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while adding task.');
        } else {
          toast.success('Task successfully added.');
          onClose();
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while adding task.');
      });
  }
  render() {
    //console.log('addmiscinvoice:render');
    const {open, onClose} = this.props;
    const {
      canAdd,
      jobNumber,
      to,
      title,
      notes,
      dueDate,
      priority,
      responseRequired,
    } = this.state;
    return (
      <Modal
        title='Add task'
        open={open}
        onClose={onClose}
      >
        <div className='modal-body'>
          <small className='text-muted'>Job #</small>
          <SearchBar
            background={colors.grey1}
            onSelect={this.onChangeJob}
            onClear={this.onChangeJob}
            value={jobNumber}
          />
          <Autocomplete
            className='m-0 mb-2'
            background={colors.grey1}
            uppercaseOnly={true}
            value={to}
            onChange={this.onChangeTo}
            onFilter={this.onFilterTo}
          >
            <small className='text-muted'>To</small>
          </Autocomplete>
          <Field
            title='Title'
            className='mb-2'
            background={colors.grey1}
            uppercaseOnly={true}
            value={title}
            onChange={this.onChangeTitle}
          />
          <div className='mb-2'>
            <small className='text-muted'>Notes</small>
            <Textarea
              type='text'
              rows={3}
              className='form-control p-0'
              onChange={this.onChangeNotes}
              value={notes}
            />
          </div>
          <div className='row'>
            <div className='col-4'>
              <CalendarInput
                title='Due date'
                selected={dueDate}
                background={colors.grey1}
                showClear={true}
                onChange={this.onChangeDueDate}
              />
            </div>
            <div className='col-4'>
              <div className='btn-group w-100 mb-2 mt-4'>
                <PriorityButton
                  type='button'
                  className='btn btn-success'
                  onClick={this.onChange.bind(this, 'priority', 1)}
                >{priority === 1 && <Icons.X width={16} height={16} color='white' />}</PriorityButton>
                <PriorityButton
                  type='button'
                  className='btn btn-warning'
                  onClick={this.onChange.bind(this, 'priority', 2)}
                >{priority === 2 && <Icons.X width={16} height={16} color='white' />}</PriorityButton>
                <PriorityButton
                  type='button'
                  className='btn btn-danger'
                  onClick={this.onChange.bind(this, 'priority', 3)}
                >{priority === 3 && <Icons.X width={16} height={16} color='white' />}</PriorityButton>
              </div>
            </div>
            <div className='col-4 form-group form-check mb-3 mt-auto'>
              <input
                type='checkbox'
                className='form-check-input'
                id='add-task-response-required'
                onChange={this.onChangeResponseRequired}
                checked={responseRequired}
              />
              <label className='form-check-label' htmlFor='add-task-response-required'>
                Response required
              </label>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={onClose}
          >Close</button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onAdd}
            disabled={canAdd !== true}
          >Add</button>
        </div>
      </Modal>
    );
  }
};
