import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';
import {toast} from "react-toastify";


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Body = styled.div`
  font-size: 16px;
  color: ${colors.body};
  padding: 1rem;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Li = styled.li`
  ::marker {
    font-weight: 500;
    font-size: 1.5rem;
  }
`;
const Star = styled.button`
  :focus {
    outline: none;
    box-shadow: none;
  }
  svg {
    stroke: ${opts => opts.selected && 'gold'};
    fill: ${opts => opts.selected && 'gold'};
  }
`;


class StarQuestion extends React.Component {
  render() {
    const {id, title, value, onChange} = this.props;
    return (
      <div>
        <h4 className='mb-2'>{title}</h4>
        <div className='d-flex flex-row justify-content-center'>
          {[1,2,3,4,5].map(i => (
            <Star
              key={i}
              className='btn'
              type='button'
              onClick={onChange}
              value={i}
              selected={value !== null && i <= value}
              data-q={id}
            >
              <Icons.Star />
            </Star>
          ))}
        </div>
      </div>
    );
  }
}

export default class AppSurvey extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: '',
      comments: '',
      disabled: true
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    const key = e.currentTarget.getAttribute('data-q');
    const value = e.currentTarget.value;
    const newState = {
      ...this.state,
      [key]: value,
    };

    this.setState({
      ...newState,
      disabled: !(
        newState.q1 !== null &&
        newState.q2 !== null &&
        newState.q3 !== null &&
        newState.q4 !== null
      )
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const {q1, q2, q3, q4, q5, comments} = this.state;
    const {match:{params:{token}}} = this.props;
    const {onSubmit} = this.props;
    onSubmit(token, {q1, q2, q3, q4, q5: q5 || null, comments: comments || null})
      .then(res => {
        if (res !== true) {
          toast.error('Error happened while submit.');
        }
      });
  }

  componentDidMount() {
    const {match:{params:{token}}} = this.props;
    const {onLoad} = this.props;
    onLoad(token);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const {notFound} = this.props;
    const {disabled} = this.state;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }
    const {
      companyName,
      abn,
      licenseNumber,
      phone,
      logo,
      pin,
      mode,
      address,
    } = this.props;
    return (
      <Body>
        <div className='row mb-5'>
          <div className='col-12 col-sm-6 text-center text-sm-left'>
            {logo && <img src={logo} alt={companyName} width="250" />}
          </div>
          <div className='col-12 col-sm-6 text-center text-sm-right'>
            <h3>{companyName}</h3>
            <div>{pin && <a href={pin} className='mr-2' target='_blank' rel='noopener noreferrer'><Icons.MapPin width={16} height={16} /></a>} {address}</div>
            <div>P: {phone}</div>
            <div>ABN: {abn}</div>
            <div>LIC #: {licenseNumber}</div>
          </div>
        </div>
        {mode === 'survey' && (
          <Card className='p-4'>
            <h3 className='text-center'>How was the Bears experience?</h3>
            <form className='w-100 mt-4' onSubmit={this.onSubmit}>
              <ol>
                {[
                  {id: 'q1', title: 'Were you happy with the estimate and booking process?'},
                  {id: 'q2', title: 'Were you happy with the presentation of the site & staff?'},
                  {id: 'q3', title: 'Were you kept informed during the repair process?'},
                  {id: 'q4', title: 'How would you rate your overall experience?'},
                ].map((q, i) => (
                  <Li key={i} className='mb-4'>
                    <StarQuestion
                      id={q.id}
                      title={q.title}
                      value={this.state[q.id]}
                      onChange={this.onChange}
                    />
                  </Li>
                ))}
                <Li className='mb-4'>
                  <h4 className='mb-2'>If you would like a follow up call from management please leave your registration details and phone number below.</h4>
                  <textarea
                    type='text'
                    rows='4'
                    className='form-control p-0'
                    onChange={this.onChange}
                    value={this.state.q5}
                    data-q='q5'
                  />
                </Li>
                <Li>
                  <h4 className='mb-2'>Do you have any suggestions on how we can improve your experience next time?</h4>
                  <textarea
                    type='text'
                    rows='4'
                    className='form-control p-0'
                    onChange={this.onChange}
                    value={this.state.comments}
                    data-q='comments'
                  />
                </Li>
              </ol>
              <button
                type='submit'
                className='btn btn-primary w-100 py-3 font-weight-bold mt-2'
                disabled={disabled}
                onClick={this.onSubmit}
              >Submit</button>
            </form>
          </Card>
        )}
        {mode === 'submitted' && (
          <Card className='p-3 py-5 text-center h5'>
            Thank you for taking the time to give us feedback and helping us improve. We really appreciate it.
          </Card>
        )}
      </Body>
    );
  }
};
