import React from 'react';
import {Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';
import Select from 'components/Select.jsx';

import Invoice from './Invoice';
import ProfitLoss from './ProfitLoss';
import Location from './Location';
import StormActivity from './StormActivity';
import StormCategory from './StormCategory';
import TechPayments from './TechPayments';
import Survey from './Survey';


const REPORT_TYPES = [
  {id: 'invoice', text: 'Invoice report'},
  {id: 'location', text: 'Location report'},
  {id: 'profit-loss', text: 'P&L report'},
  {id: 'storm-activity', text: 'Storm activity report'},
  {id: 'storm-category', text: 'Storm category report'},
  {id: 'survey', text: 'Survey report'},
  {id: 'tech-payments', text: 'Tech payments report'},
];

const Container = styled.div`
  font-size: 0.9rem;
`;

export default class Reports extends React.Component {
  constructor(opts) {
    super(opts);
    this.onChangeReportType = this.onChangeReportType.bind(this);
  }
  onChangeReportType(reportType) {
    if (reportType) {
      this.props.history.push(`/admin/reports/${reportType}`);
    } else {
      this.props.history.push('/admin/reports');
    }
  }
  render() {
    const {
      match: {
        params: {
          reportType
        }
      },
      company: {
        name: companyName,
        jurisdiction,
        abn,
        licenseNumber,
        address,
        phone,
        logo,
      },
    } = this.props;
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-none d-print-flex mb-5'>
          <div className='col-12 col-sm-6 text-center text-sm-left'>
            {logo && <img src={logo} alt='logo' width="250" />}
          </div>
          <div className='col-12 col-sm-6 text-center text-sm-right text-uppercase'>
            <h3>{companyName}</h3>
            <div>{address}</div>
            <div>P: {phone}</div>
            <div>{jurisdiction === 'AU' && 'ABN:'}{jurisdiction === 'NZ' && 'GST #:'} {abn}</div>
            <div>LIC #: {licenseNumber}</div>
          </div>
        </div>
        <h4>Reports</h4>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Report type</label>
          <div className='col-sm-4'>
            <Select
              background={colors.grey1}
              placeholder='select a report type'
              selected={reportType}
              options={REPORT_TYPES}
              onChange={this.onChangeReportType}
            />
          </div>
        </div>
        <Switch>
          <Route
            path='/admin/reports/invoice'
            exact={true}
            component={Invoice}
          />
          <Route
            path='/admin/reports/location'
            exact={true}
            component={Location}
          />
          <Route
            path='/admin/reports/profit-loss'
            exact={true}
            component={ProfitLoss}
          />
          <Route
            path='/admin/reports/storm-activity'
            exact={true}
            component={StormActivity}
          />
          <Route
            path='/admin/reports/storm-category'
            exact={true}
            component={StormCategory}
          />
          <Route
            path='/admin/reports/tech-payments'
            exact={true}
            component={TechPayments}
          />
          <Route
            path='/admin/reports/survey'
            exact={true}
            component={Survey}
          />
        </Switch>
      </Container>
    );
  }
};
