import React from 'react';
import styled from 'styled-components';

import colors from 'colors';

import CalendarInput from 'components/CalendarInput.jsx';
import Select from 'components/Select.jsx';


const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;

const {
  index: HOURS15_INDEX,
  list: HOURS15_LIST
} = Array.apply(null, Array(49))
  .reduce((acc, _, j) => {
    const hour = (7 + Math.floor(j / 4));
    const minute = ((j % 4) * 15);
    const second = hour * 3600 + minute * 60;
    const hh = ('00' + hour).slice(-2);
    const mm = ('00' + minute).slice(-2);
    acc.index[j] = second;
    acc.list.push({
      id: j,
      text: `${hh}:${mm}`
    });
    return acc;
  }, {index: {}, list: []});

const {
  index: HOURS30_INDEX,
  list: HOURS30_LIST
} = Array.apply(null, Array(25))
  .reduce((acc, _, j) => {
    const hour = (7 + Math.floor(j / 2));
    const minute = ((j % 2) * 30);
    const second = hour * 3600 + minute * 60;
    const hh = ('00' + hour).slice(-2);
    const mm = ('00' + minute).slice(-2);
    acc.index[j] = second;
    acc.list.push({
      id: j,
      text: `${hh}:${mm}`
    });
    return acc;
  }, {index: {}, list: []});

export default class Dates extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      assessmentDate: opts.assessmentDateDate,
      assessmentTime: opts.assessmentDateTime,
      readyDate: opts.readyDateDate,
      readyTime: opts.readyDateTime,
      pickupDate: opts.pickupDateDate,
      pickupTime: opts.pickupDateTime,
      fitmentDate: opts.fitmentDateDate,
      fitmentTime: opts.fitmentDateTime,
    };
  }
  componentDidMount() {
    const {stormId, bookingType, onLoad} = this.props;
    if (stormId && bookingType) {
      onLoad(stormId, bookingType);
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.stormId &&
      (
        prevProps.stormId !== this.props.stormId ||
        prevProps.bookingType !== this.props.bookingType
      )
    ) {
      this.props.onLoad(this.props.stormId, this.props.bookingType);
    }
    if (
      prevProps.assessmentDateDate !== this.props.assessmentDateDate ||
      prevProps.assessmentDateTime !== this.props.assessmentDateTime ||
      prevProps.readyDateDate !== this.props.readyDateDate ||
      prevProps.readyDateTime !== this.props.readyDateTime ||
      prevProps.pickupDateDate !== this.props.pickupDateDate ||
      prevProps.pickupDateTime !== this.props.pickupDateTime ||
      prevProps.fitmentDateDate !== this.props.fitmentDateDate ||
      prevProps.fitmentDateTime !== this.props.fitmentDateTime
    ) {
      this.setState({
        assessmentDate: this.props.assessmentDateDate,
        assessmentTime: this.props.assessmentDateTime,
        readyDate: this.props.readyDateDate,
        readyTime: this.props.readyDateTime,
        pickupDate: this.props.pickupDateDate,
        pickupTime: this.props.pickupDateTime,
        fitmentDate: this.props.fitmentDateDate,
        fitmentTime: this.props.fitmentDateTime,
      });
    }
  }
  onChange(field, e) {
    const {onChange} = this.props;
    if (field === 'assessmentDate' || field === 'assessmentTime') {
      const tmp = {
        ...this.state,
        [field]: e
      };
      if (tmp.assessmentDate !== null && tmp.assessmentTime !== null) {
        onChange({
          assessmentDate: tmp.assessmentDate + HOURS15_INDEX[tmp.assessmentTime]
        });
      } else if (tmp.assessmentDate === null) {
        onChange({
          assessmentDate: null
        });
      } else {
        this.setState(tmp);
      }
    } else if (field === 'readyDate' || field === 'readyTime') {
      const tmp = {
        ...this.state,
        [field]: e
      };
      if (tmp.readyDate !== null && tmp.readyTime !== null) {
        onChange({
          readyDate: tmp.readyDate + HOURS30_INDEX[tmp.readyTime]
        });
      } else if (tmp.readyDate === null) {
        onChange({
          readyDate: null
        });
      } else {
        this.setState(tmp);
      }
    } else if (field === 'pickupDate' || field === 'pickupTime') {
      const tmp = {
        ...this.state,
        [field]: e
      };
      if (tmp.pickupDate !== null && tmp.pickupTime !== null) {
        onChange({
          pickupDate: tmp.pickupDate + HOURS30_INDEX[tmp.pickupTime]
        });
      } else if (tmp.pickupDate === null) {
        onChange({
          pickupDate: null
        });
      } else {
        this.setState(tmp);
      }
    } else if (field === 'fitmentDate' || field === 'fitmentTime') {
      const tmp = {
        ...this.state,
        [field]: e
      };
      if (tmp.fitmentDate !== null && tmp.fitmentTime !== null) {
        onChange({
          fitmentDate: tmp.fitmentDate + HOURS30_INDEX[tmp.fitmentTime]
        });
      } else if (tmp.fitmentDate === null) {
        onChange({
          fitmentDate: null
        });
      } else {
        this.setState(tmp);
      }
    } else if (field === 'bookingDates') {
      const {startDate, finishDate} = e;
      onChange({
        startDate,
        finishDate,
        startDateError: false,
        finishDateError: false,
      });
    } else {
      onChange({
        [field]: e,
        [`${field}Error`]: false
      });
    }
  }
  render() {
    console.log('dates:render');
    const {
      canJobDetailsViewDates,
      canJobDetailsBookingOverride,      
      publicHolidays,
      currentState,
      flagged,
      startDate, startDateError,
      finishDate, finishDateError,
      calculatedRepairDays,
      blockedDays,
      bookingType,
      primaryLocationId,
      locations,
    } = this.props;
    const {
      assessmentDate,
      assessmentTime,
      readyDate,
      readyTime,
      pickupDate,
      pickupTime,
      fitmentDate,
      fitmentTime,
    } = this.state;    
    if (primaryLocationId === null || canJobDetailsViewDates !== true) return null;
    return (
      <Card className='px-3 pt-2 pb-3 mt-3'>
        <div className='row'>
          <div className='col-sm-6 col-lg-3 mt-2'>
            <div className='row no-gutters'>
              <div className='col-7'>
                <CalendarInput
                  className='mr-2'
                  title='Assess date'
                  enabledDows={locations[primaryLocationId].assessmentDows}
                  selected={assessmentDate}
                  background='#fff'
                  showClear={true}
                  onChange={this.onChange.bind(this, 'assessmentDate')}
                />
              </div>
              <div className='col-5'>
                <Select
                  title='Assess time'
                  selected={assessmentTime}
                  options={HOURS15_LIST}
                  onChange={this.onChange.bind(this, 'assessmentTime')}
                />
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-lg-3 mt-2'>
            <div className='row no-gutters'>
              <div className='col-6'>
                <CalendarInput
                  className='mr-2'
                  title='Start date'
                  enabledDows={locations[primaryLocationId].repairDows}
                  selected={startDate}
                  background='#fff'
                  error={startDateError}
                  showClear={true}
                  disabled={
                    flagged === true ||
                    bookingType === null ||
                    blockedDays === null ||
                    calculatedRepairDays === null
                  }
                  disableDaysInThePast={true}
                  blockedDays={blockedDays}
                  canSelectBlocked={canJobDetailsBookingOverride}
                  publicHolidays={publicHolidays}
                  currentState={currentState}
                  numberOfDays={calculatedRepairDays}
                  showRange={false}
                  onChange={this.onChange.bind(this, 'bookingDates')}
                />
              </div>
              <div className='col-6'>
                {calculatedRepairDays && (
                  <div className='float-right'>
                    <small className='text-muted'>{calculatedRepairDays}</small>
                  </div>
                )}
                <CalendarInput
                  title='Finish date'
                  selected={finishDate}
                  background='#fff'
                  error={finishDateError}
                  showClear={true}
                  disabled={
                    flagged === true ||
                    bookingType === null ||
                    blockedDays === null ||
                    calculatedRepairDays === null
                  }
                  disableDaysInThePast={true}
                  publicHolidays={publicHolidays}
                  currentState={currentState}
                  onChange={this.onChange.bind(this, 'finishDate')}
                />
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-lg-3 mt-2'>
            <div className='row no-gutters'>
              <div className='col-7'>
                <CalendarInput
                  className='mr-2'
                  title='Ready date'
                  selected={readyDate}
                  background='#fff'
                  showClear={true}
                  onChange={this.onChange.bind(this, 'readyDate')}
                />
              </div>
              <div className='col-5'>
                <Select
                  title='Ready time'
                  selected={readyTime}
                  options={HOURS30_LIST}
                  onChange={this.onChange.bind(this, 'readyTime')}
                />
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-lg-3 mt-2'>
            <div className='row no-gutters'>
              <div className='col-7'>
                <CalendarInput
                  className='mr-2'
                  title='Pickup date'
                  enabledDows={locations[primaryLocationId].pickupDows}
                  selected={pickupDate}
                  background='#fff'
                  showClear={true}
                  onChange={this.onChange.bind(this, 'pickupDate')}
                />
              </div>
              <div className='col-5'>
                <Select
                  title='Pickup time'
                  selected={pickupTime}
                  options={HOURS30_LIST}
                  onChange={this.onChange.bind(this, 'pickupTime')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-lg-3 mt-2'>
            <div className='row no-gutters'>
              <div className='col-7'>
                <CalendarInput
                  className='mr-2'
                  title='Fitment date'
                  enabledDows={locations[primaryLocationId].fitmentDows}
                  selected={fitmentDate}
                  background='#fff'
                  showClear={true}
                  onChange={this.onChange.bind(this, 'fitmentDate')}
                />
              </div>
              <div className='col-5'>
                <Select
                  title='Fitment time'
                  selected={fitmentTime}
                  options={HOURS30_LIST}
                  onChange={this.onChange.bind(this, 'fitmentTime')}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
};
