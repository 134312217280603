import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';
import Modal from 'components/Modal';


const Button = styled.div`
  cursor: pointer;
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;

  & svg {
    stroke: ${colors.body};
  }
  :hover svg {
    stroke: ${colors.primary};
  }
`;

export default class Add extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onAddJob = this.onAddJob.bind(this);
    this.onAddMiscInvoice = this.onAddMiscInvoice.bind(this);
    this.onAddTask = this.onAddTask.bind(this);
  }

  onOpen() {
    this.setState({
      open: true
    });
  }

  onClose() {
    this.setState({
      open: false
    });
  }

  onAddJob() {
    const {canAddJob, canAddMiscInvoice, onAddJob} = this.props;
    if ((canAddJob && canAddMiscInvoice) || window.confirm('Are you sure you wish to add a new job?') === true) {
      onAddJob()
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered while creating job.');
          } else {
            toast.success('Job successfully created.');
            this.setState({open: false});
          }
        });
    }
  }

  onAddMiscInvoice() {
    const {onAddMiscInvoice} = this.props;
    this.onClose();
    onAddMiscInvoice();
  }

  onAddTask() {
    const {onAddTask} = this.props;
    this.onClose();
    onAddTask();
  }

  render() {
    const {canAddJob, canAddMiscInvoice, canAddTask} = this.props;
    const {open} = this.state;
    if (!canAddJob && !canAddMiscInvoice && !canAddTask) return null;
    if ((canAddJob ^ canAddMiscInvoice ^ canAddTask) && !(canAddJob && canAddMiscInvoice && canAddTask)) {
      return (
        <Button
          onClick={
            (canAddJob && this.onAddJob) ||
            (canAddMiscInvoice && this.onAddMiscInvoice) ||
            (canAddTask && this.onAddTask) ||
            null
          }
        >
          <Icons.Plus />
        </Button>
      );
    }
    return (
      <React.Fragment>
        <Button onClick={this.onOpen}>
          <Icons.Plus />
        </Button>
        <Modal
          title='Add...'
          open={open}
          onClose={this.onClose}
        >
          <div className='modal-body'>
            <div className='row'>
              {canAddJob && (
                <div className='col-sm-12 col-lg'>
                  <button className='btn btn-secondary w-100 mt-sm-2 mt-lg-0' onClick={this.onAddJob}>
                    Job
                  </button>
                </div>
              )}
              {canAddMiscInvoice && (
                <div className='col-sm-12 col-lg'>
                  <button className='btn btn-secondary w-100 mt-sm-2 mt-lg-0' onClick={this.onAddMiscInvoice}>
                    Misc invoice
                  </button>
                </div>
              )}
              {canAddTask && (
                <div className='col-sm-12 col-lg'>
                  <button className='btn btn-secondary w-100 mt-sm-2 mt-lg-0' onClick={this.onAddTask}>
                    Task
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};
