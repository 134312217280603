import React from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Field from 'components/Field.jsx';
import OCRField from 'components/OCRField.jsx';
import Select from 'components/Select.jsx';

import Debtors from './Debtors.jsx';
import Storms from './Storms.jsx';
import Companies from './Companies.jsx';


const Row = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;
const Col = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;

const STATES = ['ACT', 'NSW', 'NT', 'QLD', 'VIC', 'TAS', 'WA'];
const PAINTS = ['M1', 'M2', 'M3', 'S1', 'S2'];
const BODIES = ['COUPE', 'HATCH', 'SEDAN', 'UTE2DR', 'UTE4DR', 'VAN', 'WAGON/5DR'];

export default class Header extends React.Component {
  onChange(field, e) {
    const {onChange} = this.props;
    if (
      [
        'debtorId',
        'stormId',
        'bodyShape',
        'bodyPaint'
      ].indexOf(field) !== -1
    ) {
      onChange({
        [field]: e,
        [`${field}Error`]: false
      });
    } else if (field === 'excess') {
      if (/^[0-9]*\.?([0-9]{0,2})?$/.test(e) === true || e === '') {
        onChange({
          excess: Math.round(e * 100),
          excessStr: e,
          excessError: false
        });
      }
    } else if (field === 'insuredValue') {
      if (/^[0-9]*\.?([0-9]{0,2})?$/.test(e) === true || e === '') {
        onChange({
          insuredValue: Math.round(e * 100),
          insuredValueStr: e,
          insuredValueError: false
        });
      }
    //} else if (field === 'bookingDates') {
    //  const {startDate, finishDate} = e;
    //  onChange({
    //    startDate,
    //    finishDate
    //  });
    } else {
      onChange({
        [field]: e,
        [`${field}Error`]: false
      });
    }
  }
  onOCR(field, image) {
    const {jobId, onOCR} = this.props;
    onOCR(jobId, field, image)
      .catch(e => {
        console.error(e)
        toast.error('Error encountered performing OCR.');
      });
  }
  render() {
    console.log('header:render');
    const {
      canJobDetailsViewCustomer,
      canJobDetailsViewCar,
      header: {
        name, nameError,
        address, addressError,
        state, stateError,
        postcode, postcodeError,
        comments, commentsError,
        mobile, mobileError,
        email, emailError,
        debtorId, debtorIdError,
        stormId, stormIdError,
        companyId, companyIdError,
        claimNumber, claimNumberError,
        excessStr, excessError,

        make, makeError,
        model, modelError,

        rego, regoError,
        vin, vinError,
        bodyShape, bodyShapeError,
        colour, colourError,
        bodyPaint, bodyPaintError,
        manDate, manDateError,
        insuredValueStr, insuredValueError,
      }
    } = this.props;
    const singleColumn = canJobDetailsViewCustomer !== canJobDetailsViewCar;
    return (
      <form>
        <Row className='d-flex flex-row flex-wrap'>
          {canJobDetailsViewCustomer === true && (
            <Col className={singleColumn ? 'flex-grow-1' : 'col-12 col-md-6'}>
              <Card className='p-3 mt-3'>
                <div className='row'>
                  <Field
                    className={singleColumn ? 'col-12 col-md-6 mb-2' : 'col-12 mb-2'}
                    title='Name'
                    uppercaseOnly={true}
                    value={name}
                    error={nameError}
                    onChange={this.onChange.bind(this, 'name')}
                  />
                  <Field
                    className={singleColumn ? 'col-8 col-md-4 mb-2' : 'col-8 mb-2'}
                    title='Address'
                    uppercaseOnly={true}
                    value={address}
                    error={addressError}
                    onChange={this.onChange.bind(this, 'address')}
                  />
                  <div className={singleColumn ? 'col-2 col-md-5 mb-2' : 'col-2 mb-2'}>
                    <Select
                      title='State'
                      placeholder='state'
                      options={STATES}
                      selected={state}
                      error={stateError}
                      onChange={this.onChange.bind(this, 'state')}
                    />
                  </div>
                  <Field
                    className={singleColumn ? 'col-2 col-md-5 mb-2' : 'col-2 mb-2'}
                    title='Postcode'
                    uppercaseOnly={true}
                    value={postcode}
                    error={postcodeError}
                    onChange={this.onChange.bind(this, 'postcode')}
                  />
                  <Field
                    className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}
                    title='Mobile'
                    uppercaseOnly={true}
                    value={mobile}
                    error={mobileError}
                    onChange={this.onChange.bind(this, 'mobile')}
                  />
                  <Field
                    className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}
                    title='Email'
                    value={email}
                    error={emailError}
                    onChange={this.onChange.bind(this, 'email')}
                  />
                  <div className={singleColumn ? 'col-4 col-md-3' : 'col-4'}>
                    <Debtors
                      selected={debtorId}
                      error={debtorIdError}
                      onChange={this.onChange.bind(this, 'debtorId')}
                    />
                  </div>
                  <div className={singleColumn ? 'col-4 col-md-3' : 'col-4'}>
                    <Storms
                      selected={stormId}
                      error={stormIdError}
                      onChange={this.onChange.bind(this, 'stormId')}
                    />
                  </div>
                  <div className={singleColumn ? 'col-4 col-md-3' : 'col-4'}>
                    <Companies
                      selected={companyId}
                      error={companyIdError}
                      onChange={this.onChange.bind(this, 'companyId')}
                    />
                  </div>
                  <Field
                    className={singleColumn ? 'col-12 col-md-6' : 'col-12'}
                    title='Comments'
                    uppercaseOnly={true}
                    value={comments}
                    error={commentsError}
                    onChange={this.onChange.bind(this, 'comments')}
                  />
                </div>
              </Card>
            </Col>
          )}
          {canJobDetailsViewCar === true && (
            <Col className={singleColumn ? 'flex-grow-1' : 'col-12 col-md-6'}>
              <Card className='p-3 mt-3'>
                <div className='row'>
                  <div className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}>
                    <OCRField
                      title='Rego'
                      value={rego}
                      error={regoError}
                      onChange={this.onChange.bind(this, 'rego')}
                      onUpload={this.onOCR.bind(this, 'rego')}
                    />
                  </div>
                  <div className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}>
                    <OCRField
                      title='VIN'
                      value={vin}
                      error={vinError}
                      onChange={this.onChange.bind(this, 'vin')}
                      onUpload={this.onOCR.bind(this, 'vin')}
                    />
                  </div>
                  <Field
                    className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}
                    title='Make'
                    uppercaseOnly={true}
                    value={make}
                    error={makeError}
                    onChange={this.onChange.bind(this, 'make')}
                  />
                  <Field
                    className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}
                    title='Model'
                    uppercaseOnly={true}
                    value={model}
                    error={modelError}
                    onChange={this.onChange.bind(this, 'model')}
                  />
                  <div className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}>
                    <Select
                      title='Body *'
                      placeholder='select a body type'
                      options={BODIES}
                      selected={bodyShape}
                      error={bodyShapeError}
                      onChange={this.onChange.bind(this, 'bodyShape')}
                    />
                  </div>
                  <Field
                    className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}
                    title='Colour'
                    uppercaseOnly={true}
                    value={colour}
                    error={colourError}
                    onChange={this.onChange.bind(this, 'colour')}
                  />
                  <div className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}>
                    <Select
                      title='Paint *'
                      placeholder='select a paint type'
                      options={PAINTS}
                      selected={bodyPaint}
                      error={bodyPaintError}
                      onChange={this.onChange.bind(this, 'bodyPaint')}
                    />
                  </div>
                  <Field
                    className={singleColumn ? 'col-6 col-md-3 mb-2' : 'col-6 mb-2'}
                    title='MM/YY'
                    uppercaseOnly={true}
                    value={manDate}
                    error={manDateError}
                    onChange={this.onChange.bind(this, 'manDate')}
                  />
                  {canJobDetailsViewCustomer === true && (
                    <>
                      <Field
                        className={singleColumn ? 'col-6 col-md-3' : 'col-6'}
                        title='Claim #'
                        uppercaseOnly={true}
                        value={claimNumber}
                        error={claimNumberError}
                        onChange={this.onChange.bind(this, 'claimNumber')}
                      />
                      <Field
                        className={singleColumn ? 'col-3 col-md-3' : 'col-3'}
                        title='Excess'
                        value={excessStr}
                        error={excessError}
                        onChange={this.onChange.bind(this, 'excess')}
                      />
                      <Field
                        className={singleColumn ? 'col-3 col-md-3' : 'col-3'}
                        title='Insured value'
                        value={insuredValueStr}
                        error={insuredValueError}
                        onChange={this.onChange.bind(this, 'insuredValue')}
                      />
                    </>
                  )}
                </div>
              </Card>
            </Col>
          )}
        </Row>
      </form>
    );
  }
};
