import React from 'react';

import colors from 'colors';

import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';

import {ABILITY_ROWS, TEMPLATES} from './employee-abilities.js';


export default class SettingsEmployee extends React.Component {
  constructor(opts) {
    super(opts);
    this.onToggleGst = this.onToggleGst.bind(this);
    this.onSelectPermissionTemplate = this.onSelectPermissionTemplate.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onChange(field, e) {
    this.props.onChange({
      [field]: e,
      [`${field}Error`]: false
    });
  }
  onToggleGst(e) {
    this.props.onChange({
      chargesGst: e.target.checked
    });
  }
  onSelectPermissionTemplate(e) {
    this.props.onChangeAbility(
      ABILITY_ROWS.reduce((acc, ABILITY) => (
        ABILITY.reduce((acc, a) => {
          acc[a.key] = a.templates.indexOf(e) !== -1;
          return acc;
        }, acc)
      ), {})
    );
  }
  onToggleAbility(field, e) {
    this.props.onChangeAbility({
      [field]: e.target.checked
    });
  }
  onDelete() {
    const {
      onDelete, id
    } = this.props;
    onDelete(id);
  }
  onSave() {
    const {
      onSave,
      id, name, email, password,
      chargesGst,
      abilities
    } = this.props;
    onSave(id, {
      name,
      email,
      password: (password && password !== '' ? password : null),
      chargesGst,
      abilities: ABILITY_ROWS.reduce((acc, ABILITIES) => {
        return ABILITIES.reduce((acc, a) => {
          acc[a.key] = abilities[a.key] === true;
          return acc;
        }, acc);
      }, {})
    });
  }
  render() {
    console.log('settingsemployee:render');
    const {onClose} = this.props;
    const {
      id,
      name, nameError,
      email, emailError,
      password, passwordError,
      chargesGst,
      abilities
    } = this.props;
    return (
      <React.Fragment>
        <div className='modal-body'>
          <Field
            title='Name'
            background={colors.grey1}
            uppercaseOnly={true}
            value={name}
            error={nameError}
            onChange={this.onChange.bind(this, 'name')} />
          <Field
            title='Email'
            background={colors.grey1}
            readOnly={id !== 'new'}
            value={email}
            error={emailError}
            onChange={this.onChange.bind(this, 'email')} />
          <Field
            type='password'
            background={colors.grey1}
            title='Password'
            value={password}
            error={passwordError}
            onChange={this.onChange.bind(this, 'password')} />
          <div className='form-group form-check mb-0 mt-3'>
            <input
              type='checkbox'
              id='charges-gst'
              className='form-check-input'
              checked={chargesGst}
              onChange={this.onToggleGst.bind(this)}
            />
            <label
              htmlFor='charges-gst'
              className='form-check-label'
            >Charges GST</label>
          </div>
          <div className='d-flex flex-row align-items-center justify-content-between mt-3 mb-0'>
            <strong>Abilities</strong>
            <Select
              background={colors.grey1}
              placeholder='select a template'
              options={TEMPLATES}
              selected={null}
              onChange={this.onSelectPermissionTemplate}
            />
          </div>
          {ABILITY_ROWS.map((ABILITIES, i) => (
            <React.Fragment key={i}>
              {i > 0 && <hr className='mt-2 mb-0' />}
              <div className='row'>
                {ABILITIES.map((a, j) => (
                  <div className='col-4 mt-2' key={j}>
                    <div className='form-group form-check mb-0'>
                      <input
                        type='checkbox'
                        id={`ea-${i}-${j}`}
                        className='form-check-input'
                        checked={abilities[a.key] === true}
                        onChange={this.onToggleAbility.bind(this, a.key)}
                      />
                      <label
                        htmlFor={`ea-${i}-${j}`}
                        className='form-check-label'
                      >{a.text}</label>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button type='button' className='btn btn-danger mr-auto' onClick={this.onDelete}>Delete</button>
          }
          <button type='button' className='btn btn-secondary' onClick={onClose}>Close</button>
          <button type='button' className='btn btn-primary' onClick={this.onSave}>Save</button>
        </div>
      </React.Fragment>
    );
  }
};
