import moment from 'moment';


export default (
  state = {
    notFound: null,
    jobId: null,
    jobNumber: null,
  },
  action
) => {
  switch (action.type) {
    case 'JOB-LOGS-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-LOGS-SET-FOUND':
      const{jobId, jobNumber, flagged, vpp, vip, importedFromArms} = action;
      return {
        ...state,
        notFound: false,
        jobId,
        jobNumber,
        flagged,
        vpp,
        vip,
        importUrl: importedFromArms ?
          `https://www.arms1.com.au/search?search-quote-number=${jobNumber}` :
          null,
      };
    case 'JOB-LOGS-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-LOGS-SET':
      {
        const year = moment().year();
        return {
          ...state,
          logs: action.logs.map(l => {
            const ts = moment(l.ts * 1000);
            return {
              ...l,
              tsStr: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
            };
          })
        };
      }
    default:
      return state;
  }
};
