import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';


const Card = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  border: ${opts => opts.active ? '1px solid rgba(180, 180, 180, 0.5)' : 'none'};
  box-shadow: ${opts => opts.active ? '0px 8px 15px -4px rgba(180, 180, 180, 0.5)': 'none'};
  :first-of-type {
    margin-top: 0 !important;
  }
  & a:hover {
    text-decoration: none;
  }
`;
const Avatar = styled.div`
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  background: ${opts => opts.bg};
  color: #fff;
`;


export default class Item extends React.Component {
  constructor(opts) {
    super(opts);
    this.type = null;
    this.linkPath = null;
    if (opts.type === 'order') {
      this.type = 'O';
      this.linkPath = `/orders/${opts.id}`;
    } else if (opts.type === 'quote') {
      this.type = 'Q';
      this.linkPath = `/quotes/${opts.id}`;
    }
  }
  render() {
    console.log('item:render');
    const {active, idStr, backorder, companyName, makeModel, hasContent, openUser} = this.props;
    return (
      <Card className='mt-2 p-2' active={active}>
        <NavLink
          to={this.linkPath}
          className='text-body'
        >
          <div className='d-flex flex-row justify-content-between'>
            {backorder !== true && (
              <div className='font-weight-bold'>
                {this.type}{idStr}
              </div>
            )}
            {backorder === true && (
              <div className='font-weight-bold'>
                {this.type}{idStr} (B/O)
              </div>
            )}
            <div>
              {companyName}
            </div>
          </div>
          <div className='d-flex flex-row justify-content-between align-items-center mt-1'>
            <div>{makeModel}</div>
            <div className='d-flex flex-row align-items-center'>
              {hasContent && (
                <Icons.Paperclip width={16} height={16} className='text-secondary mr-2' />
              )}
              <Avatar
                className='rounded-circle'
                bg={openUser === null ? colors.grey4 : openUser.avatarBg}
              >
                {openUser === null ? <Icons.User width={16} height={16} /> : openUser.initial}
              </Avatar>
            </div>
          </div>
        </NavLink>
      </Card>
    );
  };
};

