export default (state = {open: false}, action) => {
  switch (action.type) {
    case 'JOB-LIGHT-TUNNEL-OPEN':
      return {open: true};
    case 'JOB-LIGHT-TUNNEL-CLOSE':
      return {open: false};
    default:
      return state;
  }
};
