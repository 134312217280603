import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Locations from './component.jsx';


const load = (API, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/locations`,
      {method: 'GET'}
    )
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-LOCATIONS-SET-NOT-FOUND'
          });
          dispatch({
            type: 'JOB-GALLERY-SET',
            images: []
          });
          return null;
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        dispatch({
          type: 'JOB-LOCATIONS-SET',
          locations: resp.locations
        });
        dispatch({
          type: 'JOB-LOCATIONS-SET-FOUND',
          jobId,
          jobNumber: resp.jobNumber,
          flagged: resp.flagged,
          vpp: resp.vpp,
          vip: resp.vip,
          importedFromArms: resp.importedFromArms,
          canAddLocations: resp.canAddLocations,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-LOCATIONS-SET-NULL'
});

const addLocation = (API, jobId, locationId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/location`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({locationId})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'JOB-LOCATIONS-SET',
          locations: res.locations
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const deleteLocation = (API, jobId, locationId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/location/${locationId}`, {method: 'DELETE'})
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'JOB-LOCATIONS-SET',
          locations: res.locations
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const change = (API, jobId, locationId, changes) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/location/${locationId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(changes)
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'JOB-LOCATIONS-SET',
          locations: res.locations
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = (state, props) => {
  const {jobId} = props;
  let jobValid = null;
  if (state.jobLocations.notFound === true)
    jobValid = false;
  else if (state.jobLocations.notFound === false) {
    jobValid = true;
  }
  if (jobValid !== true) {
    return {
      jobValid,
    };
  }
  const {
    params: {
      employees: {
        index: employeeIndex
      },
      locations: {
        list: allLocations,
        abilityLists: locationAbilityLists
      }
    },
    jobLocations: {
      jobNumber,
      flagged,
      vpp,
      vip,
      importUrl,
      canAddLocations,
      locations: jobLocations
    },
  } = state;
  return {
    jobValid,
    jobId,
    jobNumber,
    flagged,
    vpp,
    vip,
    importUrl,
    canAddLocations,
    jobLocations,
    allLocations,
    employeeIndex,
    locationAbilityLists
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: jobId => dispatch(load(props.API, jobId)),
  onUnload: () => dispatch(unload()),
  onAddLocation: (jobId, locationId) => dispatch(addLocation(props.API, jobId, locationId)),
  onDeleteLocation: (jobId, locationId) => dispatch(deleteLocation(props.API, jobId, locationId)),
  onChange: (jobId, locationId, changes) => dispatch(change(props.API, jobId, locationId, changes)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Locations)
);
