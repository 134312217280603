import React from 'react';
import styled from 'styled-components';

import colors from 'colors';

import LocationSelect from 'components/LocationSelect';
import Popover from 'components/Popover.jsx';
import Job from './Job';


const Container = styled.div`
  overflow-y: auto;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 992px) {
  }
`;
const ColumnContainer = styled.div`
  @media (min-width: 992px) {
    flex-grow: 1;
  }
`;
const Column = styled.div`
  @media (min-width: 992px) {
    height: 100%;
    overflow: auto;
  }
  @media (max-width: 992px) {
    margin-bottom: 1rem !important;
    :last-of-type {
      margin-bottom: 0px !important;
    }
  }
`;
const TaskEmployee = styled.div`
  cursor: pointer;
  font-weight: ${opts => opts.selected ? 'bold' : 'normal'};
  :hover {
    background: ${colors.grey1};
  }
`;


export default class Pipeline extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      popover: null
    };
    this.onJobStageClose = this.onJobStageClose.bind(this);
    this.onJobStageToggle = this.onJobStageToggle.bind(this);
    this.onJobStageDone = this.onJobStageDone.bind(this);
  }
  onJobStageClose() {
    this.setState({
      popover: null
    });
  }
  onJobStageToggle(jobId, stage, selected, ref) {
    if (this.state.popover !== null && this.state.popover.ref === ref) {
      this.setState({
        popover: null
      });
    } else {
      const employees = this.props.employeeLists[stage];
      this.setState({
        popover: {
          jobId,
          stage,
          employees,
          selected,
          ref
        }
      });
    }
  }
  onJobStageDone(jobId, stage, done) {
    const {onSetStageDone} = this.props;
    onSetStageDone(jobId, stage, done);
  }
  onJobStageEmployeeSelect(employee) {
    const {popover:{jobId, stage}} = this.state;
    const {onSetStageEmployee} = this.props;
    onSetStageEmployee(jobId, stage, employee);
    this.setState({popover: null});
  }
  render() {
    const {popover} = this.state;
    const {
      notFound,
      incoming,
      inProgress,
      outgoing
    } = this.props;
    return (
      <React.Fragment>
        <Container className='flex-grow-1 px-3 pt-3'>
          <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
            <h5 className='mb-0'>Production pipeline</h5>
            <LocationSelect />
          </div>
          {notFound === false && (
            <ColumnContainer className='row no-gutters'>
              <Column className='col-12 col-lg-4 pr-lg-2'>
                <h6 className='ml-lg-3 mb-2'>Incoming ({incoming.length})</h6>
                {
                  incoming.map((jobId, i) => (
                    <Job
                      key={i}
                      jobId={jobId}
                      onStageToggle={this.onJobStageToggle}
                      onStageDone={this.onJobStageDone}
                    />
                  ))
                }
              </Column>
              <Column className='col-12 col-lg-4 px-lg-2'>
                <h6 className='ml-lg-3 mb-2'>In progress ({inProgress.length})</h6>
                {
                  inProgress.map((jobId, i) => (
                    <Job
                      key={i}
                      jobId={jobId}
                      onStageToggle={this.onJobStageToggle}
                      onStageDone={this.onJobStageDone}
                    />
                  ))
                }
              </Column>
              <Column className='col-12 col-lg-4 pl-lg-2'>
                <h6 className='ml-lg-3 mb-2'>Outgoing ({outgoing.length})</h6>
                {
                  outgoing.map((jobId, i) => (
                    <Job
                      key={i}
                      jobId={jobId}
                      onStageToggle={this.onJobStageToggle}
                      onStageDone={this.onJobStageDone}
                    />
                  ))
                }
              </Column>
            </ColumnContainer>
          )}
        </Container>
        {popover &&
          <Popover
            target={popover.ref}
            onClose={this.onJobStageClose}
          >
            <TaskEmployee
              className='text-secondary p-2'
              selected={popover.selected === null}
              onClick={this.onJobStageEmployeeSelect.bind(this, null)}
            >N/A</TaskEmployee>
            {popover.employees.map((e, i) => (
              <TaskEmployee
                key={i}
                className='p-2'
                selected={popover.selected === e.id}
                onClick={this.onJobStageEmployeeSelect.bind(this, e.id)}
              >{e.name}</TaskEmployee>
            ))}
          </Popover>
        }
      </React.Fragment>
    );
  }
};
