import moment from 'moment';
import numeral from 'numeral';

import TruncateString from 'utils/truncate-string.js';


export default (state = {notFound: null, employeeId: null}, action) => {
  switch (action.type) {
    case 'ADMIN-TECHNICIAN-COMMISSIONS-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        tasks: null
      };
    case 'ADMIN-TECHNICIAN-COMMISSIONS-SET-NULL':
      return {
        notFound: null,
        tasks: null,
        employeeId: null,
      };
    case 'ADMIN-TECHNICIAN-COMMISSIONS-CHANGE-EMPLOYEE-ID':
      const {employeeId} = action;
      return {
        ...state,
        employeeId
      };
    case 'ADMIN-TECHNICIAN-COMMISSIONS-SET':
      const {
         chargesGst,
         gstRate,
         invoices: _invoices,
         outstandingTasks: _outstandingTasks,
      } = action;
      const invoices = _invoices.reduce((acc, i) => {
        i.createDateStr = moment(i.createDate * 1000).format('DD-MM-YYYY');
        i.payDateStr = i.payDate ? moment(i.payDate * 1000).format('DD-MM-YYYY') : '–';
        i.tasks.forEach(t => {
          t.makeModel = TruncateString([t.make || '', t.model || ''].join(' '));
          t.amountStr = numeral(t.amount / 100).format('($0,0.00)');
        });
        if (chargesGst === true) {
          i.subtotalStr = numeral(i.total / 100).format('$0,0.00');
          i.gstStr = numeral(i.total / 100 * gstRate / 100).format('$0,0.00');
          i.totalStr = numeral(i.total / 100 * (100 + gstRate) / 100).format('$0,0.00');
        } else {
          i.totalStr = numeral(i.total / 100).format('$0,0.00');
        }
        acc.push(i);
        return acc;
      }, []);
      const {total: totalTasks, tasks: outstandingTasks} = _outstandingTasks.reduce((acc, t) => {
        t.selected = t.canInvoice && t.amount !== null;
        if (t.selected === true) {
          acc.total += t.amount;
        }
        acc.tasks.push({
          ...t,
          disabled: !t.canInvoice,
          doneDateStr: moment(t.doneDate * 1000).format('DD-MM'),
          amountStr: t.amount !== null ? numeral(t.amount / 100).format('$0,0.00') : '-',
          adjustmentStr: t.adjustment ? numeral(t.adjustment / 100).format('($0,0.00)') : null,
          makeModel: TruncateString([t.make || '', t.model || ''].join(' ')),
        });
        return acc;
      }, {total: 0, tasks: []});
      return {
        ...state,
        notFound: false,
        chargesGst,
        invoices,
        outstandingTasks,
        subtotalTasks: numeral(totalTasks / 100).format('$0,0.00'),
        gstTasks: numeral(totalTasks / 100 * (state.chargesGst ? (state.gstRate / 100) : 0)).format('$0,0.00'),
        totalTasks: numeral(totalTasks / 100 * (chargesGst ? (100 + gstRate) / 100 : 1)).format('$0,0.00'),
      };
    case 'ADMIN-TECHNICIAN-COMMISSIONS-UPDATE':
    {
      const {invoiceId, payDate, rejectDate} = action;
      return {
        ...state,
        invoices: state.invoices.reduce((acc, i) => {
          if (i.id !== invoiceId) {
            acc.push(i);
          } else if (rejectDate === null) {
            acc.push({
              ...i,
              payDate,
              payDateStr: moment(payDate * 1000).format('DD-MM-YYYY'),
            });
          }
          return acc;
        }, [])
      };
    }
    default:
      return state;
  }
};
