import numeral from 'numeral';


const COLUMNS = [
  {id: 'name', title: 'Employee'},
  {id: 'amount', type: 'money', title: 'Amount'},
];

export default (state = {notFound: null, report: null}, action) => {
  switch (action.type) {
    case 'ADMIN-REPORTS-TECH-PAYMENTS-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        report: null
      };
    case 'ADMIN-REPORTS-TECH-PAYMENTS-SET-NULL':
      return {
        ...state,
        notFound: null,
        report: null,
      };
    case 'ADMIN-REPORTS-TECH-PAYMENTS-SET':
      {
        const {report} = action;
        const columns = COLUMNS.map(c => c.title);
        const rows = report.reduce((acc, r, i) => {
          const values = [];
          COLUMNS.forEach(({id, type, title}) => {
            let v = r[id];
            if (type === 'money') {
              v = numeral(v / 100).format('$0,0.00');
            }
            values.push(v);
          });
          acc.push(values);
          return acc;
        }, []);
        return {
          ...state,
          notFound: false,
          report: {
            columns,
            rows,
          },
        };
      }
    default:
      return state;
  }
};
