

export default (state = {notFound: null, jobId: null, jobNumber: null}, action) => {
  switch (action.type) {
    case 'JOB-LOCATIONS-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-LOCATIONS-SET-FOUND':
      const{jobId, jobNumber, flagged, vpp, vip, importedFromArms, canAddLocations} = action;
      return {
        ...state,
        notFound: false,
        jobId,
        jobNumber,
        flagged,
        vpp,
        vip,
        importUrl: importedFromArms ?
          `https://www.arms1.com.au/search?search-text=${jobNumber}` :
          null,
        canAddLocations: canAddLocations,
      };
    case 'JOB-LOCATIONS-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-LOCATIONS-SET':
      {
        const {locations} = action;
        return {
          ...state,
          locations: locations.map(l => ({
            ...l,
            currentInDate: l.inDate,
            currentOutDate: l.outDate
          }))
        };
      }
    default:
      return state;
  }
};
