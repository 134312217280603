import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import env from 'env';
import {withApi} from 'components/API.jsx';
import AssessmentInbox from './component.jsx';


const assessmentInbox = (API, history) => {
  return dispatch => {
    const url = new URL(`${env.API_SERVER}/v1/internal/assessment-inbox`)
    dispatch({type: 'LOADING-INC'});
    return API.request(url, {method: 'GET'})
      .then(res => {
        if (res.error === true || res.jobId === null) return false;
        const {jobId, numOpenJobs} = res;
        dispatch({
          type: 'ASSESSMENT-INBOX-SET-COUNT',
          numOpenJobs: numOpenJobs,
        });
        history.push(`/job/${jobId}`);
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  }
};

const mapStateToProps = (state, props) => {
  const {authentication: {activeProfile, profiles}, assessmentInbox} = state;
  const {profile: {abilities: {canViewAssessmentsInbox}}} = profiles[activeProfile];
  return {
    canViewAssessmentsInbox,
    numOpenJobs: assessmentInbox
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onAssessmentInbox: () => dispatch(assessmentInbox(props.API, props.history)),
});

export default withRouter(
  withApi(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AssessmentInbox)
  )
);

