import moment from 'moment';
import numeral from 'numeral';


export default (state = {notFound: null, creditorId: null}, action) => {
  switch (action.type) {
    case 'ADMIN-CREDITOR-PAYMENTS-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        lines: null
      };
    case 'ADMIN-CREDITOR-PAYMENTS-SET-NULL':
      return {
        notFound: null,
        lines: null,
        creditorId: null,
      };
    case 'ADMIN-CREDITOR-PAYMENTS-CHANGE-CREDITOR-ID':
      const {creditorId} = action;
      return {
        ...state,
        creditorId
      };
    case 'ADMIN-CREDITOR-PAYMENTS-SET':
      const {invoices, gstRate} = action;
      const year = moment().year();
      const {selectedCount, total, lines} = invoices.reduce((acc, i) => {
        const invoiceDate = i.invoiceDate ? moment(i.invoiceDate * 1000) : null;
        const invoiceDateStr = invoiceDate ?
          invoiceDate.format(
            invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
          ) : '\u2011';
        const processDate = i.processDate ? moment(i.processDate * 1000) : null;
        const processDateStr = processDate ?
          processDate.format(
            processDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
          ) : '\u2011';
        const payDate = i.payDate ? moment(i.payDate * 1000) : null;
        const payDateStr = payDate ?
          payDate.format(
            payDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
          ) : '\u2011';
        if (i.creditId !== undefined) {
          const creditDate = i.creditDate ? moment(i.creditDate * 1000) : null;
          const creditDateStr = creditDate ?
            creditDate.format(
              creditDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
            ) : '\u2011';
          acc.lines.push({
            ...i,
            type: 'credit',
            invoiceDateStr,
            creditDateStr,
            processDateStr,
            payDateStr,
            total: -i.total,
            totalStr: numeral(-i.total / 100 * (100 + gstRate) / 100).format('$0,0.00'),
            selected: false
          });
        } else {
          acc.lines.push({
            ...i,
            type: 'invoice',
            invoiceDateStr,
            processDateStr,
            payDateStr,
            totalStr: numeral(i.total / 100 * (100 + gstRate) / 100).format('$0,0.00'),
            selected: false
          });
        }
        return acc;
      }, {selectedCount: 0, total: 0, lines: []});
      return {
        ...state,
        gstRate,
        notFound: false,
        lines,
        selectedCount,
        total,
        totalStr: numeral(total / 100 * (100 + gstRate) / 100).format('$0,0.00'),
      };
    case 'ADMIN-CREDITOR-PAYMENTS-SET-PAID':
    {
      const {lines: _lines} = action;
      const {invoiceIndex, creditIndex} = _lines.reduce((acc, l) => {
        if (l.creditId !== null && l.creditId !== undefined) {
          acc.creditIndex[l.creditId] = l.payDate;
        } else {
          acc.invoiceIndex[l.invoiceId] = l.payDate;
        }
        return acc;
      }, {invoiceIndex: {}, creditIndex: {}});
      const lines = state.lines.reduce((acc, l) => {
        if (l.type === 'credit' && creditIndex.hasOwnProperty(l.creditId)) {
          const payDate = moment(creditIndex[l.creditId] * 1000);
          const payDateStr = payDate.format('DD-MM');
          const line = {
            ...l,
            payDate: creditIndex[l.creditId],
            payDateStr
          };
          acc.push(line);
        } else if (l.type === 'invoice' && invoiceIndex.hasOwnProperty(l.invoiceId)) {
          const payDate = moment(invoiceIndex[l.invoiceId] * 1000);
          const payDateStr = payDate.format('DD-MM');
          const line = {
            ...l,
            payDate: invoiceIndex[l.invoiceId],
            payDateStr
          };
          acc.push(line);
        } else {
          acc.push(l);
        }
        return acc;
      }, []);
      return {
        ...state,
        lines,
      }
    }
    case 'ADMIN-CREDITOR-PAYMENTS-TOGGLE':
    {
      const {index} = action;
      const {selectedCount, total, lines} = state.lines.reduce((acc, l, i) => {
        let item = l;
        if (
          (index === null || i === index) &&
          item.processDate !== null &&
          item.payDate === null
        ) {
          item = {
            ...l,
            selected: !l.selected
          };
        }
        if (item.selected === true) {
          acc.selectedCount++;
          if (l.total !== null) {
            acc.total += l.total;
          }
        }
        acc.lines.push(item);
        return acc;
      }, {selectedCount: 0, total: 0, lines: []});
      return {
        ...state,
        lines,
        selectedCount,
        total,
        totalStr: numeral(total / 100 * (100 + state.gstRate) / 100).format('$0,0.00'),
      }
    }
    default:
      return state;
  }
};
