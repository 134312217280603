import numeral from 'numeral';
import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import uuidv4 from 'uuid/v4';

import colors from 'colors';

import Modal from 'components/Modal';


const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  padding: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;
const Input = styled.input`
  height: inherit !important;
`;
const Mail = styled(Icons.Mail)`
  stroke: ${colors.primary};
`;
const Send = styled(Icons.Send)`
  stroke: #fff;
`;
const Printer = styled(Icons.Printer)`
  stroke: ${colors.primary};
`;

const isInCarDebtor = (debtors, debtorId) => {
  return debtors[debtorId] && debtors[debtorId].name.includes('INCAR INSTALLATIONS')
}

export default class Print extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      id: uuidv4(),
      formOpen: false,
      to: '',
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onEmail = this.onEmail.bind(this);
    this.onPrint = this.onPrint.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open === true) {
      this.setState({
        id: uuidv4(),
        formOpen: false,
        to: '',
      });
    //  this.onPrint();
    }
  }
  onChangeEmail(e) {
    this.setState({
      to: e.target.value,
    });
  }
  onEmail() {
    const {id, formOpen, to} = this.state;
    const {jobId, onLoad} = this.props;
    if (formOpen !== true) {
      const {
        debtors,
        header: {
          debtorId,
          email
        }
      } = this.props;
      const to = [debtorId && debtors[debtorId].email, email].filter(e => e).join(', ');
      this.setState({
        formOpen: true,
        to,
      });
    } else {
      const emails = to.split(/[,;\s]/g).filter(e => e.length);
      onLoad(jobId, id, emails)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered sending email.');
          } else {
            toast.success('Email successfully sent.');
          }
          //if (res === true) {
          //  this.onPrint();
          //}
        });
    }
  }
  onPrint() {
    //setTimeout(() => {
      window.print();
    //}, 1);
  }
  render() {
    if (this.props.open !== true) return null;
    const {
      company: {
        name: companyName,
        jurisdiction,
        abn,
        licenseNumber,
        address,
        phone,
        logo,
        bsb,
        accountNumber,
      },
      mode,
      quoteDate,
      debtors,
      header: {
        jobNumber,
        name,
        make,
        model,
        rego,
        vin,
        claimNumber,
        debtorId,
        category,
        pdrTotal: _pdrTotal,
        rrTotal: _rrTotal,
        repairTotal: _repairTotal,
        paintTotal: _paintTotal,
        partTotal: _partTotal,
        miscTotal: _miscTotal,
        categoryExtraTotal: _categoryExtraTotal,
        subtotal: _subtotal,
        gst: _gst,
        total: _total,
        jobSettings,
      },
      quote: {
        pdr,
        rr,
        repair,
        paint,
        part,
        sublet,
        misc
      },
      onUnload
    } = this.props;
    const {formOpen, to} = this.state;
    const rrTotal = numeral(_rrTotal / 100).format('$0,0.00');
    const repairTotal = numeral((_pdrTotal + _repairTotal) / 100).format('$0,0.00');
    const paintTotal = numeral(_paintTotal / 100).format('$0,0.00');
    const partTotal = numeral(_partTotal / 100).format('$0,0.00');
    const miscTotal = numeral(_miscTotal / 100).format('$0,0.00');
    const categoryExtraTotal = numeral(_categoryExtraTotal / 100).format('$0,0.00');
    const subtotal = numeral(_subtotal / 100).format('$0,0.00');
    const gst = numeral(_gst / 100).format('$0,0.00');
    const total = numeral(_total / 100).format('$0,0.00');
    const pdrs = pdr.filter(l => l.id !== null && l.deleted !== true);
    const rrs = rr.filter(l => l.id !== null && l.deleted !== true);
    const repairs = repair.filter(l => l.id !== null && l.deleted !== true);
    const paints = paint.filter(l => l.id !== null && l.deleted !== true);
    const parts = part.filter(l => l.id !== null && l.deleted !== true);
    const sublets = sublet.filter(l => l.id !== null && l.deleted !== true);
    const miscs = misc.filter(l => l.id !== null && l.deleted !== true);
    return (
      <Modal
        open={true}
        fullScreen={true}
      >
        <div className='modal-body'>
          <div className='d-none d-print-flex row mb-5'>
            <div className='col-12 col-sm-6 text-center text-sm-left'>
              {logo && <img src={logo} alt='logo' width="250" />}
            </div>
            <div className='col-12 col-sm-6 text-center text-sm-right text-uppercase'>
              <h3>{companyName}</h3>
              <div>{address}</div>
              <div>P: {phone}</div>
              <div>{jurisdiction === 'AU' && 'ABN:'}{jurisdiction === 'NZ' && 'GST #:'} {abn}</div>
              <div>LIC #: {licenseNumber}</div>
            </div>
          </div>
          <div className='d-flex flex-row justify-content-between align-items-center'>
            {mode === 'jobsheet' && (
              <h5 className='p-0'>Job #: {jobNumber}</h5>
            )}
            {mode === 'quote' && (
              <div>
                <h5 className='p-0'>Quote #{jobNumber}</h5>
                <div>Date: {quoteDate}</div>
              </div>
            )}
            <div className='d-flex flex-row d-print-none'>
              {mode === 'quote' && formOpen === true && (
                <div className='input-group'>
                  <Input
                    type='email'
                    className='form-control'
                    value={to}
                    onChange={this.onChangeEmail}
                  />
                  <div className='input-group-append'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={this.onEmail}
                    ><Send width={18} height={18} /></button>
                  </div>
                </div>
              )}
              {mode === 'quote' && formOpen !== true && (
                <button type='button' className='btn' onClick={this.onEmail}><Mail /></button>
              )}
              <button type='button' className='btn' onClick={this.onPrint}><Printer /></button>
              <button type='button' className='close ml-2' onClick={onUnload}><Icons.X /></button>
            </div>
          </div>
          <Card className='mt-5'>
            <div className='row'>
              <div className='col-1 text-right font-weight-bold'>Customer:</div>
              <div className='col-3'>{name}</div>
              <div className='col-1 text-right font-weight-bold'>Make/Model:</div>
              <div className='col-3'>{[make || '', model || ''].join(' ')}</div>
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-1 text-right font-weight-bold'>Rego:</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-3'>{rego}</div>)}

              {!isInCarDebtor(debtors, debtorId) && (<div className='col-1 text-right font-weight-bold'>VIN:</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-3'>{vin}</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-1 text-right font-weight-bold'>Insurer:</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-3'>{debtorId ? debtors[debtorId].name : 'N/A'}</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-1 text-right font-weight-bold'>Claim #:</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-3'>{claimNumber}</div>)}
            </div>
          </Card>
          {mode === 'quote' && !isInCarDebtor(debtors, debtorId) && (
            <Card className='mt-3'>
              <div className='text-center text-danger'>
                {jobSettings && jobSettings.pricingMethod === 'category' && category === 0 && (
                  <span>NON CATEGORY QUOTE</span>
                )}
                {jobSettings && jobSettings.pricingMethod === 'category' && category !== 0 && (
                  <span>CATEGORY {category}</span>
                )}
              </div>
              <div className='row'>
                <div className='col-1 text-right font-weight-bold'>R/R:</div>
                <div className='col-3'>{rrTotal}</div>
                <div className='col-1 text-right font-weight-bold'>Repair:</div>
                <div className='col-3'>{repairTotal}</div>
                <div className='col-1 text-right font-weight-bold'>Paint:</div>
                <div className='col-3'>{paintTotal}</div>
                <div className='col-1 text-right font-weight-bold'>Part:</div>
                <div className='col-3'>{partTotal}</div>
                <div className='col-1 text-right font-weight-bold'>Misc:</div>
                <div className='col-3'>{miscTotal}</div>
                <div className='col-1 text-right font-weight-bold'>Extras:</div>
                <div className='col-3'>{categoryExtraTotal}</div>
                <div className='col-1 text-right font-weight-bold'>Subtotal:</div>
                <div className='col-3'>{subtotal}</div>
                <div className='col-1 text-right font-weight-bold'>GST:</div>
                <div className='col-3'>{gst}</div>
                <div className='col-1 text-right font-weight-bold'>Total:</div>
                <div className='col-3'>{total}</div>
              </div>
            </Card>
          )}
          {pdrs.length !== 0 && (
            <Card className='mt-3'>
              <div className='row'>
                <div className='col-6 font-weight-bold'>PDR</div>
                <div className='col-2 font-weight-bold'>Qty</div>
                <div className='col-2 font-weight-bold'>Size</div>
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Total</div>}
              </div>
              {pdrs.map((r, i) => (
                <div className='row' key={i}>
                  <div className='col-6'>{r.desc}</div>
                  <div className='col-2'>{r.qty}</div>
                  <div className='col-2'>{r.size}</div>
                  {mode === 'quote' && <div className='col-2'>${r.totalStr}</div>}
                </div>
              ))}
            </Card>
          )}
          {rrs.length !== 0 && !isInCarDebtor(debtors, debtorId) && (
            <Card className='mt-3'>
              <div className='row'>
                <div className='col-6 font-weight-bold'>R/R</div>
                <div className='col-2 font-weight-bold'>Hours</div>
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Rate</div>}
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Total</div>}
              </div>
              {rrs.map((r, i) => (
                <div className='row' key={i}>
                  <div className='col-6'>{r.description}</div>
                  <div className='col-2'>{r.hours}</div>
                  {mode === 'quote' && <div className='col-2'>${r.rate}</div>}
                  {mode === 'quote' && <div className='col-2'>${r.totalStr}</div>}
                </div>
              ))}
            </Card>
          )}
          {rrs.length !== 0 && isInCarDebtor(debtors, debtorId) && (
            <Card className='mt-3'>
              <div className='row'>
                <div className='col-10 font-weight-bold'>Labour</div>
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Total</div>}
              </div>
              {rrs.map((r, i) => (
                <div className='row' key={i}>
                  <div className='col-10'>{r.description}</div>
                  {mode === 'quote' && <div className='col-2'>${r.totalStr}</div>}
                </div>
              ))}
            </Card>
          )}
          {repairs.length !== 0 && (
            <Card className='mt-3'>
              <div className='row'>
                <div className='col-6 font-weight-bold'>Repair</div>
                <div className='col-2 font-weight-bold'>Hours</div>
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Rate</div>}
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Total</div>}
              </div>
              {repairs.map((r, i) => (
                <div className='row' key={i}>
                  <div className='col-6'>{r.description}</div>
                  <div className='col-2'>{r.hours}</div>
                  {mode === 'quote' && <div className='col-2'>${r.rate}</div>}
                  {mode === 'quote' && <div className='col-2'>${r.totalStr}</div>}
                </div>
              ))}
            </Card>
          )}
          {paints.length !== 0 && (
            <Card className='mt-3'>
              <div className='row'>
                <div className='col-6 font-weight-bold'>Paint</div>
                <div className='col-2 font-weight-bold'>Hours</div>
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Rate</div>}
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Total</div>}
              </div>
              {paints.map((r, i) => (
                <div className='row' key={i}>
                  <div className='col-6'>{r.description}</div>
                  <div className='col-2'>{r.hours}</div>
                  {mode === 'quote' && <div className='col-2'>${r.rate}</div>}
                  {mode === 'quote' && <div className='col-2'>${r.totalStr}</div>}
                </div>
              ))}
            </Card>
          )}
          {parts.length !== 0 && (
            <Card className='mt-3'>
              <div className='row'>
                <div className='col-4 font-weight-bold'>Part</div>
                <div className='col-2 font-weight-bold'>Part #</div>
                <div className='col-2 font-weight-bold'>Qty</div>
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Price</div>}
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Total</div>}
              </div>
              {parts.map((r, i) => (
                <div className='row' key={i}>
                  <div className='col-4'>{r.description}</div>
                  <div className='col-2'>{r.partNumber}</div>
                  <div className='col-2'>{r.qty}</div>
                  {mode === 'quote' && <div className='col-2'>${r.price}</div>}
                  {mode === 'quote' && <div className='col-2'>${r.totalStr}</div>}
                </div>
              ))}
            </Card>
          )}
          {miscs.length !== 0 && (
            <Card className='mt-3'>
              <div className='row'>
                <div className='col-8 font-weight-bold'>Misc</div>
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Price</div>}
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Total</div>}
              </div>
              {miscs.map((r, i) => (
                <div className='row' key={i}>
                  <div className='col-8'>{r.description}</div>
                  {mode === 'quote' && <div className='col-2'>${r.price}</div>}
                  {mode === 'quote' && <div className='col-2'>${r.totalStr}</div>}
                </div>
              ))}
            </Card>
          )}
          {sublets.length !== 0 && (
            <Card className='mt-3'>
              <div className='row'>
                <div className='col-8 font-weight-bold'>Sublet</div>
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Price</div>}
                {mode === 'quote' && <div className='col-2 font-weight-bold'>Total</div>}
              </div>
              {sublets.map((r, i) => (
                <div className='row' key={i}>
                  <div className='col-8'>{r.description}</div>
                  {mode === 'quote' && <div className='col-2'>${r.price}</div>}
                  {mode === 'quote' && <div className='col-2'>${r.totalStr}</div>}
                </div>
              ))}
            </Card>
          )}
          {isInCarDebtor(debtors, debtorId) && (<div className='px-3 mt-3'>
            <table className='w-100'>
              <tbody>
                <tr>
                  <td className='font-weight-bold text-right px-3'>Subtotal</td>
                  <td width='10%'>{subtotal}</td>
                </tr>
                <tr>
                  <td className='font-weight-bold text-right px-3'>GST</td>
                  <td width='10%'>{gst}</td>
                </tr>
                <tr>
                  <td className='font-weight-bold text-right px-3'>Total payable</td>
                  <td width='10%'>{total}</td>
                </tr>
              </tbody>
            </table>
          </div>)}
          {isInCarDebtor(debtors, debtorId) && (<div>
            <div className='mt-5 text-center'>
              Please make payment to the following account:
            </div>
            <div className='row no-gutters'>
              <div className='col-6 text-right pr-1'>Account Name:</div>
              <div className='col-6 text-left pl-1'>{companyName}</div>
            </div>
            <div className='row no-gutters'>
              <div className='col-6 text-right pr-1'>Account BSB:</div>
              <div className='col-6 text-left pl-1'>{bsb}</div>
            </div>
            <div className='row no-gutters'>
              <div className='col-6 text-right pr-1'>Account #:</div>
              <div className='col-6 text-left pl-1'>{accountNumber}</div>
            </div>
          </div>)}
        </div>
      </Modal>
    );
  }
};
