import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import CreditorInvoices from './component.jsx';


const search = (API, creditorId, invoiceNumber) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    const url = new URL(`${env.API_SERVER}/v1/internal/creditor-invoices/${creditorId}`);
    url.searchParams.append('invoiceNumber', invoiceNumber);
    return API.request(url, {method: 'GET'})
    //return API.request(
    //  `${env.API_SERVER}/v1/internal/creditor-invoices`,
    //  {
    //    method: 'POST',
    //    headers: {
    //      'Content-type': 'application/json'
    //    },
    //    body: JSON.stringify({creditorId, invoiceNumber})
    //  }
    //)
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-CREDITOR-INVOICES-SET-NOT-FOUND'
          });
          return null;
        }
        const {params: {generalSettings: {gstRate}}} = getState();
        dispatch({
          type: 'ADMIN-CREDITOR-INVOICES-SET',
          data: resp,
          gstRate,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-CREDITOR-INVOICES-SET-NULL'
});

const changeInvoiceNumber = invoiceNumber => ({
  type: 'ADMIN-CREDITOR-INVOICES-CHANGE-INVOICENO',
  invoiceNumber,
});

const changeCreditorId = creditorId => ({
  type: 'ADMIN-CREDITOR-INVOICES-CHANGE-CREDITOR-ID',
  creditorId,
});

const processInvoice = (API, creditorId, invoiceId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/creditor-invoices/${creditorId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({itemType: 'invoice', invoiceId})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'ADMIN-CREDITOR-INVOICES-SET-INVOICE-PROCESSED',
          processDate: res.processDate
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const processCredit = (API, creditorId, invoiceId, creditId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/creditor-invoices/${creditorId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({itemType: 'credit', invoiceId, creditId})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'ADMIN-CREDITOR-INVOICES-SET-CREDIT-PROCESSED',
          creditId,
          processDate: res.processDate
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    adminCreditorInvoices: {notFound, creditorId, invoiceNumber, data},
    params: {
      creditors: {list: creditors},
    }
  } = state;
  return {
    notFound,
    creditorId,
    invoiceNumber,
    data,
    creditors,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onSearch: (creditorId, invoiceNumber) => dispatch(search(API, creditorId, invoiceNumber)),
  onUnload: () => dispatch(unload()),
  onChangeInvoiceNumber: invoiceNumber => dispatch(changeInvoiceNumber(invoiceNumber)),
  onChangeCreditorId: creditorId => dispatch(changeCreditorId(creditorId)),
  onProcessInvoice: (creditorId, invoiceId) =>
    dispatch(processInvoice(API, creditorId, invoiceId)),
  onProcessCredit: (creditorId, invoiceId, creditId) =>
    dispatch(processCredit(API, creditorId, invoiceId, creditId)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreditorInvoices)
);
