import React from 'react';

import colors from 'colors';

import Field from 'components/Field.jsx';


export default class SettingsLightTunnel extends React.Component {
  constructor(opts) {
    super(opts);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onChange(field, e) {
    this.props.onChange({
      [field]: e,
      [`${field}Error`]: false
    });
  }
  onToggle(field, e) {
    this.props.onChange({
      [field]: e.target.checked
    });
  }
  onDelete() {
    const {
      onDelete, id
    } = this.props;
    onDelete(id);
  }
  onSave() {
    const {
      onSave,
      id, name,
    } = this.props;
    onSave(id, {name});
  }
  render() {
    console.log('settingslighttunnel:render');
    const {onClose} = this.props;
    const {
      id,
      name, nameError,
      token,
    } = this.props;
    return (
      <React.Fragment>
        <div className='modal-body'>
          <Field
            title='Name'
            background={colors.grey1}
            uppercaseOnly={true}
            value={name}
            error={nameError}
            onChange={this.onChange.bind(this, 'name')} />
          <Field
            title='Token'
            background={colors.grey1}
            readOnly={true}
            value={token}
          />
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button
              type='button'
              className='btn btn-danger mr-auto'
              onClick={this.onDelete}
            >Delete</button>
          }
          <button
            type='button'
            className='btn btn-secondary'
            onClick={onClose}
          >Close</button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onSave}
          >Save</button>
        </div>
      </React.Fragment>
    );
  }
};
