import React from 'react';

export default {
  width: 604,
  height: 1027,
  template: (
    <g>
      <path d='M427.5,127.8 L431.8,124.6 L442.3,116.8 C442.9,116.2 444.6,114.9 445.2,113.9 C446.1,112.6 446.8,111.4 447.3,109.9 C448.5,105.7 449.7,100.4 450.9,94.6 L452.4,86 C453.2,82.3 454.4,72.9 454.5,69.2 C455.3,44 453,33.7 452.2,14.6 C452.2,14.4 452.2,14.2 452.2,13.9 L452.2,7.5 L452.9,7.3 C453.6,7 454,6.3 453.8,5.7 C453.4,4.1 452.9,3.3 451.7,2.8 L450.5,1.4 C449.7,0.5 448.3,0.3 447.3,0.9 L440.9,4.6 C440,5.1 438.9,5.4 437.7,5.5 L412.4,6.4 L399,6.7 C394.8,6.5 386.8,3.9 380.7,3.4 L370.4,3.3 C370.4,3.3 355.3,3.3 351,3.3 L315.7,3.3 C310.3,3.3 303.5,3.3 297.7,3.3 C291.9,3.3 285.1,3.3 279.7,3.3 L244.4,3.3 C240.1,3.3 225,3.3 225,3.3 L214.7,3.4 C208.6,3.9 200.6,6.5 196.4,6.7 L183.1,6.4 L157.8,5.5 C156.7,5.5 155.6,5.2 154.6,4.6 L148.2,0.9 C147.2,0.3 145.8,0.5 145,1.4 L143.8,2.8 C142.6,3.3 142.1,4.1 141.7,5.7 C141.5,6.4 141.9,7.1 142.6,7.3 L143.3,7.5 L143.3,13.9 C143.3,14.1 143.3,14.3 143.3,14.6 C142.4,33.7 140.2,44 141,69.2 C141.1,73 142.3,82.3 143.1,86 L144.6,94.6 C145.8,100.4 147,105.8 148.2,109.9 C148.6,111.3 149.4,112.5 150.3,113.9 C150.9,114.9 152.6,116.2 153.2,116.8 L163.7,124.6 L168,127.8 C168,127.8 304,170.5 427.5,127.8 Z' stroke='#1D1D1B' fill='#FFFFFF' fillRule='nonzero'></path>
      <path d='M180.6,649.9 C163.7,724.4 159.4,794.9 162.4,834 L163.3,845.6 L169.6,894.9 L231.9,918.7 L369.8,916.8 L435.8,894.8 L441.4,845.5 L442.2,833.9 C444.8,794.8 437.9,725.3 421.7,649.8 C278.8,731 180.6,654.4 180.6,654.4' stroke='#1D1D1B' fill='#FFFFFF' fillRule='nonzero'></path>
      <g transform='translate(124.000000, 877.000000)'>
        <path d='M15.1,17.5 C10.4,16.7 4.4,0.2 4.4,0.2 C2.1,8.2 6.83897383e-14,30.7 0.2,35.8 L0.8,96.3 L0.9,117.3 C1.1,121.7 -0.7,141.3 3,144.8 L5.2,146 C7.9,147.2 14.8,147.1 17.4,147.2 L46.5,146.8 L90.7,146 C91.2,146 91.7,145.8 92.1,145.4 L93.4,144 C93.4,144 94.6,144 95.5,144 C99,144 116.7,144 128.7,144.1 L176.6,144.2 L177.5,144.2 L225,144.3 C237,144.3 254,144.3 257.5,144.3 C258.4,144.3 260.3,144.3 260.3,144.3 L261.6,145.7 C262,146.1 262.4,146.3 263,146.4 L307.2,147.4 L336.3,147.9 C339,147.9 345.9,147.9 348.5,146.8 L350.7,145.6 C354.4,142.1 352.7,122.5 352.9,118.1 L353,97.1 L353.7,36.6 C353.9,31.5 351.9,9 349.6,1 C349.6,1 345,14.7 337.5,17.8 C316.4,26.6 257.9,38.4 248.2,40.4 L108,41.8 C98.3,39.7 39.8,22 16.6,17.2' stroke='#1D1D1B'></path>
        <path d='M339.7,17.7 L336.9,31.9 C336.2,35.3 334.3,38.2 331.7,39.9 C322.6,45.7 311.3,50.1 299.1,52.5 L297.7,51.2 C296.9,51.4 296,51.5 295,51.6 C287.9,52.5 277.9,52.5 269.8,52.4 L264.4,42.8 C271.5,38.8 283.8,33.7 298.1,28.9 C311,24.5 325.5,20.3 339,17.2 C339.2,17.2 339.3,17.2 339.4,17.3 C339.7,17.5 339.7,17.6 339.7,17.7 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M14.3,17.2 L17,31.4 C17.6,34.8 19.5,37.7 22.2,39.4 C31.2,45.3 42.6,49.6 54.7,52.1 L56.1,50.8 C56.9,51 57.8,51.1 58.8,51.2 C65.9,52.1 75.9,52.1 84,52.1 L89.4,42.5 C82.3,38.5 70,33.3 55.8,28.4 C42.9,23.9 28.4,19.7 14.9,16.6 C14.7,16.6 14.6,16.6 14.5,16.7 C14.3,16.9 14.2,17 14.3,17.2 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M350,137.1 C347.7,138.6 343.1,139.1 336.7,139 L262.8,138.9' stroke='#000000' strokeWidth='0.5'></path>
        <path d='M3.7,136.5 C6,138 10.6,138.5 16.9,138.5 L90.8,138.6' stroke='#000000' strokeWidth='0.5'></path>
        <path d='M246,103.4 C251.9,99.6 260.6,94.4 269.1,92.4 C295.1,86.8 327.9,79.3 334.1,80.9 C341.2,83.3 343.1,104.2 343.1,109.9 C343.1,113.2 334.7,122.9 326.5,125.4 C313.2,128.8 278.1,130.2 273.5,128.3 C269.9,127.2 262.1,114.6 256.5,105.2 C256.3,104.9 256.1,104.8 255.8,104.8 C251.7,105.3 244.7,104.2 246,103.4 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M107.8,103.2 C101.9,99.4 93.2,94.2 84.7,92.1 C58.7,86.4 26,78.8 19.7,80.4 C12.6,82.8 10.6,103.6 10.6,109.4 C10.6,112.7 18.9,122.4 27.1,125 C40.3,128.5 75.5,130 80.1,128.1 C83.7,127 91.5,114.4 97.2,105 C97.4,104.7 97.6,104.6 97.9,104.6 C102,105 109,104 107.8,103.2 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M260.5,141.1 C258.9,138.9 248.3,119.5 241,105.5 C237.7,107.5 236.1,108 231.4,108 L177,107.9 L176.6,107.9 L122.2,107.8 C117.6,107.8 115.9,107.3 112.6,105.3 C105.3,119.2 94.6,138.6 93,140.8 C91.5,143.9 95.9,143.9 99.3,143.9 L176.4,144 L176.8,144 L253.9,144.1 C257.5,144.2 262,144.2 260.5,141.1 Z' stroke='#000000' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M265.6,43.1 L269.3,49.7 L270.4,51.7 L273.7,51.7 C282.2,51.7 291.7,51.6 297.6,50.4 L298,50.3 L298.5,50.8 L299.4,51.6 C311.6,49.1 322.9,44.7 331.6,38.9 C333.8,37.4 335.4,35 336,32.1 L338.8,17.9 C312.5,24 280.1,35.1 265.6,43.1 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#EDEDED' fillRule='nonzero'></path>
        <ellipse stroke='#1D1D1B' strokeWidth='0.500000782' fill='#EDEDED' fillRule='nonzero' transform='translate(323.049325, 108.352111) rotate(-89.898686) translate(-323.049325, -108.352111) ' cx='323.049325' cy='108.352111' rx='11.0000172' ry='9.10001423'></ellipse>
        <path d='M88.3,42.8 L84.6,49.4 L83.5,51.4 L80.2,51.4 C71.7,51.4 62.2,51.2 56.3,50.1 L55.9,50 L55.4,50.5 L54.5,51.3 C42.3,48.8 31.1,44.3 22.3,38.5 C20.1,37 18.5,34.6 17.9,31.7 L15.2,17.5 C41.4,23.5 73.9,34.8 88.3,42.8 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#EDEDED' fillRule='nonzero'></path>
        <ellipse stroke='#1D1D1B' strokeWidth='0.500000782' fill='#EDEDED' fillRule='nonzero' transform='translate(30.691784, 107.861227) rotate(-89.898686) translate(-30.691784, -107.861227) ' cx='30.691784' cy='107.861227' rx='11.0000172' ry='9.10001423'></ellipse>
        <path d='M336.7,33.9 L341.9,35 C344,35.5 345.1,36.6 346.7,39.4 L353.4,52.6' stroke='#1D1D1B' strokeWidth='0.5'></path>
        <path d='M17.2,33.3 L12.1,34.4 C10,34.9 8.9,36 7.4,38.7 L0.7,51.8' stroke='#1D1D1B' strokeWidth='0.5'></path>
        <rect stroke='#1D1D1B' strokeWidth='0.500000503' fill='#737373' transform='translate(176.652069, 100.637195) rotate(-89.918706) translate(-176.652069, -100.637195) ' x='165.052057' y='50.6371448' width='23.2000234' height='100.000101'></rect>
        <path d='M222.2,89.9 L131,89.7 C129.2,89.7 127.8,90.8 127.8,92.2 L127.8,108.6 C127.8,110 129.2,111.1 131,111.1 L222.2,111.3 C224,111.3 225.4,110.2 225.4,108.8 L225.4,92.4 C225.4,91.1 224,89.9 222.2,89.9 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#FFFFFF'></path>
      </g>
      <path d='M395.8,557.1 C386,511.4 391.7,395.9 397.3,305.4 L401.7,302.8 C396.8,393.5 390.4,509.6 400,556 C400,556.1 397.1,556.8 395.8,557.1 Z' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M206.5,557.1 C216.3,512.8 210.6,398.5 204.9,305.4 C204.9,305.4 200.5,302.9 200.5,302.9 C206.2,395.1 212,511.1 202.4,556.1 C202.3,556.1 205.1,556.8 206.5,557.1 Z' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M213.5,309.3 h21 v246.2 h-21 Z' stroke='#1D1D1B' strokeWidth='0.5'/>
      <path d='M366.5,309.3 h21 v246.2 h-21 Z' stroke='#1D1D1B' strokeWidth='0.5'/>
      <path d='M301.1,305.6 C301.1,305.6 225.1,305.8 206.6,305.6 C206,305.6 205.4,305.5 204.9,305.4 L202.5,304 L200.5,302.8 C199.4,287.7 198.9,263.1 198.9,247.2 C198.9,225.5 199.9,205 201,193.5 C201.2,191.6 202.4,190 204,189.3 C221.6,182.1 238,177.7 256.6,175.3 C266.3,174 276.7,173.3 288.4,173 L291.6,172.9 C293.8,172.9 296.3,172.9 298.7,172.9 L301,172.9 C304.2,172.9 307.4,172.9 310.4,173 L313.6,173.1 C325.3,173.4 335.7,174.1 345.3,175.4 C363.9,177.9 380.3,182.2 397.9,189.4 C399.6,190.1 400.8,191.7 400.9,193.6 C402,205.1 403,225.6 403,247.3 C403,263.2 402.4,287.8 401.4,302.9 L399.4,304.1 L397,305.5 C396.4,305.6 395.9,305.7 395.3,305.7 C377.1,305.8 315.5,305.7 301.1,305.6 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#808080' fillRule='nonzero'></path>
      <path d='M180.6,649.9 C180.6,646.8 181,643.6 181.7,640.6 L200.3,564 L202.2,556.1 L205.4,557 C238.1,563.3 265.4,562.1 301,562.1 C336.6,562.1 366,563.4 396.6,557 L399.8,556.1 L401.7,564 L420.3,640.6 C421,643.7 421.4,646.8 421.4,649.9 C421.4,665.3 412.1,679.7 397.8,686.4 C376.9,696.2 345.2,701 300.9,701 C256.6,701 224.8,696.2 204,686.4 C189.9,679.7 180.6,665.4 180.6,649.9 C180.6,649.9 180.6,649.9 180.6,649.9 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#808080' fillRule='nonzero'></path>
      <path d='M195.9,29.3 C203.5,38.4 204.7,42 211.4,42 L295,42 L297.8,42 L381.4,42 C388.1,42 389.3,38.3 396.9,29.3 C398.6,27.4 397.7,23.5 395.1,23.2 L384.6,21 C381,20.1 377.1,20 371,20 L297.7,20 L295,20 L221.7,20 C215.6,19.9 211.7,20 208.2,20.9 L197.7,23.1 C195.1,23.4 194.2,27.4 195.9,29.3 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
      <path d='M301.2,300.5 C301.2,300.5 231.1,300.7 214,300.5 C213.5,300.5 212.9,300.4 212.4,300.3 L210.2,299 C209.2,285.1 208.6,261.2 208.6,245.9 C208.6,225.9 209.5,207.6 210.4,197 C210.6,195.2 211.7,193.6 213.2,193 C228.9,186.6 243.6,182.6 260.3,180.4 C269.2,179.2 272.5,179 277.8,178.7 L283,182.2 C285.1,182.2 300.6,181.9 300.6,181.9 C303.5,181.9 315.4,182.1 318.2,182.2 L323.5,178.6 C328,178.8 332.9,179.1 341.8,180.3 C358.5,182.5 373.2,186.4 388.9,192.9 C390.5,193.5 391.6,195.1 391.7,196.9 C392.6,207.5 393.5,225.8 393.5,245.8 C393.5,261.1 393,285 391.9,298.9 L389.7,300.2 C389.2,300.3 388.7,300.4 388.1,300.4 C371.3,300.7 314.6,300.6 301.2,300.5 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#CFCFCF' fillRule='nonzero'></path>
      <path d='M301.1,694.7 C257.1,694.7 223.5,689.9 200.7,677.9 C190.4,672.5 182.6,654.8 184.6,645.8 L202.6,567.5 C202.9,566.2 203.7,565 204.9,564.3 C206.1,563.6 207.5,563.5 208.8,563.8 C241,569.8 269.9,568.3 301,568.3 C332.2,568.3 360.1,569.7 393.2,563.8 C394.6,563.6 396,563.6 397.1,564.3 C398.3,565 399.1,566.2 399.4,567.5 L417.4,645.8 C419.4,654.8 409,673 401.1,677.9 C378.6,692.1 347.5,694.8 301.1,694.7 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#CFCFCF' fillRule='nonzero'></path>
      <path d='M160.7,39.6 C155.6,39.6 154.7,39.6 153.5,40.8 L151.3,43.6 C150,45.4 150,46.8 150.2,52.8 L150.5,56.8 C150.8,60 153.3,60.8 157.5,61 L176.8,61.5 C189,61.5 193.3,60.3 189.9,55.4 L182.4,44.5 C180.6,42.3 179,40.6 173.1,40.2 C168.9,39.7 165,39.5 160.7,39.6 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
      <path d='M432,39.6 C437.1,39.6 438,39.6 439.2,40.8 L441.4,43.6 C442.7,45.4 442.7,46.8 442.5,52.8 L442.2,56.8 C441.9,60 439.4,60.8 435.2,61 L415.9,61.5 C403.7,61.5 399.4,60.3 402.8,55.4 L410.3,44.5 C412.1,42.3 413.7,40.6 419.6,40.2 C423.9,39.7 427.7,39.5 432,39.6 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
      <path d='M328.2,174 C323.4,179.6 319.7,182.3 318.5,182.3 L301.3,182 L300.6,182 L283.4,182.3 C282.2,182.3 278.5,179.7 273.7,174 C282,173.3 294.8,172.9 301.3,172.9 C307.5,172.9 319.5,173.2 328.2,174 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#808080' fillRule='nonzero'></path>
      <path d='M326.1,174.9 C323.7,177.6 319.3,181.1 318.4,181.1 L301.2,180.8 L300.5,180.8 L283.3,181.1 C282.5,181.1 278.3,177.7 275.9,175 C280.9,174.4 295.4,174 300.9,174 C306.4,174 320.3,174.3 326.1,174.9 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero'></path>
      <path d='M171.3,119.7 C168.8,118.2 168.7,116.2 168.8,114.3 L170.6,107.9 C171.6,105.3 175,103.6 176.9,102.6 C185.4,98.5 194.4,98 202,96.5 L186,127 C181.8,125.1 171.3,119.7 171.3,119.7 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#E11119' fillRule='nonzero'></path>
      <path d='M160.8,57.8 L160.1,42 L156.2,42 C154.8,42 152.6,43.5 152.6,45.1 C152.8,50.4 152.9,57.8 156.1,57.8 L160.8,57.8 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero'></path>
      <path d='M422.2,119.7 C424.7,118.2 424.8,116.2 424.7,114.3 L422.9,107.9 C421.9,105.3 418.5,103.6 416.6,102.6 C408.1,98.5 399.1,98 391.5,96.5 L407.5,126.9 C411.7,125.1 422.2,119.7 422.2,119.7 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#E11119' fillRule='nonzero'></path>
      <path d='M431.9,57.8 L432.6,42 L436.5,42 C437.9,42 440.1,43.5 440.1,45.1 C439.9,50.4 439.8,57.8 436.6,57.8 L431.9,57.8 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero'></path>
      <path d='M296.4,113.4 C286.7,113.4 255,113.4 255,113.4 C237.1,113.4 238.6,112.3 225.9,100.8 L219.1,94.9 L200.5,99.1' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M297.7,78.3 C293.6,78.3 262.6,77.7 239.4,78.9 C233.4,79.2 231.1,80.5 225.9,85.6 C223.4,88 220.3,92 219.2,94.8' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M198.2,89.1 C203.7,82.1 211.1,69 211,64.8 C211,60.3 194.6,36.1 188.9,33.7 C182.9,31.5 156.6,30.4 151.7,32.6 C149.2,33.9 149.6,40.3 150,46.4' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M296.3,113.4 C306,113.4 337.7,113.4 337.7,113.4 C355.6,113.4 354.1,112.3 366.8,100.8 L373.6,94.9 L392.2,99.1' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M295,78.3 C299.1,78.3 330.1,77.7 353.3,78.9 C359.3,79.2 361.6,80.5 366.8,85.6 C369.3,88 372.4,92 373.5,94.8' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M394.5,89.1 C389,82.1 381.6,69 381.7,64.8 C381.7,60.3 398.1,36.1 403.8,33.7 C409.8,31.5 436.1,30.4 441,32.6 C443.5,33.9 443.1,40.3 442.7,46.4' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M211.1,64.8 L382,64.8' stroke='#000000' strokeWidth='0.5'></path>
      <path d='M232,142.5 C218.9,156.6 213.4,166.7 202.3,190.6' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <path d='M370.1,142.6 C383.2,156.7 387.9,165.9 398.9,189.7' stroke='#1D1D1B' strokeWidth='0.5'></path>
      <rect stroke='#1D1D1B' strokeWidth='0.5' fill='#737373' x='254.5' y='85.8' width='83.9' height='20.7'></rect>
      <path d='M258.3,105.8 L334.7,105.8 C336.2,105.8 337.4,104.8 337.4,103.5 L337.4,88.8 C337.4,87.6 336.2,86.5 334.7,86.5 L258.3,86.5 C256.8,86.5 255.6,87.5 255.6,88.8 L255.6,103.5 C255.6,104.8 256.8,105.8 258.3,105.8 Z' stroke='#1D1D1B' strokeWidth='0.5' fill='#FFFFFF'></path>
      <g transform='translate(398.000000, 182.000000)' stroke='#1D1D1B'>
        <path d='M3.7,220.3 C7,192.4 29.4,139.1 39,109.4 L51.7,62 C51.7,61.9 59.1,16.3 59.2,16.3 C60,13.1 63.6,12.5 69.6,14.8 C71.1,15.4 72.7,15.5 74.2,15.2 C87,12.4 95.4,11.5 108.4,11.1 C110,11 111.6,10.5 112.9,9.5 C119.6,4.5 126.8,0.2 137.9,0.4 C160.6,0.6 175.5,-1.9 178.8,6.5 C184.2,18.7 190.4,55.6 191.3,64 C191.4,64.7 191.8,65.3 192.4,65.6 L194,66.4 C195.5,67.6 196.4,69.2 197.2,72.3 L199.7,84.5 L201.8,94.3 L191.6,94.3 C190.3,94.3 189,94.3 187.7,94.4 C184.2,94.5 180.8,94.8 177.6,95.3 C177.6,95.3 177.6,95.3 177.6,95.3 C95.8,105.1 98.1,223.7 182.5,225.7 C188.8,225.9 197.3,225.7 201.2,225.5 L203.4,225.4 L203.9,258 L205.1,488.5 L203.5,488.5 C88.1,482.6 92.1,631.3 200.2,623.4 L203.3,623.4 C203.3,650.7 201.9,663.7 199.7,691.9 C199.7,691.9 199.7,691.9 199.7,691.9 C199.6,693.1 198.7,694.1 197.5,694.4 L195.7,694.8 C194.5,695.1 193.2,695.2 192,695.2 L175,695.2 L146.7,694.9 C145.9,694.9 145.1,694.5 144.6,693.7 C144.4,693.3 144,693.1 143.6,693.2 C140.9,693.3 134.7,693.2 131.3,693 C128.2,692.9 125.2,691.8 122.8,689.8 C122,689.1 121.3,688.4 120.7,687.6 C101.4,662.7 84.6,609.9 79.3,570.8 C79.3,570.8 73.9,532 71.1,504.5 C70.9,502.6 70.7,501.9 70.6,500.1 C70.4,498.5 70.7,497.7 69.3,496.3 C67.7,495.5 21.5,415.7 9.3,368.3 C9.3,368.3 5.1,352.7 4.3,346.4 C1.4,324.2 -0.3,288.6 0.9,246.4 L3.7,220.3 Z' fill='#FFFFFF' fillRule='nonzero'></path>
        <path d='M168.5,34.7 C168.5,39.5 169,42.6 167.1,45.5 L164.2,49.6 C162.6,51.4 161.4,51.2 159.3,49.9 L148.9,43.5 C147.5,42.6 146.6,41 146.5,37.1 L145.8,15.6 C145.5,8.1 146.2,3.9 151.1,3.9 L158.2,4.1 C164.9,4.5 166.1,13.2 167,19.1 C168,25.1 168.5,28.5 168.5,34.7 Z' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M168.4,694.3 C166.2,693.9 164.3,692.4 163.5,690.3 L155.2,668.5 C154,665.3 154.6,659.5 158.9,656.6 L171.9,648.8 C174.1,647.5 176.9,648.1 178.6,650 L188.7,662.4 C190.1,664.1 190.8,666.2 190.7,668.3 L190.5,677.6 C190.4,680.7 188.4,683.5 185.5,684.5 L176.5,687.6 C174.5,688.3 173.8,688.2 173.8,689.4 L173.7,693.5 C173.7,693.9 173.5,694.3 173.2,694.6 C172.9,694.9 172.5,695 172,694.9 L168.4,694.3 Z' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M149.3,37.8 C149.3,37.5 149.5,37.3 149.8,37.3 L164.5,37.3 L165.3,38 L165.3,41.3 C165.3,41.7 164.1,42.9 163.6,42.9 C160.3,42.9 156.3,42.3 151.7,40.7 C150.4,40.3 149.5,40 149.3,38.3 C149.3,38.2 149.3,38 149.3,37.8 Z' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero'></path>
        <path d='M174.9,664.2 C179.1,664.2 182.5,665.8 182.5,666.2 C182.5,667.3 179.1,668.2 174.9,668.2 C170.7,668.2 167.3,667.3 167.3,666.2 C167.3,665.8 171.7,664.2 174.9,664.2 Z' strokeWidth='0.5' fill='#EDEDED' fillRule='nonzero'></path>
        <path d='M76.2,34.7 C77.2,37.3 79.1,38 81.1,38.3 L88,38.4 C90.9,38.2 93.5,35.5 94.9,34 C101.1,27.4 103.6,19.3 107,12.9 L72.2,19.4 C73.1,23.8 76.2,34.7 76.2,34.7 Z' strokeWidth='0.5' fill='#E11119' fillRule='nonzero'></path>
        <path d='M142.5,689.3 L133.8,667.1 L133.7,667.7 C133.7,667.4 133.6,667.1 133.6,666.7 C133.5,666.3 133.5,665.8 133.4,665.1 L119.8,636.5 L104.9,610.8 C104.8,610.6 104.5,610.4 104.3,610.4 L104.2,610.4 C104.1,609.7 103.9,609 103.8,608.4 L103.6,608 L102.9,608 L102.7,608.2 C102.3,608.7 101.8,611.4 102.6,615.2 L117,682.5 L117.1,682.7 C122.5,692.2 128,693.7 139,693.7 C139,693.7 139,693.7 139,693.7 C140.7,693.7 142.5,693.7 144.5,693.6 L145.6,693.6 L142.5,689.3 Z' fill='#EDEDED' fillRule='nonzero' strokeLinejoin='round'></path>
        <path d='M92.3,341.9 C92,342.1 91.6,342.1 91.3,341.9 C91,341.7 90.8,341.4 90.8,341.1 L87.5,293.1 C87.5,292.8 87.6,292.4 87.9,292.2 C88.2,292 88.5,291.9 88.9,292 L90.8,292.7 C93.8,293.8 95.9,296.5 96.1,299.6 L96.6,306.7 C96.7,307.6 96.6,308.5 96.3,309.3 L95.8,310.9 L95.8,327.7 L97.1,329.5 C97.7,330.3 98,331.3 98,332.2 L98,334 C98,336.9 96.4,339.6 93.8,341 L92.3,341.9 Z' strokeWidth='0.5'></path>
        <path d='M184.3,286 L189.3,468 C189.3,477.2 142.8,481.4 117.5,481.4 C109.4,481.4 99.6,481.8 92,479.2 L78,473.5' strokeWidth='0.5'></path>
        <ellipse strokeWidth='0.5' cx='174.9' cy='670.1' rx='10.6' ry='5.9'></ellipse>
        <path d='M78,473.6 L72,199.5 C72,199.5 72.7,192 64.9,191 C57,190 33.5,212.5 12,266 C9.5,279.5 -3.8,368 64.9,461.5 C74.5,471 78.1,476.6 78,473.6 Z' strokeWidth='0.5' fill='#808080' fillRule='nonzero'></path>
        <path d='M184.3,286 L177.2,286.4 C148.4,291.4 93.9,290.7 78.3,285.9 L70.8,283.9 L13.4,272 L11,272' strokeWidth='0.5'></path>
      </g>
      <g transform='translate(0.000000, 182.000000)' stroke='#1D1D1B'>
        <path d='M201.9,220.3 C198.6,192.4 176.2,139.1 166.6,109.4 L153.8,62 C153.8,61.9 146.4,16.3 146.3,16.3 C145.5,13.1 141.9,12.5 135.9,14.8 C134.4,15.4 132.8,15.5 131.3,15.2 C118.5,12.4 110.1,11.5 97.1,11.1 C95.5,11 93.9,10.5 92.6,9.5 C85.9,4.5 78.7,0.2 67.6,0.4 C44.9,0.6 30,-1.9 26.7,6.5 C21.3,18.7 15.1,55.6 14.2,64 C14.1,64.7 13.7,65.3 13.1,65.6 L11.5,66.4 C10,67.6 9.1,69.2 8.3,72.3 L5.8,84.5 L3.7,94.3 L14,94.3 C15.3,94.3 16.6,94.3 17.9,94.4 C21.4,94.5 24.8,94.8 28,95.3 C28,95.3 28,95.3 28,95.3 C109.8,105.1 107.5,223.7 23.1,225.7 C16.8,225.9 8.3,225.7 4.4,225.5 L2.2,225.4 L1.7,258 L0.5,488.5 L2.1,488.5 C117.5,482.6 113.5,631.3 5.4,623.4 L2.3,623.4 C2.3,650.7 3.7,663.7 5.9,691.9 C5.9,691.9 5.9,691.9 5.9,691.9 C6,693.1 6.9,694.1 8.1,694.4 L9.9,694.8 C11.1,695.1 12.4,695.2 13.6,695.2 L30.5,695.2 L58.8,694.9 C59.6,694.9 60.4,694.5 60.9,693.7 C61.1,693.3 61.5,693.1 61.9,693.2 C64.6,693.3 70.8,693.2 74.2,693 C77.3,692.9 80.3,691.8 82.7,689.8 C83.5,689.1 84.2,688.4 84.8,687.6 C104.1,662.7 120.9,609.9 126.2,570.8 C126.2,570.8 131.6,532 134.4,504.5 C134.6,502.6 134.8,501.9 134.9,500.1 C135.1,498.5 134.8,497.7 136.2,496.3 C137.8,495.5 184,415.7 196.2,368.3 C196.2,368.3 200.4,352.7 201.2,346.4 C204.1,324.2 205.8,288.6 204.6,246.4 L201.9,220.3 Z' fill='#FFFFFF' fillRule='nonzero'></path>
        <path d='M37,34.7 C37,39.5 36.5,42.6 38.4,45.5 L41.3,49.6 C42.9,51.4 44.1,51.2 46.2,49.9 L56.6,43.5 C58,42.6 58.9,41 59,37.1 L59.7,15.6 C60,8.1 59.3,3.9 54.4,3.9 L47.3,4.1 C40.6,4.5 39.4,13.2 38.5,19.1 C37.5,25.1 37,28.5 37,34.7 Z' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M37.1,694.3 C39.3,693.9 41.2,692.4 42,690.3 L50.3,668.5 C51.5,665.3 50.9,659.5 46.6,656.6 L33.6,648.8 C31.4,647.5 28.6,648.1 26.9,650 L16.8,662.4 C15.4,664.1 14.7,666.2 14.8,668.3 L15,677.6 C15.1,680.7 17.1,683.5 20,684.5 L29,687.6 C31,688.3 31.7,688.2 31.7,689.4 L31.8,693.5 C31.8,693.9 32,694.3 32.3,694.6 C32.6,694.9 33,695 33.5,694.9 L37.1,694.3 Z' strokeWidth='0.5' fill='#3C3C3B' fillRule='nonzero'></path>
        <path d='M56.2,37.8 C56.2,37.5 56,37.3 55.7,37.3 L41,37.3 L40.2,38 L40.2,41.3 C40.2,41.7 41.4,42.9 41.9,42.9 C45.2,42.9 49.2,42.3 53.8,40.7 C55.1,40.3 56,40 56.2,38.3 C56.2,38.2 56.2,38 56.2,37.8 Z' strokeWidth='0.5' fill='#AD110D' fillRule='nonzero'></path>
        <path d='M30.6,664.2 C26.4,664.2 23,665.8 23,666.2 C23,667.3 26.4,668.2 30.6,668.2 C34.8,668.2 38.2,667.3 38.2,666.2 C38.2,665.8 33.9,664.2 30.6,664.2 Z' strokeWidth='0.5' fill='#EDEDED' fillRule='nonzero'></path>
        <path d='M129.3,34.7 C128.3,37.3 126.4,38 124.4,38.3 L117.5,38.4 C114.6,38.2 112,35.5 110.6,34 C104.4,27.4 101.9,19.3 98.5,12.9 L133.3,19.4 C132.4,23.8 129.3,34.7 129.3,34.7 Z' strokeWidth='0.5' fill='#E11119' fillRule='nonzero'></path>
        <path d='M63,689.3 L71.7,667.1 L71.8,667.7 C71.8,667.4 71.9,667.1 71.9,666.7 C72,666.3 72,665.8 72.1,665.1 L85.7,636.5 L100.6,610.8 C100.7,610.6 101,610.4 101.2,610.4 L101.3,610.4 C101.4,609.7 101.6,609 101.7,608.4 L101.9,608 L102.6,608 L102.8,608.2 C103.2,608.7 103.7,611.4 102.9,615.2 L88.5,682.5 L88.4,682.7 C83,692.2 77.5,693.7 66.5,693.7 C64.8,693.7 63,693.7 61,693.6 L59.9,693.6 L63,689.3 Z' fill='#EDEDED' fillRule='nonzero' strokeLinejoin='round'></path>
        <path d='M113.2,341.9 C113.5,342.1 113.9,342.1 114.2,341.9 C114.5,341.7 114.7,341.4 114.7,341.1 L118,293.1 C118,292.8 117.9,292.4 117.6,292.2 C117.3,292 117,291.9 116.6,292 L114.7,292.7 C111.7,293.8 109.6,296.5 109.4,299.6 L108.9,306.7 C108.8,307.6 108.9,308.5 109.2,309.3 L109.7,310.9 L109.7,327.7 L108.4,329.5 C107.8,330.3 107.5,331.3 107.5,332.2 L107.5,334 C107.5,336.9 109.1,339.6 111.7,341 L113.2,341.9 Z' strokeWidth='0.5'></path>
        <path d='M21.3,286 L16.3,468 C16.3,477.2 62.8,481.4 88.1,481.4 C96.2,481.4 106,481.8 113.6,479.2 L127.6,473.5' strokeWidth='0.5'></path>
        <ellipse strokeWidth='0.5' cx='30.6' cy='670.1' rx='10.6' ry='5.9'></ellipse>
        <path d='M127.5,473.6 L133.5,199.5 C133.5,199.5 132.8,192 140.6,191 C148.4,190 172,212.5 193.5,266 C196,279.5 209.3,368 140.6,461.5 C131,471 127.5,476.6 127.5,473.6 Z' strokeWidth='0.5' fill='#808080' fillRule='nonzero'></path>
        <path d='M21.3,286 L28.4,286.4 C57.2,291.4 111.7,290.7 127.3,285.9 L134.8,283.9 L192.2,272 L194.6,272' strokeWidth='0.5'></path>
      </g>
    </g>
  ),
  panels: [
    {
      name: 'FRONT-BUMPER',
      transform: 'translate(123, 876)',
      paths: [
        'M354.4,98.7 L355.1,38.2 C355.3,33.3 353.4,10.7 351,2.4 L350.6,0.9 L350.1,2.4 C350.1,2.5 345.4,15.9 338.3,18.9 C337.8,19.1 337.3,19.3 336.8,19.5 C324.9,22.3 311.7,26.1 299.2,30.4 C299.1,30.4 299,30.5 298.9,30.5 C280.3,35 261.3,39 252.3,40.9 C250.9,41.2 249.8,41.4 249.1,41.6 L109,42.9 C105.2,42.1 94.6,39.1 81.2,35.2 C59.3,29 32,21.2 17.7,18.2 L17.6,18.5 C17,18.4 16.5,18.2 15.9,18.1 C15.7,18 15.4,18.1 15.2,18.3 C15.2,18.3 15.2,18.3 15.2,18.3 C11.7,16.1 7.4,5.8 5.9,1.7 L5.4,0.3 L5,1.8 C2.6,10.1 0.6,32.7 0.8,37.6 L1.4,98.1 L1.5,119.1 C1.5,120.1 1.5,121.8 1.4,124 C1.1,134.2 1,144.3 3.8,147 L6.1,148.3 C8.6,149.4 14.4,149.4 17.5,149.5 L18.5,149.5 L47.6,149.1 L91.8,148.3 C92.4,148.3 93.1,148 93.5,147.5 L94.6,146.2 L96.5,146.2 C100,146.2 117.7,146.2 129.7,146.3 L225.9,146.5 C237,146.5 252.4,146.5 257.4,146.5 L261,146.5 L262.1,147.8 C262.5,148.3 263.2,148.6 263.8,148.6 L308,149.6 L337.2,150.1 L338.2,150.1 C341.3,150.1 347.2,150 349.6,148.9 L351.8,147.7 L351.9,147.6 C354.7,144.9 354.7,134.8 354.3,124.6 C354.2,122.4 354.2,120.7 354.2,119.7 L354.4,98.7 Z'
      ]
    },
    {
      name: 'BONNET',
      transform: 'translate(161, 649)',
      paths: [
        'M19.6,0.9 C2.7,75.4 -1.6,145.9 1.4,185 L2.3,196.6 L8.6,245.9 L70.9,269.7 L208.8,267.8 L274.8,245.8 L280.4,196.5 L281.2,184.9 C283.8,145.8 276.9,76.3 260.7,0.8 C260.7,0.8 262,29.4 231.5,39.7 C201,50 183,52.1 135,52 C91.3,51.5 57.3,45.9 37.5,34.2 C27.8,28 19.4,14.6 19.6,0.9 Z'
      ]
    },
    {
      name: 'PLENUM',
      transform: 'translate(170, 649)',
      paths: [
        'M262.1,56 C259.7,42.2 254.8,15.2 251.7,0.9 C251.7,0.9 253,29.5 222.5,39.8 C192,50.1 174,52.1 126,52 C82.3,51.5 48.3,45.9 28.5,34.2 C18.7,28 10.4,14.6 10.6,0.9 C7.3,15.7 3.2,41.8 0.8,56 C26.7,73.3 151.1,121.3 262.1,56 Z'
      ]
    },
    {
      name: 'LHF-GUARD',
      transform: 'translate(469, 651)',
      paths: [
        'M61.7,13.5 C118.4,10.6 121,0 121,0 L134.9,0 L135,20 L133.4,20 C18,14 22,163 130.1,155 L133.2,155 C133.2,182 132,195.3 130,223.6 C130,223.6 130,223.6 130,223.6 C130,224.8 128.9,225.8 127.7,226.1 L125.8,226.5 C124.6,226.8 123.2,226.9 122,226.9 L105,226.9 L76.7,226.6 C75.9,226.6 75.1,226.1 74.6,225.4 C74.4,225 74,224.8 73.6,224.9 C70.9,225 64.7,224.9 61.3,224.7 C58.2,224.6 55.2,223.5 52.8,221.5 C52,220.8 51.3,220.1 50.7,219.3 C31.4,194.4 14.6,141.6 9.3,102.5 C9.3,102.5 3.9,63.7 1.1,36.2 C0.9,34.3 0.7,33.6 0.6,31.8 C0.6,31.8 4.4,24.3 12.4,5.6 C23,10.3 32.4,13 61.7,13.5 Z'
      ]
    },
    {
      name: 'RHF-GUARD',
      transform: 'translate(0, 651)',
      paths: [
        'M73.3,13.5 C16.7,10.6 14,0 14,0 L0.1,0 L0,20 L1.6,20 C117,14 113,163 4.9,155 L1.8,155 C1.8,182 3,195.3 5,223.6 C5,223.6 5,223.6 5,223.6 C5,224.8 6.2,225.8 7.4,226.1 L9.3,226.5 C10.5,226.8 11.9,226.9 13.1,226.9 L30,226.9 L58.3,226.6 C59.1,226.6 59.9,226.1 60.4,225.4 C60.6,225 61,224.8 61.4,224.9 C64.1,225 70.3,224.9 73.7,224.7 C76.8,224.6 79.8,223.5 82.2,221.5 C83,220.8 83.7,220.1 84.3,219.3 C103.6,194.4 120.4,141.6 125.7,102.5 C125.7,102.5 131.1,63.7 133.9,36.2 C134.1,34.3 134.3,33.6 134.4,31.8 C134.4,31.8 130.6,24.3 122.6,5.6 C112,10.3 102.7,13 73.3,13.5 Z'
      ]
    },
    {
      name: 'LHF-DOOR',
      transform: 'translate(398, 453)',
      paths: [
        'M205.4,13 C183.1,15.6 123.2,21.7 93.7,17.3 C75.5,13.4 63.6,10.6 57.3,9.8 C50,8.5 15,0.7 11,0.8 C6.5,1 1.5,0.4 1.5,0.4 L1,0.4 C0.9,30.6 2.4,56.7 4.8,75.2 C5.6,81.4 9.8,97 9.8,97.1 C21.6,143 66.9,222.1 69.9,225.3 C70.9,226.2 70.9,226.8 71,227.8 C71,228.1 71,228.5 71.1,228.8 C71.2,230.1 71.6,229.8 71.6,229.8 C71.6,229.8 75.4,222.5 83.4,203.8 C94.1,208.5 103.4,211.4 132.7,211.9 C189.4,209.1 192,198.9 192,198.9 L206,198.9 L205.4,13 Z'
      ]
    },
    {
      name: 'RHF-DOOR',
      transform: 'translate(0, 453)',
      paths: [
        'M0.6,13 C22.9,15.6 82.8,21.7 112.3,17.3 C130.5,13.4 142.4,10.6 148.7,9.8 C156,8.5 191,0.7 195,0.8 C199.5,1 204.5,0.4 204.5,0.4 L205,0.4 C205.1,30.6 203.6,56.7 201.2,75.2 C200.4,81.4 196.2,97 196.2,97.1 C184.4,143 139.1,222.1 136.1,225.3 C135.1,226.2 135.1,226.8 135,227.8 C135,228.1 135,228.5 134.9,228.8 C134.8,230.1 134.4,229.8 134.4,229.8 C134.4,229.8 130.6,222.5 122.6,203.8 C111.9,208.5 102.6,211.4 73.3,211.9 C16.6,209.1 14,198.9 14,198.9 L-1.13686838e-13,198.9 L0.6,13 Z'
      ]
    },
    {
      name: 'LHR-QTR',
      transform: 'translate(398, 181)',
      paths: [
        'M0.8,272.4 C0.8,272.4 5.4,272.9 9.9,272.8 C16.2,272.6 49,280.1 56.3,281.4 C62.6,282.2 74.5,285.2 92.7,289.1 C122.8,294.3 204.4,285 204.4,285 L204.3,258.9 L203.8,225.8 L201.1,225.9 C198.4,226.1 193.5,226.2 188.7,226.2 C186.4,226.2 184.3,226.2 182.4,226.1 C140,225.1 119.5,194.9 118.1,165.5 C116.7,136 134.9,102.1 176.9,96.7 L177.5,96.6 C180.8,96.1 184.1,95.9 187.5,95.8 C188.7,95.7 190,95.8 191.4,95.8 L202.2,95.8 L200,85.3 L197.5,73 C196.7,69.8 195.8,68.1 194.1,66.8 L192.5,66 C192,65.8 191.7,65.3 191.7,64.8 C190.8,56.6 184.5,19.4 179.2,7.1 C177,1.6 170.4,0.5 158.9,0.5 C156.5,0.5 153.9,0.5 151.1,0.6 C147,0.7 142.6,0.7 137.8,0.7 C127.2,0.5 120,4.3 112.5,9.9 C111.3,10.8 109.8,11.3 108.3,11.4 C96,11.8 87.3,12.5 74,15.5 C72.6,15.8 71,15.7 69.7,15.2 C67.2,14.2 65.1,13.7 63.4,13.7 C60.1,13.7 59,15.5 58.6,17 C58.5,17.7 57.9,21.2 54.3,43.4 C52.7,53.2 51.2,62.4 51.1,62.7 L38.4,110.1 C35.5,119.1 31.4,130.3 27.1,142.2 C16.9,170.1 5.4,201.6 3.1,221.2 L0.3,246.7 L0,272 L0.8,272.4 Z'
      ]
    },
    {
      name: 'RHR-QTR',
      transform: 'translate(1, 181)',
      paths: [
        'M203.6,272.4 C203.6,272.4 199,272.9 194.5,272.8 C188.2,272.6 155.4,280.1 148.1,281.4 C141.8,282.2 129.9,285.2 111.7,289.1 C81.6,294.3 5.68434189e-14,285 5.68434189e-14,285 L0.1,258.9 L0.6,225.8 L3.3,225.9 C6,226.1 10.9,226.2 15.7,226.2 C18,226.2 20.1,226.2 22,226.1 C64.4,225.1 84.6,194.9 86,165.5 C87.4,136 68.9,102.1 26.9,96.7 L26.6,96.6 C23.3,96.1 20.1,95.9 16.7,95.8 C15.5,95.7 14.3,95.8 12.9,95.8 L2.1,95.8 L4.3,85.3 L6.8,73 C7.6,69.8 8.5,68.1 10.2,66.8 L11.8,66 C12.3,65.8 12.6,65.3 12.6,64.8 C13.5,56.6 19.8,19.4 25.1,7.1 C27.3,1.6 33.9,0.5 45.4,0.5 C47.8,0.5 50.4,0.5 53.2,0.6 C57.3,0.7 61.7,0.7 66.5,0.7 C77.1,0.5 84.3,4.3 91.8,9.9 C93,10.8 94.5,11.3 96,11.4 C108.3,11.8 117,12.5 130.3,15.5 C131.7,15.8 133.3,15.7 134.6,15.2 C137.1,14.2 139.2,13.7 140.9,13.7 C144.2,13.7 145.3,15.5 145.7,17 C145.8,17.7 146.4,21.2 150,43.4 C151.6,53.2 153.1,62.4 153.2,62.7 L165.9,110.1 C168.8,119.1 172.9,130.3 177.2,142.2 C187.4,170.1 198.9,201.6 201.2,221.2 L204,246.7 L204.4,271.9 L203.6,272.4 Z'
      ]
    },
    {
      name: 'TURRET',
      transform: 'translate(200, 302)',
      paths: [{
        fillRule: 'evenodd',
        d: 'M0.5,0.8 L4.8,3.4 L196.6,3.7 L201.7,0.8 C202.5,0.8 189.7,161.6 200.1,254 C182.7,257.3 170.4,260.9 101.2,260 C33.2,261.6 23.7,257.5 2.3,254 C7,202.4 10.3,194.6 0.5,0.8 Z M13.5,7.3 h21 v246.2 h-21 Z M166.5,7.3 h21 v246.2 h-21 Z',
      }]
    },
    {
      name: 'LH-CANTRAIL',
      transform: 'translate(366, 309)',
      paths: [
        'M0.5,0.3 h21 v246.2 h-21 Z'
      ]
    },
    {
      name: 'RH-CANTRAIL',
      transform: 'translate(213, 309)',
      paths: [
        'M0.5,0.3 h21 v246.2 h-21 Z'
      ]
    },
    {
      name: 'TAILGATE',
      transform: 'translate(140, 65)',
      paths: [
        'M0.4,0 C0.4,1.4 0.5,2.8 0.5,4.2 C0.6,8.1 1.8,17.4 2.6,21.1 L4.1,29.7 C5.4,36.1 6.6,41.2 7.7,45.1 C8.1,46.6 8.9,47.8 9.8,49.2 C10.3,50 11.4,51 12.2,51.7 C12.4,51.9 12.6,52.1 12.8,52.3 L23.3,60.1 L27.6,63.3 L27.8,63.2 C28.4,63.4 89.2,82.1 165.3,82.1 C165.3,82.1 165.3,82.1 165.3,82.1 C210,82.1 251.1,75.8 287.6,63.2 L302.6,52.2 C302.7,52.1 303,51.9 303.2,51.7 C304,51 305.1,50.1 305.6,49.2 C306.5,47.9 307.3,46.6 307.7,45.1 C308.8,41.3 310,36.1 311.3,29.7 L312.8,21.2 C313.6,17.5 314.8,8.1 314.9,4.3 C314.9,2.8 315,1.5 315,0.1 L0.4,0.1 L0.4,0 Z'
      ]
    },
    {
      name: 'REAR-BUMPER',
      transform: 'translate(140, 0)',
      paths: [
        'M0.4,63.8 C0.4,63.4 0.4,63.1 0.4,62.7 C0.4,63.1 0.4,63.5 0.4,63.8 Z',
        'M1.7,31.7 C2.1,26.5 2.6,20.9 2.9,14.6 L3,13.9 L3,7.9 L2.6,7.8 C1.7,7.4 1.1,6.5 1.3,5.6 C1.7,4.1 2.2,3 3.6,2.4 L4.7,1.1 C5.6,0.1 7.3,-0.2 8.5,0.5 L14.9,4.2 C15.8,4.7 16.8,5 17.8,5 L43.1,5.9 L56.4,6.3 C58.3,6.2 61.2,5.6 64.2,4.9 C67.6,4.1 71.4,3.4 74.6,3.1 L84.9,3.1 L139.6,3.1 C141.6,3.1 143.7,3 145.9,3 L157.6,2.9 L169.3,3 C171.5,3 173.6,3.1 175.6,3.1 L230.3,3.1 L240.6,3.1 C243.9,3.4 247.7,4.1 251.1,4.8 C247.7,4 243.9,3.3 240.6,3.1 L230.3,3.1 L175.6,3.1 C173.6,3.1 171.5,3 169.3,3 L157.6,2.9 L146,2.9 C143.8,2.9 141.7,3 139.7,3 L85,3 L74.7,3 C71.5,3.3 67.7,4 64.3,4.8 C61.2,5.5 58.4,6.1 56.5,6.2 L43.2,5.8 L17.9,4.9 C16.9,4.9 15.9,4.6 15,4.1 L8.6,0.4 C7.4,-0.3 5.7,2.49800181e-15 4.8,1 L3.7,2.3 C2.3,2.9 1.8,4 1.4,5.5 C1.2,6.4 1.7,7.4 2.7,7.7 L3.1,7.8 L3.1,13.8 L3,14.5 C2.7,20.9 2.2,26.3 1.8,31.5 C1.1,40.5 0.3,49.2 0.5,61.8 C0.3,49.5 1,40.7 1.7,31.7 Z',
        'M272.3,5.8 L297.6,4.9 C298.6,4.9 299.6,4.6 300.5,4.1 L306.9,0.4 C308.1,-0.3 309.8,3.38618023e-15 310.7,1 L311.8,2.3 C313.2,2.9 313.7,4 314.1,5.5 C314.1,5.6 314.1,5.7 314.2,5.8 C314.2,5.7 314.2,5.6 314.1,5.5 C313.7,4 313.2,2.9 311.8,2.3 L310.7,1 C309.8,3.38618023e-15 308.1,-0.3 306.9,0.4 L300.5,4.1 C299.6,4.6 298.6,4.9 297.6,4.9 L272.3,5.8 L259,6.2 C258.3,6.2 257.4,6.1 256.5,5.9 C257.5,6.1 258.3,6.2 259,6.2 L272.3,5.8 Z',
        'M312.7,14.6 C313,21 313.4,26.4 313.9,31.7 C314.6,40.6 315.3,49.4 315.2,61.7 C315.3,49.2 314.6,40.6 313.9,31.6 C313.4,26.3 312.9,20.9 312.7,14.6 L312.7,7.9 L312.7,14.6 Z',
        'M315.1,62.8 C315.1,63.1 315.1,63.4 315.1,63.7 C315.1,63.5 315.1,63.1 315.1,62.8 Z',
        'M251.2,4.8 C252,5 252.7,5.1 253.4,5.3 C252.7,5.1 251.9,4.9 251.2,4.8 Z',
        'M315.1,65 C315.4,51 314.6,41.4 313.8,31.7 C313.4,26.5 312.9,21 312.6,14.6 L312.6,7.9 L313,7.8 C313.9,7.4 314.5,6.5 314.2,5.6 C313.8,4.1 313.3,3 311.9,2.4 L310.8,1.1 C309.9,0.1 308.2,-0.2 307,0.5 L300.6,4.2 C299.7,4.7 298.7,5 297.7,5 L272.4,5.9 L259,6.2 C257.1,6.1 254.2,5.5 251.1,4.8 C247.7,4 243.9,3.3 240.6,3.1 L230.3,3.1 L175.6,3.1 C173.6,3.1 171.5,3 169.3,3 L157.6,2.9 L146,2.9 C143.8,2.9 141.7,3 139.7,3 L85,3 L74.7,3 C71.5,3.3 67.7,4 64.3,4.8 C61.2,5.5 58.4,6.1 56.5,6.2 L43.2,5.8 L17.9,4.9 C16.9,4.9 15.9,4.6 15,4.1 L8.6,0.4 C7.4,-0.3 5.7,2.49800181e-15 4.8,1 L3.7,2.3 C2.3,2.9 1.8,4 1.4,5.5 C1.2,6.4 1.7,7.4 2.7,7.7 L3.1,7.8 L3.1,13.8 L3,14.5 C2.7,20.9 2.2,26.4 1.8,31.6 C1,41.3 0.2,50.9 0.5,64.9 L315.1,64.9 L315.1,65 Z'
      ]
    }
  ]
};

