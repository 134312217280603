import React from 'react';
import styled from 'styled-components';

import colors from 'colors';


const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;


export default ({
  visible,
  rrTotal,
  pdrTotal,
  repairTotal,
  paintTotal,
  partTotal,
  miscTotal,
  subletTotal,
  excess,
  quoted,
  catAdjustment,
  catExtras,
  subtotal, gst, total,
  category,
  pricingMethod,
}) => {
  console.log('summary:render');
  if (visible !== true) return null;
  return (
    <Card className='p-3 mt-3'>
      <div className='d-flex flex-row justify-content-center mb-2 text-danger'>
        {pricingMethod === 'category' && category === 0 && (
          <span>NON CATEGORY QUOTE</span>
        )}
        {pricingMethod === 'category' && category !== 0 && (
          <span>CATEGORY {category}</span>
        )}
      </div>
      <div className='row'>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>PDR:</strong> {pdrTotal}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>R/R:</strong> {rrTotal}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>Repair:</strong> {repairTotal}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>Paint:</strong> {paintTotal}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>Parts:</strong> {partTotal}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>Misc:</strong> {miscTotal}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>Sublet:</strong> {subletTotal}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-danger'>
          <strong>Excess:</strong> {excess}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-danger'>
          <strong>Quoted:</strong> {quoted}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-danger'>
          <strong>Adjustment:</strong> {catAdjustment}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-danger'>
          <strong>Extras:</strong> {catExtras}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>Subtotal:</strong> {subtotal}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-muted'>
          <strong>GST:</strong> {gst}
        </span>
        <span className='col-6 col-sm-4 col-md-3 text-danger'>
          <strong>Total:</strong> {total}
        </span>
      </div>
    </Card>
  );
};

