import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import Modal from 'components/Modal';

import MakeModel from './MakeModel.jsx';


const SearchButton = styled(Icons.Search)`
  cursor: pointer;
  stroke: ${colors.primary};
`
export default class PartsFinder extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      modelId: null,
      modelStr: '',
      sections: {}
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
  }
  //shouldComponentUpdate(nextProps, nextState) {
  //  return (
  //    nextProps.partsModelId !== this.props.partsModelId ||
  //    nextProps.selectedLineItems !== this.props.selectedLineItems ||
  //    nextState !== this.state
  //  );
  //}
  componentDidUpdate(prevProps, prevState) {
    if (this.state.modelId && this.state.modelId !== prevState.modelId) {
      this.props.onLoadVehicle(this.state.modelId);
    }
  }
  onOpen() {
    this.setState({
      open: true,
      sections: {}
    });
  }
  onClose() {
    this.setState({
      open: false,
    });
  }
  onChangeModel(m) {
    if (typeof m === 'string') {
      this.setState({
        modelId: null,
        modelStr: m
      });
    } else {
      this.setState({
        modelId: m.id,
        modelStr: m.text
      });
    }
  }
  onSectionToggle(section) {
    const {sections} = this.state;
    this.setState({
      ...this.state,
      sections: {
        ...sections,
        [section]: !sections[section]
      }
    });
  }
  onPartToggle(part) {
    this.props.onTogglePart(part);
  }
  render() {
    console.log('partsfinder:render');
    const {open, modelStr, sections} = this.state;
    const {vehicles, vehiclesNgramIndex, partSections, selectedLineItems} = this.props;
    return (
      <React.Fragment>
        <SearchButton
          onClick={this.onOpen}
          width={18}
          height={18}
        />
        <Modal title='Parts' extraWide={true} open={open} onClose={this.onClose}>
          <MakeModel
            className='m-0 px-3 pb-3'
            background={colors.grey1}
            vehicles={vehicles}
            vehiclesNgramIndex={vehiclesNgramIndex}
            value={modelStr}
            onChange={this.onChangeModel}
          />
          {partSections !== null && <div className='row no-gutters'>
            <div className='col-3 border-right p-2'>
                {
                  partSections.map(({name}, i) => {
                    const id = `section-${i}`;
                    return (
                      <div className='form-group form-check' key={i}>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          onClick={this.onSectionToggle.bind(this, i)}
                          id={id}
                        />
                        <label className='form-check-label' htmlFor={id}>{name}</label>
                      </div>
                    )
                  })
                }
            </div>
            <div className='col-9'>
              <table className='table w-100'>
                <thead>
                  <tr>
                    <th className='border-top-0'>Part #</th>
                    <th className='border-top-0'>Description</th>
                    <th className='border-top-0'>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(sections)
                      .filter(s => sections[s] === true)
                      .map((s, i) => (
                        partSections[s].parts.map((p, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>
                              <div className='form-group form-check mb-0'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  id={p.partNumber}
                                  checked={selectedLineItems[p.partId] === true}
                                  onChange={this.onPartToggle.bind(this, p)}
                                />
                                <label className='form-check-label' htmlFor={p.partNumber}>{p.partNumber}</label>
                              </div>
                            </td>
                            <td>{p.description}</td>
                            <td>{p.priceStr}</td>
                          </tr>
                        ))
                      ))
                  }
                </tbody>
              </table>
            </div>
          </div>}
        </Modal>
      </React.Fragment>
    );
  }
};
