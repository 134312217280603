import numeral from 'numeral';
import {connect} from 'react-redux';

import Summary from './component.jsx';


const memory = {};
const memoise = (type, value) => {
  if (memory[type] && memory[type].value === value) return memory[type].str;
  const str = numeral(value / 100).format('$0,0.00');
  memory[type] = {value, str};
  return str;
};

const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    jobDetails: {
      header,
      totals,
      category
    },
  } = state;
  const {profile: {abilities: {canJobDetailsViewSummary}}} = profiles[activeProfile];
  const visible = (
    canJobDetailsViewSummary === true &&
    header.stormId !== null &&
    header.debtorId !== null &&
    header.makeModel !== '' &&
    header.bodyShape !== null &&
    header.bodyPaint !== null &&
    header.hasOwnProperty('jobSettings') &&
    header.jobSettings.hasOwnProperty('pricingMethod')
  );
  if (visible !== true) return {visible};
  return {
    visible,
    rrTotal: memoise('rr', totals.rr),
    pdrTotal: memoise('pdr', totals.pdr),
    repairTotal: memoise('repair', totals.repair),
    paintTotal: memoise('paint', totals.paint),
    partTotal: memoise('part', totals.part),
    miscTotal: memoise('misc', totals.misc),
    subletTotal: memoise('sublet',totals.sublet),
    excess: memoise('excess', header.excess),
    quoted: memoise('quoted', totals.quoted),
    catAdjustment: memoise('catAdjustment', totals.catAdjustment),
    catExtras: memoise('catExtras', totals.catExtras),
    subtotal: memoise('subtotal', totals.subtotal),
    gst: memoise('gst', totals.gst),
    total: memoise('total', totals.total),
    category,
    pricingMethod: header.jobSettings.pricingMethod,
  };
};

export default connect(
  mapStateToProps,
  null
)(Summary);
