import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Dashboard from './component.jsx';


const load = API => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/dashboard`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'INTERNAL-DASHBOARD-SET-NOT-FOUND'
          });
          return null;
        }
        const {sections} = resp;
        dispatch({
          type: 'INTERNAL-DASHBOARD-SET',
          sections
        });
        return null;
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = (state, props) => {
  const {
    params: {
      employees: {
        index: employeeIndex,
      },
      locations: {
        index: locationIndex,
      },
    },
    internalDashboard
  } = state;
  return {
    ...internalDashboard,
    employeeIndex,
    locationIndex,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: () => dispatch(load(API)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
