import {connect} from 'react-redux';

import Job from './component.jsx';


const mapStateToProps = (state, props) => {
  const {
    params: {
      employees: {
        index: employeeIndex
      }
    },
    production: {
      jobs,
      filteredEmployee,
    }
  } = state;
  return {
    job: jobs[props.jobId],
    filteredEmployee,
    employeeIndex,
  };
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Job);
