import Promise from 'bluebird';
import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Documents from './component.jsx';


const load = (API, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});    
    API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/documents`, {method: 'GET'})
      .then(resp => {        
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-DOCUMENTS-SET-NOT-FOUND'
          });
          dispatch({
            type: 'JOB-GALLERY-SET',
            images: []
          });
          return null;
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });        
        dispatch({
          type: 'JOB-DOCUMENTS-SET-DOCUMENTS',
          documents: resp.documents
        });
        dispatch({
          type: 'JOB-DOCUMENTS-SET-FOUND',
          jobId,
          jobNumber: resp.jobNumber,
          flagged: resp.flagged,
          vpp: resp.vpp,
          vip: resp.vip,
          importedFromArms: resp.importedFromArms,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-DOCUMENTS-SET-NULL'
});

const upload = (API, jobId, documents) => {
  return dispatch => {
    //dispatch({type: 'LOADING-INC'});
    return Promise.mapSeries(
      documents,
      (f, i) => {
        const body = new FormData();
        body.append(`file${i}`, f);
        return API.requestForm(
          `${env.API_SERVER}/v1/internal/job/${jobId}/documents`,
          {method: 'POST', body}
        );
      }
    )
      .then(() => {
        return API.requestForm(
          `${env.API_SERVER}/v1/internal/job/${jobId}/documents`,
          {method: 'GET'}
        );
      })
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        dispatch({
          type: 'JOB-DOCUMENTS-SET-DOCUMENTS',
          documents: resp.documents
        });
        dispatch({
          type: 'JOB-DOCUMENTS-SET-FOUND',
          jobId,
          jobNumber: resp.jobNumber,
          flagged: resp.flagged,
          vpp: resp.vpp,
          vip: resp.vip,
          importedFromArms: resp.importedFromArms,
        });
        return true;
      })
      .finally(() => {
        //dispatch({type: 'LOADING-DEC'});
      });
  }
};


const del = (API, jobId, documentId) => {
  return dispatch => {
    //dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/documents/${documentId}`, {method: 'DELETE'})
      .then(resp => {
        if (resp && resp.error === true) {
          return Promise.reject(resp);
        }
        dispatch({
          type: 'JOB-DOCUMENTS-SET-DOCUMENTS',
          documents: resp.documents
        });
        return true;
      })
      .finally(() => {
        //dispatch({type: 'LOADING-DEC'});
      });
  }
};

const change = (API, jobId, documentId, sync) => {
  return dispatch => {
    //dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/documents/${documentId}`,
     {method: 'PUT', body: JSON.stringify({sync})})
      .then(resp => {
        if (resp && resp.error === true) {
          return Promise.reject(resp);
        }
        dispatch({
          type: 'JOB-DOCUMENTS-SET-DOCUMENTS',
          documents: resp.documents
        });
        return true;
      })
      .finally(() => {
        //dispatch({type: 'LOADING-DEC'});
      });
  }
};

const mapStateToProps = (state, props) => {  
  const {jobId} = props;
  let jobValid = null;  
  if (state.jobDocuments.notFound === true)
    jobValid = false;
  else if (state.jobDocuments.notFound === false) {
    jobValid = true;
  }
  if (jobValid !== true) {
    return {
      jobValid,
    };
  }
  const {
    jobDocuments: {jobNumber, flagged, vpp, vip, importUrl, documents},
    params: {employees: {index: employeesIndex}}
  } = state;
  return {
    jobValid,
    jobId,
    jobNumber,
    flagged,
    vpp,
    vip,
    importUrl,
    documents,
    employeesIndex
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: jobId => dispatch(load(props.API, jobId)),
  onUnload: () => dispatch(unload()),
  onChange: (jobId, documentId, sync) => dispatch(change(props.API, jobId, documentId, sync)),
  onDelete: (jobId, documentId) => dispatch(del(props.API, jobId, documentId)),
  onUpload: (jobId, documents) => dispatch(upload(props.API, jobId, documents)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Documents)
);
