import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

//import {Error, Search} from 'icons';
import colors from 'colors';
import CalendarInput from 'components/CalendarInput.jsx';
import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  height: 100%;
  overflow: auto;
  font-size: 0.9rem;
  @media print {
    overflow: visible;
  }
`;
const Legend = styled.div`
  width: 60px;
  border-bottom: 3px solid ${opts => opts.color};
`;
const Button = styled.button`
  & svg {
    stroke: #fff;
  }
`;
const DayCard = styled.div`
  border-radius: 0.5rem;
  background-color: ${opts => opts.empty ? 'inherit' : colors.grey1};
  box-shadow: ${opts => opts.open ? '0px 8px 15px -4px rgba(180, 180, 180, 0.5)' : 'none'};
  :last-of-type {
    margin-bottom: 0px !important;
  }
`;
const JobCard = styled.div`
  border-radius: 0.5rem;
  background: ${opts => opts.color};
  @media print {
    border: 1px solid ${colors.body};
  }
`;
//const CardHandle = styled.div`
//  border-radius: 0.5rem 0rem 0rem 0.5rem;
//  background: ${opts => opts.color};
//  width: 4px;
//  min-height: 100%;
//`;
const JobImg = styled.img`
  border-radius: 0.5rem;
  margin-left: 0px !important;
  max-height: 48px;
  width: auto;
`;

export default class BookingSchedule extends React.Component {
  componentDidMount() {
    const {stormId, dir, start, finish, onLoad} = this.props;
    if (stormId !== null) {
      onLoad(stormId, dir, start, finish);
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  componentDidUpdate(prevProps) {
    const {stormId, dir, start, finish, onLoad} = this.props;
    if (prevProps.dir !== dir) {
      this.props.onUnload();
      if (stormId !== null) {
        onLoad(stormId, dir, start, finish);
      }
    }
  }

  onSearch() {
    const {stormId, dir, start, finish, onLoad} = this.props;
    onLoad(stormId, dir, start, finish);
  }

  renderJob(job, i) {
    return (
      <div className='col-6 col-md-4 col-lg-3 mt-2' key={i}>
        <JobCard className='d-flex flex-row h-100 mx-1' color={colors[job.flag]}>
          <div className='d-flex flex-column justify-content-between flex-grow-1 p-2'>
            <div className='d-flex flex-row justify-content-between'>
              <div>
                <NavLink
                  to={`/job/${job.id}`}
                  className='text-body font-weight-bold'
                >{job.jobNumber}</NavLink>
              </div>
              <div className='text-center'>{job.rego}</div>
              <div className='text-right'>{job.startDateStr} | {job.finishDateStr}</div>
            </div>
            <div className='text-truncate text-uppercase'>{job.makeModel}</div>
          </div>
          {job.image64 && <JobImg src={job.image64} className='m-2 d-print-none' />}
        </JobCard>
      </div>
    );
  }

  renderBookingDay({bookingDateStr, jobs}, i) {
    if (jobs.length === 0) {
      return (
        <DayCard key={i} className='p-2 mb-2' empty={true}>
          <h6 className='mb-0 mx-1'>{bookingDateStr} (0)</h6>
        </DayCard>
      );
    }
    return (
      <DayCard key={i} className='p-2 mb-2'>
        <div className='font-weight-bold mx-1'>{bookingDateStr}</div>
        <div className='row no-gutters'>
          {jobs.map(this.renderJob.bind(this))}
        </div>
      </DayCard>
    );
  }

  render() {
    console.log('bookingschedule:render');
    const {
      notFound,
      storms,
      stormId,
      dir,
      start,
      finish,
      bookings,
      numPdr,
      numPaint,
      numTurret,
      onChangeDateRange,
      onSave,
    } = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load bookings list.</div>
        </ErrorContainer>
      );
    }

    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h4 className='mb-0'>{dir === 'in' ? 'Booking schedule' : 'Out list'}</h4>
          <div className='d-flex flex-row align-items-center'>
            <Legend
              className='d-none d-lg-block mr-2 text-center text-muted'
              color={colors.green}
            ><small>PDR: {numPdr || '0'}</small></Legend>
            <Legend
              className='d-none d-lg-block mr-2 text-center text-muted'
              color={colors.blue}
            ><small>Paint: {numPaint || '0'}</small></Legend>
            <Legend
              className='d-none d-lg-block mr-2 text-center text-muted'
              color={colors.orange}
            ><small>Turret: {numTurret || '0'}</small></Legend>
            <Select
              className='ml-2 mr-2'
              placeholder='select a storm'
              width='150px'
              background={colors.grey1}
              selected={stormId}
              options={storms}
              textField='name'
              onChange={this.props.onChangeStormId}
            />
            <div className='mx-2'>
              <CalendarInput
                width='85px'
                background={colors.grey1}
                selected={start}
                onChange={onChangeDateRange.bind(null, 'start')}
              />
            </div>
            <Icons.ArrowRight color={colors.grey4} width={18} height={18} />
            <div className='mx-2'>
              <CalendarInput
                align='right'
                width='85px'
                background={colors.grey1}
                selected={finish}
                onChange={onChangeDateRange.bind(this, 'finish')}
              />
            </div>
              <Button
                type='button'
                className='btn btn-primary px-3 ml-2'
                disabled={!stormId || !start || !finish}
                onClick={this.onSearch.bind(this)}
              ><Icons.Search width={18} height={18} /></Button>
              <Button
                type='button'
                className='btn btn-secondary px-3 ml-2 d-none d-lg-block'
                disabled={notFound !== false}
                onClick={onSave.bind(null, dir === 'in' ? 'Booking schedule' : 'Out list', bookings)}
              ><Icons.Download width={18} height={18} /></Button>
          </div>
        </div>
        {notFound === false && bookings.map(this.renderBookingDay.bind(this))}
      </Container>
    );
  }
};
