import moment from 'moment';
import numeral from 'numeral';


let currentState = null;
const cs = window.localStorage.getItem('admin-reports-storm-category:stormId');
if (cs) {
  currentState = JSON.parse(cs);
}

export default (state = {
  notFound: null,
  stormId: currentState,
  start: moment().day(0).hour(0).minute(0).second(0).millisecond(0).unix(),
  finish: moment().day(0).add(13, 'days').hour(0).minute(0).second(0).millisecond(0).unix(),
  report: null
}, action) => {
  switch (action.type) {
    case 'ADMIN-REPORTS-STORM-CATEGORY-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        report: null
      };
    case 'ADMIN-REPORTS-STORM-CATEGORY-SET-NULL':
      return {
        ...state,
        notFound: null,
        start: moment().day(0).hour(0).minute(0).second(0).millisecond(0).unix(),
        finish: moment().day(0).add(13, 'days').hour(0).minute(0).second(0).millisecond(0).unix(),
        report: null
      };
    case 'ADMIN-REPORTS-STORM-CATEGORY-CHANGE':
      const {change} = action;
      if (change.hasOwnProperty('stormId')) {
        window.localStorage.setItem('admin-reports-storm-category:stormId', JSON.stringify(change.stormId));
      }
      return {
        ...state,
        notFound: null,
        report: null,
        ...change,
      };
    case 'ADMIN-REPORTS-STORM-CATEGORY-SET':
      {
        return {
          ...state,
          notFound: false,
          report: action.report.map(r => ({
            ...r,
            startDateStr: moment(r.startDate * 1000).format('DD-MM'),
            finishDateStr: moment(r.finishDate * 1000).format('DD-MM'),
            categoryAdjustmentStr: numeral(r.categoryAdjustment / 100).format('($0,0.00)'),
            count: action[r.key]
          }))
        };
      }
    default:
      return state;
  }
};
