import React from 'react';
import {Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import ExcessInvoice from './ExcessInvoice';
import Invoice from './Invoice';
import Import from './Import';
import Infosheet from './Infosheet';
import LightTunnel from './LightTunnel';
import Print from './Print';

import Details from './Details';
import Locations from './Locations';
import Notes from './Notes';
import Smss from './Smss';
import Logs from './Logs';
import Payments from './Payments';
import Documents from './Documents';
import Qas from './Qas';
import Survey from './Survey';
import Purchasing from './Purchasing';


const Container = styled.div`
  overflow-x: auto;
`;

export default ({match:{params:{jobId}}}) => (
  <Container className='flex-grow-1 p-3'>
    <Switch>
      <Route
        path='/job/:jobId/details'
        exact={true}
        render={opts => <Details jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/purchasing'
        exact={true}
        render={opts => <Purchasing jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/locations'
        exact={true}
        render={opts => <Locations jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/notes'
        exact={true}
        render={opts => <Notes jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/smss'
        exact={true}
        render={opts => <Smss jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/documents'
        exact={true}
        render={opts => <Documents jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/payments'
        exact={true}
        render={opts => <Payments jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/qa'
        exact={true}
        render={opts => <Qas jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/survey'
        exact={true}
        render={opts => <Survey jobId={jobId} />}
      />
      <Route
        path='/job/:jobId/logs'
        exact={true}
        render={opts => <Logs jobId={jobId} />}
      />
    </Switch>
    <ExcessInvoice jobId={jobId} />
    <Invoice jobId={jobId} />
    <Import jobId={jobId} />
    <Infosheet jobId={jobId} />
    <LightTunnel jobId={jobId} />
    <Print jobId={jobId} />
  </Container>
);
