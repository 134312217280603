import {connect} from 'react-redux';

import Protected from './component.jsx';


const mapStateToProps = state => ({
  tokenExists: state.authentication !== null,
});

export default connect(mapStateToProps)(Protected);
