import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import MiscInvoices from './component.jsx';


const load = (API, start, finish, unpaidOnly) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    const url = new URL(`${env.API_SERVER}/v1/internal/misc-invoices`);
    url.searchParams.append('start', start);
    url.searchParams.append('finish', finish);
    url.searchParams.append('unpaidOnly', unpaidOnly);
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-MISC-INVOICES-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'ADMIN-MISC-INVOICES-SET',
          miscInvoices: resp.miscInvoices
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-MISC-INVOICES-SET-NULL'
});

const changeSearch = (field, value) => ({
  type: 'ADMIN-MISC-INVOICES-CHANGE-SEARCH',
  change: {
    [field]: value
  }
});

const filter = (field, value) => ({
  type: 'ADMIN-MISC-INVOICES-FILTER',
  field,
  value
});

const save = (API, id, payload) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/misc-invoice/${id}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(payload),
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'ADMIN-MISC-INVOICES-UPDATE',
          id,
          payload: res,
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const del = (API, id) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/misc-invoice/${id}`, {method: 'DELETE'})
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'ADMIN-MISC-INVOICES-DELETE',
          id
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    adminMiscInvoices: {
      notFound,
      start,
      finish,
      unpaidOnly,
      miscInvoices,
      totalStr,
      location,
      creditor,
      category,
    },
    params: {
      employees: {index: employeesIndex},
      miscCreditors: {index: creditorIndex, list: creditors},
      miscInvoiceCategoryIndex,
      miscInvoiceCategories,
      locations: {index: locationsIndex, list: locations},
    }
  } = state;
  return {
    notFound,
    start,
    finish,
    unpaidOnly,
    miscInvoices,
    totalStr,
    location,
    creditor,
    category,
    employeesIndex,
    locationsIndex,
    locations,
    creditorIndex,
    creditors,
    miscInvoiceCategoryIndex,
    miscInvoiceCategories,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: (start, finish, unpaidOnly) =>
    dispatch(load(API, start, finish, unpaidOnly)),
  onUnload: () => dispatch(unload()),
  onChangeSearch: (field, value) => dispatch(changeSearch(field, value)),
  onFilter: (field, value) => dispatch(filter(field, value)),
  onSave: (id, payload) =>
    dispatch(save(API, id, payload)),
  onDelete: id => dispatch(del(API, id)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MiscInvoices)
);
