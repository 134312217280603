import moment from 'moment';
import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import Select from 'components/Select.jsx';


const YEARS = Array.apply(null, Array(moment().year() - 2019 + 1)).map((_, i) => ({id: 2019 + i, text: `${2019 + i}`}));
const MONTHS = moment.monthsShort().map((m, i) => ({
  id: i + 1,
  text: m.toUpperCase(),
}));

const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Table = styled.table`
  margin-bottom: 0;
`;
const Th = styled.th`
  padding: 0 !important;
`;
const Td = styled.td`
  padding: 0 !important;
  padding-left: ${opts => opts.level * 2}rem !important;
  font-weight: ${opts => ((opts.isLeaf === false || opts.isTotal === true || opts.level === 0) && 'bold')};
`;

export default class ReportsProfitLoss extends React.Component {
  constructor(opts) {
    super(opts);
    const sd = moment().add(-1, 'month');
    this.state = {
      locations: {},
      year: sd.year(),
      month: sd.month() + 1,
      canSearch: false,
    };
    this.onChangeLocations = this.onChange.bind(this, 'locations');
    this.onChangeYear = this.onChange.bind(this, 'year');
    this.onChangeMonth = this.onChange.bind(this, 'month');
    this.onSearch = this.onSearch.bind(this);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onChange(field, e) {
    let newState;
    if (field === 'locations') {
      newState = {
        ...this.state,
        locations: {
          ...this.state.locations,
          [e.currentTarget.value]: e.currentTarget.checked,
        }
      };
    } else {
      newState = {
        ...this.state,
        [field]: e,
      };
    }
    this.setState({
      ...newState,
      canSearch: (
        Object.values(newState.locations).filter(k => k).length !== 0 &&
        newState.year !== null &&
        newState.month !== null
      ),
    });
  }
  onSearch() {
    const {onLoad} = this.props;
    const {locations, year, month} = this.state;
    onLoad(
      Object.keys(locations).reduce((acc, k) => {
        if (locations[k] === true) acc.push(k);
        return acc;
      }, []),
      year,
      month
    );
  }
  render() {
    console.log('profitloss:render');
    const {
      notFound,
      locationIndex,
      locations,
      report,
    } = this.props;
    const {
      locations: selectedLocations,
      year,
      month,
      canSearch,
    } = this.state;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load report.</div>
        </ErrorContainer>
      );
    }
    return (
      <React.Fragment>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label py-0'>Locations</label>
          <div className='col-sm-10'>
            <div className='row mb-n2'>
              {locations.map((l, i) => (
                <div className='col-sm-3 mb-2' key={i}>
                  <div className='form-check'>
                    <input
                      id={`location-${i}`}
                      className='form-check-input'
                      type='checkbox'
                      value={l.id}
                      onChange={this.onChangeLocations}
                      checked={selectedLocations[l.id] === true}
                    />
                    <label
                      className='form-check-label'
                      htmlFor={`location-${i}`}
                    >{l.name}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label py-0'>Date</label>
          <div className='col-sm-2'>
            <Select
              background={colors.grey1}
              placeholder='year'
              selected={year}
              options={YEARS}
              onChange={this.onChangeYear}
            />
          </div>
          <div className='col-sm-2'>
            <Select
              background={colors.grey1}
              placeholder='month'
              selected={month}
              options={MONTHS}
              onChange={this.onChangeMonth}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4 offset-sm-2'>
            <SearchButton
              type='button'
              className='btn btn-primary'
              disabled={canSearch !== true}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3 mt-4'>
            {report === null && (
              <div className='text-center text-secondary'>Report not found.</div>
            )}
            {report !== null && (
              <Table className='table'>
                <tbody>
                  <tr>
                    <Th className='border-0' />
                    {report.locations.map((l, i) => (
                      <Th key={i} className='text-center border-0'>{locationIndex[l].name}</Th>
                    ))}
                  </tr>
                  {report.rows.map((r, i) => (
                    <tr key={i}>
                      <Td level={r.level} isLeaf={r.isLeaf} isTotal={r.isTotal}>{r.title}</Td>
                      {r.columns.map((c, j) => (
                        <Td className='text-center' isLeaf={r.isLeaf}  isTotal={r.isTotal} key={j}>{c}</Td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card>
        )}
      </React.Fragment>
    );
  };
};

