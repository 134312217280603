import moment from 'moment';

import TruncateString from 'utils/truncate-string.js';


let sectionStates = {};
const tmp1 = window.localStorage.getItem('conventional:sectionStates');
if (tmp1) {
  sectionStates = JSON.parse(tmp1);
}
let painterStates = {};
const tmp2 = window.localStorage.getItem('conventional:painterStates');
if (tmp2) {
  painterStates = JSON.parse(tmp2);
}


const SECTIONS = [
  {title: 'Unallocated', key: 'jobsUnallocated', flagField: 'outDate'},
  {title: 'Outgoing', key: 'jobsOutgoing', grouped: true, flagField: 'outDate'},
  {title: 'Returning', key: 'jobsReturning', grouped: true, flagField: 'inDate'},
];

export default (state = {
  notFound: null,
  sections: [],
  sectionStates,
  painterStates,
  filter: null,
}, action) => {
  switch (action.type) {
    case 'CONVENTIONAL-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        sections: []
      };
    case 'CONVENTIONAL-SET':
    {
      const today = moment()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .unix();
      const tomorrow = moment()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'days')
        .unix();

      const {sections, jobIndex} = action.jobs.reduce((acc, j) => {
        let s;
        if (j.painter === null || (j.van === false && j.tow === false)) {
          s = acc.sections[0];
        } else if (j.isOut === false) {
          s = acc.sections[1];
        } else {
          s = acc.sections[2];
        }

        let flag = 'white';
        const flagField = j[s.flagField];
        if (flagField <= today) {
          flag = 'redExtraLight';
        } else if (flagField <= tomorrow) {
          flag = 'orangeExtraLight';
        }
        acc.jobIndex[j.id] = {
          ...j,
          flag,
          rego: j.rego !== '' ? j.rego : 'N/A',
          makeModel: TruncateString([j.make || '', j.model || ''].join(' ')),
          outDateStr: moment(j.outDate * 1000).format('DD-MM'),
          inDateStr: moment(j.inDate * 1000).format('DD-MM'),
          selected: true,
        };

        if (s.grouped === true) {
          if (!s.painterIndex.hasOwnProperty(j.painter)) {
            s.painterIndex[j.painter] = s.painters.length;
            s.painters.push({
              painter: j.painter,
              jobs: []
            });
          }
          s.painters[s.painterIndex[j.painter]].jobs.push(j.id);
        } else {
          s.jobs.push(j.id);
        }
        s.numJobs++;
        return acc;
      },
      {
        sections: SECTIONS.map(s =>
          s.grouped ?
            {...s, numJobs: 0, painters: [], painterIndex: {}} :
            {...s, numJobs: 0, jobs: []}
        ),
        jobIndex: {},
      });
      const sort = (field, jobs) => jobs.sort((a, b) => (jobIndex[a][field] - jobIndex[b][field]));
      sections.forEach(s => {
        if (s.grouped === true) {
          s.painters.forEach(p => {
            p.jobs = sort(s.flagField, p.jobs);
          });
        } else {
          s.jobs = sort(s.flagField, s.jobs);
        }
      });
      return {
        ...state,
        notFound: false,
        sections,
        jobIndex,
      };
    }
    case 'CONVENTIONAL-SECTION-TOGGLE':
    {
      const {section} = action;
      const newSectionStates = {
        ...state.sectionStates,
        [section]: !state.sectionStates[section]
      };
      window.localStorage.setItem(
        'conventional:sectionStates',
        JSON.stringify(newSectionStates)
      );
      return {
        ...state,
        sectionStates: newSectionStates
      };
    }
    case 'CONVENTIONAL-PAINTER-TOGGLE':
    {
      const {painter} = action;
      const newRepairerStates = {
        ...state.painterStates,
        [painter]: !state.painterStates[painter]
      };
      window.localStorage.setItem(
        'conventional:painterStates',
        JSON.stringify(newRepairerStates)
      );
      return {
        ...state,
        painterStates: newRepairerStates
      };
    }
    case 'CONVENTIONAL-JOB-TOGGLE':
    {
      const {jobId} = action;
      const newJobIndex = {
        ...state.jobIndex,
        [jobId]: {
          ...state.jobIndex[jobId],
          selected: !(state.jobIndex[jobId].selected)
        }
      };
      return {
        ...state,
        jobIndex: newJobIndex,
      };
    }
    case 'CONVENTIONAL-JOB-UPDATE':
    {
      const {jobId, jobData} = action;
      const newJobIndex = {
        ...state.jobIndex,
        [jobId]: {
          ...state.jobIndex[jobId],
          ...jobData,
        }
      };
      return {
        ...state,
        jobIndex: newJobIndex,
      };
    }
    case 'CONVENTIONAL-FILTER-SET':
      const {filter} = action;
      return {
        ...state,
        filter,
      };
    default:
      return state;
  }
};
