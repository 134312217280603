import React from 'react';
import * as Icons from 'react-feather';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Modal from 'components/Modal';
import Popover from 'components/Popover.jsx';
import Select from 'components/Select.jsx';
import STAGE_MAP from 'utils/stage-map.js';

import JobTitle from '../JobTitle';
import ImageList from '../ImageList';



const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const WarningContainer = styled.div`
  color: ${colors.orange};
  & svg {
    stroke: ${colors.orange};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Row = styled.div`
  margin: -0.25rem;
`;
const Col = styled.div`
  padding: 0.25rem !important;
`;
const Item = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
`;
const Button = styled.button`
  cursor: pointer;
  & svg {
    stroke: ${props => props.color};
  }
`;
//const Avatar = styled.div`
//  width: 28px;
//  height: 28px;
//  line-height: 28px;
//  text-align: center;
//  align-self: center;
//  background: ${opts => opts.bg};
//  color: #fff;

//  svg {
//    stroke: #fff;
//  }
//`;
const StageContainer = styled.div`
  cursor: pointer;
  color: ${colors.grey4};
`;
const StageThumbnail = styled.div`
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  background: ${opts => opts.bg};
  color: #fff;

  svg {
    stroke: #fff;
  }
`;
const TaskEmployee = styled.div`
  cursor: pointer;
  font-weight: ${opts => opts.selected ? 'bold' : 'normal'};
  :hover {
    background: ${colors.grey1};
  }
`;


class Stage extends React.Component {
  constructor(opts) {
    super(opts);
    this.ref = null;
    this.onClick = this.onClick.bind(this);
    this.onDone = this.onDone.bind(this);
  }
  onClick() {
    if (this.ref !== null && this.props.doneDate === null) {
      const {locationId, stage, employee, onToggle} = this.props;
      onToggle(locationId, stage, employee, this.ref);
    }
  }
  onDone(e) {
    const {locationId, stage, employee, onDone} = this.props;
    if (employee !== null) {
      onDone(locationId, stage, e.target.checked);
    }
  }
  render() {
    const {arrived, stage, employee, employees, doneDate} = this.props;
    return (
      <StageContainer
        ref={ref => this.ref = ref}
        className='d-flex flex-column align-items-center'
      >
        <StageThumbnail
          className='rounded-circle'
          onClick={this.onClick}
          bg={
            employee === null ?
              colors.grey4 :
              (doneDate === null ? employees[employee].avatarBg : employees[employee].avatarBgLight)
          }
        >
          {employee === null ?
            <Icons.User width={16} height={16} /> :
            <span title={employees[employee].name}>{employees[employee].initials}</span>
          }
        </StageThumbnail>
        <div
          className='py-1'
          onClick={this.onClick}
        >{stage}</div>
        <input
          type='checkbox'
          disabled={arrived !== true || employee === null}
          checked={employee !== null && doneDate !== null}
          onChange={this.onDone}
        />
      </StageContainer>
    );
  }
};


export default class Locations extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      location: null,
      popover: null,
    };
    this.ref = null;
    this.onAddDialogOpen = this.onAddDialogOpenClose.bind(this, true);
    this.onAddDialogClose = this.onAddDialogOpenClose.bind(this, false);
    this.onAddDialogChangeLocation = this.onAddDialogChange.bind(this, 'location');
    this.onAdd = this.onAdd.bind(this);
    this.onLocationStageClose = this.onLocationStageClose.bind(this);
    this.onLocationStageToggle = this.onLocationStageToggle.bind(this);
    this.onLocationStageDone = this.onLocationStageDone.bind(this);
  }
  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {jobId, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
  }
  onAddDialogOpenClose(open) {
    this.setState({
      open
    });
  }
  onAddDialogChange(field, e) {
    this.setState({
      ...this.state,
      [field]: e
    });
  }
  onDelete(locationId) {
    const {jobId, onDeleteLocation} = this.props;
    onDeleteLocation(jobId, locationId)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while deleting location.');
        } else {
          toast.success('Location successfully deleted.');
        }
      });
  }
  onAdd(e) {
    const {location} = this.state;
    const {jobId, onAddLocation} = this.props;
    onAddLocation(jobId, location)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while adding location.');
        } else {
          this.setState({
            open: false,
            location: null
          });
          toast.success('Location successfully added.');
        }
      });
  }
  onLocationStageClose() {
    this.setState({
      popover: null
    });
  }
  onLocationStageToggle(locationId, stage, selected, ref) {
    //console.log(locationId, stage, selected, ref);
    if (this.state.popover !== null && this.state.popover.ref === ref) {
      this.setState({
        popover: null
      });
    } else {
      const locationAbilityList = this.props.locationAbilityLists[locationId];
      const employees = locationAbilityList[stage];
      this.setState({
        popover: {
          locationId,
          stage,
          employees,
          selected,
          ref
        }
      });
    }
  }
  onLocationStageDone(locationId, stage, done) {
    const {jobId, onChange} = this.props;
    onChange(jobId, locationId, {
      stage,
      done
    });
  }
  onLocationStageEmployeeSelect(employee) {
    const {popover:{locationId, stage}} = this.state;
    const {jobId, onChange} = this.props;
    onChange(jobId, locationId, {
      stage,
      employee,
    });
    this.setState({popover: null});
  }
  onChange(location, field, e) {
    const {locationId, currentInDate, currentOutDate} = location;
    const {jobId, onChange} = this.props;
    if (field === 'in') {
      if (e.target.checked === true) {
        onChange(jobId, locationId, {
          isIn: true
        });
      } else {
        onChange(jobId, locationId, {
          isIn: false,
          isOut: false,
        });
      }
    } else if (field === 'out') {
      if (e.target.checked === true) {
        if (currentInDate !== null && currentOutDate === null) {
          onChange(jobId, locationId, {
            isOut: true
          });
        }
      } else {
        onChange(jobId, locationId, {
          isOut: false,
        });
      }
    } else if (field === 'primary') {
      onChange(jobId, locationId, {
        isPrimary: true,
      });
    }
  }
  renderAddDialog() {
    const {open, location} = this.state;
    const {allLocations} = this.props;
    if (open !== true) return null;
    return (
      <Modal
        title='Add location'
        open={true}
        onClose={this.onAddDialogClose}
      >
        <div className='modal-body'>
          <div>
            <Select
              title='Location'
              placeholder='select a location'
              background={colors.grey1}
              options={allLocations}
              textField='name'
              selected={location}
              onChange={this.onAddDialogChangeLocation}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={this.onAddDialogClose}
          >Close</button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onAdd}
          >Add</button>
        </div>
      </Modal>
    );
  }
  render() {
    console.log('locations:render');
    const {jobValid, canAddLocations} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }
    if (canAddLocations !== true) {
      return (
        <WarningContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job does not have a storm defined yet.</div>
        </WarningContainer>
      );
    }

    const {popover} = this.state;
    const {
      jobId,
      jobNumber,
      flagged,
      vpp,
      vip,
      importUrl,
      employeeIndex,
      jobLocations
    } = this.props;
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        <ImageList jobId={jobId} />
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold mb-2'>Locations</div>
          <Row className='d-flex flex-row flex-wrap'>
            {jobLocations.map((l, i) => (
              <Col className='col-12 col-md-4 col-lg-3' key={i}>
                <Item className='d-flex flex-row justify-content-between align-items-center'>
                  <div>{l.name}</div>
                  <div className='d-flex flex-row'>
                    <Button
                      className='btn mr-2'
                      color={l.isPrimary ? colors.primary : colors.grey4}
                      onClick={this.onChange.bind(this, l, 'primary')}
                      title='Primary location'
                    >
                      <Icons.Lock width={18} height={18} />
                    </Button>
                    <Button
                      className='btn'
                      color={colors.red}
                      onClick={this.onDelete.bind(this, l.locationId)}
                    >
                      <Icons.X width={18} height={18} />
                    </Button>
                  </div>
                </Item>
              </Col>
            ))}
          </Row>
          <Button
            className='btn mt-2'
            color={colors.body}
            onClick={this.onAddDialogOpen}
          >
            <Icons.Plus width={18} height={18} />
          </Button>
        </Card>
        {jobLocations.map((l, i) => (
          <Card className='p-3 mt-3' key={i}>
            <div className='font-weight-bold mb-2'>Production - {l.name}</div>
            <div className='d-flex flex-row justify-content-between px-2'>
              <div className='d-flex flex-column justify-content-end align-items-center'>
                <small className='text-muted mb-2'>In</small>
                <input
                  type='checkbox'
                  checked={l.inDate !== null}
                  onChange={this.onChange.bind(this, l, 'in')}
                />
              </div>
              {
                Object.keys(STAGE_MAP).map((s, i) => {
                  const stage = STAGE_MAP[s];
                  return (
                    <Stage
                      key={i}
                      stage={s}
                      locationId={l.locationId}
                      employee={l[stage.employee]}
                      employees={employeeIndex}
                      arrived={l.inDate !== null}
                      doneDate={l[stage.date]}
                      onToggle={this.onLocationStageToggle}
                      onDone={this.onLocationStageDone}
                    />
                  )
                })
              }
              <div className='d-flex flex-column justify-content-end align-items-center'>
                <small className='text-muted mb-2'>Out</small>
                <input
                  type='checkbox'
                  checked={l.outDate !== null}
                  onChange={this.onChange.bind(this, l, 'out')}
                  disabled={l.inDate === null}
                />
              </div>
            </div>
          </Card>
        ))}
        {this.renderAddDialog()}
        {popover &&
          <Popover
            target={popover.ref}
            onClose={this.onLocationStageClose}
          >
            <TaskEmployee
              className='text-secondary p-2'
              selected={popover.selected === null}
              onClick={this.onLocationStageEmployeeSelect.bind(this, null)}
            >N/A</TaskEmployee>
            {popover.employees.map((e, i) => (
              <TaskEmployee
                key={i}
                className='p-2'
                selected={popover.selected === e.id}
                onClick={this.onLocationStageEmployeeSelect.bind(this, e.id)}
              >{e.name}</TaskEmployee>
            ))}
          </Popover>
        }
      </React.Fragment>
    );
  };
};
