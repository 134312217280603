import {connect} from 'react-redux';

import Loading from './component.jsx';


const mapStateToProps = state => ({
  loading: state.loading !== 0
});

export default connect(mapStateToProps)(Loading);
