import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';


const List = styled.div`
  display: flex;

  background: ${colors.grey1};
  border-radius: 0.5rem;

  flex-direction: row;
  height: 96px;
  min-width: 0;
  flex-shrink: 0;
  overflow-x: auto;
`;
const ImageContainer = styled.div`
  cursor: pointer;
  position: relative;
  & svg {
    stroke: #fff;
  }
  & .image-btn:not(.pinned) {
    visibility: hidden;
  }
  :hover .image-btn {
    visibility: visible;
  }
`;
const ImageGallery = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  z-index: 2000;
`;
const Arrow = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  & svg {
    stroke: #fff;
  }
`;
const ArrowLeft = styled(Arrow)`
  left: 0px;
`;
const ArrowRight = styled(Arrow)`
  right: 0px;
`;


export default class ImageList extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      active: false
    };

    this.onDocumentKey = this.onDocumentKey.bind(this);

    this.onClose = this.onClose.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onNext = this.onScroll.bind(this, 1);
    this.onPrev = this.onScroll.bind(this, -1);
  }
  componentDidMount() {
    document.addEventListener('keyup', this.onDocumentKey);
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.onDocumentKey);
  }
  onDocumentKey(e) {
    const {openIndex} = this.props;
    if (openIndex === null) return;
    switch(e.key) {
      case 'Escape':
        this.onClose();
        break;
      case 'ArrowLeft':
        this.onScroll(-1, e);
        break;
      case 'ArrowRight':
        this.onScroll(1, e);
        break;
      default:
        break;
    }
  }
  onClose() {
    this.props.onSetOpenImage(null);
  }
  onScroll(dir, e) {
    e.stopPropagation();
    const {openIndex} = this.props;
    if (openIndex !== null) {
      this.props.onSetOpenImage(openIndex + dir);
    }
  }
  onClick(e) {
    e.stopPropagation();
  }
  onOpen(index, e) {
    e.stopPropagation();
    this.props.onSetOpenImage(index);
  }
  render() {
    console.log('imagelist:render');
    const {openIndex, images} = this.props;
    return (
      <React.Fragment>
        <List className='mb-3'>
          {
            images.map(({id, image128}, i) => (
              <ImageContainer key={i}>
                <img key={i} src={image128} alt='job' onClick={this.onOpen.bind(this, i)}/>
              </ImageContainer>
            ))
          }
        </List>
        {openIndex !== null && (
          <ImageGallery className='d-flex flex-row justify-content-center align-items-center' onClick={this.onClose}>
            <div className='position-relative'>
              {images.map(({image640}, i) => (
                <div className={i === openIndex ? 'd-block' : 'd-none'} key={i}>
                  <img className='img-fluid' alt='job' src={image640} onClick={this.onClick} />
                </div>
              ))}
              <ArrowLeft className='d-flex flex-row justify-content-center align-items-center' onClick={this.onPrev}>
                <Icons.ChevronLeft width={32} height={32} />
              </ArrowLeft>
              <ArrowRight className='d-flex flex-row justify-content-center align-items-center' onClick={this.onNext}>
                <Icons.ChevronRight width={32} height={32} />
              </ArrowRight>
            </div>
          </ImageGallery>
        )}
      </React.Fragment>
    );
  }
};
