
export let ABILITY_ROWS = [
  [
    {
      key: 'canLogin',
      text: 'Login',
    }, //
    {
      key: 'canViewDashboard',
      text: 'Dashboard',
    }, //
    {
      key: 'canViewReports',
      text: 'Reports',
    }, //
  ]
];
