import {get as getAvatarBg} from 'utils/avatar-bgs.js';
import getInitials from 'utils/get-initials.js';


let currentState = null;
const cs = window.localStorage.getItem('supplierUserDetails');
if (cs) {
  currentState = JSON.parse(cs);
}


export default (state = currentState, action) => {
  switch (action.type) {
    case 'SUPPLIER-USER-DETAILS-RESET':
    {
      window.localStorage.setItem('supplierUserDetails', JSON.stringify(null));
      return null;
    }
    case 'SUPPLIER-USER-DETAILS-SET':
    {
      const {name} = action;
      const initial = getInitials(name);
      const avatarBg = getAvatarBg([...name].reduce((acc, c) => acc + c.charCodeAt(0), 0)).dark;
      const details = {
        name,
        initial,
        avatarBg
      };
      window.localStorage.setItem('supplierUserDetails', JSON.stringify(details));
      return details;
    }
    default:
      return state;
  }
};
