import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Header from './component.jsx';


const ocr = (API, jobId, field, image) => {
  return dispatch => {
    const body = new FormData();
    body.append('field', field);
    body.append('image', image);
    dispatch({type: 'LOADING-INC'});
    return API.requestForm(
      `${env.API_SERVER}/v1/internal/job/${jobId}/ocr`,
      {method: 'PUT', body}
    )
      .then(res => {
        if (res !== null && res.error !== true) {
          dispatch({
            type: 'JOB-DETAILS-HEADER-UPDATE',
            changes: {
              [field]: res.ocrResult,
              [`${field}Error`]: false
            }
          });
          dispatch({
            type: 'JOB-GALLERY-SET',
            images: res.images
          });
        }
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    jobDetails: {header}
  } = state;
  const {profile: {abilities: {canJobDetailsViewCustomer, canJobDetailsViewCar}}} = profiles[activeProfile];
  return {
    canJobDetailsViewCustomer,
    canJobDetailsViewCar,
    header
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onOCR: (jobId, field, image) => dispatch(ocr(props.API, jobId, field, image)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
