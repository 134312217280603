import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import Portal from 'components/Portal.jsx';


const Container = styled.div`
  margin-top: 0.5rem;
  z-index: 1060;
  display: block;
  max-width: 300px;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 10px rgba(184, 184, 184, 0.5);
  background: #fff;
`;

export default class Popover extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      x: 0,
      y: 0
    };
    this.ref = null;
    this.update = this.update.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onRef = this.onRef.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.target !== prevProps.target) {
      this.update();
    }
  }
  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick);
    window.addEventListener('resize', this.update);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
    window.removeEventListener('resize', this.update);
  }
  onDocumentClick(e) {
    if (this.ref !== null) {
      const node = ReactDOM.findDOMNode(this.ref);
      if (!node.contains(e.target) && !this.props.target.contains(e.target)) {
        this.props.onClose();
      }
    }
  }
  onRef(ref) {
    this.ref = ref;
    this.update();
  }
  update() {
    const {top, left, height} = this.props.target.getBoundingClientRect();
    this.setState({
      x: left,
      y: top + height
    });
  }
  render() {
    const {children} = this.props;
    const {x, y} = this.state;
    return (
      <Portal>
        <Container
          className='position-absolute'
          style={{
            top: y,
            left: x
          }}
          ref={this.onRef}
        >
          <div className=''>{children}</div>
        </Container>
      </Portal>
    );
  }
};

