export default (state = {open: false}, action) => {
  switch (action.type) {
    case 'ADD-TASK-OPEN':
      return {open: true};
    case 'ADD-TASK-CLOSE':
      return {open: false};
    default:
      return state;
  }
};
