import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import AppSupplier from './component.jsx';


const mapStateToProps = state => {
  const {authentication: {activeProfile, profiles}} = state;
  const {profile: {userType}} = profiles[activeProfile];
  return {
    visible: userType === 'supplier'
  };
};

export default withRouter(connect(mapStateToProps)(AppSupplier));
