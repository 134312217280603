import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';
import {ReactComponent as Logo} from 'logo.svg';


const HeaderContainer = styled.div`
  height: 64px;
  background-color: ${colors.grey1};
`;
const Vr = styled.div`
  height: 24px;
`;
const Button = styled.div`
  cursor: pointer;
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;

  & a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    line-height: 64px;
    color: ${colors.body};
  }
  & a div {
    height: 48px;
    width: 48px;
    line-height: 48px;
  }
  & svg {
    stroke: ${colors.body};
  }
  & a.active div {
    background-color: ${colors.primary};
    box-shadow: 0px 7px 10px rgba(157, 157, 157, 0.5);
  }
  & a.active {
    color: ${colors.primary};
  }
  & a.active svg {
    stroke: #fff;
  }
  :hover a {
    text-decoration: none;
    color: ${colors.primary};
  }
  :hover a.active {
    color: ${colors.primary};
  }
  :hover svg {
    stroke: ${colors.primary};
  }
`;


export default () => (
  <HeaderContainer
    className='d-flex flex-row justify-content-between align-items-center'
  >
    <div className='d-inline-block px-4'>
      <NavLink to='/'>
        <Logo width={144} height={22} />
      </NavLink>
    </div>

    <div className='d-none d-md-flex flex-row align-items-center'>
      {false && <Button>
        <NavLink to='/quotes'>quotes</NavLink>
      </Button>}
      <Button>
        <NavLink to='/orders'>orders</NavLink>
      </Button>
      <Button>
        <NavLink to='/credits'>credits</NavLink>
      </Button>
      <Vr className='border-left mx-3' />
      <Button>
        <NavLink to='/settings'>
          <div className='rounded-circle'>
            <Icons.Settings />
          </div>
        </NavLink>
      </Button>
      <Button>
        <NavLink to='/logout'>
          <div className='rounded-circle'>
            <Icons.LogOut />
          </div>
        </NavLink>
      </Button>
    </div>
  </HeaderContainer>
);
