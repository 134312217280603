import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import uuidv4 from 'uuid/v4';

import colors from 'colors';

import Modal from 'components/Modal';


const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  padding: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;
const Input = styled.input`
  height: inherit !important;
`;
const Mail = styled(Icons.Mail)`
  stroke: ${colors.primary};
`;
const Send = styled(Icons.Send)`
  stroke: #fff;
`;
const Printer = styled(Icons.Printer)`
  stroke: ${colors.primary};
`;

export default class ExcessInvoice extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      id: uuidv4(),
      formOpen: false,
      to: '',
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onEmail = this.onEmail.bind(this);
    this.onPrint = this.onPrint.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        id: uuidv4(),
        formOpen: false,
      });
      const {jobId, open, onLoad} = this.props;
      if (open === true) {
        onLoad(jobId)
          .then(res => {
            if (res !== true) {
              toast.error('Error encountered generating invoice.');
            }
            //if (res === true) {
            //  this.onPrint();
            //}
          });
      }
    }
  }
  onChangeEmail(e) {
    this.setState({
      to: e.target.value,
    });
  }
  onEmail() {
    const {id, formOpen, to} = this.state;
    const {jobId, email, onLoad} = this.props;
    if (formOpen !== true) {
      this.setState({
        formOpen: true,
        to: email
      });
    } else {
      const emails = to.split(/[,;\s]/g).filter(e => e.length);
      onLoad(jobId, id, emails)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered sending email.');
          } else {
            toast.success('Email successfully sent.');
          }
          //if (res === true) {
          //  this.onPrint();
          //}
        });
    }
  }
  onPrint() {
    //setTimeout(() => {
      window.print();
    //}, 1);
  }
  render() {
    if (this.props.open !== true) return null;
    const {
      company: {
        name: companyName,
        jurisdiction,
        abn,
        bsb,
        accountNumber,
        licenseNumber,
        address,
        phone,
        logo,
      },
      jobNumber,
      excessInvoiceNumber,
      excessInvoiceDate,
      name,
      makeModel,
      rego,
      claimNumber,
      excess,
      startDate,
      finishDate,
      onUnload
    } = this.props;
    const {formOpen, to} = this.state;
    return (
      <Modal
        open={true}
        fullScreen={true}
      >
        <div className='modal-body'>
          <div className='d-none d-print-flex mb-5'>
            <div className='col-12 col-sm-6 text-center text-sm-left'>
              {logo && <img src={logo} alt='logo' width="250" />}
            </div>
            <div className='col-12 col-sm-6 text-center text-sm-right text-uppercase'>
              <h3>{companyName}</h3>
              <div>{address}</div>
              <div>P: {phone}</div>
              <div>{jurisdiction === 'AU' && 'ABN:'}{jurisdiction === 'NZ' && 'GST #:'} {abn}</div>
              <div>LIC #: {licenseNumber}</div>
            </div>
          </div>
          <div className='d-flex flex-row justify-content-between align-items-center'>
            <h5 className='mb-0 p-0'>Excess invoice</h5>
            <div className='d-flex flex-row d-print-none'>
              {formOpen === true && (
                <div className='input-group'>
                  <Input
                    type='email'
                    className='form-control'
                    value={to}
                    onChange={this.onChangeEmail}
                  />
                  <div className='input-group-append'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={this.onEmail}
                    ><Send width={18} height={18} /></button>
                  </div>
                </div>
              )}
              {formOpen !== true && (
                <button type='button' className='btn' onClick={this.onEmail}><Mail /></button>
              )}
              <button type='button' className='btn ml-2' onClick={this.onPrint}><Printer /></button>
              <button type='button' className='close ml-2' onClick={onUnload}><Icons.X /></button>
            </div>
          </div>
          <div className='mt-3'>
            <div className='text-right'>
              <span className='font-weight-bold'>Invoice #:</span> {excessInvoiceNumber}
            </div>
            <div className='text-right'>
              <span className='font-weight-bold'>Invoice date:</span> {excessInvoiceDate}
            </div>
          </div>
          <Card className='mt-3'>
            <div className='row'>
              <div className='col-1 text-right font-weight-bold'>Job #:</div>
              <div className='col-3'>{jobNumber}</div>
              <div className='col-1 text-right font-weight-bold'>Customer:</div>
              <div className='col-3'>{name}</div>
              <div className='col-1 text-right font-weight-bold'>Make/Model:</div>
              <div className='col-3'>{makeModel}</div>

              <div className='col-1 text-right font-weight-bold'>Rego:</div>
              <div className='col-3'>{rego}</div>
              <div className='col-1 text-right font-weight-bold'>Claim #:</div>
              <div className='col-3'>{claimNumber}</div>
              <div className='col-1 text-right font-weight-bold'>Start/Finish:</div>
              <div className='col-3'>{startDate} {'\u2013'} {finishDate}</div>
            </div>
          </Card>
          <h3 className='mt-5 text-center'>Excess amount {excess}</h3>
          <div className='mt-5 text-center'>
            Please make payment to the following account:
          </div>
          <div className='row no-gutters'>
            <div className='col-6 text-right pr-1'>Account Name:</div>
            <div className='col-6 text-left pl-1'>{companyName}</div>
          </div>
          <div className='row no-gutters'>
            <div className='col-6 text-right pr-1'>Account BSB:</div>
            <div className='col-6 text-left pl-1'>{bsb}</div>
          </div>
          <div className='row no-gutters'>
            <div className='col-6 text-right pr-1'>Account #:</div>
            <div className='col-6 text-left pl-1'>{accountNumber}</div>
          </div>
        </div>
      </Modal>
    );
  }
};
