const OUT = 0.54;


export default side => ({
  panelCode: 'D',
  lineItems: [
    {
      description: `${side} CANTRAIL`,
      panel: `${side}-CANTRAIL`,
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side} CANTRAIL OUT`,
      panel: `${side}-CANTRAIL`,
      supersetOf: [`${side} CANTRAIL BLEND`],
      settings: {
        [`${side}-CANTRAIL`]: {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.7,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
          '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.9,
                hourlyRate: 100,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `${side} CANTRAIL BLEND`,
      panel: `${side}-CANTRAIL`,
      subsetOf: [`${side} CANTRAIL OUT`],
      settings: {
        [`BOOT|${side}R-QTR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 1.7,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        },
        'TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            'COUPE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 1.7,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'COUPE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoReplaceable: true,
                paintAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side} DOOR APERTURE HARDWARE X 2`,
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.75,
                rrAutoPaintable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `${side} APERTURES NESS X 2`,
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.2,
                paintAutoPaintable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `${side} CANTRAIL LOADING 10%`,
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
        },
          },
      }
    },
    {
      description: `${side} CANTRAIL LOADING 25%`,
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `PDR ${side} CANTRAIL`,
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BUFF & POLISH ${side} CANTRAIL`,
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BRUSH TOUCH ${side} CANTRAIL`,
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
});
