import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import Modal from 'components/Modal';
import colors from 'colors';


const ModalBody = styled.div`
  & .form-group:last-of-type {
    margin-bottom: 0px;
  }
`;
const Textarea = styled.textarea`
  border-color: ${opts => opts.error ? colors.red : '#fff'} !important;
  background: ${colors.grey1};
`;

const initSelections = (entries, type, bookingType) => {
  return entries.reduce((acc, s) => {
    if (s.type !== type || (s.condition(bookingType) !== true)) return acc;
    return s.entries.reduce((acc, e) => {
      acc[e.key] = false;
      return acc;
    }, acc);
  }, {});
}

export default class QaForm extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      note: '',
      ...initSelections(opts.entries, opts.type, opts.bookingType)
    };

    this.onSave = this.onSave.bind(this);
    this.onChangeNote = this.onChange.bind(this, 'note');
    this.onchangeQa = this.onChange.bind(this, 'qa');
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
  }

  onChange(field, e) {
    if (field === 'note') {
      this.setState({
        note: e.target.value,
      });
    } else {
      const newState = {
        ...this.state,
        [e.currentTarget.getAttribute('id')]: e.currentTarget.checked
      };
      this.setState(newState);
    }
  }

  onToggleModal(open) {
    const {type, entries, bookingType} = this.props;
    this.setState({
      open,
      note: '',
      ...initSelections(entries, type, bookingType)
    });
  }

  onSave() {
    const {jobId, type, onSave} = this.props;
    let note = this.state.note;
    delete this.state.open;
    delete this.state.note;
    if(Object.keys(this.state).filter(key => this.state[key]).length === 0) {
      this.setState({
        open: false
      });
      toast.error(`Nothing selected.`);
      return;
    }

    onSave(jobId, {type, note, entries: this.state})
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while selecting qa.');
        } else {
          const typeMsg = type === 'mid-repair' ? 'Mid repair' : 'Final';
          toast.success(`${typeMsg} QA successfully received.`);
          this.setState({
            open: false
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while selecting qa.');
      });
  }

  render() {
    console.log('qaform:render');
    const {type, entries, bookingType} = this.props;
    const {open} = this.state;

    return (
      <React.Fragment>
        {type === 'mid-repair' && <button className='btn btn-primary ml-2' onClick={this.onOpen}>Mid repair QA</button>}
        {type === 'final' && <button className='btn btn-primary ml-2' onClick={this.onOpen}>Final QA</button>}
        <Modal
          title='Please complete QA outcome'
          open={open}
          onClose={this.onClose}
        >
          <ModalBody className='modal-body'>
            {entries.map((e, i) => (
              e.type === type && e.condition(bookingType) === true && (
                <React.Fragment key={i}>
                  {e.entries.map((r, j) => (
                    <div key={j} className='form-group form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id={r.key}
                        onChange={this.onchangeQa}
                        checked={this.state[r.key]}
                      />
                      <label className='form-check-label' htmlFor={r.key}>
                        {r.title}
                      </label>
                    </div>
                  ))}
                </React.Fragment>
              )
            ))}
            <div className='mt-2'>
              <small className='text-muted'>Note</small>
              <Textarea
                type='text'
                rows={3}
                className='form-control p-0'
                onChange={this.onChangeNote}
                value={this.note}
              />
            </div>
          </ModalBody>
          <div className='modal-footer bt-0'>
            <button type='button' className='btn btn-secondary' onClick={this.onClose}>Close</button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={this.onSave}
            >Save
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

