import React from 'react';
import styled from 'styled-components';

import colors from 'colors';

import Butterfly from './Butterfly';
import QuoteSection from './QuoteSection';


const Card = styled.div`
  background: ${opts => opts.alertRed
    ? colors.redExtraLight
    : opts.alertOrange
      ? colors.orangeExtraLight
      : colors.grey1};
  border-radius: 0.5rem;
`;

export default class Quote extends React.Component {
  constructor(opts) {
    super(opts);
    this.onPartOutcome = this.onPartOutcome.bind(this);
  }
  onPartOutcome(index, partId, partOutcome) {
    const {jobId, onPartOutcome} = this.props;
    return onPartOutcome(jobId, index, partId, partOutcome);
  }
  render() {
    const {
      visible,
      showCategory,
      category,
      potentialTotalLoss,
      lowCategoryAdjustment,
      rates,
      onAddRow,
      onRemoveRow,
      onChange,
    } = this.props;
    //console.log('quote:render');
    if (visible !== true) return null;
    return (
      <Card className='p-3 mt-3' alertRed={potentialTotalLoss} alertOrange={lowCategoryAdjustment}>
        {showCategory === true && (
          <div className='d-flex flex-row justify-content-center mb-2 text-danger'>
            {category === 0 && (
              <span>NON CATEGORY QUOTE</span>
            )}
            {category !== 0 && (
              <span>CATEGORY {category}</span>
            )}
          </div>
        )}
        <div className='row'>
          <div className='col-12 col-lg-4 align-self-start text-center'>
            <Butterfly />
          </div>
          <div className='col-12 col-lg-8'>
            <QuoteSection
              key='pdr'
              type='pdr'
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onChange={onChange}
            />
            <QuoteSection
              key='rr'
              type='rr'
              defaultRate={rates.rr}
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onChange={onChange}
            />
            <QuoteSection
              key='repair'
              type='repair'
              defaultRate={rates.repair}
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onChange={onChange}
            />
            <QuoteSection
              key='paint'
              type='paint'
              defaultRate={rates.paint}
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onChange={onChange}
            />
            <QuoteSection
              key='part'
              type='part'
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onChange={onChange}
              onPartOutcome={this.onPartOutcome}
            />
            <QuoteSection
              key='misc'
              type='misc'
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onChange={onChange}
            />
            <QuoteSection
              key='sublet'
              type='sublet'
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onChange={onChange}
            />
            <QuoteSection
              key='add-pdr'
              type='add-pdr'
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onChange={onChange}
            />
          </div>
        </div>
      </Card>
    );
  };
};

