import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

//import Quote from 'components/supplier/Quote';
import Item from './Item.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  height: 100%;
  overflow: hidden;
  font-size: 0.9rem;
`;
const ItemList = styled.div`
  background: ${colors.grey1};
  height: 100%;
  overflow: auto;
`;
const Content = styled.div`
  height: 100%;
  overflow: auto;
  @media (max-width: 768px) {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }
`;


export default class List extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
    };
  }
  componentDidMount() {
    this.props.onLoad();
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  render() {
    const {type, notFound, list, id, item: Details} = this.props;
    console.log('list:render', type);
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load {type}s.</div>
        </ErrorContainer>
      );
    }
    if (list.length === 0) {
      return (
        <Container className='flex-grow-1 p-3'>
          <div className='bg-light p-4 rounded'>No {type}s to be actioned.</div>
        </Container>
      );
    }
    return (
      <Container className='flex-grow-1 row no-gutters position-relative'>
        <ItemList className='col-md-4 p-2'>
          {list.map((q, i) => <Item key={i} type={type} {...q} active={q.id === id} />)}
        </ItemList>
        {id && (
          <Content className='col-md-8 p-3 bg-white'>
            <Details id={id} />
          </Content>
        )}
      </Container>
    );
  };
};

