import moment from 'm';
import numeral from 'numeral';


export default (state = {
  notFound: null,
}, action) => {
  switch (action.type) {
    case 'CLAIM-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'CLAIM-RESET':
      return {
        notFound: null,
      };
    case 'CLAIM-SET':
    {
      const {data: d} = action;
      return {
        notFound: false,
        ...d,
        excess: numeral(d.excess / 100).format('$0,0.00'),
        assessmentBooked: d.assessmentDate !== null,
        assessmentDateStr: d.assessmentDate ? moment(d.assessmentDate * 1000).format('DD-MM-YY HH:mm') : '',
        repairBooked: d.startDate !== null,
        startDateStr: d.startDate ? moment(d.startDate * 1000).format('DD-MM-YY') : 'N/A',
        finishDateStr: d.finishDate ? moment(d.finishDate * 1000).format('DD-MM-YY') : 'N/A',
        isIn: d.inDate !== null,
        inDateStr: d.inDate ? moment(d.inDate * 1000).format('DD-MM-YY HH:mm') : '',
        qaed: d.readyDate !== null && d.qaDate !== null,
        qaDateStr: d.readyDate && d.qaDate ? moment(d.qaDate * 1000).format('DD-MM-YY HH:mm') : '',
        excessInvoiceDateStr: d.excessInvoiceDate ? moment(d.excessInvoiceDate * 1000).format('DD-MM-YY HH:mm') : '',
        isOut: d.outDate !== null,
        outDateStr: d.outDate ? moment(d.outDate * 1000).format('DD-MM-YY HH:mm') : '',
        quoted: d.quoteDate !== null,
        quoteDateStr: d.quoteDate ? moment(d.quoteDate * 1000).format('DD-MM-YY HH:mm') : '',
        authorised: d.authoriseDate !== null,
        authoriseDateStr: d.authoriseDate ? moment(d.authoriseDate * 1000).format('DD-MM-YY HH:mm') : '',
        invoiced: d.invoiceDate !== null,
        invoiceDateStr: d.invoiceDate ? moment(d.invoiceDate * 1000).format('DD-MM-YY HH:mm') : '',
        paid: d.payDate !== null,
        payDateStr: d.payDate ? moment(d.payDate * 1000).format('DD-MM-YY HH:mm') : '',
      };
    }
    default:
      return state;
  }
};
