import {connect} from 'react-redux';
import XLSX from 'xlsx';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Tasks from './component.jsx';


const load = (API, locationId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/tasks/${locationId}`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'TASKS-SET-NOT-FOUND'
          });
          return null;
        }
        const {sections} = resp;
        dispatch({
          type: 'TASKS-SET',
          sections
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const toggleSection = section => ({
  type: 'TASKS-SECTION-TOGGLE',
  section,
});

const save = sections => {
  const wb = XLSX.utils.book_new();
  sections.forEach(({title, tasks}) => {
    const ws = XLSX.utils.aoa_to_sheet(tasks.reduce((acc, j) => {
      acc.push([
        j.jobNumber,
        j.rego,
        j.make,
        j.model,
        j.startDateStr,
        j.finishDateStr,
      ]);
      return acc;
    }, [
      [
        'Job #',
        'Rego',
        'Make',
        'Model',
        'Start',
        'Finish',
      ]
    ]));
    XLSX.utils.book_append_sheet(wb, ws, title);
  });
  XLSX.writeFile(wb, 'tasks.xlsx');
};

const toggleDone = (API, payload) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/tasks`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          action: 'toggle-done',
          ...payload,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error !== true) {
          dispatch({
            type: 'TASKS-SET-DONE',
            ...payload,
            ...resp,
          });
        }
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const changeDateTime = (API, payload) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/tasks`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          action: 'change-date-time',
          ...payload,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error !== true) {
          dispatch({
            type: 'TASKS-SET-DATE-TIME',
            ...payload,
          });
        }
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const changeNote = (API, payload) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/tasks`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          action: 'change-note',
          ...payload,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error !== true) {
          dispatch({
            type: 'TASKS-SET-NOTE',
            ...payload,
          });
        }
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const addComment = (API, payload) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/tasks`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          action: 'add-comment',
          ...payload,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error !== true) {
          dispatch({
            type: 'TASKS-SET-COMMENTS',
            taskId: payload.taskId,
            comments: resp.comments,
          });
        }
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const setProductionEmployee = (API, {jobId, locationId, stage, employee}) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/location/${locationId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({stage, employee})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'TASKS-SET-EMPLOYEE',
          jobId,
          locationId,
          stage,
          employeeId: res[{strip: 'striper', repair: 'repairer'}[stage]],
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = (state, props) => {
  const {
    locationId,
    params: {
      employees: {index: employeeIndex},
      locations: {
        index: locationIndex,
        abilityLists: locationAbilityLists,
      },
    },
    tasks
  } = state;
  return {
    ...tasks,
    employeeIndex,
    locationId,
    locationIndex,
    locationAbilityLists,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: (locationId) => dispatch(load(API, locationId)),
  onToggleSection: section => dispatch(toggleSection(section)),
  onToggleDone: payload => dispatch(toggleDone(API, payload)),
  onChangeDateTime: payload => dispatch(changeDateTime(API, payload)),
  onChangeNote: payload => dispatch(changeNote(API, payload)),
  onAddComment: payload => dispatch(addComment(API, payload)),
  onSetProductionEmployee: payload => dispatch(setProductionEmployee(API, payload)),
  onSave: sections => save(sections),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Tasks)
);
