const OUT = 1.40;


export default side => ({
  panelCode: 'B',
  lineItems: [
    {
      description: `${side}F DOOR`,
      panel: `${side}F-DOOR`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.4,
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR WAIST MOULD`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR FRAME MOULD`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR EXTERNAL HANDLE`,
      settings: {
        [`${side}F-DOOR`]: {
          '^(?!PRIXCAR)': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.13,
                isPart: true,
              }
            }
          },
          },
        '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 0.55,
                hourlyRate: 100,
              }
            }
          },
        },
        }
      }
    },
    {
      description: `${side}F DOOR MOULD`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.28,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR MIRROR BASE/BODY`,
      settings: {
        [`${side}F-DOOR`]: {
          '^(?!PRIXCAR)': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.1,
                paintTime: 0.4,
                isPart: true,
              }
            }
          },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 0.55,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}F DOOR FRAME SEAL`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.2,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR WEATHERSHIELD`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.09,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR TRIM AND HANDLE`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.25,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR VERTICAL MOULD`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR FRAME TAPE`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR FRAME TAPE VERTICAL`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR FRAME TAPE HORIZONTAL`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR WEATHER SHIELD`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR PART D/A`,
      panel: `${side}F-DOOR`,
      subsetOf: [`${side}F DOOR D/A`],
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.81,
                rrAutoPaintable: true,
              }
            }
          },
          },
        },
        [`${side}F-GUARD|${side}R-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.81,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR D/A`,
      panel: `${side}F-DOOR`,
      supersetOf: [`${side}F DOOR PART D/A`],
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 2.67,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR OUT`,
      panel: `${side}F-DOOR`,
      supersetOf: [`${side}F DOOR BLEND`],
      subsetOf: [`${side}F DOOR IN & OUT`],
      settings: {
        [`${side}F-DOOR`]: {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 3,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
              }
            }
          },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 3.5,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}F DOOR IN & OUT`,
      panel: `${side}F-DOOR`,
      supersetOf: [`${side}F DOOR OUT`, `${side}F DOOR BLEND`],
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 3,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.10,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR BLEND`,
      panel: `${side}F-DOOR`,
      subsetOf: [`${side}F DOOR IN & OUT`, `${side}F DOOR OUT`],
      settings: {
        [`${side}F-GUARD|${side}R-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 3,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        },
        [`${side}R-QTR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            'COUPE|HATCH': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 3,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'COUPE|HATCH': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoReplaceable: true,
                paintAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR LOADING 10%`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR LOADING 25%`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR SEALERS NESS`,
      panel: `${side}F-DOOR`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 30.80,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `R&R ${side}F SIDE MIRROR`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `${side}F DOOR MOULD`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                partPrice: 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F DOOR REALIGNMENT`,
      settings: {
        [`${side}F-DOOR`]: {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 100,
              }
            }
          },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `PDR ${side}F DOOR`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BUFF & POLISH ${side}F DOOR`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BRUSH TOUCH ${side}F DOOR`,
      settings: {
        [`${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
});
