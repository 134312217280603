const OUT = 2.04;


module.exports = {
  panelCode: 'C',
  lineItems: [
    {
      description: 'BONNET',
      panel: 'BONNET',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.27,
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET PROTECTOR',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET PAD CLIPS',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET MOULD',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.06,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET BADGE',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.05,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET ID DECAL',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET TUNE DECAL',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET DECAL OTHER',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET WASHER JETS',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET PART D/A',
      panel: 'BONNET',
      subsetOf: ['BONNET D/A'],
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.34,
                rrAutoPaintable: true,
              }
            }
          },
          },
        },
        'LHF-GUARD|RHF-GUARD': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.34,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET D/A',
      panel: 'BONNET',
      supersetOf: ['BONNET PART D/A'],
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.61,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET OUT',
      panel: 'BONNET',
      supersetOf: ['BONNET BLEND'],
      subsetOf: ['BONNET IN & OUT'],
      settings: {
        'BONNET': {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 3.5,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
              }
            }
          },
          },
        '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 4,
                hourlyRate: 100,
              }
            }
          },
        },
        }
      }
    },
    {
      description: 'BONNET IN & OUT',
      panel: 'BONNET',
      supersetOf: ['BONNET OUT', 'BONNET BLEND'],
      settings: {
        'BONNET': {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 3.5,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.97,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET BLEND',
      panel: 'BONNET',
      subsetOf: ['BONNET OUT', 'BONNET IN & OUT'],
      settings: {
        'LHF-GUARD|RHF-GUARD': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 3.5,
                paintAutoReplaceable: true,
                paintAutoPaintable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoReplaceable: true,
                paintAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET LOADING 10%',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET LOADING 25%',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BONNET SEALERS NESS',
      panel: 'BONNET',
      settings: {
        'BONNET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 43.15,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'R&R HEADLIGHTS',
      panel: 'BONNET',
      settings: {
        'BONNET': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 1,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'PDR BONNET',
      settings: {
        'BONNET': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH BONNET',
      settings: {
        'BONNET': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BRUSH TOUCH BONNET',
      settings: {
        'BONNET': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
};
