import Promise from 'bluebird';
import React from 'react';


export default class API {
  constructor(store) {
    this.store = store;
  }
  request(url, params = {}, headers = {'content-type': 'application/json'}) {
    const {authentication} = this.store.getState();
    if (!headers.hasOwnProperty('API-TOKEN') && authentication !== null) {
      const {activeProfile, profiles} = authentication;
      headers['API-TOKEN'] = profiles[activeProfile].token;
    }
    return new Promise((resolve, reject) => {
      fetch(
        url,
        {...params, headers}
      )
        .then(resp => {
          if (resp.status === 200 || resp.status === 400) {
            return resolve(resp.json());
          } else if (resp.status === 401) {
            this.store.dispatch({
              type: 'AUTHENTICATION-RESET'
            });
          }
          throw new Error('error encountered');
        })
        .catch(reject);
    });
  }
  requestForm(url, params) {
    return this.request(url, params, {});
  }
};


const APIContext = React.createContext()
export let APIProvider = ({API, children}) => (
  <APIContext.Provider value={API}>
    {children}
  </APIContext.Provider>
);
export let withApi = (Component) => {
  return props => (
    <APIContext.Consumer>
      {API => <Component API={API} {...props} />}
    </APIContext.Consumer>
  );
}
