import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import JobTitle from '../JobTitle';
import ImageList from '../ImageList';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;

const Stars = ({num}) => (
  <div className='d-flex flex-row'>
    {[1,2,3,4,5].map(i => (
      <Icons.Star
        key={i}
        color={i <= num ? 'gold' : colors.grey4}
        fill={i <= num ? 'gold' : 'none'}
      />
    ))}
  </div>
);

export default class Survey extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
    };
  }
  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {jobId, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
  }

  render() {
    const {jobValid} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }
    const {
      jobId,
      jobNumber,
      flagged,
      vpp,
      vip,
      importUrl,
      survey,
    } = this.props;
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        <ImageList jobId={jobId} />
        <Card className='p-3 mt-3'>
          <div className='d-flex flex-row justify-content-between mb-3'>
            <div className='font-weight-bold mb-2'>Survey{survey !== null && <span className='small text-muted'> {'\u2013'} {survey.ts}</span>}</div>
          </div>
          {survey === null && (
            <div className='text-center text-secondary'>Survey not filled in yet.</div>
          )}
          {survey !== null && (
            <React.Fragment>
              <ol>
                <li className='mb-4'>Were you happy with the estimate and booking process?
                  <Stars num={survey.q1} />
                </li>
                <li className='mb-4'>Were you happy with the presentation of the site & staff?
                  <Stars num={survey.q2} />
                </li>
                <li className='mb-4'>Were you kept informed during the repair process?
                  <Stars num={survey.q3} />
                </li>
                <li className='mb-4'>How would you rate your overall experience?
                  <Stars num={survey.q4} />
                </li>
                <li className='mb-4'>If you would like a follow up call from management please leave your registration details and phone number below.
                  <div className='mt-2'><pre className='text-muted'>{survey.q5 || 'N/A'}</pre></div>
                </li>
                <li className='mb-4'>We appreciate your feedback, do you have any suggestions on how we can improve your experience next time?
                  <div className='mt-2'><pre className='text-muted'>{survey.comments || 'N/A'}</pre></div>
                </li>
              </ol>
            </React.Fragment>
          )}
        </Card>
      </React.Fragment>
    );
  };
};

