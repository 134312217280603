import {connect} from 'react-redux';

import Status from './component.jsx';


const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    jobDetails: {
      bookingType,
      header: {
        debtorId,
        stormId,
        hasOrm,
        hasPrimaryLocation,
        hasPainter,
        isIn,
        isOut,
        outOfScope,
        autoOrmEnabled,
        totalLoss,
        cashSettle,
        authorised,
        convVan,
        convTow,
        convIsOut,
        convIsIn,
        jobSettings,
      },
      totals: {
        total
      },
    },
    params: {
      debtors: {index: debtors},
    },
  } = state;
  const {profile: {abilities: {canJobDetailsViewStatuses}}} = profiles[activeProfile];
  const visible = (
    canJobDetailsViewStatuses === true &&
    stormId !== null &&
    debtorId !== null &&
    hasPrimaryLocation === true
  );
  if (visible !== true) return {visible};
  const {canOrm} = debtors[debtorId];
  const canStatus = total > 0;
  const canOutOfScope = (jobSettings && jobSettings.pricingMethod === 'category');
  return {
    visible,
    hasOrm,
    hasPainter,
    isIn,
    isOut,
    outOfScope,
    canOutOfScope,
    canOrm,
    autoOrmEnabled,
    totalLoss,
    cashSettle,
    canStatus,
    authorised,
    canConv: bookingType === 'paint' || bookingType === 'turret',
    convVan,
    convTow,
    convIsOut,
    convIsIn
  };
};

const mapDispatchToProps = (dispatch, props) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Status);
