import './index.scss';

import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import API, {APIProvider} from 'components/API';
import reducers from './reducers';
import AppCustomer from 'components/AppCustomer';
import AppClaim from 'components/AppClaim';
import AppSurvey from 'components/AppSurvey';
import AppDebtor from 'components/AppDebtor';
import AppInternal from 'components/AppInternal';
import AppSupplier from 'components/AppSupplier';
import Loading from 'components/Loading';
import Login from 'components/Login';
import Protected from 'components/Protected';



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
const api = new API(store);

render(
  <Provider store={store}>
    <BrowserRouter>
      <APIProvider API={api}>
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/customer/:token' component={AppCustomer} />
          <Route path='/claim/:token' component={AppClaim} />
          <Route path='/survey/:token' component={AppSurvey} />
          <Protected>
            <AppInternal />
            <AppDebtor />
            <AppSupplier />
          </Protected>
        </Switch>
        <Loading />
        <ToastContainer position={'bottom-right'} />
      </APIProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('app')
);
