import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Credits from './component.jsx';


const load = (API, mode, creditorId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/credits/${creditorId}/${mode}`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-CREDITS-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'ADMIN-CREDITS-SET',
          credits: resp.credits
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-CREDITS-SET-NULL'
});

const changeMode = mode => ({
  type: 'ADMIN-CREDITS-CHANGE-MODE',
  mode,
});

const changeCreditorId = creditorId => ({
  type: 'ADMIN-CREDITS-CHANGE-CREDITOR-ID',
  creditorId,
});

const collect = (API, creditorId, creditId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/credits`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({creditorId, creditId})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'ADMIN-CREDITS-SET-COLLECTED',
          creditId,
          collectDate: res.collectDate
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    adminCredits: {notFound, mode, creditorId, credits},
    params: {
      partCreditors: {list: creditors},
    }
  } = state;
  return {
    notFound,
    mode,
    creditorId,
    credits,
    creditors,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: (mode, creditorId) => dispatch(load(props.API, mode, creditorId)),
  onUnload: () => dispatch(unload()),
  onChangeMode: mode => dispatch(changeMode(mode)),
  onChangeCreditorId: creditorId => dispatch(changeCreditorId(creditorId)),
  onCollect: (creditorId, creditId) => dispatch(collect(props.API, creditorId, creditId)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Credits)
);
