import moment from 'moment';
import React from 'react';

import Modal from 'components/Modal';


export default class Print extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      dateStr: null,
      lines: [],
      canRfq: false,
    };
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
    this.timeout = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.lines !== prevProps.lines ||
      this.state.open !== prevState.open
    ) {
      const lines = Object.values(this.props.lines).sort((a, b) => a.index - b.index).reduce((acc, l) => {
        if (l) {
          acc.push(l);
        }
        return acc;
      }, []);
      this.setState({
        lines,
        canRfq: lines.length !== 0
      });
    }
  }

  onToggleModal(open) {
    this.setState({
      dateStr: moment().format('DD-MM-YY'),
      open,
    });
    if (open === true) {
      setTimeout(() => {
        window.print();
      }, 1);
    }
  }

  render () {
    console.log('print:render');
    const {
      companyName,
      companyAddress,
      location,
      jobNumber,
      make,
      model,
      vin,
      manDate,
    } = this.props;
    const {open, dateStr, lines, canRfq} = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-primary ml-2'
          onClick={this.onOpen}
          disabled={canRfq !== true}
        >RFQ</button>
        {open === true && (
          <Modal
            open={open}
            onClose={this.onClose}
            fullScreen={true}
          >
            <div className='modal-body'>
              <div className='text-uppercase'>
                <h3>{companyName}</h3>
                <span>{location ? location.address : companyAddress}</span>
              </div>
              <div className='row mt-5'>
                <div className='col'>
                  <div>
                    <strong>Reference #: {jobNumber}</strong>
                  </div>
                  <div>
                    <strong>Make/model:</strong> {make} {model}
                  </div>
                  <div>
                    <strong>VIN:</strong> {vin}
                  </div>
                  <div>
                    <strong>MM/YY:</strong> {manDate}
                  </div>
                </div>
                <div className='col text-right'>
                  <strong>{dateStr}</strong>
                </div>
              </div>
              <div className='mt-5 font-weight-bold'>
                Please provide a QUOTE for the parts listed below:
              </div>
              <table className='table mt-5'>
                <thead>
                  <tr>
                    <th>Line #</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {lines.map((l, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{l.qty}x {l.description} {l.partNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
};
