import moment from 'moment';


export default (state = {openIndex: null, images: []}, action) => {
  switch (action.type) {
    case 'JOB-GALLERY-SET':
      const year = moment().year();
      return {
        openIndex: null,
        images: action.images
          .map(i => {
            const createDate = moment(i.createDate * 1000);
            return {
              ...i,
              createDateStr: createDate.format(
                createDate.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'
              ),
            };
          })
          .sort((a, b) => ((b.pinned ? 1 : 0) - (a.pinned ? 1 : 0)))
      };
    case 'JOB-GALLERY-OPEN':
    {
      const {index} = action;
      return {
        ...state,
        openIndex: index % state.images.length
      };
    }
    case 'JOB-GALLERY-CLOSE':
    {
      return {
        ...state,
        openIndex: null
      };
    }
    case 'JOB-GALLERY-SCROLL':
    {
      const {dir} = action;
      return {
        ...state,
        openIndex: (state.openIndex + state.images.length + dir) % state.images.length
      };
    }
    default:
      return state;
  }
};
