import moment from 'moment';
import numeral from 'numeral';


let currentState = null;
const cs = window.localStorage.getItem('admin-credits:mode');
if (cs) {
  currentState = JSON.parse(cs);
}


export default (state = {notFound: null, mode: currentState, creditorId: null}, action) => {
  switch (action.type) {
    case 'ADMIN-CREDITS-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        credits: null
      };
    case 'ADMIN-CREDITS-SET-NULL':
      return {
        ...state,
        notFound: null,
        credits: null
      };
    case 'ADMIN-CREDITS-CHANGE-MODE':
      const {mode} = action;
      window.localStorage.setItem('admin-credits:mode', JSON.stringify(mode));
      return {
        ...state,
        notFound: null,
        mode,
        credits: null,
      };
    case 'ADMIN-CREDITS-CHANGE-CREDITOR-ID':
      const {creditorId} = action;
      return {
        ...state,
        notFound: null,
        creditorId,
        credits: null,
      };
    case 'ADMIN-CREDITS-SET':
      {
        const year = moment().year();
        return {
          ...state,
          notFound: false,
          credits: action.credits.map(c => {
            const invoiceDate = moment(c.invoiceDate * 1000);
            const creditDate = moment(c.creditDate * 1000);
            const collectDate = c.collectDate ? moment(c.collectDate * 1000) : null;
            const collectDateStr = collectDate ?
              collectDate.format(
                collectDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
              ) : '\u2011';
            return {
              ...c,
              invoiceDateStr: invoiceDate.format(
                invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
              ),
              creditDateStr: creditDate.format(
                creditDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
              ),
              costPriceStr: numeral(c.costPrice / 100).format('$0,0.00'),
              collectDateStr,
            };
          })
        };
      }
    case 'ADMIN-CREDITS-SET-COLLECTED':
      {
        const {creditId, collectDate} = action;
        const collectDateStr = moment(collectDate * 1000).format('DD-MM');
        return {
          ...state,
          credits: state.credits.map(c => {
            if (c.creditId !== creditId) return c;
            return {
              ...c,
              collectDate,
              collectDateStr
            };
          })
        };
      }
    default:
      return state;
  }
};
