import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import JobTitle from '../JobTitle';
import ImageList from '../ImageList';

import Collect from './Collect.jsx';
import EditCredit from './EditCredit.jsx';
import DeleteOrder from './DeleteOrder.jsx';
import DeleteCredit from './DeleteCredit.jsx';
import DeleteSublet from './DeleteSublet.jsx';
import Credit from './Credit.jsx';
import InvoiceOrder from './InvoiceOrder.jsx';
import InvoiceSublet from './InvoiceSublet.jsx';
import Order from './Order.jsx';
import Print from './Print.jsx';
import Process from './Process.jsx';
import Receive from './Receive.jsx';
import RFQ from './RFQ.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const QuoteRow = styled.div`
  ${opts => opts.extra && `color: ${colors.teal};`}
  ${opts => opts.credited && `color: ${colors.orange};`}
  ${opts => opts.backorder && `color: ${colors.grey4};`}
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  :first-of-type {
    padding-top: 0 !important;
  }
  :last-of-type {
    padding-bottom: 0 !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
  }
`;

export default class Purchasing extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      allChecked: false,
      partsSelectedLines: {},
      subletsSelectedLines: {},
      orderLinesSelected: {},
      creditLinesSelected: {},
    };
    this.onToggleAllPartsLines = this.onToggleAllPartsLines.bind(this);
  }

  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  componentDidUpdate(prevProps) {
    const {jobId, orders, credits, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
    if (orders !== prevProps.orders) {
      this.setState({
        orderLinesSelected: {},
      });
    }
    if (credits !== prevProps.credits) {
      this.setState({
        creditLinesSelected: {},
      });
    }
  }

  onToggleAllPartsLines(e) {
    const {parts} = this.props;
    this.setState({
      allChecked: e.target.checked,
      partsSelectedLines: parts.reduce((acc, p) => {
        acc[p.id] = e.target.checked ? p : undefined;
        return acc;
      }, {})
    });
  }

  onTogglePartsLine(part, e) {
    const partsSelectedLines = {
      ...this.state.partsSelectedLines,
      [part.id]: e.target.checked ? part : undefined
    };
    this.setState({
      partsSelectedLines,
    });
  }

  onToggleSubletsLine(part, e) {
    const subletsSelectedLines = {
      ...this.state.subletsSelectedLines,
      [part.id]: e.target.checked ? part : undefined
    };
    this.setState({
      subletsSelectedLines,
    });
  }

  onToggleOrderLine(orderId, orderLine, e) {
    const orderLinesSelected = {
      ...this.state.orderLinesSelected,
      [orderLine.reqId]:
        e.target.checked && (orderLine.canReceive || orderLine.canCredit || orderLine.canEdit) ?
          {orderId, ...orderLine} :
          undefined
    };
    this.setState({
      orderLinesSelected
    });
  }

  onToggleCreditLine(credit, e) {
    const creditLinesSelected = {
      ...this.state.creditLinesSelected,
      [credit.id]: e.target.checked ? credit : undefined
    };
    this.setState({
      creditLinesSelected,
    });
  }

  render () {
    console.log('purchasing:render');
    const {
      jobValid,
    } = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }
    const {
      jobId,
      jobNumber,
      flagged,
      vpp,
      vip,
      importUrl,
      make,
      model,
      vin,
      manDate,
      debtorId,
      primaryLocation,
      locations,
      parts,
      sublets,
      orders,
      credits,
      companyName,
      companyAddress,
      creditorsIndex,
      partCreditors,
      subletCreditors,
      debtorsIndex,
      locationsIndex,
      onOrder,
      onOrderAction,
      onCreditAction,
      onSubletAction,
    } = this.props;
    const {
      allChecked,
      partsSelectedLines,
      subletsSelectedLines,
      orderLinesSelected,
      creditLinesSelected,
    } = this.state;
    const { gstRate } = debtorsIndex[debtorId]
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        <ImageList jobId={jobId} />
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold'>Parts</div>
          <table className='table'>
            <thead>
              <tr>
                <th width='25' className='border-top-0'>
                  <input
                    type='checkbox'
                    onChange={this.onToggleAllPartsLines}
                    checked={allChecked}
                  />
                </th>
                <th width='100' className='border-top-0'>Qty</th>
                <th className='border-top-0'>Description</th>
                <th className='border-top-0'>Part #</th>
                <th className='border-top-0'>Status</th>
              </tr>
            </thead>
            <tbody>
              {parts.length === 0 && (
                <tr>
                  <td colSpan={5} className='text-center text-secondary'>
                    Job does not contain any parts.
                  </td>
                </tr>
              )}
              {parts.length !== 0 && parts.map((p, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type='checkbox'
                      onChange={this.onTogglePartsLine.bind(this, p)}
                      checked={partsSelectedLines[p.id] !== undefined}
                    />
                  </td>
                  <td>{p.qty}</td>
                  <td>{p.description}</td>
                  <td>{p.partNumber}</td>
                  <td>{p.partOutcome}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='d-flex flex-row justify-content-end'>
            <RFQ
              companyName={companyName}
              companyAddress={companyAddress}
              location={locationsIndex[primaryLocation]}
              jobNumber={jobNumber}
              make={make}
              model={model}
              vin={vin}
              manDate={manDate}
              lines={partsSelectedLines}
            />
            <Order
              jobId={jobId}
              lines={partsSelectedLines}
              creditors={partCreditors}
              locations={locations}
              onSend={onOrder}
            />
          </div>
        </Card>
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold'>Orders</div>
          <table className='table'>
            <thead>
              <tr>
                <th className='border-top-0'>ID</th>
                <th className='border-top-0'>Sent</th>
                <th className='border-top-0'>Supplier</th>
                <th className='border-top-0' width='65%'>
                  <div className='d-flex flex-row justify-content-between'>
                    <div className='flex-grow-1 row no-gutters'>
                      <div className='col-1'>Qty</div>
                      <div className='col-4'>Description</div>
                      <div className='col-2 text-center'>Part #</div>
                      <div className='col-1 text-center'>List</div>
                      <div className='col-1 text-center'>Cost</div>
                      <div className='col-1 text-center'>Inv #</div>
                      <div className='col-1 text-center'>Inv date</div>
                      <div className='col-1 text-center'>Recv'ed</div>
                    </div>
                    <input
                      type='checkbox'
                      className='invisible'
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.length === 0 && (
                <tr>
                  <td colSpan={4} className='text-center text-secondary'>
                    Job does not contain any orders.
                  </td>
                </tr>
              )}
              {orders.length !== 0 && orders.map((r, i) => (
                <tr key={i}>
                  <td>{r.id}</td>
                  <td>{r.requestDateStr}</td>
                  <td>
                    {creditorsIndex[r.creditorId].name}
                    <div className='text-secondary'>{r.poNumber}</div>
                  </td>
                  <td>
                    {r.lines.map((l, j) => (
                      <div key={j} className='d-flex flex-row justify-content-between align-items-start'>
                        <QuoteRow
                          className='flex-grow-1 border-bottom'
                          credited={l.isCredited}
                          backorder={l.qtyBackorder !== null && l.qtyBackorder !== 0}
                        >
                          <div className='row no-gutters'>
                            {l.qtyBackorder === null && (
                              <div className='col-1'>{l.reqQty}</div>
                            )}
                            {l.qtyBackorder !== null && l.qtyBackorder === 0 && (
                              <div className='col-1'>{l.qtySupplied}</div>
                            )}
                            {l.qtyBackorder !== null && l.qtyBackorder !== 0 && (
                              <div className='col-1'>
                                {l.qtySupplied} / {l.qtyBackorder}
                              </div>
                            )}
                            <div className='col-4'>
                              {l.location !== '' && <span className='text-danger'>{l.location}</span>} {l.description || l.reqDescription}
                            </div>
                            <div className='col-2 text-center'>{l.partNumber || l.reqPartNumber}</div>
                            <div className='col-1 text-center'>{l.listPriceStr}</div>
                            <div className='col-1 text-center'>{l.costPriceStr}</div>
                            <div className='col-1 text-center'>{l.invoiceNumber || '\u2011'}</div>
                            <div className='col-1 text-center'>{l.invoiceDateStr}</div>
                            <div className='col-1 text-center'>{l.receiveDateStr}</div>
                          </div>
                          <div className='row no-gutters small text-muted'>
                            <div className='col-11 offset-1'>{l.comments}</div>
                          </div>
                        </QuoteRow>
                        <input
                          className='mt-1'
                          type='checkbox'
                          onChange={this.onToggleOrderLine.bind(this, r.id, l)}
                          checked={orderLinesSelected[l.reqId] !== undefined}
                          disabled={
                            l.qtySupplied === 0 ||
                            (
                              l.canDelete === false &&
                              l.canReceive === false &&
                              l.canCredit === false &&
                              l.canEdit === false
                            )
                          }
                        />
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='d-flex flex-row justify-content-between'>
            <DeleteOrder
              jobId={jobId}
              lines={orderLinesSelected}
              onDelete={onOrderAction}
            />
            <div className='mr-auto' />
            <InvoiceOrder
              jobId={jobId}
              lines={orderLinesSelected}
              gstRate={gstRate}
              onSave={onOrderAction}
            />
            <Receive
              jobId={jobId}
              lines={orderLinesSelected}
              onSave={onOrderAction}
            />
            <Credit
              jobId={jobId}
              lines={orderLinesSelected}
              onSave={onOrderAction}
            />
          </div>
        </Card>
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold'>Credits</div>
          <table className='table'>
            <thead>
              <tr>
                <th className='border-top-0' width='20'/>
                <th className='border-top-0'>Supplier</th>
                <th className='border-top-0'>Invoice</th>
                <th className='border-top-0'>Description</th>
                <th className='border-top-0 text-center'>Cost</th>
                <th className='border-top-0'>Credit date</th>
                <th className='border-top-0'>Reason</th>
                <th className='border-top-0'>Collect date</th>
                <th className='border-top-0'>Process date</th>
              </tr>
            </thead>
            <tbody>
              {credits.length === 0 && (
                <tr>
                  <td colSpan={11} className='text-center text-secondary'>Job does not contain any credits.</td>
                </tr>
              )}
              {credits.length !== 0 && credits.map((c, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type='checkbox'
                      onChange={this.onToggleCreditLine.bind(this, c)}
                      checked={creditLinesSelected[c.id] !== undefined}
                    />
                  </td>
                  <td>{creditorsIndex[c.creditorId].name}</td>
                  <td>{c.invoiceNumber} ({c.invoiceDateStr})</td>
                  <td>{c.qty}x {c.description} {c.partNumber}</td>
                  <td className='text-center'>{c.costPriceStr}</td>
                  <td>{c.creditDateStr}</td>
                  <td>{c.reason}</td>
                  <td>{c.collectDate}</td>
                  <td>{c.processDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='d-flex flex-row'>
            <DeleteCredit
              jobId={jobId}
              lines={creditLinesSelected}
              onDelete={onCreditAction}
            />
            <div className='mr-auto' />
            <EditCredit
              jobId={jobId}
              lines={creditLinesSelected}
              onSave={onCreditAction}
            />
            <Print
              companyName={companyName}
              companyAddress={companyAddress}
              location={locationsIndex[primaryLocation]}
              jobId={jobId}
              jobNumber={jobNumber}
              creditors={creditorsIndex}
              lines={creditLinesSelected}
            />
            <Collect
              jobId={jobId}
              lines={creditLinesSelected}
              onSave={onCreditAction}
            />
            <Process
              jobId={jobId}
              lines={creditLinesSelected}
              onSave={onCreditAction}
            />
          </div>
        </Card>
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold'>Sublets</div>
          <table className='table'>
            <thead>
              <tr>
                <th width='25' className='border-top-0' />
                <th className='border-top-0'>Description</th>
                <th className='border-top-0'>Supplier</th>
                <th className='border-top-0'>Inv #</th>
                <th className='border-top-0'>Inv date</th>
                <th className='border-top-0'>Amount</th>
              </tr>
            </thead>
            <tbody>
              {sublets.length === 0 && (
                <tr>
                  <td colSpan={4} className='text-center text-secondary'>
                    Job does not contain any sublets.
                  </td>
                </tr>
              )}
              {sublets.length !== 0 && sublets.map((p, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type='checkbox'
                      onChange={this.onToggleSubletsLine.bind(this, p)}
                      checked={subletsSelectedLines[p.id] !== undefined}
                    />
                  </td>
                  <td>{p.description}</td>
                  <td>{p.creditorId ? creditorsIndex[p.creditorId].name : ''}</td>
                  <td>{p.invoiceNumber}</td>
                  <td>{p.invoiceDateStr}</td>
                  <td>{p.costPriceStr}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='d-flex flex-row justify-content-between'>
            <DeleteSublet
              jobId={jobId}
              lines={subletsSelectedLines}
              onDelete={onSubletAction}
            />
            <InvoiceSublet
              jobId={jobId}
              lines={subletsSelectedLines}
              creditors={subletCreditors}
              onSave={onSubletAction}
            />
          </div>
        </Card>
      </React.Fragment>
    );
  }
};
