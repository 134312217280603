import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import Modal from 'components/Modal';
import Field from 'components/Field.jsx';


const ModalBody = styled.div`
  & .form-group:last-of-type {
    margin-bottom: 0px;
  }
`;

export default class EditCredit extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      creditId: null,
      lines: [],
      canEditCredit: false,
      canSave: false
    };
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout !== null) clearTimeout(this.timeout);
    this.timeout = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lines !== prevProps.lines || this.state.open !== prevState.open) {
      const {lines, canEditCredit} = Object.values(this.props.lines)
        .reduce((acc, l) => {
          if (l) {
            acc.lines.push({
              id: l.id,
              qty: l.qty,
              description: l.description,
              partNumber: l.partNumber,
              costPrice: l.costPrice,
            });
            if (l.canDelete === false) {
              acc.canEditCredit = false;
            }
          }
          return acc;
        }, {lines: [], canEditCredit: true});
      if (lines.length >= 1) {
        const newState = {
          ...this.state,
          lines,
          canEditCredit,
        };
        newState.canSave = this._getCanSave(newState);
        this.setState(newState);
      } else {
        this.setState({
          ...this.state,
          lines: [],
          canEditCredit: false,
          canSave: false,
        });
      }
    }
  }

  onToggleModal(open) {
    this.setState({
      open,
    });
    //if (open === true) {
    //} else {
    //  this.setState({
    //    open,
    //  });
    //}
  }

  _getCanSave(state) {
    return (
      state.lines.filter(l => l.costPrice === '').length === 0
    );
  }

  //onChange(field, v) {
  //  const newState = {
  //    ...this.state,
  //    [field]: v
  //  };
  //  newState.canSave = this._getCanSave(newState);
  //  this.setState(newState);
  //}

  onChangeCost(i, field, v) {
    if (/^[0-9]*\.?([0-9]{0,2})?$/.test(v) === true || v === '') {
      const lines = this.state.lines;
      lines[i][field] = v;
      this.setState({
        lines
      });
      if (this.timeout !== null) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.timeout = null;
        const canSave = this._getCanSave(this.state);
        this.setState({
          canSave
        });
      }, 300);
    }
  }

  onSave() {
    const {jobId, onSave} = this.props;
    const {lines} = this.state;
    onSave(
      jobId,
      {
        action: 'edit',
        lines: lines.map(({id, costPrice}) => ({
          id,
          costPrice,
        }))
      }
    )
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while editing credits.');
        } else {
          toast.success('Credits successfully updated.');
          this.setState({
            open: false,
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while editing credits.');
      });
  }

  render () {
    console.log('invoice:render');
    const {open, lines, canEditCredit, canSave} = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-primary ml-2'
          onClick={this.onOpen}
          disabled={canEditCredit !== true}
        >Edit</button>
        <Modal
          title='Edit'
          open={open}
          wide={true}
          onClose={this.onClose}
        >
          <ModalBody className='modal-body'>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th className='border-top-0 text-center pl-0 pr-1'>Qty</th>
                  <th className='border-top-0 px-1' width='40%'>Description</th>
                  <th className='border-top-0 text-center px-1' width='20%'>Part #</th>
                  <th className='border-top-0 text-center pl-1 pr-0'>Cost</th>
                </tr>
              </thead>
              <tbody>
                {lines.map((l, i) => (
                  <tr key={i}>
                    <td className='align-middle pl-0 pr-1'>
                      {l.qty}
                    </td>
                    <td className='align-middle px-1'>{l.description}</td>
                    <td className='align-middle px-1'>
                      {l.partNumber}
                    </td>
                    <td className='m-0 pl-1 pr-0'>
                      <Field
                        value={l.costPrice || ''}
                        background={colors.grey1}
                        onChange={this.onChangeCost.bind(this, i, 'costPrice')} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalBody>
          <div className='modal-footer bt-0'>
            <button type='button' className='btn btn-secondary' onClick={this.onClose}>Close</button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={this.onSave.bind(this)}
              disabled={canSave !== true}
            >Save</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

