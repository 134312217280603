import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import Header from 'components/debtor/Header';
import Sidebar from 'components/debtor/Sidebar';

import Dashboard from 'components/debtor/Dashboard';
import Report from 'components/debtor/Report';

import colors from 'colors';


const Body = styled.div`
  height: calc(100vh - 64px);
  color: ${colors.body};
`;

export default ({visible, abilities: {canViewDashboard, canViewReports}}) => {
  if (visible !== true) return null;
  return (
    <React.Fragment>
      <Route path='/:active?' component={Header} />
      <Body className='d-flex flex-row'>
        <Route path='/:activePage?/:activeSubPage?' component={Sidebar} />
        {canViewDashboard === true && (
          <Switch>
            <Redirect from='/' to='/dashboard' exact={true} />
            <Route path='/dashboard' exact={true} component={Dashboard} />
          </Switch>
        )}
        {canViewReports === true && (
          <Switch>
            {canViewDashboard !== true && (
              <Redirect from='/' to='/reports' exact={true} />
            )}
            <Route path='/reports' exact={true} component={Report} />
          </Switch>
        )}
      </Body>
    </React.Fragment>
  );
};
