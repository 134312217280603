const OUT = 2.22;


module.exports = {
  panelCode: 'C',
  lineItems: [
    {
      description: 'TURRET',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LH TURRET MOULD',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'RH TURRET MOULD',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LH DRIP MOULD',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'RH DRIP MOULD',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'FRONT SCREEN MOULD',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR SCREEN MOULD',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'AERIAL',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'SUNROOF METAL',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'SUNROOF GLASS',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'HOOD LINER',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LHF GRAB HANDLE',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LHR GRAB HANDLE',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'RHF GRAB HANDLE',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'RHR GRAB HANDLE',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LHF SUNVISOR',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'RHF SUNVISOR',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'FRONT SCREEN',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 150,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'REAR SCREEN',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 150,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TURRET D/A',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 3.59,
                rrAutoReplaceable: true,
              }
            },
          },
          },
        },
      }
    },
    {
      description: 'REAR TRAY D/A',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'UTE2DR|UTE4DR': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 3.74,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TURRET MOULDS X2',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.2,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'ROOF RAILS X2',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.5,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TURRET DRIP MOULDS X2',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.26,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'HEADLINING AND FITTINGS',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TURRET WIRING & HARDWARE NESS',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.5,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TURRET FRAMING AND BRACES UNPICK AND REWELD NESS',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 1.5,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TAILGATE APETURE HARDWARE NESS',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.75,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'TURRET OUT',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 4.95,
                paintAutoPaintable: true,
              }
            },
            'COUPE|HATCH|SEDAN|UTE2DR|UTE4DR|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 4.4,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            },
          },
          },
          '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '^VAN$': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 7,
                hourlyRate: 100,
              }
            },
            '^(?!VAN)': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 5.1,
                hourlyRate: 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TURRET INNER BLENDS NESS',
      panel: 'TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|HATCH|SEDAN|UTE2DR|UTE4DR|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1,
                paintAutoReplaceable: true,
              }
            },
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.5,
                paintAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TURRET LOADING 10%',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
                paintAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TURRET LOADING 25%',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE JAM',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|HATCH|VAN|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1,
                paintAutoReplaceable: true,
              }
            },
            'VAN': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.50,
                paintAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'FRONT SCREEN RR',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'COUPE|HATCH|VAN|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 118.50,
                miscAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'FRONT SCREEN & REAR SCREEN',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'SEDAN|UTE2DR|UTE4DR': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 270,
                miscAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TURRET SEALERS, PANEL BOND & ANTI FLUTTER NESS',
      settings: {
        'TURRET': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 216.45,
                miscAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'PDR TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BRUSH TOUCH TURRET',
      settings: {
        'TURRET': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
};
