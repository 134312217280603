import React from 'react';
import PropTypes from 'prop-types';

const UserDollar = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...otherProps}
    >
      <path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
      <circle cx='8.5' cy='7' r='4' />
      <g transform='translate(1.000000, 4.000000)'>
        <path d='M19.92,1.32 L19.92,11' />
        <path d='M22.0161417,2.97153611 L18.3872043,2.97153611 C17.4519078,2.97153611 16.6937002,3.66941214 16.6937002,4.53028585 C16.6937002,5.39115957 17.4519078,6.0890356 18.3872043,6.0890356 L20.8064959,6.0890356 C21.7417924,6.08903562 22.5,6.78691164 22.5,7.64778534 C22.5,8.50865904 21.7417924,9.20653507 20.8064959,9.20653509 L16.6937002,9.20653509' />
      </g>
    </svg>
  );
};

UserDollar.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UserDollar.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default UserDollar;
