import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import env from 'env';
import {withApi} from 'components/API.jsx';
import List from './component.jsx';


const URLS = {
  order: `${env.API_SERVER}/v1/supplier/orders`,
  quote: `${env.API_SERVER}/v1/supplier/quotes`
};

const load = (API, listType) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(URLS[listType], {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'SUPPLIER-LIST-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'SUPPLIER-LIST-SET',
          list: resp.quotes || resp.orders
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'SUPPLIER-LIST-RESET'
});



const mapStateToProps = (state, props) => {
  const {
    match: {params: {id}}
  } = props;
  const {
    supplierList: {notFound, list},
  } = state;
  return {
    notFound,
    list,
    id,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: () => dispatch(load(props.API, props.type)),
  onUnload: () => dispatch(unload()),
});

export default withRouter(
  withApi(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(List)
  )
);
