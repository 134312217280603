import React from 'react';
import {connect} from 'react-redux';

import Select from 'components/Select.jsx';


class Companies extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.companies !== this.props.companies ||
      nextProps.selected !== this.props.selected ||
      nextProps.error !== this.props.error
    );
  }
  render() {
    console.log('companies:render');
    const {companies, selected, error, onChange} = this.props;
    return (
      <Select
        className='mb-2'
        title='Company'
        placeholder='select a company'
        options={companies}
        textField='name'
        selected={selected}
        error={error}
        onChange={onChange}
      />
    );
  }
};


export default connect(
  (state, props) => {
    let companies = [];
    if (state.params && state.params.hasOwnProperty('companies')) {
      companies = state.params.companies.list;
    }
    return {companies};
  },
  null
)(Companies);
