import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';
import env from 'env';



const Container = styled.div`
  width: 64px;
  @media (min-width: 992px) {
    width: 220px;
  }
  background-color: ${env.APP_ENV === 'PROD' ? colors.grey1 : colors.amberExtraLight};
  overflow-y: auto;

  @media print {
    display: none !important;
  }

  ${({open}) => open && `
    @media not print {
      @media (max-width: 992px) {
        display: flex !important;
      }
    }
  `}

  & a {
    color: ${colors.body};
    text-decoration: none;
  }
  & a.active {
    color: ${colors.primary};
  }

  & a div {
    height: 48px;
    width: 48px;
    line-height: 48px;
  }
  & svg {
    stroke: ${colors.body};
  }
  & a.active div {
    background-color: ${colors.primary};
    box-shadow: 0px 7px 10px rgba(157, 157, 157, 0.5);
  }
  & a.active svg {
    stroke: #fff;
  }
`;
const NavButton = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  line-height: 64px;
  @media (max-width: 992px) {
    justify-content: center;
    width: 64px;
  }
`;
const NavIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;
  line-height: 64px;
  :hover svg {
    stroke: ${colors.primary};
  }
  @media (min-width: 992px) {
    margin-left: 1rem;
  }
`;
const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 64px;
  width: 64px;
  line-height: 64px;
  :hover svg {
    stroke: ${colors.primary};
  }
`;


export default ({open, abilities, activePage, activeSubPage, onLogout}) => (
  <Container
    open={open}
    className='flex-shrink-0 d-none d-lg-flex flex-column justify-content-between'
  >
    <div className='d-flex flex-column justify-content-start'>
      {abilities.canViewDashboard === true && (
        <NavButton to='/dashboard' exact={true}>
          <NavIcon
            className='rounded-circle'
            title='dashboard'
          >
            <Icons.Home width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Home</span>
        </NavButton>
      )}
      {abilities.canViewReports === true && (
        <NavButton to='/reports'>
          <NavIcon
            className='rounded-circle'
            title='admin'
          >
            <Icons.PieChart width={24} height={24} />
          </NavIcon>
          <span className='d-none d-lg-block ml-3'>Report</span>
        </NavButton>
      )}
      <Button
        className='d-lg-none'
        onClick={onLogout}
      >
        <Icons.LogOut width={24} height={24} />
        <span className='d-none d-lg-block ml-3'>Logout</span>
      </Button>
    </div>
  </Container>
);
