import React from 'react';
import PropTypes from 'prop-types';

import colors from 'colors';

const ExpandToggle = props => {
  const {expanded, status, size, ...otherProps } = props;
  const c = {
    ADDED: colors.purple,
    CHANGED: colors.orange,
    ACCEPTED: colors.green,
    ONREPORT: colors.brown,
  }[status] || colors.red;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={colors.grey4}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...otherProps}
    >
      {status === null && (
        <polyline
          points={expanded === true ? '6 10 12 16 18 10' : '10 18 16 12 10 6'}
        />
      )}
      {status !== null && (
        <React.Fragment>
          <circle cx='12' cy='12' r='12' stroke='none' fill={c} />
          <polyline
            points={expanded === true ? '6 10 12 16 18 10' : '10 18 16 12 10 6'}
            stroke='white'
          />
        </React.Fragment>
      )}
    </svg>
  );
};

ExpandToggle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ExpandToggle.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default ExpandToggle;
