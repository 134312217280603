import React from 'react';
import {NavLink} from 'react-router-dom';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';
import Tow from 'icons/tow.jsx';
import Van from 'icons/van.jsx';

import Field from 'components/Field.jsx';
import LocationSelect from 'components/LocationSelect';
import Popover from 'components/Popover.jsx';


const Container = styled.div`
  height: 100%;
  overflow: auto;
  font-size: 0.9rem;
  @media print {
    overflow: visible;
  }
`;
const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Section = styled.div`
  border-radius: 0.5rem;
  background-color: ${opts => opts.empty ? 'inherit' : colors.grey1};
  box-shadow: ${opts => opts.open ? '0px 8px 15px -4px rgba(180, 180, 180, 0.5)' : 'none'};
  :last-of-type {
    margin-bottom: 0px !important;
  }
  @media print {
    display: ${opts => opts.open ? 'block' : 'none'};
  }
`;
const SectionHeader = styled.div`
  cursor: pointer;
`;
const SectionTitle = styled.h6`
  color: ${colors.primary};
`;
const RepairerContainer = styled.div`
  :last-of-type {
    margin-bottom: 0px !important;
  }
  @media print {
    display: ${opts => opts.open ? 'block' : 'none'};
  }
`;
const JobCardContainer = styled.div`
  @media print {
    display: ${opts => opts.selected ? 'block' : 'none'};
    flex: 0 0 100% !important;
    max-width: 100% !important;
    border-bottom: 1px solid ${colors.grey4};
  }
  :last-of-type {
    margin-bottom: 0px !important;
    border: none;
  }
`;
const FilterOverlay = styled.div`
  border-radius: 0.5rem;
  position: absolute;
  background: #ccc;
  opacity: 75%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Filter = styled(Icons.Filter)`
  cursor: pointer;
  stroke: ${opts => opts.checked ? colors.primary : colors.grey4};
`;
const JobCard = styled.div`
  border-radius: 0.5rem;
  background: ${opts => opts.color};
  @media print {
    border: 1px solid ${colors.body};
  }
`;
const Button = styled.button`
  @media print {
    &.btn-secondary svg {
      stroke: ${colors.black};
    }
    &.btn-outline-secondary svg {
      stroke: white;
    }
  }
`;
//const JobCardFlag = styled.div`
//  border-radius: 0.5rem 0rem 0rem 0.5rem;
//  background: ${opts => opts.color};
//  width: 4px;
//  min-height: 100%;
//`;
const JobImg = styled.img`
  align-self: begin;
  border-radius: 0.25rem;
  max-height: 48px;
  width: auto;
  @media print {
    border: 1px solid ${colors.body};
  }
`;
const PainterSelectContainer = styled.div`
  cursor: pointer;
  color: ${colors.grey4};
`;
const PainterThumbnail = styled.div`
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  background: ${opts => opts.bg};
  color: #fff;

  svg {
    stroke: #fff;
  }
`;
const TaskEmployee = styled.div`
  cursor: pointer;
  font-weight: ${opts => opts.selected ? 'bold' : 'normal'};
  :hover {
    background: ${colors.grey1};
  }
`;




class PainterSelect extends React.Component {
  constructor(opts) {
    super(opts);
    this.ref = null;
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    if (this.ref !== null) {
      const {jobId, painter, onToggle} = this.props;
      onToggle(jobId, painter, this.ref);
    }
  }
  render() {
    const {painter, employees} = this.props;
    return (
      <PainterSelectContainer
        ref={ref => this.ref = ref}
        className='d-flex flex-column align-items-center'
      >
        <PainterThumbnail
          className='rounded-circle'
          onClick={this.onClick}
          bg={
            painter === null ?
              colors.grey4 :
              employees[painter].avatarBg
          }
        >
          {painter === null ?
            <Icons.User width={16} height={16} /> :
            <span title={employees[painter].name}>{employees[painter].initials}</span>
          }
        </PainterThumbnail>
      </PainterSelectContainer>
    );
  }
};

class Job extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      note: opts.note,
    }
    this.onToggleJob = this.onToggleJob.bind(this);
    this.onBlurNote = this.onChange.bind(this, 'note');
  }
  componentDidUpdate(prevProps) {
    if (prevProps.note !== this.props.note) {
      this.setState({
        note: this.props.note
      });
    }
  }
  onToggleJob() {
    const {id, onToggleJob} = this.props;
    onToggleJob(id);
  }
  onChange(field, e) {
    if (field === '_note') {
      this.setState({
        note: e
      });
    } else if (field === 'note') {
      const {id, onChange} = this.props;
      onChange(id, {
        note: this.state.note,
      });
    } else {
      const {id, onChange} = this.props;
      onChange(id, {
        [field]: e
      });
    }
  }
  render() {
    const {
      employees,
      id,
      selected,
      filter,
      flag,
      jobNumber,
      rego,
      outDateStr,
      inDateStr,
      makeModel,
      image64,
      striper,
      repairer,
      painter,
      van,
      tow,
      isOut,
      isIn,
    } = this.props;
    const {note} = this.state;
    const filtered = (
      (filter === 'van' && van !== true) ||
      (filter === 'tow' && tow !== true)
    );
    return (
      <JobCardContainer
        className={
          filtered ?
            'col-sm-12 col-md-6 col-lg-4 mt-2 d-print-none' :
            'col-sm-12 col-md-6 col-lg-4 mt-2'
        }
        selected={selected}
      >
        <JobCard
          className='d-flex flex-row h-100 mx-1 position-relative'
          color={colors[flag]}
        >
          {filtered && (
            <FilterOverlay />
          )}
          <div className='flex-grow-1 p-2'>
            <div className='d-flex flex-row'>
              <div className='d-flex flex-column justify-content-between flex-grow-1 mr-1'>
                <div className='row no-gutters'>
                  <div className='col-4'>
                    <NavLink
                      to={`/job/${id}`}
                      className='text-body font-weight-bold'
                    >
                      {jobNumber}
                    </NavLink>
                    <Filter
                      className='ml-2 d-print-none'
                      width={16}
                      height={16}
                      checked={selected}
                      onClick={this.onToggleJob}
                    />
                  </div>
                  <div className='col-4 text-center'>{rego}</div>
                  <div className='col-4 text-right'>{outDateStr} | {inDateStr}</div>
                </div>
                <div className='row no-gutters align-items-center'>
                  <div className='col-4 text-truncate text-uppercase'>{makeModel}</div>
                  <div className='col-4 text-center text-truncate text-uppercase'>
                    {employees[striper].name} | {employees[repairer].name}
                  </div>
                  <div className='col-4 d-flex flex-row justify-content-end'>
                    <PainterSelect
                      jobId={id}
                      painter={painter}
                      employees={employees}
                      onToggle={this.props.onPainterSelectToggle}
                    />
                  </div>
                </div>
              </div>
              {image64 && <JobImg src={image64} className='d-none d-md-block ml-1 d-print-none' />}
            </div>
            <div className='row no-gutters align-items-center mt-2'>
              <div className='col-3'>
                <div className='btn-group w-100'>
                  <Button
                    type='button'
                    className={van ?
                      'btn btn-secondary w-100' :
                      'btn btn-outline-secondary w-100'
                    }
                    onClick={this.onChange.bind(this, 'van', !van)}
                  ><Van width={18} height={18} /></Button>
                  <Button
                    type='button'
                    className={tow ?
                      'btn btn-secondary w-100' :
                      'btn btn-outline-secondary w-100'
                    }
                    onClick={this.onChange.bind(this, 'tow', !tow)}
                  ><Tow width={18} height={18} /></Button>
                </div>
              </div>
              <div className='col-3 px-1'>
                <div className='btn-group w-100'>
                  <Button
                    type='button'
                    className={isOut ?
                      'btn btn-secondary w-100' :
                      'btn btn-outline-secondary w-100'
                    }
                    disabled={painter === null}
                    onClick={this.onChange.bind(this, 'isOut', !isOut)}
                  >
                    <Icons.LogOut width={18} height={18} />
                  </Button>
                  <Button
                    type='button'
                    className={isIn ?
                      'btn btn-secondary w-100' :
                      'btn btn-outline-secondary w-100'
                    }
                    disabled={painter === null || isOut !== true}
                    onClick={this.onChange.bind(this, 'isIn', !isIn)}
                  >
                    <Icons.LogIn width={18} height={18} />
                  </Button>
                </div>
              </div>
              <Field
                className='col-6 mb-0'
                background={colors.grey2}
                uppercaseOnly={true}
                value={note}
                onChange={this.onChange.bind(this, '_note')}
                onBlur={this.onBlurNote}
              />
            </div>
          </div>
        </JobCard>
      </JobCardContainer>
    );
  }
}

export default class Conventional extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      popover: null,
    };
    this.renderSection = this.renderSection.bind(this);
    this.renderJob = this.renderJob.bind(this);
    this.onToggleFilter = this.onToggleFilter.bind(this);
    this.onPainterSelectClose = this.onPainterSelectClose.bind(this);
    this.onPainterSelectToggle = this.onPainterSelectToggle.bind(this);
  }
  componentDidMount() {
    const {locationId, onLoad} = this.props;
    if (locationId) {
      onLoad(locationId);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.locationId && this.props.locationId !== prevProps.locationId) {
      this.props.onLoad(this.props.locationId);
    }
  }
  onToggleFilter(e) {
    const {filter, onToggleFilter} = this.props;
    if (filter === null) onToggleFilter('van');
    else if (filter === 'van') onToggleFilter('tow');
    else onToggleFilter(null);
  }
  onToggleSection(section) {
    this.props.onToggleSection(section);
  }
  onTogglePainter(painter) {
    this.props.onTogglePainter(painter);
  }
  onPainterSelectClose() {
    this.setState({
      popover: null
    });
  }
  onPainterSelectToggle(jobId, selected, ref) {
    if (this.state.popover !== null && this.state.popover.ref === ref) {
      this.setState({
        popover: null
      });
    } else {
      this.setState({
        popover: {
          jobId,
          selected,
          ref
        }
      });
    }
  }
  onPainterSelect(painter) {
    const {popover:{jobId}} = this.state;
    const {onChange} = this.props;
    onChange(jobId, {
      painter,
    });
    this.setState({popover: null});
  }
  //onChange(jobId, field, e) {
  //  const {onChange} = this.props;
  //  onChange(jobId, {
  //    [field]: e
  //  });
  //}
  renderJob(jobId, i) {
    const {
      filter,
      jobIndex,
      employeeIndex,
      onToggleJob
    } = this.props;
    const job = jobIndex[jobId];
    return (
      <Job
        key={i}
        employees={employeeIndex}
        filter={filter}
        {...job}
        onPainterSelectToggle={this.onPainterSelectToggle}
        onToggleJob={onToggleJob}
        onChange={this.props.onChange}
      />
    );
  }
  renderRepairer(section, {painter, jobs}, i) {
    const {employeeIndex, painterStates} = this.props;
    const open = painterStates[painter] === true;
    const Chevron = open ? Icons.Minus : Icons.Plus;
    return (
      <RepairerContainer key={i} className='mt-2' open={open}>
        <div
          className='d-flex flex-row justify-content-start align-items-center cursor-pointer px-2 pb-1'
          onClick={this.onTogglePainter.bind(this, painter)}
        >
          <Chevron className='mr-2 d-print-none' width={18} height={18} color={colors.body} />
          <div className='font-weight-bold'>{employeeIndex[painter].name}</div>
        </div>
        <div className='row no-gutters'>
          {open === true && jobs.map(this.renderJob)}
        </div>
      </RepairerContainer>
    );
  }
  renderSection({title, key, grouped, jobs, painters, numJobs}, i) {
    const {sectionStates} = this.props;
    const open = (sectionStates[key] === true);
    if (numJobs === 0) {
      return (
        <Section key={i} className='p-2 mb-2' empty={true}>
          <h6 className='mb-0'>{title} (0)</h6>
        </Section>
      );
    }
    const Chevron = open ? Icons.ChevronUp : Icons.ChevronDown;
    if (grouped === true) {
      return (
        <Section key={i} className='p-2 mb-2' open={open}>
          <SectionHeader
            className='d-flex flex-row justify-content-between align-items-center'
            onClick={this.onToggleSection.bind(this, key)}
          >
            <SectionTitle className='mb-0'>{title} ({numJobs})</SectionTitle>
            <Chevron className='d-print-none' color={colors.primary} />
          </SectionHeader>
          {open === true && painters.map(this.renderRepairer.bind(this, i))}
        </Section>
      );
    }
    return (
      <Section key={i} className='p-2 mb-2' open={open}>
        <SectionHeader
          className='d-flex flex-row justify-content-between align-items-center'
          onClick={this.onToggleSection.bind(this, key)}
        >
          <SectionTitle className='mb-0'>{title} ({numJobs})</SectionTitle>
          <Chevron className='d-print-none' color={colors.primary} />
        </SectionHeader>
        <div className='row no-gutters'>
          {open === true && jobs.map(this.renderJob)}
        </div>
      </Section>
    );
  }
  render() {
    console.log('conventional:render');
    const {notFound, painters, sections, filter} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to jobs.</div>
        </ErrorContainer>
      );
    }
    const {popover} = this.state;
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h5 className='mb-0'>Conventional</h5>
          <div className='d-flex flex-row'>
            <button
              type='button'
              className='btn btn-link text-uppercase text-secondary text-decoration-none mr-3'
              onClick={this.onToggleFilter}
            >{filter || 'ALL'}</button>
            <LocationSelect />
          </div>
        </div>
        {notFound === false && sections.map(this.renderSection)}
        {popover &&
          <Popover
            target={popover.ref}
            onClose={this.onPainterSelectClose}
          >
            <TaskEmployee
              className='text-secondary p-2'
              selected={popover.selected === null}
              onClick={this.onPainterSelect.bind(this, null)}
            >N/A</TaskEmployee>
            {painters.map((p, i) => (
              <TaskEmployee
                key={i}
                className='p-2'
                selected={popover.selected === p.id}
                onClick={this.onPainterSelect.bind(this, p.id)}
              >{p.name}</TaskEmployee>
            ))}
          </Popover>
        }
      </Container>
    );
  }
};
