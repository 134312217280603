
let currentState = null;
const cs = window.localStorage.getItem('locationId');
if (cs) {
  currentState = JSON.parse(cs);
}


export default (state = currentState, action) => {
  switch (action.type) {
    case 'LOCATION-ID-RESET':
    {
      window.localStorage.setItem('locationId', JSON.stringify(null));
      return null;
    }
    case 'LOCATION-ID-SET':
    {
      const {locationId} = action;
      window.localStorage.setItem('locationId', JSON.stringify(locationId));
      return locationId;
    }
    default:
      return state;
  }
};
