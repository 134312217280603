import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Status from './component.jsx';


const load = API => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/status`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-STATUS-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'ADMIN-STATUS-SET',
          ...resp
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-STATUS-SET-NULL'
});

const mapStateToProps = (state, props) => {
  const {
    adminStatus,
  } = state;
  return adminStatus;
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: () => dispatch(load(API)),
  onUnload: () => dispatch(unload()),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Status)
);
