import {connect} from 'react-redux';

import QuoteSection from './component.jsx';

const DEFAULT_LINEITEMS = {index: {}, list: []};

const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    jobDetails: {header: {hasOrm}, quote, lineItems}
  } = state;
  const {
    profile: {
      abilities: {
        canJobDetailsViewQuotePrices,
        canJobDetailsViewQuoteAdjPdr,
        canJobDetailsViewQuoteFitParts,
        canJobDetailsSave
      }
    }
  } = profiles[activeProfile];
  return {
    canJobDetailsViewQuotePrices,
    canJobDetailsViewQuoteAdjPdr,
    canJobDetailsViewQuoteFitParts,
    canJobDetailsSave,
    hasOrm,
    lineItems: lineItems[props.type] || DEFAULT_LINEITEMS,
    rows: quote[props.type]
  };
};

export default connect(
  mapStateToProps,
  null
)(QuoteSection);
