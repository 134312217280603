import numeral from 'numeral';

import moment, {_moment} from 'm';


export default (state = {notFound: null, open: false}, action) => {
  switch (action.type) {
    case 'JOB-EXCESS-INVOICE-SET-NOT-FOUND':
      return {
        notFound: true,
        open: state.open,
      };
    case 'JOB-EXCESS-INVOICE-RESET':
      return {
        notFound: null,
        open: false,
      };
    case 'JOB-EXCESS-INVOICE-OPEN':
      return {
        ...state,
        open: true
      };
    case 'JOB-EXCESS-INVOICE-SET':
      const {
        jobNumber,
        excessInvoiceNumber,
        excessInvoiceDate,
        name,
        email,
        make,
        model,
        rego,
        claimNumber,
        excess,
        startDate,
        finishDate
      } = action;
      return {
        notFound: false,
        open: state.open,
        jobNumber,
        excessInvoiceNumber,
        excessInvoiceDate: _moment(excessInvoiceDate * 1000).format('DD-MM-YYYY'),
        name,
        email,
        makeModel: [make, model].filter(f => f && f.length).join(' '),
        rego,
        claimNumber,
        excess: numeral(excess / 100).format('$0,0.00'),
        startDate: startDate ? moment(startDate * 1000).format('DD-MM-YYYY') : 'N/A',
        finishDate: finishDate ? moment(finishDate * 1000).format('DD-MM-YYYY') : 'N/A',
      };
    default:
      return state;
  }
};
