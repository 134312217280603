import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  overflow-y: auto;
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
//const SearchButton = styled.button`
//  width: 65px;
//  & svg {
//    stroke: #fff;
//  }
//`;

export default class Report extends React.Component {
  //constructor(opts) {
  //  super(opts);
  //}
  componentDidMount() {
    const {stormId, onLoad} = this.props;
    onLoad(stormId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {stormId, onLoad} = this.props;
    if (stormId !== prevProps.stormId) {
      onLoad(stormId);
    }
  }
  render() {
    console.log('stormactivity:render');
    const {
      notFound,
      storms,
      stormId,
      report,
      onChangeStormId,
    } = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load report.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h5 className='mb-0'>Storm activity report</h5>
          <Select
            align='right'
            background={colors.grey1}
            textField='name'
            options={storms}
            selected={stormId}
            onChange={onChangeStormId}
          />
        </div>
        {report !== null && (
          <Card className='p-3 mt-4'>
            {report.map((r, i) => (
              <div className='row py-1' key={i}>
                <div className='col-6 col-sm-2 font-weight-bold'>{r.title}</div>
                <div className='col-6 col-sm-1'>{r.count}</div>
              </div>
            ))}
          </Card>
        )}
      </Container>
    );
  };
};



