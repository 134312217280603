var max_dent_count_bonnet = {1:480,2:320,3:240,4:160};
var max_dent_count_turret = {1:720,2:480,3:370,4:320};
var max_dent_count_tailgate = {1:65,2:40,3:35,4:25};
var max_dent_count_boot = {1:200,2:140,3:100,4:60};
var max_dent_count_guard = {1:60,2:40,3:30,4:15};
var max_dent_count_cantrail = {1:120,2:70,3:45,4:32};
var max_dent_count_door = {1:80,2:50,3:30,4:16};
var max_dent_count_qtr = {1:120,2:70,3:45,4:32};
var max_dent_count_plenum = {1:60,2:35,3:22,4:16};


export default {
  BONNET: max_dent_count_bonnet,
  BOOT: max_dent_count_boot,
  'LH-CANTRAIL': max_dent_count_cantrail,
  'RH-CANTRAIL': max_dent_count_cantrail,
  'LHF-DOOR': max_dent_count_door,
  'RHF-DOOR': max_dent_count_door,
  'LHR-DOOR': max_dent_count_door,
  'RHR-DOOR': max_dent_count_door,
  'LHF-GUARD': max_dent_count_guard,
  'RHF-GUARD': max_dent_count_guard,
  'LHR-QTR': max_dent_count_qtr,
  'RHR-QTR': max_dent_count_qtr,
  PLENUM: max_dent_count_plenum,
  TURRET: max_dent_count_turret,
  'TAILGATE': max_dent_count_tailgate,
  'LOWER-TAILGATE': max_dent_count_tailgate,
  'UPPER-TAILGATE': max_dent_count_tailgate
};

