import numeral from 'numeral';

import moment, {_moment} from 'm';


export default (state = {notFound: null, jobId: null, jobNumber: null, parts: []}, action) => {
  switch (action.type) {
    case 'JOB-PURCHASING-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-PURCHASING-SET-FOUND':
      const{
        jobId,
        jobNumber,
        flagged,
        vpp,
        vip,
        importedFromArms,
        stormId,
        make,
        model,
        vin,
        manDate,
        debtorId,
        partCreditors,
        subletCreditors,
      } = action;
      return {
        ...state,
        notFound: false,
        jobId,
        jobNumber,
        flagged,
        vpp,
        vip,
        importUrl: importedFromArms ?
          `https://www.arms1.com.au/search?search-quote-number=${jobNumber}` :
          null,
        make,
        model,
        vin,
        manDate,
        stormId,
        debtorId,
        partCreditors,
        subletCreditors,
      };
    case 'JOB-PURCHASING-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
        parts: [],
      };
    case 'JOB-PURCHASING-SET-LOCATIONS':
      const {locations} = action;
      return {
        ...state,
        locations,
        primaryLocation: locations.reduce((acc, l) => {
          if (l.isPrimary === true) return l.id;
          return acc;
        }, null),
      };
    case 'JOB-PURCHASING-SET-PARTS':
      const {parts} = action;
      return {
        ...state,
        parts: parts.reduce((acc, p, index) => {
          acc.push({
            ...p,
            index
          });
          return acc;
        }, []),
      };
    case 'JOB-PURCHASING-SET-SUBLETS':
      const {sublets} = action;
      const year = _moment().year();
      sublets.forEach(sublet => {
        sublet.canEdit = sublet.processDate === null && sublet.payDate === null;
        sublet.canDelete = (sublet.canEdit && sublet.invoiceId !== null);
        const invoiceDate = sublet.invoiceDate !== null ?
          moment(sublet.invoiceDate * 1000) :
          '';
        sublet.invoiceDateStr = invoiceDate ? invoiceDate.format(
          invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YY'
        ) : '';
        sublet.costPriceStr = sublet.costPrice !== null ?
          numeral(sublet.costPrice / 100).format('$0,00.00') :
          '';
        sublet.costPrice = sublet.costPrice !== null ? sublet.costPrice / 100 : null;
      });
      return {
        ...state,
        sublets,
      };
    case 'JOB-PURCHASING-SET-ORDERS':
    {
      const {orders} = action;
      const year = _moment().year();

      const credits = [];
      orders.forEach(order => {
        const creditIndex = {};
        order.credits.forEach(c => {
          c.creditorId = order.creditorId;
          c.canDelete = c.processDate === null && c.payDate === null;
          c.canCollect = false;
          c.canProcess = false;

          const invoiceDate = moment(c.invoiceDate * 1000);
          c.invoiceDateStr = invoiceDate ? invoiceDate.format(
            invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YY'
          ) : '';

          c.costPriceStr = c.costPrice !== null ?
            numeral(c.costPrice / 100).format('$0,00.00') :
            '';
          c.costPrice = c.costPrice !== null ? c.costPrice / 100 : null;

          const creditDate = _moment(c.creditDate * 1000);
          c.creditDateStr = creditDate.format(
            creditDate.year() === year ? 'DD-MM' : 'DD-MM-YY'
          );
          if (c.collectDate !== null) {
            c.canCollect = false;
            c.canProcess = true;

            const collectDate = _moment(c.collectDate * 1000);
            c.collectDate = collectDate.format(
              collectDate.year() === year ? 'DD-MM' : 'DD-MM-YY'
            );
            if (c.processDate !== null) {
              c.canProcess = false;
              const processDate = _moment(c.processDate * 1000);
              c.processDate = processDate.format(
                processDate.year() === year ? 'DD-MM' : 'DD-MM-YY'
              );
            }
          } else {
            c.canCollect = true;
          }

          creditIndex[c.invoiceLineId] = true;
          credits.push(c);
        });

        const requestDate = _moment(order.createDate * 1000);
        order.requestDateStr = requestDate.format(
          requestDate.year() === year ? 'DD-MM' : 'DD-MM-YY'
        );
        
        order.lines.forEach((line, index) => {
          line.canEdit = line.processDate === null && line.payDate === null;
          line.index = index;
          line.canReceive = false;
          line.canCredit = false;
          line.isCredited = false;
          line.costPrice = line.costPrice !== null ? line.costPrice / 100 : null;
          line.costPriceStr = line.costPrice !== null ?
            numeral(line.costPrice).format('$0,00.00') :
            '\u2011';
          line.listPrice = line.listPrice !== null ? line.listPrice / 100 : null;
          line.listPriceStr = line.listPrice !== null ?
            numeral(line.listPrice).format('$0,00.00') :
            '\u2011';
          line.invoiceDateStr = '\u2011';
          line.receiveDateStr = '\u2011';

          if (line.invoiceDate !== null) {
            line.canReceive = true;

            const invoiceDate = moment(line.invoiceDate * 1000);
            line.invoiceDateStr = invoiceDate.format(
              invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YY'
            );

            if (line.receiveDate !== null) {
              //line.canReceive = false;

              const receiveDate = _moment(line.receiveDate * 1000);
              line.receiveDateStr = receiveDate.format(
                receiveDate.year() === year ? 'DD-MM' : 'DD-MM-YY'
              );

              if (creditIndex[line.invoiceLineId] === true) {
                line.canEdit = false;
                line.isCredited = true;
                line.canCredit = false;
              } else {
                line.canCredit = true;
              }
            }
          }
        });
      });
      return {
        ...state,
        orders,
        credits,
      };
    }
    default:
      return state;
  }
};
