import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';

import LocationSelect from 'components/LocationSelect';


const Container = styled.div`
  overflow: auto;
`;
const Row = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;
const Col = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  :last-of-type {
    margin-bottom: 0px !important;
  }
`;
const Tile = styled.div`
  border-radius: 0.5rem;
  background: ${colors.grey1};
`;
const Table = styled.table`
  td {
    padding: 0.5rem;
  }
  > tbody > tr:last-child td {
    padding-bottom: 0px;
  }
`;


export default class Jobsheets extends React.Component {
  render() {
    const {
      notFound,
      filteredEmployee,
      jobs,
      locationId,
      abilityIndex,
      employeeIndex,
      employeeList,
      employeeJobs,
    } = this.props;
    const abilities = abilityIndex[locationId];
    return (
      <Container className='flex-grow-1 px-3 pt-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h5 className='mb-0'>Production jobsheets</h5>
          <LocationSelect />
        </div>
        {notFound === false && (
          <Row className='row'>
          {
            employeeList.map((eId, i) => {
              if (filteredEmployee !== null) {
                if (filteredEmployee === 'repairers') {
                  if (
                    !abilities[eId] ||
                    (
                      abilities[eId].canRr !== true &&
                      abilities[eId].canRepair !== true
                    )
                  ) {
                    return null;
                  }
                } else if (filteredEmployee === 'painters') {
                  if (
                    !abilities[eId] ||
                    abilities[eId].canPaint !== true
                  ) {
                    return null;
                  }
                } else if (filteredEmployee !== eId) {
                  return null;
                }
              }
              return (
                <Col key={i} className='col-sm-6 col-lg-4 mb-3'>
                  <Tile className='h-100 p-2'>
                    <div className='text-center font-weight-bold mb-1'>
                      {employeeIndex[eId].name}
                    </div>
                    <Table className='table mb-0'>
                      <thead>
                        <tr>
                          <th className='border-top-0'>#</th>
                          <th className='border-top-0'>Start/Finish</th>
                          <th className='border-top-0'>Rego/Make</th>
                          <th className='border-top-0'>Colour/Task</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!employeeJobs[eId] && (
                          <tr><td colSpan={4} className='text-center'>No jobs allocated</td></tr>
                        )}
                        {employeeJobs[eId] && employeeJobs[eId].map((j, i) => (
                          <tr key={i}>
                            <td>
                              <Link to={`/job/${j.jobId}`}>
                                <strong className='text-body'>{jobs[j.jobId].jobNumber}</strong>
                              </Link>
                            </td>
                            <td>
                              {jobs[j.jobId].startDateStr}
                              <br />
                              {jobs[j.jobId].finishDateStr}
                            </td>
                            <td>
                              {jobs[j.jobId].rego}
                              <br />
                              {jobs[j.jobId].makeModel}
                            </td>
                            <td className='text-uppercase'>
                              {jobs[j.jobId].colour}
                              <br />
                              {j.role}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tile>
                </Col>
              );
            })
          }
          </Row>
        )}
      </Container>
    );
  }
};
