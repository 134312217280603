import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';


const Input = styled.div`
  > input {
    width: ${opts => opts.width};
    box-shadow: none;
    color: ${opts => opts.color};
    background-color: ${opts => opts.background} !important;
    border-radius: 0.5rem;
    cursor: pointer;
    border-color: ${opts => opts.error ? colors.red : '#fff'};
  }
`;
const OCRInput = styled.label`
  cursor: pointer;
  position: absolute !important;
  right: 1rem !important;
  font-size: 0.63rem;
  line-height: 2.5rem;
  height: 2.5rem;
  padding-right: 0.5rem;
  & svg {
    stroke: ${colors.body};
  }
`;

export default class Select extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      active: false
    };
    this.onChange = this.onChange.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.error !== this.props.error
    );
  }
  onChange(e) {
    const {onChange} = this.props;
    onChange(e.target.value.toUpperCase());
  }
  onUpload(e) {
    e.preventDefault();
    const {onUpload} = this.props;
    const images = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (['image/png', 'image/jpeg'].indexOf(files[i].type) !== -1) {
        images.push(files[i]);
      }
    }
    if (images.length > 0) {
      onUpload(images[0]);
    }
    e.target.value = null;
  }
  render() {
    const {className, title, value, error} = this.props;
    return (
      <div className={className}>
        <small className='text-muted'>{title}</small>
        <Input
          className='m-0'
          error={error}
        >
          <OCRInput htmlFor={`ocrinput${title}`}>
            <Icons.Camera width={20} height={20} />
            <input
              id={`ocrinput${title}`}
              className='d-none'
              type='file'
              accept='image/*'
              onChange={this.onUpload.bind(this)}
            />
          </OCRInput>
          <input
            type='text'
            className='form-control text-uppercase p-0'
            value={value}
            onChange={this.onChange}
          />
        </Input>
      </div>
    );
  }
};

