import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import SearchBar from './component.jsx';


const search = (API, query) => {
  const url = new URL(`${env.API_SERVER}/v1/internal/search`);
  url.searchParams.append('q', query);
  return API.request(url, {method: 'GET'})
};


const mapStateToProps = (state, props) => {
  const {authentication: {activeProfile, profiles}} = state;
  const {profile: {abilities: {canSearch}}} = profiles[activeProfile];
  return {
    canSearch,
  };
};

const ocr = (API, image) => {
  const body = new FormData();
  body.append('image', image);
  return API.requestForm(
    `${env.API_SERVER}/v1/internal/search/ocr`,
    {method: 'PUT', body}
  )
};

const mapDispatchToProps = (dispatch, props) => ({
  onSearch: query => search(props.API, query),
  onOcr: query => ocr(props.API, query),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchBar)
);

