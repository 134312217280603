import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import Header from 'components/supplier/Header';

import Order from 'components/supplier/Order';
//import Quote from 'components/supplier/Quote';
import List from 'components/supplier/List';
import UserDetails from 'components/supplier/UserDetails';

import colors from 'colors';


const Body = styled.div`
  height: calc(100vh - 64px);
  color: ${colors.body};
`;

export default ({visible}) => {
  if (visible !== true) return null;
  return (
    <React.Fragment>
      <UserDetails />
      <Route path='/:active?' component={Header} />
      <Body className='d-flex flex-row'>
        <Switch>
          <Redirect from='/' to='/orders' exact={true} />
          <Route path='/orders/:id?' render={opts => <List type='order' item={Order} />} />
        </Switch>
      </Body>
    </React.Fragment>
  );
};

        //<Switch>
        //  <Redirect from='/' to='/quotes' exact={true} />
        //  <Route path='/quotes/:id?' render={opts => <List type='quote' item={Quote} />} />
        //</Switch>

          //<Route path='/quotes/:id?' component={Quotes} />

          //<Switch>
          //  <Redirect from='/' to='/dashboard' exact={true} />
          //  <Route path='/dashboard' exact={true} component={Dashboard} />
          //</Switch>
          //<Route path='/orders' exact={true} component={Orders} />
          //<Route path='/credits' exact={true} component={Credits} />

