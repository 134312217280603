import React from 'react';
import {connect} from 'react-redux';

import Select from 'components/Select.jsx';


class Debtors extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.debtors !== this.props.debtors ||
      nextProps.selected !== this.props.selected ||
      nextProps.error !== this.props.error
    );
  }
  render() {
    console.log('debtors:render');
    const {debtors, selected, error, onChange} = this.props;
    return (
      <Select
        className='mb-2'
        title='Debtor'
        placeholder='select a debtor'
        options={debtors}
        textField='name'
        selected={selected}
        error={error}
        onChange={onChange}
      />
    );
  }
};


export default connect(
  (state, props) => {
    let debtors = [];
    if (state.params && state.params.hasOwnProperty('debtors')) {
      debtors = state.params.debtors.list;
    }
    return {debtors};
  },
  null
)(Debtors);
