import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {withApi} from 'components/API.jsx';

import env from 'env';
import AppInternal from './component.jsx';


const loadParams = (API, currentVersion) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/params?version=${currentVersion}`,
      {method: 'GET'}
    )
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        if(currentVersion !== resp.version) {
          dispatch({
            type: 'PARAMS-SET',
            params: resp,
          });
        }
        return null;
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const loadAssessmentInbox = API => {
  return dispatch => {
    return API.request(
      `${env.API_SERVER}/v1/internal/assessment-inbox/count`,
      {method: 'GET'}
    )
      .then(resp => {
        if (resp && resp.error === true) {
          return null;
        }
        dispatch({
          type: 'ASSESSMENT-INBOX-SET-COUNT',
          numOpenJobs: resp.numOpenJobs,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  };
};

const mapStateToProps = state => {
  const {
    authentication: {activeProfile, profiles},
    modal: {open: modalOpen},
    params
  } = state;
  const {profile: {id: userId, userType, abilities}} = profiles[activeProfile];
  return {
    activeProfile,
    visible: userType === 'employee',
    paramsLoaded: params !== null,
    employeeIndex: params !== null ? params.employees.index : null,
    userId,
    abilities,
    modalOpen,
    version: params ? params.version : null,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoadParams: (version) => dispatch(loadParams(API, version)),
  onLoadAssessmentInbox: () => dispatch(loadAssessmentInbox(API)),
});

export default withRouter(withApi(connect(mapStateToProps, mapDispatchToProps)(AppInternal)));
