import React from 'react';
import {Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import CreditorInvoices from './CreditorInvoices';
import CreditorPayments from './CreditorPayments';
import Credits from './Credits';
import DebtorPayments from './DebtorPayments';
import ExcessPayments from './ExcessPayments';
import MiscInvoices from './MiscInvoices';
import Reports from './Reports/index.jsx';
import Status from './Status';
import Settings from './Settings';
import TechnicianCommissions from './TechnicianCommissions';


const Container = styled.div`
  overflow: auto;
  @media print {
    overflow: visible;
  }
`;


export default () => (
  <Container className='flex-grow-1'>
    <Switch>
      <Route
        path='/admin/creditor-invoices'
        exact={true}
        component={CreditorInvoices}
      />
      <Route
        path='/admin/creditor-payments'
        exact={true}
        component={CreditorPayments}
      />
      <Route
        path='/admin/credits'
        exact={true}
        component={Credits}
      />
      <Route
        path='/admin/misc-invoices'
        exact={true}
        component={MiscInvoices}
      />
      <Route
        path='/admin/debtor-payments'
        exact={true}
        component={DebtorPayments}
      />
      <Route
        path='/admin/excess-payments'
        exact={true}
        component={ExcessPayments}
      />
      <Route
        path='/admin/reports/:reportType?'
        component={Reports}
      />
      <Route
        path='/admin/status'
        exact={true}
        component={Status}
      />
      <Route
        path='/admin/settings'
        exact={true}
        component={Settings}
      />
      <Route
        path='/admin/technician-commissions'
        exact={true}
        component={TechnicianCommissions}
      />
    </Switch>
  </Container>
);
