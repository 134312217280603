import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import AddTask from './component.jsx';


const add = (API, task) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/task`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(task)
      }
    )
      .then(res => {
        if (res.error === true) return false;
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const close = () => ({
  type: 'ADD-TASK-CLOSE',
});

const mapStateToProps = (state, props) => {
  const {
    jobDetails: {
      jobId: dJobId,
      jobNumber: dJobNumber,
    },
    jobPurchasing: {
      jobId: pJobId,
      jobNumber: pJobNumber,
    },
    jobLocations: {
      jobId: lJobId,
      jobNumber: lJobNumber,
    },
    jobNotes: {
      jobId: nJobId,
      jobNumber: nJobNumber,
    },
    jobSmss: {
      jobId: sJobId,
      jobNumber: sJobNumber,
    },
    jobPayments: {
      jobId: mJobId,
      jobNumber: mJobNumber,
    },
    jobLogs: {
      jobId: aJobId,
      jobNumber: aJobNumber,
    },
    addTask: {open},
    params: {
      employees: {list: employees}
    },
  } = state;
  return {
    open,
    jobId: dJobId || pJobId || lJobId || nJobId || sJobId || mJobId || aJobId,
    jobNumber: dJobNumber || pJobNumber || lJobNumber || nJobNumber || sJobNumber || mJobNumber || aJobNumber,
    employees,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onClose: () => dispatch(close()),
  onAdd: (task) => dispatch(add(API, task)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddTask)
);

