import React from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';
import {ReactComponent as Logo} from 'logo.svg';


const Container = styled.div`
  @media (max-width: 576px) {
    padding: 5rem;
  }
`;
const Panel = styled.div`
  @media (min-width: 576px) {
    max-width: 400px;
  }
`;
const Button = styled.button`
  color: #fff;
  background-color: ${colors.primary};
  border-color: ${colors.primary};
`;

export default class Login extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      email: '',
      password: ''
    };
    this.onChangeEmail = this.onChange.bind(this, 'email');
    this.onChangePassword = this.onChange.bind(this, 'password');
    this.onLoginClick = this.onLoginClick.bind(this);
  }
  onChange(key, e) {
    this.setState({
      ...this.state,
      [key]: e.target.value
    });
  }
  onLoginClick(e) {
    e.preventDefault();
    const {email, password} = this.state;
    this.props.onLogin(email, password)
      .then(res => {
        if (res !== true) {
          toast.error('Invalid login credentials provided');
        }
      });
  }
  render() {
    const {email, password} = this.state;
    return (
      <Container
        className='w-100 h-100 d-flex flex-row justify-content-center align-items-center'
      >
        <Panel className='flex-grow-1 d-flex flex-column align-items-center bg-white rounded'>
          <Logo width={200} />
          <form className='w-100 mt-4' onSubmit={this.onLoginClick}>
            <div className='w-100'>
              <input
                autoFocus
                type='email'
                className='form-control mb-4'
                placeholder='email'
                value={email}
                onChange={this.onChangeEmail}
              />
              <input
                type='password'
                className='form-control mb-4'
                placeholder='password'
                value={password}
                onChange={this.onChangePassword}
              />
              <Link to='/forgot-password' className='d-block mb-4'>Forgot password?</Link>
              <Button type='submit' className='btn rounded w-100 py-3' onClick={this.onLoginClick}>Login</Button>
            </div>
          </form>
        </Panel>
      </Container>
    );
  }
};
