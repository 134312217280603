import numeral from 'numeral';


export default (state = null, action) => {
  switch (action.type) {
    case 'ASSESSMENT-INBOX-SET-COUNT':
     return numeral(action.numOpenJobs).format('0a');
    default:
      return state;
  }
};
