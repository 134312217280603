import moment from 'moment';
import numeral from 'numeral';


const PAYMENT_TYPES = {
  'job-excess-invoice-debit': {txt: 'excess amount', mult: -1},
  'job-invoice-debit': {txt: 'invoice amount', mult: -1},
  'job-excess-invoice-credit': {txt: 'excess payment', mult: 1},
  'job-excess-invoice-confirm': {txt: null},
  'job-invoice-credit': {txt: 'invoice payment', mult: 1},
};

export default (
  state = {
    notFound: null,
    jobId: null,
    jobNumber: null,
  },
  action
) => {
  switch (action.type) {
    case 'JOB-PAYMENTS-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-PAYMENTS-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-PAYMENTS-SET':
      {
        if (!state.jobNumber || state.jobNumber === action.jobNumber) {
          const year = moment().year();
          const {
            jobId,
            jobNumber,
            flagged,
            vpp,
            vip,
            importedFromArms,
            isInvoiced,
            isPaid,
            total,
            payments: _payments
          } = action;
          const {payments, totalPaid} = _payments.reduce((acc, p) => {
            const ts = moment(p.createDate * 1000);
            const type = PAYMENT_TYPES[p.type];
            if (type.txt !== null) {
              const amountStr = numeral(type.mult * p.amount / 100).format('($0,0.00)');
              if (type.mult === 1) {
                acc.totalPaid += p.amount;
              }
              let ref = [p.paymentMethod || '', p.paymentReference || ''].filter(f => f !== '');
              ref = ref.length === 0 ? null : `(${ref.join(' : ')})`;
              acc.payments.push({
                ...p,
                isReceivable: type.mult === -1,
                typeStr: type.txt,
                amountStr,
                ts: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
                ref
              });
            }
            return acc;
          }, {payments: [], totalPaid: null})
          return {
            ...state,
            notFound: false,
            jobId,
            jobNumber,
            flagged,
            vpp,
            vip,
            importUrl: importedFromArms ?
              `https://www.arms1.com.au/search?search-quote-number=${jobNumber}` :
              null,
            isInvoiced,
            isPaid,
            payments,
            outstandingAmount: isInvoiced === true ? numeral((total - totalPaid) / 100).format('$0,0.00') : null
          };
        } else {
          return state;
        }
      }
    default:
      return state;
  }
};
