import React from 'react';
import * as Icons from 'react-feather';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';
import Modal from 'components/Modal';
import CalendarInput from 'components/CalendarInput.jsx';
import Field from 'components/Field.jsx';
import PublicHoliday from './PublicHoliday.jsx';
import Select from 'components/Select.jsx';


const Row = styled.tr`
  background: transparent;
  :nth-of-type(odd) {
    background: ${colors.grey1};
  }
`;
const Button = styled.div`
  cursor: pointer;
  & svg {
    stroke: ${props => props.color};
  }
`;
const Card = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
`;
const Item = styled.div`
  margin-bottom: 0.5rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;

export default class General extends React.Component {
  constructor(opts) {
    super(opts);
    this.onChangeName = this.onChange.bind(this, 'name');
    this.onChangeABN = this.onChange.bind(this, 'abn');
    this.onChangeBSB = this.onChange.bind(this, 'bsb');
    this.onChangeAccountNumber = this.onChange.bind(this, 'accountNumber');
    this.onChangeLicenseNumber = this.onChange.bind(this, 'licenseNumber');
    this.onChangeAddress = this.onChange.bind(this, 'address');
    this.onChangePhone = this.onChange.bind(this, 'phone');
    this.onChangeSmsFrom = this.onChange.bind(this, 'smsFrom');
    this.onChangeLogo = this.onChange.bind(this, 'logo');
    this.onChangeJurisdiction = this.onChange.bind(this, 'jurisdiction');
    this.onChangePublicHoliday = this.onChange.bind(this, 'publicHoliday');
    this.onSave = this.onSave.bind(this);
  }
  onChange(field, e) {
    this.props.onChange({
      [field]: e
    });
  }
  onChangeBookingDays(field, e) {
    if (/^\d*$/.test(e)) {
      this.props.onChange({
        bookingDays: {
          ...this.props.bookingDays,
          [field]: Number(e)
        }
      });
    }
  }
  onDeletePublicHoliday(i) {
    this.props.onChange({
      currentPublicHoliday: null,
      publicHolidays: this.props.publicHolidays.reduce((acc, p, j) => {
        if (i !== j) acc.push(p);
        else if (p.id !== null) {
          acc.push({
            ...p,
            deleted: true
          });
        }
        return acc;
      }, [])
    },
    );
  }
  onSavePublicHoliday(i, newStates) {    
    this.props.publicHolidays[i] = {
      ...this.props.publicHolidays[i],
      states: newStates,
      stateStr: Object.entries(newStates).filter(s => s[1] === true).map(s => s[0]).join(', ')
    };
    this.props.onChange({
      currentPublicHoliday: null,
      publicHolidays: this.props.publicHolidays
    });
  }

  onChangePublicHolidayOption(changes) {
    this.props.onChangePublicHolidayOption(changes);  
  }

  onCloseModal() {    
    this.props.onChange({
      currentPublicHoliday: null
    });
  }

  onSave() {
    const {
      name,
      abn,
      licenseNumber,
      bsb,
      accountNumber,
      address,
      phone,
      smsFrom,
      jurisdiction,
      logo: _logo,
      bookingDays,
      publicHolidays,
      onSave
    } = this.props;
    const logo = (_logo || '').trim() || null;
    onSave({
      name, abn, licenseNumber, address, phone, smsFrom,
      bsb, accountNumber,
      jurisdiction, logo, bookingDays,
      publicHolidays: publicHolidays.map(({id, d, states, deleted}) => ({id, d, states, deleted})),
    })
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while updating settings.');
        } else {
          toast.success('Settings successfully updated.');
        }
      })
      .catch(e => {
        toast.error('Error encountered while updating settings.');
      });
  }

  renderPublicHoliday() {
    const { currentPublicHoliday } = this.props;    
    if (currentPublicHoliday === null) return null;
    return (
      <Modal
        title={'Edit Public holiday'}
        open={true}
        onClose={this.onCloseModal.bind(this)}
      >
        <PublicHoliday
          onClose={this.onCloseModal.bind(this)}
          onChange={this.onChangePublicHolidayOption.bind(this)}                    
          onSave={this.onSavePublicHoliday.bind(this)}
          onDelete={this.onDeletePublicHoliday.bind(this)}          
          {...currentPublicHoliday}
        />
      </Modal>
    );
  }

  render() {
    console.log('settingsgeneral:render');
    const {
      name,
      abn,
      address,
      phone,
      licenseNumber,
      bsb,
      accountNumber,
      smsFrom,
      jurisdiction,
      logo,
      bookingDays,
      publicHolidays,
      publicHoliday,
    } = this.props;    
    return (
      <React.Fragment>
        <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
          <div className='font-weight-bold mb-2'>General settings</div>
          <button
            className='btn btn-primary'
            onClick={this.onSave}
          >Save</button>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <Card>
              <Item>
                <span>Company name</span>
                <Field
                  background={colors.grey1}
                  value={name}
                  onChange={this.onChangeName}
                />
              </Item>
              <Item>
                <span>Jurisdiction</span>
                <Select
                  background={colors.grey1}
                  placeholder='select a jurisdiction'
                  selected={jurisdiction}
                  options={[{id: 'AU', text: 'Australia'}, {id: 'NZ', text: 'New Zealand'}]}
                  onChange={this.onChangeJurisdiction}
                />
              </Item>
              <Item>
                <span>Address</span>
                <Field
                  background={colors.grey1}
                  value={address}
                  onChange={this.onChangeAddress}
                />
              </Item>
              <Item>
                <span>Phone</span>
                <Field
                  background={colors.grey1}
                  value={phone}
                  onChange={this.onChangePhone}
                />
              </Item>
              {jurisdiction === 'AU' && (
                <Item>
                  <span>ABN</span>
                  <Field
                    background={colors.grey1}
                    value={abn}
                    onChange={this.onChangeABN}
                  />
                </Item>
              )}
              {jurisdiction === 'NZ' && (
                <Item>
                  <span>GST #</span>
                  <Field
                    background={colors.grey1}
                    value={abn}
                    onChange={this.onChangeABN}
                  />
                </Item>
              )}
              <Item>
                <span>License #</span>
                <Field
                  background={colors.grey1}
                  value={licenseNumber}
                  onChange={this.onChangeLicenseNumber}
                />
              </Item>
              <Item className='row'>
                <div className='col-4'>
                  <span>BSB</span>
                  <Field
                    background={colors.grey1}
                    value={bsb}
                    onChange={this.onChangeBSB}
                  />
                </div>
                <div className='col-8'>
                  <span>Account #</span>
                  <Field
                    background={colors.grey1}
                    value={accountNumber}
                    onChange={this.onChangeAccountNumber}
                  />
                </div>
              </Item>
              <Item>
                <span>SMS #</span>
                <Field
                  background={colors.grey1}
                  value={smsFrom}
                  onChange={this.onChangeSmsFrom}
                />
              </Item>
              <Item>
                <span>Logo</span>
                <Field
                  background={colors.grey1}
                  value={logo}
                  onChange={this.onChangeLogo}
                />
              </Item>
              <Item>
                <span>Booking days</span>
                <div className='d-flex flex-row flex-wrap'>
                  <Field
                    className='mr-2'
                    title='Repair'
                    background={colors.grey1}
                    value={bookingDays.repair}
                    onChange={this.onChangeBookingDays.bind(this, 'repair')}
                  />
                  <Field
                    className='mr-2'
                    title='PDR'
                    background={colors.grey1}
                    value={bookingDays.pdr}
                    onChange={this.onChangeBookingDays.bind(this, 'pdr')}
                  />
                  <Field
                    className='mr-2'
                    title='Paint (small)'
                    background={colors.grey1}
                    value={bookingDays.paint}
                    onChange={this.onChangeBookingDays.bind(this, 'paint')}
                  />
                  <Field
                    className='mr-2'
                    title='Paint (large)'
                    background={colors.grey1}
                    value={bookingDays.respray}
                    onChange={this.onChangeBookingDays.bind(this, 'respray')}
                  />
                  <Field
                    className='mr-2'
                    title='Turret'
                    background={colors.grey1}
                    value={bookingDays.turret}
                    onChange={this.onChangeBookingDays.bind(this, 'turret')}
                  />
                </div>
              </Item>
            </Card>
          </div>
          <div className='col-12 col-md-6'>
            <Card>
              <table className='w-100 mt-3'>
                <thead>
                  <tr className='d-flex flex-row justify-content-between p-2'>
                    <th width="40%">Public holidays</th>
                    <th width="50%">States</th>                      
                    <th width='10%'></th>
                  </tr>
                </thead>
                <tbody>
                  {publicHolidays.map((d, i) => (
                    d.deleted !== true && (
                    <Row key={i} className='d-flex flex-row justify-content-between p-2'>
                      <td width="40%">{d.dayStr}</td>
                      <td width="50%">{d.stateStr}</td>
                      <td width="10%"><Button
                        color={colors.primary}
                        onClick={this.props.onEditPublicHoliday.bind(null, i)}
                        ><Icons.Edit2 width={18} height={18} /></Button>
                      </td>                      
                    </Row>
                  )))}
                </tbody>                  
              </table>              
              <div className='d-flex flex-row mt-3'>
                <CalendarInput
                  className='mr-2'
                  selected={publicHoliday}
                  background={colors.grey1}
                  showClear={true}
                  onChange={this.onChangePublicHoliday}
                />
                <button
                  className='btn'
                  onClick={this.props.onAddPublicHoliday}
                  disabled={publicHoliday === null}
                >
                  <Icons.Plus width={18} height={18} color={colors.primary} />
                </button>
              </div>
            </Card>
          </div>
        </div>
        {this.renderPublicHoliday()}
      </React.Fragment>
    );
  }
};
