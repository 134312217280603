import moment from 'moment';


export default (
  state = {
    notFound: null,
    jobId: null,
    jobNumber: null,
    notes: [],
    repxNotes: [],
  },
  action
) => {
  switch (action.type) {
    case 'JOB-NOTES-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-NOTES-SET-FOUND':
      const{jobId, jobNumber, flagged, vpp, vip, importedFromArms} = action;
      return {
        ...state,
        notFound: false,
        jobId,
        jobNumber,
        flagged,
        vpp,
        vip,
        importUrl: importedFromArms ?
          `https://www.arms1.com.au/search?search-quote-number=${jobNumber}` :
          null,
      };
    case 'JOB-NOTES-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
        notes: [],
        repxNotes: [],
      };
    case 'JOB-NOTES-SET-NOTES':
      {
        const year = moment().year();
        return {
          ...state,
          notes: action.notes.map(n => {
            const ts = moment(n.createDate * 1000);
            return {
              ...n,
              ts: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
            };
          })
        };
      }
    case 'JOB-NOTES-SET-REPXNOTES':
      {
        const year = moment().year();
        return {
          ...state,
          repxNotes: action.notes.map(n => {
            const ts = moment(n.ts * 1000);
            return {
              ...n,
              ts: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
            };
          })
        };
      }
    default:
      return state;
  }
};
