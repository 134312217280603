import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import CalendarInput from 'components/CalendarInput.jsx';
import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


const Button = styled.button`
  cursor: pointer;
  & svg {
    stroke: ${props => props.color};
  }
`;
const CapacityRow = styled.div`
  :last-of-type {
    margin-bottom: 0px;
  }
`;
const ExpandButton = styled(Icons.ChevronDown)`
  cursor: pointer;
  stroke: ${colors.grey4};
`;
const CollapseButton = styled(Icons.ChevronUp)`
  cursor: pointer;
  stroke: ${colors.grey4};
`;
const DeleteButton = styled(Icons.X)`
  cursor: pointer;
  stroke: ${colors.red};
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

export default class SettingsStorm extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      selectedCreditor: null,
      toggleState: {}
    };
    this.onSelectCreditor = this.onSelectCreditor.bind(this);
    this.onAddCreditor = this.onAddCreditor.bind(this);
    this.onChangeName = this.onChange.bind(this, 'name');
    this.onChangeDefaultLocation = this.onChange.bind(this, 'defaultLocation');
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onSelectCreditor(e) {
    this.setState({
      selectedCreditor: e
    });
  }
  onAddCreditor() {
    const {selectedCreditor} = this.state;
    this.props.onAddCreditor(selectedCreditor);
    this.setState({
      selectedCreditor: null
    });
  }
  onToggleCreditor(creditorId) {
    this.setState({
      toggleState: {
        ...this.state.toggleState,
        [creditorId]: !this.state.toggleState[creditorId]
      }
    });
  }
  onToggleCreditorMake(creditorId, make) {
    this.props.onToggleCreditorMake(creditorId, make);
  }
  onDeleteCreditor(creditorId) {
    this.props.onDeleteCreditor(creditorId);
  }
  onDeleteCapacity(index) {
    const {capacities, onChange} = this.props;
    let res = capacities.filter((c, i) => i !== index);
    onChange({
      capacities: res
    });
  }
  onChangeCapacity(index, field, e) {
    const {capacities, onChange} = this.props;
    let res = capacities.map((c, i) => {
      if (i !== index) return c;
      if (field !== 'startDate') {
        if (/^\d*$/.test(e) === false) return c;
        e = Number(e);
      }
      return {
        ...c,
        [field]: e,
      };
    });
    if (index === capacities.length - 1) {
      res = res.concat({
        id: null,
        startDate: null,
        numTotal: null,
        numPaint: null,
        numTurret: null
      });
    }
    onChange({
      capacities: res
    });
  }
  onChange(field, e) {
    this.props.onChange({
      [field]: e,
      [`${field}Error`]: false
    });
  }
  onDelete() {
    const {
      onDelete, id
    } = this.props;
    onDelete(id);
  }
  onSave() {
    const {
      onSave,
      id, name, defaultLocation,
      capacities, creditors
    } = this.props;
    onSave(id, {
      name,
      defaultLocation,
      capacities: capacities.slice(0, capacities.length - 1),
      creditors: creditors.map(c => ({
        id: c.id,
        makes: c.makes
      }))
    });
  }
  renderCreditor(c, i) {
    const {
      id: creditorId,
      makeIndex
    } = c;
    const {creditorIndex} = this.props;
    const {toggleState} = this.state;
    const expanded = toggleState[creditorId];
    const ToggleButton = expanded ? CollapseButton : ExpandButton;
    const creditor = creditorIndex[creditorId];
    return (
      <Card className='mt-2' key={i}>
        <div className='d-flex flex-row'>
          <ToggleButton
            className='mt-1'
            onClick={this.onToggleCreditor.bind(this, creditorId)}
            width={16}
            height={16}
          />
          <div className='mx-2 flex-grow-1'>
            <div
              className='font-weight-bold cursor-pointer'
              onClick={this.onToggleCreditor.bind(this, creditorId)}
            >{creditor.name}</div>
            {expanded === true && creditor.makes.length === 0 && (
              <div className='mt-2 text-muted'>Creditor doesn't have any car makes</div>
            )}
            {expanded === true && creditor.makes.length !== 0 && (
              <div className='row mt-1'>
                {creditor.makes.map((m, j) => (
                  <div className='col-4 mt-1' key={j}>
                    <div className='form-group form-check mb-0'>
                      <input
                        type='checkbox'
                        id={`${creditorId}-${m}`}
                        className='form-check-input'
                        checked={makeIndex[m] === true}
                        onChange={this.onToggleCreditorMake.bind(this, creditorId, m)}
                      />
                      <label
                        htmlFor={`${creditorId}-${m}`}
                        className='form-check-label'
                      >{m}</label>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <DeleteButton
            className='mt-1'
            onClick={this.onDeleteCreditor.bind(this, creditorId)}
            width={16} height={16} />
        </div>
      </Card>
    );
  }
  render() {
    console.log('settingslocation:render');
    const {
      locations,
      id,
      name, nameError,
      defaultLocation, defaultLocationError,
      capacities, creditors, creditorList,
      onClose,
    } = this.props;
    const {selectedCreditor} = this.state;
    return (
      <React.Fragment>
        <div className='modal-body'>
          <Field
            title='Name'
            uppercaseOnly={true}
            background={colors.grey1}
            value={name}
            error={nameError}
            onChange={this.onChangeName} />
          <Select
            title='Default location'
            placeholder='select a location'
            background={colors.grey1}
            options={locations}
            textField='name'
            selected={defaultLocation}
            error={defaultLocationError}
            onChange={this.onChangeDefaultLocation}
          />
          <h6 className='mt-3'>Capacity rules</h6>
          <div className='row no-gutters mb-2'>
            <div className='col-2 font-weight-bold text-muted'>From</div>
            <div className='col-3 font-weight-bold text-muted text-center'>Total</div>
            <div className='col-3 font-weight-bold text-muted text-center'>Paint</div>
            <div className='col-3 font-weight-bold text-muted text-center'>Turret</div>
            <div className='col-1 font-weight-bold' />
          </div>
          {capacities.map((c, i) => (
            <CapacityRow className='row no-gutters mb-2' key={i}>
              <div className='col-2 pr-1'>
                <CalendarInput
                  selected={c.startDate}
                  background={colors.grey1}
                  onChange={this.onChangeCapacity.bind(this, i, 'startDate')} />
              </div>
              <div className='col-3 px-1'>
                <Field
                  background={colors.grey1}
                  value={c.numTotal}
                  onChange={this.onChangeCapacity.bind(this, i, 'numTotal')} />
              </div>
              <div className='col-3 px-1'>
                <Field
                  background={colors.grey1}
                  value={c.numPaint}
                  onChange={this.onChangeCapacity.bind(this, i, 'numPaint')} />
              </div>
              <div className='col-3 px-1'>
                <Field
                  background={colors.grey1}
                  value={c.numTurret}
                  onChange={this.onChangeCapacity.bind(this, i, 'numTurret')} />
              </div>
              <div className='col-1 pl-1 text-center'>
                {i < capacities.length - 1 &&
                  <Button
                    className='btn'
                    color={colors.red}
                    onClick={this.onDeleteCapacity.bind(this, i)}
                  ><Icons.X width={18} height={18} /></Button>
                }
              </div>
            </CapacityRow>
          ))}
          <h6 className='mt-3'>Creditors</h6>
          <div className='d-flex flex-row justify-content-between'>
            <Select
              className='flex-grow-1 mr-2'
              options={creditorList}
              textField='name'
              background={colors.grey1}
              selected={selectedCreditor}
              onChange={this.onSelectCreditor}
            />
            <Button
              className='btn btn-primary'
              color={colors.white}
              onClick={this.onAddCreditor}
              disabled={selectedCreditor === null}
            ><Icons.Plus width={18} height={18} /></Button>
          </div>
          {creditors.map(this.renderCreditor.bind(this))}
          {creditors.length === 0 && (
            <div className='mt-2 text-muted'>Storm doesn't have any creditors yet</div>
          )}
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button type='button' className='btn btn-danger mr-auto' onClick={this.onDelete}>Delete</button>
          }
          <button type='button' className='btn btn-secondary' onClick={onClose}>Close</button>
          <button type='button' className='btn btn-primary' onClick={this.onSave}>Save</button>
        </div>
      </React.Fragment>
    );
  }
};
