import moment from 'moment';


export default (
  state = {
    notFound: null,
    jobId: null,
    jobNumber: null,
    survey: []
  },
  action
) => {
  switch (action.type) {
    case 'JOB-SURVEY-SET-NOT-FOUND':
      return {
        notFound: true,
        jobId: null,
        jobNumber: null,
      };
    case 'JOB-SURVEY-SET-FOUND':
      const {jobId, jobNumber, flagged, vpp, vip, importedFromArms, bookingType, survey: _survey} = action;
      let survey = null;
      if (_survey !== null) {
        survey = _survey;
        const ts = moment(survey.createDate * 1000);
        survey.ts = ts.format(ts.year() === moment().year() ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm');
      }
      return {
        ...state,
        notFound: false,
        jobId,
        jobNumber,
        flagged,
        vpp,
        vip,
        bookingType,
        importUrl: importedFromArms ?
          `https://www.arms1.com.au/search?search-quote-number=${jobNumber}` :
          null,
        survey
      };
    case 'JOB-SURVEY-SET-NULL':
      return {
        notFound: null,
        jobId: null,
        jobNumber: null,
        survey: []
      };
    default:
      return state;
  }
};
