import React from 'react';
import styled from 'styled-components';

import colors from 'colors';


const Input = styled.input`
  color: ${opts => opts.disabled ? colors.grey4 : (opts.color || 'invalid')};
  background-color: ${opts => opts.background ? opts.background : '#fff'} !important;
  border-color: ${opts => opts.error ? colors.red : (opts.background ? opts.background : '#fff')};
  text-transform: ${opts => opts.uppercaseOnly ? 'uppercase' : 'none'};
`;

export default class Field extends React.Component {
  constructor(opts) {
    super(opts);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.error !== this.props.error ||
      nextProps.readOnly !== this.props.readOnly
    );
  }
  onChange(e) {
    const {onChange} = this.props;
    onChange(e.target.value);
  }
  onBlur() {
    const {uppercaseOnly, value, onChange, onBlur} = this.props;
    if (uppercaseOnly === true) {
      onChange((value || '').toUpperCase());
    }
    if (onBlur) {
      onBlur();
    }
  }
  render() {
    //console.log('field:render');
    const {
      className,
      color,
      background,
      type,
      title,
      placeholder,
      uppercaseOnly,
      readOnly,
      disabled,
      value,
      error,
    } = this.props;
    return (
      <div className={className}>
        <small className='text-muted'>{title}</small>
        <Input
          type={type || 'text'}
          color={color}
          background={background}
          uppercaseOnly={uppercaseOnly}
          error={error}
          className='form-control p-0'
          value={value === undefined || value === null ? '' : value}
          placeholder={placeholder}
          onChange={this.onChange}
          onBlur={this.onBlur}
          readOnly={readOnly}
          disabled={disabled}
        />
      </div>
    );
  }
};
