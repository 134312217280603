import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import JobTitle from '../JobTitle';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Item = styled.div`
  margin-bottom: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;

export default class Logs extends React.Component {
  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {jobId, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
  }
  render() {
    console.log('logs:render');
    const {jobValid} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }

    const {jobId, jobNumber, flagged, vpp, vip, importUrl, logs, employeesIndex} = this.props;
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold mb-2'>Logs</div>
          {logs.length === 0 && (
            <div className='text-center text-secondary'>No activity yet.</div>
          )}
          {logs.length !== 0 && logs.map((l, i) => (
            <Item key={i}>
              {l.employeeId && <small className='text-muted'>{employeesIndex[l.employeeId].name} {'\u2013'} {l.tsStr}</small>}
              {!l.employeeId && <small className='text-muted'>{l.tsStr}</small>}
              <div>{l.description}</div>
            </Item>
          ))}
        </Card>
      </React.Fragment>
    );
  };
};
