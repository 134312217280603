export default (state = {open: false}, action) => {
  switch (action.type) {
    case 'ADD-MISC-INVOICE-OPEN':
      return {open: true};
    case 'ADD-MISC-INVOICE-CLOSE':
      return {open: false};
    default:
      return state;
  }
};
