import numeral from 'numeral';

import moment from 'm';
import TruncateString from 'utils/truncate-string.js';


const TASK_TRANSFORMS = {
  _GENERIC_: (t, yesterday, today, tomorrow) => {
    t.startDateStr = t.startDate ? moment(t.startDate * 1000).format('DD-MM') : 'N/A';
    t.finishDateStr =  t.finishDate ? moment(t.finishDate * 1000).format('DD-MM') : 'N/A';
    t.alertColor = null;
    if (t.flagged === true) {
      t.alertColor = 'red';
    } else if (t.vpp === true) {
      t.alertColor = 'orange';
    } else if (t.vip === true) {
      t.alertColor = 'yellow';
    }
    t.makeModel = TruncateString([t.make || '', t.model || ''].join(' '));
    t.flag = 'white';
    return t;
  },
  inbox: (t, yesterday, today, tomorrow) => {
    switch (t.priority) {
      case 1:
        t.flag = 'green';
        break;
      case 2:
        t.flag = 'orange';
        break;
      case 3:
        t.flag = 'red';
        break;
      default:
        t.flag = 'white';
        break;
    }
    t.dueDateStr = t.dueDate ? moment(t.dueDate * 1000).format('DD-MM') : 'N/A';
    t.createDateStr = moment(t.createDate * 1000).format('DD-MM HH:mm');
    t.done = t.respondDate !== null;
    t.comments = t.comments.map(c => ({
      ...c,
      tsStr: moment(c.ts * 1000).format('DD-MM HH:mm'),
    }));
    t.lastComment = t.comments.slice(-1).pop();
    return t;
  },
  outbox: (t, yesterday, today, tomorrow) => {
    switch (t.priority) {
      case 1:
        t.flag = 'green';
        break;
      case 2:
        t.flag = 'orange';
        break;
      case 3:
        t.flag = 'red';
        break;
      default:
        t.flag = 'white';
        break;
    }
    t.dueDateStr = t.dueDate ? moment(t.dueDate * 1000).format('DD-MM') : 'N/A';
    t.createDateStr = moment(t.createDate * 1000).format('DD-MM HH:mm');
    t.done = false;
    t.comments = t.comments.map(c => ({
      ...c,
      tsStr: moment(c.ts * 1000).format('DD-MM HH:mm'),
    }));
    t.lastComment = t.comments.slice(-1).pop();
    return t;
  },
  productionStrip: (t, yesterday, today, tomorrow) => {
    if (t.startDate <= yesterday) t.flag = 'redExtraLight';
    else if (t.startDate <= today) t.flag = 'orangeExtraLight';
    return t;
  },
  productionRepair: (t, yesterday, today, tomorrow) => {
    if (t.startDate <= yesterday) t.flag = 'redExtraLight';
    else if (t.startDate <= today) t.flag = 'orangeExtraLight';
    return t;
  },
  partNotOrdered: (t, yesterday, today, tomorrow) => {
    if (t.hasDamagedOrWrongPart === true) {
      t.flag = 'red';
    }
    return t;
  },
  partNotReceived: (t, yesterday, today, tomorrow) => {
    if (t.isOverdue === true) {
      t.flag = 'red';
    }
    return t;
  },
  strip: (t, yesterday, today, tomorrow) => {
    if (t.startDate <= yesterday) t.flag = 'redExtraLight';
    else if (t.startDate <= today) t.flag = 'orangeExtraLight';

    t.amountStr = numeral(t.amount / 100).format('$0,0.00');
    t.task = 'strip';

    return t;
  },
  fitup: (t, yesterday, today, tomorrow) => {
    if (t.finishDate <= today) t.flag = 'redExtraLight';
    else if (t.finishDate <= tomorrow) t.flag = 'orangeExtraLight';

    t.amountStr = numeral(t.amount / 100).format('$0,0.00');
    t.task = 'fitup';

    return t;
  },
  repair: (t, yesterday, today, tomorrow) => {
    if (t.startDate <= yesterday) t.flag = 'redExtraLight';
    else if (t.startDate <= today) t.flag = 'orangeExtraLight';

    t.amountStr = numeral(t.amount / 100).format('$0,0.00');
    t.task = 'repair';

    return t;
  },
  paint: (t, yesterday, today, tomorrow) => {
    t.task = 'paint';
    return t;
  },
  detail: (t, yesterday, today, tomorrow) => {
    if (t.finishDate < today) t.flag = 'redExtraLight';
    else if (t.finishDate < tomorrow) t.flag = 'orangeExtraLight';

    t.readyDateStr = t.readyDate ? moment(t.readyDate * 1000).format('DD-MM HH:mm') : null;
    t.pickupDateStr = t.pickupDate ? moment(t.pickupDate * 1000).format('DD-MM HH:mm') : null;
    t.task = 'detail';

    return t;
  },
  qa: (t, yesterday, today, tomorrow) => {
    if (t.finishDate < today) t.flag = 'redExtraLight';
    else if (t.finishDate < tomorrow) t.flag = 'orangeExtraLight';

    t.task = 'qa';

    return t;
  },
  pickup: (t, yesterday, today, tomorrow) => {
    if (t.finishDate < today) t.flag = 'redExtraLight';
    else if (t.finishDate < tomorrow) t.flag = 'orangeExtraLight';

    t.readyDateStr = t.readyDate ? moment(t.readyDate * 1000).format('DD-MM HH:mm') : null;
    t.pickupDateStr = t.pickupDate ? moment(t.pickupDate * 1000).format('DD-MM HH:mm') : null;

    return t;
  },
  arrive: (t, yesterday, today, tomorrow) => {
    if (t.startDate < today) t.flag = 'redExtraLight';
    else if (t.startDate === today) t.flag = 'orangeExtraLight';

    return t;
  },
  highCost: (t, yesterday, today, tomorrow) => {
    t.amountStr = numeral(t.amount / 100).format('$0,0.00');
    return t;
  }
};

let sectionStates = {};
const cv = window.localStorage.getItem('tasks:sectionStates');
if (cv) {
  sectionStates = JSON.parse(cv);
}

export default (state = {
  notFound: null,
  sections: [],
  sectionStates,
}, action) => {
  switch (action.type) {
    case 'TASKS-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'TASKS-SECTION-TOGGLE':
    {
      const {section} = action;
      const newSectionStates = {
        ...state.sectionStates,
        [section]: !state.sectionStates[section]
      };
      window.localStorage.setItem('tasks:sectionStates', JSON.stringify(newSectionStates));
      return {
        ...state,
        sectionStates: newSectionStates
      };
    }
    case 'TASKS-SET':
    {
      const today = moment().hour(0).minute(0).second(0).millisecond(0);
      const yesterday = moment(today).add(-1, 'day');
      if (yesterday.day() === 6) {
        yesterday.add(-1, 'day');
      } else if (yesterday.day() === 0) {
        yesterday.add(-2, 'day');
      }
      const tomorrow = moment(today).add(1, 'day');
      if (tomorrow.day() === 6) {
        tomorrow.add(2, 'day');
      } else if (tomorrow.day() === 0) {
        tomorrow.add(1, 'day');
      }

      const todayTs = today.unix();
      const yesterdayTs = yesterday.unix();
      const tomorrowTs = tomorrow.unix();

      return {
        ...state,
        notFound: false,
        sections: action.sections.map(s => ({
          ...s,
          tasks: s.tasks && s.tasks.map(t =>
            (TASK_TRANSFORMS[s.key] || (t => t))(
              TASK_TRANSFORMS._GENERIC_(t, yesterdayTs, todayTs, tomorrowTs),
              yesterdayTs,
              todayTs,
              tomorrowTs
            )
          ),
        })),
      };
    }
    case 'TASKS-SET-DONE':
    {
      const {taskId, locationId, jobId, task, respondDate, archiveDate, done} = action;
      const newSections = state.sections.map(s => {
        if (s.task && s.key !== task) return s;
        const newTasks = s.tasks.map(t => {
          if (taskId !== undefined && t.taskId !== taskId) {
            return t;
          }
          if (
            taskId === undefined &&
            (
              t.jobId !== jobId ||
              t.locationId !== locationId ||
              t.task !== task
            )
          ) {
            return t;
          }
          if (s.key === 'inbox') {
            return {
              ...t,
              respondDate,
              done: (t.responseRequired === true ? respondDate : archiveDate) !== null,
            };
          } else if (s.key === 'outbox') {
            return {
              ...t,
              respondDate,
              done: archiveDate !== null,
            };
          } else {
            return {
              ...t,
              done,
            };
          }
        });
        return {
          ...s,
          tasks: newTasks
        };
      });
      return {
        ...state,
        sections: newSections
      };
    }
    case 'TASKS-SET-DATE-TIME':
    {
      const {jobId, field, ts} = action;
      const newSections = state.sections.map(s => {
        if (['detail', 'pickup'].indexOf(s.key) === -1) return s;
        const newTasks = s.tasks.map(t => {
          if (t.jobId !== jobId) return t;
          if (field === 'readyDate') {
            return {
              ...t,
              readyDate: ts,
              readyDateStr: ts ? moment(ts * 1000).format('DD-MM HH:mm') : null
            };
          } else if (s.key === 'allocatePickupTime') {
            return {
              ...t,
              [field]: ts
            };
          }
          return t;
        });
        return {
          ...s,
          tasks: newTasks
        };
      });
      return {
        ...state,
        sections: newSections
      };
    }
    case 'TASKS-SET-NOTE':
    {
      const {taskId, jobId, note, notes} = action;
      const newSections = state.sections.map(s => {
        if (['inbox', 'outbox', 'allocatePickupTime'].indexOf(s.key) === -1)
          return s;
        const newTasks = s.tasks.map(t => {
          if (taskId !== undefined && t.taskId !== taskId) return t;
          if (taskId === undefined && t.jobId !== jobId) return t;
          return {
            ...t,
            note,
            notes,
          };
        });
        return {
          ...s,
          tasks: newTasks
        };
      });
      return {
        ...state,
        sections: newSections
      };
    }
    case 'TASKS-SET-COMMENTS':
    {
      const {taskId, comments: _comments} = action;
      const newSections = state.sections.map(s => {
        if (['inbox', 'outbox'].indexOf(s.key) === -1)
          return s;
        const newTasks = s.tasks.map(t => {
          if (t.taskId !== taskId) return t;
          const comments = _comments.map(c => ({
            ...c,
            tsStr: moment(c.ts * 1000).format('DD-MM HH:mm'),
          }));
          return {
            ...t,
            lastComment: comments.slice(-1).pop() || null,
            comments,
          };
        });
        return {
          ...s,
          tasks: newTasks
        };
      });
      return {
        ...state,
        sections: newSections
      };
    }
    case 'TASKS-SET-EMPLOYEE':
    {
      const {jobId, locationId, stage, employeeId} = action;
      const newSections = state.sections.map(s => {
        if (stage === 'strip' && s.key !== 'productionStrip') return s;
        if (stage === 'repair' && s.key !== 'productionRepair') return s;
        const newTasks = s.tasks.map(t => {
          if (t.jobId !== jobId || t.locationId !== locationId) return t;
          return {
            ...t,
            employeeId
          };
        });
        return {
          ...s,
          tasks: newTasks
        };
      });
      return {
        ...state,
        sections: newSections
      };
    }
    default:
      return state;
  }
};
