import React from 'react';
import * as Icons from 'react-feather';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

import colors from 'colors';

import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';

import JobTitle from '../JobTitle';
import ImageList from '../ImageList';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Button = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.div`
  margin-bottom: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;


export default class Comms extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      smsUuid: uuidv4(),
      sms: '',
      template: null,
    };
    this.onChangeTemplate = this.onChange.bind(this, 'template');
    this.onChangeSms = this.onChange.bind(this, 'sms');
    this.onSend = this.onSend.bind(this);
  }
  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {jobId, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
  }
  onChange(field, e) {
    if (field === 'template') {
      this.setState({
        sms: e === null ? this.state.sms : this.props.smsTemplatesIndex[e].template,
        template: e,
      });
    } else if (field === 'sms') {
      this.setState({
        sms: e,
        template: null
      });
    }
  }
  onSend(e) {
    const {smsUuid, sms} = this.state;
    const {jobId, onSendSMS} = this.props;
    onSendSMS(jobId, smsUuid, sms)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while sending SMS.');
        } else {
          this.setState({
            smsUuid: uuidv4(),
            sms: '',
            template: null,
          });
          toast.success('SMS successfully sent.');
        }
      });
  }
  render() {
    console.log('smss:render');
    const {jobValid} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }

    const {jobId, jobNumber, flagged, vpp, vip, importUrl, smss, employeesIndex, smsTemplatesList} = this.props;
    const {sms, template} = this.state;
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        <ImageList jobId={jobId} />
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold mb-2'>SMSs</div>
          <div className='d-flex flex-row justify-content-between mb-3'>
            <Select
              className='mb-0 mr-2'
              placeholder='select a template'
              options={smsTemplatesList}
              textField='name'
              selected={template}
              onChange={this.onChangeTemplate}
            />
            <Field
              className='flex-grow-1 mb-0 mr-2'
              value={sms}
              onChange={this.onChangeSms} />
            <Button
              className='btn btn-primary'
              type='button'
              onClick={this.onSend}
              disabled={sms === ''}
            ><Icons.Send width={18} height={18} /></Button>
          </div>
          {smss.length === 0 && (
            <div className='text-center text-secondary'>No messages yet.</div>
          )}
          {smss.length !== 0 && smss.map((s, i) => (
            <Item key={i}>
              <small className='text-muted'>
                {employeesIndex[s.employeeId].name} {'\u2013'} {s.ts}{s.sendDate ? '' : '*'}
              </small>
              <div>{s.sms}</div>
            </Item>
          ))}
        </Card>
      </React.Fragment>
    );
  };
};

