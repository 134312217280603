import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';
import Field from 'components/Field.jsx';
import Modal from 'components/Modal';


const JobCard = styled.div`
  border-radius: 0.5rem;
  background: white;
  cursor: pointer;
  ${opts => opts.bordered === true && (`
    border: 1px solid ${colors.grey4};
    border-left: none;
  `)}
`;
const JobCardFlag = styled.div`
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  background: ${opts => opts.color};
  width: 4px;
  min-height: 100%;
`;
const JobImg = styled.img`
  align-self: center;
  border-radius: 0.25rem;
  max-height: 48px;
  width: auto;
`;
const Textarea = styled.textarea`
  border-color: ${opts => opts.error ? colors.red : '#fff'} !important;
  background: ${colors.grey1};
`;
const Button = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.div`
  margin-bottom: 0.5rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;
const Comment = styled.div`
  white-space: pre-wrap;
`;


export default class Todo extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      notes: '',
      comment: '',
    };
    this.onToggleDone = this.onToggleDone.bind(this);
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
    this.onChangeNotes = this.onChange.bind(this, 'notes');
    this.onUpdateNotes = this.onUpdateNotes.bind(this);
    this.onChangeComment = this.onChange.bind(this, 'comment');
    this.onAddComment = this.onAddComment.bind(this);
  }
  onToggleDone(e) {
    const {task: {taskId}, onToggleDone} = this.props;
    onToggleDone({taskId, done: e.target.checked});
  }
  onToggleModal(open) {
    this.setState({
      open,
      notes: this.props.task.notes,
      comment: '',
    });
  }
  onChange(field, e) {
    if (field === 'notes') {
      this.setState({
        notes: e.target.value,
      });
    } else if (field === 'comment') {
      this.setState({
        comment: e
      });
    }
  }
  onUpdateNotes(e) {
    const {task: {taskId}, onChangeNote} = this.props;
    const {notes} = this.state;
    onChangeNote({taskId, notes});
  }
  onAddComment(e) {
    const {task: {taskId}, onAddComment} = this.props;
    const {comment} = this.state;
    onAddComment({taskId, comment})
      .then(() => {
        this.setState({
          comment: '',
        });
      })
      .catch(e => {
      });
  }
  render() {
    const {employees, type, task} = this.props;
    const {open, notes, comment} = this.state;
    return (
      <React.Fragment>
        <div className='col-sm-6 col-md-4 col-lg-3 mt-2'>
          <JobCard
            bordered={type === 'outbox' && task.respondDate !== null}
            className='d-flex flex-row justify-content-between mx-1 h-100'
          >
            <JobCardFlag color={task.flag} />
            <div
              className='flex-grow-1 mx-1 h-100 p-2'
              onClick={this.onOpen}
            >
              <div className='d-flex flex-row justify-content-between'>
                <div>
                  <NavLink to={`/job/${task.jobId}`} className='text-body font-weight-bold'>
                    {task.jobNumber}
                  </NavLink>
                </div>
                <div className='text-center'>{task.rego}</div>
                <div className='text-right'>{task.dueDateStr}</div>
              </div>
              <div className='text-uppercase'><span className='text-muted'>{task.createDateStr}</span> {'\u2013'} {employees[task.employeeId].name}</div>
              <div className='text-uppercase'>{task.title}</div>
              {task.lastComment && (
                <div>
                  <div className='text-uppercase'><span className='text-muted'>{task.lastComment.tsStr}</span> {'\u2013'} {employees[task.lastComment.employeeId].name}</div>
                  <Comment>{task.lastComment.comment}</Comment>
                </div>
              )}
            </div>
            <div className='m-2 d-flex flex-column align-items-center'>
              {task.image64 && <JobImg src={task.image64} className='mb-1' />}
              <input
                type='checkbox'
                className='mt-1 mb-2'
                onChange={this.onToggleDone}
                checked={task.done === true}
              />
            </div>
          </JobCard>
        </div>
        <Modal
          title={task.title}
          open={open}
          onClose={this.onClose}
        >
          <div className='modal-body'>
            <div className='mb-2'>
              <small className='text-muted'>Notes</small>
              <Textarea
                type='text'
                rows={3}
                className='form-control p-0'
                onChange={this.onChangeNotes}
                onBlur={this.onUpdateNotes}
                value={notes}
              />
            </div>
            <div className='mb-3'>
              <small className='text-muted'>Comments</small>
              <div className='d-flex flex-row justify-content-between'>
                <Field
                  className='flex-grow-1 mb-0 mr-2'
                  placeholder='Type your message here...'
                  background={colors.grey1}
                  value={comment}
                  onChange={this.onChangeComment}
                />
                <Button
                  className='btn btn-primary'
                  type='button'
                  onClick={this.onAddComment}
                  disabled={comment === ''}
                ><Icons.Plus width={18} height={18} /></Button>
              </div>
            </div>
            {task.comments.length === 0 && (
              <div className='text-center text-secondary'>No comments yet.</div>
            )}
            {task.comments.length !== 0 && task.comments.map((c, i) => (
              <Item key={i}>
                <small className='text-muted'>{employees[c.employeeId].name} {'\u2013'} {c.tsStr}</small>
                <Comment>{c.comment}</Comment>
              </Item>
            ))}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};
