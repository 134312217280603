import moment from 'moment';


export default (state = {open: false, mode: null, quoteDate: null}, action) => {
  switch (action.type) {
    case 'JOB-PRINT-RESET':
      return {
        open: false,
        mode: null,
        quoteDate: null,
      };
    case 'JOB-PRINT-OPEN':
      const {mode} = action;
      return {
        open: true,
        mode,
        quoteDate: moment().format('DD-MM-YYYY'),
      };
    default:
      return state;
  }
};
