import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import TechnicianCommissions from './component.jsx';


const load = (API, employeeId) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/technician-commissions/${employeeId}`,
      {method: 'GET'}
    )
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-TECHNICIAN-COMMISSIONS-SET-NOT-FOUND'
          });
          return null;
        }
        const {params: {generalSettings: {gstRate}}} = getState();
        const {chargesGst, invoices, outstandingTasks} = resp;
        dispatch({
          type: 'ADMIN-TECHNICIAN-COMMISSIONS-SET',
          chargesGst,
          gstRate,
          invoices,
          outstandingTasks,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-TECHNICIAN-COMMISSIONS-SET-NULL'
});

const changeEmployeeId = employeeId => ({
  type: 'ADMIN-TECHNICIAN-COMMISSIONS-CHANGE-EMPLOYEE-ID',
  employeeId,
});

const process = (API, employeeId, invoiceId, action) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/technician-commissions/${employeeId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({invoiceId, action})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        dispatch({
          type: 'ADMIN-TECHNICIAN-COMMISSIONS-UPDATE',
          invoiceId: res.invoiceId,
          payDate: res.payDate,
          rejectDate: res.rejectDate,
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    adminTechnicianCommissions: {
      notFound,
      employeeId,
      chargesGst,
      invoices,
      outstandingTasks,
      subtotalTasks,
      totalTasks,
    },
    params: {
      employees: {list: employees},
    }
  } = state;
  return {
    notFound,
    employeeId,
    chargesGst,
    invoices,
    outstandingTasks,
    subtotalTasks,
    totalTasks,
    employees,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: employeeId => dispatch(load(API, employeeId)),
  onUnload: () => dispatch(unload()),
  onChangeEmployeeId: employeeId => dispatch(changeEmployeeId(employeeId)),
  onProcess: (employeeId, invoiceId, action) => dispatch(process(API, employeeId, invoiceId, action)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TechnicianCommissions)
);
