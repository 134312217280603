import numeral from 'numeral';

import moment, {_moment} from 'm';


//export default (state = {notFound: null}, action) => {
export default (state = {
  notFound: null,
  start: moment().add(-13, 'days').hour(0).minute(0).second(0).millisecond(0).unix(),
  finish: moment().add(1, 'days').hour(0).minute(0).second(0).millisecond(0).unix(),
  unpaidOnly: true,
  location: null,
  creditor: null,
  category: null,
}, action) => {
  switch (action.type) {
    case 'ADMIN-MISC-INVOICES-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'ADMIN-MISC-INVOICES-SET-NULL':
      return {
        notFound: null,
        start: moment().add(-13, 'days').hour(0).minute(0).second(0).millisecond(0).unix(),
        finish: moment().add(1, 'days').hour(0).minute(0).second(0).millisecond(0).unix(),
        unpaidOnly: true,
        location: null,
        creditor: null,
        category: null,
      };
    case 'ADMIN-MISC-INVOICES-SET':
      {
        const year = _moment().year();
        const {
          _miscInvoices,
          miscInvoices,
          total,
        } = action.miscInvoices.reduce((acc, i) => {
          const ts = _moment(i.createDate * 1000);
          const invoiceDate = moment(i.invoiceDate * 1000);
          const paymentDate = i.paymentDate ? moment(i.paymentDate * 1000) : null;
          const item = {
            ...i,
            invoiceDateStr: invoiceDate.format(
              invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
            ),
            amountStr: numeral(i.amount / 100).format('$0,0.00'),
            paymentDateStr: paymentDate ? paymentDate.format(paymentDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY') : 'N/A',
            paymentMethod: i.paymentMethod,
            paymentReference: i.paymentReference,
            ts: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
          };
          acc._miscInvoices.push(item);
          if (state.location !== null && state.location !== item.locationId) return acc;
          if (state.creditor !== null && state.creditor !== item.creditorId) return acc;
          if (state.category !== null && state.category !== item.categoryId) return acc;
          acc.miscInvoices.push(item);
          acc.total += item.amount;
          return acc;
        }, {
          _miscInvoices: [],
          miscInvoices: [],
          total: 0,
        });
        return {
          ...state,
          notFound: false,
          _miscInvoices,
          miscInvoices,
          total,
          totalStr: numeral(total / 100).format('$0,0.00'),
        };
      }
    case 'ADMIN-MISC-INVOICES-UPDATE':
      {
        const {id, payload} = action;
        const year = _moment().year();
        const newState = {
          ...state,
          ...state._miscInvoices.reduce((acc, m) => {
            if (m.id !== id) {
              acc._miscInvoices.push(m);
              if (state.location !== null && state.location !== m.locationId) return acc;
              if (state.creditor !== null && state.creditor !== m.creditorId) return acc;
              if (state.category !== null && state.category !== m.categoryId) return acc;
              acc.miscInvoices.push(m);
              acc.total += m.amount;
              return acc;
            }
            const ts = _moment(m.createDate * 1000);
            const invoiceDate = moment(m.invoiceDate * 1000);
            const paymentDate = payload.paymentDate ? moment(payload.paymentDate * 1000) : null;
            const item = {
              ...payload,
              invoiceDateStr: invoiceDate.format(
                invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
              ),
              amountStr: numeral(payload.amount / 100).format('$0,0.00'),
              paymentDateStr: paymentDate ? paymentDate.format(paymentDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY') : 'N/A',
              paymentMethod: payload.paymentMethod,
              paymentReference: payload.paymentReference,
              ts: ts.format(ts.year() === year ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'),
            };
            acc._miscInvoices.push(item);
            if (state.location !== null && state.location !== item.locationId) return acc;
            if (state.creditor !== null && state.creditor !== item.creditorId) return acc;
            if (state.category !== null && state.category !== item.categoryId) return acc;
            acc.miscInvoices.push(item);
            acc.total += item.amount;
            return acc;
          }, {
            _miscInvoices: [],
            miscInvoices: [],
            total: 0,
          })
        };
        return {
          ...newState,
          totalStr: numeral(newState.total / 100).format('$0,0.00'),
        };
      }
    case 'ADMIN-MISC-INVOICES-FILTER':
      {
        const {field, value} = action;
        const newState = {
          ...state,
          [field]: value
        };
        const res = {
          ...newState,
          ...newState._miscInvoices.reduce((acc, m) => {
            acc._miscInvoices.push(m);
            if (newState.location !== null && newState.location !== m.locationId) return acc;
            if (newState.creditor !== null && newState.creditor !== m.creditorId) return acc;
            if (newState.category !== null && newState.category !== m.categoryId) return acc;
            acc.miscInvoices.push(m);
            acc.total += m.amount;
            return acc;
          }, {
            _miscInvoices: [],
            miscInvoices: [],
            total: 0,
          })
        };
        return {
          ...res,
          totalStr: numeral(res.total / 100).format('$0,0.00'),
        };
      }
    case 'ADMIN-MISC-INVOICES-DELETE':
      return {
        ...state,
        miscInvoices: state.miscInvoices.filter(m => m.id !== action.id)
      };
    case 'ADMIN-MISC-INVOICES-CHANGE-SEARCH':
    {
      const {change} = action;
      return {
        ...state,
        ...change
      };
    }
    default:
      return state;
  }
};
