import React from 'react';

import Modal from 'components/Modal';

import Body from './Body.jsx';
import List from './List.jsx';


export default class Butterfly extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      panel: null,
      lastClick: 0,
      listModalOpen: false
    };
    this.onClick = this.onClick.bind(this);
    this.onPanelChangeQty = this.onPanelChange.bind(this, 'qty');
    this.onPanelChangeSize = this.onPanelChange.bind(this, 'size');
    this.onPanelAutoWorkClickPaint = this.onPanelAutoWorkClick.bind(this, 'paint');
    this.onPanelAutoWorkClickReplace = this.onPanelAutoWorkClick.bind(this, 'replace');
    this.onPanelListModalOpen = this.onPanelListModalOpen.bind(this);
    this.onPanelListModalClose = this.onPanelListModalClose.bind(this);
    this.onAdasOpen = this.onAdasOpen.bind(this);
    this.onNsiOpen = this.onNsiOpen.bind(this);
    this.onPrixcarOpen = this.onPrixcarOpen.bind(this);
  }
  onClick(panel, e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const now = Date.now();
    if (this.state.panel === panel && this.state.lastClick >= now - 1000) {
      this.setState({
        ...this.state,
        panel,
        lastClick: 0,
        listModalOpen: true
      });
    } else {
      this.setState({
        ...this.state,
        panel,
        lastClick: now
      });
    }
  }
  onPanelListModalClose(e) {
    this.setState({
      ...this.state,
      listModalOpen: false
    });
  }
  onPanelListModalOpen(e) {
    this.setState({
      ...this.state,
      listModalOpen: true
    });
  }
  onAdasOpen() {
    this.setState({
      ...this.state,
      panel: 'ADAS',
      listModalOpen: true
    });
  }
  onNsiOpen() {
    this.setState({
      ...this.state,
      panel: 'NSI',
      listModalOpen: true
    });
  }
  onPrixcarOpen() {
    this.setState({
      ...this.state,
      panel: 'PRIXCAR',
      listModalOpen: true
    });
  }
  onPanelAutoWorkClick(action, e) {
    const {rates, onPanelAutoWork} = this.props;
    const {panel} = this.state;
    onPanelAutoWork(panel, action, rates);
  }
  onPanelChange(field, e) {
    const {replacePanels, onPanelChangePDR} = this.props;
    const {panel} = this.state;
    if (replacePanels[panel] !== true) {
      onPanelChangePDR(panel, {[field]: e.target.value});
    }
  }
  renderModal() {
    const {panel, listModalOpen} = this.state;
    const {bodyPaint, panelLists, selectedItems, rates, onPanelListItemToggle} = this.props;
    const list = panelLists[panel] || [];
    return (
      <Modal title={panel} open={listModalOpen} onClose={this.onPanelListModalClose}>
        <List
          items={list}
          onToggleItem={onPanelListItemToggle}
          selectedItems={selectedItems}
          rates={rates}
          bodyPaint={bodyPaint}
        />
      </Modal>
    );
  }
  render() {
    const {
      bodyShape,
      pdr,
      pdrPanels,
      paintPanels,
      replacePanels,
    } = this.props;
    const {panel} = this.state;
    const {qty, size} = pdr[panel] || {qty: '', size: ''};
    return (
      <React.Fragment>
        <div
          className={
            [null, 'NSI', 'ADAS'].indexOf(panel) !== -1 ?
              'invisible' :
              'visible'
          }
        >
          <div className='input-group justify-content-center'>
            <input
              type='text'
              className='form-control'
              placeholder='# dents'
              value={qty || ''}
              onChange={this.onPanelChangeQty}
            />
            <select
              className='custom-select'
              value={size || ''}
              onChange={this.onPanelChangeSize}
            >
              <option value=''>dent size</option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
            </select>
            <div className='input-group-append'>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={this.onPanelAutoWorkClickPaint}
              >Paint</button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={this.onPanelAutoWorkClickReplace}
              >Replace</button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={this.onPanelListModalOpen}
              >List</button>
            </div>
          </div>
        </div>
        <Body
          shape={bodyShape}
          pdrPanels ={pdrPanels}
          paintPanels={paintPanels}
          replacePanels={replacePanels}
          selectedPanel={panel}
          onClick={this.onClick}
        />
        <div className='mt-4'>
          <button
            type='button'
            className='btn btn-outline-secondary w-100'
            onClick={this.onAdasOpen}
          >ADAS</button>
          <button
            type='button'
            className='btn btn-outline-secondary w-100 mt-2'
            onClick={this.onNsiOpen}
          >NSI</button>
        </div>
        {this.renderModal()}
      </React.Fragment>
    );
  }
};
