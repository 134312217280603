import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Qas from './component.jsx';


const load = (API, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/qas`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-QAS-SET-NOT-FOUND'
          });
          dispatch({
            type: 'JOB-GALLERY-SET',
            images: []
          });
          return null;
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        dispatch({
          type: 'JOB-QAS-SET-QAS',
          qas: resp.qas
        });
        dispatch({
          type: 'JOB-QAS-SET-FOUND',
          jobId,
          jobNumber: resp.jobNumber,
          flagged: resp.flagged,
          vpp: resp.vpp,
          vip: resp.vip,
          bookingType: resp.bookingType,
          importedFromArms: resp.importedFromArms,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-QAS-SET-NULL'
});

const qaAction = (API, jobId, {type, note, entries}) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/qa`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({type, note, entries}),
      }
    )
      .then(resp => {
        if (resp.error === true) return false;
        dispatch({
          type: 'JOB-QAS-SET-QAS',
          qas: resp.qas
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {jobId} = props;
  let jobValid = null;
  if (state.jobQas.notFound === true)
    jobValid = false;
  else if (state.jobQas.notFound === false) {
    jobValid = true;
  }
  if (jobValid !== true) {
    return {
      jobValid,
    };
  }
  const {
    jobQas: {jobNumber, flagged, vpp, vip, importUrl, bookingType, qas},
    params: {
      employees: {index: employeesIndex}
    }
  } = state;
  return {
    jobValid,
    jobId,
    jobNumber,
    flagged,
    vpp,
    vip,
    importUrl,
    bookingType,
    qas,
    employeesIndex,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: jobId => dispatch(load(props.API, jobId)),
  onUnload: () => dispatch(unload()),
  onQaAction: (jobId, payload) => dispatch(qaAction(props.API, jobId, payload))
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Qas)
);
