import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Pipeline from './component.jsx';


const setStageEmployee = (API, jobId, stage, employee) => {
  return (dispatch, getState) => {
    const {locationId} = getState();
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/production/${locationId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          jobId,
          stage,
          employee
        })
      }
    )
      .then(res => {
        if (res.error !== true) {
          dispatch({
            type: 'PRODUCTION-UPDATE',
            ...res
          });
        }
        //if (res === true) {
        //  dispatch({
        //    type: 'PRODUCTION-SET-STAGE-EMPLOYEE',
        //    jobId,
        //    stage,
        //    employee
        //  });
        //}
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const setStageDone = (API, jobId, stage, done) => {
  return (dispatch, getState) => {
    const {locationId} = getState();
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/production/${locationId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          jobId,
          stage,
          done
        })
      }
    )
      .then(res => {
        if (res.error !== true) {
          dispatch({
            type: 'PRODUCTION-UPDATE',
            ...res
          });
        }
        //if (res === true) {
        //  dispatch({
        //    type: 'PRODUCTION-SET-STAGE-DONE',
        //    jobId,
        //    stage,
        //    done
        //  });
        //}
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    params: {
      locations: {
        abilityLists: {
          [state.locationId]: employeeLists
        }
      }
    },
    production: {
      notFound,
      incoming,
      inProgress,
      outgoing,
      employeeJobs,
      filteredEmployee,
    }
  } = state;
  return {
    notFound,
    incoming,
    inProgress,
    outgoing,
    employeeJobs,
    filteredEmployee,
    employeeLists,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onSetStageEmployee: (jobId, stage, employee) =>
    dispatch(setStageEmployee(props.API, jobId, stage, employee)),
  onSetStageDone: (jobId, stage, done) =>
    dispatch(setStageDone(props.API, jobId, stage, done)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Pipeline)
);
