import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';
import MAKES from 'utils/makes';

import Autocomplete from 'components/Autocomplete.jsx';
import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


const Button = styled.button`
  cursor: pointer;
  & svg {
    fill: ${props => props.color};
  }
`;
const DeleteButton = styled(Icons.X)`
  cursor: pointer;
  stroke: ${colors.red};
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

export default class SettingsCreditor extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      supplierId: opts.supplierId || null,
      name: opts.supplierId === null ? opts.name : opts.supplierIndex[opts.supplierId].name,
      email: opts.supplierId === null ? opts.email : null,
      canMiscInvoice: opts.canMiscInvoice,
      canPart: opts.canPart,
      canSublet: opts.canSublet,
      selectedMake: null,
      type: opts.type,
      makes: opts.makes,
      ...opts.miscInvoiceCategories.reduce((acc, c) => {
        if (c && acc.micIndex[c] !== true) {
          acc.micIndex[c] = true;
          acc.miscInvoiceCategories.push(Number(c));
        }
        return acc;
      }, {micIndex: {}, miscInvoiceCategories: []}),
    };
    this.onFilter = this.onFilter.bind(this);
    this.onChangeName = this.onChange.bind(this, 'name');
    this.onChangeEmail = this.onChange.bind(this, 'email');
    this.onChangeCanMiscInvoice = this.onChange.bind(this, 'canMiscInvoice');
    this.onChangeCanPart = this.onChange.bind(this, 'canPart');
    this.onChangeCanSublet = this.onChange.bind(this, 'canSublet');
    this.onSelectMake = this.onSelectMake.bind(this);
    this.onAddMake = this.onAddMake.bind(this);
    this.onDeleteMake = this.onDeleteMake.bind(this);
    this.onToggleMiscInvoiceCategory = this.onToggleMiscInvoiceCategory.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onFilter(v) {
    const {supplierList} = this.props;
    const r = new RegExp(v, 'i');
    return supplierList
      .filter(s => r.test(s.name))
      .map(s => ({
        id: s.id,
        text: s.name
      }));
  }
  onChange(field, v) {
    if (field === 'name') {
      if (typeof v === 'string') {
        this.setState({
          supplierId: null,
          name: v
        });
      } else {
        this.setState({
          supplierId: v.id,
          name: v.text
        });
      }
    } else if (field === 'email') {
      this.setState({
        email: v
      });
    } else if (['canMiscInvoice', 'canPart', 'canSublet'].indexOf(field) !== -1) {
      this.setState({
        [field]: v.target.checked === true,
      });
    }
  }
  onSelectMake(make) {
    this.setState({
      selectedMake: make
    });
  }
  onAddMake() {
    const {selectedMake, makes} = this.state;
    if (selectedMake !== null) {
      this.setState({
        selectedMake: null,
        makes: makes.filter(m => m !== selectedMake).concat(selectedMake).sort()
      });
    }
  }
  onDeleteMake(i) {
    const {makes} = this.state;
    this.setState({
      makes: makes.filter((_, j) => j !== i)
    });
  }
  onToggleMiscInvoiceCategory(e) {
    const {index: micIndex, list: miscInvoiceCategories} = [
      e.target.checked === true ? e.target.value : undefined,
      ...this.state.miscInvoiceCategories,
    ].reduce((acc, c) => {
      if (c) {
        if (acc.index[c] !== true) {
          acc.index[c] = true;
          acc.list.push(Number(c));
        }
      }
      return acc;
    }, {index: {}, list: []});
    this.setState({
      micIndex,
      miscInvoiceCategories,
    });
  }
  onDelete() {
    const {
      onDelete, id
    } = this.props;
    onDelete(id);
  }
  onSave() {
    const {id, onSave} = this.props;
    const {
      supplierId,
      name,
      email,
      canMiscInvoice,
      canPart,
      canSublet,
      makes,
      miscInvoiceCategories,
    } = this.state;
    onSave(id, {
      supplierId,
      name: supplierId === null ? name : null,
      email: supplierId === null ? (email ? email.trim() : '') : null,
      canMiscInvoice,
      canPart,
      canSublet,
      makes: canPart === true ? makes : [],
      miscInvoiceCategories: canMiscInvoice === true ? miscInvoiceCategories : [],
    });
  }
  renderMake(make, i) {
    return (
      <Card className='d-flex flex-row justify-content-between align-items-center mt-2' key={i}>
        <div className='font-weight-bold'>{make}</div>
        <DeleteButton
          onClick={() => this.onDeleteMake(i)}
          width={16} height={16}
        />
      </Card>
    );
  }
  render() {
    console.log('settingscreditor:render');
    const {onClose} = this.props;
    const {
      id,
      miscInvoiceCategoryList,
    } = this.props;
    const {
      supplierId,
      name,
      email,
      canMiscInvoice,
      canPart,
      canSublet,
      selectedMake,
      makes,
      micIndex,
    } = this.state;
    const canSave =
      (
        supplierId !== null ||
        (
          name !== '' &&
          (
            (canPart !== true && canSublet !== true) ||
            (
              email !== null &&
              email !== ''
            )
          )
        )
      );
    return (
      <React.Fragment>
        <div className='modal-body'>
          <div>
            <small className='text-muted'>Name</small>
            <Autocomplete
              background={colors.grey1}
              value={name}
              uppercaseOnly={true}
              onChange={this.onChangeName}
              onFilter={this.onFilter}
            />
          </div>
          {supplierId === null && (
            <div className='mt-2'>
              <Field
                background={colors.grey1}
                title='Email'
                value={email}
                onChange={this.onChangeEmail}
              />
            </div>
          )}
          <div className='mt-2'>
            <small className='text-muted'>Type</small>
            <div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='canMiscInvoice'
                  id='creditor-canMiscInvoice'
                  checked={canMiscInvoice === true}
                  onChange={this.onChangeCanMiscInvoice}
                />
                <label className='form-check-label' htmlFor='creditor-canMiscInvoice'>Misc invoices</label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='canPart'
                  id='creditor-canPart'
                  checked={canPart === true}
                  onChange={this.onChangeCanPart}
                />
                <label className='form-check-label' htmlFor='creditor-canPart'>Parts</label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='canSublet'
                  id='creditor-canSublet'
                  checked={canSublet === true}
                  onChange={this.onChangeCanSublet}
                />
                <label className='form-check-label' htmlFor='creditor-canSublet'>Sublets</label>
              </div>
            </div>
          </div>
          {canMiscInvoice === true && (
            <React.Fragment>
              <h6 className='mt-3'>Misc invoice categories</h6>
              <div className='row'>
                {miscInvoiceCategoryList.map((c, i) => (
                  <div className='col-4 mt-2' key={i}>
                    <div className='form-group form-check mb-0'>
                      <input
                        type='checkbox'
                        id={`mic-${i}`}
                        className='form-check-input'
                        checked={micIndex[c.id] === true}
                        value={c.id}
                        onChange={this.onToggleMiscInvoiceCategory}
                      />
                      <label
                        htmlFor={`mic-${i}`}
                        className='form-check-label'
                      >{c.name}</label>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
          {canPart === true && (
            <React.Fragment>
              <h6 className='mt-3'>Makes</h6>
              <div className='d-flex flex-row justify-content-between'>
                <Select
                  className='flex-grow-1 mr-2'
                  options={MAKES}
                  background={colors.grey1}
                  selected={selectedMake}
                  onChange={this.onSelectMake}
                />
                <Button
                  className='btn btn-primary'
                  color={colors.white}
                  onClick={this.onAddMake}
                  disabled={selectedMake === null}
                ><Icons.Plus width={18} height={18} /></Button>
              </div>
              {makes.map(this.renderMake.bind(this))}
              {makes.length === 0 && (
                <div className='mt-2 text-muted'>Creditor doesn't have any makes yet</div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button type='button' className='btn btn-danger mr-auto' onClick={this.onDelete}>Delete</button>
          }
          <button
            type='button'
            className='btn btn-secondary'
            onClick={onClose}
          >Close</button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onSave}
            disabled={canSave === false}
          >Save</button>
        </div>
      </React.Fragment>
    );
  }
};
