import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';
import {ABILITY_ROWS} from './debtor-abilities.js';


const Button = styled.div`
  cursor: pointer;
  & svg {
    fill: ${props => props.color};
  }
`;
const DeleteButton = styled(Icons.X)`
  cursor: pointer;
  stroke: ${colors.red};
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

export default class SettingsDebtor extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      selectedStorm: null,
    };
    this.onSelectStorm = this.onSelectStorm.bind(this);
    this.onAddStorm = this.onAddStorm.bind(this);
    this.onChangeName = this.onChange.bind(this, 'name');
    this.onChangeEmail = this.onChange.bind(this, 'email');
    this.onChangePassword = this.onChange.bind(this, 'password');
    this.onChangeInvoiceName = this.onChange.bind(this, 'invoiceName');
    this.onChangeAbn = this.onChange.bind(this, 'abn');
    this.onChangeDentMatrixFactor = this.onChange.bind(this, 'dentMatrixFactor');
    this.onChangeRrRate = this.onChange.bind(this, 'rrRate');
    this.onChangeRepairRate = this.onChange.bind(this, 'repairRate');
    this.onChangePaintRate = this.onChange.bind(this, 'paintRate');
    this.onChangePricingMethod = this.onChange.bind(this, 'pricingMethod');
    this.onChangeCategoryMethod = this.onChange.bind(this, 'categoryMethod');
    this.onChangeTotalLossPercentage = this.onChange.bind(this, 'totalLossPercentage');
    this.onChangeMinCategoryAdjustment = this.onChange.bind(this, 'minCategoryAdjustment');
    this.onChangeGSTRate = this.onChange.bind(this, 'gstRate');
    this.onToggleCanOrm = this.onToggle.bind(this, 'canOrm');
    this.onToggleInfectionAllowanceEnabled = this.onToggle.bind(this, 'infectionAllowanceEnabled');
    this.onToggleStormActivityReportEnabled = this.onToggle.bind(this, 'stormActivityReportEnabled');
    this.onToggleExcludeFromAssessmentInbox = this.onToggle.bind(this, 'excludeFromAssessmentInbox');
    this.onToggleAutoSmsEnabled = this.onToggle.bind(this, 'autoSmsEnabled');
    this.onToggleAutoInvoiceEnabled = this.onToggle.bind(this, 'autoInvoiceEnabled');
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onSelectStorm(e) {
    this.setState({
      selectedStorm: e
    });
  }
  onAddStorm() {
    this.props.onAddStorm(this.state.selectedStorm);
    this.setState({
      selectedStorm: null
    });
  }
  onChange(field, e) {
    if (field === 'pricingMethod') {
      if (e.target.value !== 'category') {
        this.props.onChange({
          [field]: e.target.value,
          [`${field}Error`]: false,
          categoryMethod: null,
          categorySettings: null,
        });
      } else {
        this.props.onChange({
          [field]: e.target.value,
          [`${field}Error`]: false
        });
      }
    } else if (field === 'categoryMethod') {
      this.props.onChange({
        [field]: Number(e.target.value),
        [`${field}Error`]: false
      });
    } else if (field === 'totalLossPercentage') {
      if (/^\d*$/.test(e) === false || e > 100) return;
      this.props.onChange({
        [field]: e,
        [`${field}Error`]: false
      });
    } else if (field === 'gstRate') {
      if (/^\d*$/.test(e) === false || e > 100) return;
      this.props.onChange({
        [field]: e,
        [`${field}Error`]: false
      });
    } else if (field === 'dentMatrixFactor') {
      if (/^\d*$/.test(e) === false) return;
      this.props.onChange({
        [field]: e,
        [`${field}Error`]: false
      });
    } else {
      this.props.onChange({
        [field]: e,
        [`${field}Error`]: false
      });
    }
  }
  onToggleAbility(field, e) {
    this.props.onChangeAbility({
      [field]: e.target.checked
    });
  }
  onChangeCategorySettings(field, e) {
    /* eslint-disable no-useless-escape */
    if (/^[\d\.]*$/.test(e)) {
      const {categoryMethod, categorySettings: _categorySettings} = this.props;
      let categorySettings = _categorySettings || {};
      const {
        1: current1,
        2: current2,
        3: current3,
        4: current4,
        5: current5,
        6: current6,
        7: current7,
        turretAllowance,
        min,
        max,
      } = categorySettings;
      if (categoryMethod === 1) {
        categorySettings = {
          1: current1,
          2: current2,
          3: current3,
          4: current4,
          5: current5,
          6: current6,
          7: current7,
          [field]: Number(e)
        };
      } else if (categoryMethod === 2 || categoryMethod === 3) {
        categorySettings = {
          1: current1,
          2: current2,
          3: current3,
          min,
          max,
          [field]: Number(e)
        };
      } else if (categoryMethod === 4) {
        categorySettings = {
          1: current1,
          2: current2,
          3: current3,
          4: current4,
          turretAllowance,
          min,
          max,
          [field]: Number(e)
        };
      }
      this.props.onChange({
        categorySettings,
      });
    }
  }
  onToggle(field, e) {
    this.props.onChange({
      [field]: !this.props[field]
    });
  }
  onDelete() {
    const {
      onDelete, id
    } = this.props;
    onDelete(id);
  }
  onSave() {
    const {
      onSave,
      id, name, email, password, abilities,
      portalStorms, rrRate, repairRate, paintRate,
      dentMatrixFactor, totalLossPercentage, minCategoryAdjustment,
      pricingMethod, categoryMethod, categorySettings,
      canOrm, infectionAllowanceEnabled,
      stormActivityReportEnabled,
      excludeFromAssessmentInbox,
      autoSmsEnabled, autoInvoiceEnabled,
      gstRate, invoiceName, abn,
    } = this.props;
    onSave(id, {
      name, email,
      password: (password && password !== '' ? password : null),
      abilities: ABILITY_ROWS.reduce((acc, ABILITIES) => ABILITIES.reduce((acc, a) => {
        acc[a.key] = abilities[a.key] === true;
        return acc;
      }, acc), {}),
      portalStorms,
      rrRate, repairRate, paintRate,
      dentMatrixFactor, totalLossPercentage, minCategoryAdjustment,
      pricingMethod, categoryMethod, categorySettings,
      canOrm, infectionAllowanceEnabled,
      stormActivityReportEnabled,
      excludeFromAssessmentInbox,
      autoSmsEnabled, autoInvoiceEnabled,
      gstRate, invoiceName, abn,
    });
  }
  renderStorm(stormId, i) {
    const {stormIndex, onDeleteStorm} = this.props;
    return (
      <Card className='mt-2' key={i}>
        <div className='d-flex flex-row'>
          <div className='mx-2 flex-grow-1 font-weight-bold cursor-pointer'>{stormIndex[stormId]}</div>
          <DeleteButton
            className='mt-1'
            onClick={() => onDeleteStorm(stormId)}
            width={16} height={16} />
        </div>
      </Card>
    );
  }
  render() {
    console.log('settingsdebtor:render');
    const {onClose} = this.props;
    const {
      id,
      name, nameError,
      email, emailError,
      password, passwordError,
      invoiceName, invoiceNameError,
      abn, abnError,
      abilities, portalStorms,
      rrRate, rrRateError,
      repairRate, repairRateError,
      paintRate, paintRateError,
      gstRate, gstRateError,
      dentMatrixFactor, dentMatrixFactorError,
      totalLossPercentage, totalLossPercentageError,
      minCategoryAdjustment, minCategoryAdjustmentError,
      pricingMethod, categoryMethod,
      categorySettings: _categorySettings,
      canOrm, infectionAllowanceEnabled,
      stormActivityReportEnabled,
      excludeFromAssessmentInbox,
      autoSmsEnabled,
      autoInvoiceEnabled,
      stormList,
    } = this.props;
    const {selectedStorm} = this.state;
    const categorySettings = _categorySettings || {};
    return (
      <React.Fragment>
        <div className='modal-body'>
          <Field
            title='Debtor Name'
            background={colors.grey1}
            uppercaseOnly={true}
            value={name}
            error={nameError}
            onChange={this.onChangeName}
          />
          <Field
            title='Email'
            background={colors.grey1}
            value={email}
            error={emailError}
            onChange={this.onChangeEmail}
          />
          <Field
            type='password'
            background={colors.grey1}
            title='Password'
            value={password}
            error={passwordError}
            onChange={this.onChangePassword} />
            <Field
              type='invoiceName'
              background={colors.grey1}
              title='Invoice Name'
              value={invoiceName}
              error={invoiceNameError}
              onChange={this.onChangeInvoiceName} />
            <Field
              type='abn'
              background={colors.grey1}
              title='ABN'
              value={abn}
              error={abnError}
              onChange={this.onChangeAbn} />
          <div className='mt-2'>
            <small className='text-muted'>Abilities</small>
            {ABILITY_ROWS.map((ABILITIES, i) => (
              <React.Fragment key={i}>
                {i > 0 && <hr className='mt-2 mb-0' />}
                <div className='row'>
                  {ABILITIES.map((a, j) => (
                    <div className='col-4 mt-2' key={j}>
                      <div className='form-group form-check mb-0'>
                        <input
                          type='checkbox'
                          id={`ea-${i}-${j}`}
                          className='form-check-input'
                          checked={abilities[a.key] === true}
                          onChange={this.onToggleAbility.bind(this, a.key)}
                        />
                        <label
                          htmlFor={`ea-${i}-${j}`}
                          className='form-check-label'
                        >{a.text}</label>
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
          <h6 className='mt-3'>Portal storms</h6>
          <div className='d-flex flex-row justify-content-between'>
            <Select
              className='flex-grow-1 mr-2'
              options={stormList}
              textField='name'
              background={colors.grey1}
              selected={selectedStorm}
              onChange={this.onSelectStorm}
            />
            <Button
              className='btn btn-primary'
              color={colors.white}
              onClick={this.onAddStorm}
            ><Icons.Plus width={18} height={18} /></Button>
          </div>
          {portalStorms.map(this.renderStorm.bind(this))}
          {portalStorms.length === 0 && (
            <div className='mt-2 text-muted'>Debtor doesn't have any storms available in portal.</div>
          )}
          <div className='row mt-2'>
            <div className='col-12'>
              <Field
                title='GST rate'
                background={colors.grey1}
                value={gstRate}
                error={gstRateError}
                onChange={this.onChangeGSTRate}
              />
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-4'>
              <Field
                title='RR rate'
                background={colors.grey1}
                value={rrRate}
                error={rrRateError}
                onChange={this.onChangeRrRate}
              />
            </div>
            <div className='col-4'>
              <Field
                title='Repair rate'
                background={colors.grey1}
                value={repairRate}
                error={repairRateError}
                onChange={this.onChangeRepairRate}
              />
            </div>
            <div className='col-4'>
              <Field
                title='Paint rate'
                background={colors.grey1}
                value={paintRate}
                error={paintRateError}
                onChange={this.onChangePaintRate}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <Field
                title='Dent matrix factor (%)'
                background={colors.grey1}
                value={dentMatrixFactor}
                error={dentMatrixFactorError}
                onChange={this.onChangeDentMatrixFactor}
              />
            </div>
            <div className='col-4'>
              <Field
                title='Total loss %'
                background={colors.grey1}
                value={totalLossPercentage}
                error={totalLossPercentageError}
                onChange={this.onChangeTotalLossPercentage}
              />
            </div>
            <div className='col-4'>
              <Field
                title='Min Cat Adjustment'
                background={colors.grey1}
                value={minCategoryAdjustment}
                error={minCategoryAdjustmentError}
                onChange={this.onChangeMinCategoryAdjustment}
              />
            </div>
          </div>
          <div>
            <small className='text-muted'>Pricing method</small>
            <div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='debtor-pricing-method'
                  id='debtor-pricing-method-1'
                  value='category'
                  checked={pricingMethod === 'category'}
                  onChange={this.onChangePricingMethod}
                />
                <label className='form-check-label' htmlFor='debtor-pricing-method-1'>category</label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='debtor-pricing-method'
                  id='debtor-pricing-method-2'
                  value='line-by-line'
                  checked={pricingMethod === 'line-by-line'}
                  onChange={this.onChangePricingMethod}
                />
                <label className='form-check-label' htmlFor='debtor-pricing-method-2'>line by line</label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='debtor-pricing-method'
                  id='debtor-pricing-method-3'
                  value='panel'
                  checked={pricingMethod === 'panel'}
                  onChange={this.onChangePricingMethod}
                />
                <label className='form-check-label' htmlFor='debtor-pricing-method-3'>panel</label>
              </div>
            </div>
          </div>
          {pricingMethod === 'category' && (
            <React.Fragment>
              <div>
                <small className='text-muted'>Category method</small>
                <div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='storm-category-method'
                      id='storm-category-method-1'
                      value='1'
                      checked={categoryMethod === 1}
                      onChange={this.onChangeCategoryMethod}
                    />
                    <label className='form-check-label' htmlFor='storm-category-method-1'>1</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='storm-category-method'
                      id='storm-category-method-2'
                      value='2'
                      checked={categoryMethod === 2}
                      onChange={this.onChangeCategoryMethod}
                    />
                    <label className='form-check-label' htmlFor='storm-category-method-2'>2</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='storm-category-method'
                      id='storm-category-method-3'
                      value='3'
                      checked={categoryMethod === 3}
                      onChange={this.onChangeCategoryMethod}
                    />
                    <label className='form-check-label' htmlFor='storm-category-method-3'>3</label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='storm-category-method'
                      id='storm-category-method-4'
                      value='4'
                      checked={categoryMethod === 4}
                      onChange={this.onChangeCategoryMethod}
                    />
                    <label className='form-check-label' htmlFor='storm-category-method-4'>4</label>
                  </div>
                </div>
              </div>
              {categoryMethod === 1 && (
                <div>
                  <small className='text-muted'>Category settings</small>
                  <div className='d-flex flex-row flex-wrap'>
                    <Field
                      className='mr-2'
                      title='cat 1'
                      background={colors.grey1}
                      value={categorySettings[1]}
                      onChange={this.onChangeCategorySettings.bind(this, '1')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 2'
                      background={colors.grey1}
                      value={categorySettings[2]}
                      onChange={this.onChangeCategorySettings.bind(this, '2')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 3'
                      background={colors.grey1}
                      value={categorySettings[3]}
                      onChange={this.onChangeCategorySettings.bind(this, '3')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 4'
                      background={colors.grey1}
                      value={categorySettings[4]}
                      onChange={this.onChangeCategorySettings.bind(this, '4')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 5'
                      background={colors.grey1}
                      value={categorySettings[5]}
                      onChange={this.onChangeCategorySettings.bind(this, '5')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 6'
                      background={colors.grey1}
                      value={categorySettings[6]}
                      onChange={this.onChangeCategorySettings.bind(this, '6')}
                    />
                    <Field
                      title='cat 7'
                      background={colors.grey1}
                      value={categorySettings[7]}
                      onChange={this.onChangeCategorySettings.bind(this, '7')}
                    />
                  </div>
                </div>
              )}
              {(categoryMethod === 2 || categoryMethod === 3) && (
                <div>
                  <small className='text-muted'>Category settings</small>
                  <div className='d-flex flex-row flex-wrap'>
                    <Field
                      className='mr-2'
                      title='cat 1'
                      background={colors.grey1}
                      value={categorySettings[1]}
                      onChange={this.onChangeCategorySettings.bind(this, '1')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 2'
                      background={colors.grey1}
                      value={categorySettings[2]}
                      onChange={this.onChangeCategorySettings.bind(this, '2')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 3'
                      background={colors.grey1}
                      value={categorySettings[3]}
                      onChange={this.onChangeCategorySettings.bind(this, '3')}
                    />
                  </div>
                  <div className='d-flex flex-row flex-wrap'>
                    <Field
                      className='mr-2'
                      title='min'
                      background={colors.grey1}
                      value={categorySettings.min}
                      onChange={this.onChangeCategorySettings.bind(this, 'min')}
                    />
                    <Field
                      className='mr-2'
                      title='max'
                      background={colors.grey1}
                      value={categorySettings.max}
                      onChange={this.onChangeCategorySettings.bind(this, 'max')}
                    />
                  </div>
                </div>
              )}
              {categoryMethod === 4 && (
                <div>
                  <small className='text-muted'>Category settings</small>
                  <div className='d-flex flex-row flex-wrap'>
                    <Field
                      className='mr-2'
                      title='cat 1'
                      background={colors.grey1}
                      value={categorySettings[1]}
                      onChange={this.onChangeCategorySettings.bind(this, '1')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 2'
                      background={colors.grey1}
                      value={categorySettings[2]}
                      onChange={this.onChangeCategorySettings.bind(this, '2')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 3'
                      background={colors.grey1}
                      value={categorySettings[3]}
                      onChange={this.onChangeCategorySettings.bind(this, '3')}
                    />
                    <Field
                      className='mr-2'
                      title='cat 4'
                      background={colors.grey1}
                      value={categorySettings[4]}
                      onChange={this.onChangeCategorySettings.bind(this, '4')}
                    />
                  </div>
                  <div className='d-flex flex-row flex-wrap'>
                    <Field
                      className='mr-2'
                      title='turret allowance'
                      background={colors.grey1}
                      value={categorySettings.turretAllowance}
                      onChange={this.onChangeCategorySettings.bind(this, 'turretAllowance')}
                    />
                  </div>
                  <div className='d-flex flex-row flex-wrap'>
                    <Field
                      className='mr-2'
                      title='min'
                      background={colors.grey1}
                      value={categorySettings.min}
                      onChange={this.onChangeCategorySettings.bind(this, 'min')}
                    />
                    <Field
                      className='mr-2'
                      title='max'
                      background={colors.grey1}
                      value={categorySettings.max}
                      onChange={this.onChangeCategorySettings.bind(this, 'max')}
                    />
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          <div className='mt-3'>
            <button
              className={canOrm ? 'btn btn-secondary w-100' : 'btn btn-outline-secondary w-100'}
              onClick={this.onToggleCanOrm}
            >{canOrm ? 'Supports ORM' : 'Does not support ORM'}</button>
          </div>
          <div className='mt-3'>
            <button
              className={
                infectionAllowanceEnabled ?
                  'btn btn-secondary w-100' :
                  'btn btn-outline-secondary w-100'
              }
              onClick={this.onToggleInfectionAllowanceEnabled}
            >{
              infectionAllowanceEnabled ?
                'Infection allowance enabled' :
                'Infection allowance disabled'
            }</button>
          </div>
          <div className='mt-3'>
            <button
              className={
                stormActivityReportEnabled ?
                  'btn btn-secondary w-100' :
                  'btn btn-outline-secondary w-100'
              }
              onClick={this.onToggleStormActivityReportEnabled}
            >{
              stormActivityReportEnabled ?
                'Storm activity report enabled' :
                'Storm activity report disabled'
            }</button>
          </div>
          <div className='mt-3'>
            <button
              className={
                excludeFromAssessmentInbox ?
                  'btn btn-outline-secondary w-100' :
                  'btn btn-secondary w-100'
              }
              onClick={this.onToggleExcludeFromAssessmentInbox}
            >{
              excludeFromAssessmentInbox ?
                'Excluded from assessment inbox' :
                'Included in assessment inbox'
            }</button>
          </div>
          <div className='mt-3'>
            <button
              className={autoSmsEnabled ? 'btn btn-secondary w-100' : 'btn btn-outline-secondary w-100' }
              onClick={this.onToggleAutoSmsEnabled}
            >{autoSmsEnabled ? 'Auto Sms Enabled' : 'Auto Sms Disabled'}</button>
          </div>
          <div className='mt-3'>
            <button
              className={autoInvoiceEnabled ? 'btn btn-secondary w-100' : 'btn btn-outline-secondary w-100' }
              onClick={this.onToggleAutoInvoiceEnabled}
            >{autoInvoiceEnabled ? 'Auto Invoice Enabled' : 'Auto Invoice Disabled'}</button>
          </div>
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button type='button' className='btn btn-danger mr-auto' onClick={this.onDelete}>Delete</button>
          }
          <button type='button' className='btn btn-secondary' onClick={onClose}>Close</button>
          <button type='button' className='btn btn-primary' onClick={this.onSave}>Save</button>
        </div>
      </React.Fragment>
    );
  }
};
