import React from 'react';
import styled from 'styled-components';

import colors from 'colors';
import COUPE from './coupe.js';
import HATCH from './hatch.js';
import SEDAN from './sedan.js';
import UTE2DR from './ute-2dr.js';
import UTE4DR from './ute-4dr.js';
import VAN from './van.js';
import WAGON from './wagon.js';


const bodies = {COUPE, HATCH, SEDAN, UTE2DR, UTE4DR, VAN, 'WAGON/5DR': WAGON};

const SVG = styled.svg`
  max-width: 400px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;
const Panel = styled.g`
  cursor: pointer;
  opacity: ${props => (props.selected || props.paint || props.replace || props.pdr ? '0.7' : '0')};
  fill: ${props => (props.replace ? colors.orange : (props.paint ? colors.blue : (props.pdr ? colors.green : '#fff')))};
  stroke: ${props => props.selected ? colors.red : '#000'};
  stroke-width: ${props => props.selected ? '5px' : '1px'};
`;


export default class Body extends React.Component {
  render() {
    const {shape, selectedPanel, pdrPanels, paintPanels, replacePanels, onClick} = this.props;
    const {width, height, template, panels} = bodies[shape];
    return (
      <SVG
        xmlns='http://www.w3.org/2000/svg'
        viewBox={`0 0 ${width} ${height}`}
        fill='none'
      >
        {template}
        {
          panels.map((panel, i) => (
            <Panel
              transform={panel.transform}
              key={i}
              pdr={pdrPanels[panel.name]}
              paint={paintPanels[panel.name]}
              replace={replacePanels[panel.name]}
              selected={selectedPanel === panel.name}
              onClick={onClick.bind(null, panel.name)}
            >
              {panel.paths.map((path, j) => (
                typeof path === 'string' ?
                  (
                    <path
                      key={j}
                      d={path}
                    />
                  ) :
                  (
                    <path
                      key={j}
                      fillRule={path.fillRule}
                      d={path.d}
                    />
                  )
              ))}
            </Panel>
          ))
        }
      </SVG>
    );
  }
};

