import React from 'react';
import * as Icons from 'react-feather';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import JobTitle from '../JobTitle';
import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.orange};
  & svg {
    stroke: ${colors.orange};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Button = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.div`
  color: ${opts => opts.isReceivable ? colors.red : 'inherit'};
  margin-bottom: 1rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;


/* eslint-disable no-useless-escape */
const DOLLAR_AMOUNT_REGEX = /^\-?[0-9]+\.?([0-9]{0,2})?$/;
const PAYMENT_METHODS = [
  'cash',
  'cheque',
  'direct deposit',
  'eftpos',
  'waived',
];

export default class Payments extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      amount: '',
      amountValid: false,
      isExcess: false,
      paymentMethod: null,
      paymentReference: null,
      canAdd: false
    };
    this.onChangeAmount = this.onChange.bind(this, 'amount');
    this.onChangeExcess = this.onChange.bind(this, 'isExcess');
    this.onChangePaymentMethod = this.onChange.bind(this, 'paymentMethod');
    this.onChangePaymentReference = this.onChange.bind(this, 'paymentReference');
    this.onAdd = this.onAdd.bind(this);
  }
  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {jobId, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
      this.setState({
        amount: '',
        isExcess: false,
        paymentMethod: null,
        paymentReference: null,
        canAdd: false
      });
    }
  }
  onChange(field, e) {
    const {isPaid} = this.props;
    let newState = null;
    if (field === 'amount') {
      const amountValid = DOLLAR_AMOUNT_REGEX.test(e);
      newState = {
        ...this.state,
        amount: e,
        amountValid,
      };
    } else if (field === 'isExcess') {
      newState = {
        ...this.state,
        isExcess: e.target.checked
      };
    } else {
      newState = {
        ...this.state,
        [field]: e
      };
    }
    newState.canAdd = (
      newState.amount !== '' &&
      newState.amountValid === true &&
      isPaid === false &&
      newState.paymentMethod !== null
    );
    this.setState(newState);
  }
  onAdd(e) {
    const {amount, amountValid, isExcess, paymentMethod, paymentReference} = this.state;
    const {jobId, onAddPayment} = this.props;
    if (amountValid === true) {
      onAddPayment(jobId, Math.round(amount * 100), isExcess, paymentMethod, paymentReference)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered while adding payment.');
          } else {
            this.setState({
              amount: '',
              isExcess: false,
              paymentMethod: null,
              paymentReference: null,
              canAdd: false,
            });
            toast.success('Payment successfully added.');
          }
        });
    }
  }
  render() {
    console.log('payments:render');
    const {jobValid} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }

    const {
      jobId,
      jobNumber,
      flagged,
      vpp,
      vip,
      importUrl,
      isInvoiced,
      isPaid,
      payments,
      outstandingAmount,
      employeesIndex
    } = this.props;
    const {amount, amountValid, isExcess, paymentMethod, paymentReference, canAdd} = this.state;
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        {isInvoiced === false && (
          <ErrorContainer
            className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center mt-3'>
            <Icons.AlertOctagon width={64} height={64} className='mr-3' />
            <div>Job not invoiced yet.</div>
          </ErrorContainer>
        )}
        {isInvoiced === true && (
          <Card className='p-3 mt-3'>
            <div className='d-flex flex-row justify-content-between font-weight-bold mb-2'>
              <div>Payments</div>
              {isPaid === true && (
                <div className='font-weight-bold text-danger text-center'>PAID</div>
              )}
              {(isPaid === false && outstandingAmount !== null) && (
                <div className='font-weight-bold text-danger text-center'>
                  outstanding: {outstandingAmount}
                </div>
              )}
            </div>
            <div className='row align-items-center mb-3'>
              <div className='col-auto'>
                <Field
                  title='Amount ($)'
                  error={amount !== '' && amountValid !== true}
                  value={amount}
                  onChange={this.onChangeAmount}
                />
              </div>
              <div className='col-auto'>
                <label className='mb-0' htmlFor='excess-payment'>
                  <small className='text-muted'>Excess payment</small>
                </label>
                <div>
                <input
                  type='checkbox'
                  className='form-control border-0 px-0'
                  id='excess-payment'
                  checked={isExcess}
                  onChange={this.onChangeExcess}
                />
                </div>
              </div>
              <div className='col-auto'>
                <Select
                  title='Payment method'
                  selected={paymentMethod}
                  options={PAYMENT_METHODS}
                  onChange={this.onChangePaymentMethod}
                />
              </div>
              <div className='col-auto'>
                <Field
                  title='Reference'
                  value={paymentReference}
                  onChange={this.onChangePaymentReference}
                />
              </div>
              <div className='col-auto'>
                <Button
                  className='btn btn-primary mt-4'
                  type='button'
                  onClick={this.onAdd}
                  disabled={canAdd !== true}
                ><Icons.Plus width={18} height={18} /></Button>
              </div>
            </div>
            {payments.length === 0 && (
              <div className='text-center text-secondary'>No payments yet.</div>
            )}
            {payments.length !== 0 && payments.map((p, i) => (
              <Item key={i} isReceivable={p.isReceivable}>
                <small className='text-muted'>
                  {(p.employeeId && employeesIndex[p.employeeId].name) || 'SYSTEM'} {'\u2013'} {p.ts}
                </small>
                <div>
                  {p.amountStr} {p.typeStr} {p.ref}
                </div>
              </Item>
            ))}
          </Card>
        )}
      </React.Fragment>
    );
  };
};

