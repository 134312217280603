let currentState = null;
const cv = window.localStorage.getItem('authentication');
if (cv) {
  currentState = JSON.parse(cv);
}

export default (state = currentState, action) => {
  switch (action.type) {
    case 'AUTHENTICATION-RESET':
    {
      window.localStorage.removeItem('authentication');
      return null;
    }
    case 'AUTHENTICATION-SET':
    {
      const {profiles} = action;
      const newState = {
        activeProfile: 0,
        profiles
      };
      window.localStorage.setItem('authentication', JSON.stringify(newState));
      return newState;
    }
    case 'AUTHENTICATION-SET-ACTIVE-PROFILE':
    {
      const {profile} = action;
      const newState = {
        ...state,
        activeProfile: profile,
      };
      window.localStorage.setItem('authentication', JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
};
