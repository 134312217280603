export default (state = {openIndex: null, images: []}, action) => {
  switch (action.type) {
    case 'SUPPLIER-GALLERY-SET':
      return {
        openIndex: null,
        images: action.images,
      };
    case 'SUPPLIER-GALLERY-SET-OPEN-IMAGE':
    {
      const {index: openIndex} = action;
      return {
        ...state,
        openIndex: openIndex !== null ?
          (openIndex + state.images.length) % state.images.length :
          null,
      };
    }
    default:
      return state;
  }
};
