import moment from 'moment';
import numeral from 'numeral';
import QRCode from 'qrcode.react';
import React from 'react';

import env from 'env';
import Modal from 'components/Modal';


export default class Print extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      dateStr: null,
      lines: [],
      creditorId: null,
      totalStr: '',
      canPrint: false,
    };
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
    this.timeout = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.lines !== prevProps.lines ||
      this.state.open !== prevState.open
    ) {
      const {lines, creditorId, total} = Object.values(this.props.lines)
        .reduce((acc, l) => {
          if (l) {
            if (acc.creditorId === null) {
              acc.creditorId = l.creditorId;
            } else if (l.creditorId !== acc.creditorId) {
              acc.creditorId = false;
            }
            acc.total += (l.qty * l.costPrice);
            acc.lines.push(l);
          }
          return acc;
        }, {lines: [], creditorId: null, total: 0});
      this.setState({
        lines: creditorId === false ? [] : lines,
        creditorId: creditorId === false ? null : creditorId,
        totalStr: creditorId === false ? '' : numeral(total).format('$0,0.00'),
        canPrint: lines.length !== 0 && creditorId !== false
      });
    }
  }

  onToggleModal(open) {
    this.setState({
      dateStr: moment().format('DD-MM-YY'),
      open,
    });
    if (open === true) {
      setTimeout(() => {
        window.print();
      }, 1);
    }
  }

  render () {
    console.log('print:render');
    const {companyName, companyAddress, location, jobId, jobNumber, creditors} = this.props;
    const {open, dateStr, lines, creditorId, totalStr, canPrint} = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-secondary ml-2'
          onClick={this.onOpen}
          disabled={canPrint !== true}
        >Print</button>
        {open === true && (
          <Modal
            open={open}
            onClose={this.onClose}
            fullScreen={true}
          >
            <div className='modal-body'>
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <div className='text-uppercase'>
                  <h3>{companyName}</h3>
                  <span>{location ? location.address : companyAddress}</span>
                </div>
                <QRCode value={`${env.WWW_SERVER}/job/${jobId}/purchasing`} renderAs='svg' />
              </div>
              <div className='row mt-5'>
                <div className='col'>
                  <h5 className='mb-0'>{creditors[creditorId].name}</h5>
                </div>
                <div className='col text-center'>
                  <h5 className='mb-0'>{jobNumber}</h5>
                </div>
                <div className='col text-right'>
                  <h5 className='mb-0'>{dateStr}</h5>
                </div>
              </div>
              <table className='table mt-5'>
                <thead>
                  <tr>
                    <th>Invoice #</th>
                    <th>Invoice date</th>
                    <th>Description</th>
                    <th>Reason</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {lines.map((l, i) => (
                    <tr key={i}>
                      <td>{l.invoiceNumber}</td>
                      <td>{l.invoiceDateStr}</td>
                      <td>{l.qty}x {l.description} {l.partNumber}</td>
                      <td>{l.reason}</td>
                      <td>{l.costPriceStr}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4} className='text-right font-weight-bold'>TOTAL</td>
                    <td className='font-weight-bold'>{totalStr}</td>
                  </tr>
                </tbody>
              </table>
              <div className='row mt-5'>
                <div className='col-3 text-right mt-auto'><h4 className='mb-0'>Picked up by (name):</h4></div>
                <div className='col-3'>
                  <div className='border-bottom border-dark p-4' />
                </div>
                <div className='col-3 text-right mt-auto'><h4 className='mb-0'>Signature:</h4></div>
                <div className='col-3'>
                  <div className='border-bottom border-dark p-4' />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
};

