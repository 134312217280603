import numeral from 'numeral';

let currentState = null;
const cs = window.localStorage.getItem('debtor-report:stormId');
if (cs) {
  currentState = JSON.parse(cs);
}

const REPORT = [
  {key: 'numAllocated', title: '# Allocated'},
  {key: 'numCancelled', title: '# Cancelled'},
  {key: 'numTotalLoss', title: '# Total loss'},
  {key: 'numCashSettle', title: '# Cash settled'},
  {key: 'numContacted', title: '# Contacted'},
  {key: 'numAssessmentBooked', title: '# Assessments booked'},
  {key: 'numAssessNoShow', title: '# Assess no shows'},
  {key: 'numAssessed', title: '# Assessed'},
  {key: 'numBooked', title: '# Booked'},
  {key: 'numBookedPdr', title: '# Booked (pdr)'},
  {key: 'numBookedPaint', title: '# Booked (paint)'},
  {key: 'numBookedTurret', title: '# Booked (turret)'},
  {key: 'numRepairNoShow', title: '# Repair no shows'},
  {key: 'numRepairStarted', title: '# Repair started'},
  {key: 'numRepairStartedPdr', title: '# Repair started (pdr)'},
  {key: 'numRepairStartedPaint', title: '# Repair started (paint)'},
  {key: 'numRepairStartedTurret', title: '# Repair started (turret)'},
  {key: 'numRepairFinished', title: '# Repair finished'},
  {key: 'numRepairFinishedPdr', title: '# Repair finished (pdr)'},
  {key: 'numRepairFinishedPaint', title: '# Repair finished (paint)'},
  {key: 'numRepairFinishedTurret', title: '# Repair finished (turret)'},
  {key: 'numInvoiced', title: '# Invoiced'},
  {key: 'numInvoicedPdr', title: '# Invoiced (pdr)'},
  {key: 'numInvoicedPaint', title: '# Invoiced (paint)'},
  {key: 'numInvoicedTurret', title: '# Invoiced (turret)'},
];

export default (state = {
  notFound: null,
  stormId: currentState,
  storms: null,
  report: null
}, action) => {
  switch (action.type) {
    case 'DEBTOR-REPORT-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        report: null
      };
    case 'DEBTOR-REPORT-SET-NULL':
      return {
        ...state,
        notFound: null,
        storms: null,
        report: null,
      };
    case 'DEBTOR-REPORT-CHANGE-STORM-ID':
      const {stormId} = action;
      window.localStorage.setItem('debtor-report:stormId', JSON.stringify(stormId));
      return {
        ...state,
        stormId,
        report: null,
      };
    case 'DEBTOR-REPORT-SET':
      {
        const {storms, report} = action;
        return {
          ...state,
          notFound: false,
          storms,
          report: report === null ? null : REPORT.map(r => ({
            ...r,
            count: numeral(report[r.key]).format('0,0'),
          }))
        };
      }
    default:
      return state;
  }
};
