import {get as getAvatarBg} from 'utils/avatar-bgs.js';
import getInitials from 'utils/get-initials.js';


const ABILITY_MAP = {
  canRr: ['strip', 'fitup'],
  canRepair: ['repair'],
  canPaint: ['paint'],
  canDetail: ['detail'],
  canQa: ['qa']
};


const generateEmployeeLists = employeeIndex => (
  Object.keys(employeeIndex).reduce((acc, eId) => {
    const e = employeeIndex[eId];
    if (e.deleted !== true) {
      acc.list.push(e);
    }
    return acc;
  }, {index: employeeIndex, list: []})
);
const generateLocationLists = (locationIndex, employeeIndex, selfId) => (
  Object.keys(locationIndex).reduce((acc, lId) => {
    const l = locationIndex[lId];
    if (l.deleted !== true) {
      acc.list.push(l);
    }
    if (!acc.abilityLists.hasOwnProperty(lId)) {
      acc.abilityLists[lId] = {};
      acc.abilityIndex[lId] = {};
    }
    l.productionEmployees = [];
    l.employees.forEach(({id: eId, abilities}) => {
      const employee = employeeIndex[eId];
      if (employee.deleted !== true) {
        let canProduction = false;
        Object.keys(abilities).forEach(key => {
          const ability = abilities[key];
          if (ability === true) {
            if (!acc.abilityIndex[lId].hasOwnProperty(eId)) {
              acc.abilityIndex[lId][eId] = {};
            }
            acc.abilityIndex[lId][eId][key] = true;
            (ABILITY_MAP[key] || []).forEach(abilityKey => {
              if (canProduction !== true) {
                canProduction = true;
              }
              if (!acc.abilityLists[lId].hasOwnProperty(abilityKey)) {
                acc.abilityLists[lId][abilityKey] = [];
              }
              acc.abilityLists[lId][abilityKey].push({
                id: eId,
                name: employee.name,
              });
            });
          }
        });
        if (canProduction === true) {
          l.productionEmployees.push(eId);
        }
        if (eId === selfId) {
          acc.selfLocations.push({
            id: l.id,
            name: l.name
          });
        }
      }
    });
    l.productionEmployees.sort((a, b) => {
      if (employeeIndex[a].name < employeeIndex[b].name) return -1;
      else if (employeeIndex[a].name > employeeIndex[b].name) return 1;
      else return 0;
    });
    return acc;
  }, {
    index: locationIndex,
    list: [],
    abilityLists: {},
    abilityIndex: {},
    selfLocations: []
  })
);


const transformParams = params => {
  const {
    version,
    selfId,
    generalSettings,
    mailboxes: _mailboxes,
    storms: _storms,
    companies: _companies,
    locations: _locations,
    debtors: _debtors,
    creditors: _creditors,
    employees: _employees,
    smsTemplates: _smsTemplates,
    partsVehicles,
    lightTunnels,
    miscInvoiceCategories,
  } = params;
  const employeeIndex = _employees.reduce((acc, e) => {
    const avatarColor = getAvatarBg(e.id);
    acc[e.id] = {
      id: e.id,
      name: e.name,
      deleted: e.deleted,
      initials: getInitials(e.name),
      avatarBg: avatarColor.dark,
      avatarBgLight: avatarColor.light,
    };
    return acc;
  }, {null: {id: null, name: 'SYSTEM'}});
  const mailboxes = _mailboxes.reduce((acc, m) => {
    acc.index[m.id] = m;
    if (m.deleted !== true) {
      acc.list.push({
        id: m.id,
        name: m.name
      });
    }
    return acc;
  }, {index: {}, list: []});
  const locationIndex = _locations.reduce((acc, l) => {
    acc[l.id] = l;
    return acc;
  }, {});
  const debtors = _debtors.reduce((acc, d) => {
    acc.index[d.id] = d;
    if (d.deleted !== true) {
      acc.list.push({
        id: d.id,
        name: d.name
      });
    }
    return acc;
  }, {index: {}, list: []});
  const miscInvoiceCategoryIndex = miscInvoiceCategories.reduce((acc, c) => {
    acc[c.id] = c;
    return acc;
  }, {});
  const {
    all: creditors,
    misc: miscCreditors,
    part: partCreditors,
    sublet: subletCreditors
  } = _creditors.reduce((acc, c) => {
    acc.all.index[c.id] = c;
    if (c.canMiscInvoice === true) {
      const o = {
        id: c.id,
        name: c.name,
        miscInvoiceCategories: c.miscInvoiceCategories
          .map(c => miscInvoiceCategoryIndex[c])
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          })
      };
      acc.misc.index[c.id] = o;
      if (c.deleted !== true) {
        acc.misc.list.push(o);
      }
    }
    if (c.deleted !== true) {
      acc.all.list.push({
        id: c.id,
        name: c.name
      });
      if (c.canPart === true) {
        acc.part.index[c.id] = c;
        acc.part.list.push({
          id: c.id,
          name: c.name
        });
      }
      if (c.canSublet === true) {
        acc.sublet.index[c.id] = c;
        acc.sublet.list.push({
          id: c.id,
          name: c.name
        });
      }
    }
    return acc;
  }, {
    all: {index: {}, list: []},
    misc: {index: {}, list: []},
    part: {index: {}, list: []},
    sublet: {index: {}, list: []}
  });
  const storms = _storms.reduce((acc, s) => {
    acc.index[s.id] = s;
    if (s.deleted !== true) {
      acc.list.push({
        id: s.id,
        name: s.name
      });
    }
    return acc;
  }, {index: {}, list: []});
  const smsTemplates = _smsTemplates.reduce((acc, t) => {
    acc.index[t.id] = t;
    acc.list.push({
      id: t.id,
      name: t.name
    });
    return acc;
  }, {index: {}, list: []});
  let companies = {index: {}, list: {}};
  if(_companies) {
    companies = _companies.reduce((acc, d) => {
      acc.index[d.id] = d;
      if (d.deleted !== true) {
        acc.list.push({
          id: d.id,
          name: d.name
        });
      }
      return acc;
    }, {index: {}, list: []});
  }
  const partsNgramIndex = {};
  partsVehicles.forEach((v, i) => {
    const ngramList = v.model
      .replace(/[^a-z0-9\s]/gi, '')
      .split(' ')
      .filter(s => s.length)
      .reduce((acc, s) => {
        for (let i in s) {
          for (let j = 3; j <= Math.min(8, s.length - i); j++) {
            acc.push(s.substr(i, j));
          }
        }
        return acc;
      }, []);
    ngramList.forEach(ngram => {
      if (!partsNgramIndex.hasOwnProperty(ngram)) {
        partsNgramIndex[ngram] = [];
      }
      partsNgramIndex[ngram].push(i);
    });
  });

  return {
    version,
    selfId,
    generalSettings: {
      ...generalSettings,
      // this gstRate will get used to calculate creditor/technican invoices and job purchasing invoice
      gstRate: generalSettings.jurisdiction === 'NZ' ? 15 : 10,
      publicHolidayIndex: generalSettings.publicHolidays.reduce((acc, day) => {
        let d = day, _states = {ALL: true};
        if (day instanceof Object) {
          ({d, states: _states} = day);
        }
        let states = ['ALL','ACT', 'NSW', 'NT', 'QLD', 'VIC', 'TAS', 'WA'];
        if(_states['ALL'] !== true) {
          states = Object.keys(_states);
        }
        return states.reduce((acc, s) => {
          if(!acc.hasOwnProperty(s)) {
            acc[s] = {};
          }
          acc[s][d] = true;
          return acc;
        }, acc)
      }, {}),
    },
    mailboxes,
    storms,
    locations: generateLocationLists(locationIndex, employeeIndex, selfId),
    creditors,
    miscCreditors,
    partCreditors,
    subletCreditors,
    debtors,
    employees: generateEmployeeLists(employeeIndex),
    smsTemplates,
    partsVehicles,
    partsNgramIndex,
    lightTunnels,
    miscInvoiceCategoryIndex,
    miscInvoiceCategories,
    companies,
  };
}


let _currentState = null;
let tmp = window.localStorage.getItem('params');
if (tmp) {
  tmp = JSON.parse(tmp);
  _currentState = transformParams(tmp);
}


export default (state = _currentState, action) => {
  switch (action.type) {
    case 'PARAMS-SET':
    {
      if (state === null || state.version !== action.params.version) {
        const newState = transformParams(action.params);
        window.localStorage.setItem('params', JSON.stringify(action.params));
        return newState;
      }
      return state;
    }
    case 'PARAMS-RESET':
      window.localStorage.removeItem('params');
      return null;
    case 'PARAMS-UPDATE-GENERAL-SETTINGS':
    {
      return {
        ...state,
        generalSettings: action.data
      };
    }
    case 'PARAMS-UPDATE-EMPLOYEE':
    {
      const {employee: e} = action;
      const avatarColor = getAvatarBg(e.id);
      const index = {
        ...state.employees.index,
        [e.id]: {
          id: e.id,
          name: e.name,
          deleted: e.deleted,
          initials: getInitials(e.name),
          avatarBg: avatarColor.dark,
          avatarBgLight: avatarColor.light,
        }
      };
      return {
        ...state,
        employees: generateEmployeeLists(index)
      };
    }
    case 'PARAMS-UPDATE-LOCATION':
    {
      const {location: l} = action;
      const index = {
        ...state.locations.index,
        [l.id]: l
      };
      return {
        ...state,
        locations: generateLocationLists(index, state.employees.index, state.selfId),
      };
    }
    case 'PARAMS-DELETE-EMPLOYEE':
    {
      const {id} = action;
      const index = {
        ...state.employees.index,
        [id]: {
          ...state.employees.index[id],
          deleted: true
        }
      };
      return {
        ...state,
        employees: generateEmployeeLists(index)
      };
    }
    default:
      return state;
  }
};
