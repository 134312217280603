import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Autocomplete from 'components/Autocomplete.jsx';
import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const PayButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.tr`
  background: ${opts => opts.alert ? colors.redExtraLight : colors.white};
  & td {
    border: 0 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;


export default class CreditorPayments extends React.Component {
  constructor(opts) {
    super(opts);
    const {creditorId, creditor} = opts.creditors.reduce((acc, c) => {
      if (acc.creditorId === c.id) {
        acc.creditor = c.name;
      }
      return acc;
    }, {creditorId: opts.creditorId, creditor: ''});
    this.state = {
      allSelected: false,
      creditorId,
      creditor,
    };
    this.onChangeCreditor = this.onChangeCreditor.bind(this);
    this.onFilterCreditor = this.onFilterCreditor.bind(this);
    this.onPay = this.onPay.bind(this);
    this.onToggleAll = this.onToggleAll.bind(this);
  }
  componentDidMount() {
    const {creditorId, onLoad} = this.props;
    if (creditorId !== null) {
      onLoad(creditorId);
    }
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {creditorId, onLoad} = this.props;
    if (creditorId !== null && creditorId !== prevProps.creditorId) {
      onLoad(creditorId);
    }
  }
  onChangeCreditor(e) {
    if (typeof e === 'string') {
      this.setState({
        creditor: e,
        creditorId: null,
      });
      if (e === '') {
        this.props.onChangeCreditorId(null);
      }
    } else if (e != null && e.id){
      this.setState({
        creditor: e.text,
        creditorId: e.id,
      });
      this.props.onChangeCreditorId(e.id);
    }
  }
  onFilterCreditor(v) {
    const {creditors} = this.props;
    if (v === null) return creditors.map(c => ({id: c.id, text: c.name}));
    const regex = new RegExp(v, 'i');
    const res = creditors
      .reduce((acc, c) => {
        if (regex.test(c.name) === true) {
          acc.push({
            id: c.id,
            text: c.name,
          });
        }
        return acc;
      }, []);
    return res;
  }
  onToggle(index, e) {
    this.props.onToggle(index);
  }
  onToggleAll(e) {
    this.setState({
      allSelected: e.target.checked
    });
    this.props.onToggle(null);
  }
  onPay(t, e) {
    const {creditorId, lines: _lines, onPay} = this.props;
    const lines = _lines.reduce((acc, l) => {
      if (l.payDate === null && l.selected === true) {
        acc.push({
          type: l.type,
          invoiceId: l.invoiceId,
          creditId: l.creditId
        });
      }
      return acc;
    }, []);
    onPay(creditorId, lines)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while paying creditor.');
        } else {
          toast.success('Creditor successfully paid.');
        }
      });
  }
  render() {
    console.log('creditorpayments:render');
    const {notFound} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load creditor payments.</div>
        </ErrorContainer>
      );
    }
    const {lines, selectedCount, totalStr, creditors} = this.props;
    const {
      creditorId,
      creditor
    } = this.state;
    const {allSelected} = this.state;
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h4 className='mb-0'>Creditor payments</h4>
          <Autocomplete
            className='m-0'
            background={colors.grey1}
            uppercaseOnly={true}
            showCaret={true}
            value={creditor}
            onChange={this.onChangeCreditor}
            onFilter={this.onFilterCreditor}
          />
          {false && <Select
            placeholder='select a creditor'
            background={colors.grey1}
            selected={creditorId}
            options={creditors}
            textField='name'
            onChange={this.props.onChangeCreditorId}
          />}
        </div>
        {notFound === false && (
          <Card className='p-3'>
            {lines.length === 0 && (
              <div className='text-center text-secondary'>No outstanding payments found.</div>
            )}
            {lines.length !== 0 && (
              <table className='table mb-0'>
                <thead>
                  <tr>
                    <th className='border-0 py-0'>Job #</th>
                    <th className='border-0 py-0'>Invoice #</th>
                    <th className='border-0 py-0'>Invoice date</th>
                    <th className='border-0 py-0'>Amount</th>
                    <th className='border-0 py-0'>Description</th>
                    <th className='border-0 py-0'>Credit date</th>
                    <th className='border-0 py-0'>Reason</th>
                    <th className='border-0 py-0'>Pay date</th>
                    <th className='border-0 py-0 text-center'>
                      <input
                        type='checkbox'
                        checked={allSelected === true}
                        onChange={this.onToggleAll}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lines.map((l, i) => (
                    <React.Fragment key={i}>
                      <tr>
                        <td className='p-1 border-0' />
                      </tr>
                      <Item alert={l.type === 'credit'}>
                        <td>
                          <Link to={`/job/${l.jobId}/purchasing`}>
                            <strong className='text-body'>{l.jobNumber}</strong>
                          </Link>
                        </td>
                        <td>{l.invoiceNumber}</td>
                        <td>{l.invoiceDateStr}</td>
                        <td>{l.totalStr}</td>
                        <td>{l.type === 'credit' && (<span>{l.qty}x {l.description} {l.partNumber}</span>)}</td>
                        <td>{l.creditDateStr}</td>
                        <td>{l.reason}</td>
                        <td>{l.payDateStr}</td>
                        <td className='text-center'>
                          <input
                            type='checkbox'
                            disabled={l.payDate !== null}
                            checked={l.selected === true}
                            onChange={this.onToggle.bind(this, i)}
                          />
                        </td>
                      </Item>
                    </React.Fragment>
                  ))}
                  <tr>
                    <td className='border-0' colSpan={3} />
                    <td className='border-0 font-weight-bold pb-0 align-middle'>
                      {totalStr}
                    </td>
                    <td className='border-0' colSpan={4} />
                    <td className='border-0 pb-0 text-center align-middle'>
                      <PayButton
                        className='btn btn-primary d-print-none'
                        disabled={selectedCount === 0}
                        onClick={this.onPay}
                      >Pay</PayButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Card>
        )}
      </Container>
    );
  };
};

