import React from 'react';
import {toast} from 'react-toastify';

import colors from 'colors';

import Field from 'components/Field.jsx';
import Modal from 'components/Modal';


export default class UserDetails extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      canSave: false,
      name: '',
    };
    this.onChangeName = this.onChange.bind(this, 'name');
    this.onSave = this.onSave.bind(this);
  }
  onChange(field, e) {
    let newState = {
      [field]: e,
    };
    newState.canSave = (
      newState.name.trim() !== ''
    );
    this.setState(newState);
  }
  onSave() {
    const {onSave} = this.props;
    const {
      name,
    } = this.state;
    onSave({name});
    toast.success('User details updated.');
  }
  render() {
    console.log('userdetails:render');
    const {open} = this.props;
    const {
      canSave,
      name,
    } = this.state;
    return (
      <Modal
        title='Please update your details'
        open={open}
        onClose={null}
      >
        <div className='modal-body'>
          <Field
            title='Name'
            background={colors.grey1}
            uppercaseOnly={true}
            value={name}
            onChange={this.onChangeName}
          />
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onSave}
            disabled={canSave !== true}
          >Save</button>
        </div>
      </Modal>
    );
  }
};
