import React from 'react';
import styled from 'styled-components';

import PartsFinder from './PartsFinder';

import AddPdrRow from './AddPdrRow.jsx';
import PDRRow from './PDRRow.jsx';
import MiscRow from './MiscRow.jsx';
import PaintRow from './PaintRow.jsx';
import PartRow from './PartRow.jsx';
import RepairRow from './RepairRow.jsx';
import RrRow from './RrRow.jsx';
import SubletRow from './SubletRow.jsx';


const Table = styled.table`
  :last-of-type {
    margin-bottom: 0 !important;
  }
`;

export default class QuoteSection extends React.Component {
  constructor(opts) {
    super(opts);
    this.onDelete = this.onDelete.bind(this);
    this.onAddRowAfter = this.onAddRowAfter.bind(this);
  }
  shouldComponentUpdate(nextProps) {
    return (
      this.props.lineItems !== nextProps.lineItems ||
      this.props.defaultRate !== nextProps.defaultRate ||
      this.props.rows !== nextProps.rows
    );
  }
  onDelete(i) {
    const {type, onRemoveRow} = this.props;
    onRemoveRow(type, i);
  }
  onAddRowAfter(i) {
    if (i < this.props.rows.length) {
      const {type, defaultRate, onAddRow} = this.props;
      onAddRow(type, defaultRate, i);
    }
  }
  renderPDR() {
    const {
      hasOrm,
      rows,
      onChange,
      canJobDetailsViewQuotePrices,
      canJobDetailsSave
    } = this.props;
    if (!rows.length) return null;
    return (
      <Table className='w-100 mb-4'>
        <thead>
          <tr>
            <th width={25} />
            <th>PDR</th>
            <th className='text-center' width={80}>Qty</th>
            <th className='text-center' width={80}>Size</th>
            {canJobDetailsViewQuotePrices && (
              <th className='text-center' width={80}>Nett ($)</th>
            )}
            {canJobDetailsSave && (
              <th width={20} />
            )}
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, i) => (
              <PDRRow
                key={i}
                index={i}
                onChange={onChange}
                onDelete={this.onDelete}
                showPrices={canJobDetailsViewQuotePrices}
                showDelete={canJobDetailsSave}
                hasOrm={hasOrm}
                {...row}
              />
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderHourlyHeader(title, canJobDetailsViewQuotePrices, canJobDetailsSave) {
    return (
      <thead>
        <tr>
          <th width={25} />
          <th>{title}</th>
          <th className='text-center' width={80}>Hours</th>
          {canJobDetailsViewQuotePrices && (
            <React.Fragment>
              <th className='text-center' width={80}>Rate ($)</th>
              <th className='text-center' width={80}>Nett ($)</th>
            </React.Fragment>
          )}
          {canJobDetailsSave && (
            <th width={20} />
          )}
        </tr>
      </thead>
    );
  }
  renderPriceHeader(title, canJobDetailsViewQuotePrices, canJobDetailsSave) {
    return (
      <thead>
        <tr>
          <th width={25} />
          <th>{title}</th>
          {canJobDetailsViewQuotePrices && (
            <React.Fragment>
              <th className='text-center' width={80}>Price ($)</th>
              <th className='text-center' width={80}>Nett ($)</th>
            </React.Fragment>
          )}
          {canJobDetailsSave && (
            <th width={20} />
          )}
        </tr>
      </thead>
    );
  }
  renderRrs() {
    const {
      hasOrm,
      lineItems,
      rows,
      onChange,
      canJobDetailsViewQuotePrices,
      canJobDetailsSave,
    } = this.props;
    return (
      <Table className='w-100 mb-4'>
        {this.renderHourlyHeader('R/R', canJobDetailsViewQuotePrices, canJobDetailsSave)}
        <tbody>
          {
            rows.map((row, i) => (
              <RrRow
                key={i}
                index={i}
                isLast={i === rows.length - 1}
                lineItems={lineItems}
                onAddRowAfter={this.onAddRowAfter}
                onChange={onChange}
                onDelete={this.onDelete}
                showPrices={canJobDetailsViewQuotePrices}
                showDelete={canJobDetailsSave}
                hasOrm={hasOrm}
                {...row}
              />
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderRepairs() {
    const {
      hasOrm,
      lineItems,
      rows,
      onChange,
      canJobDetailsViewQuotePrices,
      canJobDetailsSave,
    } = this.props;
    return (
      <Table className='w-100 mb-4'>
        {this.renderHourlyHeader('Repair', canJobDetailsViewQuotePrices, canJobDetailsSave)}
        <tbody>
          {
            rows.map((row, i) => (
              <RepairRow
                key={i}
                index={i}
                isLast={i === rows.length - 1}
                lineItems={lineItems}
                onAddRowAfter={this.onAddRowAfter}
                onChange={onChange}
                onDelete={this.onDelete}
                showPrices={canJobDetailsViewQuotePrices}
                showDelete={canJobDetailsSave}
                hasOrm={hasOrm}
                {...row}
              />
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderPaints() {
    const {
      hasOrm,
      lineItems,
      rows,
      onChange,
      canJobDetailsViewQuotePrices,
      canJobDetailsSave,
    } = this.props;
    return (
      <Table className='w-100 mb-4'>
        {this.renderHourlyHeader('Paint', canJobDetailsViewQuotePrices, canJobDetailsSave)}
        <tbody>
          {
            rows.map((row, i) => (
              <PaintRow
                key={i}
                index={i}
                isLast={i === rows.length - 1}
                lineItems={lineItems}
                onAddRowAfter={this.onAddRowAfter}
                onChange={onChange}
                onDelete={this.onDelete}
                showPrices={canJobDetailsViewQuotePrices}
                showDelete={canJobDetailsSave}
                hasOrm={hasOrm}
                {...row}
              />
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderParts() {
    const {
      hasOrm,
      lineItems,
      rows,
      onChange,
      onPartOutcome,
      canJobDetailsViewQuotePrices,
      canJobDetailsViewQuoteFitParts,
      canJobDetailsSave
    } = this.props;
    return (
      <Table className='w-100 mb-4'>
        <thead>
          <tr>
            <th width={25} />
            <th>
              <span className='mr-3'>Part</span>
              <PartsFinder />
            </th>
            <th className='text-center' width={150}>Part #</th>
            <th className='text-center' width={80}>Bin/ETA</th>
            {canJobDetailsViewQuoteFitParts && (
              <th className='text-center' width={80}>Status</th>
            )}
            <th className='text-center' width={80}>Qty</th>
            {canJobDetailsViewQuotePrices && (
              <React.Fragment>
                <th className='text-center' width={80}>Price ($)</th>
                <th className='text-center' width={80}>Nett ($)</th>
              </React.Fragment>
            )}
            {canJobDetailsSave && (
              <th width={20} />
            )}
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, i) => (
              <PartRow
                key={i}
                index={i}
                isLast={i === rows.length - 1}
                lineItems={lineItems}
                onAddRowAfter={this.onAddRowAfter}
                onChange={onChange}
                onPartOutcome={onPartOutcome}
                onDelete={this.onDelete}
                showFitted={canJobDetailsViewQuoteFitParts}
                showPrices={canJobDetailsViewQuotePrices}
                showDelete={canJobDetailsSave}
                hasOrm={hasOrm}
                {...row}
              />
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderMisc() {
    const {
      hasOrm,
      lineItems,
      rows,
      onChange,
      canJobDetailsViewQuotePrices,
      canJobDetailsSave,
    } = this.props;
    return (
      <Table className='w-100 mb-4'>
        {this.renderPriceHeader('Misc', canJobDetailsViewQuotePrices, canJobDetailsSave)}
        <tbody>
          {
            rows.map((row, i) => (
              <MiscRow
                key={i}
                index={i}
                isLast={i === rows.length - 1}
                lineItems={lineItems}
                onAddRowAfter={this.onAddRowAfter}
                onChange={onChange}
                onDelete={this.onDelete}
                showPrices={canJobDetailsViewQuotePrices}
                showDelete={canJobDetailsSave}
                hasOrm={hasOrm}
                {...row}
              />
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderSublets() {
    const {
      hasOrm,
      lineItems,
      rows,
      onChange,
      canJobDetailsViewQuotePrices,
      canJobDetailsSave,
    } = this.props;
    return (
      <Table className='w-100 mb-4'>
        {this.renderPriceHeader('Sublet', canJobDetailsViewQuotePrices, canJobDetailsSave)}
        <tbody>
          {
            rows.map((row, i) => (
              <SubletRow
                key={i}
                index={i}
                isLast={i === rows.length - 1}
                lineItems={lineItems}
                onAddRowAfter={this.onAddRowAfter}
                onChange={onChange}
                onDelete={this.onDelete}
                showPrices={canJobDetailsViewQuotePrices}
                showDelete={canJobDetailsSave}
                hasOrm={hasOrm}
                {...row}
              />
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderAddPdrs() {
    const {
      hasOrm,
      lineItems,
      rows,
      onChange,
      canJobDetailsViewQuotePrices,
      canJobDetailsViewQuoteAdjPdr,
      canJobDetailsSave
    } = this.props;
    if (!canJobDetailsViewQuoteAdjPdr) return null;
    return (
      <Table className='w-100 mb-4'>
        {this.renderPriceHeader('Adjust PDR', canJobDetailsViewQuotePrices, canJobDetailsSave)}
        <tbody>
          {
            rows.map((row, i) => (
              <AddPdrRow
                key={i}
                index={i}
                isLast={i === rows.length - 1}
                lineItems={lineItems}
                onAddRowAfter={this.onAddRowAfter}
                onChange={onChange}
                onDelete={this.onDelete}
                showPrices={canJobDetailsViewQuotePrices}
                showDelete={canJobDetailsSave}
                hasOrm={hasOrm}
                {...row}
              />
            ))
          }
        </tbody>
      </Table>
    );
  }
  render() {
    console.log('quotesection:render');
    switch (this.props.type) {
      case 'pdr':
        return this.renderPDR();
      case 'misc':
        return this.renderMisc();
      case 'rr':
        return this.renderRrs();
      case 'repair':
        return this.renderRepairs();
      case 'paint':
        return this.renderPaints();
      case 'part':
        return this.renderParts();
      case 'sublet':
        return this.renderSublets();
      case 'add-pdr':
        return this.renderAddPdrs();
      default:
        return null;
    }
  }
};
