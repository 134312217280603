import Promise from 'bluebird';
import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import ImageList from './component.jsx';


const open = index => ({
  type: 'JOB-GALLERY-OPEN',
  index
});

const close = () => ({
  type: 'JOB-GALLERY-CLOSE',
});

const scroll = dir => ({
  type: 'JOB-GALLERY-SCROLL',
  dir
});

const upload = (API, jobId, images) => {
  return dispatch => {
    //dispatch({type: 'LOADING-INC'});
    return Promise.mapSeries(
      images,
      (f, i) => {
        const body = new FormData();
        body.append(`file${i}`, f);
        return API.requestForm(
          `${env.API_SERVER}/v1/internal/job/${jobId}/images`,
          {method: 'PUT', body}
        );
      }
    )
      .then(() => {
        return API.requestForm(
          `${env.API_SERVER}/v1/internal/job/${jobId}/images`,
          {method: 'GET'}
        );
      })
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        return true;
      })
      .finally(() => {
        //dispatch({type: 'LOADING-DEC'});
      });
  }
};

const pin = (API, jobId, imageId) => {
  return dispatch => {
    //dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/image/${imageId}`, {method: 'POST'})
      .then(resp => {
        if (resp && resp.error === true) {
          return Promise.reject(resp);
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        return true;
      })
      .finally(() => {
        //dispatch({type: 'LOADING-DEC'});
      });
  }
};

const del = (API, jobId, imageId) => {
  return dispatch => {
    //dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/image/${imageId}`, {method: 'DELETE'})
      .then(resp => {
        if (resp && resp.error === true) {
          return Promise.reject(resp);
        }
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        return true;
      })
      .finally(() => {
        //dispatch({type: 'LOADING-DEC'});
      });
  }
};


const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    jobGallery: gallery
  } = state;
  const {profile: {abilities: {canJobAddImages}}} = profiles[activeProfile];
  return {
    jobId: props.jobId,
    gallery,
    canJobAddImages,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onUpload: (jobId, images) => dispatch(upload(props.API, jobId, images)),
  onPin: (jobId, imageId) => dispatch(pin(props.API, jobId, imageId)),
  onDelete: (jobId, imageId) => dispatch(del(props.API, jobId, imageId)),
  onOpen: index => dispatch(open(index)),
  onClose: () => dispatch(close()),
  onScroll: dir => dispatch(scroll(dir)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ImageList)
);
