import React from 'react';
import styled from 'styled-components';

import colors from 'colors';


const ItemRow = styled.div`
  :nth-of-type(odd) {
    background: ${colors.grey1};
  }
`;
const ActionColumnTitle = styled.div`
  width: 10%;
  text-align: center;
  margin: 0px 1px;
`;
const ActionColumn = styled.div`
  width: 10%;
`;


class Item extends React.Component {
  constructor(opts) {
    super(opts);
    this.onClickRr = this.onClickRr.bind(this);
    //this.onClickRepair = this.onClickRepair.bind(this);
    this.onClickPaint = this.onClickPaint.bind(this);
    this.onClickPart = this.onClickPart.bind(this);
    this.onClickMisc = this.onClickMisc.bind(this);
  }
  onClickRr(e) {
    const {description, rrTime, hourlyRate, rrId, onToggle, rates} = this.props;
    onToggle({
      rowType: 'rr',
      id: rrId,
      description,
      hours: hourlyRate || rrTime,
      rate: rates.rr
    });
  }
  //onClickRepair(e) {
  //  const {description, repairTime, repairId, onToggle, rates} = this.props;
  //  onToggle({
  //    rowType: 'repair',
  //    id: repairId,
  //    description,
  //    hours: repairTime,
  //    rate: rates.repair
  //  });
  //}
  onClickPaint(e) {
    const {description, paintTime, hourlyRate, isBlend, paintId, onToggle, rates} = this.props;
    onToggle({
      rowType: 'paint',
      id: paintId,
      description,
      hours: paintTime,
      rate: hourlyRate || rates.paint,
      blend: isBlend
    });
  }
  onClickPart(e) {
    const {description, partPrice, partId, replacePanel, onToggle} = this.props;
    onToggle({
      rowType: 'part',
      id: partId,
      replacePanel,
      description,
      qty: 1,
      price: partPrice,
    });
  }
  onClickMisc(e) {
    const {
      canMisc,
      canSublet,
      canRepair,
      description,
      miscPrice,
      miscId,
      subletPrice,
      subletId,
      repairId,
      repairTime,
      hourlyRate,
      onToggle,
      rates,
    } = this.props;
    if (canMisc === true) {
      onToggle({
        rowType: 'misc',
        id: miscId,
        description,
        price: miscPrice,
      });
    } else if (canSublet === true) {
      onToggle({
        rowType: 'sublet',
        id: subletId,
        description,
        price: subletPrice,
      });
    } else if (canRepair === true) {
      onToggle({
        rowType: 'repair',
        id: repairId,
        description,
        hours: repairTime,
        rate: hourlyRate || rates.repair
      });
    }
  }
  render() {
    //if (this.props.deleted === true) return null;
    const {
      description,

      canRr,
      rrId,

      canPaint,
      paintId,

      canRepair,
      repairId,

      canPart,
      partId,

      canMisc,
      miscId,

      canSublet,
      subletId,

      selectedItems
    } = this.props;
    const rrSelected = canRr && selectedItems[rrId] === true;
    const paintSelected = canPaint && selectedItems[paintId] === true;
    const partSelected = canPart && selectedItems[partId] === true;
    const miscSelected =
      (canMisc && selectedItems[miscId] === true) ||
      (canSublet && selectedItems[subletId] === true) ||
      (canRepair && selectedItems[repairId] === true);

    return (
      <ItemRow className='d-flex flex-row px-3 py-2'>
        <div className='flex-grow-1'>{description}</div>
        <ActionColumn className='flex-shrink-0'>
          {canRr && (
            <button
              className={rrSelected ? 'btn btn-primary btn-sm w-100' : 'btn btn-sm w-100'}
              onClick={this.onClickRr}
            >+</button>
          )}
        </ActionColumn>
        <ActionColumn className='flex-shrink-0'>
          {canPaint && (
            <button
              className={paintSelected ? 'btn btn-primary btn-sm w-100' : 'btn btn-sm w-100'}
              onClick={this.onClickPaint}
            >+</button>
          )}
        </ActionColumn>
        <ActionColumn className='flex-shrink-0'>
          {canPart && (
            <button
              className={partSelected ? 'btn btn-primary btn-sm w-100' : 'btn btn-sm w-100'}
              onClick={this.onClickPart}
            >+</button>
          )}
        </ActionColumn>
        <ActionColumn className='flex-shrink-0'>
          {(canRepair || canMisc || canSublet) && (
            <button
              className={miscSelected ? 'btn btn-primary btn-sm w-100' : 'btn btn-sm w-100'}
              onClick={this.onClickMisc}
            >+</button>
          )}
        </ActionColumn>
      </ItemRow>
    );
  }
};


export default class List extends React.Component {
  render() {
    const {items, onToggleItem, selectedItems, rates, bodyPaint} = this.props;
    return (
      <React.Fragment>
        <div className='d-flex flex-row p-3'>
          <div className='flex-grow-1 font-weight-bold'>Description</div>
          <ActionColumnTitle className='font-weight-bold'>R&R</ActionColumnTitle>
          <ActionColumnTitle className='font-weight-bold'>Paint</ActionColumnTitle>
          <ActionColumnTitle className='font-weight-bold'>Part</ActionColumnTitle>
          <ActionColumnTitle className='font-weight-bold'>Misc</ActionColumnTitle>
        </div>
        {items.map((item, i) => (
          <Item
            key={i}
            onToggle={onToggleItem}
            selectedItems={selectedItems}
            rates={rates}
            bodyPaint={bodyPaint}
            {...item}
          />
        ))}
      </React.Fragment>
    );
  }
};
