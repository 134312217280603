import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';
import moment from 'm';

import CalendarInput from 'components/CalendarInput.jsx';
import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


const {
  index: HOURS30_INDEX,
  list: HOURS30_LIST
} = Array.apply(null, Array(25))
  .reduce((acc, _, j) => {
    const hour = (7 + Math.floor(j / 2));
    const minute = ((j % 2) * 30);
    const second = hour * 3600 + minute * 60;
    const hh = ('00' + hour).slice(-2);
    const mm = ('00' + minute).slice(-2);
    acc.index[j] = second;
    acc.list.push({
      id: j,
      text: `${hh}:${mm}`
    });
    return acc;
  }, {index: {}, list: []});

const JobCard = styled.div`
  border-radius: 0.5rem;
  background: ${opts => opts.color};
  border: 1px solid ${opts => opts.modified ? colors.red : opts.color};
`;
//const JobCardFlag = styled.div`
//  border-radius: 0.5rem 0rem 0rem 0.5rem;
//  background: ${opts => opts.color};
//  width: 4px;
//  min-height: 100%;
//`;
const Avatar = styled.div`
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  background: ${opts => opts.bg};
  color: #fff;

  svg {
    stroke: #fff;
  }
`;
const JobImg = styled.img`
  align-self: center;
  border-radius: 0.25rem;
  max-width: 64px;
  max-height: 48px;
  width: auto;
`;


const getDateTime = ts => {
  if (ts === null) {
    return {
      date: null,
      time: null
    };
  } else {
    const tmp = moment(ts * 1000);
    const date = moment(tmp).hour(0).minute(0).second(0).millisecond(0).unix();
    const time = ((tmp.hour() - 7) * 3600 + tmp.minute() * 60) / 60 / 30;
    return {
      date,
      time
    };
  }
};

export default class Detail extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      ...getDateTime(opts.task.readyDate),
      note: opts.task.note,
    };
    this.onChangeDate = this.onChangeTs.bind(this, 'date');
    this.onChangeTime = this.onChangeTs.bind(this, 'time');
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onBlurNote = this.onBlurNote.bind(this);
    this.onToggleDone = this.onToggleDone.bind(this);
  }
  componentDidUpdate(prevProps) {
    let tmp = this.state;
    if (prevProps.task.note !== this.props.task.note) {
      tmp = {
        ...tmp,
        note: this.props.task.note,
      };
    }
    if (prevProps.task.readyDate !== this.props.task.readyDate) {
      tmp = {
        ...tmp,
        ...getDateTime(this.props.task.readyDate)
      };
    }
    if (tmp !== this.state) {
      this.setState(tmp);
    }
  }
  onChangeTs(field, e) {
    const {task: {jobId}, onChangeDateTime} = this.props;
    const tmp = {
      ...this.state,
      [field]: e
    };
    if (tmp.date !== null && tmp.time !== null) {
      onChangeDateTime({jobId, field: 'readyDate', ts: tmp.date + HOURS30_INDEX[tmp.time]});
    } else if (tmp.date === null && field === 'date') {
      tmp.date = null;
      tmp.time = null;
      onChangeDateTime({jobId, field: 'readyDate', ts: null});
    }
    this.setState(tmp);
  }
  onChangeNote(e) {
    this.setState({
      note: e
    });
  }
  onBlurNote() {
    const {task: {jobId}, onChangeNote} = this.props;
    onChangeNote({jobId, note: this.state.note});
  }
  onToggleDone(e) {
    const {task: {locationId, jobId, task}, onToggleDone} = this.props;
    onToggleDone({locationId, jobId, task, done: e.target.checked});
  }
  render() {
    const {employees, task} = this.props;
    const {date, time, note} = this.state;
    return (
      <div className='col-sm-12 col-md-6 col-lg-4 mt-2'>
        <JobCard
          className='d-flex flex-row mx-1 p-2 h-100'
          color={colors[task.alertColor || task.flag]}
        >
          <div className='d-flex flex-row w-100'>
            <div className='d-flex flex-column justify-content-between flex-grow-1 mr-1'>
              <div className='row no-gutters'>
                <div className='col-4'>
                  <NavLink
                    to={`/job/${task.jobId}`}
                    className='text-body font-weight-bold'
                  >
                    {task.jobNumber}
                  </NavLink>
                </div>
                <div className='col-4 text-center'>{task.rego}</div>
                <div className='col-4 text-right'>{task.startDateStr} | {task.finishDateStr}</div>
              </div>
              <div
                className='row no-gutters align-items-center mt-1'
              >
                <div className='col-4 text-truncate text-uppercase'>{task.makeModel}</div>
                <div className='col-4 text-truncate text-center'>{task.pickupDateStr}</div>
                <div className='col-4 d-flex flex-row justify-content-between align-items-center'>
                  {task.striper && (
                    <Avatar
                      className='rounded-circle'
                      bg={employees[task.striper].avatarBg}
                    >
                      <span title={employees[task.striper].name}>
                        {employees[task.striper].initials}
                      </span>
                    </Avatar>
                  )}
                  <CalendarInput
                    className='mx-1'
                    selected={date}
                    background={colors.grey2}
                    showClear={true}
                    onChange={this.onChangeDate}
                  />
                  <Select
                    background={colors.grey2}
                    selected={time}
                    options={HOURS30_LIST}
                    onChange={this.onChangeTime}
                  />
                </div>
              </div>
              <Field
                className='mb-0 mt-1'
                background={colors.grey2}
                value={note}
                uppercaseOnly={true}
                onChange={this.onChangeNote}
                onBlur={this.onBlurNote}
              />
            </div>
            <div className='d-flex flex-column justify-content-begin align-items-center ml-1'>
              {task.image64 && <JobImg src={task.image64} className='d-none d-md-block' />}
              <input
                type='checkbox'
                className='mt-4'
                onChange={this.onToggleDone}
                checked={task.done === true}
              />
            </div>
          </div>
        </JobCard>
      </div>
    );
  }
};
