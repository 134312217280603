import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';

import CalendarInput from 'components/CalendarInput.jsx';
import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;

export default class ReportsStormCategory extends React.Component {
  constructor(opts) {
    super(opts);
    this.onChangeStormId = this.onChange.bind(this, 'stormId');
    this.onChangeStart = this.onChange.bind(this, 'start');
    this.onChangeFinish = this.onChange.bind(this, 'finish');
    this.onSearch = this.onSearch.bind(this);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onChange(field, value) {
    this.props.onChange({
      [field]: value
    });
  }
  onSearch() {
    const {stormId, start, finish, onLoad} = this.props;
    onLoad(stormId, start, finish);
  }
  render() {
    console.log('stormcategory:render');
    const {
      notFound,
      storms,
      stormId,
      start,
      finish,
      report,
    } = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load report.</div>
        </ErrorContainer>
      );
    }
    return (
      <React.Fragment>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Storm</label>
          <div className='col-sm-4'>
            <Select
              background={colors.grey1}
              placeholder='select a storm'
              selected={stormId}
              options={storms}
              textField='name'
              onChange={this.onChangeStormId}
            />
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Date range</label>
          <div className='col-sm-4'>
            <div className='d-flex flex-row align-items-center'>
              <div className='mr-2'>
                <CalendarInput
                  width='85px'
                  background={colors.grey1}
                  selected={start}
                  onChange={this.onChangeStart}
                />
              </div>
              <Icons.ArrowRight color={colors.grey4} width={18} height={18} />
              <div className='ml-2'>
                <CalendarInput
                  align='right'
                  width='85px'
                  background={colors.grey1}
                  selected={finish}
                  onChange={this.onChangeFinish}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4 offset-sm-2'>
            <SearchButton
              type='button'
              className='btn btn-primary'
              disabled={stormId === null}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3 mt-4'>
            {report === null && (
              <div className='text-center text-secondary'>Report not found.</div>
            )}
            {report !== null && (
              <table className='table p-0 mb-0'>
                <thead>
                  <tr>
                    <th className='border-0 py-1 px-0'>Job #</th>
                    <th className='border-0 py-1 px-0'>Start/finish</th>
                    <th className='border-0 py-1 px-0'>Category</th>
                    <th className='border-0 py-1 px-0'>Adjustment</th>
                  </tr>
                </thead>
                <tbody>
                  {report.map((r, i) => (
                    <tr key={i}>
                      <td className='border-0 py-1 px-0'>
                        <NavLink
                          to={`/job/${r.id}`}
                          className='text-body font-weight-bold'
                        >{r.jobNumber}</NavLink>
                      </td>
                      <td className='border-0 py-1 px-0'>{r.startDateStr} - {r.finishDateStr}</td>
                      <td className='border-0 py-1 px-0'>{r.category}</td>
                      <td className='border-0 py-1 px-0'>{r.categoryAdjustmentStr}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Card>
        )}
      </React.Fragment>
    );
  };
};

