const OUT = 1.51;


module.exports = {
  panelCode: 'A',
  lineItems: [
    {
      description: 'LOWER TAILGATE',
      panel: 'LOWER-TAILGATE',
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE PART D/A',
      subsetOf: ['TAILGATE D/A'],
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.46,
                rrAutoPaintable: true,
              }
            },
          },
          },
        },
        'TURRET|LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.46,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'TAILGATE D/A',
      supersetOf: ['TAILGATE PART D/A'],
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 1.28,
                rrAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'INNER TAILGATE TRIMS NESS',
      panel: 'LOWER-TAILGATE',
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.5,
                rrAutoReplaceable: true,
                rrAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LOWER TAILGATE OUT',
      panel: 'LOWER-TAILGATE',
      supersetOf: ['LOWER TAILGATE BLEND'],
      subsetOf: ['LOWER TAILGATE IN & OUT'],
      settings: {
        'LOWER-TAILGATE': {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.4,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
              }
            },
          },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2.4,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: 'LOWER TAILGATE IN & OUT',
      panel: 'LOWER-TAILGATE',
      supersetOf: ['LOWER TAILGATE OUT', 'LOWER TAILGATE BLEND'],
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.4,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.30,
                paintAutoReplaceable: true,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'LOWER TAILGATE BLEND',
      panel: 'LOWER-TAILGATE',
      subsetOf: ['LOWER TAILGATE IN & OUT', 'LOWER TAILGATE OUT'],
      settings: {
        'LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^panel$': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 2.4,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '^M[1-3]$': { /* paint type */
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LOWER TAILGATE LOADING 10%',
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'LOWER TAILGATE LOADING 25%',
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            },
          },
          },
        }
      }
    },
    {
      description: 'LOWER TAILGATE SEALERS NESS',
      panel: 'LOWER-TAILGATE',
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'HATCH|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 25,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'PDR LOWER TAILGATE',
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH LOWER TAILGATE',
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BRUSH TOUCH LOWER TAILGATE',
      settings: {
        'LOWER-TAILGATE': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
};
