import React from 'react';
import {toast} from 'react-toastify';

import colors from 'colors';

import Autocomplete from 'components/Autocomplete.jsx';
import CalendarInput from 'components/CalendarInput.jsx';
import Field from 'components/Field.jsx';
import Modal from 'components/Modal';
import Select from 'components/Select.jsx';


const PAYMENT_METHODS = [
  'cash',
  'cheque',
  'direct deposit',
  'eftpos',
  'waived',
];

export default class AddMiscInvoice extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      canSave: false,
      locationId: null,
      creditorId: null,
      creditor: '',
      categoryId: null,
      description: '',
      invoiceNumber: '',
      invoiceDate: null,
      amount: '',
      payDate: null,
      paymentMethod: null,
      paymentReference: '',
    };
    this.onChangeLocation = this.onChange.bind(this, 'locationId');
    this.onFilterCreditor = this.onFilterCreditor.bind(this);
    this.onChangeCreditor = this.onChange.bind(this, 'creditor');
    this.onChangeCategoryId = this.onChange.bind(this, 'categoryId');
    this.onChangeDescription = this.onChange.bind(this, 'description');
    this.onChangeInvoiceNumber = this.onChange.bind(this, 'invoiceNumber');
    this.onChangeInvoiceDate = this.onChange.bind(this, 'invoiceDate');
    this.onChangeAmount = this.onChange.bind(this, 'amount');
    this.onChangePaymentDate = this.onChange.bind(this, 'payDate');
    this.onChangePaymentMethod = this.onChange.bind(this, 'paymentMethod');
    this.onChangePaymentReference = this.onChange.bind(this, 'paymentReference');
    this.onAdd = this.onAdd.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        canSave: false,
        locationId: null,
        creditorId: null,
        creditor: '',
        categoryId: null,
        description: '',
        invoiceNumber: '',
        invoiceDate: null,
        amount: '',
        payDate: null,
        paymentMethod: null,
        paymentReference: '',
      });
    }
  }
  onChange(field, e) {
    let newState = null;
    if (field === 'creditor') {
      if (typeof e === 'string') {
        newState = {
          ...this.state,
          creditor: e,
          creditorId: null,
        };
      } else {
        newState = {
          ...this.state,
          creditor: e.text,
          creditorId: e.id,
        };
      }
    } else if (field === 'amount') {
      if (/^-?[0-9]*\.?([0-9]{0,2})?$/.test(e) === true || e === '') {
        newState = {
          ...this.state,
          [field]: e,
        };
      }
    } else {
      newState = {
        ...this.state,
        [field]: e,
      };
    }
    if (newState !== null) {
      newState.canSave = (
        newState.locationId !== null &&
        newState.creditorId !== null &&
        newState.categoryId !== null &&
        newState.description.trim() !== '' &&
        newState.invoiceNumber.trim() !== '' &&
        newState.invoiceDate !== null &&
        newState.amount.trim() !== '' && (
          (
            newState.payDate === null &&
            newState.paymentMethod === null &&
            newState.paymentReference === ''
          ) ||
          (
            newState.payDate !== null &&
            newState.paymentMethod !== null
          )
        )
      );
      this.setState(newState);
    }
  }
  onFilterCreditor(v) {
    const {creditors} = this.props;
    const regex = new RegExp(v, 'i');
    const res = creditors
      .reduce((acc, c) => {
        if (regex.test(c.name) === true) {
          acc.push({
            id: c.id,
            text: c.name,
          });
        }
        return acc;
      }, []);
    return res;
  }
  onAdd() {
    const {onAdd, onClose} = this.props;
    const {
      locationId,
      creditorId,
      categoryId,
      description,
      invoiceDate,
      invoiceNumber,
      amount,
      payDate,
      paymentMethod,
      paymentReference,
    } = this.state;
    onAdd(
      locationId,
      creditorId,
      categoryId,
      description.trim(),
      invoiceDate,
      invoiceNumber.trim(),
      amount,
      payDate,
      paymentMethod,
      paymentReference === '' ? null : paymentReference,
    )
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while adding invoice.');
        } else {
          toast.success('Invoice successfully added.');
          onClose();
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while adding invoice.');
      });
  }
  render() {
    //console.log('addmiscinvoice:render');
    const {open, locations, creditorIndex, onClose} = this.props;
    const {
      canSave,
      locationId,
      creditorId,
      creditor,
      categoryId,
      description,
      invoiceNumber,
      invoiceDate,
      amount,
      payDate,
      paymentMethod,
      paymentReference,
    } = this.state;
    return (
      <Modal
        title='Add miscellaneous invoice'
        open={open}
        onClose={onClose}
      >
        <div className='modal-body'>
          <Select
            title='Location'
            background={colors.grey1}
            selected={locationId}
            options={locations}
            textField='name'
            onChange={this.onChangeLocation}
          />
          <Autocomplete
            className='m-0 mb-2'
            background={colors.grey1}
            uppercaseOnly={true}
            value={creditor}
            onChange={this.onChangeCreditor}
            onFilter={this.onFilterCreditor}
          >
            <small className='text-muted'>Creditor</small>
          </Autocomplete>
          <Select
            title='Category'
            background={colors.grey1}
            disabled={creditorId === null}
            selected={categoryId}
            options={creditorId ? creditorIndex[creditorId].miscInvoiceCategories : []}
            textField='name'
            onChange={this.onChangeCategoryId}
          />
          <div>
            <Field
              title='Description'
              background={colors.grey1}
              uppercaseOnly={true}
              value={description}
              onChange={this.onChangeDescription}
            />
          </div>
          <div className='row'>
            <div className='col'>
              <CalendarInput
                title='Invoice date'
                background={colors.grey1}
                selected={invoiceDate}
                onChange={this.onChangeInvoiceDate}
              />
            </div>
            <div className='col'>
              <Field
                title='Invoice #'
                value={invoiceNumber}
                background={colors.grey1}
                uppercaseOnly={true}
                onChange={this.onChangeInvoiceNumber}
              />
            </div>
            <div className='col'>
              <Field
                title='Total (inc GST)'
                value={amount}
                background={colors.grey1}
                uppercaseOnly={true}
                onChange={this.onChangeAmount}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <CalendarInput
                title='Pay date'
                showClear={true}
                background={colors.grey1}
                selected={payDate}
                onChange={this.onChangePaymentDate}
              />
            </div>
            <div className='col'>
              <Select
                title='Payment method'
                background={colors.grey1}
                selected={paymentMethod}
                options={PAYMENT_METHODS}
                onChange={this.onChangePaymentMethod}
              />
            </div>
            <div className='col'>
              <Field
                title='Ref'
                value={paymentReference}
                background={colors.grey1}
                uppercaseOnly={true}
                onChange={this.onChangePaymentReference}
              />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={onClose}
          >Close</button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.onAdd}
            disabled={canSave !== true}
          >Save</button>
        </div>
      </Modal>
    );
  }
};
