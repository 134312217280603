import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import Modal from 'components/Modal';


const ModalBody = styled.div`
  & .form-group:last-of-type {
    margin-bottom: 0px;
  }
`;
const Input = styled.input`
  text-transform: uppercase;
`;

export default class Receive extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      orderId: null,
      lines: [],
      canReceive: false,
    };
    this.onSave = this.onSave.bind(this);
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lines !== prevProps.lines || this.state.open !== prevState.open) {
      const {lines, orders, canReceive} = Object.values(this.props.lines)
        .reduce((acc, l) => {
          if (l) {
            if (acc.orderIndex[l.orderId] !== true) {
              acc.orderIndex[l.orderId] = true;
              acc.orders.push(l.orderId);
            }
            acc.lines.push({
              id: l.invoiceLineId,
              qty: l.qtySupplied,
              description: l.description,
              partNumber: l.partNumber,
              location: l.location,
            });
            if (l.canReceive === false) {
              acc.canReceive = false;
            }
          }
          return acc;
        }, {lines: [], orders: [], orderIndex: {}, canReceive: true});
      if (lines.length >= 1 && orders.length === 1) {
        this.setState({
          ...this.state,
          orderId: orders[0],
          lines,
          canReceive
        });
      } else {
        this.setState({
          ...this.state,
          orderId: null,
          lines: [],
          canReceive: false
        });
      }
    }
  }

  onToggleModal(open) {
    this.setState({
      open,
    });
  }

  onChangeLocation(idx, e) {
    this.setState({
      lines: this.state.lines.map((l, i) => {
        if (i !== idx) return l;
        return {
          ...l,
          location: e.target.value.slice(0, 3).toUpperCase()
        }
      })
    });
  }

  onSave() {
    const {jobId, onSave} = this.props;
    const {orderId, lines} = this.state;
    onSave(jobId, orderId, {
      action: 'receive',
      lines: lines.map(l => ({id: l.id, location: l.location}))
    })
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while receiving parts.');
        } else {
          toast.success('Parts successfully received.');
          this.setState({
            open: false,
            selectedSuppliers: {}
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while receiving parts.');
      });
  }

  render () {
    console.log('receive:render');
    const {open, lines, canReceive} = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-primary ml-2'
          onClick={this.onOpen}
          disabled={canReceive !== true}
        >Receive</button>
        <Modal
          title='Are you sure you wish to receive the following parts?'
          wide={true}
          open={open}
          onClose={this.onClose}
        >
          <ModalBody className='modal-body'>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th className='border-top-0'>Qty</th>
                  <th className='border-top-0'>Description</th>
                  <th className='border-top-0'>Part #</th>
                  <th className='border-top-0 text-center' width='100'>Bin</th>
                </tr>
              </thead>
              <tbody>
                {lines.map((l, i) => (
                  <tr key={i}>
                    <td className='align-middle'>{l.qty}x</td>
                    <td className='align-middle'>{l.description}</td>
                    <td className='align-middle'>{l.partNumber}</td>
                    <td>
                      <Input
                        type='text'
                        className='form-control mb-0'
                        value={l.location}
                        onChange={this.onChangeLocation.bind(this, i)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalBody>
          <div className='modal-footer bt-0'>
            <button type='button' className='btn btn-secondary' onClick={this.onClose}>Close</button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={this.onSave}
            >Save</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

