import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import Modal from 'components/Modal';
import Field from 'components/Field.jsx';
import CalendarInput from 'components/CalendarInput.jsx';
import Select from 'components/Select.jsx';


const ModalBody = styled.div`
  & .form-group:last-of-type {
    margin-bottom: 0px;
  }
`;

export default class InvoiceSublet extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      jobLineId: null,
      description: '',
      creditorId: null,
      invoiceNumber: '',
      invoiceDate: null,
      canInvoice: false,
      canSave: false
    };
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout !== null) clearTimeout(this.timeout);
    this.timeout = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lines !== prevProps.lines || this.state.open !== prevState.open) {
      const _lines = Object.values(this.props.lines).filter(l => l !== undefined);
      if (_lines.length === 1) {
        const [{
          id: jobLineId,
          description,
          creditorId,
          invoiceNumber,
          invoiceDate,
          costPrice,
          canEdit: canInvoice,
        }] = _lines;
        this.setState({
          jobLineId,
          description,
          creditorId,
          invoiceNumber,
          invoiceDate,
          costPrice,
          canInvoice,
        });
      } else {
        this.setState({
          jobLineId: null,
          description: '',
          creditorId: null,
          invoiceNumber: '',
          invoiceDate: null,
          canInvoice: false,
          canSave: false,
        });
      }
    }
  }

  onToggleModal(open) {
    if (open === true) {
      this.setState({
        open,
        jobLineId: null,
        description: '',
        creditorId: null,
        invoiceNumber: '',
        invoiceDate: null,
        canSave: false
      });
    } else {
      this.setState({
        open,
      });
    }
  }

  _getCanSave(state) {
    return (
      state.invoiceNumber !== '' &&
      state.invoiceDate !== null &&
      state.costPrice !== ''
    );
  }

  onChange(field, v) {
    const newState = {
      ...this.state,
      [field]: v
    };
    newState.canSave = this._getCanSave(newState);
    this.setState(newState);
  }

  //onChangeCost(field, v) {
  //  if (/^[0-9]*\.?([0-9]{0,2})?$/.test(v) === true || v === '') {
  //    this.setState({
  //      costPrice: v,
  //    });
  //    if (this.timeout !== null) clearTimeout(this.timeout);
  //    this.timeout = setTimeout(() => {
  //      this.timeout = null;
  //      const canSave = this._getCanSave(this.state);
  //      this.setState({
  //        canSave
  //      });
  //    }, 300);
  //  }
  //}

  onSave() {
    const {jobId, onSave} = this.props;
    const {jobLineId, creditorId, invoiceNumber, invoiceDate, costPrice} = this.state;
    onSave(
      jobId,
      {
        action: 'invoice',
        jobLineId,
        creditorId,
        invoiceNumber,
        invoiceDate,
        costPrice,
      }
    )
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while invoicing sublet.');
        } else {
          toast.success('Sublet successfully invoiced.');
          this.setState({
            open: false,
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while invoicing sublet.');
      });
  }

  render () {
    console.log('invoicesublet:render');
    const {creditors} = this.props;
    const {
      open,
      description,
      creditorId,
      invoiceNumber,
      invoiceDate,
      costPrice,
      canInvoice,
      canSave
    } = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-primary ml-2'
          onClick={this.onOpen}
          disabled={canInvoice !== true}
        >Invoice</button>
        <Modal
          title='Invoice'
          open={open}
          wide={true}
          onClose={this.onClose}
        >
          <ModalBody className='modal-body'>
            <div className='row no-gutters mb-2'>
              <div className='col mr-1 pt-4'>{description}</div>
              <div className='col-2 mx-1'>
                <Select
                  title='Creditor'
                  background={colors.grey1}
                  selected={creditorId}
                  options={creditors}
                  textField='name'
                  onChange={this.onChange.bind(this, 'creditorId')}
                />
              </div>
              <div className='col-2 mx-1'>
                <Field
                  title='Invoice #'
                  value={invoiceNumber}
                  background={colors.grey1}
                  uppercaseOnly={true}
                  onChange={this.onChange.bind(this, 'invoiceNumber')} />
              </div>
              <div className='col-2 mx-1'>
                <CalendarInput
                  title='Invoice date'
                  background={colors.grey1}
                  selected={invoiceDate}
                  onChange={this.onChange.bind(this, 'invoiceDate')}
                />
              </div>
              <div className='col-2 ml-1'>
                <Field
                  title='Amount (ex GST)'
                  value={costPrice}
                  background={colors.grey1}
                  uppercaseOnly={true}
                  onChange={this.onChange.bind(this, 'costPrice')} />
              </div>
            </div>
          </ModalBody>
          <div className='modal-footer bt-0'>
            <button type='button' className='btn btn-secondary' onClick={this.onClose}>Close</button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={this.onSave.bind(this)}
              disabled={canSave !== true}
            >Save</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

