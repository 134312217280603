import {connect} from 'react-redux';

import Jobsheets from './component.jsx';


const mapStateToProps = (state, props) => {
  const {
    locationId,
    params: {
      employees: {index: employeeIndex},
      locations: {index: locationIndex, abilityIndex},
    },
    production: {
      notFound,
      jobs,
      employeeJobs,
      filteredEmployee,
    }
  } = state;
  let employeeList = [];
  if (locationId !== null) {
    employeeList = locationIndex[locationId].productionEmployees;
  }
  return {
    notFound,
    jobs,
    locationId,
    abilityIndex,
    employeeIndex,
    employeeList,
    employeeJobs,
    filteredEmployee,
  };
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Jobsheets);
