import React from 'react';
import {connect} from 'react-redux';

import Select from 'components/Select.jsx';


class Storms extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.storms !== this.props.storms ||
      nextProps.selected !== this.props.selected ||
      nextProps.error !== this.props.error
    );
  }
  render() {
    console.log('storms:render');
    const {storms, selected, error, onChange} = this.props;
    return (
      <Select
        className='mb-2'
        title='Storm'
        placeholder='select a storm'
        options={storms}
        textField='name'
        selected={selected}
        error={error}
        onChange={onChange}
      />
    );
  }
};


export default connect(
  (state, props) => {
    let storms = [];
    if (state.params && state.params.hasOwnProperty('storms')) {
      storms = state.params.storms.list;
    }
    return {storms};
  },
  null
)(Storms);
