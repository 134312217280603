import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';


const Input = styled.div`
  > input {
    width: ${opts => opts.width};
    box-shadow: none;
    color: ${opts => opts.disabled ? colors.grey4 : (opts.color || 'inherit')};
    background-color: ${opts => opts.background || '#fff'} !important;
    border-radius: 0.5rem;
    cursor: pointer;
    border-color: ${opts => opts.error ? colors.red : '#fff'};
  }
`;
const Caret = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  font-size: .63rem;
  line-height: 2.5rem;
  height: 2.5rem;
  & svg {
    stroke: ${opts => opts.disabled ? colors.grey4 : (opts.color || colors.body)};
  }
`;
const Options = styled.ul`
  position: absolute;
  ${opts => opts.align === 'right' && 'right: 0px;'}
  ${opts => opts.align !== 'right' && 'left: 0px;'}
  opacity: 1;
  display: block;
  min-width: 100%;
  cursor: pointer;

  list-style-type: none;
  padding-left: 0;

  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  background-color: #fff;
  margin: 0px;
  max-height: 250px;
  overflow-y: auto;
  z-index: 999;
  white-space: nowrap;
`;
const Option = styled.li`
  color: ${opts => opts.selected || opts.hovering ? '#fff !important' : 'inherit'};
  background-color: ${opts => opts.hovering ? colors.grey4 : (opts.selected ? colors.blue : 'inherit')};
`;

export default class Select extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      hoverIndex: -1,
      options: opts.options.map(i => {
        if (typeof i === 'string') {
          return {
            id: i,
            text: i
          };
        }
        return {
          id: i.id || (opts.textField ? i[opts.textField] : i.text),
          text: opts.textField ? i[opts.textField] : i.text
        };
      }),
      optionsIndex: opts.options.reduce((acc, i, j) => {
        if (typeof i === 'string') {
          acc[i] = {
            index: j,
            text: i
          };
        } else {
          acc[i.id || (this.props.textField ? i[this.props.textField] : i.text)] = {
            index: j,
            text: opts.textField ? i[opts.textField] : i.text
          };
        }
        return acc;
      }, {})
    };
    this.container = React.createRef();
    this.onClick = this.onClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }
  componentDidMount() {
    document.addEventListener('click', this.onClick);
    this.container.current.addEventListener('keydown', this.onKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onClick);
    this.container.current.removeEventListener('keydown', this.onKeyDown);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({
        options: this.props.options.map(i => {
          if (typeof i === 'string') {
            return {
              id: i,
              text: i
            };
          }
          return {
            id: i.id || (this.props.textField ? i[this.props.textField] : i.text),
            text: this.props.textField ? i[this.props.textField] : i.text
          };
        }),
        optionsIndex: this.props.options.reduce((acc, i, j) => {
          if (typeof i === 'string') {
            acc[i] = {
              index: j,
              text: i
            };
          } else {
            acc[i.id || (this.props.textField ? i[this.props.textField] : i.text)] = {
              index: j,
              text: this.props.textField ? i[this.props.textField] : i.text
            };
          }
          return acc;
        }, {})
      });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.open !== this.state.open ||
      nextState.options !== this.state.options ||
      nextState.hoverIndex !== this.state.hoverIndex ||
      nextProps.options !== this.props.options ||
      nextProps.selected !== this.props.selected ||
      nextProps.error !== this.props.error
    );
  }
  onClick(e) {
    if (
      this.container &&
      this.state.open === true
    ) {
      if (!this.container.current.contains(e.target)) {
        this.setState({
          ...this.state,
          open: false
        });
      }
    }
  }
  onKeyDown(e) {
    e.stopPropagation();
    const {open, hoverIndex, options} = this.state;
    switch (e.key) {
      case 'ArrowUp':
        {
          if (!open) return this.onToggle(e);
          e.preventDefault();
          this.setState({
            hoverIndex: Math.max(-1, Math.min(options.length -1, hoverIndex - 1))
          });
          break;
        }
      case 'ArrowDown':
        {
          if (!open) return this.onToggle(e);
          e.preventDefault();
          this.setState({
            hoverIndex: Math.max(-1, Math.min(options.length -1, hoverIndex + 1))
          });
          break;
        }
      case 'Enter':
        {
          if (!open) return;
          e.preventDefault();
          if (hoverIndex >= 0) {
            this.onSelect(options[hoverIndex].id, e);
          } else {
            this.onSelect(null, e);
          }
          this.onToggle(e);
          break;
        }
      case 'Tab':
        {
          if (!open) return;
          this.onToggle(e);
          break;
        }
      default:
        break;
    }
  }
  onMouseOver(hoverIndex) {
    this.setState({
      ...this.state,
      hoverIndex
    });
  }
  onToggle(e) {
    e.preventDefault();
    const {open, optionsIndex} = this.state;
    const {selected} = this.props;
    if (open === true) {
      this.setState({
        ...this.state,
        hoverIndex: -1,
        open: false
      });
    } else {
      let hoverIndex = optionsIndex[selected];
      if (hoverIndex) {
        hoverIndex = hoverIndex.index;
      } else {
        hoverIndex = -1;
      }
      this.setState({
        ...this.state,
        hoverIndex,
        open: true
      });
    }
  }
  onSelect(value) {
    this.props.onChange(value);
  }
  render() {
    //console.log('select:render');
    const {className, align, color, background, title, width, placeholder, selected, disabled, error} = this.props;
    const {open, hoverIndex, options, optionsIndex} = this.state;
    let value = optionsIndex[selected];
    if (value) {
      value = value.text;
    } else {
      value = '';
    }
    return (
      <div ref={this.container} className={className}>
        <small className='text-muted'>{title}</small>
        <Input
          className='position-relative m-0'
          width={width}
          color={color}
          background={background}
          error={error}
          disabled={disabled}
          onClick={this.onToggle}
        >
          <Caret
            color={color}
            disabled={disabled}
            onClick={this.onToggle.bind(this)}
          ><Icons.ChevronDown /></Caret>
          <input
            type='text'
            className='form-control p-0 px-1'
            readOnly={true}
            value={value}
            placeholder={placeholder}
          />
          <Options className={open !== true && 'd-none'} align={align}>
            {(placeholder || placeholder === '') &&
              <Option
                key='empty'
                className='text-muted px-2 py-1'
                selected={selected === null}
                hovering={hoverIndex === -1}
                onClick={this.onSelect.bind(this, null)}
                onMouseOver={this.onMouseOver.bind(this, -1)}
              >{placeholder || '\u00A0'}</Option>
            }
            {
              options.map((o, i) => (
                <Option
                  key={i}
                  className='text-primary px-2 py-1'
                  selected={selected === o.id}
                  hovering={hoverIndex === i}
                  onClick={this.onSelect.bind(this, o.id)}
                  onMouseOver={this.onMouseOver.bind(this, i)}
                >{o.text}</Option>
              ))
            }
          </Options>
        </Input>
      </div>
    );
  }
};
