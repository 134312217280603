const _RR_FACTORS = require('./rr-factors.js');
const {default: cabPillar} = require('./cab-pillar.js');
const {default: cantrail} = require('./cantrail.js');
const {default: fdoor} = require('./f-door.js');
const {default: rdoor} = require('./r-door.js');
const {default: sdoor} = require('./s-door.js');
const {default: guard} = require('./guard.js');
const {default: qtr} = require('./qtr.js');

const PANELS = {
  'FRONT-BUMPER': require('./front-bumper.js'),
  'BONNET': require('./bonnet.js'),
  'BOOT': require('./boot.js'),
  'PLENUM': require('./plenum.js'),
  'TURRET': require('./turret.js'),
  'LHF-GUARD': guard('LH'),
  'RHF-GUARD': guard('RH'),
  'LHF-DOOR': fdoor('LH'),
  'RHF-DOOR': fdoor('RH'),
  'LHR-DOOR': rdoor('LH'),
  'RHR-DOOR': rdoor('RH'),
  'LHR-QTR': qtr('LH'),
  'RHR-QTR': qtr('RH'),
  'LH-CANTRAIL': cantrail('LH'),
  'RH-CANTRAIL': cantrail('RH'),
  'LH-CAB-PILLAR': cabPillar('LH'),
  'RH-CAB-PILLAR': cabPillar('RH'),
  'LH-SLIDE-DOOR': sdoor('LH'),
  'RH-SLIDE-DOOR': sdoor('RH'),
  'TAILGATE': require('./tailgate.js'),
  'UPPER-TAILGATE': require('./upper-tailgate.js'),
  'LOWER-TAILGATE': require('./lower-tailgate.js'),
  'REAR-BUMPER': require('./rear-bumper.js'),
  'ADAS': require('./adas.js'),
  'NSI': require('./nsi.js'),
};


export let RR_FACTORS = _RR_FACTORS;
export let PAINT_MATERIAL_FACTORS = {
  S1: 27.10,
  S2: 30.20,
  M1: 32.80,
  M2: 37.70,
  M3: 43.00
};
export let PANEL_MATERIAL_FACTOR = 1;
export let PANEL_SIZE_FACTORS = Object.keys(PANELS).reduce((acc, p) => {
  const _size_factors_ = {
    'A': 1,
    'B': 1.05,
    'C': 1.1,
    'D': 1.2
  };
  if (PANELS[p].panelCode) {
    acc[p] = _size_factors_[PANELS[p].panelCode];
  }
  return acc;
}, {});
export let DENT_SIZE_FACTORS = {
  '0': 0,
  '1': 1,
  '2': 1.18,
  '3': 1.5,
  '4': 1.8
};
export let P2P_FACTORS = {
  true: 0.95,
  false: 1
};
export let NUM_DENTS_FACTORS = [
  {max: 10, rate: 1.056},
  {max: 20, rate: 1.026},
  {max: 30, rate: 1.0175},
  {max: 50, rate: 1.014},
  {max: 70, rate: 1.012},
  {max: 100, rate: 1.008},
  {max: 150, rate: 1.005},
  {max: 197, rate: 1.003},
  {max: 400, rate: 1.0038}
];


const LINE_ITEMS = Object.keys(PANELS).reduce(
  (acc, p) => acc.concat(PANELS[p].lineItems),
  []
).concat(require('./general.js'));
LINE_ITEMS.forEach(l => {
  const descId = l.description.replace(/[^a-z0-9\s]/gi, '');
  l.ngramList = descId
    .split(' ')
    .filter(s => s.length)
    .reduce((acc, s) => {
      for (let i in s) {
        for (let j = 3; j <= Math.min(8, s.length - i); j++) {
          acc.push(s.substr(i, j));
        }
      }
      return acc;
    }, []);
  const subsetIds = (l.supersetOf || []).map(desc => {
    const subsetDescId = desc.replace(/[^a-z0-9\s]/gi, '');
    return {
      rrId: `rr-${subsetDescId}`,
      paintId: `paint-${subsetDescId}`,
    };
  });
  const supersetIds = (l.subsetOf || []).map(desc => {
    const supersetDescId = desc.replace(/[^a-z0-9\s]/gi, '');
    return {
      rrId: `rr-${supersetDescId}`,
      paintId: `paint-${supersetDescId}`,
    };
  });
  l.ids = {
    rrId: `rr-${descId}`,
    paintId: `paint-${descId}`,
    repairId: `repair-${descId}`,
    partId: `part-${descId}`,
    miscId: `misc-${descId}`,
    subletId: `sublet-${descId}`,
    subsetIds,
    supersetIds,
  };
});

export let generateLineItems = (current, debtor, pricingMethod, bodyShape, bodyPaint) => {
  console.log(debtor);
  if (
    !pricingMethod ||
    !bodyShape ||
    !bodyPaint
  ) {
    return;
  }
  if (
    current &&
    current.debtor === debtor &&
    current.pricingMethod === pricingMethod &&
    current.bodyShape === bodyShape &&
    current.bodyPaint === bodyPaint
  ) {
    return current;
  }
  let out = {
    pricingMethod,
    bodyShape,
    bodyPaint,
    sections: {},
    rr: {index: {}, list: []},
    repair: {index: {}, list: []},
    paint: {index: {}, list: [], sections: {}},
    part: {index: {}, list: [], sections: {}},
    misc: {index: {}, list: []},
    sublet: {index: {}, list: []},
  };
  const getSettings = (settings, name) => {
    for (let n in settings) {
      if (n === name || new RegExp(n).test(name)) {
        return settings[n];
      }
    }
  };
  const seen = {};
  Object.keys(PANELS).forEach(panel => {
    out.sections[panel] = [];
    LINE_ITEMS.forEach(line => {
      const a = getSettings(line.settings, panel);
      if (a) {
        const b = getSettings(a, debtor);
        if (b) {
          const c = getSettings(b, pricingMethod);
          if (c) {
            const d = getSettings(c, bodyShape);
            if (d) {
              const e = getSettings(d, bodyPaint);
              if (e) {
                const item = {
                  description: line.description,
                  ...line.ids,
                  canRr: e.rrTime !== undefined,
                  canPaint: e.paintTime !== undefined,
                  canRepair: e.repairTime !== undefined,
                  canPart: e.isPart === true,
                  canMisc: e.miscPrice !== undefined,
                  canSublet: e.isSublet === true,
                  hourlyRate: e.hourlyRate,
                  rrTime: e.rrTime,
                  paintTime: e.paintTime,
                  repairTime: e.repairTime,
                  isBlend: e.isBlend === true,
                  replacePanel: e.isPanel === true ? panel : null,
                  partPrice: e.partPrice,
                  miscPrice: e.miscPrice,
                  subletPrice: e.subletPrice,
                  rrAutoReplaceable: e.rrAutoReplaceable,
                  rrAutoPaintable: e.rrAutoPaintable,
                  paintAutoReplaceable: e.paintAutoReplaceable,
                  paintAutoPaintable: e.paintAutoPaintable,
                  partAutoReplaceable: e.partAutoReplaceable,
                  partAutoPaintable: e.partAutoPaintable,
                  miscAutoReplaceable: e.miscAutoReplaceable,
                  miscAutoPaintable: e.miscAutoPaintable,
                };
                out.sections[panel].push(item);
                if (item.canRr === true && seen[item.rrId] !== true) {
                  seen[item.rrId] = true;
                  const index = out.rr.list.length;
                  out.rr.list.push({
                    _id: item.rrId,
                    description: item.description,
                    rrTime: item.rrTime
                  });
                  line.ngramList.forEach(ngram => {
                    if (!out.rr.index.hasOwnProperty(ngram)) {
                      out.rr.index[ngram] = [];
                    }
                    out.rr.index[ngram].push(index);
                  });
                }
                if (item.canPaint === true && seen[item.paintId] !== true) {
                  seen[item.paintId] = true;
                  const index = out.paint.list.length;
                  out.paint.list.push({
                    _id: item.paintId,
                    description: item.description,
                    paintTime: item.paintTime,
                    isBlend: item.isBlend,
                  });
                  line.ngramList.forEach(ngram => {
                    if (!out.paint.index.hasOwnProperty(ngram)) {
                      out.paint.index[ngram] = [];
                    }
                    out.paint.index[ngram].push(index);
                  });
                  if (line.panel) {
                    out.paint.sections[item.paintId] = line.panel;
                  }
                }
                if (item.canRepair === true && seen[item.repairId] !== true) {
                  seen[item.repairId] = true;
                  const index = out.repair.list.length;
                  out.repair.list.push({
                    _id: item.repairId,
                    description: item.description,
                    repairTime: item.repairTime,
                  });
                  line.ngramList.forEach(ngram => {
                    if (!out.repair.index.hasOwnProperty(ngram)) {
                      out.repair.index[ngram] = [];
                    }
                    out.repair.index[ngram].push(index);
                  });
                }
                if (item.canPart === true && seen[item.partId] !== true) {
                  seen[item.partId] = true;
                  const index = out.part.list.length;
                  out.part.list.push({
                    _id: item.partId,
                    description: item.description,
                    partPrice: item.partPrice || 0,
                  });
                  line.ngramList.forEach(ngram => {
                    if (!out.part.index.hasOwnProperty(ngram)) {
                      out.part.index[ngram] = [];
                    }
                    out.part.index[ngram].push(index);
                  });
                  if (line.panel && e.isPanel === true) {
                    out.part.sections[item.partId] = line.panel;
                  }
                }
                if (item.canMisc === true && seen[item.miscId] !== true) {
                  seen[item.miscId] = true;
                  const index = out.misc.list.length;
                  out.misc.list.push({
                    _id: item.miscId,
                    description: item.description,
                    miscPrice: item.miscPrice,
                  });
                  line.ngramList.forEach(ngram => {
                    if (!out.misc.index.hasOwnProperty(ngram)) {
                      out.misc.index[ngram] = [];
                    }
                    out.misc.index[ngram].push(index);
                  });
                }
                if (item.canSublet === true && seen[item.subletId] !== true) {
                  seen[item.subletId] = true;
                  const index = out.sublet.list.length;
                  out.sublet.list.push({
                    _id: item.subletId,
                    description: item.description,
                    subletPrice: item.subletPrice,
                  });
                  line.ngramList.forEach(ngram => {
                    if (!out.sublet.index.hasOwnProperty(ngram)) {
                      out.sublet.index[ngram] = [];
                    }
                    out.sublet.index[ngram].push(index);
                  });
                }
              }
            }
          }
        }
      }
    });
  });
  return out;
};
