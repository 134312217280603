import {connect} from 'react-redux';

import Datestamps from './component.jsx';


const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    jobDetails: {
      header: {
        claimStatus
      },
      status,
      datestamps
    }
  } = state;
  const {profile: {abilities: {canJobDetailsViewDatestamps}}} = profiles[activeProfile];
  const visible = (canJobDetailsViewDatestamps === true);
  if (visible !== true) return {visible};
  return {
    visible,
    claimStatus,
    status,
    datestamps,
  };
};

export default connect(
  mapStateToProps,
  null
)(Datestamps);
