import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Report from './component.jsx';


const load = (API, stormId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    const url = stormId ?
      `${env.API_SERVER}/v1/debtor/reports/storm-activity/${stormId}` :
      `${env.API_SERVER}/v1/debtor/reports/storm-activity`;
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'DEBTOR-REPORT-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'DEBTOR-REPORT-SET',
          ...resp
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'DEBTOR-REPORT-SET-NULL'
});

const changeStormId = stormId => ({
  type: 'DEBTOR-REPORT-CHANGE-STORM-ID',
  stormId,
});


const mapStateToProps = (state, props) => {
  const {
    debtorReport: {notFound, stormId, storms, report},
  } = state;
  return {
    notFound,
    storms,
    stormId,
    report,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: stormId => dispatch(load(props.API, stormId)),
  onUnload: () => dispatch(unload()),
  onChangeStormId: stormId => dispatch(changeStormId(stormId)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Report)
);
