import moment from 'm';
import colors from 'colors';


let currentState = null;
const cs = window.localStorage.getItem('debtor:stormId');
if (cs) {
  currentState = JSON.parse(cs);
}

const COLORS = [
  colors.red,
  colors.green,
  colors.blue,
  colors.purple,
  colors.yellowDark,
  colors.indigo,
  colors.pink,
  colors.teal,
  colors.lime,
  colors.amber,
  colors.orange,
  colors.brown,
];

export default (state = {
  notFound: null,
  stormId: currentState,
  storms: [],
  sections: null,
}, action) => {
  switch (action.type) {
    case 'DEBTOR-DASHBOARD-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'DEBTOR-DASHBOARD-STORM-ID-SET':
    {
      const {stormId} = action;
      window.localStorage.setItem('debtor:stormId', JSON.stringify(stormId));
      return {
        ...state,
        stormId,
      };
    }
    case 'DEBTOR-DASHBOARD-SET':
    {
      return {
        ...state,
        notFound: false,
        storms: action.storms,
        sections: action.sections.map(s => {
          if (s.key === 'map') {
            return {
              key: s.key,
              color: COLORS[0],
              points: s.data.reduce((acc, {long, lat, count}) => {
                if (long === null || lat === null) {
                  console.warn('invalid map coordinates received');
                } else {
                  acc.push({
                    long,
                    lat,
                    radius: count * 20,
                  });
                }
                return acc;
              }, []),
            };
          } else if (s.key === 'finishDates') {
            return {
              key: s.key,
              categories: s.data.categories.map((c, i) => ({
                key: c,
                color: COLORS[i % COLORS.length],
              })),
              data: s.data.data,
            };
          } else if (s.key === 'progressChart') {
            return {
              key: s.key,
              allocationsColor: COLORS[0],
              cancellationsColor: COLORS[1],
              contactsColor: COLORS[2],
              assessmentsColor: COLORS[3],
              bookedColor: COLORS[4],
              remainingColor: COLORS[5],
              categories: s.data.categories.map((c, i) => ({
                key: c,
                color: COLORS[i % COLORS.length],
              })),
              data: s.data.data,
            };
          } else if (s.key === 'productionJobs') {
            return {
              key: s.key,
              jobs: s.data.map(j => ({
                ...j,
                startDateStr: j.startDate ? moment(j.startDate * 1000).format('DD-MM') : 'N/A',
                finishDateStr: j.finishDate ? moment(j.finishDate * 1000).format('DD-MM') : 'N/A',
              })),
            };
          } else {
            return s;
          }
        }),
      };
    }
    default:
      return state;
  }
};
