import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';


const Slider = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 4px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  overflow: hidden;
  z-index: 1000;
  :before {
    position: absolute;
    background-color: ${colors.primary};
    content: '';
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(0) translateX(0%);
    animation: progress 1s cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
  }
`;
const List = styled.div`
  display: flex;

  background: ${colors.grey1};
  border-radius: 0.5rem;

  flex-direction: row;
  height: 96px;
  min-width: 0;
  flex-shrink: 0;
  overflow-x: auto;
  overflow-y: hidden;
`;
const Add = styled.label`
  cursor: pointer;
  position: relative;
  width: 128px;
  height: 96px;
  flex-shrink: 0;
  flex-grow: 0;
  background: #000;

  &.active {
    background: ${colors.body};
  }
  & svg {
    stroke: #fff;
  }
  & .image-btn:not(.pinned) {
    visibility: hidden;
  }
  :hover .image-btn {
    visibility: visible;
  }
`;
const ImageContainer = styled.div`
  cursor: pointer;
  position: relative;
  & svg {
    stroke: #fff;
  }
  & .image-btn:not(.pinned) {
    visibility: hidden;
  }
  :hover .image-btn {
    visibility: visible;
  }
`;
const Pin = styled.div`
  background: ${colors.grey4};
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 5px;
`;
const Delete = styled.div`
  background: ${colors.grey4};
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px;
  & svg {
    stroke: ${colors.red};
  }
`;
const Download = styled.div`
  background: ${colors.grey4};
  cursor: pointer;
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 5px;
`;
const DownloadAll = styled.div`
  background: ${colors.grey4};
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 5px;
`;
const ImageGallery = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.9);
  z-index: 2000;
`;
const Arrow = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  & svg {
    stroke: #fff;
  }
`;
const ArrowLeft = styled(Arrow)`
  left: 0px;
`;
const ArrowRight = styled(Arrow)`
  right: 0px;
`;
const ImageTs = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
`;


function download(content, mimeType, filename) {
  const a = window.document.createElement("a"); // Create "a" element
  const blob = new Blob([content], { type: mimeType }); // Create a blob (file-like object)
  const url = URL.createObjectURL(blob); // Create an object URL from blob
  a.setAttribute("href", url); // Set "a" element link
  a.setAttribute("download", filename); // Set download filename
  a.click(); // Start downloading
}

export default class ImageList extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      uploading: false,
      active: false
    };

    this.onDocumentKey = this.onDocumentKey.bind(this);

    this.onDragOver = this.onDrag.bind(this, true);
    this.onDragLeave = this.onDrag.bind(this, false);
    this.onDrop = this.onDrop.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onNext = this.onScroll.bind(this, 1);
    this.onPrev = this.onScroll.bind(this, -1);
  }
  componentDidMount() {
    document.addEventListener('keyup', this.onDocumentKey);
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.onDocumentKey);
  }
  onDocumentKey(e) {
    const {gallery: {openIndex}, onClose, onScroll} = this.props;
    if (openIndex === null) return;
    switch(e.key) {
      case 'Escape':
        onClose();
        break;
      case 'ArrowLeft':
        onScroll(-1);
        break;
      case 'ArrowRight':
        onScroll(1);
        break;
      default:
        break;
    }
  }
  onDrag(active, e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      active
    });
  }
  onDrop(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      active: false
    });

    const upload = images => {
      const {jobId, onUpload} = this.props;
      this.setState({uploading: true});
      onUpload(jobId, images)
        .then(res => {
          if (res !== true) {
            throw new Error('unexpected response');
          } else {
            toast.success('Job successfully updated.');
          }
        })
        .catch(e => {
          console.log(e);
          toast.error('Error encountered while updating job.');
        })
        .finally(() => {
          this.setState({uploading: false});
        });
    }

    if (e.dataTransfer.items) {
      const images = [];
      const {items} = e.dataTransfer;
      for (let i = 0; i < items.length; i++) {
        if (
          items[i].kind === 'file' &&
          ['image/png', 'image/jpeg'].indexOf(items[i].type) !== -1
        ) {
          images.push(items[i].getAsFile());
        }
      }
      upload(images);
      items.clear();
    } else {
      const images = [];
      const {files} = e.dataTransfer;
      for (let i = 0; i < files.length; i++) {
        images.push(files[i]);
      }
      upload(images);
      e.dataTransfer.clearData();
    }
  }
  onUpload(e) {
    e.preventDefault();
    const {jobId, onUpload} = this.props;
    const images = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (['image/png', 'image/jpeg'].indexOf(files[i].type) !== -1) {
        images.push(files[i]);
      }
    }
    this.setState({uploading: true});
    onUpload(jobId, images)
      .then(res => {
        if (res !== true) {
          throw new Error('unexpected response');
        } else {
          toast.success('Job successfully updated.');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while updating job.');
      })
      .finally(() => {
        this.setState({uploading: false});
      });
    e.target.value = '';
  }
  onClose() {
    this.props.onClose();
  }
  onScroll(dir, e) {
    e.stopPropagation();
    this.props.onScroll(dir);
  }
  onClick(e) {
    e.stopPropagation();
  }
  onOpen(index, e) {
    e.stopPropagation();
    const {onOpen} = this.props;
    onOpen(index);
  }
  onPin(id, e) {
    e.stopPropagation();
    const {jobId, onPin} = this.props;
    this.setState({uploading: true});
    onPin(jobId, id)
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while pinning image.');
      })
      .finally(() => {
        this.setState({uploading: false});
      });
  }
  onDelete(id, e) {
    e.stopPropagation();
    const {jobId, onDelete} = this.props;
    if (window.confirm('Are you sure you wish to delete this image?') === true) {
      this.setState({uploading: true});
      onDelete(jobId, id)
        .catch(e => {
          console.log(e);
          toast.error('Error encountered while deleting image.');
        })
        .finally(() => {
          this.setState({uploading: false});
        });
    }
  }
  onDownload(image, e) {
    e.stopPropagation();
    const path = image.split('/');
    fetch(image, {method: "GET"})
    .then((response) => {
        return response.blob()
    })
    .then(data => {
      download(data, data.type, path[path.length - 1]);
    }).catch(err => {
      console.log(err)
    })
  }
  onDownloadAll(images, e) {
    e.preventDefault();
    if (window.confirm('Are you sure you want to download all images?') === true) {
      images.forEach(i => {
        const path = i.image.split('/');
        fetch(i.image, {method: "GET"})
        .then((response) => {
            return response.blob()
        })
        .then(data => {
          download(data, data.type, path[path.length - 1]);
        }).catch(err => {
          console.log(err)
        })
      })
    }
  }
  render() {
    console.log('imagelist:render');
    const {canJobAddImages, gallery: {openIndex, images}} = this.props;
    const {uploading, active} = this.state;
    if (!canJobAddImages && !images.length) return null;
    return (
      <div className='position-relative mt-3'>
        {uploading === true && <Slider />}
        <List
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
        >
          {canJobAddImages && (
            <Add
              htmlFor='fileinput'
              className={
                active === true ?
                  'd-flex flex-row justify-content-center active mb-0' :
                  'd-flex flex-row justify-content-center mb-0'
              }
            >
              <Icons.Image className='align-self-center' />
              <input
                id='fileinput'
                className='d-none'
                type='file'
                multiple
                accept='image/*'
                onChange={this.onUpload}
              />
              <DownloadAll onClick={this.onDownloadAll.bind(this, images)} className='image-btn'>
                  <Icons.Download width={16} height={16} />
              </DownloadAll>
            </Add>
          )}
          {
            images.map(({id, pinned, image, image128}, i) => (
              <ImageContainer key={i}>
                <Pin
                  onClick={this.onPin.bind(this, id)}
                  className={pinned === true ? 'image-btn pinned' : 'image-btn '}
                >
                  <Icons.Lock width={16} height={16} />
                </Pin>
                <Download onClick={this.onDownload.bind(this, image)} className='image-btn'>
                  <Icons.Download width={16} height={16} />
                </Download>
                <Delete onClick={this.onDelete.bind(this, id)} className='image-btn'>
                  <Icons.Trash2 width={16} height={16} />
                </Delete>
                <img key={i} src={image128} alt='job' onClick={this.onOpen.bind(this, i)}/>
              </ImageContainer>
            ))
          }
        </List>
        {openIndex !== null && (
          <ImageGallery className='d-flex flex-row justify-content-center align-items-center' onClick={this.onClose}>
            <div className='position-relative'>
              {images.map(({image, image640, createDateStr}, i) => (
                <div className={i === openIndex ? 'd-block' : 'd-none'} key={i}>
                  <img className='img-fluid' alt='job' src={image640} onClick={this.onClick} />
                  <ImageTs><a href={image} target='_blank' rel='noopener noreferrer' className='text-light'>{createDateStr}</a></ImageTs>
                </div>
              ))}
              <ArrowLeft className='d-flex flex-row justify-content-center align-items-center' onClick={this.onPrev}>
                <Icons.ChevronLeft width={32} height={32} />
              </ArrowLeft>
              <ArrowRight className='d-flex flex-row justify-content-center align-items-center' onClick={this.onNext}>
                <Icons.ChevronRight width={32} height={32} />
              </ArrowRight>
            </div>
          </ImageGallery>
        )}
      </div>
    );
  }
};
