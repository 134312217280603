import React from 'react';
import * as Icons from 'react-feather';
import {Prompt} from 'react-router-dom';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import JobTitle from '../JobTitle';
import ImageList from '../ImageList';
import Dates from './Dates';
import Datestamps from './Datestamps';
import Status from './Status';
import Header from './Header';
import Summary from './Summary';
import Quote from './Quote';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Flag = styled(Icons.Flag)`
  cursor: pointer;
  stroke: ${opts => opts.selected ? colors.red : colors.body};
  fill: ${opts => opts.selected ? colors.red : 'none'};
`;
const Zap = styled(Icons.Zap)`
  cursor: pointer;
  stroke: ${opts => opts.selected ? colors.orange : colors.body};
  fill: ${opts => opts.selected ? colors.orange : 'none'};
`;
const Star = styled(Icons.Star)`
  cursor: pointer;
  stroke: ${opts => opts.selected ? colors.yellow : colors.body};
  fill: ${opts => opts.selected ? colors.yellow : 'none'};
`;
const Reallocating = styled(Icons.RefreshCcw)`
  stroke: ${colors.red};
`;
const SaveButton = styled.button`
  width: 65px;
`;

export default class Details extends React.Component {
  componentDidMount() {
    const {
      jobId,
      onLoad,
    } = this.props;
    onLoad(jobId);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  componentDidUpdate(prevProps) {
    const {
      jobId,
      onLoad,
    } = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
  }

  onChange(field, e) {
    const {onChange} = this.props;
    const value = !this.props[field];
    if (field === 'flagged' || value === false) {
      onChange({
        [field]: value,
      });
    } else {
      const counterField = field === 'vip' ? 'vpp' : 'vip';
      onChange({
        [field]: value,
        [counterField]: !value,
      });
    }
  }

  onSave() {
    const {jobId, onSave} = this.props;
    onSave(jobId)
      .then(res => {
        if (res === false) {
          toast.error('Error encountered while updating job.');
        } else if (res !== true) {
          toast.error(res);
        } else {
          toast.success('Job successfully updated.');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while updating job.');
      });
  }

  render () {
    console.log('details:render');
    const {jobValid} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }

    const {
      canJobDetailsSave,
      jobId,
      jobNumber,
      importUrl,
      modified,
      vip,
      vpp,
      flagged,
      reallocating,
      isArchived,
      isInvoiced,
      isPaid,
      onChange
    } = this.props;
    return (
      <React.Fragment>
        <Prompt
          when={modified}
          message='This job has unsaved changes. Are you sure you wish to navigate away?'
        />
        <div className='d-flex flex-row justify-content-between'>
          <JobTitle
            jobId={jobId}
            jobNumber={jobNumber}
            flagged={flagged}
            vpp={vpp}
            vip={vip}
            importUrl={importUrl}
            isArchived={isArchived}
            isInvoiced={isInvoiced}
            isPaid={isPaid}
          />
          <div className='d-flex flex-row justify-content-end align-items-center'>
            <div>
              {reallocating === true && (
                <Reallocating
                  className='ml-4'
                  width={18}
                  height={18}
                />
              )}
              <Flag
                className='ml-4'
                width={18}
                height={18}
                selected={flagged}
                onClick={this.onChange.bind(this, 'flagged')} />
              <Zap
                className='ml-4'
                width={18}
                height={18}
                selected={vpp}
                onClick={this.onChange.bind(this, 'vpp')} />
              <Star
                className='ml-4'
                width={18}
                height={18}
                selected={vip}
                onClick={this.onChange.bind(this, 'vip')} />
            </div>
            {canJobDetailsSave && (
              <SaveButton
                type='button'
                className='btn btn-primary ml-4 d-print-none'
                onClick={this.onSave.bind(this)}
              >Save</SaveButton>
            )}
          </div>
        </div>
        <ImageList jobId={jobId} />
        <Datestamps jobId={jobId} onChange={onChange} />
        <Header jobId={jobId} onChange={onChange} />
        <Dates jobId={jobId} onChange={onChange} />
        <Status jobId={jobId} onChange={onChange} />
        <Summary />
        <Quote jobId={jobId} />
      </React.Fragment>
    );
  }
};
