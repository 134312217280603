export default (state = {
  notFound: null,
}, action) => {
  switch (action.type) {
    case 'SURVEY-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'SURVEY-RESET':
      return {
        notFound: null,
      };
    case 'SURVEY-SET':
    {
      const {data: d} = action;
      return {
        notFound: false,
        ...d,
        mode: 'survey'
      };
    }
    case 'SURVEY-SAVED':
    {
      const {data: d} = action;
      return {
        notFound: false,
        ...d,
        mode: 'submitted'
      };
    }
    case 'SURVEY-SUBMIT-FAILED':
    {
      return {
        notFound: false,
        mode: 'survey'
      };
    }
    default:
      return state;
  }
};
