import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';


const JobCard = styled.div`
  border-radius: 0.5rem;
  background: ${opts => opts.color};
  border: 1px solid ${opts => opts.color};
`;
//const JobCardFlag = styled.div`
//  border-radius: 0.5rem 0rem 0rem 0.5rem;
//  background: ${opts => opts.color};
//  width: 4px;
//  min-height: 100%;
//`;
const JobImg = styled.img`
  align-self: center;
  border-radius: 0.25rem;
  margin-left: 0px !important;
  max-height: 48px;
  width: auto;
`;


export default sendToPurchasing => ({task}) => (
  <div className='col-sm-6 col-md-4 col-lg-3 mt-2'>
    <JobCard
      className='d-flex flex-row mx-1 h-100'
      color={colors[task.alertColor || task.flag]}
    >
      <div className='d-flex flex-column justify-content-between flex-grow-1 p-2'>
        <div className='d-flex flex-row justify-content-between'>
          <div>
            <NavLink to={sendToPurchasing ? `/job/${task.jobId}/purchasing` : `/job/${task.jobId}`} className='text-body font-weight-bold'>
              {task.jobNumber}
            </NavLink>
          </div>
          <div className='text-center'>{task.rego}</div>
          <div className='text-right'>{task.startDateStr} | {task.finishDateStr}</div>
        </div>
        <div className='text-truncate text-uppercase'>{task.makeModel}</div>
      </div>
      {task.image64 && <JobImg src={task.image64} className='m-2' />}
    </JobCard>
  </div>
);
