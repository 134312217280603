import moment from 'moment';
import numeral from 'numeral';

import TruncateString from 'utils/truncate-string.js';


export default (state = {
  notFound: null,
  quote: null,
}, action) => {
  switch (action.type) {
    case 'SUPPLIER-QUOTE-RESET':
      return {
        ...state,
        notFound: null,
        quote: null,
      };
    case 'SUPPLIER-QUOTE-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        quote: null,
      };
    case 'SUPPLIER-QUOTE-SET':
    {
      const {quote: q} = action;
      const {requestLines, quoteLines} = q;
      const {index: reverseQuoteIndex, lines} = requestLines.reduce((acc, l, j) => {
        acc.lines.push({
          rfqLineId: l.id,
          reqQty: l.qty,
          reqDescription: l.description,
          reqPartNumber: l.partNumber,
          quoteLineId: l.quoteLineId,
        });
        if (l.quoteLineId !== null) {
          acc.index[l.quoteLineId] = j;
        }
        return acc;
      }, {index: {}, lines: []});

      quoteLines.reduce((acc, l) => {
        let index = acc;
        if (reverseQuoteIndex.hasOwnProperty(l.id)) {
          index = reverseQuoteIndex[l.id];
        } else if (/extra/i.test(l.comments)) {
          index = lines.length;
          lines.push({
            rfqLineId: null,
            reqQty: l.qtyReq,
            reqDescription: l.description,
            reqPartNumber: l.partNumber,
            quoteLineId: l.id,
          });
        } else {
          acc++;
        }
        lines[index] = {
          ...lines[index],
          quoteLineId: l.id,
          qtyBackorder: l.qtyBackorder,
          qtyAvailable: l.qtyAvailable,
          quoteDescription: l.description,
          quotePartNumber: l.partNumber,
          quoteListPrice: l.listPrice,
          quoteListPriceStr: numeral(l.listPrice / 100).format('$0,0.00'),
          quoteCostPrice: l.costPrice,
          quoteCostPriceStr: numeral(l.costPrice / 100).format('$0,0.00'),
          quoteComments: l.comments,
        };
        return acc;
      }, 0);

      const year = moment().year();
      let expectedByStr = null;
      if (q.expectedBy) {
        const expectedBy = moment(q.expectedBy * 1000);
        if (expectedBy.day() === 6) {
          expectedBy.add(-1, 'days');
        } else if (expectedBy.day() === 0) {
          expectedBy.add(-2, 'days');
        }
        expectedByStr = expectedBy.year() === year ?
          expectedBy.format('DD-MM') :
          expectedBy.format('DD-MM-YY');
      }

      return {
        ...state,
        notFound: false,
        quote: {
          ...q,
          idStr: [q.jobNumber, q.id, q.checksum].join(':'),
          makeModel: TruncateString([q.make, q.model].join(' ')),
          expectedByStr,
          createDateStr: moment(q.createDate * 1000).format('DD-MM'),
          lines,
          isSubmitted: q.submitDate !== null,
          canSubmit: q.submitDate === null && lines.reduce((acc, l) => {
            if (acc === false || !l.quoteLineId) return false;
            return true;
          }, null)
        },
      };
    }
    default:
      return state;
  }
};
