import {connect} from 'react-redux';
import XLSX from 'xlsx';

import env from 'env';
import {withApi} from 'components/API.jsx';
import BookingSchedule from './component.jsx';


const changeStormId = stormId => ({
  type: 'BOOKING-SCHEDULE-CHANGE-STORM-ID',
  stormId
});

const changeDateRange = (field, value) => ({
  type: 'BOOKING-SCHEDULE-CHANGE-DATE-RANGE',
  change: {
    [field]: value
  }
});

const load = (API, stormId, dir, start = null, finish = null) => {
  return dispatch => {
    const url = new URL(`${env.API_SERVER}/v1/internal/booking-schedule/${stormId}/${dir}`);
    url.searchParams.append('start', start);
    url.searchParams.append('finish', finish);
    dispatch({type: 'LOADING-INC'});
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'BOOKING-SCHEDULE-SET-NOT-FOUND'
          });
          return null;
        }
        const {start, finish, bookings, numPdr, numPaint, numTurret} = resp;
        dispatch({
          type: 'BOOKING-SCHEDULE-SET',
          start,
          finish,
          bookings,
          numPdr,
          numPaint,
          numTurret
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'BOOKING-SCHEDULE-RESET'
});

const save = (dir, bookings) => {
  const ws = XLSX.utils.aoa_to_sheet(bookings.reduce((acc, b) => {
    return b.jobs.reduce((acc, j) => {
      acc.push([
        j.jobNumber,
        j.rego,
        j.make,
        j.model,
        j.startDateStr,
        j.finishDateStr,
        j.postcode,
        j.comments,
      ]);
      return acc;
    }, acc);
  }, [
    [
      'Job #',
      'Rego',
      'Make',
      'Model',
      'Start',
      'Finish',
      'Postcode',
      'Comments',
    ]
  ]));
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, dir);
  XLSX.writeFile(wb, `${dir}.xlsx`);
};

const mapStateToProps = (state, props) => {
  const {match: {params: {dir}}} = props;
  const {
    params: {storms: {list: storms}},
    bookingSchedule,
  } = state;
  return {
    storms,
    dir,
    ...bookingSchedule,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onChangeStormId: stormId => dispatch(changeStormId(stormId)),
  onChangeDateRange: (field, value) => dispatch(changeDateRange(field, value)),
  onLoad: (stormId, dir, start, finish) => dispatch(load(API, stormId, dir, start, finish)),
  onUnload: () => dispatch(unload()),
  onSave: (dir, bookings) => save(dir, bookings),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BookingSchedule)
);
