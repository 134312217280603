import {connect} from 'react-redux';

import Butterfly from './component.jsx';
import getRates from 'utils/get-debtor-rates.js';


const panelAutoWork = (panel, work, rates) => ({
  type: 'JOB-DETAILS-QUOTE-PANEL-AUTO-WORK',
  panel,
  work,
  rates,
});

const panelListItemToggle = change => ({
  type: 'JOB-DETAILS-QUOTE-ITEM-TOGGLE',
  ...change
});

const panelChangePDR = (panel, change) => ({
  type: 'JOB-DETAILS-QUOTE-UPDATE-PDR',
  id: `pdr-${panel.replace(/[^a-z0-9\s]/gi, '')}`,
  panel: panel,
  ...change
});

const mapStateToProps = (state, props) => {
  const {
    jobDetails: {
      header,
      pdr,
      selectedLineItems: selectedItems,
      pdrPanels,
      paintPanels,
      replacePanels,
      lineItems: {sections: panelLists},
    },
  } = state;
  return {
    bodyShape: header.bodyShape,
    bodyPaint: header.bodyPaint,
    panelLists,
    pdr,
    selectedItems,
    pdrPanels,
    paintPanels,
    replacePanels,
    rates: getRates(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onPanelAutoWork: (panel, work, rates) => dispatch(panelAutoWork(panel, work, rates)),
  onPanelChangePDR: (panel, change) =>  dispatch(panelChangePDR(panel, change)),
  onPanelListItemToggle: change => dispatch(panelListItemToggle(change))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Butterfly);
