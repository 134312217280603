import {connect} from 'react-redux';

import Sidebar from './component.jsx';


const logout = () => ({
  type: 'AUTHENTICATION-RESET'
});

const mapStateToProps = (state, props) => {
  const {activePage, activeSubPage} = props.match.params;
  const {authentication: {activeProfile, profiles}} = state;
  const {profile: {abilities}} = profiles[activeProfile];
  return {
    open: state.menu.open,
    abilities,
    activePage,
    activeSubPage
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLogout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
