const OUT = 1.22;


export default side => ({
  panelCode: 'A',
  lineItems: [
    {
      description: `${side}R QTR PANEL`,
      panel: `${side}R-QTR`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR MOULD`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.09,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR ARCH MOULD`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.11,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR PANEL GLASS`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 150,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR PANEL GLASS MOULD`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR TAIL LIGHT`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR MOULD`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR HARDWARE`,
      panel: `${side}R-QTR`,
      subsetOf: [`${side}R QTR D/A`],
      settings: {
        [`${side}R-QTR|${side}-CANTRAIL`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.59,
                rrAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR D/A`,
      panel: `${side}R-QTR`,
      supersetOf: [`${side}R QTR HARDWARE`],
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 4.89,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR OUT`,
      panel: `${side}R-QTR`,
      supersetOf: [`${side}R QTR BLEND`],
      subsetOf: [`${side}R QTR IN & OUT`],
      settings: {
        [`${side}R-QTR`]: {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.75,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
              }
            }
          },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 3.1,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}R QTR IN & OUT`,
      panel: `${side}R-QTR`,
      supersetOf: [`${side}R QTR OUT`, `${side}R QTR BLEND`],
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.75,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.63,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR BLEND`,
      panel: `${side}R-QTR`,
      subsetOf: [`${side}R QTR IN & OUT`, `${side}R QTR OUT`],
      settings: {
        [`BOOT|${side}R-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 2.75,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        },
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            'COUPE|HATCH|SEDAN|VAN|WAGON': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 2.75,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'COUPE|HATCH|SEDAN|VAN|WAGON': { /* body shape */
              '[^]*': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        },
        [`${side}F-DOOR|TAILGATE`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            'COUPE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 2.75,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            'COUPE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoReplaceable: true,
                paintAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR LOADING 10%`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR LOADING 25%`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}R QTR REALIGNMENT`,
      settings: {
        [`${side}R-QTR`]: {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 100,
              }
            }
          },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `PDR ${side}R QTR`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BUFF & POLISH ${side}R QTR`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BRUSH TOUCH ${side}R QTR`,
      settings: {
        [`${side}R-QTR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
});
