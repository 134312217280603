import moment from 'm';

import STAGE_MAP from 'utils/stage-map.js';
import TruncateString from 'utils/truncate-string.js';

const updateEmployeeJobs = jobs => {
  const res = {
    employeeJobs: {},
    employeeLoads: {},
    numJobs: 0,
    numAllocated: 0,
    maxAllocated: 0
  };
  for (let i in jobs) {
    const j = jobs[i];
    Object.keys(STAGE_MAP).forEach(s => {
      const role = STAGE_MAP[s].employee;
      const date = STAGE_MAP[s].date;
      if (j[date] === null) {
        res.numJobs++;
       if (j[role] !== null) {
          res.numAllocated++;
          if (!res.employeeJobs.hasOwnProperty(j[role])) {
            res.employeeJobs[j[role]] = [];
          }
          res.employeeJobs[j[role]].push({
            jobId: j.id,
            role: s
          });
        }
      }
    });
  }
  for (let i in res.employeeJobs) {
    if (res.maxAllocated < res.employeeJobs[i].length) {
      res.maxAllocated = res.employeeJobs[i].length;
    }
  }
  for (let i in res.employeeJobs) {
    res.employeeJobs[i].sort((a, b) => (jobs[a.jobId].finishDate - jobs[b.jobId].finishDate));
    res.employeeLoads[i] = Math.round(res.employeeJobs[i].length / res.maxAllocated * 100);
  }
  return res;
};

export default (state = {}, action) => {
  switch (action.type) {
    case 'PRODUCTION-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true
      };
    case 'PRODUCTION-SET':
    {
      let today = moment().hour(0).minute(0).second(0).millisecond(0).unix();
      let tomorrow = moment().hour(0).minute(0).second(0).millisecond(0).add(1, 'day');
      if (tomorrow.day() === 0) {
        tomorrow.add(1, 'day');
      } else if (tomorrow.day() === 6) {
        tomorrow.add(2, 'day');
      }
      tomorrow = tomorrow.unix();

      const {jobs: _jobs} = action;
      const {
        jobs,
        incoming,
        inProgress,
        outgoing
      } = _jobs
        .reduce((acc, j) => {
          if (j.flagged === true) {
            j.alertColor = 'red';
          } else if (j.vpp === true) {
            j.alertColor = 'orange';
          } else if (j.vip === true) {
            j.alertColor = 'yellow';
          }
          if (
            j.startDate === null ||
            j.inDate === null ||
            j.striper === null ||
            j.repairer === null
          ) {
            acc.incoming.push(j.id);
            if (j.startDate < today) {
              j.flag = 'redExtraLight';
            } else if (j.startDate < tomorrow) {
              j.flag = 'orangeExtraLight';
            } else if (j.startDate === tomorrow) {
              j.flag = 'blueExtraLight';
            } else {
              j.flag = 'grey1';
            }
          } else if (j.finishDate <= tomorrow || j.detailer !== null) {
            acc.outgoing.push(j.id);
            if (j.finishDate < today) {
              j.flag = 'redExtraLight';
            } else if (j.finishDate === today) {
              j.flag = 'orangeExtraLight';
            } else {
              j.flag = 'grey1';
            }
          } else {
            acc.inProgress.push(j.id);
            j.flag = 'grey1';
          }
          acc.jobs[j.id] = {
            ...j,
            isTurret: j.bookingType === 'turret',
            isPaint: j.bookingType === 'paint',
            startDateStr: j.startDate ? moment(j.startDate * 1000).format('DD-MM') : 'N/A',
            finishDateStr: j.finishDate ? moment(j.finishDate * 1000).format('DD-MM') : 'N/A',
            makeModel: TruncateString([j.make || '', j.model || ''].join(' '))
          };
          return acc;
        }, {
          jobs: {},
          incoming: [],
          inProgress: [],
          outgoing: []
        });

      incoming.sort((a, b) => (jobs[a].startDate - jobs[b].startDate));
      inProgress.sort((a, b) => (jobs[a].finishDate - jobs[b].finishDate));
      outgoing.sort((a, b) => (jobs[a].finishDate - jobs[b].finishDate));

      return {
        ...state,
        notFound: false,
        filteredEmployee: null,
        jobs,
        ...updateEmployeeJobs(jobs),
        incoming,
        inProgress,
        outgoing,
      };
    }
    case 'PRODUCTION-UPDATE':
    {
      const update = Object.values(STAGE_MAP)
        .reduce((acc, {employee, date}) => {
          acc[employee] = action[employee];
          acc[date] = action[date];
          return acc;
        }, {});
      const newJobs = {
        ...state.jobs,
        [action.jobId]: {
          ...state.jobs[action.jobId],
          ...update,
        }
      };
      return {
        ...state,
        jobs: newJobs,
        ...updateEmployeeJobs(newJobs)
      };
    }
    //case 'PRODUCTION-SET-STAGE-EMPLOYEE':
    //{
    //  const {jobId, stage, employee} = action;
    //  const key = STAGE_MAP[stage].employee;
    //  const newJobs = {
    //    ...state.jobs,
    //    [jobId]: {
    //      ...state.jobs[jobId],
    //      [key]: employee
    //    }
    //  };
    //  return {
    //    ...state,
    //    jobs: newJobs,
    //    ...updateEmployeeJobs(newJobs)
    //  };
    //}
    //case 'PRODUCTION-SET-STAGE-DONE':
    //{
    //  const {jobId, stage, done} = action;
    //  const key = STAGE_MAP[stage].date;
    //  const newJobs = {
    //    ...state.jobs,
    //    [jobId]: {
    //      ...state.jobs[jobId],
    //      [key]: done === true ? moment().unix() : null,
    //    }
    //  };
    //  return {
    //    ...state,
    //    jobs: newJobs,
    //    ...updateEmployeeJobs(newJobs)
    //  };
    //}
    case 'PRODUCTION-SET-FILTERED-EMPLOYEE':
    {
      const {employee} = action;
      return {
        ...state,
        filteredEmployee: employee
      };
    }
    default:
      return state;
  }
};
