import React from 'react';

import colors from 'colors';

import Autocomplete from 'components/Autocomplete.jsx';


export default class PartsMakeModel extends React.Component {
  constructor(opts) {
    super(opts);
    this.onFilter = this.onFilter.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.vehicles !== this.props.vehicles ||
      nextProps.value !== this.props.value
    );
  }
  onFilter(v) {
    const {vehicles, vehiclesNgramIndex} = this.props;
    const {list} = v
      .replace(/[^a-z0-9\s]/gi, '')
      .split(' ')
      .filter(s => s.length)
      .reduce((acc, s) => {
        for (let i in s) {
          for (let j = 2; j <= Math.min(8, s.length - i); j++) {
            const ngram = s.substr(i, j);
            if (vehiclesNgramIndex.hasOwnProperty(ngram)) {
              vehiclesNgramIndex[ngram].forEach(idx => {
                if (!acc.index.hasOwnProperty(idx)) {
                  acc.index[idx] = acc.list.length;
                  acc.list.push({
                    idx,
                    count: 0
                  });
                }
                acc.list[acc.index[idx]].count += ngram.length;
              });
            }
          }
        }
        return acc;
      }, {index: {}, list: []});
    return list
      .sort((a, b) => (b.count - a.count))
      .slice(0, 20)
      .map(ngram => {
        const {code: id, model: text} = vehicles[ngram.idx];
        return {
          id,
          text,
        };
      });
  }
  render() {
    console.log('partsmakemodel:render');
    const {value, className, onChange} = this.props;
    return (
      <Autocomplete
        className={className}
        background={colors.grey1}
        uppercaseOnly={true}
        value={value}
        onChange={onChange}
        onFilter={this.onFilter}
      >
        <small className='text-muted'>Make/model</small>
      </Autocomplete>
    );
  }
};


