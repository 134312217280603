import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import ReportsLocation from './component.jsx';


const load = (API, locationId, start, finish, primaryOnly) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/reports/location`,
      {
        method: 'POST',
        header: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          locationId,
          start,
          finish,
          primaryOnly,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-REPORTS-LOCATION-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'ADMIN-REPORTS-LOCATION-SET',
          ...resp
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-REPORTS-LOCATION-SET-NULL'
});


const mapStateToProps = (state, props) => {
  const {
    adminReportsLocation: {notFound, report},
    params: {
      locations: {index: locationIndex, list: locations},
    }
  } = state;
  return {
    notFound,
    locationIndex,
    locations,
    report,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: (locations, year, month, primaryOnly) => dispatch(load(props.API, locations, year, month, primaryOnly)),
  onUnload: () => dispatch(unload()),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportsLocation)
);
