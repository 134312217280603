import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import LightTunnel from './component.jsx';


const close = () => ({
  type: 'JOB-LIGHT-TUNNEL-CLOSE'
});

const capture = (API, lightTunnelId, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/light-tunnel/${lightTunnelId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({jobId})
      }
    )
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        //console.warn('NEED TO RELOAD CURRENT PAGE?');
        //dispatch({
        //  type: 'JOB-DETAILS-QUOTE-SET',
        //  lines: resp.lines
        //});
        //dispatch({
        //  type: 'JOB-DETAILS-QUOTE-SET',
        //  lines: resp.lines
        //});
        return resp.status;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = (state, props) => {
  const {
    jobLightTunnel: {open},
    params: {
      lightTunnels
    }
  } = state;
  const {jobId} = props;
  return {jobId, open, lightTunnels};
};

const mapDispatchToProps = (dispatch, props) => ({
  onClose: () => dispatch(close()),
  onCapture: (lightTunnelId, jobId) => dispatch(
    capture(
      props.API,
      lightTunnelId,
      jobId,
    )
  ),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LightTunnel)
);

