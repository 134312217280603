import React from 'react';
import styled from 'styled-components';

import colors from 'colors';


const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;


export default ({
  visible,
  claimStatus,
  status,
  datestamps,
}) => {
  console.log('datestamps:render');
  if (visible !== true) return null;
  return (
    <Card className='p-3 mt-3'>
      <div className='text-center font-weight-bold'>
        {status} {claimStatus && claimStatus !== 'Current' && <span className='text-danger'>({claimStatus})</span>}
      </div>
      <div className='row mt-2'>
        {datestamps.map((d, i) => (
          <div className={d.alert ? 'col-6 col-sm-4 col-md-3 text-danger' : 'col-6 col-sm-4 col-md-3 text-muted'} key={i}>
            <strong>{d.name}:</strong> {d.value}
          </div>
        ))}
      </div>
    </Card>
  );
};

