import React from 'react';
import {connect} from 'react-redux';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';


const Row = styled.div`
  margin: -0.25rem;
`;
const Col = styled.div`
  padding: 0.25rem !important;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
`;
const Button = styled.div`
  cursor: pointer;
  & svg {
    stroke: ${props => props.color};
  }
`;

class List extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false
    };
    this.onToggle = this.onToggle.bind(this);
  }
  onToggle() {
    this.setState({
      open: !this.state.open
    });
  }
  render() {
    const {title, items, onEdit} = this.props;
    const {open} = this.state;
    if (open === false) {
      return (
        <div
          className='d-flex flex-row justify-content-between align-items-center font-weight-bold cursor-pointer'
          onClick={this.onToggle}
        >
          {title}
          <Icons.ChevronDown color={colors.primary} />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div
          className='d-flex flex-row justify-content-between align-items-center font-weight-bold cursor-pointer'
          onClick={this.onToggle}
        >
          {title}
          <Icons.ChevronUp color={colors.primary} />
        </div>
        <Row className='d-flex flex-row flex-wrap mt-3'>
          {items.map((item, i) => (
            <Col key={i} className='col-12 col-md-4 col-lg-3'>
              <Card className='d-flex flex-row justify-content-between'>
                <div className='text-truncate'>{item.name}</div>
                <Button
                  color={colors.primary}
                  onClick={onEdit.bind(null, item.id)}
                ><Icons.Edit2 width={18} height={18} /></Button>
              </Card>
            </Col>
          ))}
        </Row>
        <div className='ml-3 mt-3'>
          <Button
            color={colors.body}
            onClick={onEdit.bind(null, 'new')}
          ><Icons.Plus width={18} height={18} /></Button>
        </div>
      </React.Fragment>
    );
  }
};

export default connect((state, props) => ({items: state.settings[props.name]}), null)(List);
