import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Infosheet from './component.jsx';


const load = (API, jobId) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/infosheet`,
      {method: 'GET'}
    )
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-INFOSHEET-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'JOB-INFOSHEET-SET',
          ...resp,
        });
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const email = (API, jobId, emailId, to) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/infosheet`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          emailId,
          to,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-INFOSHEET-RESET'
});

const mapStateToProps = (state, props) => {
  const {jobId} = props;
  const {
    params: {
      generalSettings: {
        name: companyName,
        abn,
        licenseNumber,
        phone
      },
      locations: {
        index: locations
      },
    },
    jobDetails: {
      header
    },
    jobInfosheet
  } = state;
  return {
    jobId,
    companyName,
    abn,
    licenseNumber,
    phone,
    customerEmail: (header && header.email) || '',
    locations,
    ...jobInfosheet,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: jobId => dispatch(load(API, jobId)),
  onEmail: (jobId, emailId, to) => dispatch(email(API, jobId, emailId, to)),
  onUnload: () => dispatch(unload()),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Infosheet)
);
