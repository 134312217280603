import {connect} from 'react-redux';
import XLSX from 'xlsx';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Tracking from './component.jsx';


const load = (API, locationId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/tracking/${locationId}`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'TRACKING-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'TRACKING-SET',
          ...resp
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const toggleSection = section => ({
  type: 'TRACKING-SECTION-TOGGLE',
  section,
});

const save = sections => {
  const wb = XLSX.utils.book_new();
  sections.forEach(({sheetTitle, jobs}) => {
    const ws = XLSX.utils.aoa_to_sheet(jobs.reduce((acc, j) => {
      acc.push([
        j.jobNumber,
        j.rego,
        j.claimNumber,
        j.make,
        j.model,
        j.startDateStr,
        j.finishDateStr,
        j.postcode,
        j.subtotal,
        j.total,
        j.totalLessExcess,
        j.mailbox,
        j.comments,
      ]);
      return acc;
    }, [
      [
        'Job #',
        'Rego',
        'Claim number',
        'Make',
        'Model',
        'Start',
        'Finish',
        'Postcode',
        'Subtotal',
        'Total',
        'Total less excess',
        'Mailbox',
        'Comments',
      ]
    ]));

    // below are ensure the amount number field has the right format so that excel can sum it
    const currencyFormat = '"$"#,##0.00_);\\("$"#,##0.00\\)';
    jobs.forEach((e, i) => {
      ws[`I${i+2}`].z = currencyFormat
      ws[`J${i+2}`].z = currencyFormat
      ws[`K${i+2}`].z = currencyFormat
    })

    XLSX.utils.book_append_sheet(wb, ws, sheetTitle);
  });
  XLSX.writeFile(wb, 'tracking.xlsx');
};

const mapStateToProps = (state, props) => {
  const {
    locationId,
    params: {
      debtors: {index: debtors}
    },
    tracking: {
      notFound,
      sections,
      sectionStates
    }
  } = state;
  return {
    notFound,
    sections,
    sectionStates,
    locationId,
    debtors,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: locationId => dispatch(load(API, locationId)),
  onToggleSection: section => dispatch(toggleSection(section)),
  onSave: sections => save(sections),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Tracking)
);
