import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Print from './component.jsx';


const load = (API, jobId, emailId = null, to = null) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/quote`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          emailId,
          to,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};
const unload = () => ({
  type: 'JOB-PRINT-RESET'
});

const mapStateToProps = (state, props) => {
  const {jobId} = props;
  const {
    params: {
      debtors: {
        index: debtors
      },
    },
    jobPrint: {
      open,
      mode,
      quoteDate
    },
    jobDetails: {
      header,
      company,
      quote,
    }
  } = state;
  return {
    jobId,
    company,
    open,
    mode,
    quoteDate,
    debtors,
    header,
    quote,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: (jobId, emailId, to) => dispatch(load(API, jobId, emailId, to)),
  onUnload: () => dispatch(unload()),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Print)
);
