import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import colors from 'colors';


const JobCard = styled.div`
  border-radius: 0.5rem;
  background-color: ${opts => opts.color};
  border: 1px solid ${opts => opts.color};
`;
//const JobCardFlag = styled.div`
//  border-radius: 0.5rem 0rem 0rem 0.5rem;
//  background: ${opts => opts.color};
//  width: 4px;
//  min-height: 100%;
//`;
const JobImg = styled.img`
  align-self: center;
  border-radius: 0.25rem;
  margin-left: 0px !important;
  max-width: 64px;
  max-height: 48px;
  width: auto;
`;


export default class TechTask extends React.Component {
  constructor(opts) {
    super(opts);
    this.onToggleDone = this.onToggleDone.bind(this);
    this.onToggleConfirm = this.onToggleConfirm.bind(this);
  }
  onToggleDone(e) {
    const {task: {locationId, jobId, task}, onToggleDone} = this.props;
    onToggleDone({locationId, jobId, task, done: e.target.checked});
  }

  onToggleConfirm(e) {
    const {task: {locationId, jobId, task}, onToggleDone} = this.props;
    if(e.target.checked === true) {
      if (window.confirm('Do you confirm that the air bags and all safety components have been refitted to OEM specifications?') === true) {
        onToggleDone({locationId, jobId, task, done: e.target.checked});
      }
    } else {
      onToggleDone({locationId, jobId, task, done: e.target.checked});
    }
  }

  render() {
    const {employees, type, task} = this.props;
    const isFitup = type === 'fitup';
    return (
      <div className='col-sm-6 col-md-4 col-lg-3 mt-2'>
        <JobCard className='d-flex flex-row mx-1 h-100' color={colors[task.alertColor || task.flag]}>
          <div className='d-flex flex-column justify-content-between flex-grow-1 p-2'>
            <div className='d-flex flex-row justify-content-between'>
              <div>
                <NavLink to={`/job/${task.jobId}`} className='text-body font-weight-bold'>
                  {task.jobNumber}
                </NavLink>
              </div>
              <div className='text-center'>{task.rego}</div>
              <div className='text-right'>{task.startDateStr} | {task.finishDateStr}</div>
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className='flex-grow-1 text-truncate text-uppercase'>{task.makeModel}</div>
              <div className='text-right text-uppercase'>
                {type === 'rr' && `${task.task} | `}
                {type === 'repair' && `${employees[task.striper].name} | `}
                {task.bookingType}
              </div>
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <input
                type='checkbox'
                onChange={isFitup ? this.onToggleConfirm : this.onToggleDone}
                checked={task.done === true}
              />
              <div className='text-right'>{task.amountStr}</div>
            </div>
          </div>
          {task.image64 && <JobImg src={task.image64} className='m-2' />}
        </JobCard>
      </div>
    );
  }
};
