import numeral from 'numeral';


export default (state = {notFound: null, report: null}, action) => {
  switch (action.type) {
    case 'ADMIN-REPORTS-PROFIT-LOSS-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        report: null
      };
    case 'ADMIN-REPORTS-PROFIT-LOSS-SET-NULL':
      return {
        ...state,
        notFound: null,
        report: null,
      };
    case 'ADMIN-REPORTS-PROFIT-LOSS-SET':
      {
        const {locations, rows} = action;
        rows.forEach(row => {
          if (row.isLeaf === true) {
            if (row.type === 'percentage') {
              row.columns = row.columns.map(c => numeral(c).format('0.00%'));
            } else {
              row.columns = row.columns.map(c => numeral(c / 100).format('$0,0.00'));
            }
          }
        });
        return {
          ...state,
          notFound: false,
          report: {
            locations,
            rows,
          }
        };
      }
    default:
      return state;
  }
};
