import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import Modal from 'components/Modal';
import Field from 'components/Field.jsx';
import CalendarInput from 'components/CalendarInput.jsx';


const ModalBody = styled.div`
  & .form-group:last-of-type {
    margin-bottom: 0px;
  }
`;

export default class InvoiceOrder extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      open: false,
      orderId: null,
      invoiceNumber: '',
      invoiceDate: null,
      canEditInvoiceNumber: true,
      lines: [],
      total: '',
      expectedTotal: 0,
      totalError: true,
      canInvoice: false,
      canSave: false
    };
    this.onOpen = this.onToggleModal.bind(this, true);
    this.onClose = this.onToggleModal.bind(this, false);
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout !== null) clearTimeout(this.timeout);
    this.timeout = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.lines === this.props.lines &&
      nextState === this.state
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lines !== prevProps.lines || this.state.open !== prevState.open) {
      const {
        orderId,
        invoiceNumber,
        invoiceDate,
        lines,
        expectedTotal,
        canInvoice
      } = Object.values(this.props.lines).sort((a, b) => a.index - b.index)
        .reduce((acc, l) => {
          if (l) {
            if (acc.orderId === null) {
              acc.canInvoice = l.canEdit;
              acc.orderId = l.orderId;
            } else if (acc.orderId !== l.orderId) {
              acc.canInvoice = false;
            }
            if (acc.canInvoice === true) {
              if (acc.invoiceNumber === null) {
                acc.invoiceNumber = l.invoiceNumber;
                acc.invoiceDate = l.invoiceDate;
              } else if (acc.invoiceNumber !== l.invoiceNumber) {
                acc.canInvoice = false;
              }
            }
            const e = {
              id: l.reqId,
              invoiceLineId: l.invoiceLineId,
              qty: l.invoiceLineId ? l.qtySupplied : l.reqQty,
              description: l.description || l.reqDescription,
              partNumber: l.partNumber || l.reqPartNumber,
              costPrice: l.costPrice,
              listPrice: l.listPrice,
            };
            acc.expectedTotal += ((e.qty || 0) * (e.costPrice || 0));
            acc.lines.push(e);
          }
          return acc;
        }, {
          orderId: null,
          invoiceNumber: null,
          invoiceDate: null,
          lines: [],
          expectedTotal: 0,
          canInvoice: true
        });
      if (orderId !== null && lines.length >= 1) {
        const newState = {
          ...this.state,
          orderId,
          invoiceNumber,
          invoiceDate,
          canEditInvoiceNumber: invoiceNumber === null,
          lines,
          total: '',
          expectedTotal,
          totalError: true,
          canInvoice
        };
        newState.canSave = this._getCanSave(newState);
        this.setState(newState);
      } else {
        this.setState({
          ...this.state,
          orderId: null,
          invoiceNumber: '',
          invoiceDate: null,
          canEditInvoiceNumber: true,
          lines: [],
          total: '',
          expectedTotal: 0,
          totalError: true,
          canInvoice: false
        });
      }
    }
  }

  onToggleModal(open) {
    if (open === true) {
      this.setState({
        open,
        invoiceNumber: '',
        invoiceDate: null,
        canEditInvoiceNumber: true,
        canSave: false
      });
    } else {
      this.setState({
        open,
      });
    }
  }

  _getCanSave(state) {
    return (
      state.invoiceNumber !== '' &&
      state.invoiceDate !== null &&
      state.total !== '' &&
      state.totalError !== true &&
      state.lines.length &&
      state.lines.filter(l =>
        l.qty === '' ||
        l.partNumber === '' ||
        l.costPrice === null ||
        l.costPrice === '' ||
        l.listPrice === null ||
        l.listPrice === ''
      ).length === 0
    );
  }

  onChange(field, v) {
    if (field === 'total') {
      if (/^[0-9]*\.?([0-9]{0,2})?$/.test(v) === true || v === '') {
        const {gstRate} = this.props;
        const expectedTotal = this.state.lines.reduce((acc, l) => acc + l.qty * l.costPrice, 0);
        const totalError = (
          Number(Math.round(v * 10) / 10).toFixed(2) !==
          Number(
            Math.round(
              Math.round(expectedTotal * ((100 + gstRate) / 100) * 100) / 100 * 10
            ) / 10
          ).toFixed(2)
        );
        const newState = {
          ...this.state,
          [field]: v,
          expectedTotal,
          totalError,
        };
        newState.canSave = this._getCanSave(newState);
        this.setState(newState);
      }
    } else {
      const newState = {
        ...this.state,
        [field]: v,
      };
      newState.canSave = this._getCanSave(newState);
      this.setState(newState);
    }
  }

  onChangeQty(i, v) {
    if (/^\d*$/.test(v) === true || v === '') {
      const {gstRate} = this.props;
      const lines = this.state.lines;
      lines[i].qty = v;
      const expectedTotal = lines.reduce((acc, l) => acc + l.qty * l.costPrice, 0);
      this.setState({
        lines,
        expectedTotal,
        totalError: (
          Number(Math.round(this.state.total * 10) / 10).toFixed(2) !==
          Number(
            Math.round(
              Math.round(expectedTotal * ((100 + gstRate) / 100) * 100) / 100 * 10
            ) / 10
          ).toFixed(2)
        ),
      });
      if (this.timeout !== null) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.timeout = null;
        const canSave = this._getCanSave(this.state);
        this.setState({
          canSave
        });
      }, 300);
    }
  }

  onChangePartNumber(i, v) {
    const lines = this.state.lines;
    lines[i].partNumber = v;
    this.setState({
      lines
    });
    if (this.timeout !== null) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.timeout = null;
      const canSave = this._getCanSave(this.state);
      this.setState({
        canSave
      });
    }, 300);
  }

  onChangeCost(i, field, v) {
    if (/^[0-9]*\.?([0-9]{0,2})?$/.test(v) === true || v === '') {
      const {gstRate} = this.props;
      const lines = this.state.lines;
      lines[i][field] = v;
      const expectedTotal = lines.reduce((acc, l) => acc + l.qty * l.costPrice, 0);
      this.setState({
        lines,
        expectedTotal,
        totalError: (
          Number(Math.round(this.state.total * 10) / 10).toFixed(2) !==
          Number(
            Math.round(
              Math.round(expectedTotal * ((100 + gstRate) / 100) * 100) / 100 * 10
            ) / 10
          ).toFixed(2)
        ),
      });
      if (this.timeout !== null) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.timeout = null;
        const canSave = this._getCanSave(this.state);
        this.setState({
          canSave
        });
      }, 300);
    }
  }

  onSave() {
    const {jobId, onSave} = this.props;
    const {orderId, invoiceNumber, invoiceDate, lines} = this.state;
    onSave(
      jobId,
      orderId,
      {
        action: 'invoice',
        invoiceNumber,
        invoiceDate,
        lines: lines.map(({id, invoiceLineId, qty, partNumber, costPrice, listPrice}) => ({
          id,
          invoiceLineId,
          qty,
          partNumber,
          costPrice,
          listPrice
        }))
      }
    )
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while invoicing parts.');
        } else {
          toast.success('Parts successfully invoiced.');
          this.setState({
            open: false,
          });
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while invoicing parts.');
      });
  }

  render () {
    console.log('invoiceorder:render');
    const {
      open,
      invoiceNumber,
      invoiceDate,
      canEditInvoiceNumber,
      lines,
      total,
      totalError,
      canInvoice,
      canSave
    } = this.state;
    return (
      <React.Fragment>
        <button
          type='button'
          className='btn btn-primary ml-2'
          onClick={this.onOpen}
          disabled={canInvoice !== true}
        >Invoice</button>
        <Modal
          title='Invoice'
          open={open}
          wide={true}
          onClose={this.onClose}
        >
          <ModalBody className='modal-body'>
            <div className='row no-gutters mb-2'>
              <div className='col mr-1'>
                <Field
                  title='Invoice #'
                  value={invoiceNumber}
                  readOnly={canEditInvoiceNumber !== true}
                  background={colors.grey1}
                  uppercaseOnly={true}
                  onChange={this.onChange.bind(this, 'invoiceNumber')} />
              </div>
              <div className='col ml-1'>
                <CalendarInput
                  title='Invoice date'
                  background={colors.grey1}
                  selected={invoiceDate}
                  disabled={canEditInvoiceNumber !== true}
                  onChange={this.onChange.bind(this, 'invoiceDate')}
                />
              </div>
              <div className='col ml-1'>
                <Field
                  title='Total (inc GST)'
                  value={total}
                  error={totalError}
                  background={colors.grey1}
                  uppercaseOnly={true}
                  onChange={this.onChange.bind(this, 'total')} />
              </div>
            </div>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th className='border-top-0 text-center pl-0 pr-1'>Qty</th>
                  <th className='border-top-0 px-1' width='40%'>Description</th>
                  <th className='border-top-0 text-center px-1' width='20%'>Part #</th>
                  <th className='border-top-0 text-center px-1'>List</th>
                  <th className='border-top-0 text-center pl-1 pr-0'>Cost</th>
                </tr>
              </thead>
              <tbody>
                {lines.map((l, i) => (
                  <tr key={i}>
                    <td className='align-middle pl-0 pr-1'>
                      <Field
                        value={l.qty || ''}
                        background={colors.grey1}
                        onChange={this.onChangeQty.bind(this, i)} />
                    </td>
                    <td className='align-middle px-1'>{l.description}</td>
                    <td className='align-middle px-1'>
                      <Field
                        value={l.partNumber || ''}
                        uppercaseOnly={true}
                        background={colors.grey1}
                        onChange={this.onChangePartNumber.bind(this, i)} />
                    </td>
                    <td className='m-0 px-1'>
                      <Field
                        value={l.listPrice || ''}
                        background={colors.grey1}
                        onChange={this.onChangeCost.bind(this, i, 'listPrice')} />
                    </td>
                    <td className='m-0 pl-1 pr-0'>
                      <Field
                        value={l.costPrice || ''}
                        background={colors.grey1}
                        onChange={this.onChangeCost.bind(this, i, 'costPrice')} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalBody>
          <div className='modal-footer bt-0'>
            <button type='button' className='btn btn-secondary' onClick={this.onClose}>Close</button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={this.onSave.bind(this)}
              disabled={canSave !== true}
            >Save</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
};

