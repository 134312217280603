
export default {
  strip: {
    employee: 'striper',
    date: 'stripDate'
  },
  repair: {
    employee: 'repairer',
    date: 'repairDate'
  },
  paint: {
    employee: 'painter',
    date: 'paintDate'
  },
  fitup: {
    employee: 'fituper',
    date: 'fitupDate'
  },
  detail: {
    employee: 'detailer',
    date: 'detailDate'
  },
  qa: {
    employee: 'qaer',
    date: 'qaDate'
  },
};
