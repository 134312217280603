import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  height: 100%;
  overflow: auto;
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Task = styled.tr`
  background: ${opts => opts.alert ? colors.redExtraLight : colors.white};
  & td {
    border: 0 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;


export default class TechnicianInvoicing extends React.Component {
  constructor(opts) {
    super(opts);
    this.onInvoice = this.onInvoice.bind(this);
  }
  componentDidMount() {
    const {onLoad} = this.props;
    onLoad();
  }
  onToggle(index, e) {
    this.props.onToggle(index, e.target.checked);
  }
  onInvoice() {
    const {outstandingTasks, onInvoice} = this.props;
    const tasks = outstandingTasks.reduce((acc, t) => {
      if (t.selected === true) {
        acc.push({
          jobId: t.jobId,
          locationId: t.locationId,
          task: t.task
        });
      }
      return acc;
    }, []);
    onInvoice(tasks)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while submitting invoice.');
        } else {
          toast.success('Invoice successfully submitted.');
        }
      })
      .catch(e => {
        toast.error('Error encountered while submitting invoice.');
      });
  }
  render() {
    console.log('technicianinvoicing:render');
    const {notFound} = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load tasks.</div>
        </ErrorContainer>
      );
    }
    const {
      chargesGst,
      invoices,
      subtotalInvoices,
      gstInvoices,
      totalInvoices,
      outstandingTasks,
      subtotalTasks,
      gstTasks,
      totalTasks
    } = this.props;
    return (
      <Container className='flex-grow-1 p-3'>
        <h5 className='mb-0'>Invoicing</h5>
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold'>Outstanding tasks ({outstandingTasks.length})</div>
          {outstandingTasks.length === 0 && (
            <div className='text-center text-secondary'>No tasks remaining.</div>
          )}
          {outstandingTasks.length !== 0 && (
            <table className='table mb-0 mt-3'>
              <thead>
                <tr>
                  <th className='border-0 py-0'>Job #</th>
                  <th className='border-0 py-0'>Rego</th>
                  <th className='border-0 py-0'>Make/model</th>
                  <th className='border-0 py-0'>Task</th>
                  <th className='border-0 py-0'>Done date</th>
                  <th className='border-0 py-0'>Amount</th>
                  <th className='border-0 py-0' width={50} />
                </tr>
              </thead>
              <tbody>
                {outstandingTasks.map((t, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td className='p-1 border-0' />
                    </tr>
                    <Task className={t.disabled && 'text-muted'}>
                      <td>
                        <Link to={`/job/${t.jobId}`}>
                          <strong className={t.disabled ? 'text-muted' : 'text-body'}>{t.jobNumber}</strong>
                        </Link>
                      </td>
                      <td>{t.rego}</td>
                      <td className='text-uppercase'>{t.makeModel}</td>
                      <td className='text-uppercase'>{t.task}</td>
                      <td>{t.doneDateStr}</td>
                      <td>
                        {t.amountStr}
                        {t.adjustment > 0 && (
                          <span className='text-success ml-2'>inc {t.adjustmentStr} adjustment</span>
                        )}
                        {t.adjustment < 0 && (
                          <span className='text-danger ml-2'>inc {t.adjustmentStr} adjustment</span>
                        )}
                      </td>
                      <td className='text-center'>
                        <input
                          type='checkbox'
                          disabled={t.disabled === true}
                          checked={t.selected === true}
                          onChange={this.onToggle.bind(this, i)}
                        />
                      </td>
                    </Task>
                  </React.Fragment>
                ))}
                {chargesGst && (
                  <tr>
                    <td
                      className='border-0 font-weight-bold pb-0 align-middle'
                      colSpan={5}
                      align='right'
                    >Subotal</td>
                    <td className='border-0 font-weight-bold pb-0 align-middle'>
                      {subtotalTasks}
                    </td>
                  </tr>
                )}
                {chargesGst && (
                  <tr>
                    <td
                      className='border-0 font-weight-bold pb-0 align-middle'
                      colSpan={5}
                      align='right'
                    >GST</td>
                    <td className='border-0 font-weight-bold pb-0 align-middle'>
                      {gstTasks}
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    className='border-0 font-weight-bold pb-0 align-middle'
                    colSpan={5}
                    align='right'
                  >Total</td>
                  <td className='border-0 font-weight-bold pb-0 align-middle'>
                    {totalTasks}
                  </td>
                </tr>
                <tr>
                  <td className='border-0 pb-0' colSpan={6} />
                  <td className='border-0 pb-0 align-middle'>
                    <button
                      className='btn btn-primary'
                      disabled={totalTasks <= 0}
                      onClick={this.onInvoice}
                    >Invoice</button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Card>
        <Card className='p-3 mt-3'>
          <div className='font-weight-bold'>Invoices ({invoices.length})</div>
          {invoices.length === 0 && (
            <div className='text-center text-secondary'>No invoices found.</div>
          )}
          {invoices.length !== 0 && (
            <table className='table mb-0 mt-3'>
              <thead>
                <tr>
                  <th className='border-0 py-0'>#</th>
                  <th className='border-0 py-0'>Create date</th>
                  <th className='border-0 py-0'>Pay date</th>
                  <th className='border-0 py-0'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((i, j) => (
                  <React.Fragment key={j}>
                    <tr>
                      <td className='p-1 border-0' />
                    </tr>
                    <Task alert={i.payDate === null}>
                      <td>{i.id}</td>
                      <td>{i.createDateStr}</td>
                      <td>{i.payDateStr}</td>
                      <td>{i.amountStr}</td>
                    </Task>
                  </React.Fragment>
                ))}
                {chargesGst && (
                  <tr>
                    <td
                      className='border-0 font-weight-bold pb-0 align-middle'
                      colSpan={3}
                      align='right'
                    >Subotal</td>
                    <td className='border-0 font-weight-bold pb-0 align-middle'>
                      {subtotalInvoices}
                    </td>
                  </tr>
                )}
                {chargesGst && (
                  <tr>
                    <td
                      className='border-0 font-weight-bold pb-0 align-middle'
                      colSpan={3}
                      align='right'
                    >GST</td>
                    <td className='border-0 font-weight-bold pb-0 align-middle'>
                      {gstInvoices}
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    className='border-0 font-weight-bold pb-0 align-middle'
                    colSpan={3}
                    align='right'
                  >Total</td>
                  <td className='border-0 font-weight-bold pb-0 align-middle'>
                    {totalInvoices}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Card>
      </Container>
    );
  }
};
