import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;

export default class ReportsStormActivity extends React.Component {
  constructor(opts) {
    super(opts);
    this.onSearch = this.onSearch.bind(this);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onSearch() {
    const {stormId, onLoad} = this.props;
    onLoad(stormId);
  }
  render() {
    console.log('stormactivity:render');
    const {
      notFound,
      storms,
      stormId,
      report,
    } = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load report.</div>
        </ErrorContainer>
      );
    }
    return (
      <React.Fragment>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Storm</label>
          <div className='col-sm-4'>
            <Select
              background={colors.grey1}
              placeholder='select a storm'
              selected={stormId}
              options={storms}
              textField='name'
              onChange={this.props.onChangeStormId}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4 offset-sm-2'>
            <SearchButton
              type='button'
              className='btn btn-primary'
              disabled={stormId === null}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3 mt-4'>
            {report === null && (
              <div className='text-center text-secondary'>Report not found.</div>
            )}
            {report !== null && report.map((r, i) => r.count !== undefined
              ? (
                <div className='row py-1' key={i}>
                  <div className='col-4 col-sm-3 font-weight-bold'>{r.title}</div>
                  <div className='col-3 col-sm-2'>{r.count}{r.amount !== undefined && (
                    <span> | {r.amount}</span>
                  )}</div>
                  {r.pct !== undefined && (
                    <div className='col-5 col-sm-3 my-1'>
                      <div className='progress'>
                        <div className="progress-bar" style={{width: `${r.pct}%`}} />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                r.type === 'break'
                  ? <div className='py-3' key={i} />
                  : (
                    <div className='row my-1' key={i}>
                      <div className='col-4 col-sm-3 bg-dark' style={{height: '1px'}} />
                      <div className='col-3 col-sm-2 bg-dark' style={{height: '1px'}} />
                      <div className='col-5 col-sm-3 bg-dark' style={{height: '1px'}} />
                    </div>
                  )
              )
            )}
          </Card>
        )}
      </React.Fragment>
    );
  };
};

