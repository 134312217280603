import React from 'react';

import colors from 'colors';

import Select from 'components/Select.jsx';


export default ({locationId, locations, onChange}) => (
  <Select
    align='right'
    background={colors.grey1}
    textField='name'
    options={locations}
    selected={locationId}
    onChange={onChange}
  />
);
