import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import ReportsStormActivity from './component.jsx';


const load = (API, stormId, start, finish) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    const url = new URL(`${env.API_SERVER}/v1/internal/reports/storm-category/${stormId}`);
    url.searchParams.append('start', start);
    url.searchParams.append('finish', finish);
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-REPORTS-STORM-CATEGORY-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'ADMIN-REPORTS-STORM-CATEGORY-SET',
          ...resp
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-REPORTS-STORM-CATEGORY-SET-NULL'
});

const change = c => ({
  type: 'ADMIN-REPORTS-STORM-CATEGORY-CHANGE',
  change: c,
});


const mapStateToProps = (state, props) => {
  const {
    adminReportsStormCategory: {notFound, stormId, start, finish, report},
    params: {
      storms: {list: storms},
    }
  } = state;
  return {
    notFound,
    storms,
    stormId,
    start,
    finish,
    report,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: (stormId, start, finish) => dispatch(load(props.API, stormId, start, finish)),
  onUnload: () => dispatch(unload()),
  onChange: c => dispatch(change(c)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportsStormActivity)
);
