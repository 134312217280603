const OUT = 0.81;


export default side => ({
  panelCode: 'A',
  lineItems: [
    {
      description: `${side}F GUARD`,
      panel: `${side}F-GUARD`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD REPEATER LAMP`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD MOULD`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.22,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD WHEEL ARCH MOULD`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.12,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD BADGE`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.1,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD SPLASH SHIELD CLIPS`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD QTR GLASS MOULD`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD HARDWARE`,
      panel: `${side}F-GUARD`,
      subsetOf: [`${side}F GUARD RR`],
      settings: {
        [`BONNET|${side}F-DOOR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.59,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        },
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.42,
                rrAutoPaintable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `${side}F GUARD RR`,
      panel: `${side}F-GUARD`,
      supersetOf: [`${side}F GUARD HARDWARE`],
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.72,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD OUT`,
      panel: `${side}F-GUARD`,
      supersetOf: [`${side}F GUARD BLEND`],
      subsetOf: [`${side}F GUARD IN & OUT`],
      settings: {
        [`${side}F-GUARD`]: {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.9,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
              }
            }
          },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  paintTime: 2.1,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `${side}F GUARD IN & OUT`,
      panel: `${side}F-GUARD`,
      supersetOf: [`${side}F GUARD OUT`, `${side}F GUARD BLEND`],
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.9,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.06,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD BLEND`,
      panel: `${side}F-GUARD`,
      subsetOf: [`${side}F GUARD IN & OUT`, `${side}F GUARD OUT`],
      settings: {
        [`BONNET|${side}F-DOOR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 1.9,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD LOADING 10`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD LOADING 25`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side}F GUARD REALIGNMENT`,
      settings: {
        [`${side}F-GUARD`]: {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 100,
              }
            }
          },
          },
          '^PRIXCAR': {
            '[^]*': { /* pricing method */
              '[^]*': { /* body shape */
                '[^]*': { /* paint type */
                  rrTime: 1,
                  hourlyRate: 100,
                }
              }
            },
          },
        }
      }
    },
    {
      description: `PDR ${side}F GUARD`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BUFF & POLISH ${side}F GUARD`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BRUSH TOUCH ${side}F GUARD`,
      settings: {
        [`${side}F-GUARD`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
});
