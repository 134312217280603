/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';
import moment from 'm';


const Input = styled.div`
  > input {
    width: ${opts => opts.width};
    box-shadow: none;
    color: ${opts => opts.disabled ? colors.grey4 : opts.color};
    background-color: ${opts => opts.background ? opts.background : colors.grey2} !important;
    border-radius: 0.5rem;
    cursor: pointer;
    border-color: ${opts => opts.error ? colors.red : (opts.background ? opts.background : colors.grey2)};
  }
`;
const Caret = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  font-size: .63rem;
  line-height: 2.5rem;
  height: 2.5rem;
`;
const Table = styled.div`
  position: absolute;
  ${opts => opts.align === 'right' && 'right: 0px;'}
  ${opts => opts.align !== 'right' && 'left: 0px;'}
  opacity: 1;
  display: block;

  font-size: 0.8rem;

  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  background-color: #fff;
  margin: 0px;
  max-height: 40rem;
  overflow-y: auto;
  z-index: 999;

  color: ${colors.body};
  svg {
    stroke: ${colors.primary};
  }
`;
const Clear = styled.div`
  cursor: pointer;
  font-weight: bold;
  color: ${colors.primary};
`;
const Cell = styled.div`
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: ${colors.grey4};
  ${({today}) => today && `
    font-weight: bold;
    font-size: 1rem;
  `}
  ${({blocked, canSelectBlocked, publicHoliday}) => (!blocked || canSelectBlocked) && !publicHoliday && `
    cursor: pointer;
  `}
  ${({current}) => current && `
    color: ${colors.body};
  `}
  ${({blocked, publicHoliday}) => (blocked || publicHoliday) && `
    color: #fff;
    background: ${colors.red};
  `}
  ${({disabled}) => disabled && `
    color: ${colors.grey4};
    background: #fff;
  `}
  ${({selected}) => selected && `
    color: #fff;
    background: ${colors.primary};
  `}
`;
const CellHeader = styled(Cell)`
  font-weight: bold;
  color: ${colors.body};
  cursor: default;
`;


export default class CalendarInput extends React.Component {
  constructor(opts) {
    super(opts);
    const month =
      moment(
        this.props.selected ? this.props.selected * 1000 : Date.now()
      );
    this.state = {
      open: false,
      ...this.update(month)
    };
    this.container = React.createRef();
    this.onClick = this.onClickHandler.bind(this);
    this.onClear = this.onClear.bind(this);
  }
  componentDidMount() {
    document.addEventListener('click', this.onClick);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onClick);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.selected !== this.props.selected ||
      prevProps.blockedDays !== this.props.blockedDays ||
      prevProps.publicHolidays !== this.props.publicHolidays ||
      prevProps.numberOfDays !== this.props.numberOfDays
    ) {
      const month =
        moment(
          this.props.selected ? this.props.selected * 1000: Date.now()
        );
      this.setState({
        ...this.state,
        ...this.update(month)
      });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.selected !== nextProps.selected ||
      this.props.disabled !== nextProps.disabled ||
      this.props.blockedDays !== nextProps.blockedDays ||
      this.props.publicHolidays !== nextProps.publicHolidays ||
      this.props.numberOfDays !== nextProps.numberOfDays ||
      this.props.error !== nextProps.error ||
      this.state.open !== nextState.open ||
      this.state.monthStr !== nextState.monthStr ||
      this.state.value !== nextState.value
    );
  }
  onClickHandler(e) {
    if (
      this.container &&
      this.state.open === true
    ) {
      if (!this.container.current.contains(e.target)) {
        this.setState({
          ...this.state,
          open: false
        });
      }
    }
  }
  update(month, selectedDate) {
    const publicHolidays = (this.props.publicHolidays || {});
    const currentState = (this.props.currentState || 'ALL');
    const blockedDays = (this.props.blockedDays || []).reduce((acc, d) => {
      acc[d] = true;
      return acc;
    }, {});
    let startDate = null;
    let startDateTs = null;
    let finishDate = null;
    const selected = {};
    if (selectedDate || this.props.selected) {
      startDate = moment((selectedDate || this.props.selected) * 1000);
      startDateTs = startDate.unix();
      let count = this.props.numberOfDays || 0;
      if (count > 0) {
        const d = moment(startDate);
        let dTs = d.unix();
        do {
          selected[dTs] = true;
          finishDate = moment(d);
          do {
            d.add(1, 'day');
            dTs = d.unix();
          } while (
            d.day() === 0 ||
            d.day() === 6 ||
            (publicHolidays[currentState] && publicHolidays[currentState][dTs] && (publicHolidays[currentState][dTs] === true))
          );
          count--;
        } while (count > 0);
      } else {
        selected[startDate.unix()] = true;
      }
    }

    const now = moment().hour(0).minute(0).second(0).millisecond(0);
    const nowTs = now.unix();
    const fom = moment(month).hour(0).minute(0).second(0).millisecond(0).date(1).day(0);
    const lom = moment(month).hour(0).minute(0).second(0).millisecond(0).add(1, 'month').date(1).day(6);

    let w = null;
    const m = month.month();
    const weeks = [];
    while (fom <= lom) {
      if (fom.week() !== w) {
        w = fom.week();
        weeks.push([]);
      }
      const value = fom.unix();
      weeks[weeks.length - 1].push({
        value,
        date: fom.date(),
        today: value === nowTs,
        inThePast: value < nowTs,
        dow: fom.day(),
        blocked: blockedDays[value] === true,
        publicHoliday: (publicHolidays[currentState] && publicHolidays[currentState][value] && (publicHolidays[currentState][value] === true)),
        selectedStartDate: value === startDateTs,
        selected: selected[value] === true,
        currentMonth: fom.month() === m
      });
      fom.add(1, 'day');
    }
    let value = '';
    if (this.props.showRange !== false && startDate !== null && finishDate !== null) {
      if (startDate.year() === now.year()) {
        value = `${startDate.format('DD-MM')} – ${finishDate.format('DD-MM')}`;
      } else {
        value = `${startDate.format('DD-MM-YYYY')} – ${finishDate.format('DD-MM-YYYY')}`;
      }
    } else if (startDate !== null) {
      if (startDate.year() === now.year()) {
        value = startDate.format('DD-MM');
      } else {
        value = startDate.format('DD-MM-YYYY');
      }
    }
    return {
      month,
      monthStr: month.format('MMM YYYY'),
      weeks,
      startDate,
      finishDate,
      value
    };
  }
  onToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.readOnly === true || this.props.disabled === true) return;
    this.setState({
      ...this.state,
      open: !this.state.open
    });
  }
  onScrollMonth(delta, e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      ...this.state,
      ...this.update(this.state.month.add(delta, 'month'))
    });
  }
  onSelect(id, blockedOrWeekend, e) {
    e.preventDefault();
    e.stopPropagation();
    if (blockedOrWeekend === true) return;
    const upd = this.update(this.state.month, id);
    this.setState({
      ...this.state,
      open: false,
      //...upd
    });
    const {startDate, finishDate} = upd;
    if (startDate !== null && finishDate !== null) {
      this.props.onChange({
        startDate: startDate.unix(),
        finishDate: finishDate.unix()
      });
    } else if (startDate !== null) {
      this.props.onChange(startDate.unix());
    }
  }
  onClear() {
    const {numberOfDays, onChange} = this.props;
    if (numberOfDays !== undefined) {
      onChange({startDate: null, finishDate: null});
    } else {
      onChange(null);
    }
  }
  render() {
    const {
      align,
      className,
      title,
      placeholder,
      selected,
      showClear,
      disabled,
      disableDaysInThePast,
      canSelectBlocked,
      showRange,
      error,
      color,
      background,
      width,
      enabledDows = {0: false, 1: true, 2: true, 3: true, 4: true, 5: true, 6: false},
    } = this.props;
    const {open, value, monthStr, weeks} = this.state;
    return (
      <div ref={this.container} className={className}>
        {title && (<small className='text-muted'>{title}</small>)}
        <Input
          className='position-relative m-0'
          width={width}
          color={color}
          background={background}
          error={error}
          disabled={disabled}
        >
          <Caret
            onClick={this.onToggle.bind(this)}
          ><Icons.ChevronDown color={disabled ? colors.grey4 : color} /></Caret>
          <input
            type='text'
            className='form-control pl-0 mb-0'
            readOnly={true}
            value={value}
            placeholder={placeholder}
            onClick={this.onToggle.bind(this)}
          />
          <Table className={open === true ? 'px-1 py-2' : 'd-none'} align={align}>
            <div className='d-flex flex-row justify-content-between p-1'>
              <a
                href='#'
                onClick={this.onScrollMonth.bind(this, -1)}
              >
                <Icons.ChevronLeft width={18} height={18} />
              </a>
              <div className='font-weight-bold'>{monthStr}</div>
              <a
                href='#'
                onClick={this.onScrollMonth.bind(this, 1)}
              >
                <Icons.ChevronRight width={18} height={18} />
              </a>
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <CellHeader>S</CellHeader>
              <CellHeader>M</CellHeader>
              <CellHeader>T</CellHeader>
              <CellHeader>W</CellHeader>
              <CellHeader>T</CellHeader>
              <CellHeader>F</CellHeader>
              <CellHeader>S</CellHeader>
            </div>
            {
              weeks.map((w, i) => (
                <div className='d-flex flex-row justify-content-between' key={i}>
                  {
                    w.map((d, j) => (
                      <Cell
                        key={j}
                        today={d.today}
                        selected={d.selected && (showRange !== false || d.selectedStartDate === true)}
                        blocked={d.blocked}
                        canSelectBlocked={canSelectBlocked}
                        publicHoliday={d.publicHoliday}
                        disabled={enabledDows[d.dow] !== true || (disableDaysInThePast === true && d.inThePast === true)}
                        current={d.currentMonth}
                        onClick={this.onSelect.bind(
                          this,
                          d.value,
                          (!canSelectBlocked && d.blocked) ||
                          d.publicHoliday ||
                          enabledDows[d.dow] !== true ||
                          (
                            !canSelectBlocked &&
                            (disableDaysInThePast === true && d.inThePast === true)
                          )
                        )}
                      >{d.date}</Cell>
                    ))
                  }
                </div>
              ))
            }
            {showClear && selected && (
              <Clear className='text-center p-2' onClick={this.onClear}>clear</Clear>
            )}
          </Table>
        </Input>
      </div>
    );
  }
};
