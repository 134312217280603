import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';
import LocationSelect from 'components/LocationSelect';
import Popover from 'components/Popover.jsx';

import Detail from './Detail.jsx';
import HighCostJob from './HighCostJob.jsx';
import Job from './Job.jsx';
import Pickup from './Pickup.jsx';
import Production from './Production.jsx';
import Tech from './Tech.jsx';
import Todo from './Todo.jsx';


const TASK_COMPONENTS = {
  inbox: Todo,
  outbox: Todo,
  productionStrip: Production('strip'),
  productionRepair: Production('repair'),
  strip: Tech,
  fitup: Tech,
  repair: Tech,
  paint: Tech,
  detail: Detail,
  qa: Tech,
  pickup: Pickup,
  arrive: Job(false),
  excess: Job(false),
  highCost: HighCostJob,
  authority: Job(false),
  boPartDelivery: Job(false),
  boPartBooking: Job(false),
  boPartFitment: Job(false),
  outstandingCredits: Job(true),
  partNotOrdered: Job(true),
  partNotReceived: Job(true),
  partNotFitted: Job(true),
  cancelled: Job(false),
  toBook: Job(false),
  new: Job(false),
  assessNoShow: Job(false),
  repairNoShow: Job(false),
  waitingAssessment: Job(false),
  assessingToday: Job(false),
};

const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  overflow-y: auto;
  font-size: 0.9rem;
`;
const Button = styled.button`
  & svg {
    stroke: #fff;
  }
`;
const Section = styled.div`
  border-radius: 0.5rem;
  background-color: ${opts => opts.empty ? 'inherit' : colors.grey1};
  box-shadow: ${opts => opts.open ? '0px 8px 15px -4px rgba(180, 180, 180, 0.5)' : 'none'};
  :last-of-type {
    margin-bottom: 0px !important;
  }
`;
const SectionHeader = styled.div`
  cursor: pointer;
`;
const SectionTitle = styled.h6`
  color: ${colors.primary};
`;
const TaskEmployee = styled.div`
  cursor: pointer;
  font-weight: ${opts => opts.selected ? 'bold' : 'normal'};
  :hover {
    background: ${colors.grey1};
  }
`;


export default class Tasks extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      popover: null
    };
    this.onProductionEmployeeClose = this.onProductionEmployeeClose.bind(this);
    this.onProductionEmployeeToggle = this.onProductionEmployeeToggle.bind(this);
  }
  componentDidMount() {
    const {locationId, onLoad} = this.props;
    if (locationId) {
      onLoad(locationId);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.locationId && this.props.locationId !== prevProps.locationId) {
      this.props.onLoad(this.props.locationId);
    }
  }
  onToggleSection(section) {
    this.props.onToggleSection(section);
  }
  onProductionEmployeeClose() {
    this.setState({
      popover: null
    });
  }
  onProductionEmployeeToggle(jobId, locationId, stage, selected, ref) {
    if (this.state.popover !== null && this.state.popover.ref === ref) {
      this.setState({
        popover: null
      });
    } else {
      const locationAbilityList = this.props.locationAbilityLists[locationId];
      const employees = locationAbilityList[stage];
      this.setState({
        popover: {
          jobId,
          locationId,
          stage,
          employees,
          selected,
          ref
        }
      });
    }
  }
  onProductionEmployeeSelect(employeeId) {
    const {popover:{jobId, locationId, stage}} = this.state;
    const {onSetProductionEmployee} = this.props;
    onSetProductionEmployee({jobId, locationId, stage, employee: employeeId});
    this.setState({popover: null});
  }
  renderTasks(key, task, i) {
    const TaskComponent = TASK_COMPONENTS[key];
    if (!TaskComponent) return null;
    const {
      employeeIndex,
      locationIndex,
      onToggleDone,
      onChangeDateTime,
      onChangeNote,
      onAddComment,
    } = this.props;

    return (
      <TaskComponent
        key={i}
        type={key}
        task={task}
        employees={employeeIndex}
        locations={locationIndex}
        onToggleDone={onToggleDone}
        onChangeDateTime={onChangeDateTime}
        onChangeNote={onChangeNote}
        onAddComment={onAddComment}
        onToggleEmployees={this.onProductionEmployeeToggle}
      />
    );
  }
  renderSection({key, title, tasks}, i) {
    const {sectionStates} = this.props;
    const open = (sectionStates[key] === true);
    const Chevron = open ? Icons.ChevronUp : Icons.ChevronDown;

    if (tasks.length === 0) {
      return (
        <Section key={i} className='p-2 mb-2' empty={true}>
          <h6 className='mb-0 mx-1'>{title} (0)</h6>
        </Section>
      );
    }
    return (
      <Section key={i} className='p-2 mb-2' open={open}>
        <SectionHeader
          className='d-flex flex-row justify-content-between align-items-center'
          onClick={this.onToggleSection.bind(this, key)}
        >
          <SectionTitle className='mb-0 mx-1'>{title} ({tasks.length})</SectionTitle>
          <Chevron color={colors.primary} />
        </SectionHeader>
        <div className='row no-gutters'>
          {open === true && tasks.map(this.renderTasks.bind(this, key))}
        </div>
      </Section>
    );
  }
  render() {
    console.log('tasks:render');
    const {notFound, sections, onSave} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load tasks.</div>
        </ErrorContainer>
      );
    }
    const {popover} = this.state;
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h5 className='mb-0'>Tasks</h5>
          <div className='d-flex flex-row align-items-center'>
            <LocationSelect />
            <Button
              type='button'
              className='btn btn-secondary px-3 ml-2 d-none d-lg-block'
              disabled={sections.length === 0}
              onClick={onSave.bind(null, sections)}
            ><Icons.Download width={18} height={18} /></Button>
          </div>
        </div>
        {sections.length !== 0 && sections.map(this.renderSection.bind(this))}
        {popover &&
          <Popover
            target={popover.ref}
            onClose={this.onProductionEmployeeClose}
          >
            <TaskEmployee
              className='text-secondary p-2'
              selected={popover.selected === null}
              onClick={this.onProductionEmployeeSelect.bind(this, null)}
            >N/A</TaskEmployee>
            {popover.employees.map((e, i) => (
              <TaskEmployee
                key={i}
                className='p-2'
                selected={popover.selected === e.id}
                onClick={this.onProductionEmployeeSelect.bind(this, e.id)}
              >{e.name}</TaskEmployee>
            ))}
          </Popover>
        }
      </Container>
    );
  }
};
