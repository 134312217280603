import moment from 'moment';
import numeral from 'numeral';


const COLUMNS = [
  {id: 'jobNumber', type: 'id', title: 'Job #'},
  {id: 'isPrimary', type: 'bool', title: 'Primary'},
  {id: 'includeGST', type: 'bool', title: 'GST'},
  {id: 'rrTotal', type: 'money', title: 'RR'},
  {id: 'pdrTotal', type: 'money', title: 'PDR'},
  {id: 'repairTotal', type: 'money', title: 'Repair'},
  {id: 'paintTotal', type: 'money', title: 'Paint'},
  {id: 'partTotal', type: 'money', title: 'Part'},
  {id: 'miscTotal', type: 'money', title: 'Misc'},
  {id: 'subletTotal', type: 'money', title: 'Sublet'},
  {id: 'subtotal', type: 'money', title: 'Total (excl GST)'},
  {id: 'total', type: 'money', title: 'Total (inc GST)'},
  {id: 'strip', type: 'money', title: 'Tech strip'},
  {id: 'repair', type: 'money', title: 'Tech repair'},
  {id: 'fitup', type: 'money', title: 'Tech fitup'},
];

export default (state = {notFound: null, report: null}, action) => {
  switch (action.type) {
    case 'ADMIN-REPORTS-LOCATION-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        report: null
      };
    case 'ADMIN-REPORTS-LOCATION-SET-NULL':
      return {
        ...state,
        notFound: null,
        report: null,
      };
    case 'ADMIN-REPORTS-LOCATION-SET':
      {
        const {report} = action;
        const columns = COLUMNS.map(c => c.title);
        const {rows, totals: _totals} = report.reduce((acc, r, i) => {
          const values = [];
          COLUMNS.forEach(({id, type, title}) => {
            let v = r[id];
            if (type === 'id') {
              v = {
                id: r.id,
                v,
              };
            } else if (type === 'bool') {
              v = {v: v ? 'Y' : 'N'};
            } else if (type === 'date') {
              v = {
                v: v ? moment(v * 1000).format('DD-MM-YYYY') : null,
              };
            } else if (type === 'money') {
              const amnt = v / 100;
              v = {v: numeral(amnt).format('$0,0.00')};
              if (!acc.totals.hasOwnProperty(title)) {
                acc.totals[title] = 0;
              }
              acc.totals[title] += amnt;
            } else {
              v = {v};
            }
            values.push(v);
          });
          acc.rows.push(values);
          return acc;
        }, {rows: [], totals: {}});
        const totals = columns.map(c => {
          if (_totals.hasOwnProperty(c)) {
            return numeral(_totals[c]).format('$0,0.00');
          }
          return '';
        });
        return {
          ...state,
          notFound: false,
          report: {
            columns,
            rows,
            totals,
          },
        };
      }
    default:
      return state;
  }
};
