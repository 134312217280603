import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';


const Button = styled.div`
  cursor: pointer;
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;

  & svg {
    stroke: ${colors.body};
  }
  :hover svg {
    stroke: ${colors.primary};
  }
`;
const CountContainer = styled.div`
  position: absolute;
  line-height: 22px;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  right: 0px;
  top: 0px;
  background: ${colors.red};
  color: white;
  text-align: center;
`;


export default class Add extends React.Component {
  constructor(opts) {
    super(opts);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const {onAssessmentInbox} = this.props;
    onAssessmentInbox()
      .then(res => {
        if (res !== true) {
          toast.error('There are no jobs remaining to be booked for assessment.');
        } else {
          this.setState({assessmentJobId: res.jobId});
        }
    });
  }

  render() {
    console.log('renderAssessmentInbox')
    const {canViewAssessmentsInbox, numOpenJobs} = this.props;
    if (!canViewAssessmentsInbox) return null;
    return (
      <React.Fragment>
        <Button onClick={this.onClick} className='position-relative'>
          <Icons.Inbox />
          <CountContainer className='small mt-2 mr-2'>{numOpenJobs || '–'}</CountContainer>
        </Button>
      </React.Fragment>
    );
  }
};
