import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Conventional from './component.jsx';


const load = (API, locationId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/conventional/${locationId}`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'CONVENTIONAL-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'CONVENTIONAL-SET',
          jobs: resp.jobs,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const change = (API, jobId, changes) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/conventional`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({jobId, ...changes})
      }
    )
      .then(resp => {
        if (resp.error === true) return false;
        dispatch({
          type: 'CONVENTIONAL-JOB-UPDATE',
          jobId,
          jobData: resp
        });
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const toggleFilter = filter => ({
  type: 'CONVENTIONAL-FILTER-SET',
  filter,
});

const toggleSection = section => ({
  type: 'CONVENTIONAL-SECTION-TOGGLE',
  section,
});

const togglePainter = painter => ({
  type: 'CONVENTIONAL-PAINTER-TOGGLE',
  painter,
});

const toggleJob = jobId => ({
  type: 'CONVENTIONAL-JOB-TOGGLE',
  jobId
});


const mapStateToProps = (state, props) => {
  const {
    locationId,
    params: {
      employees: {index: employeeIndex},
      locations: {
        abilityLists
      },
    },
    conventional: {
      notFound,
      sectionStates,
      painterStates,
      sections,
      jobIndex,
      filter,
    }
  } = state;
  return {
    notFound,
    sectionStates,
    painterStates,
    sections,
    jobIndex,
    filter,
    painters: (abilityLists[locationId] || {paint: []}).paint,
    locationId,
    employeeIndex,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: locationId => dispatch(load(API, locationId)),
  onToggleSection: section => dispatch(toggleSection(section)),
  onTogglePainter: painter => dispatch(togglePainter(painter)),
  onToggleJob: jobId => dispatch(toggleJob(jobId)),
  onChange: (jobId, changes) => dispatch(change(API, jobId, changes)),
  onToggleFilter: filter => dispatch(toggleFilter(filter)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Conventional)
);
