import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Logs from './component.jsx';


const load = (API, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/logs`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-LOGS-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'JOB-LOGS-SET',
          logs: resp.logs
        });
        dispatch({
          type: 'JOB-LOGS-SET-FOUND',
          jobId,
          jobNumber: resp.jobNumber,
          flagged: resp.flagged,
          vpp: resp.vpp,
          vip: resp.vip,
          importedFromArms: resp.importedFromArms,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-LOGS-SET-NULL'
});

const mapStateToProps = (state, props) => {
  const {jobId} = props;
  let jobValid = null;
  if (state.jobLogs.notFound === true)
    jobValid = false;
  else if (state.jobLogs.notFound === false) {
    jobValid = true;
  }
  if (jobValid !== true) {
    return {
      jobValid,
    };
  }
  const {
    jobLogs: {jobNumber, flagged, vpp, vip, importUrl, logs},
    params: {employees: {index: employeesIndex}}
  } = state;
  return {
    jobValid,
    jobId,
    jobNumber,
    flagged,
    vpp,
    vip,
    importUrl,
    logs,
    employeesIndex
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: jobId => dispatch(load(props.API, jobId)),
  onUnload: () => dispatch(unload()),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Logs)
);
