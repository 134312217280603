import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import ExpandToggle from 'icons/expand-toggle.jsx';
import colors from 'colors';


const Input = styled.input`
  border-color: ${opts => opts.error ? colors.red : '#fff'} !important;
  text-transform: ${opts => opts.uppercaseOnly ? 'uppercase' : 'none'};
  :read-only {
    background-color: ${colors.grey2} !important;
    border-color: ${colors.grey2} !important;
  }
`;
const Textarea = styled.textarea`
  border-color: ${opts => opts.error ? colors.red : '#fff'} !important;
`;
const DeleteButton = styled(Icons.X)`
  cursor: pointer;
  stroke: ${colors.red};
`;
const Tr = styled.tr`
  input, input:read-only {
    ${opts => opts.danger && `color: ${colors.red} !important;`}
    ${opts => opts.warning && `
      background-color: ${colors.orangeExtraLight} !important;
      border-color: ${colors.orangeExtraLight} !important;
    `}
  }
`;


export default class PDRRow extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      expanded: false,
    };
    this.onDelete = this.onDelete.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.p2pId = `pdr-p2p-${opts.index}`;
    this.categoryExtraId = `pdr-categoryextra-${opts.index}`;
    this.onReportId = `pdr-onreport-${opts.index}`;
    this.commentId = `pdr-comment-${opts.index}`;
    this.ormCommentId = `rr-orm-comment-${opts.index}`;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state !== nextState ||
      this.props.description !== nextProps.description ||
      this.props.qty !== nextProps.qty ||
      this.props.size !== nextProps.size ||
      this.props.total !== nextProps.total ||
      this.props.deleted !== nextProps.deleted ||
      this.props.comments !== nextProps.comments ||
      this.props.ormComments !== nextProps.ormComments ||
      this.props.ormStatus !== nextProps.ormStatus ||
      this.props.p2p !== nextProps.p2p ||
      this.props.categoryExtra !== nextProps.categoryExtra ||
      this.props.source !== nextProps.source ||
      this.props.onReport !== nextProps.onReport
    );
  }
  onToggle() {
    this.setState({
      expanded: !this.state.expanded
    });
  }
  onDelete() {
    const {index, onDelete} = this.props;
    onDelete(index);
  }
  onChange(field, e) {
    const {index, onChange} = this.props;
    if (field === 'p2p' || field === 'categoryExtra'|| field === 'onReport') {
      onChange('pdr', index, {
        [field]: e.target.checked
      });
    //} else if (field === 'ormAccept') {
    //  onChange('pdr', index, {
    //    hours: ormHours,
    //    rate: ormRate,
    //    ormStatus: ['DELETED', 'ACKNOWLEDGEDDELETE'].indexOf(ormStatus) !== -1 ? 'ACKNOWLEDGEDDELETE' : 'ACCEPTED',
    //  });
    } else {
      onChange('pdr', index, {
        [field]: e.target.value
      });
    }
  }
  render() {
    const {expanded} = this.state;
    const {
      isLast,
      desc,
      qty,
      size,
      p2p,
      deleted,
      comments,
      categoryExtra,
      totalStr,
      highCount,
      showPrices,
      showDelete,
      hasOrm,
      onReport,
      ormStatus,
      ormHours,
      ormRate,
      ormTotalStr,
      ormComments,
    } = this.props;
    if (deleted === true) return null;
    const ormChanged = hasOrm === true && ['CHANGED', 'DELETED'].indexOf(ormStatus) !== -1;
    let _ormStatus = onReport === true ? 'ONREPORT' : (hasOrm ? ormStatus : null);
    return (
      <React.Fragment>
        <Tr danger={highCount === true} warning={categoryExtra === true}>
          <td>
            {!isLast &&
              <ExpandToggle
                className='cursor-pointer'
                onClick={this.onToggle}
                expanded={expanded}
                status={_ormStatus}
                width={18}
                height={18}
              />
            }
          </td>
          <td className='pr-1'>
            <Input
              type='text'
              className='form-control p-0 m-0 text-uppercase'
              value={desc || ''}
              readOnly={true}
            />
          </td>
          <td className='px-1'>
            <Input
              type='text'
              className='form-control p-0 m-0 text-uppercase text-center'
              value={qty || ''}
              readOnly={true}
            />
          </td>
          <td className='px-1'>
            <Input
              type='text'
              className='form-control p-0 m-0 text-uppercase text-center'
              value={size || ''}
              readOnly={true}
            />
          </td>
          {showPrices && (
            <td className='px-1'>
              <Input
                type='text'
                className='form-control p-0 m-0 text-uppercase text-center'
                value={totalStr || ''}
                readOnly={true}
              />
            </td>
          )}
          {showDelete && (
            <td className='text-right pl-2'>
              {!isLast && <DeleteButton
                onClick={this.onDelete}
                width={16} height={16} />
              }
            </td>
          )}
        </Tr>
        {expanded &&
          <tr>
            <td />
            <td colSpan={1 + (ormChanged === false && (showPrices === true ? 3 : 2))}>
              <div className='d-flex flex-row align-items-center mt-2'>
                <div className='form-check mr-3'>
                  <Input
                    className='form-check-input'
                    type='checkbox'
                    checked={p2p}
                    id={this.p2pId}
                    onFocus={this.onFocus}
                    onChange={this.onChange.bind(this, 'p2p')}
                  />
                  <label className='form-check-label' htmlFor={this.p2pId}>
                    <small>P2P</small>
                  </label>
                </div>
                <div className='form-check mr-3'>
                  <Input
                    className='form-check-input'
                    type='checkbox'
                    checked={categoryExtra}
                    id={this.categoryExtraId}
                    onFocus={this.onFocus}
                    onChange={this.onChange.bind(this, 'categoryExtra')}
                  />
                  <label className='form-check-label' htmlFor={this.categoryExtraId}>
                    <small>Category extra</small>
                  </label>
                </div>
                {hasOrm === true && (
                  <div className='form-check mr-3'>
                    <Input
                      className='form-check-input'
                      type='checkbox'
                      checked={onReport}
                      id={this.onReportId}
                      onFocus={this.onFocus}
                      onChange={this.onChange.bind(this, 'onReport')}
                    />
                    <label className='form-check-label' htmlFor={this.onReportId}>
                      <small>On report</small>
                    </label>
                  </div>
                )}
              </div>
              <div className='mb-2'>
                <label
                  className='position-static mb-0'
                  htmlFor={this.commentId}
                ><small>Comments</small></label>
                <Textarea
                  id={this.commentId}
                  type='text'
                  rows={2}
                  className='form-control p-0'
                  onFocus={this.onFocus}
                  onChange={this.onChange.bind(this, 'comments')}
                  value={comments}
                />
              </div>
              {hasOrm === true && (
                <div className='mb-2'>
                  <label
                    className='position-static mb-0'
                    htmlFor={this.ormCommentId}
                  ><small>ORM Comments</small></label>
                  <Textarea
                    id={this.ormCommentId}
                    type='text'
                    rows={2}
                    className='form-control p-0'
                    onFocus={this.onFocus}
                    onChange={this.onChange.bind(this, 'ormComments')}
                    value={ormComments}
                  />
                </div>
              )}
            </td>
            {ormChanged === true && showPrices === true && (
              <td colSpan={3} className='align-top'>
                <table
                  className='w-100 mt-2'
                  style={{textDecoration: ormStatus === 'DELETED' ? 'line-through' : 'inherit'}}
                >
                  <tbody>
                    <tr>
                      <td width={80} className='text-center'>
                        {ormHours}
                      </td>
                      <td width={80} className='text-center'>
                        {ormRate}
                      </td>
                      <td width={80} className='text-center'>
                        {ormTotalStr}
                      </td>
                    </tr>
                    {false && (
                      <tr>
                        <td className='text-right' colSpan={3}>
                          <button
                            type='button'
                            className={ormStatus === 'DELETED' ? 'btn btn-danger ml-1 mt-2' : 'btn btn-success ml-1 mt-2'}
                          >Accept</button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>
            )}
            {showDelete && (
              <td />
            )}
          </tr>
        }
      </React.Fragment>
    );
  }
};
