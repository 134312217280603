const OUT = 0.55;


export default side => ({
  panelCode: 'A',
  lineItems: [
    {
      description: `${side} CAB PILLAR`,
      panel: `${side}-CAB-PILLAR`,
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `${side} CAB PILLAR OUT`,
      panel: `${side}-CAB-PILLAR`,
      supersetOf: [`${side} CAB PILLAR BLEND`],
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 1.7,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `${side} CAB PILLAR BLEND`,
      panel: `${side}-CAB-PILLAR`,
      subsetOf: [`${side} CAB PILLAR OUT`],
      settings: {
        [`${side}-CANTRAIL`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            'UTE': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 1.7,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          '^(?!panel)': { /* pricing method */
            '^UTE*': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: OUT,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `${side} CAB PILLAR LOADING 10%`,
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `${side} CAB PILLAR LOADING 25%`,
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'CAB PILLAR BADGE',
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'CAB PILLAR CLEAR DECAL',
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: `PDR ${side} CAB PILLAR`,
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BUFF & POLISH ${side} CAB PILLAR`,
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: `BRUSH TOUCH ${side} CAB PILLAR`,
      settings: {
        [`${side}-CAB-PILLAR`]: {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
});
