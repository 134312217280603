import moment from 'm';


const SECTIONS = {
  mailbox: 'mailboxes',
  storm: 'storms',
  lightTunnel: 'lightTunnels',
  miscInvoiceCategory: 'miscInvoiceCategories',
  location: 'locations',
  employee: 'employees',
  debtor: 'debtors',
  company: 'companies',
  creditor: 'creditors',
  smsTemplate: 'smsTemplates',
};

export default (state = {}, action) => {
  switch (action.type) {
    case 'SETTINGS-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'SETTINGS-SET':
    {
      const {settings} = action;
      const supplierIndex = settings.suppliers.reduce((acc, s) => {
        acc[s.id] = s;
        return acc;
      }, {});
      const employeeIndex = settings.employees.reduce((acc, e) => {
        acc[e.id] = e;
        return acc;
      }, {});
      const {creditors, creditorIndex} = settings.creditors.reduce((acc, c) => {
        c.name = c.name || supplierIndex[c.supplierId].name;
        acc.creditorIndex[c.id] = c;
        acc.creditors.push(c);
        return acc;
      }, {creditors: [], creditorIndex: {}});
      creditors.sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        return 0;
      });
      const {index: stormIndex, list: storms} = settings.storms.reduce((acc, s) => {
        const creditors = s.creditors.map(c => ({
          ...c,
          makeIndex: c.makes.reduce((acc, m) => {
            acc[m] = true;
            return acc;
          }, {})
        }));
        creditors.sort((a, b) => {
          const aName = creditorIndex[a.id].name;
          const bName = creditorIndex[b.id].name;
          if (aName < bName) return -1;
          else if (aName > bName) return 1;
          return 0;
        });
        acc.index[s.id] = s.name;
        acc.list.push({
          ...s,
          creditors,
          capacities: s.capacities.concat({
            id: null,
            startDate: null,
            numTotal: null,
            numPaint: null,
            numTurret: null
          })
        });
        return acc;
      }, {index: {}, list: []});
      const locations = settings.locations.map(l => {
        l.employees.forEach(e => {
          const commission = e.commission;
          commission.managerCommission = commission.managerCommission || 0;
          Array.apply(null, Array(8)).forEach((_, j) => {
            commission[j] = commission[j] || {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            };
          });
        });
        l.employees.sort((a, b) => {
          const aName = employeeIndex[a.id].name;
          const bName = employeeIndex[b.id].name;
          if (aName < bName) return -1;
          else if (aName > bName) return 1;
          return 0;
        });
        return l;
      });
      return {
        notFound: false,
        ...settings,
        general: {
          ...settings.general,
          publicHolidays: settings.general.publicHolidays.map(day => ({
            ...day,
            dayStr: moment(day.d * 1000).format('DD-MM-YYYY'),
            // state will be a json eg: {"ALL": true, "NSW": false}
            stateStr: Object.entries(day.states).filter(s => s[1] === true).map(s => s[0]).join(', '),
            deleted: false,
          })),
          publicHoliday: null,
          currentPublicHoliday: null
        },
        employeeIndex,
        supplierIndex,
        creditors,
        creditorIndex,
        locations,
        companies: settings.companies,
        storms,
        stormIndex,
        creditor: null,
        debtor: null,
        employee: null,
        lightTunnel: null,
        miscInvoiceCategory: null,
        location: null,
        mailbox: null,
        smsTemplate: null,
        storm: null,
        company: null,
      };
    }
    case 'SETTINGS-GENERAL-SET':
    {
      const {data} = action;
      return {
        ...state,
        general: {
          ...data,
          publicHolidays: data.publicHolidays.map(day => ({
            ...day,
            stateStr: Object.entries(day.states).filter(s => s[1] === true).map(s => s[0]).join(', '),
            dayStr: moment(day.d * 1000).format('DD-MM-YYYY'),
            deleted: false,
          })),
          publicHoliday: null,
          currentPublicHoliday: null
        }
      };
    }
    case 'SETTINGS-GENERAL-CHANGE':
    {
      const {changes} = action;
      return {
        ...state,
        general: {
          ...state.general,
          ...changes
        }
      };
    }
    case 'SETTINGS-GENERAL-ADD-PUBLIC-HOLIDAY':
    {
      const {publicHoliday: d, publicHolidays} = state.general;
      if (d === null) return state;
      const newElem = {
        id: null,
        d,
        states: {"ALL": true},
        stateStr: "ALL",
        dayStr: moment(d * 1000).format('DD-MM-YYYY'),
        deleted: false
      };
      const {added, list: newPublicHolidays} = publicHolidays.reduce((acc, p, i) => {
        if (acc.added !== true) {
          if (d === p.d) {
            p.deleted = false;
            acc.added = true;
          } else if (d < p.d) {
            acc.added = true;
            acc.list.push(newElem);
          }
        }
        acc.list.push(p);
        return acc;
      }, {added: false, list: []});
      if (added !== true) {
        newPublicHolidays.push(newElem);
      }
      return {
        ...state,
        general: {
          ...state.general,
          publicHolidays: newPublicHolidays,
          publicHoliday: null,
          currentPublicHoliday: null
        }
      };
    }
    case 'SETTINGS-GENERAL-EDIT-PUBLIC-HOLIDAY':
    {
      const {publicHolidays} = state.general;
      const {index} = action;

      const v = publicHolidays[index]
      return {
        ...state,
        general: {
          ...state.general,
          currentPublicHoliday: {
            ...v,
            index
          }
        }
      }
    }
    case 'SETTINGS-GENERAL-CHANGE-PUBLIC-HOLIDAY':
    {
      const {changes} = action;
      return {
        ...state,
        general: {
          ...state.general,
          currentPublicHoliday: {
            ...state.general.currentPublicHoliday,
            ...changes
          }
        }
      }
    }
    case 'SETTINGS-SECTION-EDIT':
    {
      const {section, id} = action;
      let v = null;
      if (id !== null) {
        if (id === 'new') {
          if (section === 'employee') {
            v = {
              id: 'new',
              name: '',
              email: '',
              password: '',
              chargesGst: false,
              abilities: {},
            };
          } else if (section === 'mailbox') {
            v = {
              id: 'new',
              name: '',
              username: '',
              password: null,
              repxUsername: '',
              repxPassword: null,
              debtorId: null,
              defaultStorm: null,
              defaultRegoState: null,
              defaultCompany: null,
              disabled: false,
              limitRepxUpdate: true,
            };
          } else if (section === 'storm') {
            v = {
              id: 'new',
              name: '',
              defaultLocation: null,
              creditors: [],
              capacities: [{
                id: null,
                startDate: null,
                numTotal: null,
                numPaint: null,
                numTurret: null
              }],
            };
          } else if (section === 'location') {
            v = {
              id: 'new',
              name: '',
              address: '',
              partsFrom: '',
              partsCc: '',
              includeInLocationTrackingReport: false,
              assessmentDows: {0: false, 1: true, 2: true, 3: true, 4: true, 5: true, 6: false},
              repairDows: {0: false, 1: true, 2: true, 3: true, 4: true, 5: true, 6: false},
              pickupDows: {0: false, 1: true, 2: true, 3: true, 4: true, 5: true, 6: false},
              fitmentDows: {0: false, 1: true, 2: true, 3: true, 4: true, 5: true, 6: false},
              employees: []
            };
          } else if (section === 'creditor') {
            v = {
              id: 'new',
              supplierId: null,
              name: '',
              email: '',
              canMiscInvoice: false,
              canPart: false,
              canSublet: false,
              makes: [],
              miscInvoiceCategories: [],
            };
          } else if (section === 'debtor') {
            v = {
              id: 'new',
              name: '',
              email: '',
              password: '',
              invoiceName: '',
              abn: '',
              abilities: {},
              portalStorms: [],
              rrRate: '',
              repairRate: '',
              paintRate: '',
              dentMatrixFactor: 100,
              totalLossPercentage: 70,
              minCategoryAdjustment: 0,
              pricingMethod: null,
              categoryMethod: null,
              categorySettings: null,
              canOrm: false,
              infectionAllowanceEnabled: false,
              stormActivityReportEnabled: false,
              excludeFromAssessmentInbox: false,
              autoSmsEnabled: true,
              autoInvoiceEnabled: false,
              gstRate: 0,
            };
          } else if (section === 'lightTunnel') {
            v = {
              id: 'new',
              name: '',
            };
          } else if (section === 'miscInvoiceCategory') {
            v = {
              id: 'new',
              name: '',
            };
          } else if (section === 'smsTemplate') {
            v = {
              id: 'new',
              autoType: null,
              name: '',
              template: '',
              enabled: true,
            };
          } else if (section === 'company') {
            v = {
              id: 'new',
              name: '',
              jurisdiction: '',
              abn: '',
              licenseNumber: '',
              address: '',
              phone: '',
              logo: '',
              accountNumber: ''
            };
          }
        } else {
          v = state[SECTIONS[section]].find(e => e.id === id);
        }
      }
      return {
        ...state,
        [section]: v
      };
    }
    case 'SETTINGS-SECTION-CHANGE':
    {
      const {section, changes} = action;
      return {
        ...state,
        [section]: {
          ...state[section],
          ...changes
        }
      };
    }
    case 'SETTINGS-SECTION-SET':
    {
      let {section, data} = action;
      if (section === 'storm') {
        data.capacities = data.capacities.concat({
          id: null,
          startDate: null,
          numTotal: null,
          numPaint: null,
          numTurret: null
        });
        data.creditors = data.creditors.map(c => ({
          ...c,
          makeIndex: c.makes.reduce((acc, m) => {
            acc[m] = true;
            return acc;
          }, {})
        }));
        data.creditors.sort((a, b) => {
          const aName = state.creditorIndex[a.id].name;
          const bName = state.creditorIndex[b.id].name;
          if (aName < bName) return -1;
          else if (aName > bName) return 1;
          return 0;
        });
      } else if (section === 'location') {
        data.employees.sort((a, b) => {
          const aName = state.employeeIndex[a.id].name;
          const bName = state.employeeIndex[b.id].name;
          if (aName < bName) return -1;
          else if (aName > bName) return 1;
          return 0;
        });
      } else if (section === 'creditor') {
        data.name = data.name || state.supplierIndex[data.supplierId].name;
      }
      let found = false;
      const newSection = state[SECTIONS[section]]
        .map(i => {
          if (i.id !== data.id) return i;
          found = true;
          return data;
        });
      if (found === false) {
        newSection.splice(newSection.length, 0, data);
      }
      newSection.sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        return 0;
      });
      return {
        ...state,
        [section]: data,
        [SECTIONS[section]]: newSection
      };
    }
    case 'SETTINGS-SECTION-DEL':
    {
      const {section, id} = action;
      const newSections = state[SECTIONS[section]].filter(i => (i.id !== id));
      return {
        ...state,
        [SECTIONS[section]]: newSections
      };
    }
    case 'SETTINGS-DEBTOR-CHANGE-ABILITY':
    {
      const newAbilities = {
        ...state.debtor.abilities,
        ...action.change
      };
      return {
        ...state,
        debtor: {
          ...state.debtor,
          abilities: newAbilities
        }
      };
    }
    case 'SETTINGS-DEBTOR-ADD-STORM':
    {
      if (!state.debtor) return state;
      const {stormId} = action;
      let found = false;
      let newPortalStorms = state.debtor.portalStorms.map(s => {
        if (s === stormId) found = true;
        return s;
      });
      if (found === false) {
        newPortalStorms.push(stormId);
      }
      newPortalStorms.sort((a, b) => {
        const aName = state.stormIndex[a].name;
        const bName = state.stormIndex[b].name;
        if (aName < bName) return -1;
        else if (aName > bName) return 1;
        return 0;
      });
      return {
        ...state,
        debtor: {
          ...state.debtor,
          portalStorms: newPortalStorms
        }
      };
    }
    case 'SETTINGS-DEBTOR-DELETE-STORM':
    {
      if (!state.debtor) return state;
      const {stormId} = action;
      const newPortalStorms = state.debtor.portalStorms.filter(s => s !== stormId);
      return {
        ...state,
        debtor: {
          ...state.debtor,
          portalStorms: newPortalStorms,
        }
      };
    }
    case 'SETTINGS-EMPLOYEE-CHANGE-ABILITY':
    {
      const newAbilities = {
        ...state.employee.abilities,
        ...action.change
      };
      return {
        ...state,
        employee: {
          ...state.employee,
          abilities: newAbilities
        }
      };
    }
    case 'SETTINGS-LOCATION-ADD-EMPLOYEE':
    {
      if (!state.location) return state;
      const {employeeId} = action;
      let found = false;
      let newEmployees = state.location.employees.map(e => {
        if (e.employeeId === employeeId) found = true;
        return e;
      });
      if (found === false) {
        newEmployees.push({
          id: employeeId,
          abilities: {
            canRr: false,
            canRepair: false,
            canPaint: false,
            canDetail: false,
            canQa: false,
          },
          commission: {
            managerCommission: 0,
            0: {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            },
            1: {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            },
            2: {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            },
            3: {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            },
            4: {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            },
            5: {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            },
            6: {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            },
            7: {
              method: 'FIXED',
              percentage: 0,
              fixedRr: 0,
              fixedRepair: 0
            },
          },
        });
      }
      newEmployees.sort((a, b) => {
        const aName = state.employeeIndex[a.id].name;
        const bName = state.employeeIndex[b.id].name;
        if (aName < bName) return -1;
        else if (aName > bName) return 1;
        return 0;
      });
      return {
        ...state,
        location: {
          ...state.location,
          employees: newEmployees
        }
      };
    }
    case 'SETTINGS-LOCATION-DELETE-EMPLOYEE':
    {
      if (!state.location) return state;
      const {employeeId} = action;
      const newEmployees = state.location.employees.filter(e => e.id !== employeeId);
      return {
        ...state,
        location: {
          ...state.location,
          employees: newEmployees
        }
      };
    }
    case 'SETTINGS-LOCATION-CHANGE-EMPLOYEE-COMMISSION':
    {
      if (!state.location) return state;
      const {employeeId, category, field, value} = action;
      const newEmployees = state.location.employees.map(e => {
        if (e.id !== employeeId) return e;
        const newCommission = {
          ...e.commission
        };
        if (category === null) {
          newCommission[field] = value;
        } else {
          newCommission[category][field] = value;
        }
        return {
          ...e,
          commission: newCommission
        };
      });
      return {
        ...state,
        location: {
          ...state.location,
          employees: newEmployees
        }
      };
    }
    case 'SETTINGS-LOCATION-CHANGE-EMPLOYEE-ABILITY':
    {
      if (!state.location) return state;
      const {employeeId, change} = action;
      const newEmployees = state.location.employees.map(e => {
        if (e.id !== employeeId) return e;
        return {
          ...e,
          abilities: {
            ...e.abilities,
            ...change
          }
        };
      });
      return {
        ...state,
        location: {
          ...state.location,
          employees: newEmployees
        }
      };
    }
    case 'SETTINGS-STORM-ADD-CREDITOR':
    {
      if (!state.storm) return state;
      const {creditorId} = action;
      let found = false;
      let newCreditors = state.storm.creditors.map(e => {
        if (e.creditorId === creditorId) found = true;
        return e;
      });
      if (found === false) {
        newCreditors.push({
          id: creditorId,
          makes: [],
          makeIndex: {},
        });
      }
      return {
        ...state,
        storm: {
          ...state.storm,
          creditors: newCreditors
        }
      };
    }
    case 'SETTINGS-STORM-DELETE-CREDITOR':
    {
      if (!state.storm) return state;
      const {creditorId} = action;
      const newCreditors = state.storm.creditors.filter(c => c.id !== creditorId);
      return {
        ...state,
        storm: {
          ...state.storm,
          creditors: newCreditors
        }
      };
    }
    case 'SETTINGS-STORM-TOGGLE-CREDITOR-MAKE':
    {
      if (!state.storm) return state;
      const {creditorId, make} = action;
      const newCreditors = state.storm.creditors.map(c => {
        if (c.id !== creditorId) return c;
        const newC = {
          ...c,
          makes: [],
          makeIndex: {
            ...c.makeIndex,
            [make]: !c.makeIndex[make]
          }
        };
        for (let m in newC.makeIndex) {
          if (newC.makeIndex[m] === true) {
            newC.makes.push(m);
          }
        }
        return newC;
      });
      return {
        ...state,
        storm: {
          ...state.storm,
          creditors: newCreditors
        }
      };
    }
    default:
      return state;
  }
};
