import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import AddMiscInvoice from './component.jsx';


const add = (
  API,
  locationId,
  creditorId,
  categoryId,
  description,
  invoiceDate,
  invoiceNumber,
  amount,
  paymentDate,
  paymentMethod,
  paymentReference
) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/misc-invoice`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          locationId,
          creditorId,
          categoryId,
          description,
          invoiceDate,
          invoiceNumber,
          amount: Math.round(amount * 100),
          paymentDate,
          paymentMethod,
          paymentReference
        })
      }
    )
      .then(res => {
        if (res.error === true) return false;
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const close = () => ({
  type: 'ADD-MISC-INVOICE-CLOSE',
});

const mapStateToProps = (state, props) => {
  const {
    addMiscInvoice: {open},
    params: {
      locations: {list: locations},
      miscCreditors: {index: creditorIndex, list: creditors}
    },
  } = state;
  return {
    open,
    locations,
    creditorIndex,
    creditors,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onClose: () => dispatch(close()),
  onAdd: (
    locationId,
    creditorId,
    categoryId,
    description,
    invoiceDate,
    invoiceNumber,
    amount,
    paymentDate,
    paymentMethod,
    paymentReference
  ) => dispatch(
    add(
      props.API,
      locationId,
      creditorId,
      categoryId,
      description,
      invoiceDate,
      invoiceNumber,
      amount,
      paymentDate,
      paymentMethod,
      paymentReference
    )
  ),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddMiscInvoice)
);

