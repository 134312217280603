import React from 'react';
import * as Icons from 'react-feather';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import CalendarInput from 'components/CalendarInput.jsx';
import Field from 'components/Field.jsx';
import Modal from 'components/Modal';
import Select from 'components/Select.jsx';


const PAYMENT_METHODS = [
  'cash',
  'cheque',
  'direct deposit',
  'eftpos',
  'waived',
];
const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const UnpaidButton = styled.button`
  width: 68px;
  & svg {
    stroke: #fff;
  }
`;


export default class MiscInvoices extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      edit: null,
    };
    this.renderMiscInvoice = this.renderMiscInvoice.bind(this);
    this.onChangeUnpaidOnly = this.onChangeUnpaidOnly.bind(this);
    this.onFilterLocation = opts.onFilter.bind(null, 'location');
    this.onFilterCreditor = opts.onFilter.bind(null, 'creditor');
    this.onFilterCategory = opts.onFilter.bind(null, 'category');
    this.onChangeCategoryId = this.onChange.bind(this, 'categoryId');
    this.onChangePaymentDate = this.onChange.bind(this, 'paymentDate');
    this.onChangePaymentMethod = this.onChange.bind(this, 'paymentMethod');
    this.onChangePaymentReference = this.onChange.bind(this, 'paymentReference');
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  componentDidMount() {
    const {start, finish, unpaidOnly, onLoad} = this.props;
    onLoad(start, finish, unpaidOnly);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onSearch() {
    const {start, finish, unpaidOnly, onLoad} = this.props;
    onLoad(start, finish, unpaidOnly);
  }
  onChangeUnpaidOnly(e) {
    const {unpaidOnly, onChangeSearch} = this.props;
    onChangeSearch('unpaidOnly', !unpaidOnly);
  }
  onClose() {
    this.setState({
      edit: null,
    });
  }
  onEdit(inv) {
    this.setState({
      edit: inv,
    });
  }
  onChange(field, v) {
    this.setState({
      edit: {
        ...this.state.edit,
        [field]: v,
      },
    });
  }
  onSave() {
    const {edit: {id, categoryId, paymentDate, paymentMethod, paymentReference}} = this.state;
    this.props.onSave(
      id,
      {categoryId, paymentDate, paymentMethod, paymentReference}
    )
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while updating misc invoice.');
        } else {
          toast.success('Misc invoice successfully updated.');
          this.setState({
            edit: null,
          });
        }
      });
  }
  onDelete(inv) {
    if (inv.isPaid === true) {
      window.alert('Unable to delete paid record.');
    } else if (window.confirm('Are you sure you wish to delete this invoice?') === true) {
      this.props.onDelete(inv.id)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered while deleting misc invoice.');
          } else {
            toast.success('Misc invoice successfully deleted.');
          }
        });
    }
  }
  renderMiscInvoice(inv, i) {
    const {employeesIndex, locationsIndex, creditorIndex, miscInvoiceCategoryIndex} = this.props;
    const {location, creditor, category} = this.state;
    if (location && inv.locationId !== location) return null;
    if (creditor && inv.creditorId !== creditor) return null;
    if (category && inv.categoryId !== category) return null;
    return (
      <div key={i} className='mt-2'>
        <div className='bg-white p-2 rounded'>
          <div className='row'>
            <div className='col-7 d-flex align-items-center'>
              <div className='row flex-grow-1'>
                <div className='col-3 mb-sm-2 mb-lg-0 d-flex align-items-center'>{locationsIndex[inv.locationId].name}</div>
                <div className='col-3 d-flex align-items-center'>{creditorIndex[inv.creditorId].name}</div>
                <div className='col-2 mb-sm-2 mb-lg-0 d-flex align-items-center'>{miscInvoiceCategoryIndex[inv.categoryId].name}</div>
                <div className='col-2 d-flex align-items-center'>{inv.description}</div>
                <div className='col-2 d-flex align-items-center'>{inv.amountStr}</div>
              </div>
            </div>
            <div className='col-4 d-flex align-items-center'>
              <div className='row flex-grow-1'>
                <div className='col-3 d-flex align-items-center'>{inv.invoiceDateStr}</div>
                <div className='col-3 d-flex align-items-center'>{inv.invoiceNumber}</div>
                {inv.paymentDate !== null && (
                  <>
                    <div className='col-3 d-flex align-items-center'>{inv.paymentDateStr}</div>
                    <div className='col-3 d-flex align-items-center'>
                      {inv.paymentMethod}
                      <br />
                      {inv.paymentReference}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='col-1 d-flex flex-row justify-content-around align-items-center'>
              <div
                className='cursor-pointer p-1'
                onClick={this.onEdit.bind(this, inv)}
              >
                <Icons.Edit2 width={18} height={18} color={colors.primary} />
              </div>
              <div
                className='cursor-pointer p-1'
                onClick={this.onDelete.bind(this, inv)}
              >
                <Icons.X width={18} height={18} color={colors.red} />
              </div>
            </div>
          </div>
        </div>
        <small className='px-2 text-muted'>
          {employeesIndex[inv.employeeId].name} {'\u2013'} {inv.ts}
        </small>
      </div>
    );
  }
  render() {
    console.log('miscinvoices:render');
    const {
      notFound,
      start,
      finish,
      unpaidOnly,
      miscInvoices,
      totalStr,
      locations,
      creditorIndex,
      creditors,
      miscInvoiceCategories,
      onChangeSearch,
      location,
      creditor,
      category,
    } = this.props;
    const {
      edit,
    } = this.state;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load miscellaneous invoices.</div>
        </ErrorContainer>
      );
    }
    return (
      <>
        <Container className='flex-grow-1 p-3'>
          <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
            <h4 className='mb-3'>Misc invoices</h4>
            <div className='d-flex flex-row align-items-center'>
              <div className='mx-2'>
                <CalendarInput
                  width='85px'
                  background={colors.grey1}
                  selected={start}
                  onChange={onChangeSearch.bind(null, 'start')}
                />
              </div>
              <Icons.ArrowRight color={colors.grey4} width={18} height={18} />
              <div className='mx-2'>
                <CalendarInput
                  align='right'
                  width='85px'
                  background={colors.grey1}
                  selected={finish}
                  onChange={onChangeSearch.bind(null, 'finish')}
                />
              </div>
              <div className='mx-2'>
                <UnpaidButton
                  type='button'
                  className={unpaidOnly ? 'btn btn-secondary' : 'btn btn-outline-secondary'}
                  onClick={this.onChangeUnpaidOnly}
                >
                  {unpaidOnly ? 'Unpaid' : 'All'}
                </UnpaidButton>
              </div>
              <div className='ml-2'>
                <SearchButton
                  type='button'
                  className='btn btn-primary'
                  disabled={!start || !finish}
                  onClick={this.onSearch.bind(this)}
                ><Icons.Search width={18} height={18} /></SearchButton>
              </div>
            </div>
          </div>
          <Card className='p-3'>
            <div className='row px-2'>
              <div className='col-7'>
                <div className='row'>
                  <div className='col-3 font-weight-bold'>
                    <Select
                      background={colors.grey2}
                      selected={location}
                      options={locations}
                      placeholder='Location'
                      textField='name'
                      onChange={this.onFilterLocation}
                    />
                  </div>
                  <div className='col-3 font-weight-bold'>
                    <Select
                      background={colors.grey2}
                      selected={creditor}
                      options={creditors}
                      placeholder='Creditor'
                      textField='name'
                      onChange={this.onFilterCreditor}
                    />
                  </div>
                  <div className='col-2 font-weight-bold'>
                    <Select
                      background={colors.grey2}
                      selected={category}
                      options={miscInvoiceCategories}
                      placeholder='Category'
                      textField='name'
                      onChange={this.onFilterCategory}
                    />
                  </div>
                  <div className='col-2 font-weight-bold d-flex align-items-center'>Description</div>
                  <div className='col-2 font-weight-bold d-flex align-items-center'>$</div>
                </div>
              </div>
              <div className='col-4 d-flex align-items-center'>
                <div className='row flex-grow-1'>
                  <div className='col-3 font-weight-bold d-flex align-items-center'>Inv date</div>
                  <div className='col-3 font-weight-bold d-flex align-items-center'>Inv #</div>
                  <div className='col-3 font-weight-bold d-flex align-items-center'>Pay date</div>
                  <div className='col-3 font-weight-bold d-flex align-items-center'>Pay details</div>
                </div>
              </div>
              <div className='col-1 font-weight-bold' />
            </div>
            {miscInvoices.length === 0 && (
              <div className='text-center text-secondary mt-3'>No miscellaneous invoices found.</div>
            )}
            {miscInvoices.length !== 0 && miscInvoices.map(this.renderMiscInvoice)}
            {miscInvoices.length !== 0 && (
              <div className='row px-2 mt-3'>
                <div className='col-7'>
                  <div className='row'>
                    <div className='col-10 text-right font-weight-bold'>Total:</div>
                    <div className='col-2 text-left font-weight-bold'>{totalStr}</div>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </Container>
        {edit !== null && (
          <Modal
            title='Update miscellaneous invoice'
            open={true}
            onClose={this.onClose}
          >
            <div className='modal-body'>
              <Select
                title='Category'
                background={colors.grey1}
                selected={edit.categoryId}
                options={creditorIndex[edit.creditorId].miscInvoiceCategories}
                textField='name'
                onChange={this.onChangeCategoryId}
              />
              <div className='row'>
                <div className='col'>
                  <CalendarInput
                    title='Pay date'
                    showClear={true}
                    background={colors.grey1}
                    selected={edit.paymentDate}
                    onChange={this.onChangePaymentDate}
                    disabled={edit.isPaid === true}
                  />
                </div>
                <div className='col'>
                  <Select
                    title='Payment method'
                    background={colors.grey1}
                    selected={edit.paymentMethod}
                    options={PAYMENT_METHODS}
                    onChange={this.onChangePaymentMethod}
                    disabled={edit.isPaid === true}
                  />
                </div>
                <div className='col'>
                  <Field
                    title='Ref'
                    value={edit.paymentReference}
                    background={colors.grey1}
                    uppercaseOnly={true}
                    onChange={this.onChangePaymentReference}
                    disabled={edit.isPaid === true}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={this.onClose}
              >Close</button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={this.onSave}
              >Save</button>
            </div>
          </Modal>
        )}
      </>
    );
  };
};
