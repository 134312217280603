import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import env from 'env';
import {withApi} from 'components/API';
import Login from './component.jsx';


const login = (API, history, email, password) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/auth`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({email, password})
      }
    )
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        dispatch({
          type: 'AUTHENTICATION-SET',
          ...resp
        });
        history.push('/');
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch, props) => ({
  onLogin: (email, password) => dispatch(login(props.API, props.history, email, password))
});

export default withApi(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
