import moment from 'm';
import numeral from 'numeral';


export default (state = {
  notFound: null,
}, action) => {
  switch (action.type) {
    case 'CUSTOMER-SET-NOT-FOUND':
      return {
        notFound: true
      };
    case 'CUSTOMER-RESET':
      return {
        notFound: null,
      };
    case 'CUSTOMER-SET':
    {
      const {data: d} = action;
      return {
        notFound: false,
        ...d,
        excess: numeral(d.excess / 100).format('$0,0.00'),
        mode: d.agreementDate ?
          'status' :
          (
            d.isIn === true ?
              'checkin' :
              (d.startDate !== null ? 'repair' : 'assess')
          ),
        assessmentDate: d.assessmentDate ? moment(d.assessmentDate * 1000).format('DD-MM HH:mm') : 'N/A',
        startDate: d.startDate ? moment(d.startDate * 1000).format('DD-MM') : 'N/A',
        finishDate: d.finishDate ? moment(d.finishDate * 1000).format('DD-MM') : 'N/A',
      };
    }
    default:
      return state;
  }
};
