import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import DebtorPayments from './component.jsx';


const load = (API, debtorId, endDate) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    const url = new URL(`${env.API_SERVER}/v1/internal/invoices/${debtorId}`);
    url.searchParams.append('endDate', endDate);
    return API.request(url, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-PAYMENTS-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'ADMIN-PAYMENTS-SET',
          invoices: resp.invoices
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-PAYMENTS-SET-NULL'
});

const changeDebtorId = debtorId => ({
  type: 'ADMIN-PAYMENTS-CHANGE-DEBTOR-ID',
  debtorId,
});

const changeEndDate = endDate => ({
  type: 'ADMIN-PAYMENTS-CHANGE-END-DATE',
  endDate,
});

const change = (jobId, changes) => ({
  type: 'ADMIN-PAYMENTS-UPDATE',
  jobId,
  changes
});

const save = (API, jobId, paymentDate, paymentMethod, paymentReference) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/invoice/${jobId}`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({paymentDate, paymentMethod, paymentReference})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    adminPayments: {
      notFound,
      debtorId,
      endDate,
      numInvoices,
      invoices0,
      invoices30,
      invoices60,
      invoices90,
      total0Str,
      total30Str,
      total60Str,
      total90Str,
      totalOutstandingStr,
      gstOutstandingStr,
    },
    params: {
      debtors: {index: debtorsIndex, list: debtorsList},
      generalSettings: {
        name: companyName,
        jurisdiction,
        abn,
        licenseNumber,
        bsb,
        accountNumber,
        address,
        phone,
        logo,
      }
    }
  } = state;
  return {
    companyName,
    logo,
    address,
    phone,
    jurisdiction,
    abn,
    licenseNumber,
    bsb,
    accountNumber,
    notFound,
    debtorId,
    endDate,
    numInvoices,
    invoices0,
    invoices30,
    invoices60,
    invoices90,
    total0Str,
    total30Str,
    total60Str,
    total90Str,
    totalOutstandingStr,
    gstOutstandingStr,
    debtorsIndex,
    debtorsList,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: (debtorId, endDate) => dispatch(load(props.API, debtorId, endDate)),
  onUnload: () => dispatch(unload()),
  onChangeDebtorId: debtorId => dispatch(changeDebtorId(debtorId)),
  onChangeEndDate: endDate => dispatch(changeEndDate(endDate)),
  onChange: (jobId, changes) => dispatch(change(jobId, changes)),
  onSave: (jobId, paymentDate, paymentMethod, paymentReference) => dispatch(
    save(props.API, jobId, paymentDate, paymentMethod, paymentReference)
  ),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DebtorPayments)
);
