import moment from 'moment';
import numeral from 'numeral';


let currentState = null;
const cs = window.localStorage.getItem('admin-creditor-invoices:creditorId');
if (cs) {
  currentState = JSON.parse(cs);
}


export default (state = {
  notFound: null,
  creditorId: currentState,
  invoiceNumber: ''
}, action) => {
  switch (action.type) {
    case 'ADMIN-CREDITOR-INVOICES-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        data: null
      };
    case 'ADMIN-CREDITOR-INVOICES-SET-NULL':
      return {
        ...state,
        notFound: null,
        data: null
      };
    case 'ADMIN-CREDITOR-INVOICES-CHANGE-INVOICENO':
      const {invoiceNumber} = action;
      return {
        ...state,
        notFound: null,
        invoiceNumber,
        data: null,
      };
    case 'ADMIN-CREDITOR-INVOICES-CHANGE-CREDITOR-ID':
      const {creditorId} = action;
      window.localStorage.setItem(
        'admin-creditor-invoices:creditorId',
        JSON.stringify(creditorId)
      );
      return {
        ...state,
        notFound: null,
        creditorId,
        data: null,
      };
    case 'ADMIN-CREDITOR-INVOICES-SET':
      {
        const {data, gstRate} = action;
        const year = moment().year();
        const invoiceDate = data.invoiceDate ? moment(data.invoiceDate * 1000) : null;
        const invoiceDateStr = invoiceDate ?
          invoiceDate.format(
            invoiceDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
          ) : '\u2011';
        const processDate = data.processDate ? moment(data.processDate * 1000) : null;
        const processDateStr = processDate ?
          processDate.format(
            processDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
          ) : '\u2011';
        const payDate = data.payDate ? moment(data.payDate * 1000) : null;
        const payDateStr = payDate ?
          payDate.format(
            payDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
          ) : '\u2011';
        return {
          ...state,
          notFound: false,
          data: {
            ...data,
            invoiceDateStr,
            processDateStr,
            payDateStr,
            totalStr: numeral(data.total / 100 * (100 + gstRate) / 100).format('$0,0.00'),
            credits: data.credits.map(c => {
              const creditDate = moment(c.creditDate * 1000);
              const collectDate = c.collectDate ? moment(c.collectDate * 1000) : null;
              const collectDateStr = collectDate ?
                collectDate.format(
                  collectDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
                ) : '\u2011';
              const processDate = c.processDate ? moment(c.processDate * 1000) : null;
              const processDateStr = processDate ?
                processDate.format(
                  processDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
                ) : '\u2011';
              const payDate = c.payDate ? moment(c.payDate * 1000) : null;
              const payDateStr = payDate ?
                payDate.format(
                  payDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
                ) : '\u2011';
              return {
                ...c,
                creditDateStr: creditDate.format(
                  creditDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
                ),
                totalStr: numeral(c.total / 100 * (100 + gstRate) / 100).format('$0,0.00'),
                collectDateStr,
                processDateStr,
                payDateStr,
              };
            })
          }
        };
      }
    case 'ADMIN-CREDITOR-INVOICES-SET-INVOICE-PROCESSED':
      {
        const year = moment().year();
        const processDate = action.processDate ? moment(action.processDate * 1000) : null;
        const processDateStr = processDate ?
          processDate.format(
            processDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
          ) : '\u2011';
        return {
          ...state,
          data: {
            ...state.data,
            processDate: action.processDate,
            processDateStr,
          }
        };
      }
    case 'ADMIN-CREDITOR-INVOICES-SET-CREDIT-PROCESSED':
      {
        const year = moment().year();
        const processDate = action.processDate ? moment(action.processDate * 1000) : null;
        const processDateStr = processDate ?
          processDate.format(
            processDate.year() === year ? 'DD-MM' : 'DD-MM-YYYY'
          ) : '\u2011';
        return {
          ...state,
          data: {
            ...state.data,
            credits: state.data.credits.map(c => {
              if (c.creditId !== action.creditId) return c;
              return {
                ...c,
                processDate: action.processDate,
                processDateStr,
              };
            })
          }
        };
      }
    default:
      return state;
  }
};
