import moment from 'moment';
import numeral from 'numeral';


const COLUMNS = [
  {id: 'jobNumber', type: 'id', title: 'Job #'},
  {id: 'debtor', title: 'Debtor'},
  {id: 'claimNumber', title: 'Claim #'},
  {id: 'invoiceNumber', title: 'Invoice #'},
  {id: 'invoiceDate', type: 'date', title: 'Inv date'},
  {id: 'excessInvoiceDate', type: 'date', title: 'XS inv date'},
  {id: 'rrTotal', type: 'money', title: 'RR'},
  {id: 'pdrTotal', type: 'money', title: 'PDR'},
  {id: 'repairTotal', type: 'money', title: 'Repair'},
  {id: 'paintTotal', type: 'money', title: 'Paint'},
  {id: 'partTotal', type: 'money', title: 'Part'},
  {id: 'miscTotal', type: 'money', title: 'Misc'},
  {id: 'excess', type: 'money', title: 'XS'},
  {id: 'quotedTotal', type: 'money', title: 'Quote'},
  {id: 'categoryAdjustment', type: 'money', title: 'Cat adjustment'},
  {id: 'categoryExtraTotal', type: 'money', title: 'Cat extra'},
  {id: 'subletTotal', type: 'money', title: 'Sublet'},
  {id: 'subtotal', type: 'money', title: 'Subtotal'},
  {id: 'gst', type: 'money', title: 'GST'},
  {id: 'total', type: 'money', title: 'Total'},
];

export default (state = {notFound: null, report: null}, action) => {
  switch (action.type) {
    case 'ADMIN-REPORTS-INVOICE-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        report: null
      };
    case 'ADMIN-REPORTS-INVOICE-SET-NULL':
      return {
        ...state,
        notFound: null,
        report: null,
      };
    case 'ADMIN-REPORTS-INVOICE-SET':
      {
        const {report} = action;
        const columns = COLUMNS.map(c => c.title);
        const rows = report.reduce((acc, r, i) => {
          const values = [];
          COLUMNS.forEach(({id, type, title}) => {
            let v = r[id];
            if (type === 'id') {
              v = {
                id: r.id,
                v,
              };
            } else if (type === 'bool') {
              v = {v: v ? 'Y' : 'N'};
            } else if (type === 'date') {
              v = {
                v: v ? moment(v * 1000).format('DD-MM-YYYY') : null,
              };
            } else if (type === 'money') {
              v = {v: numeral(v / 100).format('$0,0.00')};
            } else {
              v = {v};
            }
            values.push(v);
          });
          acc.push(values);
          return acc;
        }, []);
        return {
          ...state,
          notFound: false,
          report: {
            columns,
            rows,
          },
        };
      }
    default:
      return state;
  }
};
