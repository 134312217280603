import React from 'react';
import * as Icons from 'react-feather';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import ImageList from 'components/supplier/ImageList.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const SubmitButton = styled.button`
  width: 65px;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Line = styled.div`
  background: #fff;
  border: ${opts => opts.extra ? `1px solid ${colors.redLight}` : 'none'};
  border-radius: 0.5rem;
  :first-of-type {
    margin-top: 0px !important;
  }
`;
const Avatar = styled.div`
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  align-self: center;
  background: ${opts => opts.bg};
  color: #fff;
`;
//const SubmittedOverlay = styled.div`
//  position: absolute;
//  left: 0;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  background: rgba(0,0,0,0.7);
//  color: #fff;
//  z-index: 2000;
//  font-size: 1.2rem;
//`;

const LineItem = ({
  orderLineId, quoteLineId, reqQty, reqDescription, reqPartNumber,
  qtyBackorder, qtyAvailable, quoteDescription, quotePartNumber,
  quoteListPriceStr, quoteCostPriceStr, quoteComments
}) => {
  if (quoteLineId === null) {
    return (
      <Line className='mt-2 p-2'>
        <div className='row'>
          <div className='col-1'>
            {reqQty}x
          </div>
          <div className='col-4'>
            {reqDescription}
          </div>
          <div className='col-3'>
            {reqPartNumber}
          </div>
        </div>
      </Line>
    );
  } else if (orderLineId === null) {
    return (
      <Line className='mt-2 p-2' extra={true}>
        <div className='row'>
          <div className='col-1 font-weight-bold'>
            {qtyAvailable}x
          </div>
          <div className='col-4 font-weight-bold'>
            {quoteDescription}
          </div>
          <div className='col-3 font-weight-bold'>
            {quotePartNumber}
          </div>
          <div className='col-2 font-weight-bold'>{quoteListPriceStr}</div>
          <div className='col-2 font-weight-bold'>{quoteCostPriceStr}</div>
        </div>
        <div className='row'>
          <div className='col-11 offset-1'>{quoteComments}</div>
        </div>
        {qtyBackorder !== 0 && (
          <div className='text-danger font-weight-bold'>BACK ORDER: {qtyBackorder}</div>
        )}
      </Line>
    );
  }
  return (
    <Line className='mt-2 p-2'>
      <div className='row' >
        <div className='col-1'>
          <div className='font-weight-bold'>{qtyAvailable}x</div>
          <div className='text-muted'>{reqQty}x</div>
        </div>
        <div className='col-4'>
          <div className='font-weight-bold'>{quoteDescription}</div>
          <div className='text-muted'>{reqDescription}</div>
        </div>
        <div className='col-3'>
          <div className='font-weight-bold'>{quotePartNumber}</div>
          <div className='text-muted'>{reqPartNumber}</div>
        </div>
        <div className='col-2 font-weight-bold'>{quoteListPriceStr}</div>
        <div className='col-2 font-weight-bold'>{quoteCostPriceStr}</div>
      </div>
      <div className='row'>
        <div className='col-11 offset-1'>{quoteComments}</div>
      </div>
      {qtyBackorder !== 0 && (
        <div className='text-danger font-weight-bold'>BACK ORDER: {qtyBackorder}</div>
      )}
    </Line>
  );
};
//const LineItem = ({
//  quoteLineId, qty, reqDescription, reqPartNumber,
//  qtyBackorder, qtyAvailable, quoteDescription, quotePartNumber,
//  listPriceStr, costPriceStr, comments
//}) => {
//  if (quoteLineId === null) {
//    return (
//      <Line className='mt-2 p-2'>
//        <div className='row'>
//          <div className='col-1'>
//            {qty}x
//          </div>
//          <div className='col-4'>
//            {reqDescription}
//          </div>
//          <div className='col-3'>
//            {reqPartNumber}
//          </div>
//        </div>
//      </Line>
//    );
//  }
//  return (
//    <Line className='mt-2 p-2'>
//      <div className='row' >
//        <div className='col-1'>
//          <div className='font-weight-bold'>{qtyAvailable}x</div>
//          <div className='text-muted'>{qty}x</div>
//        </div>
//        <div className='col-4'>
//          <div className='font-weight-bold'>{quoteDescription}</div>
//          <div className='text-muted'>{reqDescription}</div>
//        </div>
//        <div className='col-3'>
//          <div className='font-weight-bold'>{quotePartNumber}</div>
//          <div className='text-muted'>{reqPartNumber}</div>
//        </div>
//        <div className='col-2 font-weight-bold'>{listPriceStr}</div>
//        <div className='col-2 font-weight-bold'>{costPriceStr}</div>
//      </div>
//      <div className='row'>
//        <div className='col-11 offset-1'>{comments}</div>
//      </div>
//      {qtyBackorder !== 0 && (
//        <div className='text-danger font-weight-bold'>BACK ORDER: {qtyBackorder}</div>
//      )}
//    </Line>
//  );
//};

export default class Order extends React.Component {
  constructor(opts) {
    super(opts);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    const {id, user, onLoad} = this.props;
    if (id && user) {
      onLoad(id, user);
    }
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {id, user, onLoad} = this.props;
    if ((prevProps.id !== id || prevProps.user !== user) && id && user) {
      onLoad(id, user);
    }
  }
  onSubmit() {
    const {id, user, order, onSubmit} = this.props;
    const lines = order.lines.map(({orderLineId, quoteLineId}) => ({orderLineId, quoteLineId}));
    onSubmit(id, user, lines)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while submitting order.');
        } else {
          toast.success('Order successfully submitted.');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while submitting order.');
      });
  }
  render() {
    console.log('order:render');
    const {notFound, order, gallery, onSetOpenImage} = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load order.</div>
        </ErrorContainer>
      );
    }
    const {
      id,
      idStr,
      jobNumber,
      companyName,
      address,
      expectedByStr,
      makeModel,
      rego,
      vin,
      quoteNumber,
      lines,
      openUser,
      isSubmitted,
      canSubmit
    } = order;
    const {openIndex, images} = gallery;
    return (
      <React.Fragment>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h5 className='mb-0'>Order #: O{idStr}</h5>
          <div className='d-flex flex-row align-items-center'>
            {openUser && (
              <Avatar
                className='rounded-circle mr-3'
                bg={openUser.avatarBg}
              >
                {openUser.initial}
              </Avatar>
            )}
            <SubmitButton
              type='button'
              className='btn btn-primary'
              onClick={this.onSubmit}
              disabled={canSubmit !== true}
            >Submit</SubmitButton>
            <div className='cursor-pointer d-md-none ml-3'>
              <NavLink className='text-muted' to='/orders'>
                <Icons.X />
              </NavLink>
            </div>
          </div>
        </div>
        {isSubmitted === true && (
          <div className='alert alert-danger mb-3 p-4 text-center font-weight-bold text-danger'>CLOSED</div>
        )}
        <Card className='mb-3 p-3'>
          <div className='mb-3 text-center font-weight-bold text-uppercase'>
            <span className='text-danger'>*** Order ***</span>
            <br />
            {companyName}
            <br />
            {address}
          </div>
          <div className='row mb-2'>
            <div className='col-4'>
              <span className='font-weight-bold'>Job #:</span> {jobNumber}
            </div>
            <div className='col-4'>
              <span className='font-weight-bold'>Order #:</span> {id}
            </div>
            <div className='col-4'>
              <span className='font-weight-bold'>Expected by:</span> {expectedByStr || '\u2011'}
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-4'>
              <span className='font-weight-bold'>Make/model:</span> <span className='text-uppercase'>{makeModel}</span>
            </div>
            <div className='col-4'>
              <span className='font-weight-bold'>Rego:</span> {rego}
            </div>
            <div className='col-4'>
              <span className='font-weight-bold'>VIN:</span> {vin}
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <span className='font-weight-bold'>Quote #:</span> {quoteNumber || 'N/A'}
            </div>
          </div>
        </Card>
        <ImageList images={images} openIndex={openIndex} onSetOpenImage={onSetOpenImage} />
        <Card className='p-3'>
          <div className='font-weight-bold'>Items</div>
          {lines.map((l, i) => (
            <LineItem key={i} {...l} />
          ))}
        </Card>
      </React.Fragment>
    );
  };
};
