import {connect} from 'react-redux';

import Reports from './component.jsx';


const mapStateToProps = (state, props) => {
  const {
    params: {
      generalSettings: company,
    }
  } = state;
  return {
    company,
  };
};


export default connect(
  mapStateToProps,
  null
)(Reports);
