import React from 'react';
import * as Icons from 'react-feather';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';


import JobTitle from '../JobTitle';
import ImageList from '../ImageList';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const Upload = styled.div`
  height: 30px;
  position: relative;
`;

const Add = styled.label`
  position: absolute;
  right: 0px;
}`

const List = styled.li`
  padding: 0.75rem 2.25rem;
  input {
    left: 2.25rem;
  }
  svg {
    position: absolute;
    right: 1.25rem;
  }

  .zoomIn {
    position: absolute;
    right: 4.25rem;
  }

  .download {
    position: absolute;
    right: 7.25rem;
  }
  embed {
    margin-left: 15px;
  }
}`

const UploadDoc = styled.div`
  margin-top: 45px;
`

function download(content, mimeType, filename) {
  const a = window.document.createElement("a"); // Create "a" element
  const blob = new Blob([content], { type: mimeType }); // Create a blob (file-like object)
  const url = URL.createObjectURL(blob); // Create an object URL from blob
  a.setAttribute("href", url); // Set "a" element link
  a.setAttribute("download", filename); // Set download filename
  a.click(); // Start downloading
}

export default class Document extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      document: ''
    };
    this.onUpload = this.onUpload.bind(this);
  }
  componentDidMount() {
    const {jobId, onLoad} = this.props;
    onLoad(jobId);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    const {jobId, onLoad} = this.props;
    if (jobId !== prevProps.jobId) {
      onLoad(jobId);
    }
  }

  onChange(id, e) {
    const {jobId, onChange} = this.props;
    onChange(jobId, id, e.currentTarget.checked)
  }

  onUpload(e) {
    e.preventDefault();
    const {jobId, onUpload} = this.props;
    const files = e.target.files;
    onUpload(jobId, files)
      .then(res => {
        if (res !== true) {
          throw new Error('unexpected response');
        } else {
          toast.success('Job successfully updated.');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while updating job.');
      })
    e.target.value = '';
  }

  onDelete(id, e) {
    e.stopPropagation();
    const {jobId, onDelete} = this.props;
    if (window.confirm('Are you sure you wish to delete this document?') === true) {
      this.setState({uploading: true});
      onDelete(jobId, id)
        .catch(e => {
          console.log(e);
          toast.error('Error encountered while deleting document.');
        })
        .finally(() => {
          this.setState({uploading: false});
        });
    }
  }

  onDownload(document, e) {
    e.stopPropagation();
    const path = document.split('/');
    fetch(document, {method: "GET"})
    .then((response) => {
        return response.blob()
    })
    .then(data => {
      download(data, data.type, path[path.length - 1]);
    }).catch(err => {
      console.log(err)
    })
  }

  render() {
    console.log('documents:render');
    const {jobValid} = this.props;
    if (jobValid === null) return null;
    if (jobValid === false) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }

    const {
      jobId,
      jobNumber,
      flagged,
      vpp,
      vip,
      importUrl,
      documents
    } = this.props;
    return (
      <React.Fragment>
        <JobTitle
          jobId={jobId}
          jobNumber={jobNumber}
          flagged={flagged}
          vpp={vpp}
          vip={vip}
          importUrl={importUrl}
        />
        <ImageList jobId={jobId} />
        <div className='row no-gutters'>
          <div className='col-sm-12 col-md-6 pr-md-2 mt-3'>
            <Card className='p-3'>
              <Upload className='d-flex flex-row justify-content-between mb-3'>
                <Add htmlFor="documentInput" className='btn btn-primary '><Icons.Upload width={26} height={26} /></Add>
                <input
                  id='documentInput'
                  className='d-none'
                  type='file'
                  multiple
                  accept='*'
                  onChange={this.onUpload}
                />
              </Upload>
              <div className='font-weight-bold mb-2'>Documents</div>
              <ul className='list-group'>
                {documents.map((d, i) => (
                  <List className='list-group-item' key={i}>
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      checked={d.sync}
                      onChange={this.onChange.bind(this, d.id)}
                      value="true"
                    />
                    <embed src={d.document} width="300px" height="300px" />
                    <a href='/' onClick={this.onDownload.bind(this, d.document)}><Icons.Download  className='download align-self-right' /></a>
                    <a href={d.document} target='_blank' rel='noopener noreferrer'><Icons.ZoomIn  className='zoomIn align-self-right' /></a>
                    <Icons.Trash2 onClick={this.onDelete.bind(this, d.id)} className='align-self-right' />
                  </List>
                ))}
              </ul>
            </Card>
          </div>
          <div className='col-sm-12 col-md-6 pl-md-2 mt-3'>
          <Card className='p-3'>
              <UploadDoc className='font-weight-bold mb-2'>ORM Documents</UploadDoc>
              <ul className='list-group'>
                { documents.filter(d => d.sync === true).flatMap((d, i) => d.images.map((img, j) =>(
                  <List className='list-group-item' key={`${i}-${j}`}>
                    <embed onClick={this.onClick} src={img} width="300px" height="300px" />
                    <a href={img} target='_blank' rel='noopener noreferrer'>
                      <Icons.ZoomIn  className='align-self-right' />
                    </a>
                  </List>
                )))}
              </ul>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  };
};

