import React from 'react';
import * as Icons from 'react-feather';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import colors from 'colors';

import Autocomplete from 'components/Autocomplete.jsx';
import Select from 'components/Select.jsx';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Item = styled.tr`
  background: ${opts => opts.alert ? colors.redExtraLight : colors.white};
  & td {
    border: 0 !important;
    :first-child {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
    :last-child {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;


export default class Credits extends React.Component {
  constructor(opts) {
    super(opts);
    const {creditorId, creditor} = opts.creditors.reduce((acc, c) => {
      if (acc.creditorId === c.id) {
        acc.creditor = c.name;
      }
      return acc;
    }, {creditorId: opts.creditorId, creditor: ''});
    this.state = {
      allSelected: false,
      creditorId,
      creditor,
    };
    this.onChangeCreditor = this.onChangeCreditor.bind(this);
    this.onFilterCreditor = this.onFilterCreditor.bind(this);
    this.onChangeModeCollect = opts.onChangeMode.bind(null, 'collect');
    this.onChangeModeProcess = opts.onChangeMode.bind(null, 'process');
    this.onSearch = this.onSearch.bind(this);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  onChangeCreditor(e) {
    if (typeof e === 'string') {
      this.setState({
        creditor: e,
        creditorId: null,
      });
      if (e === '') {
        this.props.onChangeCreditorId(null);
      }
    } else if (e != null && e.id){
      this.setState({
        creditor: e.text,
        creditorId: e.id,
      });
      this.props.onChangeCreditorId(e.id);
    }
  }
  onFilterCreditor(v) {
    const {creditors} = this.props;
    if (v === null) return creditors.map(c => ({id: c.id, text: c.name}));
    const regex = new RegExp(v, 'i');
    const res = creditors
      .reduce((acc, c) => {
        if (regex.test(c.name) === true) {
          acc.push({
            id: c.id,
            text: c.name,
          });
        }
        return acc;
      }, []);
    return res;
  }
  onSearch() {
    const {mode, creditorId, onLoad} = this.props;
    onLoad(mode, creditorId);
  }
  onCollect(credit) {
    const {creditorId, onCollect} = this.props;
    onCollect(creditorId, credit.creditId)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while marking credit as collected.');
        } else {
          toast.success('Credit successfully collected.');
        }
      });
  }
  render() {
    console.log('credits:render');
    const {notFound} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load payments.</div>
        </ErrorContainer>
      );
    }
    const {mode, credits, creditors} = this.props;
    const {
      creditorId,
      creditor
    } = this.state;
    return (
      <Container className='flex-grow-1 p-3'>
        <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
          <h4 className='mb-0'>Credits</h4>
          <div className='d-flex flex-row align-items-center'>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                id='mode-collect'
                checked={mode === 'collect'}
                onChange={this.onChangeModeCollect}
              />
              <label className='form-check-label' htmlFor='mode-collect'>To collect</label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                id='mode-process'
                checked={mode === 'process'}
                onChange={this.onChangeModeProcess}
              />
              <label className='form-check-label' htmlFor='mode-process'>To process</label>
            </div>
            <Autocomplete
              className='m-0 mx-2'
              background={colors.grey1}
              uppercaseOnly={true}
              showCaret={true}
              value={creditor}
              onChange={this.onChangeCreditor}
              onFilter={this.onFilterCreditor}
            />
            {false && <Select
              className='mx-2'
              placeholder='select a creditor'
              background={colors.grey1}
              selected={creditorId}
              options={creditors}
              textField='name'
              onChange={this.props.onChangeCreditorId}
            />}
            <SearchButton
              type='button'
              className='btn btn-primary ml-2'
              disabled={mode === null || creditorId === null}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3'>
            {credits.length === 0 && (
              <div className='text-center text-secondary'>No credits found.</div>
            )}
            {credits.length !== 0 && (
              <table className='table mb-0'>
                <thead>
                  <tr>
                    <th className='border-0 py-0'>Job #</th>
                    <th className='border-0 py-0'>Invoice #</th>
                    <th className='border-0 py-0'>Invoice date</th>
                    <th className='border-0 py-0'>Description</th>
                    <th className='border-0 py-0'>Part #</th>
                    <th className='border-0 py-0'>Cost</th>
                    <th className='border-0 py-0'>Credit date</th>
                    <th className='border-0 py-0'>Reason</th>
                    <th className='border-0 py-0'>Collect date</th>
                    {mode === 'collect' && <th className='border-0 py-0' />}
                  </tr>
                </thead>
                <tbody>
                  {credits.map((c, i) => (
                    <React.Fragment key={i}>
                      <tr>
                        <td className='p-1 border-0' />
                      </tr>
                      <Item>
                        <td className='align-middle'>
                          <Link to={`/job/${c.jobId}`}>
                            <strong className='text-body'>{c.jobNumber}</strong>
                          </Link>
                        </td>
                        <td className='align-middle'>{c.invoiceNumber}</td>
                        <td className='align-middle'>{c.invoiceDateStr}</td>
                        <td className='align-middle'>{c.description}</td>
                        <td className='align-middle'>{c.partNumber}</td>
                        <td className='align-middle'>{c.costPriceStr}</td>
                        <td className='align-middle'>{c.creditDateStr}</td>
                        <td className='align-middle'>{c.reason}</td>
                        <td className='align-middle'>{c.collectDateStr}</td>
                        {mode === 'collect' && (
                          <td className='text-center'>
                            <button
                              type='button'
                              className='btn btn-warning'
                              disabled={c.collectDate !== null}
                              onClick={this.onCollect.bind(this, c)}
                            >Collect</button>
                          </td>
                        )}
                      </Item>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </Card>
        )}
      </Container>
    );
  };
};

