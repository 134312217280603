import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';


const Container = styled.div`
  position: relative;
`;
const Input = styled.input`
  color: ${opts => opts.color || 'invalid'};
  box-shadow: none;
  background-color: ${opts => opts.background ? opts.background : '#fff'} !important;
  border-color: ${opts => opts.error ? colors.red : '#fff'} !important;
  text-transform: ${opts => opts.uppercaseOnly ? 'uppercase' : 'none'};
`;
const Caret = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  font-size: .63rem;
  line-height: 2.5rem;
  height: 2.5rem;
  & svg {
    stroke: ${opts => opts.disabled ? colors.grey4 : (opts.color || colors.body)};
  }
`;
const Options = styled.ul`
  position: absolute;
  left: 0px;
  opacity: 1;
  display: block;
  cursor: pointer;

  list-style-type: none;
  padding-left: 0;

  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  background-color: #fff;
  margin: 0px;
  max-height: 250px;
  overflow-y: auto;
  z-index: 999;
`;
const Option = styled.li`
  color: ${opts => opts.hovering ? '#fff !important' : 'inherit'};
  background-color: ${opts => opts.hovering ? colors.grey4 : 'inherit'};
`;

export default class Autocomplete extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      hoverIndex: -1,
      options: []
    };
    this.container = React.createRef();
    this.onClick = this.onClick.bind(this);
    this.onClickCaret = this.onClickCaret.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onInput = this.onInput.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.timer = null;
  }
  componentDidMount() {
    document.addEventListener('click', this.onClick);
    this.container.current.addEventListener('keydown', this.onKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onClick);
    this.container.current.removeEventListener('keydown', this.onKeyDown);
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.error !== this.props.error ||
      nextProps.value !== this.props.value ||
      nextState.hoverIndex !== this.state.hoverIndex ||
      nextState.options !== this.state.options
    );
  }
  onKeyDown(e) {
    e.stopPropagation();
    const {hoverIndex, options} = this.state;
    switch (e.key) {
      case 'ArrowUp':
        {
          if (options.length === 0) return;
          e.preventDefault();
          this.setState({
            hoverIndex: Math.max(-1, Math.min(options.length -1, hoverIndex - 1))
          });
          break;
        }
      case 'ArrowDown':
        {
          if (options.length === 0) return;
          e.preventDefault();
          this.setState({
            hoverIndex: Math.max(-1, Math.min(options.length -1, hoverIndex + 1))
          });
          break;
        }
      case 'Enter':
        {
          if (options.length === 0) return;
          e.preventDefault();
          if (hoverIndex >= 0) {
            this.onSelect(options[hoverIndex], e);
          } else {
            this.onSelect(null, e);
          }
          this.setState({
            ...this.state,
            options: []
          });
          break;
        }
      case 'Tab':
        {
          if (options.length === 0) return;
          this.setState({
            ...this.state,
            options: []
          });
          break;
        }
      default:
        break;
    }
  }
  onMouseOver(hoverIndex) {
    this.setState({
      ...this.state,
      hoverIndex
    });
  }
  onClickCaret(e) {
    this.setState({
      options: this.props.onFilter(null),
    });
  }
  onClick(e) {
    if (
      this.container &&
      this.state.options.length > 0
    ) {
      if (!this.container.current.contains(e.target)) {
        this.setState({
          ...this.state,
          options: []
        });
      }
    }
  }
  onInput(e) {
    const v = e.target.value;
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      const {uppercaseOnly, onFilter} = this.props;
      if (v.length >= 3) {
        this.setState({
          options: onFilter(uppercaseOnly === true ? v.toUpperCase() : v)
        });
      } else {
        this.setState({
          options: []
        });
      }
      this.timer = null;
    }, 250);
    this.props.onChange(v);
  }
  onBlur() {
    const {uppercaseOnly, value, onChange} = this.props;
    if (uppercaseOnly === true) {
      onChange((value || '').toUpperCase());
    }
  }
  onSelect(value, e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      options: []
    });
    this.props.onChange(value);
  }
  render() {
    const {
      value,
      className,
      color,
      background,
      uppercaseOnly,
      readOnly,
      onFocus,
      children,
      showCaret,
      error
    } = this.props;
    const {options, hoverIndex} = this.state;
    return (
      <Container
        className={className}
        ref={this.container}
      >
        {children}
        {showCaret === true && (
          <Caret
            color={color}
            onClick={this.onClickCaret}
          ><Icons.ChevronDown /></Caret>
        )}
        <Input
          type='text'
          className='form-control p-0'
          color={color}
          background={background}
          uppercaseOnly={uppercaseOnly}
          error={error}
          value={value}
          readOnly={readOnly}
          onFocus={onFocus}
          onBlur={this.onBlur}
          onChange={this.onInput}
        />
        <Options className={options.length > 0 ? 'w-100 mt-2' : 'w-100 d-none'}>
          {
            options.map((o, i) => (
              <Option
                key={o.id}
                className='text-primary px-2 py-1'
                hovering={hoverIndex === i}
                onClick={this.onSelect.bind(this, o)}
                onMouseOver={this.onMouseOver.bind(this, i)}
              >
                {o.text}
              </Option>
            ))
          }
        </Options>
      </Container>
    );
  }
};

