export default (state = 0, action) => {
  switch (action.type) {
    case 'LOADING-INC':
      return state + 1;
    case 'LOADING-DEC':
      return Math.max(0, state - 1);
    default:
      return state;
  }
};
