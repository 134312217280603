
export default (state = {open: false}, action) => {
  switch (action.type) {
    case 'MODAL-SET':
      return {
        open: action.open
      };
    default:
      return state;
  }
};
