import React from 'react';
import PropTypes from 'prop-types';

const Tow = props => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...otherProps}
    >
      <polygon points='1 16 16 16 16 12' />
      <polygon points='20 6 20 6 23 11 23 16 16 16 16 6' />
      <circle cx='5.5' cy='18.5' r='2.5' />
      <circle cx='18.5' cy='18.5' r='2.5' />
    </svg>
  );
};


Tow.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Tow.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Tow;
