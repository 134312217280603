import numeral from 'numeral';

import moment from 'm';


export default (state = {notFound: null, open: false}, action) => {
  switch (action.type) {
    case 'JOB-INFOSHEET-SET-NOT-FOUND':
      return {
        notFound: true,
        open: state.open,
      };
    case 'JOB-INFOSHEET-RESET':
      return {
        notFound: null,
        open: false,
      };
    case 'JOB-INFOSHEET-OPEN':
      return {
        ...state,
        open: true
      };
    case 'JOB-INFOSHEET-SET':
      const {jobNumber, rego, claimNumber, excess, startDate, finishDate, defaultLocation} = action;
      return {
        notFound: false,
        open: state.open,
        jobNumber,
        rego,
        claimNumber,
        excess: numeral(excess / 100).format('$0,0.00'),
        startDate: startDate ? moment(startDate * 1000).format('DD-MM') : 'N/A',
        finishDate: finishDate ? moment(finishDate * 1000).format('DD-MM') : 'N/A',
        defaultLocation,
      };
    default:
      return state;
  }
};
