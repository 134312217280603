const defaultRates = {
  rr: 0,
  repair: 0,
  paint: 0
};


let debtor = null;
let debtors = [];
let rates = {...defaultRates};
const getRates = (state) => {
  if (!state || !state.jobDetails) return defaultRates;
  const {header: {debtorId: ins}} = state.jobDetails;
  if (ins === debtor && debtors === state.params.debtors) return rates;
  debtor = ins;
  debtors = state.params.debtors || {index: {}}
  rates = {...defaultRates};
  if (debtors.index.hasOwnProperty(debtor)) {
    rates = {
      rr: debtors.index[debtor].rrRate,
      repair: debtors.index[debtor].repairRate,
      paint: debtors.index[debtor].paintRate,
    };
  }
  return rates;
};

export default getRates;
