import React from 'react';
import * as Icons from 'react-feather';
import {Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import Wrench from 'icons/wrench.jsx';
import colors from 'colors';

import Pipeline from './Pipeline';
import Jobsheets from './Jobsheets';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  font-size: 0.9rem;
  height: 100%;
  width: calc(100% - 64px);
  @media (min-width: 992px) {
    width: calc(100% - 220px);
  }
`;
const Employees = styled.div`
  overflow-x: auto;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  flex-shrink: 0;
  flex-grow: 9;
`;
const Employee = styled.div`
  cursor: pointer;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  align-self: center;
  background: ${opts => opts.bg};
  color: #fff;
  & svg {
    stroke: ${colors.body};
  }
`;
const AvatarContainer = styled.div`
  width: 36px;
  height 36px;
`;
const AvatarSVG = styled.svg`
  cursor: pointer;
`;


const Avatar = ({initials, name, color, selected, load, className, onClick}) => (
  <AvatarSVG
    xmlns='http://www.w3.org/2000/svg'
    width='36'
    height='36'
    viewBox='0 0 36 36'
    className={className}
    onClick={onClick}
  >
    <title>{name}</title>
    <circle className='donut-hole' cx='18' cy='18' r='15.91549430918954' fill={selected ? color : '#fff'} />
    <circle
      className='donut-ring'
      cx='18'
      cy='18'
      r='15.91549430918954'
      fill='transparent'
      stroke={colors.grey2}
      strokeWidth='3'
    />
    <circle
      className='donut-segment'
      cx='18'
      cy='18'
      r='15.91549430918954'
      fill='transparent'
      stroke={color}
      strokeWidth='3'
      strokeDasharray={[load, 100 - load].join(' ')}
      strokeDashoffset='25'
    />
    <text
      fontFamily='Roboto-Medium, Roboto'
      fontSize='13'
      fontWeight='400'
      x='50%'
      y='50%'
      fill={selected ? '#fff' : color}
      dominantBaseline='central'
      textAnchor='middle'
    >{initials}</text>
  </AvatarSVG>
);

export default class Production extends React.Component {
  componentDidMount() {
    const {locationId, onLoad} = this.props;
    if (locationId) {
      onLoad(locationId);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.locationId && this.props.locationId !== prevProps.locationId) {
      this.props.onLoad(this.props.locationId);
    }
  }
  onFilterEmployee(employee) {
    const {filteredEmployee, onFilterEmployee} = this.props;
    if (employee === filteredEmployee) return onFilterEmployee(null);
    onFilterEmployee(employee);
  }
  render() {
    const {
      notFound,
      filteredEmployee,
      employeeIndex,
      employeeList,
      employeeLoads,
    } = this.props;

    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load production data.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 d-flex flex-column'>
        <Switch>
          <Route path='/production/pipeline' exact={true} component={Pipeline} />
          <Route path='/production/jobsheets' exact={true} component={Jobsheets} />
        </Switch>
        {notFound === false && (
          <Employees
            className='d-flex flex-row justify-content-begin align-items-center px-2'
          >
            <AvatarContainer className='mx-1'>
              <Employee
                className='rounded-circle'
                bg={filteredEmployee === 'repairers' ? colors.grey4 : colors.grey1}
                onClick={this.onFilterEmployee.bind(this, 'repairers')}
              ><Wrench /></Employee>
            </AvatarContainer>
            <AvatarContainer className='mx-1'>
              <Employee
                className='rounded-circle'
                bg={filteredEmployee === 'painters' ? colors.grey4 : colors.grey1}
                onClick={this.onFilterEmployee.bind(this, 'painters')}
              ><Icons.Droplet /></Employee>
            </AvatarContainer>
            {
              employeeList.map((eId, i) => (
                <AvatarContainer className='mx-1' key={i}>
                  <Avatar
                    initials={employeeIndex[eId].initials}
                    name={employeeIndex[eId].name}
                    color={employeeIndex[eId].avatarBg}
                    selected={filteredEmployee === eId}
                    load={employeeLoads[eId] || 0}
                    onClick={this.onFilterEmployee.bind(this, eId)}
                  />
                </AvatarContainer>
              ))
            }
          </Employees>
        )}
      </Container>
    );
  }
};


