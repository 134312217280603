import {connect} from 'react-redux';

import LocationSelect from './component.jsx';


const change = locationId => ({
  type: 'LOCATION-ID-SET',
  locationId
});


const mapStateToProps = (state, props) => {
  const {
    params: {locations: {selfLocations: locations}},
    locationId
  } = state;
  return {
    locations,
    locationId,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onChange: locationId => dispatch(change(locationId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSelect);
