import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import Portal from 'components/Portal.jsx';


const ModalContainer = styled.div`
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  background-color: rgba(246, 246, 250, 0.65);

  & .modal-dialog {
    @media (min-width: 576px) {
      width: 500px;
      max-width: 500px;
    }
  }

  & .modal-content {
    border: none;
    box-shadow: 0px 6px 25px rgba(197, 197, 197, 1);
  }

  & .modal-header {
    border: none;
    padding-bottom: 0px;
  }

  & .modal-footer {
    border: none;
    padding-top: 0px;
  }

  ${({wide}) => wide && `
    & .modal-dialog {
      @media (min-width: 960px) {
        width: 60%;
        max-width: 60%;
      }
    }
  `}

  ${({extraWide}) => extraWide && `
    & .modal-dialog {
      @media (min-width: 600px) {
        width: 75%;
        max-width: 75%;
      }
    }
  `}

  ${({fullScreen}) => fullScreen && `
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0px;
    & .modal-dialog {
      width: 100%;
      max-width: 100%;
      height: 100%;
      margin: 0px;
    }
    & .modal-content {
      border: none;
      border-radius: 0px;
      box-shadow: none;
      height: 100%;
      overflow: scroll;
    }
    @media print {
      position: absolute;
      min-height: 100%;
      height: inherit;
    }
  `}
`;

export default class Modal extends React.Component {
  constructor(opts) {
    super(opts);
    this.onDocumentKey = this.onDocumentKey.bind(this);
  }
  componentDidMount() {
    document.addEventListener('keyup', this.onDocumentKey);
    this.props.onToggle(this.props.open);
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.onDocumentKey);
    this.props.onToggle(false);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.props.onToggle(this.props.open);
    }
    //console.log('modal update', this.props.open);
  }
  onDocumentKey(e) {
    if (e.key === 'Escape') {
      this.props.onClose && this.props.onClose();
    }
  }
  render() {
    const {title, wide, extraWide, fullScreen, open, onClose, children} = this.props;
    if (open !== true) return null;
    return (
      <Portal>
        <ModalContainer
          className='modal-backdrop d-flex flex-row justify-content-center'
          wide={wide}
          extraWide={extraWide}
          fullScreen={fullScreen}
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header align-items-center d-print-none'>
                {title && <strong>{title}</strong>}
                {onClose && (
                  <button type='button' className='close' onClick={onClose}><Icons.X /></button>
                )}
              </div>
              {children}
            </div>
          </div>
        </ModalContainer>
      </Portal>
    );
  }
};

