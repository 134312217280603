import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';

import Select from 'components/Select.jsx';


const YEARS = Array.apply(null, Array(moment().year() - 2019 + 1)).map((_, i) => ({id: 2019 + i, text: `${2019 + i}`}));
const MONTHS = moment.monthsShort().map((m, i) => ({
  id: i + 1,
  text: m.toUpperCase(),
}));

const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const SearchButton = styled.button`
  width: 65px;
  & svg {
    stroke: #fff;
  }
`;
const Table = styled.table`
  margin-bottom: 0;
`;
const Th = styled.th`
  padding: 0 !important;
`;
const Td = styled.td`
  padding: 0 !important;
  padding-left: ${opts => opts.level * 2}rem !important;
  font-weight: ${opts => ((opts.isLeaf === false || opts.isTotal === true || opts.level === 0) && 'bold')};
`;

export default class ReportsSurvey extends React.Component {
  constructor(opts) {
    super(opts);
    const sd = moment();
    this.state = {
      locationId: null,
      year: sd.year(),
      month: sd.month() + 1,
      canSearch: false,
    };
    this.onChangeLocation = this.onChange.bind(this, 'locationId');
    this.onChangeYear = this.onChange.bind(this, 'year');
    this.onChangeMonth = this.onChange.bind(this, 'month');
    this.onSearch = this.onSearch.bind(this);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }

  onChange(field, e) {
    const newState = {
      ...this.state,
      [field]: e,
    };
    this.setState({
      ...newState,
      canSearch: (
        newState.locationId !== null &&
        newState.year !== null &&
        newState.month !== null
      ),
    });
  }


  onSearch() {
    const {onLoad} = this.props;
    const {locationId, year, month} = this.state;
    onLoad(locationId, year, month);
  }

  render() {
    console.log('survey:render');
    const {
      notFound,
      locations,
      report,
    } = this.props;
    const {
      locationId,
      year,
      month,
      canSearch,
    } = this.state;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 h-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load report.</div>
        </ErrorContainer>
      );
    }
    return (
      <React.Fragment>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label py-0'>Locations</label>
          <div className='col-sm-4'>
            <Select
              background={colors.grey1}
              placeholder='select a location'
              selected={locationId}
              options={locations}
              textField='name'
              onChange={this.onChangeLocation}
            />
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label py-0'>Date</label>
          <div className='col-sm-2'>
            <Select
              background={colors.grey1}
              placeholder='year'
              selected={year}
              options={YEARS}
              onChange={this.onChangeYear}
            />
          </div>
          <div className='col-sm-2'>
            <Select
              background={colors.grey1}
              placeholder='month'
              selected={month}
              options={MONTHS}
              onChange={this.onChangeMonth}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4 offset-sm-2'>
            <SearchButton
              type='button'
              className='btn btn-primary'
              disabled={canSearch !== true}
              onClick={this.onSearch}
            ><Icons.Search width={18} height={18} /></SearchButton>
          </div>
        </div>
        {notFound === false && (
          <Card className='p-3 mt-4'>
            {report === null && (
              <div className='text-center text-secondary'>Report not found.</div>
            )}
            {report !== null && (
              <Table className='table'>
                <thead>
                <tr>
                  <Th className='border-0' width='10%'>Job #</Th>
                  <Th className='border-0' width='10%'>Q1</Th>
                  <Th className='border-0' width='10%'>Q2</Th>
                  <Th className='border-0' width='10%'>Q3</Th>
                  <Th className='border-0' width='10%'>Q4</Th>
                  <Th className='border-0'>Q5</Th>
                  <Th className='border-0'>Comments</Th>
                  <Th className='border-0' width='10%'>Date</Th>
                </tr>
                </thead>
                <tbody>
                {report.map((r, i) => (
                  <tr key={i}>
                    <Td><Link to={`/job/${r.jobId}`}><strong className='text-body'>{r.jobNumber}</strong></Link></Td>
                    <Td>{r.q1}</Td>
                    <Td>{r.q2}</Td>
                    <Td>{r.q3}</Td>
                    <Td>{r.q4}</Td>
                    <Td><pre>{(r.q5 || 'N/A').substr(0, 10)}</pre></Td>
                    <Td><pre>{(r.comments || 'N/A').substr(0, 10)}</pre></Td>
                    <Td>{r.tsStr}</Td>
                  </tr>
                ))}
                </tbody>
              </Table>
            )}
          </Card>
        )}
      </React.Fragment>
    );
  };
};

