import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Body = styled.div`
  font-size: 16px;
  color: ${colors.body};
  padding: 1rem;
`;
const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
`;
const StageContainer = styled.div`
  width: 200px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 1rem;
`;
const Bar = styled.div`
  position: relative;
  top: 25px;
  height: 2px;
  width: 100%;
  background: ${opts => opts.done ? colors.green : colors.grey4};
`;
const Dot = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background: ${opts => opts.done ? colors.green : colors.grey4};
  text-align: center;
  line-height: 48px;
  & svg {
    stroke: white;
    @media print {
      stroke: ${colors.green};
    }
  }
`;
const Title = styled.div`
  width: 90px;
  color: ${opts => opts.done ? colors.green : colors.body};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
`;


const Stage = ({done, title}) => (
  <StageContainer>
    <Bar done={done} />
    <Dot done={done}>
      {done && <Icons.Check />}
    </Dot>
    <Title done={done}>{title}</Title>
  </StageContainer>
);


export default class AppCustomer extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      checked: false,
    }
  }
  componentDidMount() {
    const {match:{params:{token}}} = this.props;
    const {onLoad} = this.props;
    onLoad(token);
  }
  componentWillUnmount() {
    this.props.onUnload();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      const {token, onLoad} = this.props;
      onLoad(token);
    }
  }
  onCheck(e) {
    this.setState({
      checked: e.target.checked === true,
    });
  }
  renderAgreement() {
    const {
      onAgree,
      match: {params: {token}},
    } = this.props;
    const {checked} = this.state;
    return (
      <Card className='p-3'>
        <h4>Terms of Service</h4>
        <div className='font-weight-bold'>What to expect</div>
        <ul className='mb-0'>
          <li>We will take images of the car to capture all damage (claim related and Old damage). Please note pre-existing damage that is not claim related will not be fixed, this includes parking dents, scratches and chips. Please ask our team if you're unsure of particular damage.</li>
          <li>Please remove baby seats from your vehicle prior to leaving it with us. They block access to the interior of the vehicle and Bears are not licensed to refit them. We will not be held liable for any costs incurred with the re fitment of the seats.</li>
          <li>During repairs we may tow or drive your vehicle. I.E paint refinish, computer re-coding, safety checks and dealer related items.</li>
          <li>We may call or text you if we need the car for extra time to repair tricky dents or we find we need to change or paint a panel. We may also let you know its ready early if we repair a panel we thought we might need to replace. Our aim is to deliver a world class repair as quick as possible with a lifetime warranty.</li>
          <li>You will get a text message mid repairs with an update, or alternatively you can log into the customer portal for live updates.</li>
          <li>Sometimes a manufacturer will run out of parts or deliver damaged or wrong parts. If this happens we may need to get you back in for an on the spot replacement. This usually takes no longer than 15 minutes unless you are told otherwise.</li>
          <li>You'll get a call or text on the day of completion, before noon, with a pick-up time.</li>
          <li>Excess payment will need to be paid on pick up - we cannot release your vehicle unless the excess is paid (cash or eftpos only) to us, or you can provide a receipt number if you've paid direct to your insurer.</li>
          <li>We will give your car an express clean when repairs are completed. We use waterless car wash and non-silicone based products, keeping the environment in mind. We don't give the car a professional detail, we focus on professional workmanship.</li>
          <li>On pick up, management will explain how the repair was completed before you go. Please allow approx. 15 minutes for the handover.</li>
          <li>Our facilities and staff are compliant with all COVID-19 practices, your vehicle will be sanitised on drop off and pick up.</li>
          <li>After the repair, you'll get a survey from both Bears and your insurer. If you have a question or concern, please drop back into the site or send us an email (info@bearsgroup.com.au). Likewise, if you've been happy with our service please let us and your insurer know!</li>
        </ul>
        <div className='font-weight-bold mt-3'>What NOT to expect</div>
        <ul className='mb-3'>
          <li>Any damage to your vehicle that is not hail related will not be repaired. Your insurance claim will only cover hail related damage. Old damage, trolley dents, scratches, chips and scuffs are not covered unless we have otherwise been instructed and authorised by your insurance company.</li>
          <li>A replacement car. Please ask insurer if you have this option on your policy and consult with them to arrange a replacement car for you.</li>
          <li>Drop off and pick up. Please arrange your own transport to and from our site.</li>
          <li>We do not buff and polish your car in an attempt to keep your duco as original as possible and in most cases will not wash the vehicle in an attempt to be as eco friendly as we can.</li>
          <li>We will not be held responsible for any items left in your car during it’s time on our premises. If you are concerned about your valuables, please remove them before leaving your vehicle.</li>
        </ul>
        <div className='form-group form-check'>
          <input
            type='checkbox'
            className='form-check-input mt-2'
            id='terms-of-service-check'
            checked={checked}
            onChange={this.onCheck.bind(this)}
          />
          <label
            className='form-check-label font-weight-bold'
            htmlFor='terms-of-service-check'
          >I accept and agree to the terms above</label>
        </div>
        <button
          type='button'
          className='btn btn-primary w-100 py-3 font-weight-bold'
          disabled={checked !== true}
          onClick={() => onAgree(token)}
        >Submit</button>
      </Card>
    );
  }
  renderStatus() {
    const {
      phone,
      assessmentBooked,
      assessed,
      repairBooked,
      repaired,
      qaed,
      excessPaid,
      isOut: finished,
    } = this.props;
    return (
      <>
        <Card className='mb-5 px-3'>
          <div className='d-flex flex-row justify-content-md-center overflow-auto py-3'>
            <Stage
              done={assessmentBooked}
              title='Assessment booked'
            />
            <Stage
              done={assessed}
              title='Assessed'
            />
            <Stage
              done={repairBooked}
              title='Repair booked'
            />
            <Stage
              done={repaired}
              title='Repaired'
            />
            <Stage
              done={qaed}
              title='QA'
            />
            <Stage
              done={excessPaid}
              title='Excess paid'
            />
            <Stage
              done={finished}
              title='Finished'
            />
          </div>
        </Card>
        <h4>Terms of Service</h4>
        <div className='font-weight-bold'>What to expect</div>
        <ul className='mb-0'>
          <li>We will take images of the car to capture all damage (claim related and Old damage). Please note pre-existing damage that is not claim related will not be fixed, this includes parking dents, scratches and chips. Please ask our team if you're unsure of particular damage.</li>
          <li>Please remove baby seats from your vehicle prior to leaving it with us. They block access to the interior of the vehicle and Bears are not licensed to refit them. We will not be held liable for any costs incurred with the re fitment of the seats.</li>
          <li>During repairs we may tow or drive your vehicle. I.E paint refinish, computer re-coding, safety checks and dealer related items.</li>
          <li>We may call or text you if we need the car for extra time to repair tricky dents or we find we need to change or paint a panel. We may also let you know its ready early if we repair a panel we thought we might need to replace. Our aim is to deliver a world class repair as quick as possible with a lifetime warranty.</li>
          <li>You will get a text message mid repairs with an update, or alternatively you can log into the customer portal for live updates.</li>
          <li>Sometimes a manufacturer will run out of parts or deliver damaged or wrong parts. If this happens we may need to get you back in for an on the spot replacement. This usually takes no longer than 15 minutes unless you are told otherwise.</li>
          <li>You'll get a call or text on the day of completion, before noon, with a pick-up time.</li>
          <li>Excess payment will need to be paid on pick up - we cannot release your vehicle unless the excess is paid (cash or eftpos only) to us, or you can provide a receipt number if you've paid direct to your insurer.</li>
          <li>We will give your car an express clean when repairs are completed. We use waterless car wash and non-silicone based products, keeping the environment in mind. We don't give the car a professional detail, we focus on professional workmanship.</li>
          <li>On pick up, management will explain how the repair was completed before you go. Please allow approx. 15 minutes for the handover.</li>
          <li>Our facilities and staff are compliant with all COVID-19 practices, your vehicle will be sanitised on drop off and pick up.</li>
          <li>After the repair, you'll get a survey from both Bears and your insurer. If you have a question or concern, please drop back into the site or send us an email (info@bearsgroup.com.au). Likewise, if you've been happy with our service please let us and your insurer know!</li>
        </ul>
        <div className='font-weight-bold mt-3'>What NOT to expect</div>
        <ul className='mb-3'>
          <li>Any damage to your vehicle that is not hail related will not be repaired. Your insurance claim will only cover hail related damage. Old damage, trolley dents, scratches, chips and scuffs are not covered unless we have otherwise been instructed and authorised by your insurance company.</li>
          <li>A replacement car. Please ask insurer if you have this option on your policy and consult with them to arrange a replacement car for you.</li>
          <li>Drop off and pick up. Please arrange your own transport to and from our site.</li>
          <li>We do not buff and polish your car in an attempt to keep your duco as original as possible and in most cases will not wash the vehicle in an attempt to be as eco friendly as we can.</li>
          <li>We will not be held responsible for any items left in your car during it’s time on our premises. If you are concerned about your valuables, please remove them before leaving your vehicle.</li>
        </ul>
        <p>If you have any concerns about any of the above, or any other questions, please feel free to contact us direct on <span className='font-weight-bold'>{phone}</span> and we will be happy to help you out.</p>
        <p className='mb-0'>Thank you,<br />Management and staff</p>
      </>
    );
  }
  render() {
    const {notFound} = this.props;
    if (notFound === null) return null;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Job not found.</div>
        </ErrorContainer>
      );
    }
    const {
      companyName,
      abn,
      licenseNumber,
      phone,
      logo,
      pin,
      jobNumber,
      rego,
      claimNumber,
      vin,
      excess,
      mode,
      assessmentDate,
      startDate,
      finishDate,
      address,
    } = this.props;
    return (
      <Body>
        <div className='row mb-5'>
          <div className='col-12 col-sm-6 text-center text-sm-left'>
            {logo && <img src={logo} alt={companyName} width="250" />}
          </div>
          <div className='col-12 col-sm-6 text-center text-sm-right'>
            <h3>{companyName}</h3>
            <div>{pin && <a href={pin} className='mr-2' target='_blank' rel='noopener noreferrer'><Icons.MapPin width={16} height={16} /></a>} {address}</div>
            <div>P: {phone}</div>
            <div>ABN: {abn}</div>
            <div>LIC #: {licenseNumber}</div>
          </div>
        </div>
        <Card className='mb-3 p-3'>
          <div className='row no-gutters'>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Job #:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{jobNumber}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Claim #:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{claimNumber}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>VIN:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{vin}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Rego:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{rego}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Excess:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{excess}</div>
            <div className='col-6 col-sm-2 col-lg-1 text-right font-weight-bold'>Start/Finish:</div>
            <div className='col-6 col-sm-4 col-lg-3 pl-2'>{startDate} {'\u2013'} {finishDate}</div>
          </div>
        </Card>
        {mode === 'assess' && (
          <Card className='p-3 text-center'>
            Please bring your car to {address} for assessment on <strong className='text-danger'>{assessmentDate}</strong>.
          </Card>
        )}
        {mode === 'repair' && (
          <Card className='p-3 text-center'>
            Your car is scheduled to be repaired from <strong className='text-danger'>{startDate}</strong> {'\u2013'} <strong className='text-danger'>{finishDate}</strong>.
          </Card>
        )}
        {mode === 'checkin' && this.renderAgreement()}
        {mode === 'status' && this.renderStatus()}
      </Body>
    );
  }
};


