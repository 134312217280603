import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import getRates from 'utils/get-debtor-rates.js';
import Quote from './component.jsx';


const addRow = (rowType, rate, position) => ({
  type: 'JOB-DETAILS-QUOTE-ADD-ROW',
  rowType,
  rate,
  position
});

const removeRow = (rowType, i) => ({
  type: 'JOB-DETAILS-QUOTE-REMOVE-ROW',
  rowType,
  i
});

const change = (rowType, i, changes) => ({
  type: 'JOB-DETAILS-QUOTE-UPDATE',
  rowType,
  i,
  changes
});

const changePartOutcome = (API, jobId, index, partId, partOutcome) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/details/part-outcome`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          partId,
          partOutcome,
        })
      }
    )
      .then(resp => {
        if (resp && resp.error === true) {
          return false;
        }
        dispatch({
          type: 'JOB-DETAILS-QUOTE-PART-OUTCOME',
          index,
          partOutcome,
        });
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    jobDetails: {
      header: {
        id: jobId,
        debtorId,
        stormId,
        bodyShape,
        bodyPaint,
        jobSettings,
      },
      category,
      potentialTotalLoss,
      lowCategoryAdjustment,
    },
  } = state;
  const {
    profile: {
      abilities: {
        canJobDetailsViewCategory,
        canJobDetailsViewQuote
      }
    }
  } = profiles[activeProfile];
  const visible = (
    canJobDetailsViewQuote === true &&
    state.params.debtors !== undefined &&
    debtorId !== null &&
    stormId !== null &&
    bodyShape !== null &&
    bodyPaint !== null
  );
  return {
    jobId,
    visible,
    showCategory: (
      canJobDetailsViewCategory === true &&
      jobSettings &&
      jobSettings.hasOwnProperty('pricingMethod') &&
      jobSettings.pricingMethod === 'category'
    ),
    category,
    potentialTotalLoss: visible && potentialTotalLoss,
    lowCategoryAdjustment: visible && lowCategoryAdjustment,
    rates: getRates(state)
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onAddRow: (rowType, rate, position) => dispatch(addRow(rowType, rate, position)),
  onRemoveRow: (rowType, i) => dispatch(removeRow(rowType, i)),
  onChange: (rowType, i, changes) => dispatch(change(rowType, i, changes)),
  onPartOutcome: (jobId, i, partId, partOutcome) => dispatch(changePartOutcome(API, jobId, i, partId, partOutcome)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Quote)
);
