import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import AppDebtor from './component.jsx';


const mapStateToProps = state => {
  const {authentication: {activeProfile, profiles}} = state;
  const {profile: {userType, abilities}} = profiles[activeProfile];
  return {
    visible: userType === 'debtor',
    abilities,
  };
};

export default withRouter(connect(mapStateToProps)(AppDebtor));
