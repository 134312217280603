import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import uuidv4 from 'uuid/v4';

import colors from 'colors';

import Modal from 'components/Modal';


const Card = styled.div`
  background: ${colors.grey1};
  border-radius: 0.5rem;
  padding: 1rem;
  & table:first-of-type {
    margin-top: 0px !important;
  }
`;
const Input = styled.input`
  height: inherit !important;
`;
const Mail = styled(Icons.Mail)`
  stroke: ${colors.primary};
`;
const Send = styled(Icons.Send)`
  stroke: #fff;
`;
const Printer = styled(Icons.Printer)`
  stroke: ${colors.primary};
`;

const isInCarDebtor = (debtors, debtorId) => {
  return debtors[debtorId] && debtors[debtorId].name.includes('INCAR INSTALLATIONS')
}

export default class Invoice extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      id: uuidv4(),
      formOpen: false,
      to: '',
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onEmail = this.onEmail.bind(this);
    this.onPrint = this.onPrint.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        id: uuidv4(),
        formOpen: false,
        to: '',
      });
      const {jobId, open, onLoad} = this.props;
      if (open === true) {
        onLoad(jobId)
          .then(res => {
            if (res !== true) {
              toast.error('Error encountered generating invoice.');
            }
            //if (res === true) {
            //  this.onPrint();
            //}
          });
      }
    }
  }
  onChangeEmail(e) {
    this.setState({
      to: e.target.value,
    });
  }
  onEmail() {
    const {id, formOpen, to} = this.state;
    const {jobId, onLoad} = this.props;
    if (formOpen !== true) {
      const {
        debtors,
        debtorId,
        email
      } = this.props;
      const to = [debtors[debtorId].email, email].filter(e => e).join(', ');
      this.setState({
        formOpen: true,
        to
      });
    } else {
      const emails = to.split(/[,;\s]/g).filter(e => e.length);
      onLoad(jobId, id, emails)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered sending email.');
          } else {
            toast.success('Email successfully sent.');
          }
          //if (res === true) {
          //  this.onPrint();
          //}
        });
    }
  }
  onPrint() {
    //setTimeout(() => {
      window.print();
    //}, 1);
  }
  render() {
    if (this.props.open !== true || this.props.notFound !== false) return null;
    const {
      company: {
        name: companyName,
        jurisdiction,
        abn,
        bsb,
        accountNumber,
        licenseNumber,
        address,
        phone,
        logo,
      },
      header: {
        debtorId,
      },
      debtors,
      jobNumber,
      invoiceNumber,
      invoiceDate,
      name,
      makeModel,
      rego,
      claimNumber,
      excess,
      subtotal,
      gst,
      total,
      totalPayable,
      startDate,
      finishDate,
      pdr,
      rr,
      repair,
      paint,
      part,
      misc,
      sublet,
      onUnload
    } = this.props;
    const {formOpen, to} = this.state;
    return (
      <Modal
        open={true}
        fullScreen={true}
      >
        <div className='modal-body'>
          <div className='d-none d-print-flex mb-5'>
            <div className='col-12 col-sm-6 text-center text-sm-left'>
              {logo && <img src={logo} alt='logo' width="250" />}
            </div>
            <div className='col-12 col-sm-6 text-center text-sm-right text-uppercase'>
              <h3>{companyName}</h3>
              <div>{address}</div>
              <div>P: {phone}</div>
              <div>{jurisdiction === 'AU' && 'ABN:'}{jurisdiction === 'NZ' && 'GST #:'} {abn}</div>
              <div>LIC #: {licenseNumber}</div>
            </div>
          </div>
          <div className='d-flex flex-row justify-content-between align-items-center'>
            <h5 className='mb-0 p-0'>Invoice</h5>
            <div className='d-flex flex-row d-print-none'>
              {formOpen === true && (
                <div className='input-group'>
                  <Input
                    type='email'
                    className='form-control'
                    value={to}
                    onChange={this.onChangeEmail}
                  />
                  <div className='input-group-append'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={this.onEmail}
                    ><Send width={18} height={18} /></button>
                  </div>
                </div>
              )}
              {formOpen !== true && (
                <button type='button' className='btn' onClick={this.onEmail}><Mail /></button>
              )}
              <button type='button' className='btn ml-2' onClick={this.onPrint}><Printer /></button>
              <button type='button' className='close ml-2' onClick={onUnload}><Icons.X /></button>
            </div>
          </div>
          <div className='mt-3'>
            <div className='text-right'>
              <span className='font-weight-bold'>Invoice #:</span> {invoiceNumber}
            </div>
            <div className='text-right'>
              <span className='font-weight-bold'>Invoice date:</span> {invoiceDate}
            </div>
            <div className='text-right'>
              <span className='font-weight-bold'>Name:</span> {debtors[debtorId].invoiceName}
            </div>
            <div className='text-right'>
              <span className='font-weight-bold'>ABN:</span> {debtors[debtorId].abn}
            </div>
          </div>
          <Card className='mt-3'>
            <div className='row'>
              <div className='col-1 text-right font-weight-bold'>Job #:</div>
              <div className='col-3'>{jobNumber}</div>
              <div className='col-1 text-right font-weight-bold'>Customer:</div>
              <div className='col-3'>{name}</div>
              <div className='col-1 text-right font-weight-bold'>Make/Model:</div>
              <div className='col-3'>{makeModel}</div>

              {!isInCarDebtor(debtors, debtorId) && (<div className='col-1 text-right font-weight-bold'>Rego:</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-3'>{rego}</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-1 text-right font-weight-bold'>Claim #:</div>)}
              {!isInCarDebtor(debtors, debtorId) && (<div className='col-3'>{claimNumber}</div>)}
              <div className='col-1 text-right font-weight-bold'>Start/Finish:</div>
              <div className='col-3'>{startDate} {'\u2013'} {finishDate}</div>
            </div>
          </Card>
          <Card className='mt-3'>
            {pdr.length !== 0 && (
              <table className='w-100 mt-3'>
                <thead>
                  <tr>
                    <th>PDR</th>
                    <th width='10%'>Qty</th>
                    <th width='10%'>Size</th>
                    <th width='10%'>Nett</th>
                  </tr>
                </thead>
                <tbody>
                  {pdr.map((r, i) => (
                    <tr key={i}>
                      <td>{r.description}</td>
                      <td>{r.qty}</td>
                      <td>{r.size}</td>
                      <td>{r.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {rr.length !== 0 && !isInCarDebtor(debtors, debtorId) && (
              <table className='w-100 mt-3'>
                <thead>
                  <tr>
                    <th>R&R</th>
                    <th width='10%'>Hours</th>
                    <th width='10%'>Rate ($)</th>
                    <th width='10%'>Nett ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {rr.map((r, i) => (
                    <tr key={i}>
                      <td>{r.description}</td>
                      <td>{r.hours}</td>
                      <td>${r.rate}</td>
                      <td>{r.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {rr.length !== 0 && isInCarDebtor(debtors, debtorId) && (
              <table className='w-100 mt-3'>
                <thead>
                  <tr>
                    <th>Labour</th>
                    <th width='10%'></th>
                    <th width='10%'></th>
                    <th width='10%'>Nett ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {rr.map((r, i) => (
                    <tr key={i}>
                      <td>{r.description}</td>
                      <td></td>
                      <td></td>
                      <td>{r.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {repair.length !== 0 && (
              <table className='w-100 mt-3'>
                <thead>
                  <tr>
                    <th>Repair</th>
                    <th width='10%'>Hours</th>
                    <th width='10%'>Rate ($)</th>
                    <th width='10%'>Nett ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {repair.map((r, i) => (
                    <tr key={i}>
                      <td>{r.description}</td>
                      <td>{r.hours}</td>
                      <td>${r.rate}</td>
                      <td>{r.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {paint.length !== 0 && (
              <table className='w-100 mt-3'>
                <thead>
                  <tr>
                    <th>Paint</th>
                    <th width='10%'>Hours</th>
                    <th width='10%'>Rate ($)</th>
                    <th width='10%'>Nett ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {paint.map((r, i) => (
                    <tr key={i}>
                      <td>{r.description}</td>
                      <td>{r.hours}</td>
                      <td>${r.rate}</td>
                      <td>{r.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {part.length !== 0 && (
              <table className='w-100 mt-3'>
                <thead>
                  <tr>
                    <th>Part</th>
                    <th>Part #</th>
                    <th width='10%'>Qty</th>
                    <th width='10%'>Price ($)</th>
                    <th width='10%'>Nett ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {part.map((r, i) => (
                    <tr key={i}>
                      <td>{r.description}</td>
                      <td>{r.partNumber}</td>
                      <td>{r.qty}</td>
                      <td>{r.price}</td>
                      <td>{r.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {misc.length !== 0 && (
              <table className='w-100 mt-3'>
                <thead>
                  <tr>
                    <th>Misc</th>
                    <th width='10%'>Nett ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {misc.map((r, i) => (
                    <tr key={i}>
                      <td>{r.description}</td>
                      <td>{r.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {sublet.length !== 0 && (
              <table className='w-100 mt-3'>
                <thead>
                  <tr>
                    <th>Sublet</th>
                    <th width='10%'>Nett ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {sublet.map((r, i) => (
                    <tr key={i}>
                      <td>{r.description}</td>
                      <td>{r.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Card>
          <div className='px-3 mt-3'>
            <table className='w-100'>
              <tbody>
                <tr>
                  <td className='font-weight-bold text-right px-3'>Subtotal</td>
                  <td width='10%'>{subtotal}</td>
                </tr>
                <tr>
                  <td className='font-weight-bold text-right px-3'>GST</td>
                  <td width='10%'>{gst}</td>
                </tr>
                {!isInCarDebtor(debtors, debtorId) && (<tr>
                  <td className='font-weight-bold text-right px-3'>Total</td>
                  <td width='10%'>{total}</td>
                </tr>)}
                {!isInCarDebtor(debtors, debtorId) && (<tr>
                  <td className='font-weight-bold text-right px-3'>Excess</td>
                  <td width='10%'>{excess}</td>
                </tr>)}
                <tr>
                  <td className='font-weight-bold text-right px-3'>Total payable</td>
                  <td width='10%'>{totalPayable}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='mt-5 text-center'>
            Please make payment to the following account:
          </div>
          <div className='row no-gutters'>
            <div className='col-6 text-right pr-1'>Account Name:</div>
            <div className='col-6 text-left pl-1'>{companyName}</div>
          </div>
          <div className='row no-gutters'>
            <div className='col-6 text-right pr-1'>Account BSB:</div>
            <div className='col-6 text-left pl-1'>{bsb}</div>
          </div>
          <div className='row no-gutters'>
            <div className='col-6 text-right pr-1'>Account #:</div>
            <div className='col-6 text-left pl-1'>{accountNumber}</div>
          </div>
        </div>
      </Modal>
    );
  }
};
