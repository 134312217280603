const OUT = 1.36;


module.exports = {
  panelCode: 'C',
  lineItems: [
    {
      description: 'BOOT LID',
      panel: 'BOOT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.32,
                isPart: true,
                isPanel: true,
                partAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID SPOILER',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.18,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID MOULD',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.1,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID SEAL',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.08,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID LOCK/HATCH',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.09,
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID GARNISH',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'LHR BOOT LID TAIL LIGHT EXT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'RHR BOOT LID TAIL LIGHT EXT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID MOULD',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID NAME',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID BADGE',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID SERIES BADGE',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LID EMBLEM',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                isPart: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT PART D/A',
      panel: 'BOOT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.61,
                rrAutoPaintable: true,
              }
            }
          },
          },
        },
        'LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'SEDAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                rrTime: 0.61,
                rrAutoPaintable: true,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT RR',
      panel: 'BOOT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 1.03,
                rrAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'INNER BOOT TRIMS NESS',
      panel: 'BOOT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                rrTime: 0.59,
                rrAutoReplaceable: true,
                rrAutoPaintable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT OUT',
      panel: 'BOOT',
      subsetOf: ['BOOT IN & OUT'],
      supersetOf: ['BOOT BLEND'],
      settings: {
        'BOOT': {
          '^(?!PRIXCAR)': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 3.5,
                paintAutoPaintable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: OUT,
                paintAutoPaintable: true,
              }
            }
          },
          },
          '^PRIXCAR': {
          '[^]*': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 3.2,
                hourlyRate: 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT IN & OUT',
      panel: 'BOOT',
      supersetOf: ['BOOT OUT', 'BOOT BLEND'],
      settings: {
        'BOOT': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 3.5,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: 2.06,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT BLEND',
      panel: 'BOOT',
      subsetOf: ['BOOT OUT', 'BOOT IN & OUT'],
      settings: {
        'LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^panel$': { /* pricing method */
            'SEDAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: 3.5,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            },
          },
          '^(?!panel)': { /* pricing method */
            'SEDAN': { /* body shape */
              '^M[1-3]$': { /* paint type */
                isBlend: true,
                paintTime: Math.round(OUT * 0.7 * 100) / 100,
                paintAutoPaintable: true,
                paintAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LOADING 10%',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.1 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT LOADING 25%',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                paintTime: Math.round(OUT * 0.25 * 100) / 100,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'BOOT SEALERS NESS',
      panel: 'BOOT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 37,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        },
        'LHR-QTR|RHR-QTR': {
          '[^]*': {
          '^(?!panel)': { /* pricing method */
            'SEDAN': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 37,
                miscAutoReplaceable: true,
              }
            }
          },
          },
        }
      }
    },
    {
      description: 'PDR BOOT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                repairTime: 1,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BUFF & POLISH BOOT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 50,
              }
            }
          },
          },
        },
      }
    },
    {
      description: 'BRUSH TOUCH BOOT',
      settings: {
        'BOOT': {
          '[^]*': {
          '^panel$': { /* pricing method */
            '[^]*': { /* body shape */
              '[^]*': { /* paint type */
                miscPrice: 33,
              }
            }
          },
          },
        },
      }
    },
  ]
};
